import { createStyles } from '@mantine/core';

export const modalFormStyles = createStyles(() => ({
  btnCloseModal: {
    '&:focus': {
      outline: 'none',
    },
    color: 'gray',
    position: 'absolute',
    top: '20px',
    left: '20px',
    borderWidth: 0,
    cursor: 'pointer',
    background: 'transparent',
  },

  buttonHolder: {
    display: 'flex',
    position: 'relative',
    marginTop: '5%',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },

  addressList: {
    backgroundColor: 'white',
    zIndex: 100,
    borderRadius: '5%',
    textAlign: 'left',
    '&.visible': {
      display: 'flex',
      border: '1px solid',
      borderColor: '#ced4da',
      borderRadius: '0px 0px 8px 8px',
    },
  },

  title: {
    fontSize: '2rem',
    fontWeight: 400,
    fontFamily: 'PP Neue Montreal',
    '@media(max-width: 767px)': {
      fontSize: '20px',
    },
  },
}));
