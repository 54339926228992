import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  creditContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    gap: 24,
    marginBottom: 24,
    '@media(max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },
  monaySpentContainer: {
    position: 'relative',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    minWidth: 420,
    height: 200,
    overflow: 'hidden',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
  },
  widgetContainer: {
    display: 'flex',
    width: '100%',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      gap: 24,
    },
    gap: rem(16),
  },
  widget: {
    '@media(min-width: 767px)': {
      flex: 1,
    },
  },
}));
