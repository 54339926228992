import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  button: {
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    background: '#FFF',
    height: '40px',
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderRadius: '8px',
    '&:hover': {
      background: 'rgba(255, 87, 69, 0.08);',
      color: theme.fn.primaryColor(),
    },
    padding: '12px 12px 10px 12px',
    marginRight: 10,
  },
  who: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '14px',
  },
  whoIcon: {
    fontSize: '20px',
    height: '32px',
    width: '32px',
    color: theme.fn.primaryColor(),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: '#FFEEEC !important',
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
    marginRight: '10px',
  },
  widgetContainer: {
    borderRadius: '0.5rem',
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: '1.5rem',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
}));

export const customMantineStyles = (primaryColor?: string) => {
  return {
    input: {
      color: '#5F5F5F',
      height: '40px',
      border: '1px solid #E6E7E9',
      borderRadius: '8px',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      background: 'white',
      '&:focus': { borderColor: primaryColor },
    },
    label: {
      color: '#4B4B4B',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19px',
    },
    item: {
      fontSize: 14,
      fontWeight: 500,
      color: '#5F5F5F',
      '&:hover': {
        border: 'none',
        background: 'rgba(255, 87, 69, 0.08);',
      },
    },
    selected: {
      fontSize: 14,
      fontWeight: 400,
      color: '#5F5F5F',
      background: 'rgba(255, 87, 69, 0.08);',
    },
    dropdown: {
      borderRadius: 8,
    },
  };
};
