import { TextInput, TextInputProps } from '@mantine/core';
import { forwardRef } from 'react';

type Props = TextInputProps & React.RefAttributes<HTMLInputElement>;

const FlexbaseInput = forwardRef<HTMLInputElement, Props>(
  ({ classNames, ...props }: Props, ref) => {
    // const { classes } = useStyles();

    return <TextInput ref={ref} {...props} classNames={{ ...classNames }} />;
  },
);

FlexbaseInput.displayName = 'FlexbaseInput';

export default FlexbaseInput;
