export const filterAutocompleteData = (
  searchString: string,
  data: { value: string; label: string }[],
) => {
  return searchString
    ? data
        .filter((c) => {
          const search = searchString.toLowerCase();
          return (
            c.value.includes(search) || c.label.toLowerCase().includes(search)
          );
        })
        .sort((a, b) => {
          const search = searchString.toLowerCase();
          if (
            a.label.toLowerCase().startsWith(search) ||
            a.value.startsWith(search)
          ) {
            return -1;
          } else if (
            b.label.toLowerCase().startsWith(search) ||
            b.value.startsWith(search)
          ) {
            return 1;
          }

          return a.label.localeCompare(b.label);
        })
        .map((c) => `${c.value} - ${c.label}`)
    : [];
};
