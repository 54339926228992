import { createStyles, getStylesRef, rem } from '@mantine/core';

interface NavProps {
  opened?: boolean;
}

const useStyles = createStyles((theme, { opened }: NavProps) => ({
  fullScreen: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  navbar: {
    position: 'fixed',
    flexBasis: '75px',
    width: '75px',
    overflowX: 'hidden',
    backgroundColor: theme.fn.primaryColor(),
    // backgroundColor: theme.fn.themeColor('neutral', 0),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: '24px 0',
    zIndex: 199, //Needs to be under 200 or else Mantine modals will appear over it
    transition: 'width 200ms ease',

    [`& .${getStylesRef('option')}`]: {
      transition: '200ms ease',
    },
    '@media(max-width: 767px)': {
      height: 'calc(100vh - 60px)',
      width: opened ? '100%' : 0,
      overflowX: 'hidden',
      [`& .${getStylesRef('option')}`]: {
        width: '100%',
      },
    },
    '@media(min-width: 768px)': {
      '&:hover': {
        width: '312px',
        flexBasis: '312px',
        [`& .${getStylesRef('option')}`]: {
          width: '273px',
        },
      },
    },
  },
  icon: {
    width: rem(60),
  },
  topSection: {
    width: '273px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    color: theme.fn.themeColor('neutral', 0),
  },
  nameSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    marginLeft: '10px',
    width: '95%',
    color: theme.fn.themeColor('neutral', 0),
  },
  nameText: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    color: theme.fn.themeColor('neutral', 0),
    // color: '#757575',
    wordWrap: 'break-word',
    letterSpacing: '0.01em',
    fontFamily: 'PP Neue Montreal',
  },
  // Note I did not extract all common styles yet
  menuOptionCommon: {
    ref: getStylesRef('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    marginTop: 20,
  },
  menuOption: {
    ref: getStylesRef('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    backgroundColor: 'none',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 4),
    },
  },
  highlightedMenuOption: {
    ref: getStylesRef('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 4),
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    color: theme.fn.themeColor('neutral', 0),
  },
  notClickableMenuOption: {
    ref: getStylesRef('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    backgroundColor: 'none',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  notClickableHighlightedMenuOption: {
    ref: getStylesRef('option'),
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: '#FFE6E3',
    borderRadius: '8px',
    whiteSpace: 'nowrap',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  subroute: {
    fontSize: '16px',
    cursor: 'pointer',
    height: '40px',
    display: 'flex',
    marginLeft: '100px',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  disabledMenuOption: {
    display: 'none',
  },
  menuOptionIcon: {
    alignSelf: 'end',
    marginLeft: 8,
    color: theme.fn.themeColor('neutral', 2),
  },
  navbarOptions: {
    marginLeft: '17.5px',
    marginRight: '17.5px',
    marginBottom: 'auto',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '@media(max-width: 767px)': {
      width: 'calc(100vw - 100px)',
    },
  },
  pageDiv: {
    height: '100%',
    overflow: 'auto',
    flexBasis: '100vw',
    marginLeft: '75px',
    backgroundColor: theme.fn.themeColor('neutral', 2),
    '> div:first-of-type': {
      margin: 'auto',
      maxWidth: '1368px',
    },
  },
  avatar: {
    height: '40px',
    width: '100px',
    color: theme.fn.primaryColor(),
  },
  txtComingSoon: {
    background: theme.colors.tertiary[0],
    borderRadius: '9px',
    padding: `0 ${theme.spacing.xs}`,
    marginLeft: '1rem',
    fontSize: '10px',
    color: theme.fn.primaryColor(),
    '@media(max-width: 767px)': {
      marginLeft: '5px',
    },
  },
}));

export default useStyles;
