import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AuthenticationToken } from '@flexbase/http-client-middleware';

import Login from './auth';
import AuthVerifyCode from './verify-code';
import AuthLoad from 'components/login/auth-loader';

import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'states/application/product-onboarding';
import { BnplState } from 'states/bnpl/bnpl.state';
import { useAuthToken } from 'states/auth/auth-token';
import AuthContainer from 'components/login/auth-page';
import { IAuthenticatedUser } from 'states/auth/authenticated-user';
import authenticatedStorage from 'states/auth/authenticated-storage';
import { EmailValidator } from 'utilities/validators/validate-email';
import { useRouteSectionContext } from 'components/routes/route-context';
import { PhoneVerifiedState } from 'areas/onboarding/onboarding-form.state';
import { LoginPasswordValidator } from 'utilities/validators/validate-password';

function isIAuthenticatedUser(object: any): object is IAuthenticatedUser {
  return object && 'user' in object;
}

function isIAuthenticationToken(object: any): object is AuthenticationToken {
  return object && 'tokenType' in object;
}

const LoginPage = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const { isBnpl } = useRecoilValue(BnplState);
  const [loading, setLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const { setShowRoutesFor } = useRouteSectionContext();
  const setPhoneVerified = useSetRecoilState(PhoneVerifiedState);
  const setUserAndCompanyData = useSetRecoilState(ApplicationState);
  const { setUserData, rememberUser, userEmail, clearToken } = useAuthToken();

  const theForm = useForm({
    initialValues: {
      email: userEmail,
      password: '',
      rememberMe: rememberUser,
    },
    validate: {
      email: EmailValidator(),
      password: LoginPasswordValidator(),
    },
  });

  const setAuthStateAndRedirect = async (user: IAuthenticatedUser) => {
    setUserData({
      token: user.token.token,
      email: theForm.values.email,
      remember: theForm.values.rememberMe,
    });
    try {
      let status = await getProductOnboardingStatus();

      // TODO: This is a stop-gap solution to handle older users with no company. The application will explode if they don't have one
      if (!status.company?.id && status.user.roles.includes('ADMIN')) {
        await flexbaseOnboardingClient.createCompany({
          companyName: 'My Company',
          optedProducts: ['CREDIT'],
        });
        status = await getProductOnboardingStatus();
      }

      setUserAndCompanyData(status);
      if (!status.completedOnboarding) {
        setShowRoutesFor('application');
      } else if (status.user.roles.includes('NO-LOGINS')) {
        navigate('/change-password');
      } else {
        setShowRoutesFor('main');
      }
    } catch (e) {
      notifications.show({
        title: 'Unable to load user data',
        message: 'An error occurred when loading user data. Please login again',
        autoClose: 10000,
      });
      clearToken();
    }
  };

  // Login flow
  const onSubmit = () => {
    const validationResult = theForm.validate();
    setErrorMsg('');
    if (!validationResult.hasErrors) {
      setLoading(true);
      const formValues = theForm.values;
      authenticatedStorage.useSessionStorage = false;
      authenticatedStorage.clear();
      flexbaseClient
        .login(formValues.email, formValues.password, '', {
          isBnpl,
          rememberUsername: formValues.rememberMe,
        })
        .then((response) => {
          setLoading(false);
          if (!response) {
            setErrorMsg(
              'Wrong email or password. Try again or select forgot password to reset',
            );
          } else {
            if (
              isIAuthenticationToken(response) &&
              response.tokenType === 'Challenge'
            ) {
              setIsVerifying(true);
            } else if (isIAuthenticatedUser(response)) {
              setAuthStateAndRedirect(response as IAuthenticatedUser);
            }
          }
        });
    }
  };

  // 2FA flow
  const resendCode = (): void => {
    const { email, password } = theForm.values;
    flexbaseClient.login(email, password, '');
  };

  const goBack = (): void => {
    setIsVerifying(false);
  };

  const handleOnChange = async (code: string): Promise<void> => {
    setErrorMsg('');
    if (code.length === 6) {
      const { email, password, rememberMe } = theForm.values;
      setLoading(true);

      const tokenResponse = await flexbaseClient.login(email, password, code, {
        isBnpl,
        rememberUsername: rememberMe,
      });
      Sentry.setUser({ email });
      if (!tokenResponse) {
        setErrorMsg('Invalid code. Please try again or re-send the code');
        setLoading(false);
      }
      if (isIAuthenticatedUser(tokenResponse)) {
        setAuthStateAndRedirect(tokenResponse as IAuthenticatedUser);
        setPhoneVerified(true);
      }
    }
  };

  useEffect(() => {
    sessionStorage.removeItem('logout');
  }, []);

  return (
    <AuthContainer>
      {loading && <AuthLoad />}
      {isVerifying ? (
        <AuthVerifyCode
          onGoBack={goBack}
          onChange={handleOnChange}
          onResendCode={resendCode}
          {...{ errorMsg, setErrorMsg }}
        />
      ) : (
        <Login loginForm={theForm} {...{ errorMsg, onSubmit }} />
      )}
    </AuthContainer>
  );
};

export default LoginPage;
