import { DateTime } from 'luxon';

/**
 * calculates the bill date list for the chart. This is
 * used to request the data for minumum due
 */
export const billDateList = (d?: DateTime): DateTime[] => {
  const dateList = [];
  const defaults = { hour: 0, minute: 0, second: 0, millisecond: 0 };

  const now = d || DateTime.now();
  const half = now.set({ day: 15, ...defaults });
  const full = now
    .plus({ month: 1 })
    .set({ day: 1, ...defaults })
    .minus({ day: 1 });
  let addExtra = true;

  if (now.day <= half.day) {
    // set first past billing date to the 30/31st of the last month
    dateList.push(now.set({ day: 1, ...defaults }).minus({ day: 1 }));
    addExtra = false;
  }
  dateList.push(half);
  dateList.push(full);
  dateList.push(full.plus({ month: 1 }).set({ day: 15, ...defaults }));
  dateList.push(
    full
      .plus({ month: 2 })
      .set({ day: 1, ...defaults })
      .minus({ day: 1 }),
  );

  if (addExtra) {
    dateList.push(full.plus({ month: 2 }).set({ day: 15, ...defaults }));
  }
  return dateList;
};

export const convertDatesToMonths = (datetimes: DateTime[]): string[] => {
  const months = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return datetimes.map((x) => months[x.month] + ' ' + x.day);
};

// convert a number to currency with short codes
export const convertToShortenedNumberFormats = (
  value: number,
  currencyType = 'en',
) => {
  const formatter = Intl.NumberFormat(currencyType, { notation: 'compact' });
  return formatter.format(value);
};

// convert a number to currency with short codes
export const convertToCurrency = (value: number, currencyType = 'en') => {
  const formatter = Intl.NumberFormat(currencyType);
  return formatter.format(value);
};

export const convertToCurrencyArray = (
  values: number[],
  currencyDenomination?: string,
) => {
  const formattedValues = values.map((v) => {
    if (currencyDenomination) {
      return `$${convertToCurrency(v)}`;
    }
    convertToCurrency(v);
  });
  return formattedValues;
};

// shorten labels to a specific length and add ...
export const shortenLabelName = (value: string, n: number) => {
  value = value
    .split('_')
    .map((v) => {
      return v.charAt(0).toUpperCase() + v.slice(1);
    })
    .join(' ');
  return value.length > n ? value.slice(0, n - 1) + '...' : value;
};

/**
 * calculate weighted average markers with min, max, and zero guards.
 * useful if you want to make dynamically sized markers without creating
 * a large amount of overlap due to extreme values.
 * */
export const createWeightedAverageMarkers = (
  arrayMarkers: number[],
  config: {
    min: number;
    max: number;
    avg: number;
    zero: number;
  },
) => {
  // find average of payments.
  const sumOfMarkers = arrayMarkers.reduce((a, b) => a + b);
  const averageMarker = sumOfMarkers / arrayMarkers.length;

  // normalize payment values to % above or below and apply
  const percentMarkersFromAverage = arrayMarkers
    .map((markerValue) => (markerValue / averageMarker) * config.avg)
    .map((markerValue) => {
      if (isNaN(markerValue)) {
        return 0;
      }
      return markerValue;
    });

  // apply max, mins, and zeros
  const percentArrayMarkers = percentMarkersFromAverage.map(
    (normalizedPayment) => {
      if (normalizedPayment === undefined) {
        normalizedPayment = 0;
      }
      if (normalizedPayment > config.max) {
        return config.max;
      } else if (normalizedPayment <= 0) {
        return config.zero;
      } else if (normalizedPayment < config.min) {
        return config.min;
      } else {
        return normalizedPayment;
      }
    },
  );
  return percentArrayMarkers;
};
