import FormBase from './form-base';
import { TextInput } from '@mantine/core';
import { useFormStyles } from './form.styles';
import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';

type Props = {
  onClose: () => void;
  onSubmit: (title: string) => void;
  currentTitle?: string;
};

const TitleForm = ({ onClose, onSubmit, currentTitle }: Props) => {
  const { classes } = useFormStyles();

  const form = useForm({
    initialValues: {
      title: currentTitle || '',
    },
    validate: {
      title: (val) => (validateRequired(val) ? null : 'Title is required'),
    },
  });

  const onUpdateClick = () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      const { title } = form.values;
      onSubmit(title);
    }
  };
  return (
    <FormBase onCancel={onClose} onSubmit={onUpdateClick}>
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        label={'Title'}
        data-testid="title-input"
        size="lg"
        {...form.getInputProps('title')}
      />
    </FormBase>
  );
};

export default TitleForm;
