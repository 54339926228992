import ValidatorFn from './validator-fn';

export const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
);

export function NewPasswordValidator(
  errorMessage?: string,
): ValidatorFn<string> {
  return (val) =>
    val && passwordRegex.test(val)
      ? null
      : errorMessage ||
        'Password must be at least 8 characters, contain 1 lowercase letter, 1 uppercase letter, a number and a symbol';
}

export function LoginPasswordValidator(
  errorMessage?: string,
): ValidatorFn<string> {
  return (val) =>
    val && val.length >= 8
      ? null
      : errorMessage || 'Password should be at least 8 characters';
}
