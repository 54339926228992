import {
  createStyles,
  Group,
  Image,
  Progress,
  Text,
  useMantineTheme,
} from '@mantine/core';
import AddressVerification from '../steps/address-verification/address-verification';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import VerifyIdentity from '../steps/verify-identity/verify-identity';
import VerifyPhone from '../steps/verify-phone/verify-phone';
import Summary from '../steps/summary/summary';
import BusinessType from '../steps/business-type/business-type';
import BusinessActivity from '../steps/business-activity/business-activity';
import VerifyBusiness from '../steps/verify-business/verify-business';
import { useMediaQuery } from '@mantine/hooks';
import MultiProduct from '../steps/multi-product/multi-product';
import VerifyBank from '../steps/verify-bank';
import BeneficialOwnersNew from '../steps/owners/beneficial-owners-new';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { merchantCallback } from 'utilities/url/get-bnpl-merchant-callback';
import ErrorEndScreen from './end-states/error-end-screen';
import DocUpload from '../steps/doc-upload';
import { OnboardingRouteNames } from 'states/application/product-onboarding.models';
import { useProductOnboarding } from '../onboarding-hooks';
import TreasuryAllocation from '../steps/treasury-allocation';
import StripeConfig from '../steps/stripe-config';
import { ContainerWithFooter } from 'components/footer/footer-container';
import { useEffect, useState } from 'react';
import { TransparentCenteredLoader } from '@flexbase-eng/web-components';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from '../../../states/application/product-onboarding';
import { useAuthToken } from '../../../states/auth/auth-token';
import ChangePasswordPage from '../steps/change-password/change-password';
import CreditCardImage from 'assets/images/credit-card.png';
import { CheckCircleProgress, CloudLock, FlexIcon } from 'assets/svg';

type CurrentStepProps = { step?: OnboardingRouteNames | string };
const CurrentStep = ({ step }: CurrentStepProps) => {
  switch (step) {
    case 'change-password':
      return <ChangePasswordPage />;
    case 'product-select':
      return <MultiProduct />;
    case 'user-address':
      return <AddressVerification />;
    case 'verify-identity':
      return <VerifyIdentity />;
    case 'verify-phone':
      return <VerifyPhone />;
    case 'business-type':
      return <BusinessType />;
    case 'business-activity':
      return <BusinessActivity />;
    case 'verify-business':
      return <VerifyBusiness />;
    case 'verify-bank':
      return <VerifyBank />;
    case 'summary':
      return <Summary />;
    case 'business-owners':
      return <BeneficialOwnersNew />;
    case 'doc-upload-id':
      return <DocUpload documentType={'id'} key={'id'} />;
    case 'doc-upload-tax':
      return <DocUpload documentType={'tax'} key={'tax'} />;
    case 'doc-upload-csa':
      return <DocUpload documentType={'csa'} key={'csa'} />;
    case 'treasury-allocation':
      return <TreasuryAllocation />;
    case 'account-config':
      return <StripeConfig />;
    default:
      return <ErrorEndScreen />;
  }
};

const OnboardingV2 = () => {
  const theme = useMantineTheme();
  const { step } = useParams();
  const { classes, cx } = useStyles();
  const isMobileScreenSize = useMediaQuery('(max-width: 767px)');
  const isTooWide = useMediaQuery('(max-width: 550px)');
  const { currentProgress, product } = useProductOnboarding();
  const { logout } = useAuthToken();
  const [loading, setLoading] = useState(true);
  const [applicationState, setApplicationState] =
    useRecoilState(ApplicationState);

  const {
    isBnpl,
    amount,
    merchantLogoUrl,
    merchantName,
    callbackBaseUrl,
    callbackParam,
    sessionParam,
  } = useRecoilValue(BnplState);

  const onExitClick = async () => {
    if (isBnpl) {
      window.location.replace(
        merchantCallback(
          callbackBaseUrl,
          TransactionStatus.canceled,
          callbackParam,
          sessionParam,
        ),
      );
    } else {
      logout();
    }
  };

  const getOnboardingStatus = async () => {
    setLoading(true);
    try {
      const status = await getProductOnboardingStatus(true);
      setApplicationState(status);
    } catch (e) {
      console.error('onboarding-stepper.page.tsx', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOnboardingStatus();
  }, []);

  return (
    <ContainerWithFooter
      disclaimer2={true}
      product={product}
      className={classes.footerContainer}
      classNames={{
        container: classes.footerContainer,
        footerDiscloure: classes.footerDisclosure,
        scrollContainer: classes.scrollContainer,
      }}
    >
      <div className={classes.header}>
        <FlexIcon width={68} />
        <div
          className={classes.headerActions}
          style={!isMobileScreenSize && isBnpl ? { width: 220 } : {}}
        >
          {isBnpl && (
            <div className={cx(classes.headerSupport, classes.headerBnpl)}>
              {merchantLogoUrl && (
                <Image
                  alt={merchantName}
                  src={merchantLogoUrl}
                  id="merchant-logo"
                  className={classes.headerImage}
                ></Image>
              )}
              {amount && (
                <div className={classes.headerText}>
                  {formatCurrency(amount!)}
                </div>
              )}
            </div>
          )}
          {isBnpl ? (
            <div
              className={classes.headerText}
              onClick={onExitClick}
              style={{ cursor: 'pointer' }}
            >
              Exit
            </div>
          ) : (
            <div className={classes.notices}>
              {step !== 'product-select' &&
                step !== 'change-password' &&
                !isTooWide && (
                  <Group spacing="sm" className={classes.creditImpactNotice}>
                    <CheckCircleProgress />
                    <div
                      className={cx(classes.headerText, classes.progressSaved)}
                    >
                      Progress saved!
                    </div>
                  </Group>
                )}
              {step !== 'summary' && (
                <Group className={classes.creditImpactNotice} spacing="sm">
                  <CloudLock />
                  <div className={classes.headerText}>No impact to credit</div>
                </Group>
              )}
            </div>
          )}
        </div>
      </div>
      <Progress
        size="xs"
        color={theme.fn.themeColor('primarySecondarySuccess', 2)}
        value={currentProgress}
        classNames={{ root: classes.progressRoot }}
        h={2}
      />
      {applicationState.userIsApplicant &&
        step === 'verify-phone' &&
        applicationState.product === 'CREDIT' && (
          <div className={classes.banner}>
            <Image
              src={CreditCardImage}
              width={isMobileScreenSize ? '154px' : '210px'}
              height={isMobileScreenSize ? '115px' : '150px'}
              style={{ marginRight: isMobileScreenSize ? 0 : '32px' }}
            />
            <div>
              <Text
                size={isMobileScreenSize ? '24px' : '48px'}
                color="#FF5745"
                ff={'PP Neue Montreal'}
              >
                Congrats!
              </Text>
              <Text
                size={isMobileScreenSize ? '18px' : '36px'}
                color="#FF5745"
                ff={'PP Neue Montreal'}
              >
                You’re eligible for the Flexbase Card.
              </Text>
            </div>
          </div>
        )}
      <div
        className={cx(
          classes.webContainer,
          isMobileScreenSize && classes.mobileContainer,
        )}
      >
        {loading ? (
          <TransparentCenteredLoader visible={true} />
        ) : (
          <CurrentStep step={step} />
        )}
      </div>
    </ContainerWithFooter>
  );
};

export const useStyles = createStyles((theme) => ({
  webContainer: {
    marginTop: 48,
    paddingBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
  },
  mobileContainer: {
    width: '100%',
    padding: '20px 16px 0 16px',
    margin: 'unset',
  },
  header: {
    padding: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
      padding: 16,
    },
  },
  footerContainer: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
  },
  footerDisclosure: {
    color: theme.fn.primaryColor(),
  },
  scrollContainer: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
  },
  notices: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  headerSupport: {
    display: 'flex',
    gap: 16,
    cursor: 'pointer',
  },
  headerImage: {
    // cursor: 'pointer',
  },
  headerText: {
    color: theme.colors.blackish[0],
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
  },
  progressSaved: {
    color: theme.colors['flexbase-teal'][0],
    marginRight: 24,
    '@media(max-width: 550px)': {
      svg: {
        marginRight: 0,
      },
    },
  },
  creditImpactNotice: {
    '@media(max-width: 767px)': {
      svg: {
        marginRight: -7,
      },
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 32,
  },
  headerBnpl: {
    cursor: 'default',
    alignItems: 'center',
  },
  banner: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    height: '217px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
  },
  progressRoot: {
    backgroundColor: theme.fn.themeColor('neutral', 1),
  },
}));

export default OnboardingV2;
