import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMantineTheme } from '@mantine/core';

import { stylesPDF } from './styles';
import HeaderPDF from 'components/pdf-viewer/header';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { WireInstructionsModel } from 'services/flexbase/banking.model';

const PDFWireInstructionsViewer = () => {
  const theme = useMantineTheme();
  const { accountId } = useParams();
  const [error, setError] = useState('');
  const [wireInstructions, setWireInstructions] =
    useState<WireInstructionsModel>();

  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    secondaryDark: theme.colors.primarySecondarySuccess[2],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });

  const companyAddress = (address: string) => {
    const parts = address.split(', ');
    if (parts.length < 2) {
      return address;
    }
    const [street, city, stateAndZipCode] = parts;
    const [state, zipCode] = stateAndZipCode.split(' ');

    return `${street}\n${city}, ${state} - ${zipCode}`;
  };

  const getWireInstructions = async (_accountId: string) => {
    try {
      const instructions = await flexbaseBankingClient.getWireInstructions(
        _accountId,
      );
      setWireInstructions(instructions);
    } catch (e) {
      console.error('Unable to load wire instructions for this account.', e);
      setError('Unable to load wire instructions for this account.');
    }
  };

  useEffect(() => {
    if (accountId) {
      getWireInstructions(accountId);
    }
  }, [accountId]);

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document
        title={`${
          wireInstructions?.beneficiaryName
        } ••${wireInstructions?.beneficiaryAccountNumber?.slice(-4)}`}
      >
        <Page size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>
          <View fixed style={styles.containerHeader}>
            <View>
              <Text style={{ ...styles.textHeader, fontStyle: 'bold' }}>
                Flex Debit Card
              </Text>
              <Text style={styles.textHeader}>
                {wireInstructions?.beneficiaryName}
              </Text>
              <Text style={{ ...styles.textHeader, textAlign: 'right' }}>
                {companyAddress(wireInstructions?.beneficiaryAddress || '')}
              </Text>
            </View>
          </View>

          <View style={styles.containerPage}>
            <View>
              <Text style={{ ...styles.subText, fontStyle: 'thin' }}>
                Let’s flex this
              </Text>
              <Text style={{ ...styles.title }}>Domestic Transfer Details</Text>
              <Text style={{ ...styles.subText, fontStyle: 'thin' }}>
                {`Use these details to send domestic wires to ${wireInstructions?.beneficiaryName}’s Flex account.`}
              </Text>
            </View>

            {error ? (
              <View style={{ ...styles.card, marginTop: 25 }}>
                <Text style={styles.title}>{error}</Text>
              </View>
            ) : (
              <>
                <View style={{ ...styles.card, marginTop: 25 }}>
                  <Text style={styles.greenText}>Beneficiary</Text>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Account Name</Text>
                    <Text style={styles.textInfo}>
                      {wireInstructions?.beneficiaryName}
                    </Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Type of Account</Text>
                    <Text style={styles.textInfo}>Checking</Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Account Address</Text>
                    <Text style={styles.textInfo}>
                      {wireInstructions?.beneficiaryAddress}
                    </Text>
                  </View>
                </View>

                <View style={{ ...styles.card, marginTop: 25 }}>
                  <Text style={styles.greenText}>Bank Details</Text>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Account Number</Text>
                    <Text style={styles.textInfo}>
                      {wireInstructions?.beneficiaryAccountNumber}
                    </Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Routing Number</Text>
                    <Text style={styles.textInfo}>
                      {wireInstructions?.receiverABA}
                    </Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Bank Name</Text>
                    <View>
                      <Text style={styles.textInfo}>
                        {wireInstructions?.beneficiaryBankName}
                      </Text>
                      <Text style={{ ...styles.smallText, fontStyle: 'thin' }}>
                        Flex uses Thread Bank as a banking partner
                      </Text>
                    </View>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Bank Address</Text>
                    <Text style={styles.textInfo}>
                      {wireInstructions?.beneficiaryBankAddress}
                    </Text>
                  </View>
                </View>
              </>
            )}
          </View>

          <View style={styles.footerContainer} fixed>
            <Text>Banking services provided by Thread Bank, Member FDIC.</Text>
            <Text>
              Apply for an account in minutes at{' '}
              <Text style={styles.smallGreenText}>flex.one</Text>
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFWireInstructionsViewer;
