import { Button, Card, Image, Text, Tooltip } from '@mantine/core';
import { useStyles } from './styles';
import { cardsIntegrations } from './list-integrations';
import { useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useRecoilState } from 'recoil';
import { QbooksCompanyState } from 'states/qbooks/qbooks-company';
import AlertWithLink from 'components/alerts/alert-with-link';

const Integrations = () => {
  const { classes } = useStyles();
  const [qbCompanyLoading, setQbCompanyLoading] = useState(false);
  const [qbCompany, setQbCompany] = useRecoilState(QbooksCompanyState);
  const hasCompany = !!qbCompany.CompanyInfo?.CompanyName;

  const getQbooksCompany = async () => {
    setQbCompanyLoading(true);
    try {
      const qbCompanyResponse =
        await flexbaseOnboardingClient.getQbooksCompany();
      setQbCompany(qbCompanyResponse);
    } catch (e) {
      console.error(e);
    } finally {
      setQbCompanyLoading(false);
    }
  };

  const onQbooksLoginRedirect = (e: StorageEvent) => {
    const { key, newValue } = e;
    if (key === 'qbloginredirect' && newValue === 'true' && !hasCompany) {
      getQbooksCompany();
    }
  };

  useEffect(() => {
    localStorage?.setItem('qbloginredirect', 'false');
    if (!hasCompany) {
      getQbooksCompany();
    }
    window.addEventListener('storage', onQbooksLoginRedirect);

    return () => {
      window.removeEventListener('storage', onQbooksLoginRedirect);
    };
  }, []);

  return (
    <div>
      <AlertWithLink
        withCloseButton={false}
        message="Stay tuned for some amazing upcoming integrations!"
      />
      <div style={{ marginTop: '1rem' }}>
        {cardsIntegrations.map((integration, idx) => {
          return (
            <Card
              key={(integration.cardName, idx)}
              className={classes.card}
              style={integration.active ? {} : { opacity: 0.5 }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: '0.5rem',
                }}
              >
                <Image src={integration.icon} width={50} height={50} />
                <div style={{ marginLeft: '1rem' }}>
                  <Text className={classes.title}>{integration.cardName}</Text>
                  <Text className={classes.integrationsLabel}>
                    {integration.content}
                  </Text>
                </div>
              </div>
              <Tooltip label="Coming soon" disabled={integration.active}>
                {integration.actionButton ? (
                  integration.actionButton(hasCompany, qbCompanyLoading)
                ) : (
                  <Button
                    className={classes.btnConnect}
                    disabled={!integration.active}
                  >
                    <Text className={classes.connectText}>Connect</Text>
                  </Button>
                )}
              </Tooltip>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default Integrations;
