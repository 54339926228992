import {
  Center,
  Loader,
  Progress,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useSetRecoilState } from 'recoil';
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  PlaidAccount,
  DepositAccount,
  AUTHORIZATIONS,
} from './move-funds.model';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { useStyles } from '../styles';
import { CloseIcon, FlexIcon } from 'assets/svg';
import Stepper from 'components/stepper/stepper';
import useModal from 'components/modal/modal-hook';
import TransferReview from './step-2-transfer-review';
import TransferDetails from './step-1-transfer-details';
import { Analytics } from 'services/analytics/analytics';
import TransferError from '../components/modal-error/modal-error';
import { DepositAccountsState } from '../states/deposit-accounts';
import { formatCurrency } from 'utilities/formatters/format-currency';
import TransferSuccess from '../components/modal-success/modal-success';
import { PaymentForm, paymentFormInitial } from 'states/banking/payments';
import { usePlaidBankingComponent } from 'components/banking/plaid-banking-component';

export enum MoveFundsSteps {
  TRANSFER_DETAILS,
  TRANSFER_REVIEW,
  SUCCESS,
  LINK_ACCOUNT,
  LOADING,
  ERROR,
}

const MoveFunds = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { closeAllModals } = useModal();
  const isMobile = useMediaQuery('(max-width: 767px)');

  // get account from location state
  const navigate = useNavigate();
  const { state } = useLocation();
  const initPlaidAccount =
    state?.account.plaidOrDeposit === 'plaid' ? state?.account : undefined;
  const initDepositAccount =
    state?.account.plaidOrDeposit === 'deposit' ? state?.account : undefined;

  // move funds states
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(MoveFundsSteps.LOADING);
  const [needsLinkAccount, setNeedsLinkAccount] = useState(false);
  const [bankingAccounts, setBankingAccounts] = useState<DepositAccount[]>([]);
  const [plaidLinkedAccounts, setPlaidLinkedAccounts] = useState<
    PlaidAccount[]
  >([]);
  const [transferForm, setTransferForm] =
    useState<PaymentForm>(paymentFormInitial);
  const setDepositAccountsList = useSetRecoilState(DepositAccountsState);

  // initialize deposit and Plaid accounts
  const init = async () => {
    try {
      const [depositAccounts, plaidAccounts] = await Promise.all([
        flexbaseBankingClient.getDepositList(),
        flexbaseOnboardingClient.getPlaidCompanyAccounts(),
      ]);

      // set up deposit accounts list
      if (depositAccounts.success) {
        const activeAccounts = depositAccounts.accounts.filter(
          (acc) => acc.status === 'Open',
        );
        const accountsData: DepositAccount[] = activeAccounts.map((account) => {
          return { ...account, plaidOrDeposit: 'deposit' } as DepositAccount;
        });

        setBankingAccounts(accountsData);
      }

      // set up plaid accounts list
      if (
        plaidAccounts?.accounts?.length &&
        plaidAccounts?.accounts?.length > 0
      ) {
        const plaidLinkedAccounts = plaidAccounts.accounts?.filter(
          (acc) => !acc.unlinked,
        );
        const plaidAccountsData: PlaidAccount[] = plaidLinkedAccounts.map(
          (account) => {
            return { ...account, plaidOrDeposit: 'plaid' } as PlaidAccount;
          },
        );
        setPlaidLinkedAccounts(plaidAccountsData);
        setStep(MoveFundsSteps.TRANSFER_DETAILS);
      } else {
        setNeedsLinkAccount(true);
        setStep(MoveFundsSteps.LINK_ACCOUNT);
      }
    } catch (error) {
      console.error('Error getting accounts', error);
      setStep(MoveFundsSteps.ERROR);
      setError(
        "We've encountered an error attempting to get the accounts list. Try again later.",
      );
    }
  };

  const makePayment = async () => {
    try {
      setLoading(true);
      const transferFormToAccount = transferForm.toAccount as
        | PlaidAccount
        | DepositAccount
        | undefined;

      let result: any;

      if (transferForm.fromAccount?.plaidOrDeposit === 'plaid') {
        // fund banking account with plaid external account
        result = await flexbaseBankingClient.makePayment({
          type: 'ach',
          direction: 'Debit',
          description: `ACHIn${transferForm.fromAccount.last4}`,
          accountId: transferFormToAccount?.id ?? '',
          plaidTokenId: transferForm.fromAccount?.id,
          amount: formatCurrency(transferForm.amount),
          authorizations: [AUTHORIZATIONS.achDebit],
        });
      } else if (
        transferForm.fromAccount?.plaidOrDeposit === 'deposit' &&
        transferForm.toAccount?.plaidOrDeposit === 'deposit'
      ) {
        // book payment, flexbase banking account to account
        result = await flexbaseBankingClient.makePayment({
          type: 'book',
          description: 'account to account transfer',
          direction: 'Credit',
          accountIdTo: transferForm.toAccount?.id,
          accountId: transferForm.fromAccount?.id,
          amount: formatCurrency(transferForm.amount),
        });
      } else {
        if (
          !!transferFormToAccount?.id &&
          transferForm.fromAccount?.plaidOrDeposit === 'deposit'
        ) {
          // move money from flexbase banking to plaid external account
          result = await flexbaseBankingClient.makePayment({
            type: 'ach',
            direction: 'Credit',
            description: 'Disburse',
            accountId: transferForm.fromAccount.id,
            plaidTokenId: transferFormToAccount?.id,
            amount: formatCurrency(transferForm.amount),
            authorizations: [AUTHORIZATIONS.default],
          });
        }
      }

      if (result?.success) {
        setTransferForm({
          ...transferForm,
          status: result.payment?.status,
          reason: result.payment?.reason,
          id: result.payment?.id,
        });
        setDepositAccountsList((prevData) => {
          // update balances
          return prevData.map((acc) => {
            if (acc.id === result.payment?.depositIdTo) {
              return {
                ...acc,
                balance: acc.balance + Number(result.payment?.payAmountCents),
              };
            }
            if (acc.id === result.payment?.depositId) {
              return {
                ...acc,
                balance: acc.balance - Number(result.payment?.payAmountCents),
              };
            }
            return acc;
          });
        });
        setStep(MoveFundsSteps.SUCCESS);
      } else {
        if ((result.error as string).includes('expired')) {
          setError(
            'Your Plaid account connection has expired, please relink plaid by logging out and logging back in again.',
          );
        } else {
          setError('Unable to make the payment. Please, try it again');
        }
        setStep(MoveFundsSteps.ERROR);
      }
      Analytics.track('Banking Payment Submitted', {
        ...result,
      });
    } catch (error) {
      console.error('Unable to make the payment', error);
      const { payment = {}, error: errorMsg = '' } = JSON.parse(error.message);

      if (payment.status === 'Rejected' && payment.reason) {
        setTransferForm({
          ...transferForm,
          status: payment.status,
          reason: payment.reason,
        });
        setStep(MoveFundsSteps.SUCCESS);
      } else if (errorMsg.includes('expired')) {
        setError(
          'Your Plaid account connection has expired, please relink plaid by logging out and logging back in again.',
        );
      } else {
        setError('Unable to make the payment. Please, try it again');
      }
      setStep(MoveFundsSteps.ERROR);
    } finally {
      setLoading(false);
    }
  };

  // plaid logic when there are no external accounts yet
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => {
      closeModal();
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to link bank account',
      });
    },
    setLoading: (loading) => setLoading(loading),
  });

  const onLinkSuccess = async () => {
    setLoading(true);
    setNeedsLinkAccount(false);
    await init();
    setLoading(false);
  };

  useEffect(() => {
    if (needsLinkAccount && ready) {
      open();
    }
  }, [needsLinkAccount, ready]);

  // generate the contents of this flow based on step state
  const getContents = () => {
    switch (step) {
      case MoveFundsSteps.TRANSFER_DETAILS:
        return (
          <TransferDetails
            form={transferForm}
            onBackClick={closeModal}
            onContinue={(values: PaymentForm) => {
              setTransferForm({ ...transferForm, ...values });
              setStep(MoveFundsSteps.TRANSFER_REVIEW);
            }}
            {...{
              bankingAccounts,
              initPlaidAccount,
              initDepositAccount,
              plaidLinkedAccounts,
            }}
          />
        );

      case MoveFundsSteps.TRANSFER_REVIEW:
        return (
          <TransferReview
            form={transferForm}
            onContinue={makePayment}
            loading={loading}
            onBackClick={() => setStep(MoveFundsSteps.TRANSFER_DETAILS)}
          />
        );

      case MoveFundsSteps.SUCCESS:
        return (
          <TransferSuccess
            backTo="dashboard"
            onClick={() => reset()}
            closeModal={closeModal}
            title={`You’ve transferred ${formatCurrency(
              transferForm.amount,
            )} to ${
              transferForm.toAccount?.plaidOrDeposit === 'deposit'
                ? transferForm.toAccount.nickName
                : transferForm.toAccount?.plaidOrDeposit === 'plaid'
                ? transferForm.toAccount?.bankName
                : transferForm.toAccount?.name
            } ${
              transferForm.toAccount?.plaidOrDeposit === 'deposit' ||
              transferForm.toAccount?.plaidOrDeposit === 'admDeposit'
                ? transferForm.toAccount?.accountNumber
                : transferForm.toAccount?.account
            }`}
            textToStart="Make another transfer"
            status={transferForm.status}
            reason={transferForm.reason}
          />
        );

      case MoveFundsSteps.LINK_ACCOUNT:
        return <div />;

      case MoveFundsSteps.LOADING:
        return (
          <Center style={{ height: '100vh' }}>
            <Loader color={theme.fn.primaryColor()} size={50} />
          </Center>
        );

      case MoveFundsSteps.ERROR:
        return <TransferError errorMessage={error} onGoBack={closeModal} />;
    }
  };

  const reset = () => {
    setTransferForm(paymentFormInitial);
    setStep(MoveFundsSteps.TRANSFER_DETAILS);
  };

  const closeModal = () => {
    closeAllModals();
    reset();
    navigate(-1);
  };

  useEffect(() => {
    init();
  }, []);

  if (isMobile) {
    return (
      <div className={classes.styleTest}>
        <>
          {step < MoveFundsSteps.SUCCESS && (
            <Progress
              radius="xs"
              value={step === MoveFundsSteps.TRANSFER_DETAILS ? 50 : 100}
            />
          )}
          <UnstyledButton
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '15px 15px 0px 0px',
            }}
            onClick={closeModal}
          >
            <CloseIcon color="#979797" />
          </UnstyledButton>
        </>
        <div className="content">{getContents()}</div>
      </div>
    );
  }

  return (
    <div className={classes.moveMovementsContent}>
      <div>
        <FlexIcon width={90} />
        {step < MoveFundsSteps.SUCCESS && (
          <Stepper activeStep={step} stepsData={['Details', 'Review']} />
        )}
      </div>

      <div className="content">{getContents()}</div>

      <div className="close">
        <UnstyledButton onClick={closeModal}>
          <CloseIcon color={theme.fn.themeColor('neutral', 5)} />
        </UnstyledButton>
      </div>
    </div>
  );
};

export default MoveFunds;
