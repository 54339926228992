import { Text, TextInput } from '@mantine/core';
import { useStyles } from './styles';
import { CreditShield, ProtectSSN } from 'assets/svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VerifyIdentityFormState } from '../../onboarding-form.state';
import { validatePersonalTaxId } from 'utilities/validators/validate-tax-id';
import { validateUSBirthDate } from 'utilities/validators/validate-dates';
import { useForm } from '@mantine/form';
import { omit } from 'underscore';
import { useProductOnboarding } from '../../onboarding-hooks';
import FlexbaseInput from 'components/input/flexbase-input';
import { useOnboardingStyles } from '../../onboarding.styles';
import { formatPersonalTaxId } from 'utilities/formatters/format-tax-id';
import OnboardingStep from '../../components/onboarding-step';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useState } from 'react';
import {
  formatDateForApi,
  formatDateInput,
} from 'utilities/formatters/format-date-input';
import { ApplicationState } from 'states/application/product-onboarding';
import TagManager from 'react-gtm-module';
import YellowBanner from 'areas/onboarding-v2/components/yellow-banner';

export type VerifyIdentityFormValues = {
  birthDate: string;
  taxId: string;
};
const VerifyIdentity = () => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [formState] = useRecoilState(VerifyIdentityFormState); // TODO: Figure out why I removed calls to set this
  const { navigateToNextProductStep, goBack } = useProductOnboarding();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { userIsApplicant, product } = useRecoilValue(ApplicationState);
  const accordionList = [
    {
      image: <ProtectSSN />,
      label: 'Protect SSN',
      description: 'We protect your SSN',
      text: `Your encrypted personal data is only used for ID verification${
        userIsApplicant ? ' and credit checks.' : '.'
      }`,
    },

    {
      image: <CreditShield />,
      label: userIsApplicant ? 'Credit Impact' : 'Identity',
      description: userIsApplicant ? '' : 'Identity Verification',
      text:
        userIsApplicant && (product === 'CREDIT' || product === 'ALL')
          ? 'Flexbase uses your SSN to verify your identity and pre-qualify you for credit. We do a soft pull on your credit report which does not impact your credit score. Feel free to contact support with concerns.'
          : 'Flexbase uses your SSN to verify your identity.',
    },
  ];

  const verifyIdentityForm = useForm<VerifyIdentityFormValues>({
    initialValues: { ...formState },
    validate: {
      taxId: (val) => {
        // This is a "hack" to ignore the masked value that the API returns
        if (val.includes('*')) {
          return null;
        }
        return validatePersonalTaxId(val) ? null : 'Invalid SSN';
      },
      birthDate: (val) =>
        validateUSBirthDate(val)
          ? null
          : 'Must be at least 18 in format MM/DD/YYYY.',
    },
  });

  const setPersonalTaxId = (value: string) =>
    verifyIdentityForm.setFieldValue('taxId', formatPersonalTaxId(value));

  const setDob = (value: string) => {
    verifyIdentityForm.setFieldValue('birthDate', formatDateInput(value));
  };

  const onContinueClick = async () => {
    const formValues = verifyIdentityForm.values;
    const validationResult = verifyIdentityForm.validate();
    if (!validationResult.hasErrors) {
      setLoading(true);
      try {
        const request = {
          birthDate: formatDateForApi(formValues.birthDate),
          taxId: formValues.taxId,
        };
        await flexbaseOnboardingClient.updateUser({
          ...(formValues.taxId.includes('*')
            ? omit(request, 'taxId')
            : request),
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'verifiedIdentity',
          },
        });
        await navigateToNextProductStep();
      } catch (e) {
        if (
          e?.text &&
          typeof e.text === 'string' &&
          e.text.toLowerCase().includes('already in use')
        ) {
          verifyIdentityForm.setFieldError(
            'taxId',
            'You already have an account with us. Please contact us at support@flex.one',
          );
        } else {
          setErrorMessage(
            'Unable to update identity data. Please check for correctness.',
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const onBackClick = () => {
    goBack();
  };

  return (
    <div>
      <OnboardingStep
        title="Verify identity"
        subtitle={<>We are required by law to collect this information.</>}
        onBackClick={onBackClick}
        onNextClick={onContinueClick}
        stepId="verify-identity"
        error={errorMessage}
        showContinueSpinner={loading}
        bottomDisclosureText="To help the government fight the funding of terrorism and money
        laundering activities, federal law requires all financial institutions
        to obtain, verify, and record information that identifies each person
        who opens an account. What this means for you: when you open an account,
        we will ask for your name, address, date of birth, and other information
        that will allow us to identify you. We may also ask to see a copy of
        your driver’s licenses or other identifying documents. By Clicking “Continue”,
        you authorize us and our Partner Banks to obtain consumer credit reports and
        other consumer background data (“Consumer Report”) about the business owner(s)
        and business principal(s) listed in the application (each, a “Consumer Report Subject”)
        from consumer reporting agencies and other third-party sources, and business credit
        reports and other business background information (“Business Report”) from the
        business listed in the application. You represent and warrant to us that you have
        obtained prior written consent from each Consumer Report Subject to permit us
        to obtain such Consumer Report for any lawful purpose, including but not limited
        to: (1) reviewing your Flexbase Technologies Corporate Mastercard application,
        (2) underwriting and monitoring your Flexbase Technologies Corporate Mastercard
        account, and (3) deciding whether to market and provide certain other Flexbase
        products and services to you. In connection with purposes (1) and (2), we may
        share all or parts of your Consumer Report and Business Report with third
        parties, and you authorize us to share for these purposes. We and our Partner
        Banks may elect to report late payments, missed payments, other defaults,
        and other credit-related experiences with Flexbase to consumer reporting
        agencies and such information may appear on the Consumer Report Subject’s
        consumer credit report and may affect the Consumer Report Subject’s personal
        credit score. We and our Partner Banks may also elect to report such information
        to business credit agencies and such information may appear on your business
        credit reports and may impact your business credit scores. "
      >
        <div className={classes.accordionContainer}>
          {accordionList.map((item) => {
            if (product === 'TREASURY' && item.label === 'Credit Impact') {
              return <></>;
            }
            return (
              <>
                {item.description && (
                  <div className={classes.container} key={item.label}>
                    <Text>{item.description}</Text>
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className={classes.inputContainer}>
          {!formState.taxId.includes('*') && (
            <FlexbaseInput
              placeholder="SSN"
              label="Social Security Number"
              classNames={{
                label: onboardingClasses.inputLabel,
                root: onboardingClasses.btnSpacingAddressPage,
              }}
              {...verifyIdentityForm.getInputProps('taxId')}
              onChange={(e) => setPersonalTaxId(e.target.value)}
              id="input-ssn"
            />
          )}
          <TextInput
            classNames={{
              label: onboardingClasses.inputLabel,
              root: onboardingClasses.btnSpacingAddressPage,
            }}
            {...verifyIdentityForm.getInputProps('birthDate')}
            label="Birthday"
            placeholder="MM / DD / YYYY"
            onChange={(e) => setDob(e.target.value)}
            id="input-birthdate"
          />
        </div>
        {userIsApplicant && (product === 'CREDIT' || product === 'ALL') && (
          <YellowBanner text="If you currently have a freeze on your credit, you must remove the Equifax freeze before submitting your application. We will not be able to process your application until the Equifax freeze is removed." />
        )}
      </OnboardingStep>
    </div>
  );
};

export default VerifyIdentity;
