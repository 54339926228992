import { Button } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';
import useModal from 'components/modal/modal-hook';
import { IProjectInfo } from 'states/projects/project-info.interface';
import ModalForm from '../modal-form/modal-form';

interface ItableRow {
  project: IProjectInfo;
  setAllProjects: Dispatch<SetStateAction<IProjectInfo[]>>;
}

const TableRow = ({ project, setAllProjects }: ItableRow) => {
  const modal = useModal();
  const closeModal = modal.closeAllModals;

  const openModalForm = () => {
    screen.width <= 767
      ? modal.openCenterModal(
          <ModalForm {...{ closeModal, project, setAllProjects }} />,
        )
      : modal.openRightModal(
          <ModalForm {...{ closeModal, project, setAllProjects }} />,
        );
  };

  return (
    <>
      <tr key={project.id}>
        <td data-title="Project">{project.name}</td>
        <td data-title="Description">{project?.description}</td>
        <td data-title="Address">
          {project?.street1}, {project?.state}
        </td>
        <td>
          <Button
            onClick={() => openModalForm()}
            className="btnEditProject"
            radius={8}
            id="edit-project"
            variant="outline"
            aria-label="edit-project"
            color="gray"
            size="md"
          >
            Edit Project
          </Button>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
