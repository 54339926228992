import {
  OnboardingCompany,
  OnboardingStatus,
  OnboardingUser,
} from './onboarding-info';
import { DateTime } from 'luxon';
import { formatPhoneForApi } from 'utilities/formatters/format-phone-number';

export interface StripeAddress {
  line1: string;
  line2: string;
  postalCode: string;
  city: string;
  state: string;
}

export interface StripeAccount {
  businessType: string;
  businessProfile: {
    mcc: string;
    url: string;
  };
  tosAcceptance?: {
    accepted: boolean;
  };
}

export interface StripeCompany {
  name: string;
  address: StripeAddress;
  phone: string;
  taxId: string;
  ownersProvided?: boolean;
}

export interface StripePerson {
  id?: string;
  idNumber: string;
  firstName: string;
  lastName: string;
  dob: {
    day: number;
    month: number;
    year: number;
  };
  address: StripeAddress;
  email: string;
  phone: string;
  ssnLast_4: string;
  relationship: {
    title: string;
    owner: boolean;
    representative: boolean;
  };
}

export interface StripeExternalAccount {
  accountNumber: string;
  routingNumber: string;
}

export interface StripeDetails {
  account?: StripeAccount;
  company?: StripeCompany;
  person?: StripePerson;
  externalAccount?: StripeExternalAccount;
}

export const composeStripeRequestFromUser = (
  user: Partial<OnboardingUser>,
  userIsOwner: boolean,
): StripeDetails => {
  const userBirthDateTime = user.birthDate
    ? DateTime.fromFormat(user.birthDate, 'yyyy-MM-dd')
    : null;

  return {
    person: {
      idNumber: user.taxId || '',
      address: {
        line1: user.address?.line1 || '',
        line2: user.address?.line2 || '',
        city: user.address?.city || '',
        state: user.address?.state || '',
        postalCode: user.address?.postalCode || '',
      },
      dob: {
        day: userBirthDateTime?.day || 0, // All this to make TS happy
        month: userBirthDateTime?.month || 0,
        year: userBirthDateTime?.year || 0,
      },
      email: user.email || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      phone: formatPhoneForApi(user.phone || ''),
      relationship: {
        title: user.jobTitle || '',
        owner: true,
        representative: true,
      },
      ssnLast_4: user.taxId?.slice(-4) || '',
    },
  };
};

export const composeStripeRequestFromCompany = (
  company: Partial<OnboardingCompany>,
  allOwnersProvided = false,
  acceptTos = false,
): StripeDetails => {
  const stripeDetails: StripeDetails = {
    account: {
      businessType: 'company',
      businessProfile: {
        mcc: company.category || '',
        url: company.website || '',
      },
    },
    company: {
      name: company.companyName || '',
      address: {
        line1: company.address?.line1 || '',
        line2: company.address?.line2 || '',
        city: company.address?.city || '',
        state: company.address?.state || '',
        postalCode: company.address?.postalCode || '',
      },
      phone: formatPhoneForApi(company.phone || ''),
      taxId: company.taxId || '',
    },
  };

  if (allOwnersProvided) {
    stripeDetails.company!.ownersProvided = true;
  }

  if (company.financialInstitutions?.length) {
    stripeDetails.externalAccount = {
      accountNumber: company.financialInstitutions?.[0]?.account || '',
      routingNumber: company.financialInstitutions?.[0]?.routing || '',
    };
  }

  if (acceptTos) {
    stripeDetails.account!.tosAcceptance = {
      accepted: true,
    };
  }

  return stripeDetails;
};

export const composeStripeRequest = (
  onboardingInfo: OnboardingStatus,
  acceptTos: boolean,
  ownersProvided: boolean,
  includePerson: boolean,
  includeAccount: boolean,
): StripeDetails => {
  const { company, user } = onboardingInfo;

  let stripeDetails: StripeDetails = {};

  if (includeAccount) {
    stripeDetails = {
      ...stripeDetails,
      ...composeStripeRequestFromCompany(company, ownersProvided, acceptTos),
    };
  }

  if (includePerson) {
    const userIsOwner = true;
    stripeDetails = {
      ...stripeDetails,
      ...composeStripeRequestFromUser(user, userIsOwner),
    };
  }

  return stripeDetails;
};
