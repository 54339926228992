import { CreditBankingForm } from './credit-banking-form';

export const CreditBankingContentSm = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CreditBankingForm />
    </div>
  );
};
