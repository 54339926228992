import ReactPixel from 'react-facebook-pixel';
// add more actions if needed
/**
 * ReactPixel.pageView(); // For tracking page view
 * ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
 * ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
 * ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
 * ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
 */
const actions = {
  init: () => {
    // const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: import.meta.env.VITE_APP_PIXEL_DEBUG ? true : false, // enable logs
    };
    console.log(
      `initializing fbook pixel ${import.meta.env.VITE_APP_PIXEL_ID}`,
    );
    ReactPixel.init(
      import.meta.env.VITE_APP_PIXEL_ID || '',
      undefined,
      options,
    );
  },
  pageView: () => {
    ReactPixel.pageView();
  },
  track: (event: string, data: any) => {
    ReactPixel.track(event, data);
  },
  trackCustom: (event: string, data: any) => {
    ReactPixel.trackCustom(event, data);
  },
};

export const FacebookPixel = actions;
