import React, { forwardRef, useState } from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { createStyles, TextInput, TextInputStylesNames } from '@mantine/core';

type ClassNames = Record<TextInputStylesNames, string> & { container: string };

type Props = {
  loading?: boolean;
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: string) => void;
  label: string;
  classNames?: Partial<ClassNames>;
  className?: any;
  onItemSubmit: (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => void;
} & React.RefAttributes<HTMLInputElement>;

export const useStyles = createStyles((theme) => ({
  container: {
    img: {
      display: 'none',
    },
  },
  selectList: {
    padding: 'calc(0.75rem / 1.5) 0.75rem',
    background: 'white',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      background: theme.fn.themeColor('primarySecondarySuccess', 0),
    },
  },
}));

const GooglePlacesSuggest = forwardRef<HTMLInputElement, Props>(
  (
    {
      loading,
      id,
      placeholder,
      value,
      onChange,
      label,
      onItemSubmit,
      classNames,
      className,
      ...props
    }: Props,
    ref,
  ) => {
    const { classes, cx } = useStyles();
    const [search, setSearch] = useState('');
    const [sessionToken, setSessionToken] = useState<any>(null);
    const handleInputChange = (e: any) => {
      setSearch(e.target.value);
      onChange(e.target.value);
    };

    const handleSelectSuggest = (geocodedPrediction: any) => {
      let address = '';
      let country = '';
      let state = '';
      let city = '';
      let postalCode = '';
      // Iterate through the address components to extract the necessary information.
      geocodedPrediction.address_components.forEach((component: any) => {
        if (component.types.includes('country')) {
          country = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('street_number')) {
          address = component.long_name;
        } else if (
          component.types.some((type: string) =>
            ['street_address', 'route', 'premise'].includes(type),
          )
        ) {
          address = address
            ? `${address} ${component.long_name}`
            : component.long_name;
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        } else if (component.types.includes('postal_code_suffix')) {
          postalCode = `${postalCode}-${component.long_name}`;
        }
      });
      onItemSubmit({ address, country, city, postalCode, state });
      onChange(address);
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
      setSearch('');
    };

    return (
      <div className={cx(classes.container, classNames?.container)}>
        <ReactGoogleMapLoader
          params={{
            key: import.meta.env.VITE_APP_GOOGLE_API_KEY,
            libraries: 'places,geocode',
          }}
          render={(googleMaps: any) =>
            googleMaps && (
              <ReactGooglePlacesSuggest
                googleMaps={googleMaps}
                autocompletionRequest={{
                  input: search,
                  componentRestrictions: { country: 'US' },
                  sessionToken,
                }}
                customRender={(prediction) =>
                  prediction ? (
                    <div className={classes.selectList}>
                      {prediction.description}
                    </div>
                  ) : (
                    <div className={classes.selectList}>No results found</div>
                  )
                }
                onSelectSuggest={handleSelectSuggest}
              >
                <TextInput
                  label={label}
                  ref={ref}
                  id={id}
                  classNames={classNames}
                  className={className}
                  type="text"
                  value={value}
                  placeholder={placeholder}
                  onChange={(e) => handleInputChange(e)}
                  {...props}
                />
              </ReactGooglePlacesSuggest>
            )
          }
        />
      </div>
    );
  },
);

GooglePlacesSuggest.displayName = 'GooglePlacesSuggest';

export default GooglePlacesSuggest;
