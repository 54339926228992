import { useAuthToken } from '../../states/auth/auth-token';

/**
 * Note: onLogout may not function correctly if refresh is enabled
 */
type LogoutParams = { withRefresh: boolean; onLogout: () => void };

// TODO: Remove this
export const useLogout = () => {
  const { logout } = useAuthToken();

  return async (params: Partial<LogoutParams> = { withRefresh: true }) => {
    await logout();

    if (params.onLogout) {
      params.onLogout();
    }
  };
};
