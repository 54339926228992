import { Button, UnstyledButton, Text, useMantineTheme } from '@mantine/core';
import { BsArrowRight } from 'react-icons/bs';
import { FiCheck } from 'react-icons/fi';
import { useStyles } from './modal-success.styles';

interface Props {
  title: string;
  backTo: string;
  description?: string;
  textToStart?: string;
  onClick?: () => void;
  closeModal: () => void;
}

const ModalSuccess = ({
  title,
  closeModal,
  onClick,
  backTo,
  description,
  textToStart,
}: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  return (
    <div>
      <div className={classes.modalSuccess}>
        <div
          className="check_icon"
          style={{ backgroundColor: 'rgba(39, 194, 129, 0.1)' }}
        >
          <FiCheck color="#27C281" size={38} />
        </div>

        <Text size={28} color="#000" mt={12} ff="PP Neue Montreal">
          {title}
        </Text>
        {description && (
          <div style={{ textAlign: 'center' }}>{description}</div>
        )}
        <Button
          className="back_button"
          radius="md"
          onClick={() => closeModal()}
          color={theme.fn.primaryColor()}
        >
          Go back to {backTo}
        </Button>

        {textToStart && (
          <UnstyledButton className="new_item" onClick={onClick}>
            {textToStart} <BsArrowRight color={theme.fn.primaryColor()} />
          </UnstyledButton>
        )}
      </div>
    </div>
  );
};
export default ModalSuccess;
