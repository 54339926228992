import { Grid, Space, Text, useMantineTheme } from '@mantine/core';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { Transaction } from 'services/flexbase/banking.model';
import { DateTime } from 'luxon';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useNavigate } from 'react-router-dom';
import { CardBase } from '@flexbase-eng/web-components';
import { useStyles } from './styles';
import { useMediaQuery } from '@mantine/hooks';

const RecentTransactionsWidget = () => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery('(max-width: 1000px)');
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { classes } = useStyles();
  /**
   * Get banking Transactions
   */
  const getBankingTransactions = async () => {
    try {
      setLoading(true);
      const bankingTransactions = await flexbaseBankingClient.getTransactions();
      if (bankingTransactions?.transactions) {
        const top3LatestTransactions = [...bankingTransactions.transactions]
          .sort((a, b) => {
            return (
              DateTime.fromSQL(b.createdAt).toMillis() -
              DateTime.fromSQL(a.createdAt).toMillis()
            );
          })
          .slice(0, 3);
        setTransactions(top3LatestTransactions);
      }
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to retrieve banking accounts at this time.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBankingTransactions();
  }, []);
  return (
    <CardBase
      className={classes.transactionWidget}
      height={useMobileView ? 'auto' : 200}
    >
      <Text size={16} color="#5F5F5F" weight={500}>
        Recent Transactions
      </Text>
      <Space h="md" />
      <Grid style={{ color: '#5F5F5F' }}>
        {transactions.map((transaction) => {
          return (
            <>
              <Grid.Col
                sm={4}
                md={3}
                span={useMobileView ? 3 : 2}
                key={transaction.id}
              >
                <Text size={12} key={`${transaction.id}-created-at`}>
                  {DateTime.fromSQL(transaction.createdAt).toFormat('LLL dd')}
                </Text>
              </Grid.Col>
              <Grid.Col sm={4} md={7} span={useMobileView ? 5 : 7}>
                <Text size={12} key={`${transaction.id}-summary`}>
                  {transaction.summary.length > 25
                    ? transaction.summary.slice(0, 25) + '..'
                    : transaction.summary}
                </Text>
              </Grid.Col>
              <Grid.Col
                sm={4}
                md={2}
                span={useMobileView ? 4 : 3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color:
                    transaction.direction == 'Debit' ? '#5F5F5F' : '#27C281',
                }}
              >
                <Text size={12} key={`${transaction.id}-amount`}>
                  {transaction.direction === 'Debit' ? '-' : ''}
                  {formatCurrency(Number(transaction.amount) / 100)}
                </Text>
              </Grid.Col>
            </>
          );
        })}
        <Grid.Col
          span={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Text
            size={12}
            weight={500}
            onClick={() => {
              navigate('/banking/transactions');
            }}
            style={{
              cursor: 'pointer',
              color: theme.fn.themeColor('primarySecondarySuccess', 2),
            }}
          >
            View more
          </Text>
        </Grid.Col>
      </Grid>
    </CardBase>
  );
};

export default RecentTransactionsWidget;
