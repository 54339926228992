import { LoadingOverlay, Text } from '@mantine/core';
import FlexbaseHeader from '../../components/header/flex-pay/flexbase-header';
import { useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from '../../services/flexbase-client';
import type { TermsOfServiceDoc } from 'services/flexbase/flexbase-onboarding-client';
import { PdfIcon } from '../../assets/svg';
import { useStyles } from './styles';

const LegalPage = () => {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState<TermsOfServiceDoc[]>([]);
  const { classes } = useStyles();

  useEffect(() => {
    getTerms();
  }, []);

  const getTerms = async () => {
    setLoading(true);
    try {
      const result = await flexbaseOnboardingClient.getTermsOfServiceDocs();
      setDocuments(
        result.filter((t) => t.type === 'credit' || t.type === 'flexbase'),
      );
    } catch (e) {
      // Do something here at some point.
      console.log('no terms');
    } finally {
      setLoading(false);
    }
  };

  // group the documents by terms-of-service type
  const tableOfContents = documents.reduce((grouped, document) => {
    const type = document.type[0].toUpperCase() + document.type.slice(1);
    grouped[type] = [...(grouped[type] ?? []), document];
    return grouped;
  }, {} as Record<string, TermsOfServiceDoc[]>);

  const titleCase = (str: string) => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  };

  // convert each group of documents (by type) into a header + unordered list
  const contents = Object.entries(tableOfContents).map(([type, documents]) => (
    <div key={type}>
      <Text color={'#5F5F5F'} className={classes.agreementTypeText}>
        {type} Agreements
      </Text>
      <div className={classes.agreementsContainer}>
        {documents.map((document) => (
          <div className={classes.container} key={document.url}>
            <a
              href={document.url}
              target="_blank"
              className={classes.a}
              rel="noreferrer"
            >
              <div className={classes.documentsContainer}>
                <PdfIcon className={classes.pdfIcon} />
                <Text style={{ fontFamily: 'PP Neue Montreal' }}>
                  {titleCase(document.label)}
                </Text>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  ));

  return (
    <>
      <LoadingOverlay visible={loading} />
      <FlexbaseHeader />
      <div style={{ marginTop: 50 }}>
        <ul>{contents}</ul>
      </div>
    </>
  );
};

export default LegalPage;
