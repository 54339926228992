import { approveStyles } from './submitting-screen-styles';
import { Box, Text } from '@mantine/core';
import { ReactNode } from 'react';

type Props = { title: string; description: ReactNode; children: ReactNode };

export const StatusBox = ({ title, description, children }: Props) => {
  const { classes } = approveStyles();

  return (
    <div className={classes.status}>
      <Text className={classes.statusTitle} data-testid="text-status-title">
        {title}
      </Text>
      <Text className={classes.description}>{description}</Text>
      <Box mt="auto">{children}</Box>
    </div>
  );
};
