import { createStyles } from '@mantine/core';

export const useSettingStyles = createStyles(() => ({
  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    '.title': {
      '@media(max-width: 767px)': {
        fontSize: '23px',
      },
      fontFamily: 'PP Neue Montreal',
    },
  },
}));
