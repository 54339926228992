import {
  Button,
  Radio,
  Stack,
  Text,
  Title,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { contactFormStyles } from './contact-form.styles';
import { ChangeEvent, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import FlexbaseInput from 'components/input/flexbase-input';
import * as XLSX from 'xlsx';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';

interface Props {
  closeModal: () => void;
}

const ContactForm = ({ closeModal }: Props) => {
  const theme = useMantineTheme();
  const { classes } = contactFormStyles();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [, setItems] = useState([]);
  const [contactForm] = useState({
    typeBusiness: 'business',
    bussinesName: '',
    contactName: '',
    email: '',
    phone: '',
    address: '',
  });

  const form = useForm({
    initialValues: {
      ...contactForm,
    },
  });

  const readExcel = (file: File) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise
      .then((d: any) => {
        setItems(d);
      })
      .catch((error) => {
        console.error('Error importing data from excel', error);
      });
  };

  const onUploadFileClick = () => {
    fileInputRef.current?.click();
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      readExcel(files[0]);
    }
  };

  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <div className={classes.innerContent}>
        <Stack style={{ maxWidth: 500, width: 'auto' }}>
          <Title className={classes.title}>Add a new contact</Title>

          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            type="file"
            accept=".xlsx"
            onChange={onUpload}
          />

          <Text size="sm" color="#5F5F5F">
            Have multiple contacts to add?{' '}
            <UnstyledButton
              onClick={onUploadFileClick}
              style={{ color: theme.fn.primaryColor() }}
            >
              Import contacts
            </UnstyledButton>
          </Text>

          <Text color="#4B4B4B">Individual or business?</Text>

          <Radio.Group
            size="sm"
            style={{ display: 'flex' }}
            color={theme.fn.primaryColor()}
            aria-label="business-type"
            {...form.getInputProps('typeBusiness')}
          >
            <Radio
              aria-label="individual"
              value="individual"
              label="Individual"
            />
            <Radio
              ml="3rem"
              aria-label="business"
              value="business"
              label="Business"
            />
          </Radio.Group>

          {form.values.typeBusiness === 'business' && (
            <>
              <Text mb="-1vh" color="#4B4B4B">
                Business name *
              </Text>
              <FlexbaseInput
                variant="default"
                radius={8}
                data-testid="business-name"
                placeholder="Enter a valid business name"
                {...form.getInputProps('bussinesName')}
              />
            </>
          )}

          <Text mb="-1vh" color="#4B4B4B">
            Contact&apos;s name
          </Text>
          <FlexbaseInput
            variant="default"
            radius={8}
            data-testid="name"
            placeholder="Enter contacts legal name"
            {...form.getInputProps('contactName')}
          />

          <Text mb="-1vh" color="#4B4B4B">
            Email
          </Text>
          <FlexbaseInput
            variant="default"
            radius={8}
            data-testid="email"
            placeholder="Enter a valid email address"
            {...form.getInputProps('email')}
          />

          <Text mb="-1vh" color="#4B4B4B">
            Phone
          </Text>
          <FlexbaseInput
            variant="default"
            radius={8}
            data-testid="phone"
            placeholder="Enter a phone number"
            {...form.getInputProps('phone')}
          />

          {form.values.typeBusiness === 'business' && (
            <>
              <Text mb="-1vh" color="#4B4B4B">
                Address - More detail hidden{' '}
              </Text>
              <FlexbaseInput
                variant="default"
                radius={8}
                data-testid="address"
                placeholder="Address"
                {...form.getInputProps('address')}
              />
            </>
          )}

          <Button
            size="lg"
            radius={8}
            fullWidth
            color={theme.fn.primaryColor()}
            style={{ height: '40px' }}
          >
            <Text style={{ fontSize: '14px', fontWeight: 500 }}>
              Add {form.values.typeBusiness} contact
            </Text>
          </Button>
        </Stack>
      </div>
    </FullScreenModalContainer>
  );
};

export default ContactForm;
