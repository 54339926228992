import PaymentStep, { getPrettyMethod } from '../payment-step';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useEffect, useState } from 'react';
import { Accordion, createStyles } from '@mantine/core';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { SafeBoxIcon, TrashIcon } from 'assets/svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  moneyMovementAmountDetails,
  paymentMethod,
  paymentRecipientName,
  recipientState,
  selectedCounterparty,
} from './payment.states';
import AccountBox from 'components/account-box/account-box';

type Props = {
  onNextClick: () => void;
  onBackClick: () => void;
};

const PaymentReview = ({ onNextClick, onBackClick }: Props) => {
  const { classes, cx, theme } = useStyles();

  // payment flow state
  const recipientName = useRecoilValue(paymentRecipientName);
  const method = useRecoilValue(paymentMethod);
  const [moneyMovementDetails, setMoneyMovementDetails] = useRecoilState(
    moneyMovementAmountDetails,
  );
  const counterparty = useRecoilValue(selectedCounterparty);
  const recipient = useRecoilValue(recipientState);
  // local state
  const [currencyDollars, setcurrencyDollars] = useState('');
  const [currencyCents, setCurrencyCents] = useState('');

  useEffect(() => {
    const currencyString = formatCurrency(moneyMovementDetails.amount);
    const split = currencyString.split('.');
    setcurrencyDollars(split[0]);
    setCurrencyCents(split[1]);
  }, [moneyMovementDetails.amount]);

  return (
    <PaymentStep
      nextButtonLabel="Send Payment"
      onNextClick={onNextClick}
      onBackClick={onBackClick}
      balloonText={`${getPrettyMethod(method)} to ${recipientName}`}
      data-testid={'payment-method'}
      title={
        <div className={classes.titleLarge}>
          {currencyDollars}
          {'.'}
          <span className={classes.titleSmall}>{currencyCents}</span>
        </div>
      }
    >
      <div>
        <div className={classes.sectionTitle} style={{ marginBottom: 12 }}>
          Recipient&apos;s details
        </div>
        <AccountBox
          icon={<SafeBoxIcon />}
          headerText={recipientName}
          subheaderText={
            <div>
              <div data-testid="routing-number">
                Routing number:{' '}
                {counterparty?.routingNumber || recipient?.routingNumber}
              </div>
              <div data-testid="account-number">
                Account number:{' '}
                {getPaddedAccountMask(
                  counterparty?.accountNumber || recipient?.accountNumber,
                  2,
                )}
              </div>
            </div>
          }
          showBorder
          data-testid={'name'}
        />
      </div>
      <div>
        <div
          className={cx(classes.sectionTitle, classes.marginTop)}
          style={{ marginBottom: 12 }}
        >
          Transferring from
        </div>
        {/*TODO: extract drop down and setting logic into component library.*/}
        <AccountBox
          icon={<SafeBoxIcon />}
          showBorder
          headerText={`${moneyMovementDetails.sourceAccount.name || 'Account'}`}
          subheaderText={`${formatCurrency(
            moneyMovementDetails.sourceAccount.available / 100,
          )} / ${
            moneyMovementDetails.sourceAccount.accountType[0].toUpperCase() +
            moneyMovementDetails.sourceAccount.accountType.slice(1)
          } ${getPaddedAccountMask(
            moneyMovementDetails.sourceAccount.accountNumber,
            2,
          )}`}
          data-testid={'deposit-account'}
        />
      </div>

      <div>
        <div
          className={cx(classes.sectionTitle, classes.marginTop)}
          style={{ marginBottom: 5 }}
        >
          External memo for recipient
        </div>
        <div className={classes.notesSection} data-testid={'memo'}>
          {moneyMovementDetails.memo || 'No memo provided'}
        </div>
      </div>
      <div>
        <div
          className={cx(classes.sectionTitle, classes.marginTop)}
          style={{ marginBottom: 5 }}
        >
          Internal notes
        </div>
        <div className={classes.notesSection} data-testid={'internal-notes'}>
          {moneyMovementDetails.internalNotes || 'No internal notes provided'}
        </div>
      </div>

      {moneyMovementDetails.files && moneyMovementDetails.files.length > 0 && (
        <div>
          <Accordion
            chevronPosition="left"
            defaultValue="attachments"
            classNames={{
              label: classes.sectionTitle,
              content: classes.content,
              control: classes.control,
            }}
          >
            <Accordion.Item value="attachments">
              <Accordion.Control>Attachments</Accordion.Control>
              <Accordion.Panel>
                <div className={classes.filesContainer}>
                  {moneyMovementDetails.files.map((file, i) => (
                    <div key={file.name + i} className={classes.file}>
                      {file.name}
                      <button
                        className={classes.deleteFile}
                        onClick={() => {
                          setMoneyMovementDetails({
                            ...moneyMovementDetails,
                            files: moneyMovementDetails.files!.filter(
                              (f) => f !== file,
                            ),
                          });
                        }}
                      >
                        <TrashIcon width={16} color={theme.fn.primaryColor()} />
                      </button>
                    </div>
                  ))}
                </div>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      <div
        className={`${classes.text} ${classes.disclaimerText}`}
        data-testid={'disclaimer'}
      >
        By clicking Send payment, I authorize Flex to initiate the transaction
        detailed above. Flex is powered by Flexbase Technologies, Inc.
      </div>
    </PaymentStep>
  );
};

const useStyles = createStyles((theme) => ({
  titleLarge: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: '48px',
  },
  titleSmall: {
    fontSize: '24px',
    fontFamily: 'PP Neue Montreal',
  },
  sectionTitle: {
    color: theme.fn.primaryColor(),
    fontWeight: 500,
    fontSize: '14px',
  },
  marginTop: {
    marginTop: 30,
  },
  notesSection: {
    height: 42,
    display: 'flex',
    fontWeight: 400,
    fontSize: '14px',
    padding: '0px 20px',
    alignItems: 'center',
    borderRadius: theme.defaultRadius,
    color: theme.fn.themeColor('neutral', 6),
    backgroundColor: theme.fn.themeColor('neutral', 1),
  },
  text: {
    fontWeight: 400,
    fontSize: '14px',
    color: theme.fn.themeColor('neutral', 6),
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
    gap: '16px',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  disclaimerText: {
    margin: '24px 0',
  },
  box: {
    padding: '24px 0 !important',
  },
  filesContainer: {
    minHeight: '50px',
    display: 'column',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: '40px',
    margin: '8px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    fontSize: '12px',
    fontWeight: 500,
    color: theme.fn.themeColor('neutral', 6),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  deleteFile: {
    all: 'unset',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    marginTop: 20,
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    padding: '0',
  },
}));

export default PaymentReview;
