import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  accounting: {
    display: 'flex',
    flexDirection: 'column',
  },
  widgetContainer: {
    boxSizing: 'border-box',
    background: '#FFFFFF',
    borderRadius: '8px',
    display: 'flex,',
    flexDirection: 'column',
    marginBottom: '18px',
    padding: '26px 30px 26px 30px',
  },
  titleText: {
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '31.2px',
    color: '#000000',
    marginBottom: '10px',
  },
  smallText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#757575',
  },
  orgTitleText: {
    color: '#979797',
    marginBottom: '10px',
  },
  orgText: {
    fontSize: '24px',
    lineHeight: '28.8px',
    marginBottom: '4px',
  },
  lineDivider: {
    boxSizing: 'border-box',
    height: '1px',
    border: 0,
    borderTop: '1px solid #EAEAEA',
    margin: '25px 0 25px 0',
  },
  newRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 105,
    justifyContent: 'flex-start',
    margin: '14px 0 14px 0',
  },
  and: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '40px',
    color: '#000000',
  },
  syncButton: {
    height: '40px',
  },
  cellContainer: {
    justifyContent: 'center',
  },
  cellText: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  cellTextBold: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  cellTextSmall: {
    color: '#979797',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '11px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}));

export default useStyles;

export const customTableStyle = (primaryColor?: string) => {
  return {
    responsiveWrapper: {
      style: {
        overflow: 'visible',
      },
    },
    table: {
      style: {
        borderTop: '1px solid #DEE2E6',
        color: '#5F5F5F',
        fontSize: '14px',
        lineHeight: '21px',
        width: '100%',
      },
    },
    cells: {
      style: {
        color: '#5F5F5F',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        paddingLeft: '8px',
        paddingRight: '8px',
        alignItems: 'center',
      },
    },
    headCells: {
      style: {
        color: 'black',
        fontWeight: 400,
        display: 'flex',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#5F5F5F',
        lineHeight: '21px',
        minHeight: '68px',
        overflow: 'visible',
      },
      stripedStyle: {
        backgroundColor: 'rgba(230, 231, 233, 0.12)',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#FFEEEC',
      span: {
        color: primaryColor,
      },
      svg: {
        fill: primaryColor,
      },
    },
  };
};
