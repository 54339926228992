import { useEffect, useState } from 'react';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import flexbaseClient from 'services/flexbase-client';
import { useRecoilValue } from 'recoil';
import {
  OnSuccessProps,
  PlaidBankingComponentProps,
  PlaidState,
} from 'states/plaid/plaid-state';
import PlaidContext from './plaid-context';
import { showNotification } from '@mantine/notifications';

export const onSuccess = async (data: OnSuccessProps) => {
  showNotification({
    color: 'flexbase-teal',
    title: 'Plaid Link successful!',
    message: 'Plaid is now linked to fund your new Flexbase deposit account.',
  });
  // setTableData(await checkAccounts(id));
};

export const onError = () => {
  showNotification({
    color: 'red',
    title: 'Error linking Plaid',
    message:
      'Plaid linking is unavailable at this time, please try again later!',
  });
};

export const usePlaidBankingComponent = ({
  onSuccess,
  onError,
  setLoading,
}: PlaidBankingComponentProps) => {
  const [token, setToken] = useState<string | null>(null);
  const [isOauth, setIsOauth] = useState(true);
  const plaidState = useRecoilValue(PlaidState);
  const [receivedRedirectUri, setReceivedRedirectUri] = useState('');

  useEffect(() => {
    if (plaidState?.oauth && plaidState?.callbackUri) {
      setIsOauth(true);
      setReceivedRedirectUri(plaidState.callbackUri);
    } else {
      setIsOauth(false);
    }
  }, [plaidState]);

  const _onSuccess: PlaidLinkOnSuccess = (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    setLoading(true);
    PlaidContext.clearPlaidContext();
    flexbaseClient
      .exchangePlaidPublicToken(publicToken, metadata)
      .then((response) => {
        setLoading(false);
        response.success
          ? onSuccess({ metadata, keys: response.response! })
          : onError();
      });
  };

  const initPlaid = async () => {
    if (!token) {
      const linkToken = await flexbaseClient.getPlaidLinkToken();
      setToken(linkToken);
    }
  };

  const _onEvent = (eventName: PlaidLinkStableEvent | string) => {
    if (['ERROR', 'EXIT', 'FAIL_OAUTH'].includes(eventName)) {
      onError();
    }
  };

  const config: PlaidLinkOptions = {
    onSuccess: _onSuccess,
    onEvent: _onEvent,
    token,
    receivedRedirectUri,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    initPlaid();
  }, []);

  useEffect(() => {
    if (isOauth && ready && token) {
      open();
    }
  }, [token, isOauth, ready]);

  return { open, error, ready, isOauth };
};
