import { createStyles, rem } from '@mantine/core';

interface Props {
  preview?: boolean;
  credit?: boolean;
}

export const useStyles = createStyles((theme, { preview, credit }: Props) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      margin: '0px 0px 18px 0px',
    },
  },
  creditPaymentContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    display: 'flex',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    height: '200px',
    width: '100%',
    minWidth: '420px',
    maxWidth: credit ? 'initial' : 420,
    flexDirection: 'column',
    padding: '1.5rem',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
      height: 'fit-content', // For whatever reason, mobile 200px is ignored?
    },
    position: 'relative',
  },
  cardHover: {
    cursor: 'pointer',
    border: `1px solid ${theme.fn.themeColor('neutral', 0)}`,
    '&:hover': {
      border: `1px solid ${theme.fn.primaryColor()}`,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amountDueLabel: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
  amountDue: {
    fontSize: '26px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: 'black',
    marginBottom: '34px',
    fontFamily: 'PP Neue Montreal',
  },
  currentBalance: {
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '8px',
    color: '#5F5F5F',
  },
  daysTilDueContainer: {
    // background: theme.fn.primaryColor(),
    // background: theme.fn.lighten('tertiary', -0.3),
    background: 'rgba(222, 170, 87, 0.30)',
    width: '119px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: rem(2),
  },
  daysTilDue: {
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    color: 'rgba(7, 28, 20, 0.80)',
  },
  actionButtonsContainer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: credit ? 'normal' : 'space-between',
    '@media(max-width: 767px)': {
      alignItems: 'center',
    },
  },
  payEarly: {
    height: '40px',
    width: '100px',
    padding: '12px 20px 12px 20px',
  },
  payEarlyText: {
    color: 'rgb(252,239,237)',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'medium',
    lineHeight: '16px',
  },
  autopay: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '155px',
  },
  autopayLabel: {
    padding: '3px',
  },
  autopayLabelText: {
    color: '#5F5F5F',
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    textAlign: 'end',
  },
  autopaySchedule: {
    color: '#979797',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  learnAboutRewards: {
    background: 'white',
    width: '171px',
    height: '40px',
    border: '1px solid #979797',
    borderRadius: '8px',
    marginLeft: '10px',
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
  },
  learnAboutRewardsText: {
    color: '#5F5F5F',
    fontSize: '13.6px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
}));
