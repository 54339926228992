import {
  Button,
  createStyles,
  Image,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Merchant } from 'flexbase-client';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';

interface Props {
  closeModal: () => void;
  merchant: Merchant;
  amount?: string;
  merchantCallbackCancelUrl?: string;
  onContinue: () => void;
  onBack: () => void;
}

const useStyles = createStyles((theme) => ({
  bnplConfirm: {
    '@media(max-width: 767px)': {
      width: '85%',
    },
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    margin: 'auto',
  },
  almostDone: {
    '@media(max-width: 767px)': {
      width: '97.5%',
      marginTop: '10%',
    },
    marginLeft: '5px',
    marginTop: '10%',
    fontSize: '21px',
    fontWeight: 700,
  },
  title: {
    '@media(max-width: 767px)': {
      fontSize: '24px',
      margin: '7.5% 0 7.5% 5px',
    },
    margin: '1.75% 0 1.75% 5px',
    fontWeight: 300,
    fontSize: '38px',
    fontFamily: 'PP Neue Montreal',
  },
  subtitle: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
    },
    marginLeft: '5px',
    fontSize: '24px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '32px',
    fontFamily: 'PP Neue Montreal',
  },
  totalBox: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
      margin: '7.5% 0 7.5% 0',
    },
    width: '100%',
    border: '2px solid #F2F2F2',
    borderRadius: '10px',
    margin: '4% 0 4% 0',
    padding: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  merchantLogoLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  merchantLogoImage: {
    '@media(max-width: 767px)': {
      width: '135px !important',
      marginRight: '7%',
    },
    marginRight: '5%',
    width: '70px !important',
  },
  question: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
    },
    fontSize: '22px',
    fontWeight: 400,
  },
  warning: {
    fontSize: '16px',
    fontWeight: 300,
    margin: '5% 0 5% 0',
  },
  totalLine: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  separationLine: {
    border: '1px solid #F2F2F2',
    width: '100%',
    margin: '0 0 5% 0',
  },
  total: {
    fontSize: '22px',
    fontWeight: 700,
  },
  approveButton: {
    '@media(max-width: 767px)': {
      margin: '3.5% 0 3.5% 0',
    },
    height: '55px',
    backgroundImage: `linear-gradient(to bottom right, ${theme.fn.primaryColor()}, #FF8779)`,
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: '22px',
  },
  cancelButton: {
    height: '55px',
    color: '#8A8A8F',
    fontSize: '22px',
    fontWeight: 700,
    margin: '2% auto auto auto',
    cursor: 'pointer',
  },
}));

const BnplConfirm = ({
  closeModal,
  merchant,
  amount,
  merchantCallbackCancelUrl,
  onContinue,
  onBack,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const cancel = (): void => {
    window.location.replace(merchantCallbackCancelUrl!);
    closeModal();
  };
  return (
    <div>
      <FlexbaseHeader onCloseButtonClick={cancel} />
      <div className={classes.bnplConfirm}>
        <Text
          size="lg"
          weight="bold"
          align="left"
          className={classes.almostDone}
          color={theme.fn.primaryColor()}
        >
          Almost done!
        </Text>
        <Title order={1} align="left" className={classes.title}>
          Complete your purchase!
        </Title>
        <Text align="left" className={classes.subtitle}>
          Now when your credit card is approved and is <br />
          issued as virtual - you can go back to the store <br />
          and complete your purchase
        </Text>
        <div className={classes.totalBox}>
          <div className={classes.merchantLogoLine}>
            <Image
              alt={merchant.name}
              src={merchant.logoUrl}
              className={classes.merchantLogoImage}
            ></Image>
            <Text align="left" className={classes.question}>
              {' '}
              Allow {merchant.name} to make a purchase using your available
              credit?
            </Text>
          </div>
          <Text align="left" className={classes.warning}>
            Make sure you authorize this transaction. {merchant.name} will make
            the purchase on your side using the credit on your Flexbase account.
            This action can&apos;t be undone.
          </Text>
          <div className={classes.separationLine}></div>
          <div className={classes.totalLine}>
            <Text className={classes.total}>Total (due on checkout) </Text>
            <Text className={classes.total}>{formatCurrency(amount!)}</Text>
          </div>
        </div>
        <Button
          fullWidth={true}
          className={classes.approveButton}
          onClick={onContinue}
        >
          Approve Transaction
        </Button>
        <Text className={classes.cancelButton} onClick={onBack}>
          Previous Step
        </Text>
      </div>
    </div>
  );
};

export default BnplConfirm;
