import {
  Alert,
  Avatar,
  Button,
  Loader,
  Pagination,
  Table,
  useMantineTheme,
} from '@mantine/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { Employee } from 'states/employees/employee';
import { AlertMessage } from 'states/alert/alert-message';
import { formatUSPhoneNumber } from 'utilities/formatters/format-phone-number';
import useModal from 'components/modal/modal-hook';
import ActionModal from './action-modal/action-modal';
import { useUserStyles } from './user.styles';
import { useRecoilValue } from 'recoil';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';

interface Props {
  closeModal: () => void;
  setAllUsers: Dispatch<SetStateAction<Employee[]>>;
  setAlertMessage: Dispatch<SetStateAction<AlertMessage | null>>;
  allUsers?: Employee[];
  alertMessage?: AlertMessage | null;
}

const Users = ({
  allUsers,
  closeModal,
  setAllUsers,
  setAlertMessage,
  alertMessage,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useUserStyles();
  const userId = useRecoilValue(UserIdState);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * 8;
  const indexOfFirstPost = indexOfLastPost - 8;
  const totalPageCount =
    allUsers && allUsers?.length > 0 ? Math.ceil(allUsers?.length / 8) : 0;
  const users = allUsers?.slice(indexOfFirstPost, indexOfLastPost);

  const modal = useModal();

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      let companyUsers = await flexbaseClient.getEmployees();
      if (companyUsers?.length > 0) {
        companyUsers = companyUsers?.filter(
          (currentUser: Employee) => currentUser?.id !== userId,
        );
        setAllUsers(companyUsers);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const openActionModal = (employee: Employee) => {
    screen.width <= 767
      ? modal.openCenterModal(
          <ActionModal
            {...{ closeModal, employee, setAllUsers, setAlertMessage }}
          />,
        )
      : modal.openRightModal(
          <ActionModal
            {...{ closeModal, employee, setAllUsers, setAlertMessage }}
          />,
        );
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Loader color={theme.fn.primaryColor()} />
      </div>
    );
  }

  const tableRows = users?.map((employee) => (
    <tr key={employee.id}>
      <td data-title="User">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {screen.width > 767 && (
            <Avatar style={{ marginRight: 10 }} color="red" radius="xl">
              {employee?.firstName?.charAt(0)}
              {employee?.lastName?.charAt(0)}
            </Avatar>
          )}
          {employee?.firstName} {employee?.lastName}
        </div>
      </td>

      <td data-title="Job Title">{employee?.jobTitle}</td>
      <td data-title="Email">{employee?.email}</td>
      <td data-title="Phone">
        {formatUSPhoneNumber(employee?.cellPhone || '')}
      </td>
      <td>
        <Button
          variant="outline"
          color="gray"
          size="md"
          className="btnEdit"
          onClick={() => openActionModal(employee)}
        >
          Edit User
        </Button>
      </td>
    </tr>
  ));

  return (
    <div data-testid={'users-list'} className={classes.container}>
      <Table className="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Job Title</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
      {alertMessage && (
        <Alert
          color={alertMessage.type === 'success' ? 'teal' : 'red'}
          title={alertMessage?.title}
          withCloseButton
          closeButtonLabel="Close alert"
          onClose={() => setAlertMessage(null)}
          style={{ width: '25%', margin: 20 }}
        >
          {alertMessage?.message}
        </Alert>
      )}

      <Pagination
        position="right"
        value={currentPage}
        total={totalPageCount}
        classNames={
          {
            // item: cx(classes.item, a,
          }
        }
        onChange={(page) => {
          setCurrentPage(page);
          scroll(0, 0);
        }}
      />
    </div>
  );
};

export default Users;
