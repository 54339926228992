// import Account from './dashboard';
import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import { Outlet } from 'react-router-dom';
import FlexbaseMainLayout from 'components/layouts/main';
import Loadable from 'components/loading/loadable';
// import { FinancialInstitution } from 'states/onboarding/onboarding-info';
// import { showNotification } from '@mantine/notifications';

// This component is responsible for loading state for the Treasury feature
const CashManagement = () => {
  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title="Treasury Management" />
      <Loadable loadingIndicator="skeleton">
        <Outlet />
      </Loadable>
    </FlexbaseMainLayout>
  );
};

export default CashManagement;
