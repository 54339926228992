export const setPaymentRejectedError = (reason: string) => {
  let message = '';
  switch (reason) {
    case 'InsufficientFunds':
      message =
        "It looks like there's not enough funds in your account to complete this transaction. Please add funds and try again.";
      break;

    case 'DailyACHCreditLimitExceeded':
      message =
        "You've exceeded the daily debit limit set by Flexbase. Please try again tomorrow.";
      break;

    case 'MonthlyACHCreditLimitExceeded':
      message =
        "You've exceeded the monthly credit limit set by Flexbase. Please try again next month.";
      break;

    case 'MonthlyACHDebitLimitExceeded':
      message =
        "You've exceeded the monthly debit limit set by Flexbase. Please try again next month.";
      break;

    case 'CounterpartyInsufficientFunds':
      message =
        'The account does not have enough funds to complete this transaction. Please try again later or select a different account.';
      break;

    case 'PlaidBalanceUnavailable':
      message =
        "Sorry! We're unable to determine the balance of the account at this time. Please try again later.";
      break;

    case 'PlaidLoginRequired':
      message =
        'It looks like your Plaid authentication has expired. Please re-login to Plaid and try again.';
      break;

    case 'PlaidInvalidProduct':
    case 'PlaidUnsupportedBank':
      message =
        'Sorry! Plaid integration is not available for this product or bank. Please try a different account.';
      break;

    case 'PlaidInternalError':
      message =
        "We've encountered an error while connecting to Plaid. Please contact Flexbase for assistance.";
      break;
  }

  return message;
};
