import {
  Text,
  Group,
  Stack,
  Avatar,
  Anchor,
  Button,
  Divider,
  Popover,
  CloseButton,
} from '@mantine/core';
import { DateTime } from 'luxon';
import { useMediaQuery } from '@mantine/hooks';

import { useStyles } from './styles';
import useModal from 'components/modal/modal-hook';
import { formatCurrency } from 'utilities/formatters/format-currency';

interface TransactionDetailsProps {
  transaction: any;
}

const TransactionDetails = ({ transaction }: TransactionDetailsProps) => {
  const { closeAllModals } = useModal();
  const { classes, theme } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const createdAt = DateTime.fromSQL(transaction.createdAt);
  const formattedDate = `${createdAt.toFormat('DDD')} at ${createdAt.toFormat(
    't',
  )}`;
  const displayDisputeButton = [
    'ACH Received',
    'Card Purchase',
    'Card Transaction',
  ].includes(transaction.type);

  return (
    <div className={classes.transactionDetails}>
      <div className={classes.header}>
        <div style={{ display: 'flex', gap: 14 }}>
          <Avatar mt={10}>{transaction.initials}</Avatar>
          <div id="text-header">
            <Text size={useMobileView ? 20 : 36}>
              {formatCurrency(transaction.amount)}
            </Text>
            <Text size={useMobileView ? 10 : 14}>{formattedDate}</Text>
          </div>
        </div>
        <div>
          <CloseButton
            color="neutral.0"
            variant="transparent"
            onClick={closeAllModals}
            size={useMobileView ? 20 : 30}
          />
        </div>
      </div>

      <Divider mb={24} />

      <div>
        <Group spacing="xl" grow mx={useMobileView ? 20 : 50} align="initial">
          <div>
            <Text size={useMobileView ? 12 : 14}>Recipient</Text>
            <Text size={useMobileView ? 15 : 20}>
              {transaction.name || 'Repayment'}
            </Text>
          </div>
          <div>
            <Text size={useMobileView ? 12 : 14}>Account name</Text>
            <Text size={useMobileView ? 15 : 20}>{transaction.account}</Text>
          </div>
        </Group>
        <Group grow mx={useMobileView ? 20 : 50} my={30} align="initial">
          <div>
            <Text size={useMobileView ? 12 : 14}>Type</Text>
            <Text size={useMobileView ? 15 : 20}>{transaction.type}</Text>
          </div>
        </Group>

        <Divider my={20} />

        <Stack mx={useMobileView ? 20 : 50} style={{ gap: 2 }}>
          <Text size={useMobileView ? 12 : 14}>Memo</Text>
          <Text size={useMobileView ? 15 : 20}>{transaction.toFrom}</Text>
        </Stack>

        <Divider my={20} />

        <Stack mx={useMobileView ? 20 : 50} style={{ gap: 2 }}>
          <Text size={useMobileView ? 10 : 14}>Transaction ID</Text>
          <Text>#{transaction.id}</Text>
        </Stack>
      </div>
      {displayDisputeButton && (
        <Stack mx={useMobileView ? 20 : 50} my={30} align="flex-start">
          <Popover
            width={useMobileView ? '100%' : 250}
            withArrow
            arrowPosition="side"
            shadow="md"
          >
            <Popover.Target>
              <Button w={useMobileView ? '100%' : 'auto'}>
                Dispute Transaction
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              {transaction.type === 'ACH Received' ? (
                <Text size={useMobileView ? 12 : 14}>
                  Send the signed copy of the{' '}
                  <Anchor
                    color={theme.fn.primaryColor()}
                    href="https://docs.google.com/document/d/1bbZVwr-gyfd3AkUZ0-I9ier70SRVOoLv/edit"
                    target="_blank"
                  >
                    ACH debit dispute
                  </Anchor>{' '}
                  to the following email address:{' '}
                  <Anchor
                    color="black"
                    href="mailto:support@flex.one"
                    target="_blank"
                    fw={600}
                  >
                    support@flex.one
                  </Anchor>
                </Text>
              ) : (
                <Text size={useMobileView ? 12 : 14}>
                  If you want to dispute this transaction, please contact the
                  toll-free number <strong>18338521519</strong>
                </Text>
              )}
            </Popover.Dropdown>
          </Popover>
        </Stack>
      )}
    </div>
  );
};

export default TransactionDetails;
