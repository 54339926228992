export const formatPersonalTaxId = (value: string) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) {
    return currentValue;
  }
  if (cvLength < 6) {
    const t = `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    return t;
  }
  return `${currentValue.slice(0, 3)}-${currentValue.slice(
    3,
    5,
  )}-${currentValue.slice(5, 9)}`;
};

export const formatEinTaxId = (value: string) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 3) {
    return currentValue;
  }
  return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 9)}`;
};
