import type { TermsOfServiceAcceptance } from 'services/flexbase/flexbase-onboarding-client';
import { useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { formatDate } from 'utilities/formatters/format-datetime';
import { selector, useRecoilValue } from 'recoil';
import { Box, Text, createStyles, useMantineTheme } from '@mantine/core';

// an external document that the user has agreed to
// as part of accepting some terms-of-service
interface Agreement {
  acceptedAt: string;
  url: string;
  label: string;
  tosCreatedAt: string;
}

// extract the external User Agreement links from the anchor tags
// in the HTML contents returned from the API
type ExtractAgreements = (acceptance: TermsOfServiceAcceptance) => Agreement[];
const extractAgreements: ExtractAgreements = ({
  acceptedAt,
  tosCreatedAt,
  contents,
}) => {
  const parser = new DOMParser();

  const links = parser
    .parseFromString(contents, 'text/html')
    .querySelectorAll('a');

  return Array.from(links).map((link) => ({
    tosCreatedAt,
    acceptedAt,
    url: link.href,
    label: link.textContent!,
  }));
};

// Fetch and cache the user's prior terms-of-service acceptances
const termsHistoryQuery = selector({
  key: 'tos/history',
  get: async () => flexbaseOnboardingClient.getTosHistory(),
});

// Render in a simple table the User Agreements that have been
// accepted as parts of terms-of-service
const UserAgreements = () => {
  const termsOfService = useRecoilValue(termsHistoryQuery);
  const agreements: Agreement[] = termsOfService.flatMap(extractAgreements);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  function removeDuplicates<T>(arr: T[], getKey: (item: T) => any): T[] {
    const uniqueKeys = new Set();
    const result: T[] = [];

    for (const obj of arr) {
      const key = getKey(obj);
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        result.push(obj);
      }
    }

    return result;
  }

  const uniqueArray = removeDuplicates(agreements, (item) => item.label);

  const [selectedTerm, setSelectedTerm] = useState<Agreement>(uniqueArray[0]);

  return (
    <div className={classes.container}>
      <div className={classes.tabsContainer}>
        {uniqueArray.map((item) => {
          return (
            <Box
              mb={9}
              onClick={() => {
                setSelectedTerm(item);
              }}
              key={item.url}
              className={classes.containerTerm}
              style={{
                backgroundColor:
                  selectedTerm.label === item.label
                    ? theme.colors.neutral[2]
                    : '#FFFF',
              }}
            >
              <Text> {item.label} </Text>
            </Box>
          );
        })}
      </div>
      <div className={classes.contentContainer}>
        <Text mb={25} ff={'PP Neue Montreal'} fs={'italic'}>
          Accepted at: {formatDate(selectedTerm.acceptedAt)}
        </Text>
        <embed
          src={selectedTerm.url}
          type="application/pdf"
          width="100%"
          height="700px"
        />
      </div>
    </div>
  );
};
const useStyles = createStyles((theme) => ({
  container: { display: 'flex', flexDirection: 'row' },
  contentContainer: {
    backgroundColor: '#FFFF',
    padding: '20px',
    borderRadius: theme.defaultRadius,
    width: '100%',
    marginLeft: 21,
  },
  containerTerm: {
    padding: 8,
    cursor: 'pointer',
  },
  tabsContainer: {
    backgroundColor: '#FFFF',
    width: '32rem',
    padding: '20px',
    borderRadius: theme.defaultRadius,
  },
}));
export default UserAgreements;
