export const validatePersonalTaxId = (value: string): boolean =>
  /^((?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$|XXX-XX-XXXX)/.test(
    value,
  );

export const validateEinTaxId = (value: string): boolean =>
  /^(\d{2}-?\d{7}|XX-XXXXXXX)$/.test(value);

type validatorFn = (val: any) => string | null;

// TODO: Replace validators with higher order fns like this
export const isEin = (error?: string): validatorFn => {
  return (val: string) =>
    validateEinTaxId(val) ? null : error || 'EIN is not valid.';
};
