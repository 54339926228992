import { View, Text, Image } from '@react-pdf/renderer';
import FlexbaseImg from 'assets/images/logo-flexbase.png';
import { stylesStatementPDF } from './styles';

const Header = () => {
  return (
    <View style={stylesStatementPDF.containerHeader}>
      <Image src={FlexbaseImg} style={{ height: 20 }} />

      <View style={{ marginTop: 5 }}>
        <Text
          style={{
            ...stylesStatementPDF.text,
            alignSelf: 'flex-end',
            marginTop: 2,
          }}
        >
          flexbase.app
        </Text>
      </View>
    </View>
  );
};

export default Header;
