import { View, Text } from '@react-pdf/renderer';
import { stylesPDF } from '../styles-pdf';

type Props = {
  theme: any;
};

const StatementTerms = ({ theme }: Props) => {
  const styles = stylesPDF({
    neutral: theme.colors.neutral[2],
    borderRadius: theme.defaultRadius,
    textColor: theme.colors.neutral[6],
  });

  return (
    <>
      <View style={styles.containerPageTerms}>
        <Text
          style={{
            ...styles.textTermsBold,
            fontSize: 13,
            textAlign: 'center',
            margin: '15px 0px',
          }}
        >
          Flexbase Statement Terms
        </Text>
        <Text style={styles.textTerms}>
          <Text style={styles.textTermsBold}>Payments: </Text>
          Your payment must be sent electronically to Flexbase through the
          Flexbase mobile app or website and must be initiated by 11:59 p.m. UTC
          to be credited against your Amount Due as of the day it is initiated.
          Payments initiated after 11:59 p.m. UTC will not be credited against
          your Amount Due until the next day. Although we will give you credit
          against your Amount Due when a payment is initiated, your Available
          Credit balance will not reflect such payment until the payment has
          settled in our account. If your payment does not meet the above
          requirement, crediting against your Amount Due may be delayed and you
          may incur late payment fees and additional interest charges as
          described in your Flexbase Line of Credit Disclosure and Account
          Agreement (“Flexbase LOC Agreement”). The amount of any interest
          charges and late payment fees will be as set out in the Flexbase LOC
          Agreement. Capitalized terms used but not defined herein shall have
          the respective meanings given to them in the Flexbase LOC Agreement
          Electronic payments must be made through an electronic payment method
          payable in US dollars and clearable through the US banking system. If
          your payment does not meet all of the above requirements, crediting
          may be delayed and you may incur late payment fees and additional
          interest charges. We will re-present to your financial institution any
          payment that is returned unpaid.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          <Text style={styles.textTermsBold}>
            Permission for Electronic Withdrawal:{' '}
          </Text>
          By making a payment on the Flexbase mobile app or Flexbase web app on
          the Flexbase website, or any other electronic payment service of ours,
          you give us permission to electronically withdraw funds from the
          deposit or other asset account you linked to your Flexbase account at
          your initial acceptance of your Flexbase line of credit, in the amount
          you request. For autopay enrollees, you may revoke this authorization
          by canceling your automatic payment through our website or customer
          service telephone number at least three days prior to the next payment
          processing date. If we cannot collect the funds electronically, we may
          issue a draft against your deposit or other asset account for the
          amount of the payment you requested. Payments using such services of
          ours received after 11:59 p.m. UTC may not be credited against your
          Amount Due until the next business day.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          <Text style={styles.textTermsBold}>
            How We Calculate Your Balance:{' '}
          </Text>
          We use the account balance at the end of the previous billing period
          as the basis for calculating the previous balance. We use the account
          balance on the date of statement generation (this is the “Generated
          On” date on your statement) as the basis for current balance. Any
          unpaid Amount Due and Interest Charges at the end of the previous
          billing period will serve as the basis on which we charge interest on
          your Account. Please see the “Interest Charges” section below or call
          the Flexbase Customer Support Line at 415-840-8721 for more
          information about this balance computation method and how resulting
          interest charges are determined.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          <Text style={styles.textTermsBold}>Amount Due: </Text>
          Each bi-monthly billing cycle, you agree to pay us 100% of the unpaid
          Line of Credit Advances that are outside of their Grace Period
          (defined below) plus any outstanding Interest Charges and fees by the
          payment due date shown on your billing statement `{'Payment Due Date'}
          `. You can pay down balances faster by paying more than the minimum
          payment or the total unpaid balance on your account.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          <Text style={styles.textTermsBold}>Interest Charges: </Text>
          We will not charge you interest if you pay in full your Amount Due (as
          defined above) by each bi-weekly due date. (i) Interest on Line of
          Credit Advances: You will be given a 60-day grace period on each Line
          of Credit Advance during which no interest will accrue on such Line of
          Credit Advance (the “Grace Period”). For each Line of Credit Advance
          that remains unpaid by the end of the then-current bi-monthly billing
          period after such Line of Credit Advance’s Grace Period has elapsed
          (the “Past Due Amounts”), interest will begin to accrue daily from the
          first day of the subsequent bi-monthly billing period at the APR set
          forth in your Flexbase LOC Agreement. (ii) All Accrued Interest:
          Accrued Interest that remains unpaid by the end of the subsequent
          bi-monthly billing period will then be added to your Past Due Balance,
          with interest then compounding at the APR until paid in full. See your
          Flexbase LOC Agreement for further details.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          <Text style={styles.textTermsBold}>Credit Balance: </Text>
          Any credit balance shown on the statement represents money owed to
          you. If within the six month period following the date of the first
          statement indicating the credit balance you do not charge enough to
          use up the credit balance, we will send you a check or electronic
          funds transfer to your linked bank account for the credit balance
          within 30 days if the amount is $1.00 or more.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          <Text style={styles.textTermsBold}>Credit Reporting: </Text>
          We may report information about your account to credit bureaus. Late
          payments, missed payments, or other defaults on your account may be
          reflected in your credit report.
        </Text>

        <Text
          style={{
            ...styles.textTermsBold,
            marginTop: 10,
          }}
        >
          Billing Dispute Procedures:{' '}
        </Text>

        <Text
          style={{
            ...styles.textTerms,
            textDecoration: 'underline',
          }}
        >
          What To Do If You Think You Find A Mistake On Your Statement
        </Text>
        <Text style={styles.textTerms}>
          If you think there is an error on your statement, write to us at
          billing@flex.one
        </Text>
        <Text style={styles.textTerms}>
          In your email, give us the following information:
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          Account information: Your name and account number.
        </Text>
        <Text style={styles.textTerms}>
          Dollar amount: The dollar amount of the suspected error.
        </Text>
        <Text style={styles.textTerms}>
          Description of problem: Describe what you believe is wrong and why you
          believe it is a mistake.
        </Text>
      </View>

      <View style={{ ...styles.containerPageTerms, height: '85vh' }}>
        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          You must contact us:
        </Text>
        <Text style={styles.textTerms}>
          • Within 60 days after the error appeared on your statement.
        </Text>
        <Text style={styles.textTerms}>
          • At least 3 business days before an automated payment is scheduled,
          if you want to stop payment on the amount you think is wrong.
        </Text>
        <Text style={styles.textTerms}>
          You must notify us of any potential errors in writing. You may call
          us, but if you do, we may not follow these procedures and you may have
          to pay the amount in question.
        </Text>

        <Text
          style={{
            ...styles.textTerms,
            textDecoration: 'underline',
            marginTop: 10,
          }}
        >
          What Will Happen After We Receive Your Correspondence
        </Text>

        <Text style={{ ...styles.textTerms, textDecoration: 'underline' }}>
          When we receive your email, we must do two things:
        </Text>
        <Text style={styles.textTerms}>
          1. Within 30 days of receiving your email, we must tell you that we
          received your email. We will also tell you if we have already
          corrected the error.
        </Text>
        <Text style={styles.textTerms}>
          2. Within 90 days of receiving your email, we will either correct the
          error or explain to you why we believe the bill is correct.
        </Text>

        <Text
          style={{
            ...styles.textTerms,
            marginTop: 10,
          }}
        >
          While we investigate whether or not there has been an error:
        </Text>

        <Text
          style={{
            ...styles.textTerms,
            marginTop: 10,
          }}
        >
          • We cannot try to collect the amount in question, or report you as
          delinquent on that amount.
        </Text>
        <Text style={styles.textTerms}>
          • The charge in question may remain on your statement, and we may
          continue to charge you interest on that amount.
        </Text>

        <Text style={styles.textTerms}>
          • While you do not have to pay the amount in question, you are
          responsible for the remainder of your balance and we can apply any
          unpaid amount against your credit limit. After we finish our
          investigation, one of two things will happen:
        </Text>
        <Text style={{ ...styles.textTerms, marginLeft: 10 }}>
          • If we made a mistake: You will not have to pay the amount in
          question or any interest or other fees related to that amount.
        </Text>
        <Text style={{ ...styles.textTerms, marginLeft: 10 }}>
          • If we do not believe there was a mistake: You will have to pay the
          amount in question, along with applicable interest and fees. We will
          send you a statement of the amount you owe and the date payment is
          due. We may report you as delinquent if you do not pay the amount we
          think you owe.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          If you receive our explanation but still believe your bill is wrong,
          you must write to us within 10 days telling us that you still refuse
          to pay. If you do so, we cannot report you as delinquent without also
          reporting that you are questioning your bill. We must tell you the
          name of anyone to whom we reported you as delinquent, and we must let
          those organizations know when the matter has been settled between us.
        </Text>
        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          If we do not follow all of the rules above, you do not have to pay the
          first $50 of the amount you question even if your bill is correct.
        </Text>
        <Text
          style={{
            ...styles.textTermsBold,
            marginTop: 10,
          }}
        >
          Your Cardholder Agreement
        </Text>
        <Text style={styles.textTerms}>
          To access the most up to date version of your Cardholder Agreement,
          please log in to your account at{' '}
          <Text style={styles.textTermsBold}>www.flex.one</Text>
        </Text>

        <Text
          style={{
            ...styles.textTermsBold,
            marginTop: 10,
          }}
        >
          Your Rights If You Are Dissatisfied With Your Card Purchases
        </Text>
        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          If you are dissatisfied with the goods or services that you have
          purchased with your Flexbase card, and you have tried in good faith to
          correct the problem with the merchant, you may have the right not to
          pay the remaining amount due on the purchase.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          To use this right, all of the following must be true:
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          1. The purchase must have been made in your home state or within 100
          miles of your current mailing address, and the purchase price must
          have been more than $50. (Note: Neither of these are necessary if your
          purchase was based on an advertisement we mailed to you, or if we own
          the company that sold you the goods or services.)
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          2. You must have used your Flexbase card for the purchase. Purchases
          made with cash advances from an ATM or with a check that accesses your
          Flexbase card account do not qualify.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          3. You must not yet have fully paid for the purchase.
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          If all of the criteria above are met and you are still dissatisfied
          with the purchase, contact us electronically at:
          <Text style={{ fontStyle: 'bold' }}> support@flex.one</Text>, or
          submit a chat request on our Website
        </Text>

        <Text style={{ ...styles.textTerms, marginTop: 10 }}>
          While we investigate, the same rules apply to the disputed amount as
          discussed above. After we finish our investigation, we will tell you
          our decision. At that point, if we think you owe an amount and you do
          not pay, we may report you as delinquent.
        </Text>
      </View>
    </>
  );
};

export default StatementTerms;
