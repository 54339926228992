import { useStartScreenStyles } from '../styles';
import { CreditBankingForm } from './credit-banking-form';

export const CreditBankingContentLg = () => {
  const { classes } = useStartScreenStyles();

  return (
    <div style={{ marginTop: 'auto' }}>
      <div className={classes.contentContainer}>
        <div className={classes.gridLayout}>
          <div className={classes.formContainer}>
            <CreditBankingForm />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <img src="/images/img.png" className={classes.image} alt="Flex" />
          </div>
        </div>
      </div>
    </div>
  );
};
