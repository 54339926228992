import { Button, Group, Popover, Switch, Text } from '@mantine/core';
import useModal from 'components/modal/modal-hook';
import { formatCurrency } from 'utilities/formatters/format-currency';
import React, { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { useStyles } from './credit-payment.styles';
import CompanyBalance from 'states/company/balance';
import CardRepaymentModal from '../../repayment/card-repayment-modal';
import { DateTime } from 'luxon';
import { showNotification } from '@mantine/notifications';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IoReloadOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { TransparentCenteredLoader } from '@flexbase-eng/web-components';
import {
  ApplicationState,
  CompanySelector,
  IsAccountant,
} from '../../../../states/application/product-onboarding';

interface Props {
  preview?: boolean;
  credit?: boolean;
}

const CreditPaymentCard = ({ preview, credit }: Props) => {
  const navigate = useNavigate();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { openTransparentModal, closeAllModals } = useModal();
  const [{ company }, setCompany] = useRecoilState(ApplicationState);

  const closeModal = () => {
    localStorage?.removeItem('paymentAmount');
    closeAllModals();
  };

  const openPaymentModal = () => {
    openTransparentModal(<CardRepaymentModal closeModal={closeModal} />);
  };

  const { classes } = useStyles({ preview, credit });
  // const useMobileView = useMediaQuery('(max-width: 767px)');
  const { id } = useRecoilValue(CompanySelector);
  const isAccountant = useRecoilValue(IsAccountant);
  const [companyBalance, setCompanyBalance] = useState<CompanyBalance>({
    success: false,
    totalInvoices: 0,
    totalPayments: 0,
    currentBalance: 0,
    creditLimit: 0,
    availableLimit: 0,
    minimumDue: 0,
    maximumAllowedPayment: 0,
    billDate: '',
    graceDate: '',
    companySpendLimit: 0,
    delinquentAmount: 0,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const getDaysTilDue = () => {
    if (!companyBalance.billDate) {
      return 'N/A';
    }
    const diff = DateTime.fromFormat(
      companyBalance.billDate,
      'yyyy-MM-dd',
    ).diffNow();

    const diffDays = Math.floor(diff.as('days'));
    if (companyBalance.minimumDue <= 0) {
      return 'Nothing due';
    }

    return diffDays < 0
      ? 'Due today'
      : `Due in ${diffDays} ${diffDays === 1 ? 'day' : 'days'}`;
  };

  const getCompanyBalance = async () => {
    setLoading(true);
    try {
      const currentCompanyBalance = await flexbaseClient.getCompanyBalance(
        `${id}`,
      );
      if (currentCompanyBalance) {
        setCompanyBalance(currentCompanyBalance);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onAutopayToggle = async (enable: boolean) => {
    setLoading(true);

    try {
      await flexbaseOnboardingClient.updateCompany({
        autopay: enable,
      });
      setCompany((prev) => ({
        ...prev,
        company: { ...prev.company, autopay: enable },
      }));
      showNotification({
        title: 'Success!',
        message: `Autopay successfully ${enable ? 'enabled' : 'disabled'}.`,
        color: 'flexbase-teal',
      });
    } catch (error) {
      showNotification({
        title: 'Failure',
        message: `Autopay could not be ${
          enable ? 'enabled' : 'disabled'
        }. Please contact support if the issue persists.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyBalance();
  }, []);

  return (
    <div
      className={`${classes.creditPaymentContainer} ${
        !credit ? classes.cardHover : ''
      }`}
      onClick={() => {
        navigate('/cards/pay');
      }}
    >
      <TransparentCenteredLoader visible={loading} />
      <div className={classes.header}>
        <Text className={classes.amountDueLabel}>Next Payment</Text>
        {(companyBalance.billDate || companyBalance.delinquentAmount) && (
          <div
            className={classes.daysTilDueContainer}
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <Popover opened={popoverOpened} position="top">
              <Popover.Target>
                <Text
                  className={classes.daysTilDue}
                  onMouseEnter={() => setPopoverOpened(true)}
                  onMouseLeave={() => setPopoverOpened(false)}
                >
                  {companyBalance.delinquentAmount
                    ? 'OVERDUE'
                    : getDaysTilDue()}
                </Text>
              </Popover.Target>
              <Popover.Dropdown>
                <div>
                  <Text
                    style={{
                      fontSize: '14px',
                      color: '#5F5F5F',
                      margin: 'auto',
                    }}
                  >
                    To ensure your payment is processed on the date submitted,
                    it must be sent before 5:00 pm EST
                  </Text>
                </div>
              </Popover.Dropdown>
            </Popover>
          </div>
        )}
      </div>
      {error ? (
        <Group style={{ marginTop: 10, alignItems: 'flex-start' }}>
          <IoReloadOutline
            style={{ cursor: 'pointer' }}
            onClick={() => getCompanyBalance()}
          />
          <Text className={classes.amountDueLabel}>Error. Reload data.</Text>
        </Group>
      ) : (
        <div className={classes.amountDue}>
          {formatCurrency(
            companyBalance.minimumDue > 0
              ? companyBalance.minimumDue.toString()
              : 0,
          )}
          <Text className={classes.currentBalance}>
            Total Current Balance :{' '}
            {formatCurrency(companyBalance.maximumAllowedPayment)}
          </Text>
        </div>
      )}

      <div className={classes.actionButtonsContainer}>
        <Button
          disabled={isAccountant}
          onClick={(e: any) => {
            e.stopPropagation();
            openPaymentModal();
          }}
          data-testid="pay-now-button"
          style={{ marginRight: '16px' }}
        >
          Pay Now
        </Button>
        {credit ? (
          // <Tooltip label="Coming soon" disabled={false}>
          //   <Button
          //     className={classes.learnAboutRewards}
          //     style={useMobileView ? { width: 'unset' } : {}}
          //   >
          //     <Text className={classes.learnAboutRewardsText}>
          //       {useMobileView ? 'About rewards' : 'Learn about rewards'}
          //     </Text>
          //   </Button>
          // </Tooltip>
          <></>
        ) : (
          <>
            {!company.autopay && (
              <Group>
                <div className={classes.autopayLabel}>
                  <Text className={classes.autopayLabelText}>Auto Pay</Text>
                  <Text className={classes.autopaySchedule}>
                    60 day billing
                  </Text>
                </div>
                <div onClick={(e) => e.stopPropagation()}>
                  <Switch
                    disabled={isAccountant}
                    checked={company.autopay}
                    color="flexbase-ornage"
                    onChange={(e) => {
                      onAutopayToggle(e.currentTarget.checked);
                    }}
                    size={'lg'}
                    styles={{
                      input: { width: '50px', cursor: 'pointer' },
                    }}
                  />
                </div>
              </Group>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreditPaymentCard;
