import useModal from 'components/modal/modal-hook';
import { useState } from 'react';
import IncomingPayment from './components/incoming-payment';
import PaymentsRequestTable from './components/payments-request-table/payments-request-table';

const IncomingPayments = () => {
  const { openTransparentModal } = useModal();
  const [error] = useState('');

  const openSendPaymentModal = () => {
    openTransparentModal(<IncomingPayment />);
  };

  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        <PaymentsRequestTable
          {...{ openSendPaymentModal }}
          errorMessage={error}
        />
      </div>
    </>
  );
};

export default IncomingPayments;
