import { atom, DefaultValue, selector } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { union } from 'underscore';
import {
  formatPhoneForApi,
  formatUSPhoneNumber,
} from 'utilities/formatters/format-phone-number';
import { recoilPersist } from 'recoil-persist';
import authenticatedStorage from 'states/auth/authenticated-storage';
import IAddress from 'states/user/address';
import { formatFullAddressToString } from 'utilities/formatters/format-address';
import {
  _ApplicationState,
  OnboardingProduct,
} from './product-onboarding.models';
import { DEFAULT_APP_STATE_VALUES } from './onboarding-defaults';
import {
  OnboardingCompany,
  OnboardingUser,
  OwnerType,
} from 'states/onboarding/onboarding-info';
import { UserRole } from 'states/user/user-info';

export const mapProductsArrayToProduct = (
  products: string[],
): OnboardingProduct => {
  if (!products?.length || products.length < 1) {
    return '';
  }

  if (products.length > 1) {
    return 'ALL';
  }

  return products[0] as OnboardingProduct;
};

export const mapProductToProductsArray = (
  product: OnboardingProduct,
): OptedProduct[] => {
  switch (product) {
    case 'BANKING':
      return ['BANKING'];
    case 'CREDIT':
      return ['CREDIT'];
    case 'TREASURY':
      return ['TREASURY'];
    case 'ALL':
      return ['BANKING', 'CREDIT']; // Not including treasury here for now.
    default:
      return [];
  }
};

export const { persistAtom } = recoilPersist({
  key: 'fb_product',
  storage: authenticatedStorage,
});

// TODO: Somehow fix this to not make a call if no auth. Warning: using get(AuthenticatedUserState) causes a never ending loop
export const ProductState = atom<OnboardingProduct>({
  key: 'onboarding_product',
  default: selector({
    key: 'prod_selc',
    get: ({ get }) => {
      const statusInMain = get(ApplicationState);
      const statusInApp = get(ApplicationState);
      return statusInMain.product || statusInApp.product || '';
    },
  }),
  effects: [],
});

export const PromoCodeState = atom<string>({
  key: 'promo_code_atom',
  default: '',
  // default: selector({
  //   key: 'promo_code_selector',
  //   get: ({ get }) => {
  //     const statusInMain = get(ApplicationState);
  //     const statusInApp = get(ApplicationState);
  //
  //   },
  // }),
});

export const getProductOnboardingStatus = async (
  withFullOwnerData?: boolean,
): Promise<_ApplicationState> => {
  const {
    company,
    user,
    completedOnboarding,
    requiredCredit,
    requiredBanking,
    requiredTreasury,
    required,
    productStatus,
  } = await flexbaseOnboardingClient.getProductOnboardingStatus(
    withFullOwnerData,
  );

  const reqs = (
    company.optedProducts || ['CREDIT', 'BANKING', 'TREASURY']
  ).reduce<string[]>((allReqs, optedProduct) => {
    switch (optedProduct) {
      case 'TREASURY': {
        return union(allReqs, requiredTreasury);
      }
      case 'CREDIT': {
        return union(
          allReqs,
          productStatus.credit.status === 'unqualified' ? [] : requiredCredit,
        );
      }
      case 'BANKING': {
        return union(allReqs, requiredBanking);
      }
    }
  }, []);

  const hasExpiredPassword = user.roles.includes('NO-LOGINS');

  const additionalRequirements = hasExpiredPassword
    ? ['user.changePassword']
    : [];

  const userIsApplicant = user.id === company.createdBy;
  // TODO: enable multi product onboarding
  //  completedOnboarding: reqs.length === 0 ? 'complete' : '',
  return {
    product: mapProductsArrayToProduct(company.optedProducts || []),
    optedProducts: company.optedProducts,
    company: { ...company },
    user: { ...user },
    requiredCredit: requiredCredit
      .filter(
        (r) =>
          userIsApplicant ||
          (!r.startsWith('company') && r !== 'user.plaidConnection'),
      )
      .concat(additionalRequirements),
    requiredTreasury: requiredTreasury.filter(
      (r) =>
        userIsApplicant ||
        (!r.startsWith('company') && r !== 'user.plaidConnection'),
    ),
    requiredBanking: requiredBanking.filter(
      (r) =>
        userIsApplicant ||
        (!r.startsWith('company') && r !== 'user.plaidConnection'),
    ),
    requiredProperties: reqs.filter(
      (r) =>
        userIsApplicant ||
        (!r.startsWith('company') && r !== 'user.plaidConnection'),
    ),
    completedOnboarding: completedOnboarding || '',
    requiredStripeCredit: required.filter(
      (r) =>
        userIsApplicant ||
        (!r.startsWith('company') && r !== 'user.plaidConnection'),
    ),
    productStatus: { ...productStatus },
    userType: user.roles.includes('ADMIN') ? 'admin' : 'other',
    userIsApplicant,
  };
};

export type OptedProduct = 'BANKING' | 'CREDIT' | 'TREASURY';

// ONLY USE IN APPLICATION FLOW
export const ApplicationState = atom<_ApplicationState>({
  key: 'product_onboarding_state',
  default: {
    product: '',
    company: { ...DEFAULT_APP_STATE_VALUES.company },
    user: { ...DEFAULT_APP_STATE_VALUES.user },
    requiredCredit: [],
    productStatus: { ...DEFAULT_APP_STATE_VALUES.productStatus },
    completedOnboarding: '',
    requiredBanking: [],
    requiredProperties: [],
    requiredStripeCredit: [],
    optedProducts: [],
    requiredTreasury: [],
    userType: 'other',
    userIsApplicant: false,
  },
});

// user and company helper/selector reference
export const CompanySelector = selector<OnboardingCompany>({
  key: 'application_company_selector',
  get: ({ get }) => {
    const { company } = get(ApplicationState);
    return company;
  },
});

// if owner list, then find largest %
// if equal %s, default to user that started application
export const MainOwnerSelector = selector<OwnerType>({
  key: 'application_owner_selector',
  get: ({ get }) => {
    const { company } = get(ApplicationState);
    return company.owners.reduce((prev, current) => {
      return prev.ownershipPct! > current.ownershipPct! ? prev : current;
    });
  },
});

export const UserSelector = selector<OnboardingUser>({
  key: 'application_user_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user;
  },
});

export const RolesSelector = selector<UserRole[]>({
  key: 'user_roles',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles;
  },
});

// roles helpers
export const IsAdmin = selector<boolean>({
  key: 'is_admin_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('ADMIN');
  },
});

export const IsEmployee = selector<boolean>({
  key: 'is_employee_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('EMPLOYEE');
  },
});

export const IsAccountant = selector<boolean>({
  key: 'is_accountant_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('ACCOUNTANT');
  },
});

export const IsPlaidStale = selector<boolean>({
  key: 'is_plaid_stale_selector',
  get: ({ get }) => {
    const { user } = get(ApplicationState);
    return user.roles.includes('PLAID-STALE');
  },
});

export const OnboardingUserPhone = selector<string>({
  key: 'onboarding_user_phone_selector',
  get: ({ get }) => {
    try {
      const unformatted = get(ApplicationState)?.user?.cellPhone || '';

      return formatUSPhoneNumber(unformatted);
    } catch (e) {
      return '';
    }
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return newValue;
    }
    set(ApplicationState, (prev) => {
      const formatted = formatPhoneForApi(newValue);
      return {
        ...prev,
        user: { ...prev.user, phone: formatted, cellPhone: formatted },
      };
    });
  },
});

/**
 * This is intended to be used in places where we need to save a user value without saving it to the API
 * or otherwise altering the product onboarding state. Do not rely on this to have data.
 */
export const InProgressUser = atom<{ phone: string }>({
  key: 'in_progress_user',
  default: {
    phone: '',
  },
});

export const ProductOnboardingBtnLoaderState = atom<boolean>({
  key: 'product_onboarding_btn_loader_state',
  default: false,
});

export const ProductNavStack = atom<string[]>({
  key: 'product_nav_stack',
  default: [],
});

export const ProductCurrentStep = atom<string>({
  key: 'product_curr_step',
  default: '',
});

export type AddressWithString = { address: IAddress; addressString: string };
type _ExistingAddresses = AddressWithString[];

export const ExistingAddresses = atom<_ExistingAddresses>({
  key: 'product_existing_addresses',
  default: selector({
    key: 'product_existing_address_selector',
    get: ({ get }) => {
      const { user, company } = get(ApplicationState);
      const addressArray: _ExistingAddresses = [];
      if (user.address.line1) {
        addressArray.push({
          address: user.address,
          addressString: formatFullAddressToString(user.address),
        });
      }
      if (company.address.line1) {
        addressArray.push({
          address: company.address,
          addressString: formatFullAddressToString(company.address),
        });
      }
      return addressArray;
    },
  }),
  effects: [persistAtom],
});
