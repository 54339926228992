import { Invoice } from 'flexbase-client';
import { useStyles } from '../credit-transactions.styles';
import { CSSProperties, useCallback } from 'react';
import { DateTime } from 'luxon';
import { Avatar } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { ArrowRightIconGreen } from 'assets/svg';

type TransactionDetailProps = {
  transaction: Invoice & { storeLogoUrl: string };
  showBottomBorder: boolean;
  customStyles?: CSSProperties;
  onClick: (transction: Invoice) => void;
};

const TransactionDetailRow = ({
  transaction,
  customStyles,
  showBottomBorder,
  onClick,
}: TransactionDetailProps) => {
  const { classes } = useStyles();

  const getDateString = useCallback((): string => {
    const date = DateTime.fromSQL(transaction.createdAt);

    if (date.diffNow('days').days > -7) {
      return date.toLocaleString({ weekday: 'long' });
    }

    return date.toLocaleString({
      month: 'numeric',
      day: '2-digit',
    });
  }, [transaction]);

  return (
    <>
      <div
        className={classes.transactionRow}
        style={customStyles}
        onClick={() => onClick(transaction)}
      >
        {transaction.storeLogoUrl ? (
          <img
            src={transaction.storeLogoUrl}
            className={classes.storeLogo}
            alt={transaction.storeName}
          />
        ) : (
          <Avatar
            radius="md"
            className={classes.storeIcon}
            style={{ minWidth: 'unset' }}
          >
            {transaction.storeName?.charAt(0) || '$'}
          </Avatar>
        )}
        <div>
          <div className={classes.text}>{transaction.storeName}</div>
          <div
            className={classes.text}
            style={{ color: '#808080', fontSize: '12px' }}
          >
            {transaction.origin === 'bnpl' ? 'Flexbase Pay' : 'Card'}
          </div>
          <div
            className={classes.text}
            style={{ color: '#808080', fontSize: '12px' }}
          >
            {getDateString()}
          </div>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <div
            className={classes.text}
            style={{
              fontSize: '16px',
              color: +transaction.total < 0 ? 'green' : '',
            }}
          >
            {+transaction.total < 0 && '+ '}
            {formatCurrency(Math.abs(+transaction.total))}
            <span style={{ marginLeft: '8px' }}>
              <ArrowRightIconGreen />
            </span>
          </div>
        </div>
      </div>
      {showBottomBorder && (
        <div
          style={{
            marginLeft: '8px',
            borderBottom: '1px solid #E6E7E9',
          }}
        ></div>
      )}
    </>
  );
};

export default TransactionDetailRow;
