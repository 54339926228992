import PaymentStep, { getPrettyMethod } from '../payment-step';
import {
  createStyles,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useRef, useState } from 'react';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { AttachmentIcon, SafeBoxIcon, TrashIcon } from 'assets/svg';
import { DepositAccount } from 'services/flexbase/banking.model';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  depositAccountList,
  moneyMovementAmountDetails,
  paymentMethod,
  paymentRecipientName,
} from './payment.states';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import AccountBox from 'components/account-box/account-box';

type Props = {
  onNextClick: () => void;
  onBackClick: () => void;
};

const PaymentAmount = ({ onBackClick, onNextClick }: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  // payment flow state
  const [moneyMovementDetails, setMoneyMovementDetails] = useRecoilState(
    moneyMovementAmountDetails,
  );
  const recipientName = useRecoilValue(paymentRecipientName);
  const method = useRecoilValue(paymentMethod);
  const accounts = useRecoilValue(depositAccountList);
  const openAccounts = accounts.filter((a) => a.status === 'Open');

  // local state
  const [internalNote, setInternalNote] = useState(
    moneyMovementDetails.internalNotes || '',
  );
  const [paymentAmount, setPaymentAmount] = useState(
    moneyMovementDetails.amount || 0,
  );
  const [memoText, setMemoText] = useState(moneyMovementDetails.memo || '');
  const [showAccountList, setShowAccountList] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<DepositAccount>(
    moneyMovementDetails.sourceAccount.accountNumber
      ? moneyMovementDetails.sourceAccount
      : openAccounts[0],
  );

  const [hasError, setHasError] = useState({ memo: false, amount: false });
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length === 0) {
      setPaymentAmount(0);
      return;
    }

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else if (currentValue.length > 9) {
      return;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    if (hasError && Number.parseFloat(currentValue) >= 25) {
      setHasError({ ...hasError, amount: false });
    }

    setPaymentAmount(Number.parseFloat(currentValue));
  };

  const onNext = () => {
    if (paymentAmount && memoText) {
      // check wire minimum
      if (method === 'wire' && paymentAmount < 25) {
        setHasError({ ...hasError, amount: true });
        return;
      }
      setHasError({ ...hasError, amount: false });
      setMoneyMovementDetails({
        amount: paymentAmount,
        sourceAccount: selectedAccount,
        memo: memoText,
        internalNotes: internalNote,
        files,
      });
      onNextClick();
    } else if (!memoText) {
      setHasError({ ...hasError, memo: true });
    }
  };

  const onBack = () => {
    setMoneyMovementDetails({
      amount: paymentAmount,
      sourceAccount: selectedAccount,
      memo: memoText,
    });
    onBackClick();
  };

  // upload files
  const [files, setFiles] = useState<File[]>(moneyMovementDetails.files || []);
  const addFile = async (droppedFiles: File[]) => {
    setFiles([...files, ...droppedFiles]);
  };

  return (
    <PaymentStep
      balloonText={`${getPrettyMethod(method)} payment to ${recipientName}`}
      onNextClick={onNext}
      onBackClick={onBack}
    >
      <div
        style={{
          display: 'flex',
          borderBottom: `3px solid ${theme.fn.themeColor(
            'primarySecondarySuccess',
            2,
          )}`,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <TextInput
          variant="unstyled"
          value={formatCurrency(paymentAmount)}
          onChange={(e) => onInputChange(e.target.value)}
          classNames={{ root: classes.inputRoot, input: classes.input }}
          ref={inputRef}
          error={hasError.amount && 'wire amount must be greater than $25.00'}
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length,
            );
          }}
          data-testid={'amount'}
        />
      </div>
      <div className={classes.sectionHeader}>Recipient account</div>
      <AccountBox
        onClick={() => setShowAccountList((prev) => !prev)}
        showArrow={accounts.length > 1}
        rotateArrow={showAccountList}
        isListItem={false}
        icon={<SafeBoxIcon />}
        headerText={`${
          selectedAccount.nickName || selectedAccount.name || 'Account'
        }`}
        subheaderText={`${formatCurrency(selectedAccount.available / 100)} / ${
          selectedAccount.accountType[0].toUpperCase() +
          selectedAccount.accountType.slice(1)
        } ${getPaddedAccountMask(selectedAccount.accountNumber, 2)}`}
        data-testid={'deposit-accounts-list'}
        showBorder
      />
      {showAccountList && openAccounts.length > 1 && (
        <div className={classes.selectList}>
          {accounts
            .filter((a) => a.id !== selectedAccount.id)
            .map((c, index) => (
              <AccountBox
                headerText={`${c.nickName || c.name || 'Account'}`}
                subheaderText={`${formatCurrency(c.available / 100)} / ${
                  c.accountType[0].toUpperCase() + c.accountType.slice(1)
                } ${getPaddedAccountMask(c.accountNumber, 2)}`}
                onClick={() => {
                  setSelectedAccount(c);
                  setShowAccountList(false);
                }}
                showArrow={false}
                rotateArrow={false}
                isListItem={true}
                icon={<SafeBoxIcon />}
                key={index}
                data-testid={`deposit-accounts-list-${index}`}
              />
            ))}
        </div>
      )}
      <div className={classes.sectionHeader}>
        External Memo{' '}
        <span>
          (Required, max {getPrettyMethod(method) === 'ACH' ? 10 : 50}{' '}
          characters)
        </span>
      </div>
      <TextInput
        placeholder="Description for counterparty statement"
        maxLength={getPrettyMethod(method) === 'ACH' ? 10 : 50}
        value={memoText}
        error={hasError.memo && 'Memo required for payment'}
        onChange={(e) => {
          setMemoText(e.target.value);
          if (hasError && e.target.value) {
            setHasError({ ...hasError, memo: false });
          }
        }}
        data-testid={'memo'}
      />
      <div className={classes.sectionHeader}>
        Internal Notes <span>(Optional)</span>
      </div>
      <Textarea
        placeholder="Enter your internal notes here"
        styles={{ input: { height: '90px !important' } }}
        onChange={(e) => {
          setInternalNote(e.target.value);
        }}
        value={internalNote}
        data-testid={'internal-note'}
      ></Textarea>

      <div className={classes.sectionHeader}>
        Internal attachments <span>(Optional)</span>
      </div>
      <Dropzone
        onDrop={(file) => addFile(file)}
        maxSize={3 * 1024 ** 2}
        accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
        classNames={{
          root: classes.dropZone,
        }}
      >
        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <AttachmentIcon />
          Upload an attachment for this payment
        </div>
      </Dropzone>
      {files.length > 0 && (
        <div className={classes.filesContainer}>
          {files.map((file, i) => (
            <div key={file.name + i} className={classes.file}>
              {file.name}
              <button
                className={classes.deleteFile}
                onClick={() => {
                  setFiles(files.filter((f) => f !== file));
                }}
              >
                <TrashIcon width={16} color={theme.fn.primaryColor()} />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className={classes.attachamentsText}>
        Visible only to individuals within your organization, invoices,
        contracts, or other supporting documents that you may want to reference
        at a later date
      </div>
    </PaymentStep>
  );
};

const useStyles = createStyles((theme) => ({
  input: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '48px',
    textAlign: 'center',
    height: 'fit-content',
    border: 'unset',
  },
  inputRoot: { width: 'fit-content' },
  icon: { opacity: 0.8 },
  sectionHeader: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    marginTop: '32px',
    marginBottom: '8px',
    span: {
      fontSize: '12px',
      color: '#979797',
    },
  },
  selectList: {
    position: 'absolute',
    maxHeight: '250px',
    width: '356px',
    overflow: 'scroll',
    marginTop: 4,
    backgroundColor: 'white',
    zIndex: 1,
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  textArea: {
    height: '152px',
  },
  filesContainer: {
    minHeight: '50px',
    display: 'column',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: '40px',
    margin: '8px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    fontSize: '12px',
    fontWeight: 500,
    color: theme.fn.themeColor('neutral', 6),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  deleteFile: {
    all: 'unset',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  dropZone: {
    gap: 10,
    padding: 12,
    width: '100%',
    fontSize: 14,
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    color: theme.fn.themeColor('neutral', 6),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
  attachamentsText: {
    color: '#979797',
    fontSize: '12px',
    margin: '15px 0px',
  },
}));

export default PaymentAmount;
