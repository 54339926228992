import { createStyles } from '@mantine/core';

export const projectStyles = createStyles(() => ({
  container: {
    '@media(max-width: 767px)': {
      '.table': {
        margin: '4%',
        width: 'auto',
        borderRadius: '8px',
        td: {
          border: 'none',
          paddingBottom: '0px',
          width: 'auto',
        },
        th: {
          border: 'none',
        },
        tr: {
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid grey',
          padding: '1em',
          marginBottom: '1em',
          borderRadius: '4px',
        },
        thead: {
          display: 'none',
        },
        'td[data-title]': {
          display: 'flex',
        },
        'td[data-title]::before': {
          content: `attr(data-title)`,
          display: 'grid',
          gridTemplateColumns: '6rem',
          fontWeight: 'bold',
        },
        '.btnEditProject': {
          width: '100%',
          height: '30px',
          borderRadius: '4px',
          marginTop: '4%',
        },
      },
    },
  },

  title: {
    fontWeight: 300,
    fontFamily: 'PP Neue Montreal',
    '@media(max-width: 767px)': {
      fontSize: '23px',
    },
  },

  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
    alignItems: 'center',
  },

  description: {
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '14px',
    color: '#6b7280',
    marginBottom: '35px',
    marginTop: '0.5rem',
  },

  containerMessage: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
  },

  messageText: {
    color: '#6F7181',
    fontSize: '14px',
    textAlign: 'center',
    width: '80%',
  },
}));
