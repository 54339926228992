import { useState } from 'react';
import { createStyles, TextInput } from '@mantine/core';

import AuthContent from 'components/login/auth-content';

interface TwoFAProps {
  errorMsg: string;
  onGoBack: () => void;
  onResendCode: () => void;
  onChange: (code: string) => void;
  setErrorMsg: (msg: string) => void;
}

const AuthVerifyCode = ({
  errorMsg,
  onChange,
  onGoBack,
  onResendCode,
  setErrorMsg,
}: TwoFAProps) => {
  const { classes } = useStyles();
  const [code, setCode] = useState('');

  const onSubmit = () => {
    if (code.length < 6) {
      setErrorMsg('Please enter a valid code');
    }
  };

  return (
    <AuthContent
      screen="2FA"
      errorMsg={errorMsg}
      onSubmit={onSubmit}
      onGoback={onGoBack}
      onResendCode={onResendCode}
      submitButtonLabel="Continue"
      title="Enter your verification code"
    >
      <TextInput
        label="Code"
        maxLength={6}
        type="number"
        error={!!errorMsg}
        id="one-time-code"
        classNames={classes}
        placeholder="Enter 6 digit code"
        data-testid="input-one-time-code"
        onChange={(e) => {
          setCode(e.target.value);
          onChange(e.target.value);
        }}
      />
    </AuthContent>
  );
};

export default AuthVerifyCode;

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.colors.critical[2],
  },
}));
