import { createStyles } from '@mantine/core';

interface Props {
  isMobile?: boolean;
}

export const useStyles = createStyles((theme, { isMobile }: Props) => ({
  contentContainer: {
    backgroundColor: '#FFFFFF',
    width: isMobile ? 300 : 421,
    height: 227,
    marginTop: 177,
    marginRight: isMobile ? 100 : 0,
    padding: isMobile ? 25 : '28px 31px 32px 38px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    position: 'relative',
  },
  title: {
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontSize: isMobile ? 20 : 24,
    fontWeight: 400,
    lineHeight: '29px',
    color: '##000000',
  },
  escapeIcon: {
    color: '#979797',
    cursor: 'pointer',
  },
  messageContainer: {
    width: isMobile ? 246 : 346,
    height: 54,
    marginTop: 20,
  },
  message: {
    color: '#5F5F5F',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '23px',
  },
  actionButtonsContainer: {
    display: 'flex',
    marginTop: isMobile ? 30 : 27,
  },
  cancel: {
    color: '#979797',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    background: '#FFF',
    width: isMobile ? 130 : 170,
    height: 40,
    border: '1px solid #979797',
    borderRadius: '8px',
    '&:hover': {
      border: 'none',
      background: 'lightgray',
      color: '#FFF',
    },
    marginRight: 10,
    padding: '12px 12px 12px 12px',
  },
  delete: {
    background: theme.fn.primaryColor(),
    width: isMobile ? 120 : 165,
    height: 40,
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderRadius: '8px',
    color: '#FFF',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
    '&:hover': {
      border: 'none',
      background: 'rgba(255, 87, 69, 0.08);',
      color: theme.fn.primaryColor(),
    },
    padding: '12px 12px 12px 12px',
  },
}));
