import { Text } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './spent-this-week.styles';
import { getCurrentWeek } from 'utilities/dates/dates';
import flexbaseClient from 'services/flexbase-client';
import { Invoice } from 'flexbase-client';
import { useEffect, useState } from 'react';
import { UserInfoState } from 'states/user/user-info';
import { useRecoilValue } from 'recoil';

const SpentWeek = () => {
  const { id } = useRecoilValue(UserInfoState);
  const [spentWeek, setSpentWeek] = useState({ total: 0, quantityInv: 0 });
  const { classes } = useStyles();

  const getSpentWeek = async () => {
    try {
      const dates = getCurrentWeek();
      const result = await flexbaseClient.getInvoicesByUser(id, {
        after: dates.sunday,
        before: dates.saturday,
        includeMerchantName: true,
      });
      if (result && result.length > 0) {
        const spentWeek = result
          .map((item: Invoice) => (Number(item?.total) > 0 ? +item.total : 0))
          .reduce((prev, curr) => prev + curr, 0);

        const quantityInv = 0;

        result
          .map((item: Invoice) =>
            Number(item?.total) > 0 ? quantityInv + 1 : 0,
          )
          .reduce((prev, curr) => prev + curr, 0);
        setSpentWeek({ quantityInv, total: spentWeek });
      }
    } catch (error) {
      console.error('Unable to get invoices', error);
    }
  };

  useEffect(() => {
    getSpentWeek();
  }, []);

  return (
    <div className={classes.spentWeekContainer}>
      <div className={classes.totalSpentWeekContainer}>
        <div>
          <Text className={classes.spentWeekLabel}>
            Total amount spent this week
          </Text>
          <div className={classes.totalSpentWeek}>
            {formatCurrency(spentWeek.total ?? 0)}
          </div>
        </div>
        <div style={{ marginTop: '4.3rem' }}>
          <Text className={classes.purchasesText}>
            You’ve made{' '}
            <strong style={{ color: 'black' }}>{spentWeek.quantityInv}</strong>{' '}
            transactions this week
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SpentWeek;
