import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Navigate } from 'react-router-dom';
import { useQuery } from 'utilities/url/query-param';
import flexbaseClient from 'services/flexbase-client';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { NoAuthUrlState } from 'states/pending-url/pending-url-state';
import { useAuthToken } from '../../states/auth/auth-token';
import { ApplicationState } from '../../states/application/product-onboarding';

type Props = { children: React.ReactNode };

const BnplRoute = ({ children }: Props) => {
  const queryParams = useQuery();
  const noAuthUrl = useRecoilValue(NoAuthUrlState);
  const setBnplState = useSetRecoilState(BnplState);
  const { tokenIsValid: isAuthorized } = useAuthToken();
  const { completedOnboarding } = useRecoilValue(ApplicationState);

  const getBnplData = async () => {
    const apiKey = queryParams.get('apiKey') ?? queryParams.get('apikey');
    const amount = queryParams.get('amount');
    if (apiKey) {
      const merchant = await flexbaseClient.getMerchant(apiKey);

      if (merchant) {
        setBnplState({
          isBnpl: true,
          hasError: false,
          merchantLogoUrl: merchant.logoUrl,
          amount: amount || '',
          merchantName: merchant.name,
          callbackParam: queryParams.get('callback') || '',
          callbackBaseUrl: merchant.baseUrl,
          sessionParam: queryParams.get('session') || '',
          status: TransactionStatus.pending,
          invoiceId: '',
        });
      }
    } else {
      setBnplState((prev) => ({ ...prev, hasError: true, isBnpl: true }));
    }
  };

  useEffect(() => {
    getBnplData();
  }, []);

  if (!isAuthorized()) {
    return <Navigate to={noAuthUrl} replace={true} />;
  }

  if (!completedOnboarding) {
    return <Navigate to="/application" />;
  }

  return <>{children}</>;
};

export default BnplRoute;
