import { createStyles, Text, Title, useMantineTheme } from '@mantine/core';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';

interface Props {
  closeModal: () => void;
  merchantCallbackCancelUrl?: string;
}

const useStyles = createStyles((theme) => ({
  failedLoc: {
    '@media(max-width: 767px)': {
      width: '85%',
      minHeight: '87.5vh',
      justifyContent: 'space-between',
    },
    width: '600px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    margin: 'auto',
  },
  wereSorry: {
    '@media(max-width: 767px)': {
      width: '97.5%',
      marginTop: '10%',
    },
    marginLeft: '5px',
    marginTop: '10%',
    fontSize: '21px',
    fontWeight: 700,
  },
  title: {
    '@media(max-width: 767px)': {
      fontSize: '24px',
    },
    margin: '1.75% 0 1.75% 5px',
    fontWeight: 300,
    fontSize: '38px',
    fontFamily: 'PP Neue Montreal',
  },
  subtitle: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      margin: '7.5% 0 7.5% 5px',
    },
    margin: '1.75% 0 3.5% 5px',
    fontWeight: 300,
    fontSize: '24px',
    fontFamily: 'PP Neue Montreal',
  },
  failedlocBlock: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100px',
    },
    border: '2px solid #F2F2F2',
    borderRadius: '10px',
    width: '100%',
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1% 0 1% 0',
    padding: '1% 3% 1% 3%',
  },
  failedlocText: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
    },
    fontSize: '18px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
  // approveButton: {
  //   [`@media (max-width: ${theme.breakpoints.md}px)`]: {
  //     fontSize: '20px',
  //   },
  //   height: '55px',
  //   backgroundImage: 'linear-gradient(to bottom right, #FF5745, #FF8779)',
  //   color: '#FFFFFF',
  //   borderRadius: '10px',
  //   fontSize: '22px',
  //   marginTop: '7%',
  // },
  // cancelButton: {
  //   [`@media (max-width: ${theme.breakpoints.md}px)`]: {
  //     fontSize: '20px',
  //   },
  //   height: '55px',
  //   color: '#8A8A8F',
  //   fontSize: '22px',
  //   fontWeight: 700,
  //   margin: '2% auto 2% auto',
  //   cursor: 'pointer',
  // },
}));

const BnplFailedLineOfCredit = ({
  closeModal,
  merchantCallbackCancelUrl,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const cancel = (): void => {
    window.location.replace(merchantCallbackCancelUrl!);
    closeModal();
  };

  setTimeout(() => {
    window.location.replace(merchantCallbackCancelUrl!);
  }, 7000);

  return (
    <div>
      <FlexbaseHeader onCloseButtonClick={cancel} />
      <div className={classes.failedLoc}>
        <Text
          size="lg"
          weight="bold"
          align="left"
          className={classes.wereSorry}
          color={theme.fn.primaryColor()}
        >
          {`We're sorry`}
        </Text>
        <Title order={1} align="left" className={classes.title}>
          Not enough available credit
        </Title>
        <Title order={2} align="left" className={classes.subtitle}>
          Unfortunately, you do not have enough available credit. Either reduce
          the transaction amount you are trying or contact Flexbase support.
        </Title>
        <div className={classes.failedlocBlock}>
          <Text className={classes.failedlocText} weight="bold">
            Expansion loan through banking partners
          </Text>
          <Text className={classes.failedlocText}>
            1% APY business checking account
          </Text>
        </div>
        <div className={classes.failedlocBlock}>
          <Text className={classes.failedlocText} weight="bold">
            Daily spend cards
          </Text>
        </div>
        <div className={classes.failedlocBlock}>
          <Text className={classes.failedlocText} weight="bold">
            And much more...
          </Text>
        </div>

        {/*<div className={classes.buttons}>*/}
        {/*  <Button*/}
        {/*    fullWidth={true}*/}
        {/*    className={classes.approveButton}*/}
        {/*    onClick={onContinue}*/}
        {/*  >*/}
        {/*    Click here to explore other options*/}
        {/*  </Button>*/}
        {/*  <Text className={classes.cancelButton} onClick={cancel}>*/}
        {/*    Restart application process*/}
        {/*  </Text>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default BnplFailedLineOfCredit;
