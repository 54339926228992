import {
  Button,
  Flex,
  Grid,
  Modal,
  Switch,
  TextInput,
  Text,
  Divider,
} from '@mantine/core';
import { useStyles } from './styles';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { DepositAccount } from 'services/flexbase/banking.model';
import { showNotification } from '@mantine/notifications';
import { useRecoilState } from 'recoil';
import { DepositAccountsState } from '../states/deposit-accounts';

interface Props {
  addModal: boolean;
  setAddModal: (set: boolean) => void;
}
const NewAccountModal = ({ addModal, setAddModal }: Props) => {
  const { classes } = useStyles();
  const [, setBankAccount] = useState<DepositAccount>();
  const [, setLoading] = useState(false);
  const [bankingAccounts, setBankingAccounts] =
    useRecoilState(DepositAccountsState);

  const form = useForm({
    initialValues: {
      isDefault: false,
      nickName: undefined,
    },
  });

  const addDepositAccount = async () => {
    try {
      setLoading(true);
      const result = await flexbaseBankingClient.createDepositAccount({
        ...form.values,
      });

      if (result.success && result.depositAccount) {
        setBankAccount(result.depositAccount);
        setBankingAccounts([...bankingAccounts, result.depositAccount]);
      }
      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: 'Successfully added a new bank account!',
      });
      setAddModal(false);
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `We were unable to add a new bank account at this time. Error: ${error}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      opened={addModal}
      onClose={() => {
        setAddModal(false);
      }}
      title="Open new Flex bank account"
      centered
      styles={{
        header: { fontSize: 20 },
      }}
    >
      <>
        <Divider mb={20} />
        <Grid>
          <Grid.Col span={12}>
            <form onSubmit={form.onSubmit(addDepositAccount)}>
              <Text fw={400} size={14} color="neutral.7">
                Your account will be immediately created and ready for use.
              </Text>
              <TextInput
                mt={30}
                label="Account name"
                placeholder="Write a nickname"
                {...form.getInputProps('nickName')}
              ></TextInput>
              <Switch
                label="Default Account?"
                className={classes.switch}
                color="primarySecondarySuccess.2"
              />
              <Flex justify="flex-end" gap={20}>
                <Button variant="outline" onClick={() => setAddModal(false)}>
                  Cancel
                </Button>
                <Button variant="light" type="submit">
                  Add
                </Button>
              </Flex>
            </form>
          </Grid.Col>
        </Grid>
      </>
    </Modal>
  );
};

export default NewAccountModal;
