import { Alert, createStyles, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useModal from 'components/modal/modal-hook';
import { InfoIcon } from '@flexbase-eng/web-components';

type Props = {
  message: string;
  link?: {
    text: string;
    url: string;
  };
  icon?: React.ReactNode;
  color?: string;
  style?: React.CSSProperties;
  styleMessage?: React.CSSProperties;
  withCloseButton?: boolean;
};

const AlertWithLink = ({
  message,
  link,
  icon = <InfoIcon />,
  color = 'info',
  style = { marginBottom: '16px' },
  withCloseButton = true,
}: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { closeAllModals } = useModal();
  return (
    <Alert
      withCloseButton={withCloseButton}
      icon={icon}
      style={style}
      color={color}
    >
      <Text className={classes.message}>
        {message}{' '}
        {link && (
          <span
            className={classes.link}
            onClick={() => {
              closeAllModals();
              navigate(link.url);
            }}
          >
            {link.text}
          </span>
        )}
      </Text>
    </Alert>
  );
};

const useStyles = createStyles((theme) => ({
  link: {
    marginLeft: '5px',
    fontWeight: 600,
    textDecoration: 'underline',
    ':hover': {
      cursor: 'pointer',
    },
  },
  message: {
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
}));

export default AlertWithLink;
