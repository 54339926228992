import { ApplicationConfigBuilder } from './product-onboarding.models';

const configBuilder = new ApplicationConfigBuilder(
  'BANKING',
).forRequirementArray('requiredBanking');
configBuilder
  .addRoute('change-password', 1, true)
  .withRequiredField('user.changePassword');
configBuilder
  .addRoute('verify-phone', 2, false)
  .withRequiredField('user.phone')
  .withRequiredField('user.cellPhone');
configBuilder
  .addRoute('user-address', 3, false)
  .withRequiredField('user.address');
configBuilder
  .addRoute('verify-identity', 4, false)
  .withRequiredField('user.taxId')
  .withRequiredField('user.birthDate');
configBuilder
  .addRoute('business-type', 5)
  .withRequiredField('company.legalStructure');
configBuilder
  .addRoute('business-activity', 6)
  .withRequiredField('company.companyName')
  .withRequiredField('company.website')
  .withRequiredField('company.annualRevenue')
  .withRequiredField('company.businessPurpose')
  .withRequiredField('company.category')
  .withRequiredField('company.monthlyExpenditure')
  .withRequiredField('company.businessVertical');
configBuilder
  .addRoute('verify-business', 7)
  .withRequiredField('company.taxId')
  .withRequiredField('company.formationDate')
  .withRequiredField('company.phone')
  .withRequiredField('company.address');
configBuilder
  .addRoute('business-owners', 8)
  .withRequiredField('company.owners')
  .withRequiredField('user.jobTitle');
configBuilder
  .addRoute('summary', 9, false)
  .withRequiredField('user.termsOfServiceSigned')
  .withRequiredField('user.bankingTermsOfServiceSigned');

export const BankingApplicationConfig = configBuilder.build();
