import FlexbaseConfetti from 'components/confetti/flexbase-confetti';
import React, { useState } from 'react';
import ReviewAndPay from './review-and-pay';
import PaymentSuccess from './payment-success';
import PaymentError from './payment-error';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';

type Props = { closeModal: () => void };

const CardRepaymentModal = ({ closeModal }: Props) => {
  const [step, setStep] = useState<'review' | 'success' | 'error'>('review');

  const [amountPaid, setAmountPaid] = useState(0);
  const [paymentId, setPaymentId] = useState('');

  const getContent = () => {
    switch (step) {
      case 'review':
        return (
          <ReviewAndPay
            closeModal={closeModal}
            onSuccess={(id, paymentAmount) => {
              setAmountPaid(paymentAmount);
              setPaymentId(id);
              setStep('success');
            }}
            onError={() => setStep('error')}
          />
        );
      case 'success':
        return (
          <PaymentSuccess
            paymentAmount={amountPaid}
            paymentId={paymentId}
            onCloseClick={closeModal}
          />
        );
      case 'error':
        return (
          <PaymentError
            onClose={closeModal}
            onTryAgain={() => {
              setPaymentId('');
              setAmountPaid(0);
              setStep('review');
            }}
          />
        );
    }
  };

  return (
    <>
      {step === 'success' && <FlexbaseConfetti />}
      <FullScreenModalContainer closeModal={closeModal}>
        {getContent()}
      </FullScreenModalContainer>
    </>
  );
};

export default CardRepaymentModal;
