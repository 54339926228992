import { Dispatch, forwardRef, SetStateAction, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Text,
  AutocompleteItem,
  Autocomplete,
  SelectItemProps,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaCheck, FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { modalFormStyles } from './modal-form.styles';
import flexbaseClient from 'services/flexbase-client';
import { useDebouncedCallback } from 'use-debounce';
import { IProjectInfo } from 'states/projects/project-info.interface';
import { Address, CreateOrUpdateProjectResponse } from 'flexbase-client';

interface ItemProps extends SelectItemProps {
  label: string;
  description: string;
}

interface IModalForm {
  closeModal: () => void;
  project?: IProjectInfo;
  setAllProjects: Dispatch<SetStateAction<IProjectInfo[]>>;
}

interface IAddress {
  value: string;
  label: string;
  description: string;
  addr: Address;
}

const ModalForm = ({ closeModal, project, setAllProjects }: IModalForm) => {
  const { classes } = modalFormStyles();
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const debounced = useDebouncedCallback((value) => getAddresses(value), 200, {
    maxWait: 1000,
    leading: true,
  });

  const theForm = useForm<IProjectInfo>({
    initialValues: {
      id: project?.id || '',
      name: project?.name || '',
      street1: project?.street1 || '',
      street2: project?.street2 || '',
      state: project?.state || '',
      city: project?.city || '',
      postalCode: project?.postalCode || '',
      description: project?.description || '',
    },
  });

  const createProject = async (values: IProjectInfo) => {
    try {
      setIsLoading(true);
      const { id, name, description } = values;
      const response: CreateOrUpdateProjectResponse | null =
        await flexbaseClient.createOrUpdateProject({
          id,
          name,
          description,
          location: {
            street1: values.street1 || '',
            street2: values.street2 || '',
            city: values.city || '',
            country: values.country || '',
            postalCode: values.postalCode || '',
            state: values.state || '',
          },
        });

      if (!response) {
        throw 'Unable to create a new project';
      }

      const { street1, city, postalCode, country, state, street2 } =
        response.location;

      setAllProjects((prevProjects: IProjectInfo[]) => [
        ...prevProjects,
        {
          id: response.id,
          name: response.name,
          description: response.description,
          street1,
          street2,
          city,
          postalCode,
          country,
          state,
        },
      ]);
      setTypeMessage('success');
      setMessage('You have a new project!');
    } catch (error) {
      console.error('CREATE PROJECT ERROR', error);
      setTypeMessage('error');
      setMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProject = async (values: IProjectInfo) => {
    try {
      setIsLoading(true);
      const { id, name, description } = values;
      const response: CreateOrUpdateProjectResponse | null =
        await flexbaseClient.createOrUpdateProject({
          id,
          name,
          description,
          location: {
            street1: values.street1 || '',
            street2: values.street2 || '',
            city: values.city || '',
            country: values.country || '',
            postalCode: values.postalCode || '',
            state: values.state || '',
          },
        });
      if (!response) {
        throw 'Unable to update the data';
      }

      const { street1, city, postalCode, country, state, street2 } =
        response.location;

      setAllProjects((prevProjects) =>
        prevProjects.map((project) => {
          if (response?.id === project.id) {
            return {
              id: response.id,
              name: response.name,
              description: response.description,
              street1,
              street2,
              city,
              postalCode,
              country,
              state,
            };
          }
          return project;
        }),
      );
      setTypeMessage('success');
      setMessage('The information has been updated!');
    } catch (error) {
      console.error('UPDATE PROJECT ERROR', error);
      setTypeMessage('error');
      setMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveProject = (values: IProjectInfo) => {
    if (values.id) {
      updateProject(values);
    } else {
      createProject(values);
    }
  };

  const getAddresses = (street: string) => {
    flexbaseClient.getAddressPreview(street).then((x: Address[]) => {
      setAddresses(
        x.map((y: Address) => {
          return {
            value: y.street1 + y.city + y.postalCode,
            label: y.street1,
            description: `${y.city} ${y.state} ${y.postalCode}`,
            addr: y,
          };
        }),
      );
    });
  };

  const setStreet = (street: string) => {
    theForm.setFieldValue('street1', street);
    debounced(street);
  };

  const selectAddress = (item: AutocompleteItem) => {
    const addr = item['addr'];
    theForm.setFieldValue('street1', addr.street1);
    theForm.setFieldValue('city', addr.city);
    theForm.setFieldValue('state', addr.state);
    theForm.setFieldValue('postalCode', addr.postalCode);
  };

  const AddressAutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, label, ...others }: ItemProps, ref) => (
      <div aria-label="addressItem" ref={ref} {...others}>
        <Text size="md">{label}</Text>
        <Text size="md" color="dimmed">
          {description}
        </Text>
      </div>
    ),
  );

  AddressAutoCompleteItem.displayName = 'AddressAutoCompleteItem';

  const inputSize = screen.width <= 767 ? 'md' : 'xl';

  return (
    <Container p="3rem" style={{ position: 'relative' }}>
      <button
        type="button"
        className={classes.btnCloseModal}
        onClick={() => {
          closeModal();
        }}
      >
        <FaTimes size={20} />
      </button>
      <div>
        <div className={classes.title}>
          {theForm.values?.id ? 'Edit Project' : 'Create Project'}
        </div>
      </div>
      <form id="addOrUpdateProject" onSubmit={theForm.onSubmit(saveProject)}>
        <Container mt="1.5rem" p="0">
          <TextInput
            mt="0.3rem"
            size={inputSize}
            radius="md"
            id="name"
            name="name"
            aria-label="name"
            label="Name"
            type="text"
            {...theForm.getInputProps('name')}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <TextInput
            mt="0.3rem"
            size={inputSize}
            radius="md"
            id="description"
            name="description"
            label="Description"
            aria-label="description"
            type="text"
            {...theForm.getInputProps('description')}
          />
        </Container>
        <Container mt="1.5rem" p="0" style={{ position: 'relative' }}>
          <Autocomplete
            id="street1"
            name="street1"
            aria-label="street1"
            label="Address"
            size={inputSize}
            radius="md"
            mt="0.3rem"
            {...theForm.getInputProps('street1')}
            data={addresses}
            onChange={(value) => setStreet(value)}
            onItemSubmit={selectAddress}
            itemComponent={AddressAutoCompleteItem}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <TextInput
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="state"
            name="state"
            label="State"
            aria-label="state"
            {...theForm.getInputProps('state')}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <TextInput
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="city"
            name="city"
            aria-label="city"
            label="City"
            type="text"
            {...theForm.getInputProps('city')}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <TextInput
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="postalCode"
            name="postalCode"
            aria-label="postalCode"
            label="Postal Code"
            type="text"
            {...theForm.getInputProps('postalCode')}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <TextInput
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="street2"
            name="street2"
            aria-label="street2"
            label="Optional Address"
            {...theForm.getInputProps('street2')}
          />
        </Container>
      </form>
      {message !== '' ? (
        <div style={{ marginTop: '1.5rem' }}>
          <Alert
            icon={
              typeMessage === 'success' ? (
                <FaCheck color="black" />
              ) : (
                <FaExclamationCircle color="black" />
              )
            }
            color={typeMessage === 'success' ? 'green' : 'red'}
          >
            <Text color="black">{message}</Text>
          </Alert>
        </div>
      ) : null}

      <div className={classes.buttonHolder}>
        <Button
          type="submit"
          form="addOrUpdateProject"
          aria-label="btnCreateOrUpdateProject"
          color="flexbase-teal"
          size="lg"
          fullWidth
          loading={isLoading}
        >
          {theForm.values?.id ? 'Update Project' : 'Add Project'}{' '}
        </Button>
      </div>
    </Container>
  );
};

export default ModalForm;
