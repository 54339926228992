import { createStyles } from '@mantine/core';
import { baseTableStyles } from 'components/styles';

import { TableStyles } from 'react-data-table-component';

export const useStyles = createStyles((theme) => ({
  container: {
    background: '#ffffff',
    borderRadius: theme.defaultRadius,
    padding: 24,
  },
  titleText: {
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '19px',
    color: '#5f5f5f',
  },
  tableContainer: {
    marginTop: 24,
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
    flexShrink: 0,
    '@media (max-width: 767px)': {
      gap: 0,
    },
  },
  contentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#5f5f5f',
    '@media (max-width: 767px)': {
      fontSize: 12,
    },
  },
  smallContentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '21px',
    color: '#5f5f5f',
  },
  menuEllipsisContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },
}));

export const overrideStyles = (primaryColor?: string) => {
  return {
    button: {
      root: {
        borderRadius: '8px',
        borderColor: primaryColor,
        height: 40,
        marginTop: 16,
        '&:hover': {
          backgroundColor: 'rgba(255, 87, 69, 0.1)',
        },
      },
      inner: { display: 'flex', alignItems: 'center' },
      label: {
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: primaryColor,
      },
    },
    input: {
      input: {
        color: '#5F5F5F',
        width: '368px',
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        background: 'white',
        '&:focus': { borderColor: primaryColor },
        '@media screen and (max-width: 767px)': {
          width: '100%',
        },
      },
    },
    menu: {
      root: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: 'fit-content',
      },
      itemInner: {
        display: 'flex',
        alignItems: 'center',
        color: '#5F5F5F',
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Inter, sans-serif',
        width: 200,
      },
      body: {
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
      },
    },
  };
};

export const externalAccountsTableStyles: TableStyles = {
  ...baseTableStyles(),
  ...{ cells: { style: { ...baseTableStyles().cells?.style, svg: {} } } },
};
