import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { LoadingOverlay } from '@mantine/core';
import { flexbaseOnboardingClient } from '../../services/flexbase-client';
import authenticatedStorage from './authenticated-storage';

export const KEY_TOKEN_STORAGE = 'fb_token';
export const KEY_USER_EMAIL_STORAGE = 'fb_user_email';
export const KEY_REMEMBER_USER = 'fb_remember_user';

type SetUserDataParams = { token: string; email: string; remember: boolean };
type SetUserDataFn = (prev: SetUserDataParams) => SetUserDataParams;

type UseAuthTokenReturnType = {
  token: string;
  setToken: (newToken: string) => void;
  setUserData: (data: SetUserDataParams | SetUserDataFn) => void;
  clearToken: () => void;
  tokenIsValid: () => boolean;
  userEmail: string;
  rememberUser: boolean;
  logout: () => Promise<void>;
};

const AuthContext = createContext<UseAuthTokenReturnType>(
  {} as UseAuthTokenReturnType,
);
type AuthContextProps = { children: ReactNode };
export const AuthProvider = ({ children }: AuthContextProps) => {
  const [token, _setToken] = useState(
    localStorage?.getItem(KEY_TOKEN_STORAGE) || '',
  );
  const [userEmail, _setUserEmail] = useState(
    localStorage?.getItem(KEY_USER_EMAIL_STORAGE) || '',
  );
  const [rememberUser, _setRememberUser] = useState(
    localStorage?.getItem(KEY_REMEMBER_USER) === 'true',
  );
  const [loading, setLoading] = useState(false);

  function setToken(newToken: string) {
    localStorage?.setItem(KEY_TOKEN_STORAGE, newToken);
    _setToken(newToken);
  }

  function clearToken(): void {
    localStorage?.removeItem(KEY_TOKEN_STORAGE);
    setToken('');
  }

  const setUserData = useCallback(
    (dataOrFn: SetUserDataFn | SetUserDataParams) => {
      const data =
        typeof dataOrFn === 'function'
          ? dataOrFn({ token, email: userEmail, remember: rememberUser })
          : dataOrFn;
      setToken(data.token);
      localStorage?.setItem(KEY_USER_EMAIL_STORAGE, data.email);
      _setUserEmail(data.email);
      localStorage?.setItem(
        KEY_REMEMBER_USER,
        data.remember ? 'true' : 'false',
      );
      _setRememberUser(data.remember);
    },
    [token, userEmail, rememberUser],
  );

  const logout = async () => {
    setLoading(true);
    try {
      await flexbaseOnboardingClient.deleteAuthToken();
    } catch (e) {
      console.error('Unable to mark token as deleted.');
    }
    clearToken();
    authenticatedStorage.clear();
    if (rememberUser) {
      setUserData({ token: '', email: userEmail, remember: rememberUser });
    }
    sessionStorage.setItem('logout', 'true');
    setTimeout(() => {
      location.reload();
    }, 500);
  };

  const tokenIsValid = useCallback((): boolean => {
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode<{ exp: number }>(token);
      const timeStuff = DateTime.fromMillis(decodedToken.exp * 1000);
      return timeStuff > DateTime.now();
    } catch (e) {
      return false;
    }
  }, [token]);

  const memo = useMemo(
    () => ({
      token,
      setToken,
      clearToken,
      tokenIsValid,
      userEmail,
      rememberUser,
      logout,
      setUserData,
    }),
    [token, userEmail, rememberUser],
  );

  return (
    <AuthContext.Provider value={memo}>
      {loading ? <LoadingOverlay visible={true} /> : <>{children}</>}
    </AuthContext.Provider>
  );
};

export const useAuthToken = () => {
  return useContext(AuthContext);
};
