import { Anchor, Button, Text } from '@mantine/core';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useStyles } from './invite-user.styles';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';
import Input from 'components/input/flexbase-input';
import ModalSuccess from 'areas/team-members/modal-success/modal-success';
import { CompanyIdState } from 'areas/onboarding/onboarding-form.state';
import OptionBox from 'components/option-box/option-box';

export type UserInviteFormValues = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  role: string;
};

type Props = { closeModal: () => void; refreshTeamMembers?: () => void };

const roles = [
  {
    label: 'Admin',
    value: 'ADMIN',
    description:
      'Full account access, manage users, manage cards, move funds, update settings, manage integrations.',
  },
  {
    label: 'Employee',
    value: 'EMPLOYEE',
    description:
      'Can view transactions on credit cards issued to them, can freeze or unfreeze their cards, dispute transactions, upload receipts and add memos.',
  },
  {
    label: 'Bookkeeper',
    value: 'ACCOUNTANT',
    description:
      'Can view all account transactions, download statements, view memos, download receipts. Cannot make any transfers or transactions.',
  },
];

const InviteUser = ({ closeModal, refreshTeamMembers }: Props) => {
  const { classes } = useStyles();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const companyId = useRecoilValue(CompanyIdState);

  const userInviteForm = useForm<UserInviteFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      role: '',
    },
    validate: {
      firstName: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter the first name of the user you wish to invite.',
      lastName: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter the last name of the user you wish to invite.',
      emailAddress: (v) =>
        validateRequired(v)
          ? null
          : 'Please enter an email address for the user you wish to invite.',
    },
  });

  const sendInvite = async () => {
    try {
      setLoading(true);
      const formValues = userInviteForm.values;
      const validationResult = userInviteForm.validate();

      if (!validationResult.hasErrors) {
        const result = await flexbaseOnboardingClient.inviteUser(
          formValues.firstName,
          formValues.lastName,
          formValues.emailAddress,
          formValues.role,
          companyId,
        );
        if (!result.success) {
          setError('There was an error inviting the new user.');
        } else {
          setError('');
          setSuccess(true);
          if (refreshTeamMembers) {
            refreshTeamMembers();
          }
        }
      }
    } catch (error) {
      setError('There was an error inviting the new user.');
      console.error('Unable to invite user', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {success ? (
        <div>
          <ModalSuccess
            title={`Invite sent to ${userInviteForm.values.firstName}!`}
            backTo="contacts"
            closeModal={closeModal}
            onClick={() => {
              setSuccess(false);
              userInviteForm.setValues({
                firstName: '',
                lastName: '',
                emailAddress: '',
                role: '',
              });
            }}
            textToStart="Invite another person"
          />
        </div>
      ) : (
        <div className={classes.container}>
          <Text className={classes.title}>Invite team members</Text>
          <div className={classes.inputContainer}>
            <Input
              label="First Name"
              w={'167px'}
              style={{ paddingTop: 20 }}
              placeholder="First Name"
              {...userInviteForm.getInputProps('firstName')}
            />
            <Input
              label="Last Name"
              w={'167px'}
              ml={'1rem'}
              style={{ paddingTop: 20 }}
              placeholder="Last Name"
              {...userInviteForm.getInputProps('lastName')}
            />
          </div>
          <Input
            label="Email"
            style={{ paddingTop: 20 }}
            placeholder="Email address"
            {...userInviteForm.getInputProps('emailAddress')}
          />

          <div>
            <div className={classes.rolesLabel}>Assign role</div>
            <div className={classes.rolesDescription}>
              A team member&apos;s role determines what they can see and do on
              your Flexbase account.{' '}
              <Anchor
                className={classes.link}
                href="https://flexbase.zendesk.com/hc/en-us/articles/9118462437261-What-are-the-types-of-user-roles-on-flexbase-"
                target="_blank"
              >
                Learn more about roles
              </Anchor>
            </div>

            {roles.map((role) => (
              <OptionBox
                key={role.value}
                optionLabel={role.label}
                optionDescription={role.description}
                isSelected={userInviteForm.values.role === role.value}
                onSelect={() =>
                  userInviteForm.setFieldValue('role', role.value)
                }
              />
            ))}
          </div>

          <Text style={{ paddingTop: 10, color: 'red' }}>{error}</Text>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <Button variant="outline" loading={loading} onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="light" loading={loading} onClick={sendInvite}>
              Send Invitation
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteUser;
