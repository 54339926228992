import { createStyles, Progress } from '@mantine/core';
import { useEffect, useState } from 'react';
import useModal from 'components/modal/modal-hook';
import { CloseIconSquareEnds, FlexIcon } from 'assets/svg';
import PaymentRecipient from './step1-payment-recipient';
import PaymentMethod from './step2-payment-method';
import PaymentRecipientDetails from './step3-payment-recipient-details';
import PaymentAmount from './step4-payment-amount';
import PaymentReview from './step5-payment-review';
import PaymentConfirmation from './step6-payment-confirmation';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { flexbaseBankingClient } from 'services/flexbase-client';
import {
  CreateWireMoneyMovement,
  DepositAccount,
  MoneyMovement,
} from 'services/flexbase/banking.model';
import { LoadingState } from 'areas/onboarding/onboarding-form.state';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useMediaQuery } from '@mantine/hooks';
import {
  counterpartiesListFilter,
  depositAccountList,
  depositInitial,
  moneyMovementAmountDetails,
  newRecipientFlag,
  paymentCounterpartiesList,
  paymentMethod,
  paymentRecipientName,
  paymentStep,
  recipientInitial,
  recipientState,
  saveCounteraprtyState,
  selectedCounterparty,
} from './payment.states';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Analytics } from 'services/analytics/analytics';
import Stepper from 'components/stepper/stepper';

export enum PaymentSteps {
  RECIPIENT,
  PAYMENT_METHOD,
  RECIPIENT_DETAILS,
  AMOUNT,
  REVIEW,
  CONFIRMATION,
  ERROR,
}

export type CounterpartyId = string;
// TODO: probably just be better to pass around the selected counterparty,
// since it has the full data set for reference
type Props = {
  onPaymentSuccess?: () => void;
  onPaymentError?: (error: any) => void;
};

const SendPayment = ({ onPaymentError }: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { closeAllModals } = useModal();
  const { classes } = useStyles();

  // url params
  const [searchParams] = useSearchParams();
  const getRecipientName = searchParams.get('recipient');

  // local state
  const [, setPaymentAccount] = useState<DepositAccount>();
  const [, setReason] = useState<string | null>(null);
  const [payment, setPayment] = useState<MoneyMovement | null>(null);
  const navigate = useNavigate();

  // payment flow state
  const [paymentStepState, setPaymentStepState] = useRecoilState(paymentStep);
  const [, setCounterparties] = useRecoilState(paymentCounterpartiesList);
  const selectedCounterpartyValue = useRecoilValue(selectedCounterparty);
  const [, setDepositAccounts] = useRecoilState(depositAccountList);
  const [recipientName, setRecipientName] =
    useRecoilState(paymentRecipientName);
  const [pMethod, setPMethod] = useRecoilState(paymentMethod);
  const [moneyMovementDetails, setMoneyMovementDetails] = useRecoilState(
    moneyMovementAmountDetails,
  );
  const saveCounterparty = useRecoilValue(saveCounteraprtyState);
  const setFilter = useSetRecoilState(counterpartiesListFilter);

  const [newRecipient, setNewRecipient] = useRecoilState(recipientState);
  const setNewRecipientFlag = useSetRecoilState(newRecipientFlag);

  const setLoading = useSetRecoilState(LoadingState);

  const init = async () => {
    setLoading(true);
    try {
      const { counterparties } =
        await flexbaseBankingClient.getCounterPartyList();
      const { accounts } = await flexbaseBankingClient.getDepositList();

      setCounterparties(counterparties.filter((c) => c.status === 'active'));
      setDepositAccounts(accounts);

      if (getRecipientName) {
        setRecipientName(getRecipientName);
        setPaymentStepState(PaymentSteps.PAYMENT_METHOD);
      } else {
        setPaymentStepState(PaymentSteps.RECIPIENT);
      }
    } catch (e) {
      setPaymentStepState(PaymentSteps.ERROR);
      if (onPaymentError) {
        onPaymentError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const makePayment = async () => {
    setLoading(true);
    try {
      let paymentResult: any;
      // is this an existing linked counterparty?
      if (selectedCounterpartyValue?.id) {
        if (pMethod === 'ach') {
          paymentResult = await flexbaseBankingClient.makePayment({
            amount: formatCurrency(moneyMovementDetails.amount),
            counterpartyId: selectedCounterpartyValue.id,
            accountId: moneyMovementDetails.sourceAccount.id,
            type: pMethod,
            direction: 'Credit',
            description: moneyMovementDetails.memo,
            notes: moneyMovementDetails?.internalNotes,
          });
        }
        if (pMethod === 'wire') {
          // pretend it's a external account, and send in full details as an inline counterparty until refactor is complete
          const wirePayment: CreateWireMoneyMovement = {
            type: pMethod,
            accountId: moneyMovementDetails.sourceAccount.id,
            amount: formatCurrency(moneyMovementDetails.amount),
            direction: 'Credit',
            counterpartyId: selectedCounterpartyValue.id,
            description: moneyMovementDetails.memo,
            notes: moneyMovementDetails?.internalNotes,
          };
          paymentResult = await flexbaseBankingClient.makePayment(wirePayment);
        }
      } else {
        //   // this is an inline payment
        //   // save counterparty by default for future payments
        if (saveCounterparty && pMethod === 'ach') {
          const counterpartyResponse =
            await flexbaseBankingClient.createCounterparty({
              type: pMethod,
              counterparty: {
                name: recipientName,
                nickName: recipientName,
                routingNumber: newRecipient?.routingNumber,
                accountNumber: newRecipient?.accountNumber,
                accountType: newRecipient?.accountType,
                type: newRecipient.recipientTypePersonBusiness,
              },
            });
          paymentResult = await flexbaseBankingClient.makePayment({
            amount: formatCurrency(moneyMovementDetails.amount),
            counterpartyId: counterpartyResponse.counterparty.id,
            accountId: moneyMovementDetails.sourceAccount.id,
            type: pMethod,
            direction: 'Credit',
            description: moneyMovementDetails.memo,
            notes: moneyMovementDetails?.internalNotes,
          });
        }
        if (saveCounterparty && pMethod === 'wire') {
          const counterpartyResponse =
            await flexbaseBankingClient.createCounterparty({
              type: pMethod,
              counterparty: {
                name: recipientName,
                nickName: newRecipient.nickName,
                routingNumber: newRecipient?.routingNumber,
                accountNumber: newRecipient?.accountNumber,
                accountType: newRecipient?.type,
                type: newRecipient.type,
                address: {
                  city: newRecipient.city,
                  state: newRecipient.state,
                  postalCode: newRecipient.postalCode,
                  country: newRecipient.country,
                  address: newRecipient.address,
                  addressLine2: newRecipient.addressLine2,
                },
              },
            });

          paymentResult = await flexbaseBankingClient.makePayment({
            type: pMethod,
            amount: formatCurrency(moneyMovementDetails.amount),
            counterpartyId: counterpartyResponse.counterparty.id,
            accountId: moneyMovementDetails.sourceAccount.id,
            direction: 'Credit',
            description: moneyMovementDetails.memo,
            notes: moneyMovementDetails?.internalNotes,
          });
        }
      }
      if (paymentResult.success) {
        if (
          moneyMovementDetails.files &&
          moneyMovementDetails.files.length > 0
        ) {
          for (const file of moneyMovementDetails.files) {
            await flexbaseBankingClient.uploadPaymentDoc(
              paymentResult.payment.id,
              {
                file,
                description: 'Banking payments docs',
              },
            );
          }
        }
        setPayment(paymentResult.payment);
        setReason(paymentResult.payment.reason);
        setPaymentStepState(PaymentSteps.CONFIRMATION);
        Analytics.track('Payments Sent a Payment', { ...paymentResult });
      }
      setPaymentStepState(PaymentSteps.CONFIRMATION);
    } catch (e) {
      console.error(e);
      setPaymentStepState(PaymentSteps.ERROR);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  // generate the contents of this flow based on step state
  const getContents = () => {
    switch (paymentStepState) {
      case PaymentSteps.RECIPIENT:
        return <PaymentRecipient />;
      case PaymentSteps.PAYMENT_METHOD:
        return (
          <PaymentMethod
            onNextClick={() => {
              setPaymentStepState(PaymentSteps.RECIPIENT_DETAILS);
              setFilter('active_recipient_method');
            }}
            onBackClick={() => {
              setPaymentStepState(PaymentSteps.RECIPIENT);
              setFilter('active');
            }}
          />
        );
      case PaymentSteps.RECIPIENT_DETAILS:
        return (
          <PaymentRecipientDetails
            onNextClick={() => {
              setPaymentStepState(PaymentSteps.AMOUNT);
            }}
            onBackClick={() => {
              setPaymentStepState(PaymentSteps.PAYMENT_METHOD);
              setFilter('active_recipient');
            }}
          />
        );
      case PaymentSteps.AMOUNT:
        return (
          <PaymentAmount
            onNextClick={() => {
              setPaymentStepState(PaymentSteps.REVIEW);
            }}
            onBackClick={() =>
              setPaymentStepState(PaymentSteps.RECIPIENT_DETAILS)
            }
          />
        );
      case PaymentSteps.REVIEW:
        return (
          <PaymentReview
            onNextClick={() => {
              makePayment();
            }}
            onBackClick={() => setPaymentStepState(PaymentSteps.AMOUNT)}
          />
        );
      case PaymentSteps.CONFIRMATION:
        return (
          <PaymentConfirmation
            payment={payment}
            onCloseClick={() => {
              closeModal();
              navigate('/payments/outgoing');
            }}
            onMakeAnotherPaymentClick={() => {
              reset();
            }}
          />
        );
      case PaymentSteps.ERROR:
        return (
          <PaymentConfirmation
            payment={payment}
            onCloseClick={() => {
              {
                closeModal();
                navigate('/payments/outgoing');
              }
            }}
            onMakeAnotherPaymentClick={() => reset()}
            error={true}
          />
        );
    }
  };

  // reset all payment flow
  const reset = () => {
    setNewRecipient(recipientInitial);
    setNewRecipientFlag(false);
    setRecipientName('');
    setMoneyMovementDetails({
      amount: 0,
      memo: '',
      sourceAccount: depositInitial,
    });
    setPMethod('ach');
    setPaymentAccount(undefined);
    setPaymentStepState(PaymentSteps.RECIPIENT);
  };

  const closeModal = () => {
    reset();
    closeAllModals();
    navigate(-1);
  };

  if (isMobile) {
    return (
      <div className={classes.smallModal}>
        <div
          className={classes.escapeIconSm}
          onClick={() => closeModal()}
          data-testid={'close'}
        >
          <CloseIconSquareEnds color="#979797" width={18} height={18} />
        </div>
        {paymentStepState < PaymentSteps.CONFIRMATION && (
          <Progress
            mb={20}
            radius="sm"
            value={((paymentStepState + 1) * 100) / 5}
          />
        )}
        <div className="content">{getContents()}</div>
      </div>
    );
  }

  return (
    <div className={classes.fakeModal}>
      <div className={classes.leftContainer}>
        <FlexIcon width={90} />
        {paymentStepState < PaymentSteps.CONFIRMATION && (
          <Stepper
            activeStep={paymentStepState}
            stepsData={[
              'Recipient',
              'Payment method',
              'Recipient details',
              'Amount & source',
              'Review',
            ]}
          />
        )}
      </div>

      <div
        className={classes.innerContent}
        style={
          paymentStepState >= PaymentSteps.CONFIRMATION
            ? { backgroundColor: 'unset', width: 'unset', border: 'unset' }
            : {}
        }
      >
        {getContents()}
      </div>

      <div className={classes.rightContainer}>
        <div
          className={classes.escapeIcon}
          onClick={() => closeModal()}
          data-testid={'close'}
        >
          <CloseIconSquareEnds />
        </div>
      </div>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  avatar: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.fn.primaryColor(),
    borderRadius: 8,
    marginRight: 5,
  },
  leftContainer: {
    width: '174px',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'right',
    width: '174px',
  },
  escapeIcon: {
    color: '#979797',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    svg: {
      height: '18px',
      width: '18px',
    },
  },
  escapeIconSm: {
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    marginBottom: '15px',
    justifyContent: 'flex-end',
  },
  escape: {
    color: '#979797',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  fakeModal: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    overflowX: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 50,
    backgroundColor: theme.fn.themeColor('neutral', 2),
    alignItems: 'flex-start', // Otherwise the height for the content goes funky
  },
  innerContent: {
    backgroundColor: '#FFFFFF',
    minHeight: 521,
    width: 420,
    marginTop: '2.5vh',
    padding: '36px 32px',
    display: 'flex',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  smallModal: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    minWidth: '100%',
    backgroundColor: '#FAFAFA',
    position: 'fixed',
    overflowX: 'auto',
  },
  smallContent: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    width: '100%',
    marginTop: 16,
    padding: 32,
    minHeight: 521,
  },
}));

export default SendPayment;
