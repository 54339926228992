import { atom, selector } from 'recoil';
import { generateStatementDates, StatementDates } from 'utilities/dates/dates';
import {
  Account,
  PlaidBalanceAccount,
  PlaidBalances,
  TreasuryActivity,
} from 'services/flexbase/banking.model';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from 'services/flexbase-client';

export type AccountSnapshot = {
  bankName: string;
  accountNumber: string;
  accountName: string;
  accountType: string;
  routingNumber: string;
  unitToken: string;
  stripeToken: string;
  availableBalance: number;
  currentBalance: number;
  accountMask: string;
  unlinked: boolean;
  logoUrl: string;
  primary: boolean;
};

export type BankSnapshot = {
  bankName: string;
  availableBalance: number;
  currentBalance: number;
  logoUrl: string;
  accounts: AccountSnapshot[];
};

// FIXME: This type was lifted from flex-accounts-table.tsx and may not be accurate to what we need in the end.
export type FlexAccountInfo = {
  plaidOrDeposit: 'admDeposit';
  id: string;
  name: string;
  subName: string;
  accountNumber: string;
  availableBalance: number;
  uninsuredCapital: number;
  accountDiversificationNumber: number;
};

export type DiversificationAccount = {
  bankName: string;
  availableBalance: number;
  logoUrl: string;
};

type _TreasuryManagementState = {
  primaryAccount?: AccountSnapshot;
  bankSnapshots: Record<string, BankSnapshot>;
  statementDates: StatementDates;
  totalAvailableBalance: number;
  totalCurrentBalance: number;
  uninsuredCapitalBalance: number;
  totalAccounts: number;
  flexTreasuryAccounts: FlexAccountInfo[];
  diversificationAccounts: DiversificationAccount[];
  activities: TreasuryActivity[];
};

const _TreasuryManagementSelector = selector<_TreasuryManagementState>({
  key: 'treas_mgmt_selector',
  get: async () => {
    return await loadTreasuryInfo();
  },
});

export const TreasuryManagementState = atom<_TreasuryManagementState>({
  key: 'treas_mgmt_state',
  default: _TreasuryManagementSelector,
});

/**
 * This function loads all data required for the Treasury feature and calculates
 * various values like totalAvailableBalance and uninsuredCapitalBalance. It handles errors and attempts to work around
 * them. No null/undefined
 *
 * @returns {Promise<_TreasuryManagementState>} A promise resolving in the TreasuryManagementState in its entirety
 */
export async function loadTreasuryInfo(): Promise<_TreasuryManagementState> {
  type LazyCompanyType = { createdAt: string; bankAccts: Account[] };
  const results = await Promise.allSettled([
    flexbaseOnboardingClient.getUserCompany(),
    flexbaseBankingClient.getPlaidBalances(),
    flexbaseOnboardingClient.getPlaidCompanyAccounts(),
    flexbaseBankingClient.getTreasuryAccount(),
    flexbaseBankingClient.getTreasuryAllocations(),
    flexbaseBankingClient.getTreasuryActivities(),
  ]);
  const company =
    results[0].status === 'fulfilled'
      ? (results[0].value as LazyCompanyType)
      : { createdAt: '', bankAccts: [] };
  const balances =
    results[1].status === 'fulfilled'
      ? results[1].value
      : ({ accounts: [], success: false } as PlaidBalances);
  const bankAccts =
    results[2].status === 'fulfilled'
      ? results[2].value?.accounts || []
      : ([] as Account[]);
  const treasuryAccountResponse = results[3];
  const treasuryAllocationsResponse = results[4];
  const activitiesResponse = results[5];
  const statementDates = generateStatementDates(company.createdAt, 'both');

  const accountBalancesMap = balances.accounts.reduce<
    Record<string, PlaidBalanceAccount>
  >((prev, curr) => {
    return { ...prev, [curr.accountId]: curr };
  }, {});

  let totalAvailableBalance = 0;
  let totalCurrentBalance = 0;

  const accountInfo = bankAccts.map((b) => {
    const balance = accountBalancesMap[b.accountId];
    if (balance) {
      totalAvailableBalance += balance.balances.available;
      totalCurrentBalance += balance.balances.current;
    }
    return {
      bankName: b.bankName || '',
      accountNumber: b.account || '',
      accountName: b.accountName || '',
      accountType: b.accountType || '',
      routingNumber: b.routing || '',
      unitToken: b.unitProcessorToken || '',
      stripeToken: b.stripeBankAccountToken || '',
      availableBalance: balance?.balances.available ?? 0,
      currentBalance: balance?.balances.current ?? 0,
      accountMask: balance?.mask || '0000',
      unlinked: b.unlinked ?? false,
      logoUrl: b.logoUrl || '',
      primary:
        treasuryAccountResponse.status === 'fulfilled'
          ? b.id === treasuryAccountResponse.value.plaidTokenId
          : false,
    };
  });

  const primaryAccount = accountInfo.find((a) => a.primary) || undefined;

  const bankSnapshots = accountInfo.reduce<Record<string, BankSnapshot>>(
    (prevn, curr) => {
      const fromMap = prevn[curr.bankName];
      if (fromMap) {
        fromMap.availableBalance += curr.availableBalance;
        fromMap.currentBalance += curr.currentBalance;
        fromMap.bankName = curr.bankName;
        fromMap.logoUrl = fromMap.logoUrl
          ? fromMap.logoUrl
          : curr.logoUrl || '';
        fromMap.accounts.push(curr);
      } else {
        prevn[curr.bankName] = {
          availableBalance: curr.availableBalance,
          currentBalance: curr.currentBalance,
          bankName: curr.bankName,
          logoUrl: curr.logoUrl || '',
          accounts: [curr],
        };
      }
      return prevn;
    },
    {},
  );

  let uninsuredCapitalBalance = 0;
  Object.values(bankSnapshots).forEach((b) => {
    const uninsured = b.availableBalance - 250000;
    if (uninsured > 0) {
      uninsuredCapitalBalance += uninsured;
    }
  });

  const flexTreasuryAccounts: FlexAccountInfo[] =
    treasuryAccountResponse.status === 'fulfilled' &&
    treasuryAllocationsResponse.status === 'fulfilled'
      ? [
          {
            id: treasuryAccountResponse.value.id,
            name: treasuryAccountResponse.value.admName,
            plaidOrDeposit: 'admDeposit',
            subName: treasuryAccountResponse.value.admName, // ?
            accountDiversificationNumber:
              treasuryAllocationsResponse.value.allocations.length, // Waiting on API for /treasury/allocations
            accountNumber: treasuryAccountResponse.value.id, // ? This is a GUID from our DB. Actual account number from ???
            uninsuredCapital: 0, // Waiting on API for /treasury/allocations
            availableBalance: parseFloat(
              treasuryAllocationsResponse.value.closingBalance,
            ), // Waiting on API for /treasury/allocations
          },
        ]
      : [];

  const diversificationAccounts =
    treasuryAllocationsResponse.status === 'fulfilled'
      ? treasuryAllocationsResponse.value.allocations.map((allocation) => {
          return {
            bankName: allocation.instName,
            availableBalance: parseFloat(allocation.amount),
            logoUrl: allocation.instLogoUrl,
          };
        })
      : [];

  const activities =
    activitiesResponse.status === 'fulfilled' ? activitiesResponse.value : [];

  return {
    primaryAccount,
    bankSnapshots,
    totalAvailableBalance,
    totalCurrentBalance,
    statementDates,
    uninsuredCapitalBalance,
    totalAccounts: bankAccts.length,
    flexTreasuryAccounts,
    diversificationAccounts,
    activities,
  };
}
