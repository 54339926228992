import { DateTime } from 'luxon';

export const formatDate = (date: string) =>
  DateTime.fromSQL(date).toFormat('D');

export const formatOnboardingApiDate = (date?: string) => {
  if (!date) {
    return '';
  }
  const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');

  return dateTime.isValid ? dateTime.toFormat('MM/dd/yyyy') : '';
};
