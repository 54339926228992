import {
  Avatar,
  Button,
  Image,
  Loader,
  Menu,
  Skeleton,
  Text,
  Textarea,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { VscChromeClose } from 'react-icons/vsc';
import { useStyles } from './transaction-details.styles';
// import { ReactComponent as Flag } from '../../../../../assets/svg/flag.svg';
import { Quickbook } from 'assets/svg';

import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useEffect, useRef, useState } from 'react';
// import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiArrowUpSLine } from 'react-icons/ri';
// import { BiEditAlt } from 'react-icons/bi';
import { IoClose, IoTrashOutline } from 'react-icons/io5';
import flexbaseClient from 'services/flexbase-client';
import { IoMdOpen } from 'react-icons/io';
import { format } from 'date-fns';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Invoice } from 'flexbase-client';
import { showNotification } from '@mantine/notifications';
import filetype from 'magic-bytes.js';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import { BsDownload } from 'react-icons/bs';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { useRecoilValue } from 'recoil';
import { IsAccountant } from '../../../../../states/application/product-onboarding';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
interface Props {
  id: string;
  createdAt: string;
  docId: string;
  description: string;
  storeName: string;
  total: string;
  employee: string;
  cardId: string;
  storeId: string;
  storeLogoUrl: string;

  //Currently unused/unavailable data fields
  flag?: boolean;
  billable?: boolean;
  clientName?: string;
  projectName?: string;
  remind?: boolean;

  //functional props
  focusElement?: FocusElement;
  closeModal: () => void;
  updateTransaction: (transaction: Invoice) => void;
}

export type FocusElement = 'receipt' | 'memo' | 'flag';

// TODO: Figure out how to do functionality the ref props on Menu and Tooltip do since they were removed in mantine v5
const TransactionDetails = ({
  id,
  createdAt,
  docId,
  description,
  storeName,
  total,
  employee,
  cardId,
  storeId,
  storeLogoUrl,

  flag,
  billable,
  clientName,
  projectName,
  remind,

  focusElement,
  closeModal,
  updateTransaction,
}: Props) => {
  // const [billableToClient, setBillableToClient] = useState(billable);
  const theme = useMantineTheme();
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [memo, setMemo] = useState(description);
  const [cardName, setCardName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [loadingMemo, setLoadingMemo] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [receiptUrl, setReceiptUrl] = useState<string | null>();
  const [receiptThumbnailUrl, setReceiptThumbnailUrl] = useState<
    string | null
  >();
  const [receiptExtension, setReceiptExtension] = useState<string | null>();

  const [receiptOpened, setReceiptOpened] = useState(false);
  const [disputeOpen, setDisputeOpen] = useState(true); //TODO: Set to false by default when bringing "Flag Transaction" back
  const receiptRef = useRef<HTMLDivElement>(null);
  const receiptImageExpandButtonRef = useRef<HTMLButtonElement>(null);
  // const receiptImageRef = useClickOutside(
  //   () => setReceiptOpened(false),
  //   ['mouseup', 'touchend'],
  // );
  const memoRef = useRef<HTMLTextAreaElement>(null);
  const flagRef = useRef<HTMLDivElement>(null);
  const isAccountant = useRecoilValue(IsAccountant);
  const [actionButtonMenuOpen, setActionButtonMenuOpen] = useState(false);

  const notifyError = (error: string) => {
    showNotification({
      title: 'Oops',
      message: error,
      color: 'red',
    });
  };

  const notifySuccess = (msg: string) => {
    showNotification({
      title: 'Success!',
      message: msg,
      color: 'flexbase-teal',
    });
  };

  const { classes, cx } = useStyles({
    flagMenuHeight: disputeOpen ? '235px' : '115px',
  });

  const getCardNameFromCardId = async (id: string) => {
    try {
      const result = await flexbaseClient.getCard(id);
      if (result.card?.cardName) {
        await setCardName(result.card.cardName!);
      } else {
        await setCardName('N/A');
      }
    } catch (error) {
      console.error(error);
      await setCardName('N/A');
    }
  };

  const getLocationFromStoreId = async (id: string) => {
    try {
      const result = await flexbaseClient.getMerchant(id);
      if (result) {
        if (result.category) {
          const words = result.category.split('_').join(' ');
          await setCategory(capitalizeOnlyFirstLetter(words));
        } else {
          await setCategory('N/A');
        }
        const location = `${
          result.city && !result.city.match(/\d/) ? result.city + ', ' : ''
        }${result.state ? result.state + ', ' : ''}${
          result.country ? result.country + ' ' : ''
        }${result.postalCode ? result.postalCode : ''}`;
        await setLocation(location);
      }
    } catch (error) {
      console.error(error);
      await setCategory('N/A');
      await setLocation('N/A');
    }
  };

  const addFile = async (files: File[]) => {
    const imageUri = URL.createObjectURL(files[0]);
    await setReceiptUrl(imageUri);
    const type = files[0].type;
    setReceiptExtension(files[0].name.split('.').at(-1));
    if (type.includes('pdf')) {
      await setPDFThumbnail(imageUri);
    } else {
      await setReceiptThumbnailUrl(imageUri);
    }
    uploadReceiptFile(files[0]);
  };

  const downloadReceipt = (url: string, name: string, extension: string) => {
    const alink = document.createElement('a');
    alink.href = url;
    alink.download = `${name}.${extension}`;
    alink.click();
  };

  const setPDFThumbnail = async (url: string) => {
    await pdfjs.getDocument(url).promise.then((pdf) => {
      pdf.getPage(1).then((page) => {
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        const viewport = page.getViewport({ scale: 1 });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        if (canvasContext) {
          page.render({ canvasContext, viewport }).promise.then(() => {
            setReceiptThumbnailUrl(canvas.toDataURL());
          });
        }
      });
    });
  };

  const getReceiptFile = async (doc: string) => {
    await setLoadingReceipt(true);
    try {
      const result = await flexbaseClient.getDocumentImage(doc);
      if (result) {
        const blobURL = URL.createObjectURL(new Blob([result]));
        await setReceiptExtension(
          filetype(new Uint8Array(result))[0].extension,
        );
        await setReceiptUrl(blobURL);
        if (filetype(new Uint8Array(result))[0].extension!.includes('pdf')) {
          await setPDFThumbnail(blobURL);
        } else {
          await setReceiptThumbnailUrl(blobURL);
        }
      } else {
        await setReceiptUrl(null);
        await setReceiptThumbnailUrl(null);
        await setReceiptExtension(null);
      }
    } catch (error) {
      console.error(error);
      notifyError('Unable to display the invoice image');
    } finally {
      await setLoadingReceipt(false);
    }
  };

  const uploadReceiptFile = async (file: any) => {
    try {
      await setLoadingReceipt(true);
      const fileResult = await flexbaseClient.uploadInvoiceFile(id, file);
      if (fileResult) {
        notifySuccess('Receipt uploaded');
        updateTransaction(fileResult);
      }
    } catch (error) {
      console.error('Error updating the invoice', error);
      return notifyError(error);
    } finally {
      await setLoadingReceipt(false);
    }
  };

  const unlinkReceiptFile = async () => {
    try {
      await setLoadingReceipt(true);
      const fileResult = await flexbaseClient.unlinkInvoiceFile(id);
      if (fileResult) {
        notifySuccess('Receipt deleted');
        updateTransaction(fileResult);
        setReceiptUrl(null);
        setReceiptThumbnailUrl(null);
        setReceiptExtension(null);
      }
    } catch (error) {
      console.error('Error updating the invoice', error);
      return notifyError(error);
    } finally {
      await setLoadingReceipt(false);
    }
  };

  const DropzoneChildren = () => (
    <div data-testid="file-container">
      <div>
        <Image
          width={32}
          height={32}
          alt="no-invoice-image"
          src="/images/upload-cloud.svg"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
        />
        <Text className={classes.dropZonePlaceholder}>
          Drag and drop file, or&nbsp;<span>browse</span>&nbsp;to add your image
        </Text>
      </div>
    </div>
  );

  const updateMemo = async (text: string) => {
    try {
      setLoadingMemo(true);
      const updateResult = await flexbaseClient.updateInvoice(id, {
        description: text,
      } as any);
      if (updateResult) {
        notifySuccess('Memo added');
        updateTransaction(updateResult);
        setUpdatedDescription(updateResult.description);
      }
    } catch (error) {
      console.error('Error updating the invoice', error);
      setMemo(updatedDescription);
      return notifyError(error);
    } finally {
      setLoadingMemo(false);
    }
  };

  useEffect(() => {
    if (docId) {
      getReceiptFile(docId);
    }
    if (cardId) {
      getCardNameFromCardId(cardId);
    } else {
      setCardName('N/A');
    }
    if (storeId) {
      getLocationFromStoreId(storeId);
    } else {
      setLocation('N/A');
    }
  }, []);

  useEffect(() => {
    switch (focusElement) {
      case 'receipt':
        receiptRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'memo':
        memoRef?.current?.scrollIntoView({ behavior: 'smooth' });
        memoRef?.current?.focus();
        break;
      case 'flag':
        flagRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setActionButtonMenuOpen(true);
        break;
      default:
        break;
    }
  }, [flagRef, receiptRef, memoRef, focusElement]);

  const formatDatetime = (date: string) => {
    const newDate = new Date(date);
    const year = format(newDate, 'y');
    const month = format(newDate, 'MMM');
    const day = format(newDate, 'd');
    const hour = format(newDate, 'h');
    const minute = format(newDate, 'mm');
    const ampm = format(newDate, 'aa');

    return `${month} ${day}, ${year} at ${hour}:${minute} ${ampm}`;
  };

  return (
    <div className={classes.baseContainer}>
      <div className={classes.headerRow1}>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              {storeLogoUrl ? (
                <img
                  src={storeLogoUrl}
                  className={classes.storeLogo}
                  alt={storeName}
                />
              ) : (
                <Avatar radius="md" className={classes.avatar}>
                  {storeName?.charAt(0) || '$'}
                </Avatar>
              )}
            </div>
            <div className={classes.amount}>
              {formatCurrency(Math.abs(total as unknown as number)) || 'N/A'}
            </div>
          </div>
          <div className={classes.dateTime}>
            <Text>{formatDatetime(createdAt)}</Text>
          </div>
        </div>
        <div>
          <button className={classes.closeButton} onClick={closeModal}>
            <VscChromeClose size={34} color="#FFFF" />
          </button>
        </div>
      </div>

      {/* <div className={classes.headerRow2}> */}

      <div>
        {/* <Button variant="outline" className={classes.flagButtonTop}>
            <Flag
              style={{ paddingRight: '8px', marginBottom: '-2px' }}
              width={18}
              height={18}
            />
            Flag Transaction
          </Button> */}
      </div>
      {/* </div> */}

      <div className={classes.section}>
        <div className={classes.infoRow}>
          <div>
            <Text className={classes.infoTitle}>Employee</Text>
            <Text className={classes.infoValue}>{employee}</Text>
          </div>
          <div>
            <Text className={classes.infoTitle}>Card Name</Text>
            {cardName ? (
              <Text className={classes.infoValue}>{cardName}</Text>
            ) : (
              <Skeleton
                className={classes.infoValue}
                height={20}
                width={100}
                radius="xl"
              />
            )}
          </div>
        </div>

        <div className={classes.infoRow}>
          <div className={classes.infoCluster}>
            <Text className={classes.infoTitle}>Merchant Name</Text>
            <Text className={classes.infoValue}>{storeName}</Text>
          </div>
          <div className={classes.infoCluster}>
            <Text className={cx(classes.infoTitle, classes.infoTitleFlex)}>
              Category
              <Tooltip label="Quickbooks integration coming soon">
                <div>
                  <Quickbook width={20} height={20} />
                </div>
              </Tooltip>
            </Text>
            {category ? (
              <Text className={classes.infoValue}>
                {category}
                {/* <BiEditAlt
                  style={{ marginLeft: '5px', marginBottom: '-2px', cursor: 'not-allowed' }}
                /> */}
              </Text>
            ) : (
              <Skeleton
                className={classes.infoValue}
                height={20}
                width={100}
                radius="xl"
              />
            )}
          </div>
        </div>

        <div className={classes.infoRow}>
          <div className={classes.infoCluster}>
            <Text className={classes.infoTitle}>Location</Text>
            {location ? (
              <Text className={classes.infoValue}>{location}</Text>
            ) : (
              <Skeleton
                className={classes.infoValue}
                height={20}
                width={200}
                radius="xl"
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle} ref={receiptRef}>
          <Text className={classes.infoTitle}>Upload receipt</Text>
        </div>
        {loadingReceipt ? (
          <div className={classes.dropZone}>
            <Loader color="green" />
          </div>
        ) : (
          <>
            {receiptThumbnailUrl && receiptUrl && receiptExtension ? (
              <div className={classes.dropZone}>
                <div>
                  <button
                    ref={receiptImageExpandButtonRef}
                    type="button"
                    onClick={() => {
                      setReceiptOpened((p) => !p);
                    }}
                  >
                    <IoMdOpen size={18} color="#5F5F5F" />
                  </button>
                  <button
                    disabled={isAccountant}
                    type="button"
                    onClick={() => unlinkReceiptFile()}
                  >
                    <IoTrashOutline size={18} color="#D80027" />
                  </button>
                </div>

                {!receiptOpened && (
                  <Image
                    width={90}
                    height={90}
                    src={receiptThumbnailUrl}
                    radius="sm"
                    style={{ cursor: 'pointer' }}
                  />
                )}

                {receiptOpened ? (
                  <>
                    <div
                      style={{ top: '-18rem', zIndex: 1, position: 'absolute' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          width: '100%',
                        }}
                      >
                        <div
                          className={classes.roundIcons}
                          onClick={() => {
                            downloadReceipt(
                              receiptUrl,
                              'receipt',
                              receiptExtension,
                            );
                          }}
                        >
                          <BsDownload size={20} color="white" />
                        </div>
                        <div
                          className={classes.roundIcons}
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            setReceiptOpened(false);
                          }}
                        >
                          <IoClose size={20} color="white" />
                        </div>
                      </div>

                      <Image
                        alt="invoice-image"
                        mt="1vh"
                        src={receiptThumbnailUrl}
                        style={{ cursor: 'pointer', width: '100%' }}
                      />
                    </div>
                  </>
                ) : null}

                <Text className={classes.dropZonePlaceholder}>
                  Drag and drop file, or&nbsp;<span>browse</span>
                  &nbsp;to add your image
                </Text>
              </div>
            ) : (
              <Dropzone
                disabled={isAccountant}
                onDrop={(files) => addFile(files)}
                data-testid="upload-image"
                maxSize={3 * 1024 ** 2}
                accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
                multiple={false}
                classNames={{
                  root: classes.dropZone,
                }}
              >
                <DropzoneChildren />
              </Dropzone>
            )}
          </>
        )}
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <Text className={classes.infoTitle}>Memo</Text>
        </div>
        <Textarea
          size="6rem"
          className={classes.containerMemoInput}
          classNames={{ input: classes.memoInput }}
          minRows={5}
          value={memo}
          onChange={(event) => {
            setMemo(event.target.value);
          }}
          ref={memoRef}
          disabled={loadingMemo || isAccountant}
        />
        <div
          className={classes.memoButtons}
          style={{
            maxHeight: memo !== updatedDescription ? '90px' : 0,
          }}
        >
          <Button
            variant="outline"
            color="gray"
            onClick={() => {
              setMemo(updatedDescription);
            }}
            disabled={loadingMemo}
          >
            Cancel
          </Button>
          <Button
            color={theme.fn.primaryColor()}
            onClick={() => {
              updateMemo(memo);
            }}
            disabled={loadingMemo}
          >
            Save
          </Button>
        </div>
      </div>
      {/* <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <Switch
            className={classes.switch}
            size="md"
            color="green"
            checked={billableToClient}
            onChange={(event) =>
              setBillableToClient(event.currentTarget.checked)
            }
            disabled
          />
          <Text className={classes.infoTitle}>Billable to client?</Text>
          <Tooltip label="If a transaction is billable to a client or a project, then it will be added to Quickbooks.">
            <AiOutlineInfoCircle className={classes.billableInfo} />
          </Tooltip>
        </div>
        {billableToClient ? (
          <>
            <Text className={classes.billableTitle}>Client name</Text>
            <Input
              className={classes.billableInput}
              placeholder="ex: John Smith"
            />
            <Text className={classes.billableTitle}>Project name</Text>
            <Input
              className={classes.billableInput}
              placeholder="ex: Project, Job, Property, etc."
            />
          </>
        ) : null}
      </div> */}

      {/* <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <Switch
            className={classes.switch}
            size="md"
            color="flexbase-cosmic"
            disabled
          />
          <Text className={classes.infoTitle}>
            Automatically remind employee to upload receipts?
          </Text>
        </div>
      </div> */}
      <div className={classes.actionButtons} ref={flagRef}>
        <Menu
          classNames={{
            dropdown: classes.flagMenu,
            itemLabel: classes.flagMenuText,
            item: classes.flagMenuItem,
          }}
          closeOnItemClick={false}
          opened={actionButtonMenuOpen}
          onChange={(open) => setActionButtonMenuOpen(open)}
          onClose={() => {
            // setDisputeOpen(false); //TODO: Uncomment when bringing flag transaction back
          }}
        >
          <Menu.Target>
            <Button
              disabled={isAccountant}
              variant="outline"
              className={classes.flagButtonButtom}
            >
              {/* <Flag
                style={{ paddingRight: '10px', marginBottom: '-3px' }}
                width={20}
                height={20}
              /> */}
              {/* //TODO: Uncomment when bringing flag transaction back and change the text of the button to Flag Transaction */}
              Dispute Transaction
              <RiArrowUpSLine />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {disputeOpen ? (
              <div className={classes.disputeMenu}>
                <div className={classes.disputeTitle}>Dispute Transaction?</div>
                <div className={classes.disputeText}>
                  Please email us the date, transaction amount, disputed amount,
                  merchant name and reason for your dispute. Include any
                  applicable evidence to support the dispute. Note that disputes
                  may take up to 120 days to resolve with the MasterCard&trade;
                  and Visa&trade; networks.
                </div>
                <div className={classes.disputeButtons}>
                  <Button
                    variant="outline"
                    color="gray"
                    onClick={() => setActionButtonMenuOpen(false)}
                  >
                    Nevermind
                  </Button>
                  <Button
                    color={theme.fn.primaryColor()}
                    onClick={() => {
                      window.location.href = 'mailto:disputes@flex.one';
                      setActionButtonMenuOpen(false);
                    }}
                  >
                    Email us to dispute
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <Menu.Item
                  // style={{ backgroundColor: '#0000', marginBottom: 20 }}
                  onClick={() => {
                    setDisputeOpen(true);
                  }}
                >
                  Dispute Transaction
                </Menu.Item>
                <Menu.Item disabled>Flag transaction</Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
        {/*<Button className={classes.approve} color="green" disabled>*/}
        {/*  Approve*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

export default TransactionDetails;
