import {
  Container,
  TextInput,
  Select,
  Button,
  Title,
  Text,
  LoadingOverlay,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import IAddress from 'states/user/address';
import { Employee } from 'states/employees/employee';
import { AlertMessage } from 'states/alert/alert-message';
import flexbaseClient from 'services/flexbase-client';
import { actionModalStyles } from './action-modal.styles';
import { formatUSPhoneNumber } from 'utilities/formatters/format-phone-number';
import { FaTimes } from 'react-icons/fa';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import FlexbaseAddressAutoComplete from 'components/address/address-component';

interface Props {
  closeModal: () => void;
  setAlertMessage: Dispatch<SetStateAction<AlertMessage | null>>;
  setAllUsers: Dispatch<SetStateAction<Employee[]>>;
  employee?: Employee;
}

enum Roles {
  ADMIN = 'Administrator',
  ACCOUNTANT = 'Accountant',
  EMPLOYEE = 'Employee',
}

const ActionModal = ({
  closeModal,
  employee,
  setAllUsers,
  setAlertMessage,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = actionModalStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [employeeInfo, setEmployeeInfo] = useState<Employee>({});

  useEffect(() => {
    if (employee) {
      setEmployeeInfo(employee);
    }
  }, []);

  const addEmployee = async () => {
    try {
      setIsLoading(true);
      const response: any = await flexbaseClient.addPerson({
        ...employeeInfo,
        address: addressForm.values.line1,
        addressLine2: addressForm.values.line2,
        city: addressForm.values.city,
        state: addressForm.values.state,
        postalCode: addressForm.values.postalCode,
        country: addressForm.values.country || '',
      });
      setAllUsers((prevUser: any[]) => [...prevUser, response]);
      if (response) {
        closeModal();
        setAlertMessage({
          title: 'Great!',
          message: 'You have a new user',
          type: 'success',
        });
      } else {
        setErrorMessage('Something went wrong, please try again');
      }
    } catch (error) {
      console.error('ADD USER ERROR', error);
      setAlertMessage({
        title: 'Oops!',
        message: 'Unable to add a new user',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateEmployee = async () => {
    const userId = employeeInfo?.id;
    try {
      setIsLoading(true);
      if (userId) {
        const response = await flexbaseClient.updatePerson(userId, {
          ...employeeInfo,
          address: {
            street1: addressForm.values.line1,
            street2: addressForm.values.line2 || '',
            city: addressForm.values.city,
            state: addressForm.values.state,
            postalCode: addressForm.values.postalCode,
            country: addressForm.values.country || '',
          },
        });
        setAllUsers((prevUser) =>
          prevUser.map((user) => {
            if (response?.id === user.id) {
              return { ...user, ...response };
            }
            return user;
          }),
        );
        if (response) {
          closeModal();
          setAlertMessage({
            title: 'Great!',
            message: 'The user has been updated',
            type: 'success',
          });
        } else {
          setErrorMessage('Something went wrong, please try again');
        }
      }
    } catch (error) {
      console.error('UPDATE USER ERROR', error);
      setAlertMessage({
        title: 'Oops!',
        message: 'Unable to update the user',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const saveUser = () => {
    if (employee?.id) {
      updateEmployee();
    } else {
      addEmployee();
    }
  };

  const addressForm = useForm<IAddress>({
    initialValues: {
      line1: employee?.address || '',
      line2: employee?.addressLine2 || '',
      city: employee?.city || '',
      state: employee?.state || '',
      postalCode: employee?.postalCode || '',
      country: employee?.country || '',
    },
  });

  const userRoles =
    employeeInfo?.roles && employeeInfo?.roles.length > 1
      ? employeeInfo?.roles[0]
      : employeeInfo?.roles;

  return (
    <Container p="3rem" style={{ position: 'relative' }}>
      <button
        type="button"
        className={classes.btnCloseModal}
        onClick={() => {
          closeModal();
        }}
      >
        <FaTimes size={20} />
      </button>
      <div style={{ marginTop: 10 }}>
        <Title order={3}>{employee?.id ? 'Edit' : 'Add'} User</Title>
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ color: theme.fn.primaryColor() }}
        />
        <TextInput
          style={{ marginTop: 15 }}
          placeholder="Jhon"
          label="First Name"
          value={employeeInfo?.firstName}
          onChange={(e) =>
            setEmployeeInfo({ ...employeeInfo, firstName: e.target.value })
          }
        />
        <TextInput
          style={{ marginTop: 15 }}
          placeholder="Smith"
          label="Last Name"
          value={employeeInfo?.lastName}
          onChange={(e) =>
            setEmployeeInfo({ ...employeeInfo, lastName: e.target.value })
          }
        />
        <TextInput
          style={{ marginTop: 15 }}
          disabled={employeeInfo?.id ? true : false}
          placeholder="Manager"
          label="Job title"
          value={employeeInfo?.jobTitle}
          onChange={(e) =>
            setEmployeeInfo({ ...employeeInfo, jobTitle: e.target.value })
          }
        />
        <TextInput
          style={{ marginTop: 15 }}
          placeholder="john@example.com"
          label="Email"
          value={employeeInfo?.email}
          onChange={(e) =>
            setEmployeeInfo({ ...employeeInfo, email: e.target.value })
          }
        />
        <TextInput
          style={{ marginTop: 15 }}
          placeholder="(123) 456-7890"
          label="Phone number"
          value={formatUSPhoneNumber(employeeInfo?.cellPhone || '')}
          onChange={(e) =>
            setEmployeeInfo({ ...employeeInfo, cellPhone: e.target.value })
          }
        />
        <div style={{ marginTop: 15 }}>
          <FlexbaseAddressAutoComplete theForm={addressForm} />
        </div>
        <Select
          style={{ marginTop: 15 }}
          disabled={employeeInfo?.id ? true : false}
          label="User type"
          placeholder={
            userRoles != undefined
              ? Roles[userRoles as keyof typeof Roles]
              : 'Select user role'
          }
          onChange={(value: string) =>
            setEmployeeInfo({ ...employeeInfo, roles: [value] })
          }
          data={[
            { value: 'ADMIN', label: Roles.ADMIN },
            { value: 'ACCOUNTANT', label: Roles.ACCOUNTANT },
            { value: 'EMPLOYEE', label: Roles.EMPLOYEE },
          ]}
        />
        {errorMessage && (
          <Text style={{ marginTop: 18, color: 'red' }}> {errorMessage}</Text>
        )}
        <div className={classes.buttonContainer}>
          <Button
            color="flexbase-teal"
            aria-label="saveUser"
            fullWidth
            onClick={() => {
              saveUser();
            }}
          >
            Save User
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ActionModal;
