import { useForm } from '@mantine/form';
import { useState, useEffect } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { Alert, Button, Divider, useMantineTheme } from '@mantine/core';

import Multiselect from './custom-multiselect';
import Input from 'components/input/flexbase-input';
import Select from 'components/select/flexbase-select';
import flexbaseClient from 'services/flexbase-client';
import { Card, CardForm } from 'states/cards/card-info';
import { formatCurrency } from 'utilities/formatters/format-currency';

interface Props {
  card: CardForm;
  cardId: string;
  setCardInfo: (x: CardForm) => void;
  setShowOptions: (x: boolean) => void;
  updateCompanyCards: (card: Card) => void;
  amountSpentThisMonth: number;
  updateGeneralData: (perUtilization: number, newLimitAmount: number) => void;
}

const EditCard = ({
  card,
  cardId,
  setCardInfo,
  setShowOptions,
  updateCompanyCards,
  amountSpentThisMonth,
  updateGeneralData,
}: Props) => {
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const form = useForm({
    initialValues: {
      cardName: card?.cardName || '',
      amount: card?.expensesTypes?.amount || 0,
      groups: card?.expensesTypes?.groups || [],
      interval: card?.expensesTypes?.amount !== 0 ? 'monthly' : 'unlimited',
    },
  });

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }
    setHasChanges(true);
    form.setFieldValue('amount', Number(currentValue));
  };

  const updateCard = async () => {
    try {
      setIsLoading(true);
      const { card: cardUpdated, success } = await flexbaseClient.updateCard(
        cardId,
        {
          cardName: form.values.cardName,
          expensesTypes: {
            amount:
              form.values.interval === 'unlimited'
                ? 0
                : Number(form.values.amount),
            groups: form.values.groups,
            interval:
              form.values.interval === 'unlimited'
                ? null
                : form.values.interval,
          },
          creditLimit:
            form.values.interval === 'unlimited'
              ? null
              : Number(form.values.amount),
          notifyUse: card.notifyUse,
        },
      );
      if (!success) {
        throw Error(
          JSON.stringify({
            errorMessage:
              'Unable to update the card data, please contact support',
          }),
        );
      }
      if (success && cardUpdated) {
        setCardInfo({
          cardType: cardUpdated.cardType,
          cardName: cardUpdated.cardName,
          expensesTypes: cardUpdated.expensesTypes
            ? {
                amount: cardUpdated.expensesTypes.amount,
                groups: cardUpdated.expensesTypes.groups,
                interval:
                  cardUpdated.expensesTypes.amount !== 0
                    ? 'monthly'
                    : 'unlimited',
              }
            : null,
          creditLimit: cardUpdated.creditLimit,
          notifyUse: cardUpdated.notifyUse,
          userId: cardUpdated.userId,
        });
        setShowOptions(false);
        const utilization = cardUpdated?.expensesTypes?.amount
          ? Math.round(
              (amountSpentThisMonth / cardUpdated.expensesTypes.amount) * 100,
            )
          : 0;
        updateGeneralData(utilization, cardUpdated.expensesTypes?.amount || 0);
        updateCompanyCards({
          ...cardUpdated,
          monthToDateSpends: { mtdSpend: amountSpentThisMonth },
          utilization,
        });
      }
    } catch (error) {
      console.error('Unable to update the card data', error);
      setErrorMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage !== '')
      setTimeout(() => {
        setErrorMessage('');
      }, 10000);
  }, [errorMessage]);

  return (
    <>
      <Divider my={16} />
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ width: '100%' }}>
          <Input
            label="Name of card"
            value={form.values.cardName}
            style={{ marginTop: '15px' }}
            onChange={(e) => {
              setHasChanges(true);
              form.setFieldValue('cardName', e.target.value);
            }}
          />
          <Multiselect {...{ form, setHasChanges }} />
          <Select
            label="Limit type"
            value={form.values.interval}
            placeholder="Select Limit Type"
            data={[
              { label: 'Monthly', value: 'monthly' },
              { label: 'Unlimited', value: 'unlimited' },
            ]}
            onChange={(value) => {
              setHasChanges(true);
              form.setFieldValue('interval', value!);
            }}
            style={{ marginTop: '15px' }}
          />

          {form.values.interval === 'monthly' && (
            <Input
              label="Monthly limit"
              style={{ marginTop: '15px' }}
              value={formatCurrency(form.values.amount || '0')}
              onChange={(v) => onInputChange(v.currentTarget.value)}
            />
          )}

          {errorMessage !== '' && (
            <div style={{ marginTop: '1.5rem' }}>
              <Alert
                icon={<FaExclamationCircle color="red" />}
                radius={8}
                color="red"
              >
                {errorMessage}
              </Alert>
            </div>
          )}

          <div className="buttons-container">
            <Button
              radius="md"
              color="gray"
              variant="outline"
              onClick={() => setShowOptions(false)}
              styles={{ root: { height: '40px', fontWeight: 500 } }}
            >
              Cancel
            </Button>
            <Button
              radius="md"
              loading={isLoading}
              onClick={updateCard}
              disabled={
                !hasChanges ||
                (form.values.interval === 'monthly' && form.values.amount === 0)
              }
              color={theme.fn.primaryColor()}
              styles={{
                root: {
                  height: '40px',
                  '&:disabled': {
                    opacity: 0.5,
                    color: '#fff !important',
                    backgroundColor: `${theme.fn.primaryColor()} !important`,
                  },
                },
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCard;
