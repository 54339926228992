import { Merchant, PayWithFlexbase } from 'flexbase-client';
import { useEffect, useState } from 'react';
import { useCreditCheck } from 'services/credit/credit-check';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { useQuery } from 'utilities/url/query-param';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { Analytics } from 'services/analytics/analytics';
import { UserIdState } from '../onboarding/onboarding-form.state';

enum BnplStep {
  Congrats,
  Loc,
  Autopay,
  Confirm,
  FailedLoc,
  Error,
  Loading,
  Success,
}

export const useBnpl = () => {
  const creditCheck = useCreditCheck();
  const query: URLSearchParams = useQuery();
  const setBnplData = useSetRecoilState(BnplState);

  const paramAmount: string = query.get('amount')?.replace(/,/g, '') || '';
  const paramApiKey: string | null = query.get('apiKey') ?? query.get('apikey');
  const paramCallback: string | null = query.get('callback');
  const paramSession: string | null = query.get('session');
  const userId = useRecoilValue(UserIdState);

  const [merchant, setMerchant] = useState<Merchant>();
  const [totalLoc, setTotalLoc] = useState<number>();
  const [lastFourBankAccountDigits, setLastFourBankAccountDigits] =
    useState<string>();
  const [error, setError] = useState<string>();
  const [noCredit, setNoCredit] = useState(false);
  const [bnplSuccess, setBnplSuccess] = useState(false);

  const [isBuyNowRunning, setIsBuyNowRunning] = useState(false);
  const [isCheckCreditRunning, setIsCheckCreditRunning] = useState(false);
  const [isValidationRunning, setIsValidationRunning] = useState(false);
  const [bnplStep, setBnplStep] = useState<BnplStep>();

  const validateParamsAndMerchant = async () => {
    setIsValidationRunning(true);

    if (
      !paramApiKey ||
      !paramCallback ||
      !paramAmount ||
      !Number(paramAmount) ||
      !paramSession
    ) {
      setError(
        `Something went wrong. Please try again later or select another payment method at the merchant. You will be redirected shortly.`,
      );
    } else {
      // TODO: Enable this at some point. DOZR doesn't want it currently
      // Before we do anything, verify the transaction.
      // const env = getEnvironment(window.location.host);

      // if (env === 'production') {
      //   const verified = await flexbaseOnboardingClient.verifyBnplTransaction(
      //     paramSession,
      //     paramApiKey,
      //     paramAmount,
      //   );
      //
      //   if (!verified) {
      //     setError(
      //       `Flexbase is unable to issue you credit at this time. Please select another payment method at ${
      //         merchant?.name || 'the merchant'
      //       }`,
      //     );
      //     setIsValidationRunning(false);
      //     return;
      //   }
      // }

      const response = await flexbaseClient.getMerchant(paramApiKey);

      if (!response) {
        setError(
          `Something went wrong. Please try again later or select another payment method at the merchant. You will be redirected shortly.`,
        );
      } else {
        setMerchant(response);
        setBnplStep(BnplStep.Congrats);
        setBnplData({
          amount: paramAmount,
          merchantLogoUrl: response.logoUrl,
          merchantName: response.name,
          isBnpl: true,
          hasError: false,
          callbackBaseUrl: response.baseUrl,
          sessionParam: paramSession || '',
          callbackParam: paramCallback,
          invoiceId: '',
          status: TransactionStatus.pending,
        });
        if (userId) {
          Analytics.people.set(userId, {
            isBnpl: true,
          });
        }
      }

      try {
        const companyResponse =
          await flexbaseOnboardingClient.getOnboardingStatus();
        const accountNumber =
          companyResponse.company.financialInstitutions[0].account;
        setLastFourBankAccountDigits(accountNumber.slice(-4));
      } catch (err) {
        setError(
          `Something went wrong. Please try again later or select another payment method at ${
            merchant?.name || 'the merchant'
          }. You will be redirected shortly`,
        );
      }
    }

    // Is this stupid? Maybe. But if the API returned a status, I wouldn't have to do this.
    // Credit limit is 0 if they have been denied, null/undef if it has not been run
    const companyResponse =
      (await flexbaseOnboardingClient.getUserCompany()) as {
        creditLimit: string | null;
      };
    if (
      companyResponse.creditLimit === undefined ||
      companyResponse.creditLimit === null
    ) {
      setError(
        `Your application is still under review. Please try again later or select another payment method at ${
          merchant?.name || 'the merchant'
        }. You will be redirected shortly.`,
      );
    } else if (Number(companyResponse.creditLimit) <= 0) {
      // Set a generic error so that DOZR doesn't complain, but they'll probably complain about this
      setError(
        `Something went wrong. Please try again later or select another payment method at ${
          merchant?.name || 'the merchant'
        }. You will be redirected shortly.`,
      );
    } else {
      const companyCredit = await flexbaseClient.getCompanyCredit(); // This always returns 0 for credit limit if it is null or undefined.
      if (!companyCredit?.available) {
        setError(
          `Not enough available credit. Please select another payment method at ${
            merchant?.name || 'the merchant'
          }. You will be redirected shortly`,
        );
      } else {
        setTotalLoc(companyCredit!.available);
      }
    }

    setIsValidationRunning(false);
  };

  const checkCredit = async () => {
    setIsCheckCreditRunning(true);
    const response = await creditCheck(Number(paramAmount!));

    if (response) {
      setNoCredit(true);
    }
    setIsCheckCreditRunning(false);
  };

  const buyNow = async (successAction: (invoiceId: string) => void) => {
    setIsBuyNowRunning(true);
    const bnplPayload: PayWithFlexbase = {
      apiKey: paramApiKey!,
      amount: Number(paramAmount!),
      session: paramSession!,
      mode: 'immediate',
      description: merchant!.name,
    };

    const response = await flexbaseClient.requestPayWithFlexbase(bnplPayload);

    if (!response.approved) {
      setError(
        `Flexbase is unable to issue you credit at this time. Please select another payment method at ${
          merchant?.name || 'the merchant'
        }. You will be redirected shortly`,
      );
      Analytics.track('BNPL Transaction Error', {
        ...response,
      });
    } else {
      setBnplSuccess(true);
      Analytics.track('BNPL Transaction Approved', {
        ...response,
      });
      successAction(response.invoice?.id || '');
    }

    setIsBuyNowRunning(false);
  };

  useEffect(() => {
    validateParamsAndMerchant();
  }, []);

  useEffect(() => {
    if (merchant && merchant.id) {
      checkCredit();
    }
  }, [merchant]);

  return [
    {
      query,
      merchant,
      totalLoc,
      lastFourBankAccountDigits,
      callback: paramCallback,
      amount: paramAmount ? paramAmount!.replaceAll(',', '') : '',
      session: paramSession,
      isLoading: isValidationRunning || isCheckCreditRunning || isBuyNowRunning,
      error,
      noCredit,
      success: bnplSuccess,
      bnplStep,
    },
    { buyNow },
    { setError },
    { setBnplStep },
  ];
};

export { BnplStep };
