export const sortList = (
  arrayList: Record<string, any>[],
  { sortBy = '', sortType = 'asc' } = {},
) => {
  if (Array.isArray(arrayList)) {
    arrayList = arrayList.sort((a, b) => {
      let resp = 0;
      const prev = a !== null && typeof a === 'object' ? a[sortBy] : a;
      const curr = b !== null && typeof b === 'object' ? b[sortBy] : b;
      if (
        typeof prev === 'string' &&
        /^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(prev)
      ) {
        resp = new Date(prev).getTime() - new Date(curr).getTime();
      } else if (Object.prototype.toString.call(prev) === '[object Date]') {
        resp = prev - curr;
      } else if (typeof prev === 'string' || typeof prev === 'number') {
        resp = prev > curr ? 1 : -1;
      }
      return sortType === 'desc' ? -1 * resp : resp;
    });
  }
  return arrayList;
};
