import { createStyles, rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  actionsContainer: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#e5e7eb',
    },
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    padding: '6px 8px',
    color: 'gray',
  },

  transactionsContainer: {
    height: '100%',
    '.table': {
      padding: '10px',
      margin: '16px 0px',
      borderRadius: '8px',
      alignItems: 'center',
      backgroundColor: '#F9FBFE',
      '@media(max-width: 767px)': {
        border: '1px solid',
        borderColor: '#ebf0f7',
      },
      span: {
        fontSize: '12px',
        lineHeight: '20px',
        color: 'rgba(111, 113, 129, 0.56)',
      },
      p: {
        margin: '0px',
        color: '#0b1130',
      },
      button: {
        border: 0,
        cursor: 'pointer',
        backgroundColor: 'unset',
      },
      '.actions': {
        display: 'flex',
        justifyContent: 'end',
      },
    },
  },

  emptyState: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: '50px',
      justifyContent: 'center',
    },

    '> div': {
      width: '30%',
    },

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: '32px',
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: '14px',
      textAlign: 'center',
    },
  },

  // invoice info modal styles
  modalContainer: {
    padding: '5px 32px',
    '@media(max-width: 767px)': {
      '.mantine-Col-root grid mantine-9f0u9d': {
        span: {
          margin: '0px',
        },
      },
    },
    textarea: {
      padding: '2px 0px',
    },
    span: {
      color: '#6f7181',
      fontSize: '14px',
      margin: '5px',
    },
    p: {
      margin: '2px',
      fontSize: '14px',
    },
    '.transaction-details': {
      margin: '0px',
      borderRadius: '8px',
      border: '1px solid #ecf0f1',
    },
    '.grid': {
      borderBottom: '2px solid #f4f7f8',
      span: {
        '@media(max-width: 767px)': {
          margin: '0px',
        },
      },
    },
    '.buttons-container': {
      display: 'block',
      '.main-action': {
        height: 'auto',
        padding: '10px',
        '> div': {
          justifyContent: 'center',
        },
        span: {
          color: '#fff',
        },
      },
      '.secondary-action': {
        border: 0,
        width: '100%',
        color: '#adb0ca',
        fontSize: '12px',
        cursor: 'pointer',
        marginTop: '12px',
        backgroundColor: 'unset',
        justifyContent: 'center',
      },
    },
    '.title': {
      fontSize: '20px',
      margin: '27px 0px',
      fontFamily: 'PP Neue Montreal',
    },
    '.subtitle': {
      margin: '20px 0px',
      fontFamily: 'PP Neue Montreal',
    },
    '.invoice': {
      marginTop: '16px',
      position: 'relative',
      button: {
        top: 10,
        left: 10,
        zIndex: 1,
        width: '32px',
        height: '32px',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        borderRadius: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        border: '1px solid rgba(255, 255, 255, 0.5)',
      },
    },
    '.loading': {
      display: 'flex',
      justifyContent: 'center',
      height: '360px',
      alignItems: 'center',
    },
    '.error-message': {
      fontSize: '12px',
      marginTop: '20px',
      color: theme.fn.primaryColor(),
    },
  },

  // dropzone styles
  root: {
    padding: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #ecf0f1',
    '&:hover': {
      backgroundColor: 'rgba(57, 160, 255, 0.04)',
    },
    p: {
      fontSize: '12px',
      textAlign: 'center',
      color: 'rgba(111, 113, 129, 0.56)',
    },
  },

  cardsContainer: {
    paddingBottom: rem(10),
    '@media(max-width: 767px)': {
      '.table': {
        width: '100%',
        td: {
          border: 'none',
          paddingBottom: '0px',
        },
        th: {
          border: 'none',
        },
        tr: {
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid grey',
          padding: '1em',
          marginBottom: '1em',
        },
        thead: {
          display: 'none',
        },
        'td[data-title]': {
          display: 'flex',
        },
        'td[data-title]::before': {
          content: `attr(data-title)`,
          display: 'grid',
          gridTemplateColumns: '6rem',
          fontWeight: 'bold',
        },
      },
    },
  },

  tableTitles: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '12px',
    color: '#6f7181',
    fontWeight: 400,
  },

  tableArrows: {
    cursor: 'pointer',
    marginTop: '0.2rem',
  },

  // pagination styles
  item: {
    fontSize: '12px',
    color: '#6F7181',
    marginTop: '20px',
    backgroundColor: '#fff',
    border: '1px solid #EBF0F7',
    '&[data-active]': {
      color: theme.fn.primaryColor(),
    },
  },
  dots: {
    fontSize: '12px',
    color: '#6F7181',
    marginTop: '20px',
    backgroundColor: '#fff',
    border: '1px solid #EBF0F7',
  },
  active: {
    fontSize: '12px',
    color: theme.fn.primaryColor(),
    marginTop: '20px',
    backgroundColor: '#fff',
    border: '1px solid #EBF0F7',
  },

  // issue card modal
  issueCardContainer: {
    '.mantine-Title-root': {
      '@media(max-width: 767px)': {
        fontSize: '24px',
      },
    },
    h1: {
      fontWeight: 300,
      textAlign: 'center',
    },
    p: {
      fontSize: '14px',
      color: '#6f7181',
      margin: '8px 0px',
    },
    span: {
      fontSize: '18px',
    },
    input: {
      ':not(:last-child)': {
        margin: '15px 0px',
      },
    },
    'input[type="checkbox"]': {
      margin: '0px',
    },
    '.box': {
      width: '100%',
      display: 'flex',
      padding: '28px',
      marginTop: '25px',
      cursor: 'pointer',
      borderRadius: '8px',
      alignItems: 'center',
      textAlign: 'initial',
      backgroundColor: 'unset',
      border: '1px solid #ebf0f7',
      justifyContent: 'space-between',
      '@media(max-width: 767px)': {
        padding: '0px',
      },
      '& svg': {
        color: '#ebf0f7',
      },
      '&:hover': {
        padding: '27px',
        borderRadius: '8px',
        border: `2px solid ${theme.fn.primaryColor()}`,
        '& svg': {
          color: theme.fn.primaryColor(),
        },
      },
    },
    '.buttons-container': {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      '@media(max-width: 767px)': {
        justifyContent: 'center',
        display: 'contents',
      },
    },
    '.btn-create-card': {
      height: '40px',
      '@media(max-width: 767px)': {
        width: '100%',
        marginLeft: 0,
      },
    },
    '.image-container': {
      display: 'flex',
      marginTop: '80px',
      justifyContent: 'center',
      '@media(max-width: 767px)': {
        width: '100%',
        height: '100%',
      },
    },
    '.alert': {
      left: 20,
      bottom: 50,
      position: 'absolute',
    },
    '.center-container': {
      display: 'flex',
      marginTop: '50px',
      justifyContent: 'center',
    },
  },

  // checkboxs styles
  input: {
    backgroundColor: '#f9fbfe !important',
    borderColor: '#ebf0f7 !important',
  },

  icon: {
    color: '#1ec98b',
  },

  grayContainer: {
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ebf0f7',
    width: '60%',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },

  totalBalanceContainer: {
    marginRight: '1rem',
    marginTop: '5rem',
    borderColor: '#c8d6e5',
    width: '70%',
    margin: '1.5rem',
  },

  creditLimitCont: {
    fontSize: '0.875rem',
    marginBottom: '0.5rem',
    color: '#6f7181',
  },

  statusBar: {
    borderRadius: '0.5rem',
    backgroundColor: '#EBF0F6',
  },

  greenBar: {
    backgroundColor: '#5ec92c',
    height: '0.5rem',
    borderRadius: '0.5rem',
  },

  purpleContainer: {
    backgroundColor: '#7f7bf7',
    width: '40%',
    borderRadius: '0.5rem',
    padding: '1.5rem',
    marginLeft: '1rem',
    '@media(max-width: 767px)': {
      width: '100%',
      marginLeft: '0px',
    },
  },

  repayButton: {
    backgroundColor: 'rgb(216 180 254)',
    width: '100%',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    borderRadius: '0.375rem',
    outline: 'none',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderWidth: '0px',
  },

  containerRepayButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4rem',
  },

  containerCompBalance: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '1rem',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      marginLeft: '0rem',
    },
  },

  btnNewCreditCard: {
    background: 'white',
    borderColor: theme.fn.primaryColor(),
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 'normal',
    ':hover': {
      background: 'white',
    },
    width: '300px',
  },

  containerSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.5rem',
    paddingBottom: '24px',
  },

  searchInput: {
    color: 'black',
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      width: '65%',
    },
  },

  holderField: {
    marginLeft: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'max-content',
  },

  cardAvatar: {
    color: theme.fn.primaryColor(),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: `${theme.colors.neutral[2]} !important`, // TODO change this shit
      fontSize: '14px',
      fontWeight: 400,
    },
  },

  utilizationField: {
    marginLeft: '0.5rem',
    color: 'black',
    fontSize: '12px',
  },

  showAccountNumber: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  containerTable: {
    background: 'white',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    marginBottom: '1rem',
    padding: '0.1rem 1.5rem',
  },

  tableTitle: {
    color: '#5F5F5F',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
  },

  inputSearch: {
    '.mantine-TextInput-input': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: ' #5F5F5F',
    },
  },

  containerMessage: {
    width: 'max-content',
    position: 'fixed',
    bottom: '2rem',
    zIndex: 1,
  },

  containerButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  badge: {
    fontWeight: 500,
    fontSize: '12px',
    marginLeft: '10px',
    padding: '4px 15px',
    textTransform: 'capitalize',
  },
}));

export const CustomMantineStyles = (primaryColor?: string) => {
  return {
    searchTransactions: {
      input: {
        width: '100%',
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '8px',
        fontSize: '14px',
        background: 'white',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        color: ' #5F5F5F',
        '&:focus': { borderColor: primaryColor },
      },
    },
    issueCard: {
      radio: {
        body: {
          alignItems: 'center',
        },
      },
    },
  };
};
