import { createStyles } from '@mantine/core';

export const detailsStyles = createStyles(() => ({
  title: {
    fontStyle: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '43px',
    '@media(max-width: 767px)': {
      fontSize: '1.3rem',
    },
  },

  btnCloseContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },

  subtitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#4B4B4B',
  },

  data: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#4B4B4B',
    marginTop: '0.8vh',
  },

  container: {
    paddingLeft: '3rem',
    marginTop: '2rem',
    paddingRight: '3rem',
  },
}));
