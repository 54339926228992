import { atom, selector } from 'recoil';
import { OnboardingCompany } from 'states/onboarding/onboarding-info';
import { UTM_VALUES } from 'states/business/constants';
import { PersonalAddressFormValues } from './steps/verify-business/verify-business';
import { VerifyIdentityFormValues } from './steps/verify-identity/verify-identity';
import { ApplicationState } from 'states/application/product-onboarding';
import { formatOnboardingApiDate } from 'utilities/formatters/format-datetime';

export function getUtmParamsFromCompany(company: OnboardingCompany): string {
  const utmParams = new URLSearchParams();

  if (company?.averageMonthlyPayroll) {
    utmParams.append(
      'payroll',
      UTM_VALUES.get(company?.averageMonthlyPayroll)!,
    );
  }

  if (company?.monthlyExpenditure) {
    utmParams.append('cardspend', UTM_VALUES.get(company?.monthlyExpenditure)!);
  }

  if (company?.category) {
    utmParams.append('industry', UTM_VALUES.get(company?.category)!);
  }

  return utmParams.toString();
}

export const PersonalAddressFormState = atom<PersonalAddressFormValues>({
  key: 'personal_address_v2',
  default: selector({
    key: 'personal_address_v2/default',
    get: ({ get }) => {
      const onboardingStatus = get(ApplicationState);
      return {
        line1: onboardingStatus.user?.address?.line1 || '',
        line2: onboardingStatus.user?.address?.line2 || '',
        state: onboardingStatus.user?.address?.state || '',
        postalCode: onboardingStatus.user?.address?.postalCode || '',
        city: onboardingStatus.user?.address?.city || '',
        country: onboardingStatus.user?.address?.country || 'US',
      };
    },
  }),
});

export const VerifyIdentityFormState = atom<VerifyIdentityFormValues>({
  key: 'verify_identity_v2',
  default: selector({
    key: 'verify_identity_v2/default',
    get: ({ get }) => {
      const { user } = get(ApplicationState);
      return {
        birthDate: formatOnboardingApiDate(user.birthDate),
        taxId: user.taxId || '',
      };
    },
  }),
});
