import { useEffect, useState } from 'react';
import { loadVGSCollect } from '@vgs/collect-js';

import DebitCard from '../debit-card-details/debit-card';
import { Alert, Button, useMantineTheme } from '@mantine/core';
import { CardByUser } from 'services/flexbase/banking.model';
import { RedAlertIcon } from 'assets/svg';

interface Props {
  loading: boolean;
  cardStatus: string;
  errorMessage: string;
  card: CardByUser;
  onNextClick: (f: any) => void;
}

const SetPin = ({
  card,
  loading,
  cardStatus,
  errorMessage,
  onNextClick,
}: Props) => {
  const theme = useMantineTheme();
  const [errors, setErrors] = useState({
    pin: '',
    validationPinMessage: '',
  });
  const [form, setForm] = useState({
    submit: (path: string, req: any, result: any) => void 0,
  });

  const onClick = () => {
    setErrors({ ...errors, validationPinMessage: '' });
    if (errors.pin.includes('is required')) {
      setErrors({ ...errors, validationPinMessage: '*This field is required' });
    } else if (errors.pin.includes("the value doesn't match a pattern")) {
      setErrors({
        ...errors,
        validationPinMessage:
          '*Make sure that the pattern has more than four-digit',
      });
    } else {
      onNextClick(form);
    }
  };

  useEffect(() => {
    const loadForm = async () => {
      const vgs_collect = await loadVGSCollect({
        vaultId: 'tntazhyknp1',
        environment: 'sandbox',
        version: '2.18.0',
      }).catch((e) => {
        console.log(e);
      });
      initForm(vgs_collect);
    };
    loadForm();
  }, []);

  const initForm = (vgs_collect: any) => {
    const form = vgs_collect.init((state: any) =>
      setErrors({ ...errors, pin: state['data.attributes.pin'].errorMessages }),
    );
    form.field('#cc-pin', {
      type: 'text',
      name: 'data.attributes.pin',
      successColor: theme.colors.positive[2],
      errorColor: theme.colors.critical[1],
      placeholder: '1234',
      maxLength: 6,
      validations: ['required', '/^([0-9]{4,6})$/'],
      css: {
        display: 'flex',
        fontSize: '48px',
        textAlign: 'center',
        position: 'relative',
        '&::placeholder': {
          color: '#979797',
        },
      },
    });
    setForm(form);
  };

  return (
    <div className="set-pin">
      <span className="title">Set your new pin</span>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div id="cc-pin" className="field" />
      </div>
      <p className="valiation-message">{errors.validationPinMessage}</p>

      <div style={{ margin: '30px 0px' }}>
        <DebitCard {...{ card, cardStatus }} />
      </div>

      {errorMessage && errorMessage !== '' && (
        <Alert mt={20} icon={<RedAlertIcon />} color="red">
          {errorMessage}
        </Alert>
      )}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          mt={20}
          variant="light"
          type="submit"
          {...{ loading }}
          onClick={onClick}
        >
          Save pin
        </Button>
      </div>
    </div>
  );
};

export default SetPin;
