import IAddress from 'states/user/address';

type Props = { address: IAddress } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const PlainTextAddress = ({ address, ...rest }: Props) => {
  return (
    <div {...rest}>
      {address.line1}
      <br />
      {address.line2 ? address.line2 : ''}
      {address.line2 && <br />}
      {`${address.city}, ${address.state}`}
      <br />
      {address.postalCode}
    </div>
  );
};

export default PlainTextAddress;
