import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Group,
  Loader,
  Select,
  Switch,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './auto-pay.styles';
import { showNotification } from '@mantine/notifications';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TransparentCenteredLoader } from '@flexbase-eng/web-components';
import { Analytics } from 'services/analytics/analytics';
import AccountSelectItem from '../account-select-item/account-select-item';
import { PlusSignIcon } from '../../../../assets/svg';
import {
  onError,
  usePlaidBankingComponent,
} from '../../../../components/banking/plaid-banking-component';
import {
  IsAccountant,
  ApplicationState,
  getProductOnboardingStatus,
} from '../../../../states/application/product-onboarding';
import { FinancialInstitution } from 'states/onboarding/onboarding-info';

interface CompanyBalance {
  currentBalance: number;
  creditLimit: number;
  minimumDue: number;
  billDate: string;
}

const AutoPay = () => {
  const { classes } = useStyles();
  const isAccountant = useRecoilValue(IsAccountant);
  const [loading, setLoading] = useState(true);
  const [{ company }, setCompany] = useRecoilState(ApplicationState);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [error, setError] = useState('');
  const [companyBalance, setCompanyBalance] = useState<CompanyBalance>({
    currentBalance: 0,
    creditLimit: 0,
    minimumDue: 0,
    billDate: '',
  });
  const [accounts, setAccounts] = useState<
    { value: string; label: string; description: string }[]
  >([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const { open } = usePlaidBankingComponent({
    onSuccess: () => onLinkBank(),
    onError: () => {
      onError();
      setAccountLoading(false);
    },
    setLoading,
  });

  const now = DateTime.now().toString();

  const getCompanyBalance = async () => {
    setLoading(true);
    try {
      const currentCompanyBalance = await flexbaseClient.getCompanyBalance(
        `${company.id}`,
      );
      if (currentCompanyBalance) {
        setCompanyBalance(currentCompanyBalance);
      } else {
        setError('Error');
      }
    } catch (error) {
      setError('Error');
    } finally {
      setLoading(false);
    }
  };

  const onLinkBank = async () => {
    showNotification({
      color: 'flexbase-teal',
      title: 'Bank Account Linked!',
      message: 'Now you may select it as your default payment account.',
      autoClose: 10000,
    });
    try {
      setAccountLoading(true);
      const { company } = await getProductOnboardingStatus();
      await getAccounts(company.financialInstitutions);
    } catch (error) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to refresh linked bank account info',
      });
    } finally {
      setAccountLoading(false);
    }
  };

  // Yes, this is copy-pasted from credit-payment.tsx
  const onAutopayToggle = async (enable: boolean) => {
    setLoading(true);

    try {
      await flexbaseOnboardingClient.updateCompany({
        autopay: enable,
      });
      setCompany((prev) => ({
        ...prev,
        company: { ...prev.company, autopay: enable },
      }));
      showNotification({
        title: 'Success!',
        message: `Autopay successfully ${enable ? 'enabled' : 'disabled'}.`,
        color: 'flexbase-teal',
      });
      Analytics.track('Credit Switched Autopay Flag', { enable });
    } catch (error) {
      showNotification({
        title: 'Failure',
        message: `Autopay could not be ${
          enable ? 'enabled' : 'disabled'
        }. Please contact support if the issue persists.`,
        color: 'red',
      });
    } finally {
      setLoading(false);
      try {
        setCompanyLoading(true);
        const currentCompanyBalance = await flexbaseClient.getCompanyBalance(
          `${company.id}`,
        );
        if (currentCompanyBalance) {
          setCompanyBalance(currentCompanyBalance);
        }
      } catch (error) {
        console.error('Unable to get the company balance', error);
      } finally {
        setCompanyLoading(false);
      }
    }
  };

  const getAccounts = async (accounts: FinancialInstitution[]) => {
    const mappedAccounts = accounts
      .filter((fi) => fi.active && !fi.unlinked)
      .map((fi) => {
        return {
          value: fi.id!,
          description: fi.bankName!,
          label: `${fi.accountName} - ${fi.last4}`,
        };
      });
    setAccounts(mappedAccounts);
    const primaryPlaidAccount = mappedAccounts.find(
      ({ value }) => value === company.primaryPlaidAccount,
    );
    setSelectedAccount(primaryPlaidAccount?.value ?? null);
  };

  const onAccountChange = async (value: string | null) => {
    if (!value) {
      setSelectedAccount(value);
    } else {
      setAccountLoading(true);
      try {
        await flexbaseOnboardingClient.updateCompany({
          primaryPlaidAccount: value,
        });
        setCompany((prev) => ({
          ...prev,
          company: { ...prev.company, primaryPlaidAccount: value },
        }));
        showNotification({
          color: 'flexbase-teal',
          title: 'Success',
          message: 'Successfully updated payment account',
        });
        setSelectedAccount(value);
      } catch (error) {
        console.error('Unable to update repayment account.', error);
        showNotification({
          color: 'red',
          title: 'Error',
          message: 'Unable to update repayment account.',
        });
      } finally {
        setAccountLoading(false);
      }
    }
  };

  useEffect(() => {
    getCompanyBalance();
    getAccounts(company.financialInstitutions);
  }, []);

  return (
    <div className={classes.baseContainer}>
      <div className={classes.autoPayContainer}>
        <TransparentCenteredLoader visible={loading} />
        <div className={classes.autoPaySettings}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 28,
              marginBottom: 5,
            }}
          >
            <Text className={classes.title}> Auto pay Settings </Text>
            {!company.autopay && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text className={classes.title}> Auto Pay </Text>
                <Switch
                  disabled={isAccountant}
                  style={{ marginLeft: 8 }}
                  checked={company.autopay}
                  onChange={(e) => onAutopayToggle(e.currentTarget.checked)}
                  color="flexbase-orange"
                  size="md"
                  styles={{
                    input: { width: '50px', cursor: 'pointer' },
                  }}
                />
              </div>
            )}{' '}
          </div>
          {companyLoading ? (
            <Loader color="red" size={'sm'} />
          ) : (
            <Text className={classes.dateAmountDue}>
              {companyBalance.billDate
                ? DateTime.fromISO(companyBalance.billDate).toFormat('LLL dd')
                : DateTime.fromISO(now).toFormat('LLL dd')}{' '}
              for{' '}
              {formatCurrency(
                companyBalance.minimumDue > 0 ? companyBalance.minimumDue : 0,
              )}
            </Text>
          )}
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text className={classes.title}> Default Payment Account </Text>
            </div>
            <Box mt={8}>
              <Group>
                <Select
                  data={accounts}
                  itemComponent={AccountSelectItem}
                  placeholder={'Select default account'}
                  value={selectedAccount}
                  onChange={(v) => onAccountChange(v)}
                  icon={accountLoading ? <Loader size="sm" /> : <Box w={36} />}
                  disabled={accountLoading}
                />
                <Button
                  variant="outline"
                  w="fit-content"
                  disabled={accountLoading}
                  leftIcon={<PlusSignIcon />}
                  onClick={() => {
                    setAccountLoading(true);
                    open();
                  }}
                >
                  Link New Account
                </Button>
              </Group>
              {error && (
                <Text
                  className={classes.bankBalance}
                  mt={4}
                  sx={(theme) => ({ color: theme.colors.failure[1] })}
                >
                  {error}
                </Text>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoPay;
