import { useOnboardingStyles } from '../../onboarding.styles';
import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';
import { useProductOnboarding } from '../../onboarding-hooks';
import { Text, TextInput } from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import OnboardingStep from '../../components/onboarding-step';
import { useState } from 'react';
import FlexbaseSelect from 'components/select/flexbase-select';
import { Analytics } from 'services/analytics/analytics';
import TagManager from 'react-gtm-module';
import { ApplicationState } from '../../../../states/application/product-onboarding';

export type BusinessTypeFormValues = {
  legalStructure: string;
  otherStructure: string;
};

const legalStrucs = [
  { label: 'LLC', value: 'LLC' },
  { label: 'C-Corp', value: 'C-Corp' },
  { label: 'S-Corp', value: 'S-Corp' },
  { label: 'Limited Partnership', value: 'LP' },
  { label: 'Sole Proprietor', value: 'S-Prop' },
  { label: 'Trust', value: 'Trust' },
  { label: 'Non-Profit', value: 'Non-Profit' },
];

const BusinessType = () => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { navigateToNextProductStep, goBack, createOrUpdateCompany } =
    useProductOnboarding();
  const [companyOnboarding, setCompanyOnboarding] =
    useRecoilState(ApplicationState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useRecoilValue(ApplicationState);

  const businessTypeForm = useForm<BusinessTypeFormValues>({
    initialValues: {
      legalStructure: companyOnboarding.company.legalStructure || '',
      otherStructure: '',
    },
    validate: {
      legalStructure: (val) =>
        validateRequired(val) ? null : 'Please select a business type',
      otherStructure: (val: string, vals: BusinessTypeFormValues) =>
        vals.legalStructure !== 'Other' || validateRequired(val)
          ? null
          : 'Please specify business type',
    },
  });

  const onSubmit = async () => {
    const validationResult = businessTypeForm.validate();
    if (!validationResult.hasErrors) {
      setError('');
      setLoading(true);
      const { otherStructure, ...formValues } = businessTypeForm.values;
      if (user) {
        Analytics.people.set(user.id, {
          businessType: formValues.legalStructure,
        });
      }
      if (formValues.legalStructure === 'Other') {
        formValues.legalStructure = otherStructure;
      }
      setCompanyOnboarding((prev) => ({
        ...prev,
        ...formValues,
      }));
      let canNavigateToNextStep = true;
      if (companyOnboarding.company.id) {
        const result = await createOrUpdateCompany(formValues);
        canNavigateToNextStep = result.success;
      }
      if (canNavigateToNextStep) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'businessTypeSubmitted',
          },
        });
        navigateToNextProductStep(true);
      } else {
        setError('Unable to update business type');
        setLoading(false);
      }
    } else {
      setError('Please select a business type');
    }
  };

  const onBack = () => {
    goBack();
  };

  const setLegalStructure = (value: string) => {
    setError('');
    businessTypeForm.setFieldValue('legalStructure', value);
  };

  const setOtherStructure = (value: string) => {
    setError('');
    businessTypeForm.setFieldValue('otherStructure', value);
  };

  return (
    <OnboardingStep
      title="Business type"
      subtitle="How are you operating?"
      onBackClick={onBack}
      onNextClick={onSubmit}
      stepId="Business-type"
      showContinueSpinner={loading}
      error={error}
    >
      <FlexbaseSelect
        placeholder="Select a legal structure"
        data={legalStrucs}
        value={businessTypeForm.values.legalStructure}
        onChange={setLegalStructure}
        id="input-select-business-type"
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
      />
      {businessTypeForm.values.legalStructure === 'Other' && (
        <TextInput
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          placeholder="Specify business type"
          mt={28}
          mb={16}
          {...businessTypeForm.getInputProps('otherStructure')}
          onChange={(e) => setOtherStructure(e.target.value)}
        />
      )}
      <Text className={onboardingClasses.descriptionLabel} mt={28}>
        By clicking Continue below, you represent and warrant that you are a
        “control person” (i.e., an individual that has significant ability to
        control, manage, or direct the business’s activity) for customer
        identification purposes.”
      </Text>
    </OnboardingStep>
  );
};

export default BusinessType;
