import {
  Accordion,
  Group,
  SegmentedControl,
  Text,
  TextInput,
} from '@mantine/core';
import { AchIcon } from 'assets/svg';
import { Counterparty } from 'services/flexbase/banking.model';
import { useStyles } from './detail.styles';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

type Props = {
  counterparties: Counterparty[];
  type: string;
  disabled?: boolean;
};

type AccordianLabelProps = {
  label: string;
  description: string;
};

const CounterpartyAccountDetails = ({
  counterparties,
  type,
  disabled = true,
}: Props) => {
  const { classes } = useStyles();

  // create fun accordion drop down
  const AccordianLabel = ({ label, description }: AccordianLabelProps) => {
    return (
      <Group noWrap>
        <AchIcon></AchIcon>
        <div>
          <Text>{label}</Text>
          <Text size="sm" color="neutral.6" weight={400}>
            {description}
          </Text>
        </div>
      </Group>
    );
  };

  // for each type there are 0 to many counterparties.
  const listCounterparties = counterparties.map((counterparty, index) => {
    // TODO: button to add an account if no counterparties exist
    const marginTop = 10;
    const name = counterparty.bank || counterparty.accountName;
    const mask = getPaddedAccountMask(counterparty.accountNumber, 2);
    const labelName = `${name} ${mask}`;

    if (counterparty.type === 'ach') {
      return (
        <div key={index} style={{ width: '100%' }}>
          <Accordion
            multiple
            classNames={{
              control: classes.control,
            }}
          >
            <Accordion.Item value={labelName}>
              <Accordion.Control>
                <AccordianLabel
                  label={labelName}
                  description={counterparty.accountType!}
                ></AccordianLabel>
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ fontSize: 14, fontWeight: 500 }}>
                  Recipient type
                </div>
                <SegmentedControl
                  data={[
                    { label: 'Person', value: 'Person' },
                    { label: 'Business', value: 'Business' },
                  ]}
                  style={{ marginTop }}
                  radius={8}
                  transitionDuration={800}
                  transitionTimingFunction="linear"
                  disabled={disabled}
                  value={counterparty.response.data.attributes.type}
                />
                <TextInput
                  style={{ marginTop }}
                  label={'Routing number'}
                  placeholder="9 digit routing number"
                  data-testid="routing-number-input"
                  size="md"
                  disabled={disabled}
                  value={counterparty.routingNumber}
                />
                <TextInput
                  style={{ marginTop }}
                  label="Account Number"
                  data-testid="account-number-input"
                  placeholder="bank account number"
                  size="md"
                  disabled={disabled}
                  value={counterparty.accountNumber}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      );
    } else if (counterparty.type === 'wire') {
      const description = `${counterparty.address?.city}, ${counterparty.address?.state}`;
      return (
        <div key={index} style={{ width: '100%' }}>
          <Accordion
            multiple
            classNames={{
              control: classes.control,
            }}
          >
            <Accordion.Item value={labelName}>
              <Accordion.Control>
                <AccordianLabel
                  label={labelName}
                  description={description}
                ></AccordianLabel>
              </Accordion.Control>
              <Accordion.Panel>
                <TextInput
                  style={{ marginTop }}
                  label={'Routing number'}
                  placeholder="9 digit routing number"
                  data-testid="routing-number-input"
                  size="md"
                  disabled={disabled}
                  value={counterparty.routingNumber}
                />
                <TextInput
                  style={{ marginTop }}
                  label="Account Number"
                  data-testid="account-number-input"
                  placeholder="bank account number"
                  size="md"
                  disabled={disabled}
                  value={counterparty.accountNumber}
                />
                <TextInput
                  style={{ marginTop }}
                  label="Address"
                  data-testid="street-input"
                  placeholder="street address"
                  size="md"
                  disabled={disabled}
                  value={counterparty.address?.address}
                />
                <TextInput
                  style={{ marginTop }}
                  label="City"
                  data-testid="city-input"
                  placeholder="city"
                  size="md"
                  disabled={disabled}
                  value={counterparty.address?.city}
                />
                <TextInput
                  style={{ marginTop }}
                  label="State"
                  data-testid="state-input"
                  placeholder="two digit state"
                  size="md"
                  disabled={disabled}
                  value={counterparty.address?.state}
                />
                <TextInput
                  style={{ marginTop }}
                  label="Postal Code"
                  data-testid="postal-input"
                  placeholder="6 digit postal code"
                  size="md"
                  disabled={disabled}
                  value={counterparty.address?.postalCode}
                />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      );
    }
  });

  return (
    <>
      <div className={classes.counterpartyTypeTitle}>{type} details</div>
      <div>{listCounterparties}</div>
    </>
  );
};

export default CounterpartyAccountDetails;
