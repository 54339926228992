import { createStyles } from '@mantine/core';
import { CheckBlack, WhiteCircle } from 'assets/svg';

interface Props {
  isSelected: boolean;
  optionLabel: string;
  onSelect: () => void;
  optionDescription: string | JSX.Element; // if we want different view on this section
}

const OptionBox = ({
  isSelected,
  optionLabel,
  onSelect,
  optionDescription,
}: Props) => {
  const { classes } = useStyles();

  return (
    <div onClick={onSelect} className={classes.optionPaymentBox}>
      {isSelected ? <CheckBlack /> : <WhiteCircle />}
      <div>
        <div className={classes.optionText}>{optionLabel}</div>
        <div className={classes.optionInfoContainer}>{optionDescription}</div>
      </div>
    </div>
  );
};

export default OptionBox;

const useStyles = createStyles((theme) => ({
  optionPaymentBox: {
    display: 'grid',
    margin: '10px 0px',
    gridTemplateColumns: '40px auto',
    alignItems: 'center',
    border: '1px solid #E6E7E9',
    borderRadius: '2px',
    padding: '10px 15px',
    cursor: 'pointer',
    color: theme.colors.primarySecondarySuccess[8],
    '&:hover': {
      backgroundColor: theme.colors.primarySecondarySuccess[4],
      cursor: 'pointer',
      color: theme.colors.neutral[0],
      fill: theme.colors.neutral[0],
    },
  },

  optionText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },

  optionInfoContainer: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}));
