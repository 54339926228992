import { createStyles } from '@mantine/core';
import {
  action,
  actionHover,
  foreground,
  foregroundLight,
} from 'utilities/theme/theme';

export const useProfileStyles = createStyles(() => ({
  containerForm: {
    display: 'flex',
    flexDirection: 'row',
    gap: '6rem',
    marginLeft: '1.25rem',
    width: '56rem',
    '@media(max-width: 767px)': {
      width: '100%',
      flexDirection: 'column',
      gap: '0rem',
      marginLeft: '0rem',
      padding: '10px',
      alignItems: 'center',
    },
  },

  imgContainer: {
    display: 'flex',
    marginTop: '1.75rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '9999px',
    height: '12rem',
    width: '12rem',
    border: ` 2px solid ${foregroundLight}`,
    [`& img`]: {
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: '100%',
      width: '100%',
      height: '100%',
    },
  },

  btnUploadPic: {
    width: '100%',
    color: foreground,
    backgroundColor: 'transparent',
    borderRadius: '8px',
    border: `1px solid ${foreground}`,
    height: 42,
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
      border: `1px solid ${foregroundLight}`,
    },
  },

  btnSaveProfile: {
    width: '100%',
    marginTop: '1rem',
    backgroundColor: action,
    border: 0,
    height: 42,
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
      backgroundColor: actionHover,
    },
  },

  btnChangePassword: {
    width: '100%',
    border: 0,
    height: 42,
    paddingLeft: 20,
    paddingRight: 20,
  },
}));
