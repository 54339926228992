import { CheckIcon, Group, createStyles } from '@mantine/core';
import PaymentStep from '../payment-step';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  paymentMethod,
  paymentRecipientName,
  PayMethod,
} from './payment.states';

type Props = {
  onNextClick: () => void;
  onBackClick: () => void;
};

const PaymentMethod = ({ onBackClick, onNextClick }: Props) => {
  const { classes } = useStyles();
  const recipientName = useRecoilValue(paymentRecipientName);
  const setPaymentMethodState = useSetRecoilState(paymentMethod);
  const paymentMethodState = useRecoilValue(paymentMethod);

  return (
    <PaymentStep
      titleText="How do you want to pay?"
      balloonText={`Payment to ${recipientName}`}
      onNextClick={() => onNextClick()}
      onBackClick={() => onBackClick()}
    >
      <div className={classes.inputContainer}>
        <PaymentOptionBox
          isSelected={paymentMethodState === 'flexPay'}
          optionName={'Flexbase Pay'}
          onSelect={() => setPaymentMethodState('flexPay')}
          price={'Free'}
          speed={'Flex Pay'}
          emphasizeSpeed
          disabled
        />
        <PaymentOptionBox
          isSelected={paymentMethodState === 'ach'}
          optionName="ACH"
          onSelect={() => setPaymentMethodState('ach')}
          price="Free"
          speed="3 business days"
        />
        <PaymentOptionBox
          isSelected={paymentMethodState === 'wire'}
          optionName="Wire"
          onSelect={() => setPaymentMethodState('wire')}
          price="Free"
          speed="1 business day"
        />
        <PaymentOptionBox
          isSelected={paymentMethodState === 'wireInternational'}
          optionName="International Wire"
          onSelect={() => setPaymentMethodState('wireInternational')}
          price="Coming soon"
          speed="1 business day"
          disabled
        />
      </div>
    </PaymentStep>
  );
};

type PaymentOptionBoxProps = {
  isSelected: boolean;
  optionName: string;
  onSelect: (optionName: PayMethod) => void;
  price: string;
  speed: string;
  emphasizeSpeed?: boolean;
  disabled?: boolean;
};
const PaymentOptionBox = ({
  isSelected,
  optionName,
  onSelect,
  price,
  speed,
  emphasizeSpeed,
  disabled,
}: PaymentOptionBoxProps) => {
  const { classes, theme, cx } = useStyles();

  const onBoxClick = () => {
    if (!disabled) {
      onSelect(optionName as PayMethod);
    }
  };

  return (
    <div
      className={`${classes.optionPaymentBox} ${
        disabled ? classes.disabled : classes.enabled
      }`}
      style={isSelected ? { backgroundColor: theme.fn.primaryColor() } : {}}
      data-testid={optionName}
      onClick={onBoxClick}
    >
      <Group>
        {isSelected ? (
          <div className={classes.circle}>
            <CheckIcon width={16} />
          </div>
        ) : (
          <div className={classes.circle} />
        )}
        <div
          className={cx(classes.optionText, isSelected && classes.lightText)}
        >
          {optionName}
        </div>
      </Group>
      <div className={classes.optionInfoContainer}>
        <div className={classes.priceText}>{price}</div>
        <div
          className={cx(classes.optionSpeed, isSelected && classes.lightText)}
        >
          {speed}
        </div>
      </div>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  optionPaymentBox: {
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
    padding: '20px',
  },
  enabled: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  optionText: {
    color: theme.fn.primaryColor(),
    fontSize: '16px',
    fontWeight: 500,
  },
  optionInfoContainer: {
    color: theme.fn.primaryColor(),
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  optionSpeed: {
    color: theme.fn.primaryColor(),
    fontWeight: 400,
    fontSize: 12,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  priceText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.fn.themeColor('primarySecondarySuccess', 1),
  },
  lightText: {
    color: theme.fn.themeColor('neutral', 0),
  },
  circle: {
    width: '24px',
    height: '24px',
    display: 'flex',
    borderRadius: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    color: theme.fn.primaryColor(),
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
}));

export default PaymentMethod;
