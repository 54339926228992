import { useMantineTheme } from '@mantine/core';
import Zendesk from 'react-zendesk';
const zendeskKey = import.meta.env.VITE_APP_ZENDESK_KEY || '';

const setting = (primaryColor?: string) => {
  return {
    color: {
      theme: primaryColor,
    },
    launcher: {
      chatLabel: {
        'en-US': 'Support',
      },
    },
    contactForm: {
      fields: [
        {
          id: 'description',
          prefill: { '*': 'describe how we can help you today!' },
        },
      ],
    },
    webWidget: {
      offset: {
        mobile: { vertical: '40px' },
      },
    },
  };
};

const ZenDesk = () => {
  const theme = useMantineTheme();
  return (
    <Zendesk
      defer
      zendeskKey={zendeskKey}
      {...setting(theme.fn.primaryColor())}
    ></Zendesk>
  );
};

export default ZenDesk;
