import { createContext, ReactNode, useContext, useState } from 'react';

type RouteSection = 'auth' | 'application' | 'main';
type RouteSectionContextReturnType = {
  showRoutesFor: RouteSection;
  setShowRoutesFor: (section: RouteSection) => void;
};
const RouteSectionContext = createContext<RouteSectionContextReturnType>({
  showRoutesFor: 'auth',
} as RouteSectionContextReturnType);

type RouteProviderProps = { children: ReactNode };
export const RouteSectionProvider = ({ children }: RouteProviderProps) => {
  const [showRoutesFor, setShowRoutesFor] = useState<
    'auth' | 'application' | 'main'
  >('auth');

  return (
    <RouteSectionContext.Provider value={{ showRoutesFor, setShowRoutesFor }}>
      {children}
    </RouteSectionContext.Provider>
  );
};

export const useRouteSectionContext = () => {
  return useContext(RouteSectionContext);
};
