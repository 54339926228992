import {
  Button,
  createStyles,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Merchant } from 'flexbase-client';

interface Props {
  closeModal: () => void;
  merchant: Merchant;
  totalLoc: number | undefined;
  amount?: string;
  merchantCallbackCancelUrl?: string;
  onContinue: () => void;
  onBack: () => void;
}

const useStyles = createStyles((theme) => ({
  loc: {
    '@media(max-width: 767px)': {
      width: '85%',
      minHeight: '87.5vh',
      justifyContent: 'space-between',
    },
    width: '600px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    margin: 'auto',
  },
  hereWeGo: {
    '@media(max-width: 767px)': {
      width: '97.5%',
      marginTop: '10%',
    },
    marginLeft: '5px',
    marginTop: '10%',
    fontSize: '21px',
    fontWeight: 700,
  },
  title: {
    '@media(max-width: 767px)': {
      fontSize: '24px',
      margin: '7.5% 0 7.5% 5px',
    },
    margin: '1.75% 0 1.75% 5px',
    fontWeight: 300,
    fontSize: '38px',
    fontFamily: 'PP Neue Montreal',
  },
  locBlock: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
      height: '100px',
    },
    border: '2px solid #F2F2F2',
    borderRadius: '10px',
    width: '100%',
    height: '70px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1% 0 1% 0',
    padding: '25px',
  },
  locText: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '14px',
    },
    fontSize: '18px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
  approveButton: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '20px',
    },
    height: '55px',
    backgroundImage: `linear-gradient(to bottom right, ${theme.fn.primaryColor()}, #FF8779)`,
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: '22px',
    marginTop: '7%',
  },
  cancelButton: {
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      fontSize: '20px',
    },
    height: '55px',
    color: '#8A8A8F',
    fontSize: '22px',
    fontWeight: 700,
    margin: '2% auto 2% auto',
    cursor: 'pointer',
  },
}));

const BnplLineOfCredit = ({
  closeModal,
  merchant,
  totalLoc,
  amount,
  merchantCallbackCancelUrl,
  onContinue,
  onBack,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const cancel = (): void => {
    window.location.replace(merchantCallbackCancelUrl!);
    closeModal();
  };
  const now = new Date();
  const locInfo = [
    {
      title: 'Available Line of Credit Amount:',
      value: `${formatCurrency(totalLoc + '')}`,
    },
    {
      title: `${merchant.name} transaction:`,
      value: `${formatCurrency(amount!)}`,
    },
    {
      title: `Repayment Term:`,
      value: '2 months',
    },
    {
      title: `Origination Fee:`,
      value: '$0',
    },
    {
      title: `Start Date:`,
      value: `Today, ${now.toLocaleString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })}`,
    },
    {
      title: `APR:`,
      value: '0% for 60 days',
    },
    {
      title: `Late APR:`,
      value: '24% after 60 days if not paid back',
    },
    {
      title: `Prepayment Penalty:`,
      value: '$0',
    },
  ];

  return (
    <div>
      <FlexbaseHeader onCloseButtonClick={cancel} />
      <div className={classes.loc}>
        <Text
          size="lg"
          weight="bold"
          align="left"
          className={classes.hereWeGo}
          color={theme.fn.primaryColor()}
        >
          Here we go!
        </Text>
        <Title order={1} align="left" className={classes.title}>
          Line of Credit Information
        </Title>
        {locInfo.map((info) => (
          <div key={info.title} className={classes.locBlock}>
            <Text className={classes.locText}>{info.title}</Text>
            <Text className={classes.locText} weight="bold">
              {info.value}
            </Text>
          </div>
        ))}

        <div className={classes.buttons}>
          <Button
            fullWidth={true}
            className={classes.approveButton}
            onClick={onContinue}
          >
            Accept my Line of Credit
          </Button>
          <Text className={classes.cancelButton} onClick={onBack}>
            Previous step
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BnplLineOfCredit;
