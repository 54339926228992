import { createFormContext } from '@mantine/form';

interface TermsFormValues {
  creditTermsOfServiceSigned: boolean;
  bankingTermsOfServiceSigned: boolean;
  treasuryTermsOfServiceSigned: boolean;
  termsOfServiceSigned: boolean;
  personalGuarantySigned: boolean;
}

export const [TermsFormProvider, useTermsFormContext, useTermsForm] =
  createFormContext<TermsFormValues>();
