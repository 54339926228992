import { Skeleton, Table } from '@mantine/core';
import { useStyles } from './styles';

interface Props {
  withTabs?: boolean;
  numRows?: number;
}

// TODO: make this dynamic to genereate the num columns and rows to show as being "loaded"
const SkeletonLoading = ({ withTabs }: Props) => {
  const { classes } = useStyles();

  const baseLoader = {
    a: <Skeleton height={8} width={150} radius="xl" />,
    b: <Skeleton height={8} width={330} radius="xl" />,
    c: <Skeleton height={8} width={100} radius="xl" />,
    d: <Skeleton height={8} width={100} radius="xl" />,
    e: <Skeleton height={8} width={180} radius="xl" />,
  };

  const loaders = Array(4)
    .fill(0)
    .map(() => Object.assign({}, baseLoader));

  return (
    <div>
      {withTabs && (
        <div className={classes.tabs}>
          <Skeleton height={8} width={80} radius="xl" mr={50} />
          <Skeleton height={8} width={80} radius="xl" mr={50} />
          <Skeleton height={8} width={110} radius="xl" mr={50} />
          <Skeleton height={8} width={110} radius="xl" mr={50} />
          <Skeleton height={8} width={80} radius="xl" />
        </div>
      )}
      <div className={classes.card}>
        <Skeleton height={8} width={150} radius="xl" mb={24} />
        <Table verticalSpacing="lg">
          <thead>
            <tr>
              <th>
                <Skeleton height={8} width={150} radius="xl" />
              </th>
              <th>
                <Skeleton height={8} width={150} radius="xl" />
              </th>
              <th>
                <Skeleton height={8} width={50} radius="xl" />
              </th>
              <th>
                <Skeleton height={8} width={50} radius="xl" />
              </th>
              <th>
                <Skeleton height={8} width={80} radius="xl" />
              </th>
            </tr>
          </thead>
          <tbody>
            {loaders.map((loader, index) => (
              <tr key={index}>
                <td>{loader.a}</td>
                <td>{loader.b}</td>
                <td>{loader.c}</td>
                <td>{loader.d}</td>
                <td>{loader.e}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Skeleton height={40} width={200} radius="sm" mt={20} />
      </div>
    </div>
  );
};

export default SkeletonLoading;
