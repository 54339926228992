// TODO checkout https://github.com/google/libphonenumber for future use?

export const formatUSPhoneNumber = (value: string) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) {
    return currentValue;
  }
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6,
  )}-${currentValue.slice(6, 10)}`;
};

export const formatPhoneForApi = (phoneInput: string): string => {
  if (!phoneInput) {
    return phoneInput;
  }

  return phoneInput.replaceAll(/\D/g, '');
};
