import { Anchor, Box, Text } from '@mantine/core';
import { useStartScreenStyles } from './styles';
import { FlexIcon } from '../../../../assets/svg';
import Frame from 'assets/images/loginFrame.png';
import { useMediaQuery } from '@mantine/hooks';
import { CreditBankingContentSm } from './credit-banking/credit-banking-content-sm';
import { CreditBankingContentLg } from './credit-banking/credit-banking-content-lg';
import { useNavigate } from 'react-router-dom';
import { FlexbaseFooter } from '../../../../components/footer/flexbase-footer';

export const CreditAndBankingStart = () => {
  const { classes } = useStartScreenStyles();
  const navigate = useNavigate();
  const useSmallContent = useMediaQuery('(max-width: 767px)');
  return (
    <div>
      <Box
        sx={(t) => ({
          minHeight: '100vh',
          backgroundColor: t.fn.primaryColor(),
          width: '100%',
          color: t.fn.themeColor('neutral', 0),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${Frame})`,
        })}
      >
        <div className={classes.headerContainer}>
          <FlexIcon width={112} />
          <Text>
            Already have an account?{' '}
            <Anchor
              style={{ color: '#BDF3D2' }}
              onClick={() => navigate('/login')}
              data-testid="button-login"
            >
              Login.
            </Anchor>
          </Text>
        </div>
        {useSmallContent ? (
          <CreditBankingContentSm />
        ) : (
          <CreditBankingContentLg />
        )}
      </Box>
      <FlexbaseFooter
        footerMarginTop="0"
        disclaimer2={false}
        classNames={{
          footerDiscloure: classes.footerDisclosure,
          footer: classes.footerContainer,
        }}
      />
    </div>
  );
};
