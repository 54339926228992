import { IconType } from 'react-icons';
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { ReactElement, useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import {
  Divider,
  Menu,
  Image,
  Group,
  Button,
  Text,
  Alert,
} from '@mantine/core';

import useModal from 'components/modal/modal-hook';
import { useStyles } from './debit-card-details.styles';
import {
  FreezeIcon,
  PlayIcon,
  CheckmarkIconGreen,
  ListIcon,
  ChevronDownIcon,
  RedAlertIcon,
} from 'assets/svg';
import { flexbaseBankingClient } from 'services/flexbase-client';
import CancelDebitCard from '../cancel-debit-card/cancel-debit-card';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { CardByUser, CardStatus } from 'services/flexbase/banking.model';
import ActivateDebitCard from '../activate-debit-card/activate-debit-card';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { UserInfoState } from 'states/user/user-info';

interface Props {
  card: CardByUser;
  cardStatus: string;
  setError: (x: boolean) => void;
  setCardStatus: (x: string) => void;
  setEditLimits: (x: boolean) => void;
  setDisplayData: (x: boolean) => void;
  updateDebitCardsList: (x: CardByUser) => void;
}

const DebitCardActions = ({
  card,
  setError,
  cardStatus,
  setCardStatus,
  setEditLimits,
  setDisplayData,
  updateDebitCardsList,
}: Props) => {
  const { classes, cx } = useStyles();
  const userId = useRecoilValue(UserIdState);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [activateCard, setActivateCard] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const user = useRecoilValue(UserInfoState);
  const {
    openFullModal,
    closeAllModals,
    openConfirmationModal,
    closeModalById,
  } = useModal();

  const updateCardStatus = async (status: CardStatus) => {
    try {
      setLoading(true);
      const result = await flexbaseBankingClient.cardStatus(card.id, status);
      if (result.success && result.card) {
        setCardStatus(result.card.status);
        updateDebitCardsList(result.card);
      } else {
        setError(true);
      }
      return result;
    } catch (error) {
      console.error('Error freezing the car', error);
    } finally {
      setLoading(false);
    }
  };

  const cancelVirtualCard = async () => {
    openConfirmationModal({
      title: 'Are you sure you want to cancel this card?',
      confirmText: 'Cancel card',
      cancelText: 'Go back',
      onCancel: () => {
        closeModalById('cancel-card');
      },
      onConfirm: async () => {
        const result = await updateCardStatus('cancelled');
        if (result?.success) {
          showNotification({
            message: `The card has been cancelled`,
            color: 'flexbase-teal',
            icon: <CheckmarkIconGreen />,
          });
        } else {
          showNotification({
            title: 'Error',
            message: `Unable to cancel the card`,
            color: 'red',
          });
        }
      },
      showCloseButton: false,
      content: (
        <div>
          <div style={{ fontSize: '14px' }}>This cannot be undone.</div>
          <Divider my={20} />
          <div className={classes.debitCardInfoContent}>
            <Image mr={20} width={100} src="/images/back-debit-card.png" />
            <div>
              <span>{card.holder}</span>
              <p>
                Virtual debit card {getPaddedAccountMask(card.cardNumber, 2)}
              </p>
            </div>
          </div>
          <Divider my={20} />
        </div>
      ),
      id: 'cancel-card',
    });
  };

  let cardAction: {
    style: string;
    actionTxt: string;
    onClick: () => void;
    buttonVariant: string;
    icon: ReactElement<IconType>;
  };
  switch (cardStatus) {
    case 'issued':
      cardAction = {
        style: 'actions',
        actionTxt: 'Activate',
        buttonVariant: 'light',
        onClick: () => {
          if (userId === card.userId) {
            setActivateCard(true);
          } else {
            setMessage('*The cardholder must activate the card');
          }
        },
        icon: <PlayIcon color="#fff" width={15} />,
      };
      break;

    case 'suspended':
      cardAction = {
        style: 'actions',
        buttonVariant: 'filled',
        actionTxt: 'Unfreeze',
        onClick: () => {
          if (user.roles.includes('ADMIN')) {
            updateCardStatus('unfreeze');
          }
        },
        icon: <PlayIcon color="#fff" width={15} />,
      };
      break;

    default:
      cardAction = {
        actionTxt: 'Freeze',
        buttonVariant: 'filled',
        style: 'freeze-action',
        onClick: () => {
          if (user.roles.includes('ADMIN')) {
            updateCardStatus('freeze');
            setDisplayData(false);
          }
        },
        icon: <FreezeIcon width={18} />,
      };
      break;
  }

  setTimeout(() => {
    setMessage('');
  }, 5000);

  useEffect(() => {
    if (activateCard) {
      openFullModal(
        <ActivateDebitCard
          closeModal={closeAllModals}
          {...{ card, updateDebitCardsList }}
        />,
      );
    }
  }, [activateCard]);

  return (
    <div>
      <Group mx={useMobileView ? 20 : 50} position="apart">
        <div>
          <Button
            loading={loading}
            leftIcon={cardAction.icon}
            onClick={cardAction.onClick}
            variant={cardAction.buttonVariant}
          >
            {cardAction.actionTxt}
          </Button>
        </div>
        <div
          className={classes.transactionsAction}
          onClick={() => console.info('click')}
        >
          <ListIcon width={20} />
          <Text>Transactions</Text>
        </div>
        <div className={useMobileView ? classes.alingEnd : undefined}>
          <Menu position="bottom-end">
            <Menu.Target>
              <div className={cx(classes.rowContent, classes.cursor)}>
                <Text size={14} mr={5}>
                  More
                </Text>
                <ChevronDownIcon width={15} />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  if (user.roles.includes('ADMIN')) {
                    setEditLimits(true);
                  }
                }}
              >
                Adjust limits
              </Menu.Item>
              {cardStatus === 'active' && (
                <Menu.Item
                  onClick={() => {
                    if (
                      card.cardType === 'PHYSICAL' &&
                      user.roles.includes('ADMIN')
                    ) {
                      openFullModal(
                        <CancelDebitCard
                          card={card}
                          setCardStatus={setCardStatus}
                          updateDebitCardsList={updateDebitCardsList}
                        />,
                      );
                    } else {
                      cancelVirtualCard();
                    }
                  }}
                >
                  Cancel Card
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      </Group>
      {message !== '' && (
        <Alert
          my={10}
          mx={useMobileView ? 20 : 50}
          icon={<RedAlertIcon />}
          color="red"
        >
          {message}
        </Alert>
      )}
    </div>
  );
};

export default DebitCardActions;
