import { StyleSheet, Font } from '@react-pdf/renderer';
import RedactionRegular from 'assets/fonts/Redaction-Regular.otf';
import PPNeueMontrealBold from 'assets/fonts/PPNeueMontreal-Bold.otf';
import PPNeueMontrealMedium from 'assets/fonts/PPNeueMontreal-Medium.otf';
import PPNeueMontrealRegular from 'assets/fonts/PPNeueMontreal-Regular.otf';

Font.register({
  family: 'Redaction',
  fonts: [
    { src: RedactionRegular }, // font-style: normal, font-weight: normal
  ],
});

Font.register({
  family: 'PP Neue Montreal',
  fonts: [
    { src: PPNeueMontrealMedium }, // font-style: normal, font-weight: normal
    { src: PPNeueMontrealBold, fontStyle: 'bold' },
    { src: PPNeueMontrealRegular, fontStyle: 'thin' },
  ],
});

export const stylesStatementPDF = StyleSheet.create({
  containerHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: '36px',
    color: '#4B4B4B',
    marginTop: 10,
    fontFamily: 'Redaction',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    color: '#4A4947',
    fontFamily: 'PP Neue Montreal',
  },
  cardStatementContainer: {
    borderRadius: 2,
    border: '1px solid #F6F3EE',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    marginTop: 20,
  },
  containerPage: {
    padding: '0px 25px',
  },
  cardTable: {
    borderRadius: 2,
    border: '1px solid #F6F3EE',
    padding: '10px 15px',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  container: {
    borderRadius: 2,
    border: '1px solid #F6F3EE',
    padding: 20,
    marginTop: 20,
  },
  containerData: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  footerContainer: {
    backgroundColor: '#F6F3EE',
    fontSize: '10px',
    color: '#4A4947',
    padding: '15px 20px',
    flexDirection: 'row',
    height: '7vh',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    fontFamily: 'PP Neue Montreal',
  },
});
