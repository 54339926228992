import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { Alert, Divider, Progress, Text } from '@mantine/core';

import Actions from './actions';
import DebitCard from './debit-card';
import { RedAlertIcon } from 'assets/svg';
import BillingAddress from './billing-address';
import UpdateDebitCard from './update-debit-card';
import { useStyles } from './debit-card-details.styles';
import TerminatedDebitCard from './terminated-debit-card';
import { CardByUser } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { DepositAccountsState } from 'areas/banking/states/deposit-accounts';

interface Props {
  closeModal: () => void;
  card: CardByUser;
  setDebitCards: (x: any) => void;
}

const DebitCardDetails = ({ card, setDebitCards }: Props) => {
  const [error, setError] = useState(false);
  const { classes, cx, theme } = useStyles();
  const [editLimits, setEditLimits] = useState(false);
  const [displayData, setDisplayData] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [cardStatus, setCardStatus] = useState(card.status);
  const depositAccounts = useRecoilValue(DepositAccountsState);

  const limitType =
    card?.expensesTypes && Object.keys(card?.expensesTypes).length > 0
      ? card.expensesTypes.dailyPurchase
        ? 'daily'
        : 'monthly'
      : 'unlimited';

  const cardLimit =
    card.expensesTypes?.dailyPurchase ??
    card.expensesTypes?.monthlyPurchase ??
    0;

  const [cardData, setCardData] = useState({
    ...card,
    limit: cardLimit,
    limitType: limitType,
  });

  const availableAmount = cardData.limit - card.monthToDateSpends?.mtdSpend;

  const displayPercent = Math.round(
    (card.monthToDateSpends?.mtdSpend * 100) / cardData.limit,
  );

  const updateDebitCardsList = (card: CardByUser, newCard?: boolean) => {
    if (newCard) {
      setDebitCards((prevState: CardByUser[]) => [card, ...prevState]);
    } else {
      setDebitCards((prevState: CardByUser[]) =>
        prevState.map((currentCard) => {
          if (card.id === currentCard.id) {
            return { ...currentCard, ...card };
          }
          return currentCard;
        }),
      );
    }
  };

  const cardDepositAccount = depositAccounts.find(
    (account) => account.id === card.depositAccountId,
  );

  setTimeout(() => {
    if (error) {
      setError(false);
    }
  }, 5000);

  if (card.status === 'terminated') {
    return (
      <TerminatedDebitCard
        limit={cardLimit}
        {...{ card, limitType, cardStatus }}
      />
    );
  }

  return (
    <div className={classes.cardDetails}>
      <div style={{ opacity: editLimits ? 0.6 : 1 }}>
        <div className={cx(classes.header, classes.rowContent)}>
          <div>
            <Text size={16}>{card.cardName || 'Office Card'}</Text>
            <Text size={14} mt={6}>
              {card.holder}
            </Text>
          </div>
          <div>
            <Text size={20} align="right">
              {formatCurrency(card.monthToDateSpends?.mtdSpend)}
            </Text>
            <Text size={14} mt={6} align="right">
              Spent this month
            </Text>
          </div>
        </div>

        {cardData.limitType !== 'unlimited' && (
          <div className={classes.padding}>
            <Progress
              h={5}
              my={20}
              value={displayPercent}
              color={theme.fn.primaryColor()}
              sx={{
                backgroundColor: '#fff',
              }}
            />

            <div className={classes.rowContent}>
              <Text color="primarySecondarySuccess.8" size={14} fw={500}>
                {formatCurrency(availableAmount)} available
              </Text>
              <Text
                color="primarySecondarySuccess.8"
                size={14}
                fw={500}
                align="right"
              >
                {cardData.limitType !== 'unlimited'
                  ? `${formatCurrency(cardData.limit)} limit`
                  : 'Unlimited'}
              </Text>
            </div>
          </div>
        )}
        <div style={{ margin: '25px 50px' }}>
          <DebitCard {...{ card, cardStatus, displayData, setDisplayData }} />
        </div>
      </div>

      {editLimits ? (
        <>
          <Divider my={20} />
          <UpdateDebitCard
            {...{
              cardData,
              setCardData,
              setEditLimits,
              updateDebitCardsList,
            }}
          />
        </>
      ) : (
        <>
          <Actions
            {...{
              card,
              setError,
              cardStatus,
              setCardStatus,
              setEditLimits,
              setDisplayData,
              updateDebitCardsList,
            }}
          />

          {card.cardType === 'PHYSICAL' && (
            <>
              <Divider my={20} />
              <BillingAddress {...{ card }} />
            </>
          )}

          <Divider my={20} />

          <div className={classes.padding}>
            <div className={classes.rowContent}>
              <Text size={14}>Account</Text>
              <Text size={14}>
                {cardDepositAccount?.nickName ?? cardDepositAccount?.name}
              </Text>
            </div>
            <div className={classes.rowContent}>
              <Text my={5} size={14}>
                Card type
              </Text>
              <Text my={5} size={14}>
                {card.cardSubtype.includes('Virtual') ? 'Virtual' : 'Physical'}
              </Text>
            </div>
            <div className={classes.rowContent}>
              <Text size={14}>POS Limit</Text>
              <Text size={14}>
                {cardData.limitType !== 'unlimited'
                  ? formatCurrency(cardData.limit)
                  : 'Unlimited'}
              </Text>
            </div>
          </div>
        </>
      )}

      {error && (
        <Alert
          color="red"
          mt={20}
          mx={useMobileView ? 20 : 50}
          icon={<RedAlertIcon />}
        >
          Unable to update the card status. Please, try it again.
        </Alert>
      )}
    </div>
  );
};

export default DebitCardDetails;
