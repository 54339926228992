import { Invoice } from 'flexbase-client';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import TransactionDetailRow from './transaction-detail-row';
import { CloseIcon } from 'assets/svg';
import { useStyles } from '../credit-transactions.styles';
import CreditTransactionHeaderSm from '../credit-transactions-header/credit-transaction-header-sm';
import TransactionDetails from '../details/transaction-details';
import useModal from 'components/modal/modal-hook';

type Props = { year: number; transactions: Invoice[]; onClose?: () => void };

const TransactionsByYear = ({ year, transactions, onClose }: Props) => {
  const { classes } = useStyles();
  const [filterText, setFilterText] = useState('');
  const modals = useModal();

  const getByMonth = useCallback(() => {
    console.log(filterText);
    const transactionsForYear = transactions.filter((t) => {
      const transactionDate = DateTime.fromSQL(t.createdAt);
      return (
        transactionDate.year === year &&
        t.storeName.toLowerCase().includes(filterText.toLowerCase())
      );
    });

    const transactionByMonth: { [month: string]: Invoice[] } = {};

    transactionsForYear.forEach((t) => {
      const transactionDate = DateTime.fromSQL(t.createdAt);
      const month = transactionDate.toLocaleString({ month: 'long' });
      if (transactionByMonth[month]) {
        transactionByMonth[month].push(t);
      } else {
        transactionByMonth[month] = [t];
      }
    });

    return Object.entries(transactionByMonth);
  }, [transactions, filterText]);

  const onTransactionClick = (transaction: Invoice) => {
    modals.openFullModal(
      <TransactionDetails
        id={transaction.id}
        createdAt={transaction.createdAt}
        docId={transaction.docId}
        description={transaction.description || ''}
        storeName={transaction.storeName}
        total={transaction.total}
        employee={transaction.cardholderName}
        cardId={transaction.cardId}
        storeId={transaction.storeId}
        storeLogoUrl={(transaction as any).storeLogoUrl}
        closeModal={() => modals.closeAllModals()}
        updateTransaction={() => {
          console.log('f');
        }}
      />,
    );
  };

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: '#FAFAFA',
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '32px',
        }}
      >
        <div className={classes.text} style={{ fontSize: '20px' }}>
          Transactions in {year}
        </div>
        {onClose && (
          <div className={classes.closeIcon} onClick={() => onClose()}>
            <CloseIcon />
          </div>
        )}
      </div>
      <CreditTransactionHeaderSm
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
        onDownloadCSVClick={() => console.log('ok')}
      />
      {getByMonth().map(([month, transactions]) => (
        <div key={month}>
          <div
            className={classes.latestTransactions}
            style={{ marginTop: '32px', marginBottom: '16px' }}
          >
            {month}
          </div>
          {transactions.map((t, i) => (
            <TransactionDetailRow
              transaction={t as any}
              onClick={onTransactionClick}
              key={t.id}
              showBottomBorder={i < transactions.length - 1}
              customStyles={{
                borderTopLeftRadius: i === 0 ? '8px' : '',
                borderTopRightRadius: i === 0 ? '8px' : '',
                borderBottomLeftRadius:
                  i === transactions.length - 1 ? '8px' : '',
                borderBottomRightRadius:
                  i === transactions.length - 1 ? '8px' : '',
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default TransactionsByYear;
