import { Button, Menu, Text, Tooltip, useMantineTheme } from '@mantine/core';
import {
  CustomMantineStyles,
  useStyles,
} from './credit-transactions-header.styles';
import { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { SyncIcon } from 'assets/svg';
import { FilterComp, FilterList } from '@flexbase-eng/web-components';
import { Filter } from 'areas/banking/components/filter/filter-models';
import FilterAdvancedDate from 'areas/banking/banking-transactions/filter/filter-views/filter-advanced-date/filter-advanced-date';

interface Props {
  onFilter: (e: any) => void;
  filterText: string;
  filterList: Filter[];
  onDownloadCSVClick: () => void;
  qbConnected?: boolean;
  onSyncSelection: () => void;
  onSyncUnsynced: () => void;
  onSyncAll: () => void;
  hasSelection: boolean;
  setFilterList: (x: Filter[]) => void;
}

//TODO: remove tooltips after V2
const CreditTransactionsHeader = ({
  onFilter,
  filterText,
  onDownloadCSVClick,
  qbConnected,
  onSyncSelection,
  onSyncUnsynced,
  onSyncAll,
  hasSelection,
  filterList,
  setFilterList,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles({});
  const [menuOpen, setMenuOpen] = useState(false);

  const customFilterList = filterList.filter(
    (filter) => filter.filterColumn !== 'TO_FROM',
  );
  const toFromFilter = filterList.filter(
    (filter) => filter.filterColumn === 'TO_FROM',
  );
  toFromFilter.sort((a, b) => {
    if (a.filterString < b.filterString) return -1;
    if (a.filterString > b.filterString) return 1;
    return 0;
  });
  const startDate = toFromFilter[0];
  const last = toFromFilter[toFromFilter.length - 1];
  const filterDates = {
    filterString: `${startDate?.filterString} - ${last?.filterString}`,
    filterColumn: 'TO_FROM',
  };
  const filterListToDisplay =
    toFromFilter.length > 0 ? [...customFilterList, filterDates] : filterList;

  const renderView = (viewIndex: number) => {
    let view = <></>;
    switch (viewIndex) {
      case 0:
        view = <FilterAdvancedDate selectWidth={150} />;
        break;
    }

    return view;
  };

  // const navbarOptions: NavbarOptions[] = [
  //   {
  //     label: 'Date',
  //     icon: (
  //       <DateIcon
  //         style={{
  //           marginTop: '3px',
  //         }}
  //         width={20}
  //         height={20}
  //       />
  //     ),
  //     viewIndex: 0,
  //   },
  // ];

  return (
    <div className={classes.baseContainer}>
      <div className={classes.headerContainer}>
        <Text className={classes.title}>Transactions</Text>
        <div className={classes.actionsContainer}>
          <FilterComp
            withSearchbar
            renderView={renderView}
            // navbarOptions={navbarOptions}
            filterText={filterText}
            placeholder="Search transaction"
            setFilterText={onFilter}
            classNames={{
              textSearch: classes.textSearch,
              popoverDropdown: classes.popoverDropdown,
            }}
            openFilterIconColor={theme.fn.themeColor(
              'primarySecondarySuccess',
              8,
            )}
          />
          <Menu
            styles={{
              item: CustomMantineStyles().actions.item,
              dropdown: { width: '252px', borderRadius: '8px' },
            }}
            opened={menuOpen}
            onChange={(open) => setMenuOpen(open)}
          >
            <Menu.Target>
              <div
                className={classes.actions}
                style={CustomMantineStyles().actions.root}
              >
                <div>Actions</div>
                {menuOpen ? (
                  <BsChevronUp color="#5F5F5F" size={20} />
                ) : (
                  <BsChevronDown color="#5F5F5F" size={20} />
                )}
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                disabled={!hasSelection || !qbConnected}
                onClick={onSyncSelection}
              >
                <div>Sync selected transactions with Quickbooks</div>
              </Menu.Item>
              <Menu.Item disabled={!qbConnected} onClick={onSyncUnsynced}>
                <div>Sync unsynced transactions with Quickbooks</div>
              </Menu.Item>
              <Menu.Item onClick={onDownloadCSVClick}>Download CSV</Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Tooltip
            label={
              qbConnected
                ? 'Sync ALL transactions to Quickbooks'
                : 'Connect a Quickbooks account to sync'
            }
          >
            <Button
              leftIcon={<SyncIcon fill={'#5F5F5F'} width={19} height={19} />}
              className={classes.sync}
              onClick={onSyncAll}
              disabled={!qbConnected}
            >
              Sync
            </Button>
          </Tooltip>
          {/* <Tooltip label="Quickbooks integration coming soon">
          <Button
            leftIcon={<ReviewIcon fill={'#5F5F5F'} width={20} height={20} />}
            className={classes.review}
            disabled={true}
          >
            Review
          </Button>
        </Tooltip> */}
        </div>
      </div>
      <div className={classes.filterListContainer}>
        <FilterList
          filterList={filterListToDisplay}
          setFilterList={setFilterList}
        />
      </div>
    </div>
  );
};

export default CreditTransactionsHeader;
