import { useState } from 'react';
import { Loader } from '@mantine/core';
import { useStyles } from './summary.styles';
import OnboardingButtonGroup from '../../components/onboarding-button-group';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import { useOnboardingStyles } from '../../onboarding.styles';
import OnboardingTitleGroup from '../../components/onboarding-title-group';
import SummaryNameForm from './edit-forms/summary-name-form';
import SummaryBusinessPurposeForm from './edit-forms/summary-business-purpose-form';
import SummaryBirthdayForm from './edit-forms/summary-birthday-form';
import { SummaryEditBox } from './summary-edit-box';
import AddressEditForm from './edit-forms/address-edit-form';
import SummaryBusinessNameForm from './edit-forms/summary-business-name-form';
import SummaryWebsiteForm from './edit-forms/summary-website-form';
import SummaryCategoryForm from './edit-forms/summary-category-form';
import SummaryEstimatedRevenueForm from './edit-forms/summary-estimated-revenue-form';
import { TermsFormProvider, useTermsForm } from './terms-form-context';
import {
  ConditionalFieldValidator,
  RequiredFieldValidator,
} from '@flexbase-eng/web-components';
import { Termsinator } from './termsinator';
import { useProductOnboarding } from '../../onboarding-hooks';
import { OnboardingUser } from '../../../../states/onboarding/onboarding-info';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { PRODUCT_ONBOARDING_BASE_ROUTE } from '../../onboarding.constants';
import { useNavigate } from 'react-router-dom';
import { UnderlinedTabs } from '../../../../components/tabs/underlined-tabs';

const Summary = () => {
  const { classes, cx } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { user, company, requiredProperties, userIsApplicant } =
    useRecoilValue(ApplicationState);
  const { goBack, refreshProductOnboardingStatus } = useProductOnboarding();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const formOptions = userIsApplicant
    ? ['Personal Information', 'Business Information']
    : ['Personal Information'];
  const [selectedForm, setSelectedForm] = useState<
    'Personal Information' | 'Business Information' | string
  >('Personal Information');
  const onFormChange = (nextSelectedForm: string) => {
    setSelectedForm(nextSelectedForm);
  };

  const termsForm = useTermsForm({
    initialValues: {
      creditTermsOfServiceSigned: user.creditTermsOfServiceSigned,
      bankingTermsOfServiceSigned: user.bankingTermsOfServiceSigned,
      treasuryTermsOfServiceSigned: user.treasuryTermsOfServiceSigned,
      termsOfServiceSigned: user.termsOfServiceSigned,
      personalGuarantySigned: user.personalGuarantySigned,
    },
    validate: {
      creditTermsOfServiceSigned: ConditionalFieldValidator(
        requiredProperties.includes('user.creditTermsOfServiceSigned'),
        RequiredFieldValidator('Credit terms of service must be accepted'),
      ),
      bankingTermsOfServiceSigned: ConditionalFieldValidator(
        requiredProperties.includes('user.bankingTermsOfServiceSigned'),
        RequiredFieldValidator('Banking terms of service must be accepted'),
      ),
      termsOfServiceSigned: ConditionalFieldValidator(
        requiredProperties.includes('user.termsOfServiceSigned'),
        RequiredFieldValidator('Flexbase terms of service must be accepted'),
      ),
      treasuryTermsOfServiceSigned: ConditionalFieldValidator(
        requiredProperties.includes('user.treasuryTermsOfServiceSigned'),
        RequiredFieldValidator('Treasury terms of service must be accepted'),
      ),
      personalGuarantySigned: ConditionalFieldValidator(
        requiredProperties.includes('user.personalGuarantySigned'),
        RequiredFieldValidator('Your personal guaranty must be accepted'),
      ),
    },
  });

  const onSubmitApplication = async () => {
    setError('');
    const validationResult = termsForm.validate();
    if (!validationResult.hasErrors) {
      setLoading(true);
      // TODO: Uncomment this crap and save the terms values from the termsForm.values (only truthy ones)
      // TODO: Save the personal guaranty acceptance (I can't remember how this is done, think it may just be personalGurantySigned on the user obj
      try {
        const request: Partial<OnboardingUser> = { id: user.id };
        Object.entries(termsForm.values).forEach(([key, value]) => {
          if (value) {
            request[key as keyof OnboardingUser] = value;
          }
        });
        await flexbaseOnboardingClient.updateUser(request);
        await refreshProductOnboardingStatus();
        navigate(`${PRODUCT_ONBOARDING_BASE_ROUTE}/end`);
      } catch (e) {
        setError(
          'An error occurred while submitting your application. Please contact support or try again.',
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <OnboardingTitleGroup
        titleText={userIsApplicant ? 'Application Summary' : 'Summary'}
        subtitleText=""
      />
      <UnderlinedTabs
        tabs={formOptions.map((val) => ({ value: val, label: val }))}
        selectedTab={selectedForm}
        onTabClick={(val) => onFormChange(val)}
        classNames={{ tabsContainer: classes.tabsContainer }}
      />

      {selectedForm === 'Personal Information' && (
        <>
          <SummaryNameForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <SummaryBirthdayForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <SummaryEditBox
            label="Email"
            value={user.email}
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
            showEdit={false}
          />
          <AddressEditForm
            entity="user"
            classNames={{
              box: cx(
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
        </>
      )}

      {selectedForm === 'Business Information' && (
        <>
          <SummaryBusinessNameForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          {company.taxId && (
            <SummaryEditBox
              label="EIN"
              value={company.taxId}
              classNames={{
                box: cx(
                  onboardingClasses.bottomSpacing,
                  onboardingClasses.bankAccountCard,
                  onboardingClasses.summaryCard,
                ),
              }}
              showEdit={false}
            />
          )}
          <SummaryBusinessPurposeForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <AddressEditForm
            entity="company"
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          {company.website && (
            <SummaryWebsiteForm
              classNames={{
                box: cx(
                  onboardingClasses.bottomSpacing,
                  onboardingClasses.bankAccountCard,
                  onboardingClasses.summaryCard,
                ),
              }}
            />
          )}
          <SummaryCategoryForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
          <SummaryEstimatedRevenueForm
            classNames={{
              box: cx(
                onboardingClasses.bottomSpacing,
                onboardingClasses.bankAccountCard,
                onboardingClasses.summaryCard,
              ),
            }}
          />
        </>
      )}
      <div
        className={cx(classes.termsContainer, onboardingClasses.bottomSpacing)}
      >
        <TermsFormProvider form={termsForm}>
          <Termsinator
            requiredProperties={requiredProperties}
            showPreview={userIsApplicant}
          />
        </TermsFormProvider>
      </div>
      {error && (
        <div
          className={cx(
            onboardingClasses.stepText,
            onboardingClasses.stepErrorText,
          )}
        >
          {error}
        </div>
      )}
      <OnboardingButtonGroup
        classNames={{
          buttonGroup: classes.buttonContainer,
          nextButton: classes.summaryContinueButton,
        }}
        nextButtonLabel={
          loading ? (
            <Loader size="sm" color="white" />
          ) : (
            `Submit${userIsApplicant ? ' Application' : ''}`
          )
        }
        disableAll={loading}
        onBackClick={() => goBack()}
        onNextClick={() => onSubmitApplication()}
      />
    </div>
  );
};

export default Summary;
