import { atom } from 'recoil';

export type NotificationChannels = 'sms' | 'email' | 'push';

export interface UserPreferences {
  notifications: {
    [key: string]: {
      default: NotificationChannels[];
      [key: string]: NotificationChannels[];
    };
  };
  settings: {
    [key: string]: number;
  };
}

export const UserPreferenceState = atom<UserPreferences>({
  key: 'user-preferences',
  default: {
    notifications: {},
    settings: {},
  },
});
