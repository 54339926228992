import { Svg, Path } from '@react-pdf/renderer';

export const SupportSvg = () => (
  <Svg style={{ width: 16, height: 16 }} viewBox="0 0 16 16">
    <Path
      d="M7.27656 0C3.77565 0 0.918844 2.8362 0.879687 6.32812L0 16H7.27656H9.67656V14.4H7.27656C4.61611 14.4 2.47656 12.2604 2.47656 9.6V8.8C2.47656 7.90667 3.18323 7.2 4.07656 7.2H10.4766C11.3699 7.2 12.0766 7.90667 12.0766 8.8V11.2H8.80625C8.72363 10.9663 8.57067 10.7639 8.36839 10.6207C8.16611 10.4774 7.92443 10.4003 7.67656 10.4C7.3583 10.4 7.05308 10.5264 6.82803 10.7515C6.60299 10.9765 6.47656 11.2817 6.47656 11.6C6.47697 11.9031 6.59206 12.1948 6.79872 12.4166C7.00538 12.6383 7.2883 12.7736 7.59063 12.7953C7.61917 12.7984 7.64785 12.8 7.67656 12.8H12.8766H13.6766C14.5566 12.8 15.2766 12.08 15.2766 11.2V9.6C15.2766 8.72 14.5566 8 13.6766 8V6.4C13.6766 2.87485 10.8017 0 7.27656 0ZM7.27656 1.6C9.81454 1.6 11.8732 3.54854 12.0578 6.0375C11.5888 5.76673 11.0534 5.6 10.4766 5.6H4.07656C3.49973 5.6 2.96435 5.76673 2.49531 6.0375C2.67996 3.54854 4.73858 1.6 7.27656 1.6Z"
      fill="#071C14"
    />
  </Svg>
);

export const EmailSvg = () => (
  <Svg style={{ width: 16, height: 16 }} viewBox="0 0 18 16">
    <Path
      d="M1.51229 0C0.685775 0 0 0.691 0 1.52381V11.4286H1.51229V1.52381H13.6106V0H1.51229ZM4.53686 3.04762C3.70284 3.04762 3.02457 3.73105 3.02457 4.57143V12.1905C3.02457 13.0309 3.70284 13.7143 4.53686 13.7143H9.82987V12.1905H4.53686V6.57292L9.82987 9.90476L15.1229 6.57292V8.5119L15.436 8.82738L16.6352 10.0357V4.57143C16.6352 3.73105 15.9569 3.04762 15.1229 3.04762H4.53686ZM4.53686 4.57143H15.1229V5.04911L9.82987 8.38095L4.53686 5.04911V4.57143ZM14.3667 9.90476V12.1905H11.3422V13.7143H14.3667V16L17.3913 12.9524L14.3667 9.90476Z"
      fill="#071C14"
    />
  </Svg>
);

export const LeftArrow = () => (
  <Svg style={{ width: 8, height: 8 }} viewBox="0 0 8 8">
    <Path
      d="M4.93625 2.42592L3.26492 4.09725L4.93625 5.76858L4.41919 6.282L2.23445 4.09725L4.41919 1.91251L4.93625 2.42592Z"
      fill="#1F212A"
    />
  </Svg>
);

export const RightArrow = () => (
  <Svg style={{ width: 8, height: 8 }} viewBox="0 0 8 8">
    <Path
      d="M2.41321 5.76858L4.08454 4.09725L2.41321 2.42592L2.93026 1.91251L5.11501 4.09725L2.93026 6.282L2.41321 5.76858Z"
      fill="#1F212A"
    />
  </Svg>
);
