import { createStyles } from '@mantine/core';

export const useChangePasswordStyles = createStyles(() => ({
  buttonHolder: {
    display: 'flex',
    position: 'relative',
    marginTop: '5%',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },

  input: {
    width: '45px',
    height: '45px',
    padding: 0,
    fontSize: '24px',
    textAlign: 'center',
    marginTop: '25px',
    textTransform: 'uppercase',
    border: '1px solid #d6d6d6',
    borderRadius: '4px',
    background: '#fff',
    backgroundClip: 'padding-box',
  },

  inputContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  message: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.25rem',
  },

  userData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.25rem',
  },

  secondaryAction: {
    border: 0,
    width: '100%',
    color: '#adb0ca',
    fontSize: '12px',
    cursor: 'pointer',
    marginTop: '12px',
    backgroundColor: 'unset',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'transparent',
    },
  },

  inputStyle: {
    '.mantine-NumberInput-input': {
      padding: '20px',
      borderRadius: '8px',
    },
  },
}));
