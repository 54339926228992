import { useRecoilState, useRecoilValue } from 'recoil';
import { BiEditAlt } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import {
  Alert,
  Button,
  Divider,
  Loader,
  Progress,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';

import EditCard from './edit-card';
import CreditCard from './credit-card';
import { detailsCardStyles } from './styles';
import CreditCardActions from './card-actions';

import '../../lithic-frame.css';
import {
  CardHiddenInfo,
  EmbedUrlHiddenInfo,
} from 'flexbase-client/dist/models/Card/Card';
import flexbaseClient from 'services/flexbase-client';
import { FormCardState } from 'states/cards/card-state';
import { Card } from 'states/cards/card-info';
import { formatCurrency } from '@flexbase-eng/web-components';
import {
  CompanySelector,
  IsAdmin,
} from '../../../../states/application/product-onboarding';

interface ICardDetails {
  card: Card;
  updateCompanyCards: (card: Card) => void;
}

const CardDetails = ({ card, updateCompanyCards }: ICardDetails) => {
  const theme = useMantineTheme();
  const isAdmin = useRecoilValue(IsAdmin);
  const { classes } = detailsCardStyles();

  const companyInfo = useRecoilValue(CompanySelector);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [cardStatus, setCardStatus] = useState(card?.status);
  const [cardInfo, setCardInfo] = useRecoilState(FormCardState);
  const [generalData, setGeneralData] = useState({
    limitAmount: 0,
    amountSpentThisMonth: 0,
    availableAmount: 0,
    utilization: 0,
  });
  const [cardData, setCardData] = useState<CardHiddenInfo>({
    cardNumber: '',
    cvc: '',
    expirationDate: '',
    success: false,
  });

  const [embedUrlData, setEmbedUrlData] = useState<EmbedUrlHiddenInfo>({
    embedUrl: '',
    last4: '',
    success: false,
  });

  const updateGeneralData = (
    newPerUtilization: number,
    newLimitAmount: number,
  ) => {
    if (!newPerUtilization && !newLimitAmount) {
      return generalData;
    }
    setGeneralData({
      ...generalData,
      utilization: newPerUtilization,
      limitAmount: newLimitAmount,
      availableAmount:
        newLimitAmount - (card?.monthToDateSpends?.mtdSpend || 0),
    });
  };

  function isCardHiddenInfo(
    value: CardHiddenInfo | EmbedUrlHiddenInfo,
  ): value is CardHiddenInfo {
    return 'cardNumber' in value;
  }

  const getCardHiddenInfo = async (cardId: string) => {
    try {
      const response = await flexbaseClient.getCardHiddenInfo(cardId);
      if (isCardHiddenInfo(response)) {
        setCardData(response as CardHiddenInfo);
      } else {
        setEmbedUrlData(response as EmbedUrlHiddenInfo);
      }
    } catch (error) {
      console.error('Unable to obtain hidden card information', error);
    }
  };

  const cancelCard = async () => {
    try {
      setIsLoadingStatus(true);
      const response = await flexbaseClient.updateCardStatus(
        card.id,
        'terminated',
      );
      if (!response || !response.card) {
        throw Error(
          JSON.stringify({
            errorMessage: 'Unable to cancel your card, please contact support',
          }),
        );
      }
      const utilization = response?.card?.expensesTypes?.amount
        ? Math.round(
            (generalData.amountSpentThisMonth /
              response?.card.expensesTypes.amount) *
              100,
          )
        : 0;
      setCardStatus(response.card.status);
      updateCompanyCards({
        ...response.card,
        monthToDateSpends: { mtdSpend: generalData.amountSpentThisMonth },
        utilization,
      });
      setShowCancelForm(false);
    } catch (error) {
      console.error('ERROR TERMINATE THE CARD', error);
      setErrorMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoadingStatus(false);
    }
  };
  const ErrorMessage = () => (
    <div>
      {errorMessage !== '' && (
        <div style={{ marginTop: '1.5rem' }}>
          <Alert
            icon={<FaExclamationCircle color="red" />}
            radius={8}
            color="red"
          >
            {errorMessage}
          </Alert>
        </div>
      )}
    </div>
  );

  const ConfirmCancelCardModal = () => (
    <div>
      {' '}
      <Text size="sm" color={theme.colors.blackish[0]}>
        Are you sure you want to cancel this card?
      </Text>
      <div className="buttons-container">
        <Button
          radius="md"
          color="gray"
          variant="outline"
          onClick={() => {
            setShowCancelForm(false);
          }}
        >
          Close
        </Button>
        <Button radius="md" loading={isLoadingStatus} onClick={cancelCard}>
          Cancel Card
        </Button>
      </div>
      {ErrorMessage()}
    </div>
  );

  useEffect(() => {
    if (card?.id) {
      getCardHiddenInfo(card.id);
      setCardInfo({
        cardType: card.cardType,
        cardName: card.cardName,
        creditLimit: card.creditLimit,
        expensesTypes: {
          amount: card.expensesTypes?.amount,
          groups: card.expensesTypes?.groups,
          interval: card.expensesTypes?.interval,
        },
        notifyUse: card.notifyUse,
        userId: card.userId,
      });
      setGeneralData({
        ...generalData,
        limitAmount: card?.expensesTypes?.amount || 0,
        amountSpentThisMonth: card?.monthToDateSpends?.mtdSpend || 0,
        availableAmount:
          (card?.expensesTypes?.amount || 0) -
          (card?.monthToDateSpends?.mtdSpend || 0),
        utilization: card?.utilization || 0,
      });
    }
  }, []);

  useEffect(() => {
    if (errorMessage !== '')
      setTimeout(() => {
        setErrorMessage('');
      }, 10000);
  }, [errorMessage]);

  return (
    <div className={classes.cardDetails}>
      <div style={{ opacity: showOptions || showCancelForm ? 0.6 : 1 }}>
        <div className="row-content">
          <span>{card.cardName || 'Credit Card'}</span>
          <span>{formatCurrency(generalData.amountSpentThisMonth)}</span>
        </div>
        <div className="end-content">
          <p style={{ fontSize: '12px' }}>Spent this month</p>
        </div>
        <Progress
          size="sm"
          color={theme.fn.primaryColor()}
          style={{ margin: '15px 0px' }}
          value={generalData.utilization || 0}
        />
        <div className="row-content" style={{ justifyContent: 'flex-end' }}>
          {isAdmin && cardStatus !== 'terminated' ? (
            <UnstyledButton onClick={() => setShowOptions(true)}>
              <BiEditAlt color="#5F5F5F" />
              {cardInfo.expensesTypes?.amount !== 0
                ? `${formatCurrency(generalData.limitAmount)} limit`
                : 'Unlimited'}
            </UnstyledButton>
          ) : (
            <p>
              {cardInfo.expensesTypes?.amount !== 0
                ? `${formatCurrency(generalData.limitAmount)} limit`
                : 'Unlimited'}
            </p>
          )}
        </div>
        {card.liCardToken && !cardData.cardNumber ? (
          <>
            {embedUrlData.embedUrl === '' ? (
              <div>
                <Loader size={'xs'} color="red" />
              </div>
            ) : (
              <iframe
                style={{ marginTop: '1vh' }}
                id="iframe"
                src={embedUrlData.embedUrl || ''}
                allow="clipboard-write"
              ></iframe>
            )}
          </>
        ) : (
          <CreditCard {...{ card, cardData, cardStatus }} />
        )}
      </div>

      {showOptions ? (
        <EditCard
          {...{
            setCardInfo,
            card: cardInfo,
            setShowOptions,
            cardId: card.id,
            updateCompanyCards,
            amountSpentThisMonth: generalData.amountSpentThisMonth,
            updateGeneralData,
          }}
        />
      ) : showCancelForm ? (
        <ConfirmCancelCardModal />
      ) : (
        <>
          <CreditCardActions
            {...{
              card,
              cardStatus,
              setCardStatus,
              setShowOptions,
              setErrorMessage,
              setShowCancelForm,
              updateCompanyCards,
              amountSpentThisMonth: generalData.amountSpentThisMonth,
              isLoadingStatus,
              setIsLoadingStatus,
            }}
          />

          <Divider style={{ margin: '20px 0px' }} />

          <div>
            <p style={{ fontSize: '12px', marginBottom: '5px' }}>
              Billing address
            </p>
            <p>{companyInfo.address.line1}</p>
            <p>{companyInfo?.address.line2}</p>
            <p>
              {companyInfo.address.city}, {companyInfo.address.state}{' '}
              {companyInfo.address.postalCode}
            </p>
          </div>

          <Divider style={{ margin: '20px 0px' }} />

          <div className="row-content">
            <span>Card type</span>
            <p>
              {card.cardType.toLowerCase() === 'virtual'
                ? 'Virtual'
                : 'Physical'}
            </p>
          </div>
          {ErrorMessage()}
        </>
      )}
    </div>
  );
};

export default CardDetails;
