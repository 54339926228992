import { onChangeFilters } from '@flexbase-eng/web-components';
import Select from 'components/select/flexbase-select';
import { DatePicker, DatesRangeValue } from '@mantine/dates';
import { useRecoilState } from 'recoil';
import {
  FilterListState,
  TIME_FRAME,
  TimeFrameState,
} from '../../../../components/filter/filter-states';
import { DateTime } from 'luxon';

interface Props {
  selectWidth?: number;
}

const FilterAdvancedDate = ({ selectWidth }: Props) => {
  const [timePeriod, setTimePeriod] = useRecoilState(TimeFrameState);
  const [filterList, setFilterList] = useRecoilState(FilterListState);
  const filterStringList = filterList.map((filter) => {
    return filter.filterString;
  });

  const filterDataByDateRange = (dates: DatesRangeValue | []) => {
    const [startDate, endDate] = dates;
    const datesInRange = [];
    if (startDate && endDate) {
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      for (let i = startDay; i <= endDay; i++) {
        const newDate = new Date(startDate);
        newDate.setDate(i);
        datesInRange.push(new Date(newDate));
      }
    }

    const formattedDates = datesInRange.map((date) => {
      const newDate = new Date(date);
      const isoString = newDate.toISOString();
      const formattedString = isoString.replace('T', ' ').slice(0, -5) + '+00';
      return DateTime.fromSQL(formattedString).toFormat('LLL dd, yyyy');
    });

    const newFilterList = filterList
      .filter((filter) => filter.filterColumn !== 'TO_FROM')
      .map((filter) => {
        return filter.filterString;
      });

    onChangeFilters({
      filterList: filterList,
      setFilterList: setFilterList,
      values: [...newFilterList, ...formattedDates],
      filterStringList: filterStringList,
      filterType: 'TO_FROM',
    });
  };

  const filterDataLastWeek = () => {
    const today = new Date();
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7,
    ); // get the date from a week ago
    const endDate = today;
    filterDataByDateRange([startDate, endDate]);
  };

  const filterDataThisMonth = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const endDate = today;
    filterDataByDateRange([startDate, endDate]);
  };

  const filterDataLastMonth = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    filterDataByDateRange([startDate, endDate]);
  };

  const toFromValues = filterList
    .filter((filter) => filter.filterColumn === 'TO_FROM')
    .map((filter) => filter.filterString);

  const selectTimePeriod = (value: string) => {
    setTimePeriod(value);

    switch (value) {
      case 'allTime':
        {
          onChangeFilters({
            filterList: filterList,
            setFilterList: setFilterList,
            values: filterStringList.filter(
              (filter) => !toFromValues.includes(filter),
            ),
            filterStringList: filterStringList,
            filterType: 'TO_FROM',
          });
        }
        break;
      case 'custom':
        {
          onChangeFilters({
            filterList: filterList,
            setFilterList: setFilterList,
            values: filterStringList,
            filterStringList: filterStringList,
            filterType: 'TO_FROM',
          });
        }
        break;
      case 'lastWeek':
        filterDataLastWeek();
        break;
      case 'thisMonth':
        filterDataThisMonth();
        break;
      case 'lastMonth':
        filterDataLastMonth();
        break;
    }
  };

  return (
    <div>
      <Select
        style={selectWidth ? { width: selectWidth } : {}}
        data={TIME_FRAME}
        value={timePeriod}
        label={'Show transactions for'}
        onChange={(value) => selectTimePeriod(value!)}
      />
      {timePeriod === 'custom' && (
        <DatePicker
          mt={12}
          type="range"
          onChange={(dates) => filterDataByDateRange(dates)}
        />
      )}
    </div>
  );
};

export default FilterAdvancedDate;
