import QuickbooksGreen from 'assets/images/quickbooks.png';
import Xero from 'assets/images/xero.png';
import OracleNetsuite from 'assets/images/oracle-netsuite.png';
import PayPal from 'assets/images/paypal.png';
import Sage from 'assets/images/sage.png';
import Stripe from 'assets/images/stripe.png';
import GoogleWorspace from 'assets/images/google-workspace.png';
import QbooksActionButton from './qbooks-action-button';

interface CardIntegration {
  cardName: string;
  content: string;
  icon: string;
  active: boolean;
  actionButton?: (connected: boolean, loading: boolean) => JSX.Element;
}

export const cardsIntegrations: CardIntegration[] = [
  {
    cardName: 'Quickbooks',
    active: true,
    content:
      'Track income and expenses, manage payroll, invoicing, taxes, and inventory',
    icon: QuickbooksGreen,
    actionButton: (connected: boolean, loading: boolean) => (
      <QbooksActionButton connected={connected} loading={loading} />
    ),
  },
  {
    cardName: 'Xero',
    active: false,
    content:
      'Integrates with your bank account to manage expenses, bills, payments, projects and payroll',
    icon: Xero,
  },
  {
    cardName: 'Oracle NetSuite',
    active: false,
    content:
      'Manage inventory, track financials, host e-commerce stores, and manage customer relationships',
    icon: OracleNetsuite,
  },
  {
    cardName: 'Sage 300',
    active: false,
    content: 'Accounting, billing and time tracking, HR and project management',
    icon: Sage,
  },
  {
    cardName: 'Google Workspace',
    active: false,
    content:
      'Manage contacts, emails, calendars, video calls, and cloud storage for your organization',
    icon: GoogleWorspace,
  },
  {
    cardName: 'Stripe',
    active: false,
    content: 'Manage payments, invoices, risk/fraud, tax and accounting',
    icon: Stripe,
  },
  {
    cardName: 'PayPal',
    active: false,
    content:
      'Manage your digital wallet, send and receive money globally with alternative payment methods',
    icon: PayPal,
  },
];
