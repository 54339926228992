export const formatCurrency = (value: string | number, format = 'en-US') => {
  if (value === '' || value === undefined) {
    return value;
  }

  const stringValue = String(value);

  if (!stringValue.match(/\d/g)) {
    return '';
  }

  const removeComma = stringValue.replace(/,/g, '');
  const hasDecimal = removeComma.match(/\./);
  const [int, decimals] = removeComma.split('.');

  const formattedInt = new Intl.NumberFormat(format, {
    currency: 'USD',
    style: 'currency',
  }).format(Number(int) || Number(removeComma));

  if (!int || !hasDecimal) {
    return formattedInt;
  }
  return decimals
    ? new Intl.NumberFormat(format, {
        currency: 'USD',
        style: 'currency',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(Number(`${int || removeComma}.${decimals}`))
    : `${formattedInt}.`;
};

export const formatSpendValue = (value: number) => {
  let spendLimitDisplayPercent;
  if (value < 0) {
    spendLimitDisplayPercent = 0;
  } else if (value > 100) {
    spendLimitDisplayPercent = 100;
  } else {
    spendLimitDisplayPercent = value;
  }

  return spendLimitDisplayPercent;
};

export const round = (num: string) =>
  Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;

export const formatNumberInput = (value: string) =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatValue = (value: number | string) => {
  const abbreviations = ['', 'K', 'M', 'B', 'T'];
  let num = Number(value);
  let index = 0;
  while (num >= 1000 && index < abbreviations.length - 1) {
    num /= 1000;
    index++;
  }
  return num.toFixed(2) + abbreviations[index];
};

export const formatCurrencyInput = (input: string) => {
  if (input.length === 0) {
    return '';
  } else {
    const sanitizedInput = input.replace(/[^0-9.]/g, '');

    if (sanitizedInput.length === 0) {
      return '';
    }

    const amount = parseFloat(sanitizedInput);

    if (!isNaN(amount)) {
      return amount > 5000000
        ? '5,000,000'
        : amount.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    }
  }
};
