import InviteUser from '../content/invite-user';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';

type Props = { closeModal: () => void; refreshTeamMembers?: () => void };

const InviteUserModal = ({ closeModal, refreshTeamMembers }: Props) => {
  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <InviteUser
        closeModal={closeModal}
        refreshTeamMembers={refreshTeamMembers}
      />
    </FullScreenModalContainer>
  );
};

export default InviteUserModal;
