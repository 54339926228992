import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  modalSuccess: {
    display: 'grid',
    marginTop: '30px',
    maxWidth: '500px',
    justifyItems: 'center',
    p: {
      color: '#5F5F5F',
      textAlign: 'center',
      marginBottom: '40px',
    },
    '> span': {
      color: '#000',
      fontSize: '28px',
      marginTop: '40px',
      lineHeight: '34px',
      textAlign: 'center',
    },
    '.check_icon': {
      width: '72px',
      height: '72px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50px',
      justifyContent: 'center',
    },
    '.back_button': {
      marginTop: 33,
      width: '263px',
      height: '40px',
    },
    '.new_item': {
      display: 'flex',
      fontSize: '12px',
      color: '#5F5F5F',
      marginTop: '24px',
      alignItems: 'center',
      svg: {
        marginLeft: '12px',
      },
    },
  },
}));
