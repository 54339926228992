import { atom } from 'recoil';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';

export interface PublicTokenExchangeResponse {
  accessToken: string;
  accountId: string;
  itemId: string;
  userId: string;
  unitProcessorToken: string;
}

export interface OnSuccessProps {
  metadata: PlaidLinkOnSuccessMetadata;
  keys: PublicTokenExchangeResponse;
}

export interface PlaidBankingComponentProps {
  onSuccess(data: OnSuccessProps): void;
  onError(): void;
  setLoading(loading: boolean): void;
  receivedRedirectUri?: string;
}

export interface PlaidDetails {
  oauth: boolean;
  callbackUri: string;
}

export const PlaidState = atom<PlaidDetails>({
  key: 'plaid-state',
  default: { oauth: false, callbackUri: '' },
});
