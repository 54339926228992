import { UnstyledButton, useMantineTheme } from '@mantine/core';
import { ArrowLineRightIcon } from 'assets/svg';

interface Props {
  onClick: () => void;
}

const ActivateDebitCardError = ({ onClick }: Props) => {
  const theme = useMantineTheme();
  return (
    <div className="error-step">
      <span>Something went wrong</span>
      <p>An unexpected error occurred. Please, try it again later</p>
      <UnstyledButton onClick={onClick}>
        Back to debit cards{' '}
        <ArrowLineRightIcon color={theme.fn.primaryColor()} />
      </UnstyledButton>
    </div>
  );
};

export default ActivateDebitCardError;
