import { Component, ErrorInfo, ReactNode } from 'react';
import { Analytics } from 'services/analytics/analytics';
import ErrorEndScreen from '../pages/end-states/error-end-screen';
import * as Sentry from '@sentry/react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

export const OnboardingError = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <ErrorEndScreen />
    </div>
  );
};

class OnboardingErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
    console.error(
      'Uncaught error in onboarding-error-boundary.component.tsx',
      error,
      errorInfo,
    );
  }

  public render() {
    if (this.state.hasError) {
      Analytics.track('Application Error Occurred', {
        error: this.state.errorMessage || 'Unknown error',
      });
      return <OnboardingError />;
    }

    return this.props.children;
  }
}

export default OnboardingErrorBoundary;
