import { Transaction } from 'services/flexbase/banking.model';

export const formatInitials = (name: string): string => {
  const initials =
    name
      ?.split(' ')
      ?.reduce(
        (prev: string, curr: string) => prev + curr[0]?.toUpperCase(),
        '',
      ) || '';

  if (initials.length > 2) {
    return `${initials.charAt(0)}${initials.charAt(initials.length - 1)}`;
  }

  return initials;
};

export const takeFirstTwo = (
  name: string,
  transaction: Transaction,
): string => {
  if (!name) {
    console.log('this is an issue');
  }
  if (name.length >= 2) {
    return name.slice(0, 2);
  }
  return name;
};

export const capitalizeOnlyFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};
