class AuthenticatedStorage {
  private _innerStorage: Storage = localStorage; // default to localStorage so it will local auth if they checked rememberMe

  set useSessionStorage(use: boolean) {
    this._innerStorage = use ? sessionStorage : localStorage;
  }

  clear = (): void => this._innerStorage.clear();

  clearAll = (): void => {
    localStorage?.clear();
    sessionStorage.clear();
  };

  getItem = (key: string): string | null => this._innerStorage.getItem(key);

  key = (index: number): string | null => this._innerStorage.key(index);

  removeItem = (key: string): void => this._innerStorage.removeItem(key);

  setItem = (key: string, value: string): void =>
    this._innerStorage.setItem(key, value);
}

const authenticatedStorage = new AuthenticatedStorage();

export default authenticatedStorage;
