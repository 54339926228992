import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { PendingUrlState } from 'states/pending-url/pending-url-state';

const AuthorizedRoutes = () => {
  const location = useLocation();
  const setPendingUrl = useSetRecoilState(PendingUrlState);

  useEffect(() => {
    if (location.pathname.includes('/credit/pay')) {
      // This is a hack to always make sure the current BNPL url is set.
      setPendingUrl(`${location.pathname}${location.search}`);
    } else {
      setPendingUrl((prevValue) => {
        return prevValue || `${location.pathname}${location.search}`;
      });
    }
  }, []);

  return <Outlet />;
};

export default AuthorizedRoutes;
