// Note: This component may be able to be used for banking, if we ever get around to launching it.
import { useStartScreenStyles } from '../styles';
import { useForm } from '@mantine/form';
import { EmailValidator } from '../../../../../utilities/validators/validate-email';
import { NewPasswordValidator } from '../../../../../utilities/validators/validate-password';
import { selector, useRecoilValue } from 'recoil';
import {
  mapProductToProductsArray,
  ProductState,
} from '../../../../../states/application/product-onboarding';
import { BnplState } from '../../../../../states/bnpl/bnpl.state';
import {
  Alert,
  Button,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import PasswordStrengthPopover from '../../../../login/password-strength-popover';
import { TermsOfServiceCheckboxPrompt } from '../../../../../components/terms/prompt';
import { useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import authenticatedStorage from '../../../../../states/auth/authenticated-storage';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from '../../../../../services/flexbase-client';
import { PRODUCT_ONBOARDING_BASE_ROUTE } from '../../../onboarding.constants';
import { Analytics } from '../../../../../services/analytics/analytics';
import { formatCurrency } from '@flexbase-eng/web-components';
import { RequiredFieldValidator } from 'utilities/validators/validate-required';
import TagManager from 'react-gtm-module';
import { useAuthToken } from '../../../../../states/auth/auth-token';
import { useRouteSectionContext } from '../../../../../components/routes/route-context';

type RegisterForm = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  flexbaseTos: boolean;
};

// Fetch and cache the latest credit Application and Solicitation Disclosure
const applicationDisclosureQuery = selector({
  key: 'application_disclosure',
  get: async () => {
    try {
      const documents = await flexbaseOnboardingClient.getTermsOfServiceDocs(
        'credit',
        false,
      );
      return documents.find(
        ({ label }) => label === 'Application and Solicitation Disclosure',
      );
    } catch (e) {
      console.error('terms', e);
    }
  },
});

/**
 * Currently used for all registration flows except credit.
 * Ideally, we should have different ones for each product.
 * @constructor
 */
const MiscAppStartForm = () => {
  const theme = useMantineTheme();
  const { classes } = useStartScreenStyles();
  const registerForm = useForm<RegisterForm>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      flexbaseTos: false,
    },
    validate: {
      email: EmailValidator(),
      password: NewPasswordValidator(),
      confirmPassword: (val, values) =>
        val === values?.password ? null : 'Passwords do not match',
      flexbaseTos: RequiredFieldValidator(
        'You must accept the terms and conditions',
      ),
    },
  });
  const selectedProduct = useRecoilValue(ProductState);
  const { isBnpl, amount, merchantName } = useRecoilValue(BnplState);
  const [error, setError] = useState('');
  const [accountExistsError, setAccountExistsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const applicationDisclosure = useRecoilValue(applicationDisclosureQuery);
  const navigate = useNavigate();
  const { setUserData } = useAuthToken();
  const { setShowRoutesFor } = useRouteSectionContext();

  const register = async (values: RegisterForm) => {
    setError('');
    setLoading(true);
    authenticatedStorage.clear();
    authenticatedStorage.useSessionStorage = false;
    try {
      const response = await flexbaseClient.register(
        {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          options: {
            rememberUsername: false,
          },
        },
        localStorage?.getItem('variant') ?? undefined,
      );
      setUserData({
        email: values.email,
        remember: true,
        token: response.token.token,
      });
      const segmentAnon = localStorage?.getItem('segment_anon');
      if (segmentAnon) {
        Analytics.alias(segmentAnon, response.user?.userId).then(() => {
          Analytics.submitProductStart.set(
            response.user!.userId,
            selectedProduct,
          );
        });
      } else {
        Analytics.submitProductStart.set(
          response.user!.userId,
          selectedProduct,
        );
      }
      await flexbaseOnboardingClient.updateUser({
        termsOfServiceSigned: values.flexbaseTos,
      });
      await flexbaseOnboardingClient.createCompany({
        companyName: 'My Company',
        optedProducts: mapProductToProductsArray(selectedProduct),
      });
      setShowRoutesFor('application');
      //TODO: capture all `misc` products that fall under this start form
      navigate(PRODUCT_ONBOARDING_BASE_ROUTE);
    } catch (error) {
      console.log('error!', error);
      setLoading(false);

      Analytics.track('Application Error Registering New User', {
        email: values.email,
      });

      if (error.message && (error.message as string).includes('same email')) {
        setError('Account already exists, please login');
        setAccountExistsError(true);
      } else {
        setError('Unable to register your account.');
      }
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = () => {
    const validationResult = registerForm.validate();

    if (!validationResult.hasErrors) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'dgd_miscFormSubmit',
        },
      });
      register(registerForm.values);
    } else if (validationResult.errors.acceptTerms) {
      setError('You must accept all terms.');
    }
  };

  return (
    <>
      <Text className={classes.title}>
        {selectedProduct === 'TREASURY'
          ? 'Treasury Management \nfor SMBs'
          : isBnpl
          ? 'Get started with Flexbase Pay'
          : `Flex Business Banking`}
      </Text>
      <div style={{ width: 450 }}>
        <Text className={classes.subtitle}>
          {selectedProduct === 'TREASURY'
            ? "Secure your company's assets today"
            : isBnpl
            ? `Complete a quick application to use Flexbase Pay for your ${formatCurrency(
                amount,
              )} purchase with ${merchantName}`
            : `Zero fees business banking.`}
        </Text>
      </div>
      <div className={classes.greenContainer}>
        <Text className={classes.greenContText}>Done in just minutes!</Text>
      </div>
      <Stack style={{ marginTop: 20 }}>
        <div>
          <TextInput
            label="Legal first name of business owner"
            id="input-first-name"
            data-testid="input-first-name"
            placeholder="e.g. Sam"
            size="lg"
            required
            classNames={{
              root: classes.nameInputs,
              input: classes.input,
              label: classes.label,
            }}
            {...registerForm.getInputProps('firstName')}
          />
        </div>
        <div>
          <TextInput
            id="input-last-name"
            data-testid="input-last-name"
            label="Legal last name of business owner"
            placeholder="e.g. Smith"
            size="lg"
            required
            classNames={classes}
            {...registerForm.getInputProps('lastName')}
          />
        </div>
        <div>
          <TextInput
            id="input-email"
            data-testid="input-email"
            label="Email"
            type="email"
            placeholder="john.smith@business.com"
            size="lg"
            required
            classNames={classes}
            {...registerForm.getInputProps('email')}
          />
        </div>

        <PasswordStrengthPopover inputValue={registerForm.values.password}>
          <PasswordInput
            label="Password"
            placeholder="Create a password"
            size="lg"
            required
            {...registerForm.getInputProps('password')}
            id="input-password"
            data-testid="input-password"
            classNames={classes}
          />
        </PasswordStrengthPopover>
        <PasswordInput
          placeholder="Confirm password"
          label="Confirm password"
          size="lg"
          required
          {...registerForm.getInputProps('confirmPassword')}
          id="input-confirm-password"
          data-testid="input-confirm-password"
          classNames={classes}
        />
        <div style={{ margin: '16px 0' }}>
          <TermsOfServiceCheckboxPrompt
            type="flexbase"
            size="sm"
            {...registerForm.getInputProps('flexbaseTos')}
          />
        </div>
        {error ? (
          <Alert icon={<FaExclamationCircle />} color="red">
            {accountExistsError ? (
              <>
                An account with this email already exists.{' '}
                <Link
                  to={'/login'}
                  style={{ color: theme.fn.primaryColor() }}
                  id="navigate-existing-login"
                  data-testid="navigate-existing-login"
                >
                  Login
                </Link>{' '}
                or{' '}
                <Link
                  to={'/forgot-password'}
                  style={{ color: theme.fn.primaryColor() }}
                  id="navigate-forgot-password"
                  data-testid="navigate-forgot-password"
                >
                  reset your password
                </Link>
                .
              </>
            ) : (
              error
            )}
          </Alert>
        ) : null}
        <Button
          classNames={{ root: classes.submitButton }}
          size="lg"
          id="button-register-submit"
          data-testid="button-register-submit"
          onClick={onSubmit}
          loading={loading}
        >
          Start application
        </Button>
        <Stack align="center">
          <Text>
            Have an account?{' '}
            <Link
              to={'/login'}
              style={{ color: '#979797' }}
              id="navigate-login"
              data-testid="navigate-login"
            >
              Sign in
            </Link>
          </Text>
        </Stack>
        <Stack align="center">
          {applicationDisclosure &&
            (selectedProduct === 'CREDIT' || selectedProduct === 'ALL') && (
              <Text className={classes.disclosure}>
                By clicking Start Application you are agreeing to our{' '}
                <Link
                  to={applicationDisclosure.url}
                  target="_blank"
                  style={{ color: theme.fn.primaryColor() }}
                >
                  Application and Solicitation Disclosure
                </Link>
              </Text>
            )}
        </Stack>
      </Stack>
    </>
  );
};

export default MiscAppStartForm;
