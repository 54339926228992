import { useState, useEffect } from 'react';
import { Button, Group, Image, useMantineTheme } from '@mantine/core';

import { useStyles } from '../../styles';
import errorPendingTransfer from 'assets/images/watch-banking.png';
import SuccessTransfer from 'assets/images/transferred-banking.png';
import { setPaymentRejectedError } from 'utilities/formatters/payment-rejected-message';

interface Props {
  title: string;
  backTo: string;
  reason?: string;
  status?: string;
  textToStart?: string;
  onClick?: () => void;
  closeModal: () => void;
}

const ModalSuccess = ({
  title,
  reason,
  backTo,
  onClick,
  closeModal,
  textToStart,
  status = 'Sent',
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [message, setMessage] = useState<string>();

  let transactionStatus: {
    title: string;
    color: string;
    description?: string;
    sketch?: string;
  };

  switch (status) {
    case 'Pending':
      transactionStatus = {
        title: 'Pending request',
        color: theme.fn.themeColor('tertiary', 1),
        description:
          'Your deposit request has been submited. It is now waiting for confirmation.',
        sketch: errorPendingTransfer,
      };
      break;

    case 'Rejected':
      transactionStatus = {
        description: message,
        title: 'Your transaction has failed',
        color: theme.fn.themeColor('critical', 1),
        sketch: errorPendingTransfer,
      };
      break;

    default:
      transactionStatus = {
        title: title,
        color: theme.fn.themeColor('primarySecondarySuccess', 1),
        sketch: SuccessTransfer,
      };
      break;
  }

  const getPaymentError = (reason: string) => {
    const message = setPaymentRejectedError(reason);
    setMessage(message);
  };

  useEffect(() => {
    if (reason) {
      getPaymentError(reason);
    }
  }, []);

  return (
    <div className={classes.modalSuccess}>
      <Image src={transactionStatus.sketch} width={260} />

      <span data-testid={'message'}>{transactionStatus.title}</span>
      <p style={{ fontFamily: 'PP Neue Montreal' }}>
        {transactionStatus.description}
      </p>

      <Group position="apart">
        <Button
          variant="outline"
          onClick={() => closeModal()}
          data-testid={'go-back-button'}
        >
          Back to {backTo}
        </Button>

        {onClick && textToStart && (
          <Button
            variant="light"
            onClick={onClick}
            data-testid={'make-another-transfer-button'}
          >
            {textToStart}
          </Button>
        )}
      </Group>
    </div>
  );
};

export default ModalSuccess;
