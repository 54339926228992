import OnboardingStep from '../components/onboarding-step';
import { useEffect, useState } from 'react';
import { Center, RingProgress, Text } from '@mantine/core';
import {
  composeStripeRequest,
  composeStripeRequestFromCompany,
} from '../../../states/onboarding/stripe-details';
import { flexbaseOnboardingClient } from '../../../services/flexbase-client';
import { stripAndFormat } from '../../../utilities/formatters/api-request-formatters';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import { useProductOnboarding } from '../onboarding-hooks';
import TagManager from 'react-gtm-module';

let stripeRequestInFlight = false;
const StripeConfig = () => {
  const [loading, setLoading] = useState(true);
  const onboardingStatus = useRecoilValue(ApplicationState);
  const [sections, setSections] = useState<{ value: number; color: string }[]>(
    [],
  );
  const [label, setLabel] = useState('Configuring company');
  const [error, setError] = useState('');
  const { navigateToNextProductStep, goBack } = useProductOnboarding();

  const configureStripe = async () => {
    try {
      const companyReq = composeStripeRequestFromCompany(
        onboardingStatus.company,
      );
      await flexbaseOnboardingClient.updateStripe(companyReq);

      setSections([{ value: 33, color: 'flexbase-teal' }]);
      setLabel('Configuring owners');

      // Next, add the owner.
      const ownerReq = stripAndFormat(
        composeStripeRequest(onboardingStatus as any, true, false, true, true),
      );
      await flexbaseOnboardingClient.updateStripe(ownerReq);

      setSections([
        { value: 33, color: 'flexbase-teal' },
        { value: 33, color: 'flexbase-teal' },
      ]);
      setLabel('Finalizing');

      // Finally, set owners as provided.
      const ownersProvidedReq = stripAndFormat(
        composeStripeRequestFromCompany(onboardingStatus.company, true, true),
      );
      await flexbaseOnboardingClient.updateStripe(ownersProvidedReq);

      setSections([
        { value: 33, color: 'flexbase-teal' },
        { value: 33, color: 'flexbase-teal' },
        { value: 34, color: 'flexbase-teal' },
      ]);
      TagManager.dataLayer({
        dataLayer: {
          event: 'stripeConfigured',
        },
      });
      setLabel('All done!');
    } catch (e) {
      setError('A critical error occurred. Contact support or press continue');
      setSections([{ value: 100, color: 'red' }]);
      setLabel('Error');
    } finally {
      setLoading(false);
      stripeRequestInFlight = false;
    }
  };

  useEffect(() => {
    if (!stripeRequestInFlight) {
      stripeRequestInFlight = true;
      configureStripe();
    }
  }, []);

  const onContinue = () => {
    // We are going to let them continue so they don't get stuck because Stripe screwed up.
    TagManager.dataLayer({
      dataLayer: {
        event: 'continuedWithoutStripe',
      },
    });
    navigateToNextProductStep(true);
  };

  return (
    <OnboardingStep
      title={'Account Configuration'}
      stepId={'account-config'}
      subtitle={'We need to configure your account. This may take a while'}
      showContinueSpinner={loading}
      onNextClick={onContinue}
      onBackClick={() => goBack()}
      error={error}
    >
      <Center py={32}>
        <RingProgress
          sections={sections}
          label={
            <Text size="xs" align="center">
              {label}
            </Text>
          }
          size={180}
        />
      </Center>
    </OnboardingStep>
  );
};

export default StripeConfig;
