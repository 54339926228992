import { Stack } from '@mantine/core';

import IAddress from 'states/user/address';
import { useStyles } from '../cancel-debit-card-styles';
import AddressForm from 'components/forms/address-form';
import { CardByUser } from 'services/flexbase/banking.model';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

interface Props {
  card: CardByUser;
  loading: boolean;
  onBack: () => void;
  nextStep: (x: IAddress) => void;
}

const ConfirmShippingAddress = ({ card, loading, onBack, nextStep }: Props) => {
  const { classes } = useStyles();

  return (
    <div>
      <p className={classes.subtitle}>
        DEBIT CARD {getPaddedAccountMask(card.cardNumber, 4)}
      </p>
      <span className={classes.title}>Confirm Shipping Address</span>

      <Stack mt={20}>
        <AddressForm
          currentAddress={{
            ...card.shipTo,
            line1: card.shipTo.street ?? card.shipTo.address,
            line2: card.shipTo.street2 ?? card.shipTo.addressLine2,
          }}
          onSubmit={nextStep}
          onClose={onBack}
          cancelText="Back"
          submitText="Confirm"
          loading={loading}
        />
      </Stack>
    </div>
  );
};

export default ConfirmShippingAddress;
