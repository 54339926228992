import { atom, selector } from 'recoil';
import { Counterparty, DepositAccount } from 'services/flexbase/banking.model';
import { PaymentSteps } from './send-payment';

export const depositInitial = {
  asOf: '',
  companyId: '',
  createdAt: '',
  id: '',
  isDefault: false,
  name: '',
  nickName: '',
  status: '',
  type: 'depositAccount' as const,
  accountType: 'checking' as const,
  ucCustomerId: '',
  ucDepositId: '',
  userId: '',
  version: 0,
  routingNumber: '',
  accountNumber: '',
  balance: 0,
  available: 0,
  tier: 1 as const,
  tenantId: '',
};

export const counterpartyInitial = {
  accountName: '',
  accountNumber: '',
  routingNumber: '',
  asOf: '',
  companyId: '',
  createdAt: '',
  id: '',
  response: {
    data: {
      attributes: {
        accountNumber: '',
        accountType: '',
        bank: '',
        createdAt: '',
        name: '',
        permissions: '',
        routingNumber: '',
        tags: {
          companyId: '',
          userId: '',
          tenantId: '',
        },
        type: '',
      },
      id: '',
      relationships: {
        customer: {
          data: {
            id: '',
            type: '',
          },
        },
      },
      type: '',
    },
  },
  status: 'active' as const,
  type: 'ach' as const,
  ucCounterpartyId: '',
  nickName: '',
  name: '',
  ucCustomerId: '',
  userId: '',
  version: 0,
  tenantId: '',
  lastUsedAt: '',
  bank: '',
};

export const recipientInitial = {
  name: '',
  accountNumber: '',
  routingNumber: '',
  accountType: '',
  nickName: '',
  type: 'ach',
  recipientTypePersonBusiness: '',
  address: '',
  addressLine2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
} as Recipient;

export type PayMethod = 'ach' | 'wire' | 'flexPay';

export const newRecipientFlag = atom<boolean>({
  key: 'add_payment_new_recipient',
  default: false,
});

export const paymentStep = atom<PaymentSteps>({
  key: 'add_payment_step',
  default: 0,
});

export const saveCounteraprtyState = atom<boolean>({
  key: 'add_payment_save_counterparty',
  default: true,
});

export const paymentRecipientName = atom<string>({
  key: 'add_payment_recipient_name',
  default: '',
});

export const paymentMethod = atom<
  'wire' | 'ach' | 'flexPay' | 'wireInternational'
>({
  key: 'add_payment_method',
  default: 'ach',
});

export const selectedCounterparty = atom<Counterparty>({
  key: 'add_payment_selected_counterparty',
  default: undefined,
});

export const paymentCounterpartiesList = atom<Counterparty[]>({
  key: 'add_payment_counterparties_list',
  default: [],
});

// filter method: https://recoiljs.org/docs/basic-tutorial/selectors
export const counterpartiesListFilter = atom<string>({
  key: 'add_payment_counterparties_filter',
  default: 'active',
});

export const filteredCounterpartiesListState = selector({
  key: 'add_payment_filtered_counterparties_list',
  get: ({ get }) => {
    const filter = get(counterpartiesListFilter);
    const list = get(paymentCounterpartiesList);
    const accountName = get(paymentRecipientName);
    const type = get(paymentMethod);

    switch (filter) {
      case 'active':
        return list.filter((item) => item.status === 'active');
      case 'active_recipient':
        return list
          .filter((item) => item.status === 'active')
          .filter((item) => item.accountName);
      case 'active_recipient_method':
        return list
          .filter((item) => item.status === 'active')
          .filter((item) => item.accountName === accountName)
          .filter((item) => item.type === type);
      case 'new_recipient':
        return [];
      default:
        return list;
    }
  },
});

// i dont know why i dont like it, it's just countarperty flattened.
// i cannot figure out how to use mantine form with typescript + deeply nested interface requirements (address)
export interface Recipient {
  name: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  type: PayMethod;
  recipientTypePersonBusiness: string;
  nickName: string;
  address: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export const recipientState = atom<Recipient>({
  key: 'add_payment_recipient',
  default: undefined,
});

export interface MoneyMovementDetails {
  amount: number;
  sourceAccount: DepositAccount;
  internalNotes?: string;
  memo: string;
  files?: File[];
}

export const depositAccountList = atom<DepositAccount[]>({
  key: 'add_payment_deposit_list',
  default: [],
});

export const moneyMovementAmountDetails = atom<MoneyMovementDetails>({
  key: 'add_payment_amount',
  default: {
    amount: 0,
    memo: '',
    internalNotes: '',
    sourceAccount: depositInitial,
    files: [],
  },
});
