import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { hotjar } from 'react-hotjar';
import { getEnvironment } from './utilities/url/window-helpers';

const hostEnv = getEnvironment(window.location.host);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (hostEnv === 'production') {
  hotjar.initialize(2328562, 6);
}

root.render(<App />);
