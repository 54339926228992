import { atom } from 'recoil';

interface CompanyAddr {
  Id: string;
  Line1: string;
  City: string;
  CountrySubDivisionCode: string;
  PostalCode: string;
  Lat: string;
  Long: string;
}

interface CustomerCommunicationAddr {
  Id: string;
  Line1: string;
  City: string;
  CountrySubDivisionCode: string;
  PostalCode: string;
  Lat: string;
  Long: string;
}

interface LegalAddr {
  Id: string;
  Line1: string;
  City: string;
  CountrySubDivisionCode: string;
  PostalCode: string;
  Lat: string;
  Long: string;
}

interface CustomerCommunicationEmailAddr {
  Address: string;
}

interface Email {
  Address: string;
}

interface NameValue {
  Name: string;
  Value: any;
}

interface MetaData {
  CreateTime: Date;
  LastUpdatedTime: Date;
}

interface CompanyInfo {
  CompanyName: string;
  LegalName: string;
  CompanyAddr: CompanyAddr;
  CustomerCommunicationAddr: CustomerCommunicationAddr;
  LegalAddr: LegalAddr;
  CustomerCommunicationEmailAddr: CustomerCommunicationEmailAddr;
  PrimaryPhone: Record<string, unknown>;
  CompanyStartDate: string;
  FiscalYearStartMonth: string;
  Country: string;
  Email: Email;
  WebAddr: Record<string, unknown>;
  SupportedLanguages: string;
  NameValue: NameValue[];
  domain: string;
  sparse: boolean;
  Id: string;
  SyncToken: string;
  MetaData: MetaData;
}

export interface QbooksCompany {
  CompanyInfo: CompanyInfo;
  time: Date;
}

export const QbooksCompanyState = atom<QbooksCompany>({
  key: 'qbooks-company',
  default: {
    CompanyInfo: {
      CompanyName: '',
      Id: '',
    } as CompanyInfo,
    time: new Date(),
  },
});
