export enum PlaidContextValue {
  ONBOARDING = 'onboarding',
  BANKING = 'banking',
  STALE = 'stale',
}

class PlaidContext {
  static set appContext(context: PlaidContextValue) {
    localStorage?.setItem('plaid_context', context);
  }

  static get appContext(): PlaidContextValue {
    const storedContext = localStorage?.getItem('plaid_context');
    return storedContext
      ? (storedContext as PlaidContextValue)
      : PlaidContextValue.ONBOARDING;
  }

  static clearPlaidContext() {
    localStorage?.removeItem('plaid_context');
  }
}

export default PlaidContext;
