import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { Image, Text } from '@mantine/core';

import { useStyles } from './styles';
import NoAccount from './no-account/no-account';
import BankingAccount from './accounts/banking-account';
import Statements from './statements/statements';
import { flexbaseBankingClient } from 'services/flexbase-client';
import SkeletonLoading from 'components/loading/skeleton-loading';
import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import { Analytics } from 'services/analytics/analytics';
import { useParams } from 'react-router-dom';
import { NavTab } from 'components/tabs/nav-tabs';
import DebitCards from './debit-cards/debit-cards';
import ExternalAccounts from './external-accounts/external-accounts';
import BankingTransactions from './banking-transactions/banking-transactions';
import MoveFundsModal from './move-funds/move-funds.page';
import { DepositAccountsState } from './states/deposit-accounts';
import { UserIdState } from '../onboarding/onboarding-form.state';
import { NavTabContainer } from '../../components/tabs/nav-tab-container';
import { ApplicationState } from '../../states/application/product-onboarding';
import { BankingApplicationStatus } from 'services/flexbase/banking.model';
import WatchBAnking from 'assets/images/watch-banking.png';

const Banking = () => {
  const { classes } = useStyles();
  const { tab } = useParams();
  const [state, setState] = useState('');
  const [appType, setAppType] = useState(''); // businessApplication or personalApplication
  const [loading, setLoading] = useState(false);
  const [documentsLink, setDocumentsLink] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [bankingAccounts, setBankingAccounts] =
    useRecoilState(DepositAccountsState);
  const [applicationStatus, setApplicationStatus] =
    useState<BankingApplicationStatus | null>(null);
  const { user } = useRecoilValue(ApplicationState);

  const disabledTabs = [
    'PendingReview',
    'Denied',
    'Canceled',
    'Pending',
    'Incomplete',
    'AwaitingDocuments',
    'Unused',
    null,
  ].includes(applicationStatus);

  const tabs: NavTab[] = [
    { label: 'Accounts', route: '/banking/accounts' },
    {
      label: 'Transactions',
      route: '/banking/transactions',
      disabled: disabledTabs,
    },
    {
      label: 'Debit Cards',
      route: '/banking/debit-cards',
      disabled: disabledTabs,
    },
    {
      label: 'Transfer Funds',
      route: '/banking/move-funds',
      disabled: disabledTabs || !user.roles.includes('ADMIN'),
    },
    {
      label: 'Statements',
      route: '/banking/statements',
      disabled: disabledTabs,
    },
    {
      label: 'External Accounts',
      route: '/banking/linked-accounts',
      disabled: disabledTabs,
    },
  ];

  const userId = useRecoilValue(UserIdState);

  const getTabContent = () => {
    switch (tab) {
      case 'transactions':
        return <BankingTransactions />;
      case 'move-funds':
        return <MoveFundsModal />;
      case 'statements':
        return <Statements />;
      case 'debit-cards':
        return <DebitCards {...{ appType, bankingAccounts }} />;
      case 'linked-accounts':
        return <ExternalAccounts />;
      default:
        return (
          <BankingAccount
            {...{
              loading,
              errorMessage,
              documentsLink,
              bankingAccounts,
              applicationStatus,
            }}
          />
        );
    }
  };

  const getAccountInfo = async () => {
    try {
      setLoading(true);
      const response = await flexbaseBankingClient.getDepositList();
      if (response.success && response.accounts) {
        setBankingAccounts(response.accounts);
      } else {
        setErrorMessage(true);
      }
    } catch (error) {
      setErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const getApplicationStatus = async () => {
    try {
      const response = await flexbaseBankingClient.getApplicationStatus();
      if (response.success && response.status && userId) {
        setApplicationStatus(response.status);
        if (
          response.status === 'AwaitingDocuments' &&
          response.success &&
          'url' in response
        ) {
          // FIXME: use response.documents instead once we've replaced the whitelabel components
          setDocumentsLink(response.url ?? '');
        }

        Analytics.people.set(userId, {
          'Bank Application Status': response.status,
        });
        setAppType(response.type);
      }
      setState('appStatus');
    } catch (error: any) {
      setState('error');
    }
  };

  let comp;

  switch (state) {
    case 'error':
      comp = (
        <div className={classes.emptyState}>
          <Image
            width={200}
            src={WatchBAnking}
            alt="Display info error"
            className="image-container"
          />
          <Text ff="redaction" size={36} align="center" mt={30}>
            Sorry about that...
          </Text>
          <p>
            We are unable to retrieve the account information at the moment.
          </p>
        </div>
      );
      break;

    case 'appStatus':
      comp = (
        <>
          {!['Unused', null].includes(applicationStatus) ? (
            <>
              <NavTabContainer tabs={tabs}>{getTabContent()}</NavTabContainer>
            </>
          ) : (
            <NoAccount />
          )}
        </>
      );
      break;
    default:
      comp = <SkeletonLoading withTabs />;
      break;
  }

  useEffect(() => {
    getApplicationStatus().then(getAccountInfo);
  }, []);

  return (
    <div className={classes.mainContainer}>
      <FlexbaseDefaultHeader title="Banking" />
      {comp}
    </div>
  );
};

export default Banking;
