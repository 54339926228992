import useStyles, { customTableStyle } from './styles';
import { Button, Menu, Skeleton, Text, useMantineTheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import {
  QBooksAccount,
  QBooksMappingTarget,
} from 'services/flexbase/flexbase-onboarding-client';
import { useRecoilState, useRecoilValue } from 'recoil';
import { QbooksCompanyState } from 'states/qbooks/qbooks-company';
import FlexbaseSelect from 'components/select/flexbase-select';
import { useCustomInlineSelectStyles } from 'areas/document-management/styles';
import { PlusSignIcon, RowAction } from 'assets/svg';
import FlexbaseInput from 'components/input/flexbase-input';
import { CompanyIdState } from 'areas/onboarding/onboarding-form.state';
import TableContainerWithSearchFilter from 'components/table/table-container';
import FlexbaseTable from 'components/table/flexbase-table';
import AlertWithLink from 'components/alerts/alert-with-link';
import { customMantineStyles } from '../../team-members/team-members.styles';
import { showNotification } from '@mantine/notifications';

type MappingTargetsTableRow = {
  index: number;
  newRow: boolean;
  target: QBooksMappingTarget;
};

const Accounting = () => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const { classes: customInputStyles } = useCustomInlineSelectStyles();
  const [loading, setLoading] = useState(false);
  const companyId = useRecoilValue(CompanyIdState);
  const [loadingTargets, setLoadingTargets] = useState(true);
  const [qbCompany, setQbCompany] = useRecoilState(QbooksCompanyState);
  const hasCompany = !!qbCompany.CompanyInfo?.CompanyName;
  const [expenseAccounts, setExpenseAccounts] = useState<QBooksAccount[]>([]);
  const [creditCardAccounts, setCreditCardAccounts] = useState<QBooksAccount[]>(
    [],
  );
  const [mappingTargets, setMappingTargets] = useState<QBooksMappingTarget[]>(
    [],
  );

  const [newRow, setNewRow] = useState<boolean>(false);
  const [newExpenseId, setNewExpenseId] = useState<string>('');
  const [newAccountId, setNewAccountId] = useState<string>('');
  const [newMatcher, setNewMatcher] = useState<string>('');
  const [newValue, setNewValue] = useState<string>();
  const [valuesNeeded, setValuesNeeded] = useState(false);
  const [valueOverlap, setValueOverlap] = useState(false);

  const newRowTableData = newRow
    ? [
        {
          newRow: true,
        } as MappingTargetsTableRow,
      ]
    : [];

  const mappingTargetsTableData: MappingTargetsTableRow[] = mappingTargets
    .map((target, index) => {
      return {
        index,
        newRow: false,
        target: target,
      };
    })
    .concat(newRowTableData);

  const getQbooksCompany = async () => {
    setLoading(true);
    try {
      const qbCompanyResponse =
        await flexbaseOnboardingClient.getQbooksCompany();
      setQbCompany(qbCompanyResponse);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getQbooksAccounts = async () => {
    try {
      const qbAccounts = await flexbaseOnboardingClient.getQbooksAccounts();
      if (qbAccounts.success) {
        setCreditCardAccounts(
          qbAccounts.accounts.filter(
            (account) => account.AccountType === 'Credit Card',
          ),
        );
        setExpenseAccounts(
          qbAccounts.accounts.filter(
            (account) => account.AccountType === 'Expense',
          ),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getQbooksMappingTargets = async () => {
    setLoadingTargets(true);
    try {
      const qbTargets =
        await flexbaseOnboardingClient.getQbooksMappingTargets();
      if (qbTargets.success) {
        if (qbTargets.targets.length > 0) {
          setMappingTargets(qbTargets.targets as QBooksMappingTarget[]);
        } else {
          setNewRow(true);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingTargets(false);
    }
  };

  const putQbooksMappingTarget = async (target: QBooksMappingTarget) => {
    setLoadingTargets(true);
    try {
      const qbPutTargetResponse =
        await flexbaseOnboardingClient.putQbooksMappingTarget(target);
      if (qbPutTargetResponse) {
        await getQbooksMappingTargets();
        setNewRow(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingTargets(false);
    }
  };

  useEffect(() => {
    if (!hasCompany) {
      getQbooksCompany();
    }
    getQbooksAccounts();
    getQbooksMappingTargets();
  }, []);

  const onSyncNew = () => {
    setValuesNeeded(false);
    setValueOverlap(false);
    if (!newAccountId || !newExpenseId || (newMatcher && !newValue)) {
      setValuesNeeded(true);
    } else if (
      (newMatcher === 'storeId' &&
        mappingTargets.map((target) => target.storeId).includes(newValue)) ||
      (newMatcher === 'mccCode' &&
        mappingTargets.map((target) => target.mccCode).includes(newValue)) ||
      (newMatcher === 'nameBit' &&
        mappingTargets.map((target) => target.nameBit).includes(newValue)) ||
      (!newMatcher &&
        mappingTargets.find(
          (target) =>
            !target.mccCode &&
            !target.nameBit &&
            !target.mccCode &&
            target.companyId === companyId,
        ))
    ) {
      setValuesNeeded(true);
      setValueOverlap(true);
    } else {
      const account = creditCardAccounts.find(
        (account) => account.Id === newAccountId,
      );
      const expense = expenseAccounts.find(
        (account) => account.Id === newExpenseId,
      );
      if (account && expense) {
        const newTarget: QBooksMappingTarget = {
          account,
          expense,
        };
        if (newMatcher) {
          newTarget[
            newMatcher as 'storeId' | 'nameBit' | 'mccCode' | 'companyId'
          ] = newValue;
        }
        putQbooksMappingTarget(newTarget);
      }
    }
  };

  const columns = [
    {
      name: 'hidden_target_id',
      selector: (row: MappingTargetsTableRow) => row.index,
      omit: true,
    },
    {
      name: 'Source',
      cell: (row: MappingTargetsTableRow) =>
        row.newRow ? (
          <div
            className={classes.newRowContainer}
            style={{ margin: '0 14px 0 14px' }}
            data-tag="allowRowEvents"
          >
            <FlexbaseSelect
              size="sm"
              value={newMatcher}
              data={[
                { value: 'storeId', label: 'Merchant ID' },
                { value: 'nameBit', label: 'Merchant Name Contains' },
                { value: 'mccCode', label: 'MCC Code' },
                // { value: 'companyId', label: 'Company ID' },
              ]}
              placeholder="Select match type (optional)"
              classNames={customInputStyles}
              style={{
                width: 250,
                marginBottom: 10,
              }}
              error={valueOverlap}
              onChange={(e: 'storeId' | 'nameBit' | 'mccCode' | 'companyId') =>
                setNewMatcher(e)
              }
            />
            <FlexbaseInput
              required
              size="sm"
              placeholder="Value"
              value={newValue}
              style={{
                width: 250,
              }}
              disabled={!newMatcher}
              error={
                valueOverlap
                  ? 'A rule with this match already exists'
                  : valuesNeeded && !!newMatcher && !newValue
              }
              onChange={(e) => setNewValue(e.currentTarget.value)}
            />
          </div>
        ) : (
          <div style={{ marginLeft: '16px', marginTop: '-6px' }}>
            {row.target.storeId && (
              <div className={classes.cellContainer}>
                <Text className={classes.cellTextSmall}>Store ID</Text>
                <Text className={classes.cellText}>{row.target.storeId}</Text>
              </div>
            )}
            {row.target.nameBit && (
              <div className={classes.cellContainer}>
                <Text className={classes.cellTextSmall}>
                  Merchant Name Contains
                </Text>
                <Text className={classes.cellText}>{row.target.nameBit}</Text>
              </div>
            )}
            {row.target.mccCode && (
              <div className={classes.cellContainer}>
                <Text className={classes.cellTextSmall}>MCC Code</Text>
                <Text className={classes.cellText}>{row.target.mccCode}</Text>
              </div>
            )}
            {row.target.companyId && row.target.companyId !== companyId && (
              <div className={classes.cellContainer}>
                <Text>Company ID</Text>
                <Text className={classes.cellText}>{row.target.companyId}</Text>
              </div>
            )}
          </div>
        ),
      sortable: false,
      grow: 2,
    },
    {
      name: 'Expense Account',
      selector: (row: MappingTargetsTableRow) => row.target.expense.Name,
      cell: (row: MappingTargetsTableRow) =>
        row.newRow ? (
          <div className={classes.newRowContainer}>
            <FlexbaseSelect
              data-tag="allowRowEvents"
              value={newExpenseId}
              placeholder="Select an expense account"
              disabled={expenseAccounts?.length < 1}
              data={expenseAccounts.map((account) => {
                return { value: account.Id, label: account.Name };
              })}
              searchable
              maxDropdownHeight={300}
              size="sm"
              classNames={customInputStyles}
              style={{
                width: 300,
              }}
              error={valuesNeeded && !newExpenseId}
              onChange={(e) => setNewExpenseId(e || '')}
            />
          </div>
        ) : (
          <div className={classes.cellContainer}>
            <Text className={classes.cellText}>{row.target.expense.Name}</Text>
          </div>
        ),
      sortable: true,
      grow: 2,
    },
    {
      cell: (row: MappingTargetsTableRow) =>
        row.newRow && (
          <div className={classes.newRowContainer}>
            <Text className={classes.and}>and</Text>
          </div>
        ),
      center: true,
    },
    {
      name: 'Credit Card Account',
      selector: (row: MappingTargetsTableRow) => row.target.account.Name,
      cell: (row: MappingTargetsTableRow) =>
        row.newRow ? (
          <div className={classes.newRowContainer}>
            <FlexbaseSelect
              data-tag="allowRowEvents"
              value={newAccountId}
              placeholder="Select a credit card account"
              disabled={creditCardAccounts?.length < 1}
              data={creditCardAccounts.map((account) => {
                return { value: account.Id, label: account.Name };
              })}
              searchable
              maxDropdownHeight={300}
              size="sm"
              classNames={customInputStyles}
              style={{
                width: 300,
              }}
              error={valuesNeeded && !newAccountId}
              onChange={(e) => setNewAccountId(e || '')}
            />
          </div>
        ) : (
          <div className={classes.cellContainer}>
            <Text className={classes.cellText}>{row.target.account.Name}</Text>
          </div>
        ),
      sortable: true,
      grow: 2,
    },
    {
      cell: (row: MappingTargetsTableRow) =>
        row.newRow && (
          <div className={classes.newRowContainer}>
            <Button
              variant="filled"
              className={classes.syncButton}
              onClick={onSyncNew}
            >
              Save rule
            </Button>
          </div>
        ),
      center: true,
    },
    {
      cell: (row: MappingTargetsTableRow) => (
        <div>
          <Menu styles={customMantineStyles(theme.fn.primaryColor())}>
            <Menu.Target>
              <div style={{ width: '30px' }}>
                <RowAction style={{ fill: '#979797', cursor: 'pointer' }} />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                style={{ fontSize: '14px', width: 150 }}
                onClick={async () => {
                  const result =
                    await flexbaseOnboardingClient.deleteQbooksMappingTarget(
                      row.target.id!,
                    );
                  if (result.success === true) {
                    const newMappings = mappingTargets.filter(
                      (mapping) => mapping.id !== row.target.id,
                    );
                    setMappingTargets(newMappings);
                  } else {
                    console.error('error deleting mapping target');
                    showNotification({
                      color: 'red',
                      title: 'Failure',
                      message: `Error deleting mapping target`,
                    });
                  }
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      ),
      omit: newRow,
      center: true,
      sortable: false,
    },
  ];

  const onNewRule = () => {
    setNewRow(true);
    setNewAccountId('');
    setNewExpenseId('');
    setNewExpenseId('');
    setNewValue('');
    setValuesNeeded(false);
  };

  return (
    <div className={classes.accounting}>
      {!loading && !hasCompany && (
        <AlertWithLink
          message="Connect a Quickbooks account to use this feature."
          link={{
            text: 'Go to Integrations page',
            url: '/settings/integrations',
          }}
        />
      )}
      <div className={classes.widgetContainer}>
        <Text className={classes.titleText}>Quickbooks</Text>
        <Text className={classes.smallText}>
          Manage your Quickbooks settings by managing and configuring default
          mappings for transactions
        </Text>
        <hr className={classes.lineDivider} />
        <Text className={cx(classes.smallText, classes.orgTitleText)}>
          Organization Name
        </Text>
        <Text className={cx(classes.titleText, classes.orgText)}>
          <Skeleton
            height={30}
            width={loading ? 250 : 'unset'}
            radius="md"
            visible={loading}
          >
            {hasCompany
              ? qbCompany.CompanyInfo.CompanyName
              : 'No account connected'}
          </Skeleton>
        </Text>
      </div>
      {hasCompany && (
        <TableContainerWithSearchFilter title="Rules">
          <FlexbaseTable
            columns={columns}
            data={mappingTargetsTableData}
            selectableRows={false}
            customStyles={customTableStyle(theme.fn.primaryColor())}
            isFetchingData={loadingTargets}
          />
          {!loadingTargets && (
            <Button
              variant="outline"
              leftIcon={<PlusSignIcon width={12} height={12} />}
              style={{ marginTop: '14px', width: '130px' }}
              onClick={onNewRule}
              disabled={newRow}
            >
              New Rule
            </Button>
          )}
        </TableContainerWithSearchFilter>
      )}
    </div>
  );
};

export default Accounting;
