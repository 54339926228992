import { StyleSheet } from '@react-pdf/renderer';

export const stylesStatementPDF = StyleSheet.create({
  topColors: {
    width: '100%',
    height: '6px',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
  },
  topRed: {
    width: '69%',
    height: '6px',
    backgroundColor: '#FF5745',
  },
  topRose: {
    width: '15.5%',
    height: '6px',
    backgroundColor: '#FFF2F0',
  },
  topBlue: {
    width: '15.5%',
    height: '6px',
    backgroundColor: '#2F2CFF',
  },
  containerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexbaseLogoImage: {
    color: '#ff5745',
    fontWeight: 700,
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    color: 'gray',
  },
  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerPage: {
    padding: '0px 25px',
    marginTop: 28,
    width: '100%',
    height: '100vh',
  },
  footerContainer: {
    backgroundColor: 'rgba(255, 87, 69, 0.1)',
    fontSize: '10px',
    color: '#979797',
    padding: '15px 20px',
    flexDirection: 'row',
    height: '7vh',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
});
