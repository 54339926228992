import {
  BankingIcon,
  BankingIconActive,
  CreditIcon,
  CreditIconActive,
  InsuranceIcon,
  InsuranceIconActive,
  PaymentIcon,
  PaymentIconActive,
  SettingsIconNew,
  SettingsIconNewActive,
  TeamsIcon,
  TeamsIconActive,
  TreasuryIcon,
  TreasuryIconActive,
  DocumentsIcon,
  DocumentsIconActive,
} from 'assets/svg';
import React, { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { OnboardingProduct } from 'states/application/product-onboarding.models';
import { UserRole } from 'states/user/user-info';

export interface MenuOption {
  label: string;
  location: string;
  icon: ReactElement<IconType>;
  disabled?: boolean;
  subRoutes?: [{ label: string; location: string }];
  onClick?: () => void;
  unclickable?: boolean;
  devOnly?: boolean;
  adminOnly?: boolean;
  rolesAllowed?: UserRole[];
  activeIcon: ReactElement<IconType>;
  product: OnboardingProduct;
}

export const NAVBAR_OPTIONS: MenuOption[] = [
  // {
  //   label: 'Home',
  //   location: '/',
  //   icon: <HomeIcon />,
  //   activeIcon: <HomeIconActive />,
  //   adminOnly: true,
  //   product: 'ALL',
  // },
  {
    label: 'Treasury Management',
    location: '/accounts',
    icon: <TreasuryIcon style={{ marginLeft: '2px', marginBottom: '3px' }} />,
    activeIcon: (
      <TreasuryIconActive style={{ marginLeft: '2px', marginBottom: '3px' }} />
    ),
    // unclickable: true,
    product: 'TREASURY',
  },
  {
    label: 'Credit',
    location: '/cards',
    icon: <CreditIcon />,
    activeIcon: <CreditIconActive />,
    product: 'CREDIT',
  },
  {
    label: 'Banking',
    location: '/banking',
    // disabled: true,
    icon: <BankingIcon />,
    rolesAllowed: ['ADMIN', 'ACCOUNTANT'],
    activeIcon: <BankingIconActive />,
    // unclickable: true,
    devOnly: true,
    product: 'BANKING',
  },
  {
    label: 'Payments',
    location: '/payments',
    icon: <PaymentIcon />,
    rolesAllowed: ['ADMIN', 'ACCOUNTANT'],
    activeIcon: <PaymentIconActive />,
    devOnly: true,
    // unclickable: true,
    product: 'BANKING',
  },
  {
    label: 'Insurance',
    location: '/insurance',
    icon: <InsuranceIcon />,
    activeIcon: <InsuranceIconActive />,
    disabled: true,
    unclickable: true,
    product: 'ALL', // We don't yet have a specific insurance product.
  },
];

export const BOTTOM_NAVBAR_OPTIONS: MenuOption[] = [
  {
    label: 'Documents',
    location: '/documents',
    icon: <DocumentsIcon />,
    activeIcon: <DocumentsIconActive />,
    product: 'ALL',
    adminOnly: true,
    devOnly: true,
  },
  {
    label: 'Team',
    location: '/team',
    icon: <TeamsIcon />,
    activeIcon: <TeamsIconActive />,
    adminOnly: true,
    product: 'ALL',
  },
  {
    label: 'Settings',
    location: '/settings',
    icon: <SettingsIconNew />,
    activeIcon: <SettingsIconNewActive />,
    product: 'ALL',
  },
];
