import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import {
  PRODUCT_ONBOARDING_BASE_ROUTE,
  ProductOnboardingRoutes,
} from './onboarding.constants';
import { OnboardingCompany } from 'states/onboarding/onboarding-info';
import { formatPhoneForApi } from 'utilities/formatters/format-phone-number';
import { formatDateForApi } from 'utilities/formatters/format-date-input';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  ApplicationState,
  getProductOnboardingStatus,
  ProductCurrentStep,
  ProductNavStack,
  ProductOnboardingBtnLoaderState,
  ProductState,
} from 'states/application/product-onboarding';
import { stripAndFormat } from 'utilities/formatters/api-request-formatters';
import {
  _ApplicationState,
  OnboardingApplicationConfig,
  OnboardingProduct,
  OnboardingRouteNames,
} from 'states/application/product-onboarding.models';
import { BankingApplicationConfig } from 'states/application/banking-application.config';
import { CreditApplicationConfig } from 'states/application/credit-application.config';
import { AllProductsApplicationConfig } from '../../states/application/all-application.config';
import { BUSINESS_ANNUAL_REVENUE } from '../../states/business/constants';

const getConfigFromApplicationState = (
  status: _ApplicationState,
): OnboardingApplicationConfig => {
  const { credit, banking } = status.productStatus;

  // Default to credit+banking
  let config = 'credit+banking';

  if (credit.status !== 'incomplete' && banking.status === 'incomplete') {
    config = 'banking';
  } else if (
    credit.status === 'incomplete' &&
    banking.status !== 'incomplete'
  ) {
    config = 'credit';
  }

  switch (config) {
    case 'banking':
      return BankingApplicationConfig;
    case 'credit':
      return CreditApplicationConfig;
    case 'credit+banking':
    default:
      return AllProductsApplicationConfig;
  }
};

export const useProductOnboarding = () => {
  const setLoading = useSetRecoilState(ProductOnboardingBtnLoaderState);
  const navigate = useNavigate();
  const { step } = useParams();
  const [navStack, setNavStack] = useRecoilState(ProductNavStack);
  const setCurrentStep = useSetRecoilState(ProductCurrentStep);

  const [product, setProduct] = useRecoilState(ProductState);
  const [productOnboardingStatus, setProductOnboardingStatus] =
    useRecoilState(ApplicationState);
  const currentConfig = getConfigFromApplicationState(productOnboardingStatus);
  // I'm not sure if this would be better as a memo. The arrays aren't that big, so likely the useMemo memory footprint will be larger than running this calculation every render.
  const currentProgress = !step
    ? 0
    : (currentConfig.routes.indexOf(step as OnboardingRouteNames) + 1) *
      (100 / currentConfig.routes.length);
  const refreshProductOnboardingStatus = async (includeFullOwners = false) => {
    const newStatus = await getProductOnboardingStatus(includeFullOwners);
    setProductOnboardingStatus(newStatus);
    // Make sure to update the products so we always have the correct config. Only set when it is returned on the company, otherwise leave it as it was.
    if (newStatus.product) {
      setProduct(newStatus.product);
    }
    return newStatus;
  };
  const navigateToNextProductStep = useCallback(
    async (isTransientStep = false) => {
      setLoading(true);
      try {
        const newStatus = await refreshProductOnboardingStatus();
        const declinedStep = await getDeclineScreen(product, newStatus);
        if (declinedStep) {
          navigate(
            `${PRODUCT_ONBOARDING_BASE_ROUTE}/${ProductOnboardingRoutes.END}/${declinedStep}`,
          );
        } else if (navStack.length > 0) {
          const pop = navStack[0];
          setNavStack((prev) => [...prev.slice(1)]);
          navigate(`${PRODUCT_ONBOARDING_BASE_ROUTE}/${pop}`);
        } else {
          const nextStep = isTransientStep
            ? currentConfig.getNextRouteFromCurrentRoute(
                step as OnboardingRouteNames,
              )
            : currentConfig.getNextRouteFromStatus(newStatus);
          navigate(`${PRODUCT_ONBOARDING_BASE_ROUTE}/${nextStep}`);
          setCurrentStep(nextStep);
        }
        window.scroll(0, 0);
      } catch (e) {
        navigate(`${PRODUCT_ONBOARDING_BASE_ROUTE}/error`);
      } finally {
        setLoading(false);
      }
    },
    [step, product, navStack, currentConfig],
  );

  const goBack = useCallback(() => {
    setNavStack((prev) => [step!, ...prev]);
    const prevStep = currentConfig.getPreviousRoute(
      step as OnboardingRouteNames,
      productOnboardingStatus.userIsApplicant,
    );
    navigate(`${PRODUCT_ONBOARDING_BASE_ROUTE}/${prevStep}`);
    window.scroll(0, 0);
  }, [step, productOnboardingStatus, currentConfig]);

  const createOrUpdateCompany = useRecoilCallback(
    ({ snapshot }) =>
      async (
        data: Partial<OnboardingCompany>,
      ): Promise<{
        success: boolean;
        error: string;
        company: Partial<OnboardingCompany>;
      }> => {
        setLoading(true);
        const { company } = await snapshot.getPromise(ApplicationState);

        const formattedData = stripAndFormat({ id: company.id, ...data }, [
          { field: 'formationDate', formatterFn: formatDateForApi },
          { field: 'phone', formatterFn: formatPhoneForApi },
        ]);
        try {
          let companyPromise: Promise<OnboardingCompany>;
          let optionalFields = {};
          // This is a terrible hack to be able to explicitly set website to null for banking.
          // Need to figure out a better way to set null values and not have them stripped
          // from the request by the formatter above.
          if (data.website || data.website === null) {
            optionalFields = { website: data.website };
          }

          if (formattedData.id) {
            companyPromise = flexbaseOnboardingClient.updateCompany({
              ...formattedData,
              ...optionalFields,
            });
          } else {
            companyPromise = flexbaseOnboardingClient.createCompany({
              ...formattedData,
              ...optionalFields,
            });
          }
          const company = await companyPromise;
          setLoading(false);
          return { success: true, error: '', company };
        } catch (error) {
          console.error('useProductOnboarding::createOrUpdateCompany', error);
          setLoading(false);
          if (error?.message?.includes('already exists')) {
            return { success: false, error: 'ein_conflict', company: {} };
          }
          return {
            success: false,
            error: error?.message || 'api_error',
            company: {},
          };
        }
      },
    [],
  );

  /**
   * Check the application for any reasons to short-circuit/decline.
   * @returns string - An empty string which indicates no declines, or a string that matches a declined end state route
   */
  const getDeclineScreen = async (
    _product: OnboardingProduct,
    _applicationStatus: _ApplicationState,
  ): Promise<string> => {
    if (
      _applicationStatus.user.promoCode ||
      _product !== 'CREDIT' ||
      !_applicationStatus.user.roles.includes('ADMIN') ||
      !!_applicationStatus.productStatus.credit.creditLimit ||
      _applicationStatus.company.onboardedBy !== _applicationStatus.user.id
    ) {
      return '';
    }

    if (
      _applicationStatus.company.legalStructure &&
      _applicationStatus.company.legalStructure === 'S-Prop'
    ) {
      return 'declined-sp';
    }

    const revenue = _applicationStatus.company.annualRevenue;

    if (!revenue || BUSINESS_ANNUAL_REVENUE.indexOf(revenue) > 3) {
      return '';
    }

    try {
      const ficoScore = await flexbaseOnboardingClient.getFicoScore(
        productOnboardingStatus.user.id,
      );

      if (
        !ficoScore.score &&
        (!ficoScore.issues.length ||
          ficoScore.issues.some(
            (issue) =>
              issue.toLowerCase().includes('freeze') ||
              issue.toLowerCase().includes('frozen'),
          ))
      ) {
        return '';
      }
      return ficoScore.score < 700 ? 'declined-fs' : '';
    } catch (e) {
      return ''; // Error indicates frozen. (Apparently not anymore but I'm leaving this here..)
    }
  };

  return {
    refreshProductOnboardingStatus,
    navigateToNextProductStep,
    goBack,
    createOrUpdateCompany,
    product,
    currentProgress,
    getDeclineScreen,
  };
};
