import { Avatar, createStyles, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { intersection, uniq } from 'underscore';
import { MoneyMovement } from 'services/flexbase/banking.model';
import { formatInitials } from 'utilities/formatters/format-strings';
import PaymentStep from '../payment-step';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  newRecipientFlag,
  paymentCounterpartiesList,
  paymentRecipientName,
  paymentStep,
} from './payment.states';
import { PaymentSteps } from './send-payment';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { PlusSignIcon } from 'assets/svg';

const PaymentRecipient = () => {
  const { classes } = useStyles();
  // payment flow state
  const [counterparties] = useRecoilState(paymentCounterpartiesList);
  const setPaymentStepState = useSetRecoilState(paymentStep);
  const setNewRecipientFlag = useSetRecoilState(newRecipientFlag);

  const [recipientName, setRecipientName] =
    useRecoilState(paymentRecipientName);

  // local state
  const [frequentlyPaid, setFrequentlyPaid] = useState<string[]>([]);
  const [counterpartyNames, setCounterpartyNames] = useState<string[]>([]);
  const [, setPreviousPayments] = useState<MoneyMovement[]>([]);

  const init = async () => {
    setNewRecipientFlag(false);
    const { payments } = await flexbaseBankingClient.getMoneyMovements();
    setPreviousPayments(payments);

    const uniqueCounterparties = uniq(counterparties.map((c) => c.accountName));
    setCounterpartyNames(uniqueCounterparties);

    const allPaymentCounterpartyNames = payments.map((p) => p.payCtrParty);

    const paymentCounts = allPaymentCounterpartyNames.reduce<{
      [name: string]: number;
    }>((frequencyMap, name) => {
      frequencyMap[name] = frequencyMap[name] ? frequencyMap[name] + 1 : 1;
      return frequencyMap;
    }, {});

    // Payments can apparently include counterparties that won't be in the list so we need to only
    // get the names of those counterparties in both lists.
    const paymentCounterpartyNames = intersection(
      uniqueCounterparties,
      allPaymentCounterpartyNames,
    );

    const sorted = uniq(paymentCounterpartyNames)
      .sort((a, b) => {
        return paymentCounts[b] - paymentCounts[a];
      })
      .slice(0, 3);

    setFrequentlyPaid(sorted);
  };

  const compareAutocompleteNames = (first: string, second: string) => {
    const firstLower = first.toLowerCase();
    const secondLower = second.toLowerCase();

    if (firstLower.startsWith(recipientName.toLowerCase())) {
      return -1;
    }

    if (secondLower.startsWith(recipientName.toLowerCase())) {
      return 1;
    }

    return firstLower.localeCompare(secondLower);
  };

  useEffect(() => {
    init();
  }, [counterparties]);
  // previousPayments, counterparties
  return (
    <PaymentStep
      titleText="Who are you paying?"
      showNextButton={false}
      showBackButton={false}
    >
      <TextInput
        value={recipientName}
        onChange={(e) => setRecipientName(e.target.value)}
        styles={{
          input: { padding: 0, border: 'none' },
        }}
        style={{ width: '100%' }}
        data-testid={'name'}
        placeholder="Enter the recipient’s Legal Name"
      />
      <div className={classes.autoCompleteContainer}>
        {!recipientName && frequentlyPaid.length > 0 && (
          <>
            <div className={classes.frequentlyPaidText}>Frequently Paid</div>
            <div className={classes.frequentlyPaidContainer}>
              {frequentlyPaid.map((name, index) => (
                <RecipientRow
                  name={name}
                  onRowClick={() => {
                    setRecipientName(name);
                    setPaymentStepState(PaymentSteps.PAYMENT_METHOD);
                  }}
                  key={index}
                />
              ))}
            </div>
          </>
        )}
        {recipientName && (
          <>
            <div
              className={classes.newRecipient}
              onClick={() => {
                setRecipientName(recipientName);
                setNewRecipientFlag(true);
                setPaymentStepState(PaymentSteps.PAYMENT_METHOD);
              }}
            >
              <PlusSignIcon />
              <div
                className={classes.newRecipientText}
                data-testid={'pay-new-recipient'}
              >
                Pay someone new: {recipientName}
              </div>
            </div>
            <div className={classes.frequentlyPaidContainer}>
              {counterpartyNames
                .filter((name) =>
                  name.toLowerCase().includes(recipientName.toLowerCase()),
                )
                .sort((a, b) => compareAutocompleteNames(a, b))
                .map((name, index) => (
                  <RecipientRow
                    name={name}
                    onRowClick={() => {
                      setRecipientName(name);
                      setPaymentStepState(PaymentSteps.PAYMENT_METHOD);
                    }}
                    data-testid={`frequently-paid-${name}`}
                    key={index}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </PaymentStep>
  );
};

type RecipientRowProps = { name: string; onRowClick: () => void };

const RecipientRow = ({ name, onRowClick }: RecipientRowProps) => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.recipientRow}
      onClick={() => onRowClick()}
      data-testid={`frequently-paid-${name.toLowerCase()}`}
    >
      <Avatar>{formatInitials(name)}</Avatar>
      <div className={classes.name}>{name}</div>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  autoCompleteContainer: {
    marginTop: '12px',
    backgroundColor: '#F6F3EE80',
    borderRadius: '0px 0px 8px 8px',
    borderTop: `3px solid ${theme.fn.primaryColor()}`,
  },
  recipientRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
  name: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
  },
  frequentlyPaidText: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: `1px solid ${theme.fn.themeColor('neutral', 2)}`,
    padding: '8px 16px',
  },
  frequentlyPaidContainer: {
    marginTop: '16px',
  },
  newRecipient: {
    backgroundColor: '#F6F3EE80',
    borderRadius: '0px 0px 2px 2px',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    padding: '8px 16px',
    alignItems: 'center',
    cursor: 'pointer',
    svg: {
      color: theme.fn.themeColor('primarySecondarySuccess', 2),
      fill: theme.fn.themeColor('primarySecondarySuccess', 2),
    },
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
  newRecipientText: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 500,
  },
}));

export default PaymentRecipient;
