import {
  Alert,
  Checkbox,
  Container,
  createStyles,
  PasswordInput,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { FaExclamationCircle } from 'react-icons/fa';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import PasswordStrengthPopover from '../../../../areas/login/password-strength-popover';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { NewPasswordValidator } from '../../../../utilities/validators/validate-password';
import { useMediaQuery } from '@mantine/hooks';
import { TermsOfServiceCheckboxPrompt } from 'components/terms/prompt';
import { useProductOnboarding } from 'areas/onboarding-v2/onboarding-hooks';
import OnboardingStep from 'areas/onboarding-v2/components/onboarding-step';
import { selector, useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import { useOnboardingStyles } from 'areas/onboarding-v2/onboarding.styles';
import { Link } from 'react-router-dom';

type ConfigureAccountFormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  legalAge: boolean;
  flexbaseTos: boolean;
};

const useStyles = createStyles(() => ({
  checkboxBody: { alignItems: 'center' },
  disclosure: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

// Fetch and cache the latest credit Application and Solicitation Disclosure
const applicationDisclosureQuery = selector({
  key: 'application_disclosure',
  get: async () => {
    const documents = await flexbaseOnboardingClient.getTermsOfServiceDocs(
      'credit',
      false,
    );
    return documents.find(
      ({ label }) => label === 'Application and Solicitation Disclosure',
    );
  },
});

const ChangePasswordPage = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const applicationDisclosure = useRecoilValue(applicationDisclosureQuery);
  const { navigateToNextProductStep } = useProductOnboarding();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useRecoilValue(ApplicationState);
  const passwordForm = useForm<ConfigureAccountFormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      legalAge: false,
      flexbaseTos: false,
    },
    validate: {
      currentPassword: (value) =>
        value ? null : 'Current password is required.',
      newPassword: (value, allFormValues) => {
        if (!value) {
          return 'New password is required';
        }
        if (value === allFormValues.currentPassword) {
          return 'New password must be different from old password';
        }
        return NewPasswordValidator()(value);
      },
      confirmPassword: (value, allFormValues) =>
        value !== allFormValues.newPassword ? 'Passwords do not match' : null,
      flexbaseTos: (value) =>
        value ? null : 'You must accept the terms and conditions',
      legalAge: (value) => (value ? null : 'You must be of legal age'),
    },
  });

  const onNextClick = async () => {
    try {
      const validationResult = passwordForm.validate();

      if (!validationResult.hasErrors) {
        const { currentPassword, newPassword, flexbaseTos } =
          passwordForm.values;
        setLoading(true);
        const currentPasswordValid = await flexbaseClient.validatePassword(
          user.email,
          currentPassword,
        );
        if (!currentPasswordValid.token) {
          passwordForm.setFieldError(
            'currentPassword',
            'Current password is not valid. Use the password you used to login.',
          );
          return;
        }

        await flexbaseOnboardingClient.updateUser({
          termsOfServiceSigned: flexbaseTos,
        });

        const changePasswordResponse = await flexbaseClient.changePassword(
          newPassword,
        );

        if (!changePasswordResponse.success) {
          setError(
            'Something went wrong. Please try again or contact support if the issue persists.',
          );
          return;
        }

        navigateToNextProductStep();
      }
    } catch (error) {
      console.error('Unable to update the password', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <OnboardingStep
      title={'Welcome!'}
      stepId="change-password"
      subtitle={'Please get started by creating a secure password.'}
      showBack={false}
      error={error}
      onNextClick={onNextClick}
      showContinueSpinner={loading}
    >
      <Container p={0} size="xs">
        <Stack style={{ marginTop: 30 }}>
          <PasswordInput
            id="current-password"
            label="Temporary Password"
            required
            classNames={{
              label: onboardingClasses.inputLabel,
            }}
            {...passwordForm.getInputProps('currentPassword')}
          />
          <PasswordStrengthPopover inputValue={passwordForm.values.newPassword}>
            <PasswordInput
              label="New Password"
              required
              classNames={{
                label: onboardingClasses.inputLabel,
              }}
              {...passwordForm.getInputProps('newPassword')}
              id="new-password"
            />
          </PasswordStrengthPopover>
          <PasswordInput
            label="Confirm New Password"
            required
            classNames={{
              label: onboardingClasses.inputLabel,
            }}
            {...passwordForm.getInputProps('confirmPassword')}
            id="confirm-password"
          />
          <Checkbox
            mt={useMobileView ? '1.5rem' : '0.5rem'}
            {...passwordForm.getInputProps('legalAge')}
            id="checkbox-legal-age"
            label={<Text size="xs">I am at least 18 years old</Text>}
          />
          <TermsOfServiceCheckboxPrompt
            type="flexbase"
            size="sm"
            mt={15}
            {...passwordForm.getInputProps('flexbaseTos')}
            className={classes.checkboxBody}
          />
          <Stack pb={0} mt={useMobileView ? '1rem' : 'auto'} align="flex-end">
            {applicationDisclosure && (
              <Text className={classes.disclosure}>
                By clicking Continue you are agreeing to our{' '}
                <Link
                  to={applicationDisclosure.url}
                  target="_blank"
                  style={{ color: theme.fn.primaryColor() }}
                >
                  Application and Solicitation Disclosure
                </Link>
              </Text>
            )}
          </Stack>
          {error && (
            <Alert icon={<FaExclamationCircle />} color="red">
              {error}
            </Alert>
          )}
        </Stack>
      </Container>
    </OnboardingStep>
  );
};

export default ChangePasswordPage;
