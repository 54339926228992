import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    maxWidth: '1307.5px',
    margin: 'auto',
  },
  widgetContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    flexDirection: 'row',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    padding: '1.5rem',
    '@media(max-width: 767px)': {
      width: '100%',
      backgroundColor: 'unset',
      padding: 0,
    },
  },
  storeName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
  },
  storeIcon: {
    fontSize: '20px',
    color: theme.fn.primaryColor(),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: `${theme.colors.neutral[2]} !important`, // TODO change this shit
      fontWeight: 500,
      fontFamily: 'PP Neue Montreal',
    },
    marginRight: '10px',
  },
  storeLogo: {
    borderRadius: '34px',
    width: '32px',
    height: '32px',
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: '10px',
  },
  date: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#5F5F5F',
    lineHeight: '21px',
  },
  status: {
    padding: '4px 16px 4px 16px',
    background: '#EEEEF3',
    fontSize: '14px',
    fontWeight: 500,
    width: '105px',
    height: '32px',
    lineHeight: '24px',
    textAlign: 'center',
    borderRadius: '100px',
    color: 'black',
  },
  latestTransactions: {
    marginBottom: '8px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
  transactionRow: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',
  },
  transactionYearRow: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',
    borderRadius: '8px',
    marginTop: '24px',
    height: '76px',
  },
  text: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  showMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 4px',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    cursor: 'pointer',
    height: '50px',
  },
  closeIcon: {
    color: '#979797',
    cursor: 'pointer',
  },
}));
