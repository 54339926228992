import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  card: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '102px',
    borderRadius: '8px',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  integrationsLabel: {
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '20px',
    color: '#5F5F5F',
  },
  title: {
    fontSize: '21px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '25.2px',
    color: '#5F5F5F',
  },
  btnConnect: {
    background: '#FFF',
    width: '133px',
    height: '40px',
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderRadius: '8px',
    '&:hover': {
      background: 'lightgray',
    },
    marginRight: 10,
    padding: '0 12px 0 12px',
  },
  btnConnected: {
    pointerEvents: 'none',
    border: '1px solid rgba(0,0,0,0)',
  },
  connectText: {
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  connectedText: {
    color: '#27C281',
  },
}));
