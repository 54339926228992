import {
  ComponentProps,
  ExpandableRowsComponent,
} from 'react-data-table-component/dist/src/DataTable/types';
import SkeletonLoading from 'components/loading/skeleton-loading';
import DataTable, {
  ConditionalStyles,
  SortFunction,
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { baseTableStyles } from 'components/styles';
import { ArrowDownIcon } from 'assets/svg';
import { ReactNode } from 'react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

// DataTable,
interface FlexbaseTableProps {
  columns: TableColumn<any>[];
  data: any;
  title?: string;
  striped?: boolean;
  disabled?: boolean;
  sortFunction?: SortFunction<any>;
  selectableRows?: boolean;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  subHeader?: boolean;
  pagination?: boolean;
  customStyles?: TableStyles;
  highlightOnHover?: boolean;
  noDataComponent?: string | ReactNode;
  onRowClicked?: (row: any) => void;
  defaultSortAsc?: boolean;
  conditionalRowStyles?: ConditionalStyles<any>[];
  pointerOnHover?: boolean;
  selectableRowsComponent?: 'input' | React.ReactNode;
  selectableRowsComponentProps?: ComponentProps;
  onSelectedRowsChange?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void;
  clearSelectedRows?: boolean;
  dense?: boolean;
  noHeader?: boolean;
  noTableHead?: boolean;
  defaultSortFieldId?: string | number | null;
  withTabs?: boolean;
  isFetchingData?: boolean;
  errorMessage?: string;
  expandableRows?: boolean;
  expandableRowsComponent?: ExpandableRowsComponent<any>;
  expandOnRowClicked?: boolean;
}

export const FlexbaseTable = (props: FlexbaseTableProps) => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery('(max-width: 767px)');

  const customPaginationOptions = {
    paginationSize: 5,
    hidePageNumbers: true,
    hideFirstLastPages: true,
    rowsPerPageText: 'Rows/Pg',
    rangeSeparatorText: 'of',
    selectAllRowsItem: false,
    selectAllRowsItemSelectable: false,
  };

  return props.isFetchingData ? (
    <SkeletonLoading withTabs={props.withTabs || false}></SkeletonLoading>
  ) : (
    <DataTable
      paginationComponentOptions={useMobileView ? customPaginationOptions : {}}
      title={props.title}
      columns={props.columns}
      data={props.data}
      striped={props.striped || false}
      disabled={props.disabled}
      sortFunction={props.sortFunction}
      selectableRows={props.selectableRows}
      selectableRowsComponent={props.selectableRowsComponent}
      selectableRowsComponentProps={props.selectableRowsComponentProps}
      onSelectedRowsChange={props.onSelectedRowsChange}
      clearSelectedRows={props.clearSelectedRows}
      fixedHeader={props.fixedHeader}
      fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}
      subHeader={props.subHeader}
      pagination={props.pagination || true}
      customStyles={props.customStyles || baseTableStyles(theme)}
      highlightOnHover={props.highlightOnHover}
      noDataComponent={props.noDataComponent}
      onRowClicked={props.onRowClicked}
      defaultSortAsc={props.defaultSortAsc}
      sortIcon={
        <ArrowDownIcon
          style={{
            width: '12px',
            height: '12px',
            marginLeft: '4px',
          }}
          fill="#5F5F5F"
        />
      }
      conditionalRowStyles={props.conditionalRowStyles}
      dense={props.dense}
      noHeader={props.noHeader}
      noTableHead={props.noTableHead}
      pointerOnHover={props.pointerOnHover}
      defaultSortFieldId={props.defaultSortFieldId}
      expandableRows={props.expandableRows}
      expandableRowsComponent={props.expandableRowsComponent}
      expandOnRowClicked={props.expandOnRowClicked}
    />
  );
};
export default FlexbaseTable;
