import { forwardRef, useImperativeHandle, useState } from 'react';
import { Group, Radio, TextInput, createStyles, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';
import ValidateRoutingNumber from 'utilities/validators/validate-routing-number';
import { US_STATES } from 'states/business/constants';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { formatZip } from 'utilities/formatters/format-address';
import { paymentMethod, PayMethod, Recipient } from './payment.states';
import { useRecoilValue } from 'recoil';
import GooglePlacesSuggest from 'components/input/google-places-suggest-input';

type Props = {
  onFormValid: (formData: Recipient) => void;
  onFormError: () => void;
};

export type PaymentRecipientFormRef = {
  submitForm: () => Promise<Recipient | null>;
};

const PaymentRecipientForm = forwardRef<PaymentRecipientFormRef, Props>(
  ({ onFormValid, onFormError }: Props, ref) => {
    const { classes } = useStyles();
    const method = useRecoilValue(paymentMethod);
    const [searchAddress, setSearchAddress] = useState('');

    // one form representing both types of recipients.
    // ach recipient counterparty does not need address.
    // wire recipient counterparty does.
    const form = useForm<Recipient>({
      initialValues: {
        routingNumber: '',
        accountNumber: '',
        accountType: '',
        address: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        name: '',
        recipientTypePersonBusiness: '',
        type: method as PayMethod,
        nickName: '',
      },
      validate: {
        routingNumber: (value) =>
          ValidateRoutingNumber(value)
            ? null
            : 'Routing number is invalid. Must be 9 digits and match an existing bank routing number.',
        accountNumber: (value) =>
          validateRequired(value)
            ? null
            : 'A valid account number is required.',
        accountType: (value) => {
          // only required for ach
          if (method === 'ach') {
            return validateRequired(value)
              ? null
              : 'Please select an account type.';
          }
        },
        address: (value) => {
          if (method === 'wire') {
            return validateRequired(value) ? null : 'Please select an address.';
          }
        },
        city: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please add the city for the address.';
          }
        },
        state: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please select the state for the address.';
          }
        },
        postalCode: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please input a 5 digit postal code.';
          }
        },
        country: (value) => {
          if (method === 'wire') {
            return validateRequired(value)
              ? null
              : 'Please input a country code.';
          }
        },
      },
    });

    const setStreet = (street: string) => {
      setSearchAddress(street);
    };

    const setZip = (zip: string) => {
      form.setFieldValue('postalCode', formatZip(zip));
    };

    const setCountry = (country: string) => {
      form.setFieldValue('country', country);
    };

    const selectAddress = (item: {
      address: string;
      country: string;
      state: string;
      city: string;
      postalCode: string;
    }) => {
      setSearchAddress(item.address.trim());
      form.setFieldValue('address', item.address.trim());
      form.setFieldValue('city', item.city);
      form.setFieldValue('state', item.state);
      form.setFieldValue('postalCode', item.postalCode);
    };

    const setFormFieldNumber = (value: string, formField: keyof Recipient) => {
      if (value.length === 0) {
        form.setFieldValue(formField, '');
        return;
      }

      const regex = /\d+$/g;
      const matches = regex.test(value);

      if (matches) {
        form.setFieldValue(formField, value);
      }
    };

    const submit = async (): Promise<Recipient | null> => {
      const validationResult = form.validate();
      const addressValidationResult = form.validate(); // Seperate because it made things a bit easier

      if (validationResult.hasErrors || addressValidationResult.hasErrors) {
        return null;
      } else {
        // Perform extra validation on the routing number
        try {
          const validateRoutingPlaid =
            await flexbaseBankingClient.checkRoutingNumber(
              form.values.routingNumber,
            );
          if (
            !validateRoutingPlaid.success ||
            !validateRoutingPlaid.banks.length
          ) {
            form.setFieldError(
              'routingNumber',
              'Routing number is not recognized',
            );
            return null;
          }
        } catch (e) {
          form.setFieldError(
            'routingNumber',
            'Routing number could not be validated',
          );
          return null;
        }
        return form.values;
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        return submit();
      },
    }));

    return (
      <div style={{ marginBottom: '24px' }}>
        <TextInput
          label="Routing number"
          placeholder="Enter routing number"
          {...form.getInputProps('routingNumber')}
          maxLength={9}
          onChange={(e) => setFormFieldNumber(e.target.value, 'routingNumber')}
          data-testid="routingRumber"
          required
          c="neutral.8"
        />
        <TextInput
          className={classes.inputContainer}
          label="Account number"
          placeholder="Enter account number"
          {...form.getInputProps('accountNumber')}
          onChange={(e) => setFormFieldNumber(e.target.value, 'accountNumber')}
          data-testid="accountNumber"
          required
        />

        {method === 'ach' && (
          <>
            <Radio.Group
              mt={20}
              {...form.getInputProps('recipientTypePersonBusiness')}
              label="Recipient type"
              required
            >
              <Group mt={12}>
                <Radio value="Person" label="Person" />
                <Radio value="Business" label="Business" />
              </Group>
            </Radio.Group>

            <Select
              label="Account type"
              data={['Checking', 'Savings']}
              className={classes.inputContainer}
              {...form.getInputProps('accountType')}
              required
            />
          </>
        )}

        {method === 'wire' && (
          <>
            <div className={classes.sectionHeader}>
              Recipient&apos;s Legal Address
            </div>
            <GooglePlacesSuggest
              label="Address line 1"
              value={searchAddress}
              onChange={(value) => setStreet(value)}
              onItemSubmit={selectAddress}
              placeholder="Address"
              className={classes.inputContainer}
              id="input-search-address"
            />
            <TextInput
              mt={10}
              label="Address line 2"
              data-testid="address2"
              placeholder="Apt, suite, etc. (Optional)"
              {...form.getInputProps('addressLine2')}
            />
            <TextInput
              mt={10}
              label="City"
              data-testid="city"
              placeholder="City"
              required
              {...form.getInputProps('city')}
            />
            <Select
              mt={10}
              label="State"
              placeholder="State"
              data-testid="state"
              data={US_STATES}
              searchable
              maxDropdownHeight={400}
              nothingFound="No data"
              filter={(value, item) => {
                const lowerCaseValue = value.toLowerCase();
                return (
                  item.label?.toLowerCase().includes(lowerCaseValue) ||
                  item.value.toLowerCase().includes(lowerCaseValue)
                );
              }}
              {...form.getInputProps('state')}
              required
            />
            <TextInput
              mt={10}
              label="ZIP Code"
              data-testid="zip"
              placeholder="Zip Code"
              pattern={'[0-9]*'}
              required
              {...form.getInputProps('postalCode')}
              onChange={(e) => setZip(e.target.value)}
            />
            <TextInput
              mt={10}
              label="Country"
              data-testid="country"
              placeholder="US"
              pattern={'[0-9]*'}
              required
              {...form.getInputProps('country')}
              onChange={(e) => setCountry(e.target.value)}
            />
          </>
        )}
      </div>
    );
  },
);

const useStyles = createStyles({
  inputContainer: { marginTop: '24px' },
  sectionHeader: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '20px',
    color: '#000000',
    marginTop: '48px',
  },
  selectLabel: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
});

PaymentRecipientForm.displayName = 'PaymentRecipientForm';

export default PaymentRecipientForm;
