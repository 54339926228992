import {
  Button,
  createStyles,
  PasswordInput,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { merchantCallback } from 'utilities/url/get-bnpl-merchant-callback';
import PasswordStrengthPopover from './password-strength-popover';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { PendingUrlState } from 'states/pending-url/pending-url-state';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import { NewPasswordValidator } from '../../utilities/validators/validate-password';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from '../../states/application/product-onboarding';
import { useRouteSectionContext } from '../../components/routes/route-context';
import AuthContainer from 'components/login/auth-page';
import AuthContent from 'components/login/auth-content';
import AuthLoader from 'components/login/auth-loader';

type ConfigureAccountFormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const useStyles = createStyles((theme) => ({
  subheading: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    color: theme.fn.themeColor('neutral', 0),
  },
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.colors.critical[2],
  },
}));

const ChangePasswordPage = () => {
  const { classes } = useStyles();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { isBnpl, callbackParam, callbackBaseUrl, sessionParam, merchantName } =
    useRecoilValue(BnplState);
  const pendingUrl = useRecoilValue(PendingUrlState);
  const navigate = useNavigate();
  const passwordForm = useForm<ConfigureAccountFormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      currentPassword: (value) =>
        value ? null : 'Current password is required.',
      newPassword: (value, allFormValues) => {
        if (!value) {
          return 'New password is required';
        }
        if (value === allFormValues.currentPassword) {
          return 'New password must be different from old password';
        }
        return NewPasswordValidator()(value);
      },
      confirmPassword: (value, allFormValues) =>
        value !== allFormValues.newPassword ? 'Passwords do not match' : null,
    },
  });

  const [status, setStatus] = useRecoilState(ApplicationState);
  const { setShowRoutesFor } = useRouteSectionContext();

  const onCancel = () => {
    window.location.replace(
      merchantCallback(
        callbackBaseUrl,
        TransactionStatus.canceled,
        callbackParam,
        sessionParam,
      ),
    );
  };

  const onSubmit = async () => {
    const validationResult = passwordForm.validate();

    if (!validationResult.hasErrors) {
      const { currentPassword, newPassword } = passwordForm.values;
      setLoading(true);
      const currentPasswordValid = await flexbaseClient.validatePassword(
        status.user.email,
        currentPassword,
      );
      if (!currentPasswordValid.token) {
        passwordForm.setFieldError(
          'currentPassword',
          'Current password is not valid. Use the password you used to login.',
        );
        setLoading(false);
        return;
      }

      const changePasswordResponse = await flexbaseClient.changePassword(
        newPassword,
      );

      if (!changePasswordResponse.success) {
        setError(
          'Something went wrong. Please try again or contact support if the issue persists.',
        );
        setLoading(false);
        return;
      }

      showNotification({
        title: 'Success!',
        color: 'flexbase-teal',
        message: 'Password successfully changed!',
      });

      // This is to make sure we have the updated roles instead of removing it manually.
      let newStatus = await getProductOnboardingStatus();

      // TODO: This is a stop-gap solution to handle older users with no company. The application will explode if they don't have one
      if (!status.company?.id && status.user.roles.includes('ADMIN')) {
        await flexbaseOnboardingClient.createCompany({
          companyName: 'My Company',
          optedProducts: ['CREDIT'],
        });
        newStatus = await getProductOnboardingStatus();
      }

      setStatus(newStatus);
      if (!newStatus.completedOnboarding) {
        setShowRoutesFor('application');
      } else {
        setShowRoutesFor('main');
      }

      if (pendingUrl) {
        navigate(`${pendingUrl}`, { replace: true });
      }
    }
  };

  return (
    <AuthContainer>
      {loading && <AuthLoader />}
      <AuthContent
        errorMsg={error}
        onSubmit={onSubmit}
        screen="change-password"
        title="Change your password"
        submitButtonLabel="Change password"
        onGoback={() => navigation('/login')}
      >
        <Stack>
          <Text className={classes.subheading}>
            Your password is expired. Change it now.
          </Text>
          <PasswordInput
            id="current-password"
            placeholder="Current or temporary password"
            label="Current password"
            classNames={classes}
            {...passwordForm.getInputProps('currentPassword')}
          />
          <PasswordStrengthPopover inputValue={passwordForm.values.newPassword}>
            <PasswordInput
              label="New password"
              placeholder="New password"
              classNames={classes}
              {...passwordForm.getInputProps('newPassword')}
              id="new-password"
            />
          </PasswordStrengthPopover>
          <PasswordInput
            label="Confirm new password"
            placeholder="Confirm your new password"
            classNames={classes}
            {...passwordForm.getInputProps('confirmPassword')}
            id="confirm-password"
          />
          {isBnpl && (
            <Button
              id="change-password-cancel"
              variant={'subtle'}
              onClick={onCancel}
            >
              {`Back to ${merchantName || 'merchant'}`}
            </Button>
          )}
        </Stack>
      </AuthContent>
    </AuthContainer>
  );
};

export default ChangePasswordPage;
