import OnboardingStep from '../components/onboarding-step';
import { useState } from 'react';
import { useProductOnboarding } from '../onboarding-hooks';
import { useOnboardingStyles } from '../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import getPaddedAccountMask from '../../../utilities/formatters/get-padded-account-mask';
import { PlaidLogo2, RegularPlus } from 'assets/svg';
import {
  Box,
  Popover,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { usePlaidBankingComponent } from 'components/banking/plaid-banking-component';
import YellowBanner from '../components/yellow-banner';

const VerifyBank = () => {
  const theme = useMantineTheme();
  const { classes, cx } = useOnboardingStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {
    goBack,
    navigateToNextProductStep,
    refreshProductOnboardingStatus,
    createOrUpdateCompany,
  } = useProductOnboarding();
  const [{ company, requiredProperties }, setProductOnboardingState] =
    useRecoilState(ApplicationState);
  const [plaidLinked, setPlaidLinked] = useState(
    !requiredProperties.includes('user.plaidConnection') &&
      company.financialInstitutions?.length > 0,
  );
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => setError('Unable to link bank account'),
    setLoading,
  });

  const onLinkSuccess = async () => {
    setError('');
    setLoading(true);
    setPlaidLinked(true);
    try {
      const status = await refreshProductOnboardingStatus();
      if (!status.company?.financialInstitutions?.length) {
        setError(
          'We were unable to correctly configure your external accounts.',
        );
      } else if (status.company.financialInstitutions.length === 1) {
        setPrimaryAccount(status.company.financialInstitutions[0].id!);
      }
    } catch (e) {
      setError('Unable to refresh company data.');
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    if (plaidLinked && company.primaryPlaidAccount) {
      navigateToNextProductStep();
    } else if (!plaidLinked) {
      setError('Please link bank account with Plaid');
    } else if (!company.primaryPlaidAccount) {
      setError('Please designate a primary account');
    }
  };

  const onBack = () => {
    goBack();
  };

  const isPrimaryAccount = (accountId?: string): boolean => {
    return (
      !!company.primaryPlaidAccount &&
      !!accountId &&
      company.primaryPlaidAccount === accountId
    );
  };

  const setPrimaryAccount = async (accountId: string) => {
    setError('');
    const result = await createOrUpdateCompany({
      primaryPlaidAccount: accountId,
    });
    if (!result.success) {
      setError('Unable to set primary account');
    } else {
      setProductOnboardingState((prev) => ({
        ...prev,
        company: { ...prev.company, ...result.company },
      }));
    }
  };

  const onLinkBankClick = () => {
    if (ready) {
      setError('');
      open();
    }
  };

  const isTreasury = company.optedProducts.includes('TREASURY');

  const subtitle = isTreasury
    ? `Please link all institutions that you bank with so your treasury account will have full FDIC insurance.`
    : `Securely connect your business bank accounts so we can give you the maximum credit limit to grow your business.`;
  return (
    <OnboardingStep
      title="Bank Verification"
      stepId="verify-bank"
      subtitle={subtitle}
      image={
        <Box mb="xl">
          <PlaidLogo2 />
        </Box>
      }
      showContinueSpinner={loading}
      disableNext={!company.financialInstitutions?.length}
      error={error}
      onNextClick={onContinue}
      onBackClick={onBack}
      bottomDisclosureText={
        'Flexbase uses Plaid Inc. (“Plaid”) to gather your data from financial institutions for bank account and fraud verification. By using the site, you grant Flexbase and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from your relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the Plaid end user privacy policy.'
      }
    >
      {company.financialInstitutions?.map((f, i) => (
        <div
          className={cx(
            classes.card,
            classes.bottomSpacing,
            classes.bankAccountCard,
            isPrimaryAccount(f.id) && classes.selectedCard,
          )}
          key={i}
        >
          <div className={classes.cardTextContent}>
            <div className={cx(classes.flavorText, classes.unsetMargin)}>
              {f.bankName ? `${f.bankName} / ` : ''}
              {f.accountName}
            </div>
            <div
              className={cx(
                classes.flavorText,
                classes.largerFont,
                classes.unsetMargin,
              )}
            >
              {getPaddedAccountMask(f.account, 4)}
            </div>
          </div>
          {isPrimaryAccount(f.id) ? (
            <Text
              ml="auto"
              fz={12}
              fw={500}
              px="0.875rem"
              color={theme.fn.primaryColor()}
              id={`text-primary-account-${f.id}`}
            >
              Primary
            </Text>
          ) : (
            <UnstyledButton
              variant="subtle"
              fz={12}
              w={100}
              ml={'auto'}
              c="something.2"
              onClick={() => setPrimaryAccount(f.id!)}
              id={`button-set-primary-account-${i}`}
            >
              Make Primary
            </UnstyledButton>
          )}
        </div>
      ))}
      {company.financialInstitutions.length > 0 ? (
        <UnstyledButton
          variant="subtle"
          onClick={() => onLinkBankClick()}
          className={cx(!ready && classes.disabled)}
        >
          <Text ta="right" fz="sm" c="something.2">
            + Connect another account
          </Text>
        </UnstyledButton>
      ) : (
        <Box
          mt="16px"
          mb="sm"
          w="100%"
          onClick={() => onLinkBankClick()}
          className={classes.addButton}
          id="button-link-bank-account"
        >
          <RegularPlus /> Connect a bank account
        </Box>
      )}
      {!company.financialInstitutions?.length && (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Popover width={350} position="bottom-end" withArrow shadow="md">
            <Popover.Target>
              <UnstyledButton variant="subtle">
                <Text ta="right" fz="sm" td="underline" c="something.2">
                  What is this?
                </Text>
              </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown>
              <Text size="sm">
                By connecting your bank account, you&apos;re allowing Flexbase
                to view your account balance history. We&apos;ll use this
                information solely for the purpose of evaluating your
                creditworthiness and will not share it with third parties.
              </Text>
            </Popover.Dropdown>
          </Popover>
        </div>
      )}
      <YellowBanner text="Connect all business bank accounts to maximize your initial credit limit for the Flexbase credit card. Account balances significantly impact your limit." />
      <div className={cx(classes.flavorText)}>
        {isTreasury
          ? 'Please designate a primary bank account that will be used for all deposits and withdrawals from your treasury account.'
          : ``}
      </div>
    </OnboardingStep>
  );
};

export default VerifyBank;
