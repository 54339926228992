import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    color: '#fff',
    padding: '45px',
    marginBottom: '40px',
    backgroundColor: theme.fn.primaryColor(),
    '@media(max-width: 767px)': {
      padding: '20px',
      marginBottom: '20px',
    },
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding: {
    padding: '0px 50px',
    '@media(max-width: 767px)': {
      padding: '0px 20px',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  alingEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  switch: {
    marginTop: 20,
    marginBottom: 20,
  },
  transactionWidget: {
    borderRadius: theme.defaultRadius,
    '@media(max-width: 767px)': {
      minWidth: 'auto',
    },
  },

  accountDetailsContainer: {
    paddingBottom: 50,
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: 20,
    },
  },

  accountDetailsName: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '32px',
    color: theme.fn.themeColor('neutral', 0),
  },

  accountDetailsType: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    color: theme.fn.themeColor('neutral', 0),
  },

  accountDetailsLabel: {
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '20px',
    lineHeight: '20px',
    marginBottom: '5px',
    color: theme.fn.primaryColor(),
  },

  accountDetailsInput: {
    fontSize: '14px',
    padding: '10px 20px',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.colors.neutral[0],
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },

  buttonsContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 10,
  },

  actionConent: {
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    svg: {
      marginRight: '10px',
    },
    '&:hover': {
      color: theme.fn.themeColor('primarySecondarySuccess', 2),
      svg: {
        color: theme.fn.themeColor('primarySecondarySuccess', 2),
      },
    },
  },

  menuItem: {
    fontSize: '14px',
    color: '#5F5F5F',
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.08)',
    },
  },

  menuDropdown: {
    cursor: 'pointer',
  },

  menuLabel: {
    gap: '10px',
    display: 'flex',
    fontWeight: 400,
    fontSize: '14px',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.fn.primaryColor(),
  },

  editIcon: {
    cursor: 'pointer',
    marginRight: '2px',
  },
}));
