import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import { NavTab } from 'components/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import {
  ApplicationState,
  IsAccountant,
  IsAdmin,
} from 'states/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import NoAccountPage from './no-account/no-account.page';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import AlertWithLink from '../../components/alerts/alert-with-link';
import { NavTabContainer } from '../../components/tabs/nav-tab-container';
import { Outlet } from 'react-router-dom';

const adminTabs = [
  { label: 'Overview', route: '/cards/overview' },
  { label: 'Credit Cards', route: '/cards/details' },
  { label: 'Credit Payment', route: '/cards/pay' },
  { label: 'Statements', route: '/cards/statements' },
];

const tabs: NavTab[] = [
  { label: 'Overview', route: '/cards/overview' },
  { label: 'Credit cards', route: '/cards/details' },
];

const Credit = () => {
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const onboardingState = useRecoilValue(ApplicationState);

  const notApproved =
    (onboardingState.productStatus.credit.status === 'incomplete' ||
      onboardingState.productStatus.credit.status === 'pending') &&
    !onboardingState.productStatus.credit.creditLimit;
  const unqualified =
    onboardingState.productStatus.credit.status === 'unqualified' &&
    !onboardingState.productStatus.credit.creditLimit;
  const showOverlay = notApproved || unqualified;

  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Credit'} />
      {onboardingState.company.optedProducts?.includes('CREDIT') === false ? (
        <NoAccountPage
          client={flexbaseOnboardingClient}
          optedProducts={onboardingState.company.optedProducts}
        />
      ) : (
        <>
          {showOverlay && (
            <AlertWithLink
              message={
                notApproved
                  ? 'Your credit application is still pending.'
                  : 'Check back for business banking, coming soon!'
              }
            />
          )}
          <div
            style={
              showOverlay ? { pointerEvents: 'none', opacity: 0.5 } : undefined
            }
          >
            <NavTabContainer tabs={isAdmin || isAccountant ? adminTabs : tabs}>
              <Outlet />
            </NavTabContainer>
          </div>
        </>
      )}
    </FlexbaseMainLayout>
  );
};

export default Credit;
