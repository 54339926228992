import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  autoPayContainer: {
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    minHeight: '229px',
    height: '100%',
    width: '100%',
    maxWidth: '620px',
    position: 'relative',
  },
  autoPaySettings: {
    borderColor: '#c8d6e5',
    width: '100%',
    padding: '1.5rem',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '30px',
    color: '#5F5F5F',
  },
  cashBackText: {
    fontSize: '14px',
    color: '#979797',
  },
  bankBalance: {
    fontSize: '12px',
    color: '#979797',
  },
  dateAmountDue: {
    fontSize: '26px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: 'black',
    marginBottom: '30px',
  },
  unlinkedPlaidAccount: {
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: theme.fn.primaryColor(),
    marginBottom: '30px',
  },
  accountContainer: {
    width: 580,
    height: 79,
    marginTop: 8,
    marginBottom: 13,
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
}));
