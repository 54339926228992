/**
 * Function to only the display the last four digits of any account number (banking, credit card, etc)
 *
 * @param accountNumber
 */
export const maskSensitiveAccountNumber = (accountNumber: string) => {
  return accountNumber.replace(/.(?=.{4})/g, '•');
};

/**
 * Format string to display Customer Name + masked Account Number
 * @param customerFirstName
 * @param customerLastName
 * @param accountNumber
 */
export const formatCustomerAndAccountNumber = (
  customerFirstName: string,
  customerLastName: string,
  accountNumber: string,
) => {
  const maskedAccountNumber = maskSensitiveAccountNumber(accountNumber);
  return `${customerFirstName} ${
    customerLastName[0]
  }. ${maskedAccountNumber.substring(
    maskedAccountNumber.length - 7,
    maskedAccountNumber.length,
  )}`;
};

/**
 * Extract ToFrom patron name from the transaction summary
 *
 * @param summary
 */
export const extractToFrom = (summary: string) => {
  if (summary.includes('|')) {
    return summary.split('|')[0].trim();
  }
  return summary;
};
