import { Autocomplete, AutocompleteItem, Group } from '@mantine/core';
import { useState } from 'react';
import { AddressAutoCompleteItem } from './address-auto-complete-item';
import { useDebouncedCallback } from 'use-debounce';
import flexbaseClient from 'services/flexbase-client';
import { customMantineStyles } from './styles';
import FlexbaseInput from 'components/input/flexbase-input';

// TODO: Refactor to avoid passing in the form hook
interface AddressComponentProps {
  theForm: any;
  personalProfile?: boolean;
}

const FlexbaseAddressAutoComplete = ({
  theForm,
  personalProfile,
}: AddressComponentProps) => {
  const [addresses, setAddresses] = useState<any>([]);
  const debounced = useDebouncedCallback((value) => getAddresses(value), 500, {
    maxWait: 1000,
    leading: true,
  });

  const setStreet = (street: string) => {
    theForm.setFieldValue('line1', street);
    debounced(street);
  };

  const getAddresses = (street: string) => {
    flexbaseClient.getAddressPreview(street).then((x) => {
      setAddresses(
        x.map((y) => {
          return {
            value: y.street1 + y.city + y.postalCode,
            label: y.street1,
            description: `${y.city} ${y.state} ${y.postalCode}`,
            addr: y,
          };
        }),
      );
    });
  };

  const selectAddress = (item: AutocompleteItem) => {
    const addr = item['addr'];
    theForm.setFieldValue('line1', addr.street1.trim());
    theForm.setFieldValue('line2', addr.street2?.trim() || '');
    theForm.setFieldValue('city', addr.city);
    theForm.setFieldValue('state', addr.state);
    theForm.setFieldValue('postalCode', addr.postalCode);
  };

  return (
    <>
      <Autocomplete
        label="Address"
        required
        {...theForm.getInputProps('line1')}
        data={addresses}
        onChange={(value) => setStreet(value)}
        onItemSubmit={selectAddress}
        itemComponent={AddressAutoCompleteItem}
        styles={customMantineStyles}
      />
      <FlexbaseInput
        style={{ marginTop: personalProfile ? 21 : 0 }}
        label="Address 2"
        {...theForm.getInputProps('line2')}
      />
      <FlexbaseInput
        style={{ marginTop: personalProfile ? 21 : 0 }}
        label="City"
        required
        {...theForm.getInputProps('city')}
      />
      {personalProfile ? (
        <>
          <FlexbaseInput
            style={{ marginTop: 21 }}
            label="State"
            required
            {...theForm.getInputProps('state')}
          />
          <FlexbaseInput
            style={{ marginTop: 21 }}
            label="Zip Code"
            required
            {...theForm.getInputProps('postalCode')}
          />
        </>
      ) : (
        <Group position="apart" grow>
          <FlexbaseInput
            label="State"
            required
            {...theForm.getInputProps('state')}
          />
          <FlexbaseInput
            label="Zip Code"
            required
            {...theForm.getInputProps('postalCode')}
          />
        </Group>
      )}
    </>
  );
};

export default FlexbaseAddressAutoComplete;
