export const PRODUCT_ONBOARDING_BASE_ROUTE = '/application';

export enum ProductOnboardingRoutes {
  CHANGE_PASSWORD = 'change-password',
  MULTI_PRODUCT_SELECT = 'product-select',
  VERIFY_PHONE = 'verify-phone',
  PERSONAL_ADDRESS = 'user-address',
  VERIFY_IDENTITY = 'verify-identity',
  BUSINESS_TYPE = 'business-type',
  BUSINESS_ACTIVITY = 'business-activity',
  VERIFY_BUSINESS = 'verify-business',
  BENEFICIAL_OWNERS = 'business-owners',
  VERIFY_BANK = 'verify-bank',
  SUMMARY = 'summary',
  END = 'end',
  TREASURY_CASH = 'treasury-info',
  DOC_UPLOAD = 'doc-upload',
}
