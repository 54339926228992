import { Route, Routes } from 'react-router';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { getEnvironment } from 'utilities/url/window-helpers';
import OnboardingHost2 from 'areas/onboarding-v2/pages/onboarding-host.page';
import OnboardingStepperPage2 from 'areas/onboarding-v2/pages/onboarding-stepper.page';
import LoginPage from 'areas/login/auth.page';
import LegalPage from 'areas/legal/legal.page';
import Bnpl from 'areas/bnpl/bnpl';
import SettingsTab from 'areas/settings/settings';
import CompanySettings from 'areas/company-settings/company-settings';
import AuthorizedRoutes from './components/auth/authorized-routes';
import NavbarAppShell from './components/navbar/navbar-app-shell';
import Banking from 'areas/banking/banking';
import { LoadingOverlay } from '@mantine/core';
import { useSetRecoilState } from 'recoil';
import PasswordResetPage from 'areas/login/password-reset.page';
import MagicLinkPage from 'areas/login/magic-link.page';
import Credit from 'areas/credit/credit';
import Patrons from 'areas/patrons/patrons';
import Projects from 'areas/projects/projects';
import Overview from 'areas/credit/tabs/overview/overview';
import Cards from 'areas/cards/credit-cards';
import CreditPaymentTab from 'areas/credit/tabs/credit-payment/credit-payment';
import { Navigate, useSearchParams } from 'react-router-dom';
import PeopleProjects from 'areas/people-projects/people-projects';
import PDFMonthlyTransactionReport from 'areas/cards/statements/pdf-monthly-transaction-report/monthly-transaction-report';
import Statements from 'areas/cards/statements';
import OnboardedRoutes from './components/auth/onboarded-routes';
import Notifications from 'areas/company-settings/notifications/notifications';
import ChangePasswordPage from 'areas/login/change-password.page';
import Integrations from 'areas/company-settings/integrations/integrations';
import TeamMembers from 'areas/team-members/team-members';
import ConfirmationReceipt from 'areas/credit/repayment/confirmation-receipt-pdf';
import UserSettingsList from 'areas/company-settings/personal-profile/user-settings-list';
import CompanySettingsList from 'areas/company-settings/company-settings-list';
import StartScreen from 'areas/onboarding-v2/steps/start-screen/start-screen';
import PaymentDashboard from 'areas/payments/dashboard';
import OutgoingPayments from 'areas/payments/outgoing-payments';
import Recipients from 'areas/payments/recipients';
import IncomingPayments from 'areas/payments/incoming-payments';
import SendPaymentPage from 'areas/payments/components/send-payment/send-payment.page';
import DocumentManagement from 'areas/document-management/document-management';
import PaymentRequestPDF from 'areas/payments/components/payment-request-pdf/payment-request-pdf';
import {
  PRODUCT_ONBOARDING_BASE_ROUTE,
  ProductOnboardingRoutes,
} from 'areas/onboarding-v2/onboarding.constants';
import EndPage from 'areas/onboarding-v2/pages/end.page';
import Accounting from 'areas/company-settings/accounting/accounting';
import QbooksLoginResponse from 'areas/company-settings/accounting/qbooks-login-response-page';
import CashManagement from 'areas/cash-management';
import PDFRiskStatementViewer from 'areas/cash-management/statements/pdf-statement/pdf-statement-viewer';
import Vendors from 'areas/people-projects/vendors/vendors';
import TreasuryDashboard from 'areas/cash-management/dashboard/treasury-dashboard';
import TreasuryManagementTabContainer from 'areas/cash-management/treasury-management-tab-container';
import FlexTreasury from 'areas/cash-management/flex-treasury/flex-treasury';
import OpenBankAccount from 'areas/cash-management/open-bank-account/open-bank-account';
import Loadable from './components/loading/loadable';
import BnplRoute from './components/auth/bnpl-route';
import Customers from 'areas/customers/customers';
import MoveTreasuryFunds from 'areas/cash-management/move-funds/move-funds-treasury';
import MoveFundsActivities from 'areas/cash-management/move-funds/move-funds-activities';
import { useEffect, useState } from 'react';
import UserAgreements from 'areas/company-settings/terms-of-service/user-agreements';
import ErrorEndScreen from 'areas/onboarding-v2/pages/end-states/error-end-screen';
import DeclineShortCircuit from 'areas/onboarding-v2/pages/end-states/declined/declined-short-circuit';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from './states/application/product-onboarding';
import DeclinedBadFico from './areas/onboarding-v2/pages/end-states/declined/declined-bad-fico';
import DeclinedSoleProp from './areas/onboarding-v2/pages/end-states/declined/declined-sp';
import PDFWireInstructionsViewer from 'areas/banking/components/wire-instructions/wire-instructions-pdf';
import PDFWireReceiptViewer from 'areas/payments/components/wire-receipt/wire-receipt-pdf';
import { useAuthToken } from './states/auth/auth-token';
import { useRouteSectionContext } from './components/routes/route-context';
import AddFunds from 'areas/banking/add-funds/add-funds.page';
import { ReferralRoute } from './components/routes/referral-route';
import useAnalyticsPageView from 'services/analytics/use-analytics-page-view';
import useGA4 from 'services/ga4/use-ga';
import { analytics } from 'app';

const OnboardingRequiredRoutes = () => {
  const featureName = 'banking_v1';
  const attributes = {
    hostEnv: getEnvironment(),
  };

  return (
    <Route element={<OnboardedRoutes />}>
      <Route
        path="/credit/monthly-transaction-report/:after/:before"
        element={
          <Loadable>
            <PDFMonthlyTransactionReport />
          </Loadable>
        }
      />
      <Route
        path="/accounts/risk-statement"
        element={
          <Loadable>
            <PDFRiskStatementViewer />
          </Loadable>
        }
      />
      <Route
        path="/banking/wire-instructions/:accountId"
        element={<PDFWireInstructionsViewer />}
      />
      <Route
        path="/payment/wire-receipt/:paymentId"
        element={<PDFWireReceiptViewer />}
      />
      <Route
        path="/payment/card-receipt/:paymentId"
        element={<ConfirmationReceipt />}
      />
      <Route path="/qbooks-loggedin" element={<QbooksLoginResponse />} />
      <Route path="/payment-request-pdf" element={<PaymentRequestPDF />} />
      <Route element={<NavbarAppShell />}>
        <Route path="/" element={<Credit />}>
          <Route index element={<Navigate to="/cards/overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="details" element={<Cards />} />
          <Route path="pay" element={<CreditPaymentTab />} />
          <Route path="statements" element={<Statements />} />
        </Route>
        <Route path="/credit/pay" element={<Bnpl />} />
        <Route path="/payments" element={<PaymentDashboard />}>
          <Route index element={<Navigate to="/payments/outgoing" />} />
          <Route path="outgoing" element={<OutgoingPayments />} />
          <Route path="outgoing/recipient" element={<SendPaymentPage />} />
          <Route path="incoming" element={<IncomingPayments />} />
          <Route path="recipients" element={<Recipients />} />
        </Route>
        <Route path="/projects" element={<Projects />} />
        <Route path="/settings" element={<SettingsTab />} />
        <Route path="/cards" element={<Credit />}>
          <Route index element={<Navigate to="/cards/overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route path="details" element={<Cards />} />
          <Route path="pay" element={<CreditPaymentTab />} />
          <Route path="statements" element={<Statements />} />
        </Route>
        <Route path="/documents" element={<DocumentManagement />} />
        <Route path="/team" element={<TeamMembers />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/people-projects" element={<PeopleProjects />} />
        <Route path="/accounts" element={<CashManagement />}>
          <Route index element={<Navigate to={'/accounts/dashboard'} />} />
          <Route path="dashboard" element={<TreasuryDashboard />} />
          <Route element={<TreasuryManagementTabContainer />}>
            <Route path="treasury" element={<FlexTreasury />} />
            <Route path="open-account" element={<OpenBankAccount />} />
            <Route path="documents" element={<Statements />} />
            <Route path="move-funds" element={<MoveTreasuryFunds />} />
            <Route path="activities" element={<MoveFundsActivities />} />
          </Route>
          <Route path="*" element={<Navigate to={'/accounts/dashboard'} />} />
        </Route>
        <Route path="/banking">
          <Route index element={<Navigate to={'/banking/accounts'} />} />
          <Route path="add-funds" element={<AddFunds />} />
          <Route
            path=":tab"
            element={
              <SplitTreatments names={[featureName]} attributes={attributes}>
                {({ treatments, isReady }) => {
                  if (isReady) {
                    const { treatment } = treatments[featureName];
                    if (treatment === 'on') {
                      return <Banking />;
                    }
                    return <div />;
                  } else {
                    return <div />;
                  }
                }}
              </SplitTreatments>
            }
          />
        </Route>
        <Route path="/patrons" element={<Patrons />} />
        <Route path="/settings" element={<CompanySettings />}>
          <Route index element={<Navigate to="/settings/profile" />} />
          <Route path="company-profile" element={<CompanySettingsList />} />
          <Route path="profile" element={<UserSettingsList />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="accounting" element={<Accounting />} />
          <Route path="user-agreements" element={<UserAgreements />} />
        </Route>
        {(attributes.hostEnv === 'local' ||
          attributes.hostEnv === 'development') && (
          <Route path="/people-projects" element={<PeopleProjects />}>
            <Route index element={<Navigate to="/people-projects/vendors" />} />
            <Route path="vendors" element={<Vendors />} />
          </Route>
        )}
      </Route>
      <Route path="*" element={<Navigate to={'/'} replace={true} />} />
    </Route>
  );
};

const AuthorizationRequiredRoutes = () => {
  return (
    <Route element={<AuthorizedRoutes />}>
      <Route
        path="*"
        element={
          <Navigate to={`${PRODUCT_ONBOARDING_BASE_ROUTE}`} replace={true} />
        }
      />
      <Route
        path="/onboarding"
        element={
          <Navigate to={`${PRODUCT_ONBOARDING_BASE_ROUTE}`} replace={true} />
        }
      />
      <Route path={PRODUCT_ONBOARDING_BASE_ROUTE} element={<OnboardingHost2 />}>
        <Route
          index
          element={
            <Navigate
              to={`${PRODUCT_ONBOARDING_BASE_ROUTE}/${ProductOnboardingRoutes.MULTI_PRODUCT_SELECT}`}
            />
          }
        />
        <Route path=":step" element={<OnboardingStepperPage2 />} />
        {/* NOTE: These end states are outside the <EndPage/> because that currently runs a bunch of logic to finish an app*/}
        <Route
          path={`${ProductOnboardingRoutes.END}/declined-short-circuit`}
          element={<DeclineShortCircuit />}
        />
        <Route
          path={`${ProductOnboardingRoutes.END}/declined-fs`}
          element={<DeclinedBadFico />}
        />
        <Route
          path={`${ProductOnboardingRoutes.END}/declined-sp`}
          element={<DeclinedSoleProp />}
        />
        <Route path={`${ProductOnboardingRoutes.END}`} element={<EndPage />} />
      </Route>
    </Route>
  );
};

const NoAuthRoutes = (noAuthUrl: string) => {
  return (
    <>
      <Route path="/register" element={<StartScreen />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/error" element={<ErrorEndScreen />} />
      <Route path="/reset-password" element={<PasswordResetPage />} />
      <Route path="/forgot-password" element={<MagicLinkPage />} />
      <Route path="/magic-login" element={<PasswordResetPage />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
      <Route path="/" element={<Navigate to={noAuthUrl} replace={true} />} />
      <Route path="*" element={<Navigate to={noAuthUrl} replace={true} />} />
      <Route path="/declined-short-circuit" element={<DeclineShortCircuit />} />
      <Route path="/referral/:code" element={<ReferralRoute />} />
    </>
  );
};

function FlexbaseRoutes() {
  // analytics - DONT REMOVE
  const hostEnv = getEnvironment();
  useAnalyticsPageView(hostEnv);
  useGA4();
  const [searchParams] = useSearchParams();
  const segmentAnon = searchParams.get('segment_anon');
  if (segmentAnon) {
    analytics
      .setAnonymousId(segmentAnon)
      .then(() => {
        console.log(`segment anon set ${segmentAnon}`);
      })
      .catch((err) => {
        console.log(`segment anon error ${err}`);
      });
  }

  const [loading, setLoading] = useState(true);
  const setUserAndCompanyInfo = useSetRecoilState(ApplicationState);
  const { tokenIsValid, clearToken, userEmail } = useAuthToken();
  const { showRoutesFor, setShowRoutesFor } = useRouteSectionContext();

  const loadState = async () => {
    if (tokenIsValid()) {
      try {
        const status = await getProductOnboardingStatus(false);
        setUserAndCompanyInfo(status);
        if (status.completedOnboarding) {
          setShowRoutesFor('main');
        } else {
          setShowRoutesFor('application');
        }
      } catch (e) {
        setShowRoutesFor('auth');
        clearToken();
      } finally {
        setLoading(false);
      }
    } else {
      setShowRoutesFor('auth');
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadState();
  }, []);

  return (
    <div>
      <LoadingOverlay visible={loading} style={{ position: 'fixed' }} />
      {!loading && (
        <Routes>
          {showRoutesFor === 'main' && OnboardingRequiredRoutes()}
          {showRoutesFor === 'application' && AuthorizationRequiredRoutes()}
          {showRoutesFor === 'auth' &&
            NoAuthRoutes(
              userEmail || sessionStorage.getItem('logout')
                ? '/login'
                : '/register',
            )}
          <Route path="/legal" element={<LegalPage />} />
          <Route
            path="/credit/pay"
            element={
              <BnplRoute>
                <Bnpl />
              </BnplRoute>
            }
          />
        </Routes>
      )}
    </div>
  );
}

export default FlexbaseRoutes;
