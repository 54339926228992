import { useEffect, useState } from 'react';
import { Box, Text } from '@mantine/core';
import { statementsStyles } from './styles';
import FlexbaseTable from 'components/table/flexbase-table';
import flexbaseClient from 'services/flexbase-client';
import Select from 'components/select/flexbase-select';
import { generateStatementDates } from 'utilities/dates/dates';
import { DateTime } from 'luxon';
import { DownloadIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';

interface IDownloadStatement {
  target: string;
  after?: string;
  before?: string;
  type?: string;
}

interface IStatements {
  date: string;
  target: string;
}

const Statements = () => {
  const { cx, classes } = statementsStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [loading, setLoading] = useState(true);
  const [statements, setStatements] = useState<IStatements[]>([]);
  const [filterDate, setFilterDate] = useState('');
  const [totalYears, setTotalYears] = useState<string[]>([]);

  const downloadPDF = (data: IDownloadStatement) => {
    window.open(
      `/credit/monthly-transaction-report/${data.after}/${data.before}`,
    );
  };

  const sortDate = (a: any, b: any) => {
    return (
      DateTime.fromSQL(a.target).toMillis() -
      DateTime.fromSQL(b.target).toMillis()
    );
  };

  const columns = [
    {
      name: (
        <Text size="13px" fw={500}>
          Month
        </Text>
      ),
      cell: (statement: any) => {
        return (
          <>
            {statements.length === 0 ? (
              <div className={classes.rectangleRounded}></div>
            ) : (
              <div>{statement.date}</div>
            )}
          </>
        );
      },
      sortFunction: sortDate,
      selector: (statement: { target: string }) => statement.target,
      sortable: true,
      width: useMobileView ? 'auto' : '80%',
    },
    {
      name: (
        <Text className={classes.pdfTableHeader} size="13px" fw={500}>
          PDF
        </Text>
      ),
      cell: (statement: any) => {
        return (
          <>
            {statements.length === 0 ? (
              <div className={classes.contentCenter}>
                <Box className={classes.rectangleRounded}></Box>
              </div>
            ) : (
              <div
                onClick={() =>
                  downloadPDF({
                    target: statement.target,
                    after: statement.after,
                    before: statement.before,
                    type: statement.type,
                  })
                }
                className={classes.statementRow}
              >
                <DownloadIcon />
                <Text ml="0.5rem">Download</Text>
              </div>
            )}
          </>
        );
      },
      width: useMobileView ? 'auto' : '20%',
    },
  ];

  const filterDates = async (newDate: string, newStatemets: IStatements[]) => {
    if (newDate !== '') {
      const result = (newStatemets || []).filter(
        (item: { date: string; target: string }) => {
          if (newDate === item.target.slice(0, 4)) {
            return item;
          }
        },
      );
      setStatements(result.reverse());
    } else {
      setStatements(newStatemets.reverse());
    }
  };

  const getStatements = async (): Promise<IStatements[]> => {
    try {
      setLoading(true);
      const { company } = await flexbaseClient.getCompanyData();
      if (company) {
        const generatedDates = generateStatementDates(
          company['createdAt'],
          'statements',
        );
        // Need to put this side effect here to make the code work.
        setTotalYears(generatedDates.totalYears);

        return generatedDates.statements;
      }

      return [];
    } catch (error) {
      console.error('Unable to get company data', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const getAvailabilityMessage = () => {
    const currentDate = DateTime.local();
    const currentDay = currentDate.day;
    let availabilityDate = currentDate.set({ day: 15 });

    if (currentDay >= 1) {
      availabilityDate = currentDate.plus({ months: 1 }).startOf('month');
    }

    return (
      <>
        <Text align="center">Your first statement will be </Text>
        <Text align="center">
          available after the 1st of {availabilityDate.toFormat('MMMM')}
        </Text>
      </>
    );
  };

  useEffect(() => {
    try {
      setLoading(true);
      getStatements().then((result) => {
        const maxYear = result.reduce(
          (max, item) => Math.max(max, DateTime.fromISO(item.target).year),
          -Infinity,
        );

        const selectedFilter = filterDate
          ? filterDate.toString()
          : maxYear.toString();
        setFilterDate(selectedFilter);
        filterDates(selectedFilter, result);
      });
    } finally {
      setLoading(false);
    }
  }, [filterDate]);

  return (
    <div className={classes.container}>
      <div className={classes.containerSelect}>
        <Select
          className={cx(statements.length === 0 && classes.blurryTextStyle)}
          value={filterDate}
          data={totalYears}
          placeholder="Filter by year"
          onChange={(value) => setFilterDate(value!)}
          disabled={statements.length === 0}
        />
      </div>

      {statements.length === 0 && !loading ? (
        <Box pos="relative" mih={'500px'}>
          <Box className={classes.filterStyle}>
            <div style={{ margin: 'auto' }}>
              <FlexbaseTable
                data={[1, 2, 3, 4, 5, 6, 7]}
                columns={columns}
                pagination={false}
                disabled
              />
            </div>
          </Box>
          <div className={classes.containerMessageNoData}>
            <Text className={classes.noDataTitle}>No statements yet</Text>
            <Box color="#5f5f5f">{getAvailabilityMessage()}</Box>
          </div>
        </Box>
      ) : (
        <>
          <div style={{ margin: 'auto' }}>
            <FlexbaseTable
              data={statements}
              columns={columns}
              noDataComponent={'There are no statements to display'}
              pagination={statements.length > 8}
              isFetchingData={loading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Statements;
