import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import FlexbaseTable from 'components/table/flexbase-table';

import { ExpanderComponentProps } from 'react-data-table-component';
import { BankSnapshot } from '../treasury-management.state';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Menu, Stack, Text, useMantineTheme } from '@mantine/core';
import { useStyles } from '../styles';
import { EditIcon } from 'assets/svg';
import { LinkIcon, PlaidIcon, UnlinkIcon } from 'components/icons/circle-icons';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import AvatarLogoCell from 'components/table/cells/avatar-logo-cell';
import MenuCell from 'components/table/cells/menu-cell';
import TableContainerWithSearchFilter from 'components/table/table-container';

// TODO: Make a generic box component that allows children to be set, add click for transfer funds
export const ExpandedComponent: React.FC<
  ExpanderComponentProps<BankSnapshot>
> = ({ data }) => {
  const { classes } = useStyles();

  // TODO fix styles, move any inline styles to classNames
  return (
    <Stack style={{ padding: '16px 32px 32px 32px', width: '100%' }}>
      {data.accounts.map((a, i) => (
        <div className={classes.expandedCard} key={i}>
          <div className={classes.linkIcon}>
            {a.unlinked ? <UnlinkIcon /> : <LinkIcon />}
          </div>
          <div>
            <Text
              size={14}
              weight={400}
              color="#000000"
              style={{ textTransform: 'capitalize' }}
            >
              {a.accountType}
            </Text>
            <Text
              size={14}
              weight={400}
              color="#000000"
              style={{ textTransform: 'capitalize' }}
            >
              {formatCurrency(a.availableBalance)} / {a.accountType}{' '}
              {getPaddedAccountMask(a.accountMask, 2)}
            </Text>
          </div>
        </div>
      ))}
    </Stack>
  );
};

const UnisuredCapitalCell = ({ row }: { row: BankSnapshot }) => {
  const { classes, cx } = useStyles();
  const [uninsuredPercent, setUninsuredPercent] = useState(0);

  useEffect(() => {
    if (row.availableBalance > 250000) {
      const _uninsuredAmount = row.availableBalance - 250000;
      setUninsuredPercent((_uninsuredAmount / row.availableBalance) * 100);
    }
  }, [row.availableBalance]);

  return (
    <div
      className={cx(
        classes.tableCellMargin,
        uninsuredPercent > 0 && classes.risky,
        uninsuredPercent === 0 && classes.good,
      )}
    >
      {uninsuredPercent ? `${uninsuredPercent.toFixed(2)}%` : '0%'}
    </div>
  );
};

type Props = {
  bankSnapshots: Record<string, BankSnapshot>;
  onLinkBankClick: () => void;
};

const Empty = ({ onLinkBankClick }: Omit<Props, 'bankSnapshots'>) => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  return (
    <Text
      className={cx(classes.pp, classes.emptyTableComp)}
      weight={500}
      size={14}
      color="#000000"
    >
      <Text
        component="span"
        weight={500}
        size={14}
        color={theme.fn.primaryColor()}
        className={cx(classes.pp, classes.emptyTableLink)}
        onClick={onLinkBankClick}
      >
        Link
      </Text>{' '}
      all of your bank accounts to see your total % uninsured funds and all your
      accounts in one place
    </Text>
  );
};

const Risk = ({ bankSnapshots, onLinkBankClick }: Props) => {
  const { classes, cx } = useStyles();
  const [searchText, setSearchText] = useState('');
  const filters = useMemo(() => {
    return [
      {
        column: 'Bank Names',
        options: Object.keys(bankSnapshots).map((k) => ({
          label: k,
          value: k,
        })),
        title: 'Filter by bank',
      },
      {
        column: 'Exposure',
        title: 'Filter by risk',
        options: [
          { label: 'Risky', value: 'risky' },
          { label: 'Safe', value: 'safe' },
        ],
      },
    ];
  }, [bankSnapshots]);

  const [activeFilter, setActiveFilter] = useState({
    column: 'Bank Names',
    value: '',
  });

  const linkedAccounts = useMemo(() => {
    return Object.values(bankSnapshots)
      .filter((b) => {
        switch (activeFilter.column) {
          case 'Bank Names': {
            return activeFilter.value
              ? b.bankName === activeFilter.value
              : true;
          }
          case 'Exposure': {
            const exposureFilter = activeFilter.value;
            if (exposureFilter === 'risky') {
              return b.availableBalance - 250000 > 0;
            } else if (exposureFilter === 'safe') {
              return b.availableBalance - 250000 <= 0;
            } else {
              return true;
            }
          }
          default:
            return true;
        }
      })
      .filter((b) => {
        return b.bankName.toLowerCase().includes(searchText.toLowerCase());
      });
  }, [activeFilter, searchText, bankSnapshots]);

  const columns = [
    {
      name: 'Account',
      cell: (row: BankSnapshot) => {
        return (
          <AvatarLogoCell
            placeholderName={row.bankName}
            logoUrl={row.logoUrl}
            withLabel={true}
          />
        );
      },
      sortable: false,
    },
    {
      name: 'Available balance',
      cell: (row: BankSnapshot) => (
        <div className={classes.tableCellMargin}>
          {formatCurrency(row.availableBalance)}
        </div>
      ),
      sortable: false,
    },
    {
      name: 'Uninsured Capital',
      cell: (row: BankSnapshot) => (
        <UnisuredCapitalCell row={row} key={row.bankName} />
      ),
      sortable: false,
    },
    {
      name: '',
      cell: () => {
        return (
          <MenuCell>
            {/*<Tooltip label={'Coming soon'}>*/}
            <Menu.Item
              icon={<EditIcon />}
              onClick={() => console.log('menu click')}
            >
              Edit account name
            </Menu.Item>
            {/*</Tooltip>*/}
          </MenuCell>
        );
      },
      right: true,
    },
  ];

  return (
    <TableContainerWithSearchFilter
      title={
        <div className={cx(classes.flexGroup, classes.linkedAccountsTitle)}>
          <Text color="#5F5F5F" weight={500} size={16}>
            External Accounts
          </Text>
          <PlaidIcon />
        </div>
      }
      searchPlaceholder="Search external accounts"
      searchProps={{
        value: searchText,
        onChange: (val) => setSearchText(val),
      }}
      filterProps={{
        filters: filters,
        initial: activeFilter,
        searchCallback: (column, value) => {
          setActiveFilter({ column, value });
        },
      }}
    >
      <FlexbaseTable
        striped={true}
        columns={columns}
        data={linkedAccounts}
        isFetchingData={false}
        expandOnRowClicked={true}
        expandableRows={true}
        expandableRowsComponent={ExpandedComponent}
        noDataComponent={<Empty onLinkBankClick={onLinkBankClick} />}
      />
    </TableContainerWithSearchFilter>
  );
};

export default Risk;
