import { useRecoilValue } from 'recoil';
import { IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import {
  Button,
  UnstyledButton,
  Alert,
  Group,
  Divider,
  TextInput,
  Flex,
} from '@mantine/core';

import { useStyles } from '../../styles';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import { UserInfoState } from 'states/user/user-info';
import { RedAlertIcon } from 'assets/svg';

interface Props {
  token: string;
  stepName?: number;
  closeModal?: () => void;
  getDebitCards?: () => void;
  setStep?: (x: number) => void;
  setBearerToken?: (x: string) => void;
}

const UnitcoToken = ({
  token,
  setStep,
  stepName,
  closeModal,
  getDebitCards,
  setBearerToken,
}: Props) => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [retry, setRetry] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const userInfo = useRecoilValue(UserInfoState);
  const [isLoading, setIsLoading] = useState(false);
  const [unitToken, setUnitToken] = useState(token);
  const AuthInputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = async (code: string): Promise<void> => {
    try {
      setRetry(false);
      if (code.length === 6) {
        setIsLoading(true);

        if (setBearerToken && setStep && stepName) {
          const tokenResponse = await flexbaseBankingClient.createCustTokenVGS({
            verificationCode: code,
            verificationToken: unitToken || token,
          });

          if (tokenResponse.success) {
            setBearerToken(tokenResponse.accessToken);
            setStep(stepName);
          } else {
            setErrorMsg('Invalid code. Please try again or re-send the code');
            setRetry(true);
          }
        } else {
          const tokenResponse = await flexbaseBankingClient.createUnitCoToken({
            verificationCode: code,
            verificationToken: unitToken || token,
            scope: 'transactions cards cards-write cards-sensitive-write',
          });

          if (tokenResponse.success) {
            if (setStep && stepName) {
              setStep(stepName);
            } else {
              getDebitCards!()!;
              closeModal!();
            }
          } else {
            setErrorMsg('Invalid code. Please try again or re-send the code');
            setRetry(true);
          }
        }
      }
    } catch (error) {
      console.error('Unable to validate the code', error);
      setErrorMsg('Invalid code. Please try again or re-send the code');
    } finally {
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    setRetry(true);
    const result = await flexbaseClient.getUnitcoToken();
    if (result.success && result.attributes) {
      setUnitToken(result.attributes.verificationToken);
    } else {
      setErrorMsg('An unhandled error occurred. Please, try it again');
    }
  };

  useEffect(() => {
    flexbaseClient.getUnitcoToken();
  }, []);

  useEffect(() => {
    if (retry && AuthInputRef.current) {
      AuthInputRef.current.value = '';
    }
  }, [retry]);

  return (
    <div
      className={classes.modal2FAContent}
      style={{
        alignItems: stepName ? 'initial' : 'center',
      }}
    >
      <Group position="apart" mx={20}>
        <span>Verification required</span>
        {!stepName && (
          <UnstyledButton
            className="close-button"
            onClick={() => {
              closeModal!();
              navigate('/banking/accounts');
            }}
          >
            <IoClose size={24} color="#7B7A77" />
          </UnstyledButton>
        )}
      </Group>
      <Divider color="neutral.2" my={15} />

      <div style={{ margin: '0px 20px' }}>
        <p className="text">
          Enter the 6 digit code sent to •••••
          {(userInfo.phone ?? userInfo.cellPhone)?.slice(-4)}.
        </p>

        <p className="label">Code</p>
        <TextInput
          autoFocus
          maxLength={6}
          type="number"
          ref={AuthInputRef}
          error={errorMsg !== ''}
          placeholder="Enter code"
          onChange={(e) => handleOnChange(e.currentTarget.value)}
        />

        <UnstyledButton className="resend-button" onClick={resendCode}>
          Resend code
        </UnstyledButton>

        {errorMsg !== '' && (
          <Alert mt={20} icon={<RedAlertIcon />} color="red">
            {errorMsg}
          </Alert>
        )}

        <Flex justify="flex-end">
          <Button
            variant="light"
            className="continue-button"
            loading={isLoading}
          >
            Continue
          </Button>
        </Flex>
      </div>
    </div>
  );
};

export default UnitcoToken;
