/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Group, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
// import { FilterComp, FilterList } from '@flexbase-eng/web-components';

import {
  // WhoIcon,
  // ListIcon,
  // CardIcon,
  // AccountsIcon,
  SearchIcon,
  PlusSignIcon,
} from 'assets/svg';
import { CardByUser } from 'services/flexbase/banking.model';
// import CardType from '../debit-cards-header/filter-views/type-filter';
// import CardHolder from '../debit-cards-header/filter-views/who-filter';
// import Account from '../debit-cards-header/filter-views/deposit-filter';
// import CardStatus from '../debit-cards-header/filter-views/status-filter';
import { NavbarOptions, Filter } from '../../components/filter/filter-models';

interface Props {
  filterText: string;
  filterList: Filter[];
  debitCards: CardByUser[];
  onClickButton: (e: any) => void;
  setFilterText: (e: any) => void;
  setFilterList: (x: Filter[]) => void;
}

// COMENTED UNTIL THE FILTER IS READY
// const navbarOptions: NavbarOptions[] = [
//   {
//     label: 'Status',
//     icon: <ListIcon width={18} height={18} />,
//     viewIndex: 0,
//   },
//   {
//     label: 'Type',
//     icon: <CardIcon width={20} height={20} />,
//     viewIndex: 1,
//   },
//   {
//     label: 'Who',
//     icon: <WhoIcon width={20} height={20} />,
//     viewIndex: 2,
//   },
//   {
//     label: 'Account',
//     icon: <AccountsIcon width={20} height={20} />,
//     viewIndex: 3,
//   },
// ];

// const renderView = (viewIndex: number) => {
//   let view = <></>;
//   switch (viewIndex) {
//     case 0:
//       view = <CardStatus />;
//       break;
//     case 1:
//       view = <CardType />;
//       break;
//     case 2:
//       view = <CardHolder {...props} />;
//       break;
//     case 3:
//       view = <Account />;
//       break;
//   }

//   return view;
// };

const DebitCardsHeader = (props: Props) => {
  const useMobileView = useMediaQuery('(max-width: 767px)');

  return (
    <div>
      <div className="header">
        {useMobileView ? (
          <TextInput
            mt={15}
            icon={<SearchIcon />}
            value={props.filterText}
            placeholder="Search debit cards"
            onChange={(e) => props.setFilterText(e.target.value)}
          />
        ) : (
          <Group position="apart">
            <TextInput
              w={500}
              icon={<SearchIcon />}
              value={props.filterText}
              placeholder="Search debit cards"
              onChange={(e) => props.setFilterText(e.target.value)}
            />
            <Button
              variant="light"
              onClick={props.onClickButton}
              leftIcon={<PlusSignIcon width={10} />}
            >
              New debit card
            </Button>
          </Group>
          // <FilterComp
          //   withSearchbar
          //   navbarOptions={navbarOptions}
          //   renderView={renderView}
          //   filterText={props.filterText}
          //   placeholder="Search debit cards"
          //   setFilterText={props.setFilterText}
          // />
        )}
      </div>
      {/* <div style={{ display: 'flex' }}>
        <FilterList
          filterList={props.filterList}
          setFilterList={props.setFilterList}
        />
      </div> */}
    </div>
  );
};

export default DebitCardsHeader;
