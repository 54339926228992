import { TextInput } from '@mantine/core';
import FormBase from './form-base';
import { useFormStyles } from './form.styles';
import { useForm } from '@mantine/form';
import { validateUrl } from 'utilities/validators/validate-url';

type Props = {
  onClose: () => void;
  onSubmit: (website: string) => void;
  currentWebsite?: string;
};

const WebsiteForm = ({ onClose, onSubmit, currentWebsite }: Props) => {
  const { classes } = useFormStyles();

  const form = useForm({
    initialValues: { website: currentWebsite || '' },
    validate: {
      website: (value) =>
        validateUrl(value) ? null : 'You must enter a valid URL',
    },
  });

  const onUpdateClick = () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      const { website } = form.values;
      onSubmit(website);
    }
  };

  return (
    <FormBase onCancel={onClose} onSubmit={onUpdateClick}>
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        label={'Website URL'}
        data-testid="website-input"
        size="lg"
        {...form.getInputProps('website')}
      />
    </FormBase>
  );
};

export default WebsiteForm;
