import { createStyles, rem } from '@mantine/core';
import { FlexbaseFooter } from './flexbase-footer';
import { ReactNode } from 'react';
import { OnboardingProduct } from 'states/application/product-onboarding.models';

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.contentBackground[0],
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    '@media(max-width: 767px)': {
      overflowX: 'hidden',
      height: 'auto',
    },
  },
  fullScreen: {
    height: '100vh',
    overflow: 'hidden',
  },
}));

type ClassNames = {
  scrollContainer: string;
  container: string;
  footer: string;
  footerInner: string;
  footerDisclaimer: string;
  footerDisclaimer2: string;
  footerContact: string;
  footerDiscloure: string;
  footerDisclosureContainer: string;
};

type Props = {
  product?: OnboardingProduct;
  disclaimer2?: boolean;
  withFooter?: boolean;
  children: ReactNode;
  footerMarginTop?: string;
  typeProduct?: string;
  restrictFullScreen?: boolean;
  className?: string;
  classNames?: Partial<ClassNames>;
};

export const ContainerWithFooter = ({
  product = 'CREDIT',
  disclaimer2 = false,
  children,
  withFooter = true,
  footerMarginTop = rem(120),
  restrictFullScreen = false,
  className,
  classNames,
}: Props) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classNames?.scrollContainer}>
      <div
        className={cx(
          classes.container,
          {
            [classes.fullScreen]: restrictFullScreen,
          },
          className,
          classNames?.container,
        )}
      >
        {children}
      </div>
      {withFooter && (
        <FlexbaseFooter
          footerMarginTop={footerMarginTop}
          disclaimer2={disclaimer2}
          classNames={classNames}
        />
      )}
    </div>
  );
};
