import { ArrowRightIconGreen } from 'assets/svg';
import { useSettingsSectionStyles } from './settings-section.styles';

type Props = {
  field: string | JSX.Element;
  value: string | JSX.Element;
  editable: boolean;
  onEditClick?: () => void;
  showDivider: boolean;
};

const SettingsSection = ({
  field,
  value,
  editable,
  onEditClick,
  showDivider,
}: Props) => {
  const { classes } = useSettingsSectionStyles();

  const onClick = () => {
    if (onEditClick) {
      onEditClick();
    }
  };

  return (
    <div
      className={`${classes.sectionContainer} ${
        showDivider ? classes.containerBorder : ''
      }`}
    >
      <div className={`${classes.fieldContainer} ${classes.text}`}>{field}</div>
      <div className={classes.valueContainer}>
        <div className={classes.text}>
          {value ? value : editable ? '' : 'N/A'}
        </div>
        {editable && (
          <div
            className={`${classes.text} ${classes.edit}`}
            style={{
              color: '#808080',
              marginTop: !value ? 0 : '',
              width: 'fit-content',
            }}
            onClick={onClick}
          >
            {value ? 'Edit ' : 'Add '}
            <span>
              <ArrowRightIconGreen />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsSection;
