import { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { createStyles, Select, SelectProps } from '@mantine/core';

export const customMantineStyles = createStyles((theme, { menuOpen }: any) => ({
  input: {
    color: '#5F5F5F',
    height: '40px',
    border: '1px solid #E6E7E9',
    borderRadius: theme.defaultRadius,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    background: 'white',
    '&:focus': { borderColor: theme.fn.primaryColor() },
  },
  label: {
    color: '#4B4B4B',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
  },
  item: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5F5F5F',
    backgroundColor: 'white',
    '&:hover': {
      border: 'none',
      backgroundColor: 'rgba(255, 87, 69, 0.08)',
    },
  },
  rightSection: {
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  dropdown: {
    borderRadius: 8,
  },
}));

type Props = SelectProps & React.RefAttributes<HTMLInputElement>;

const FlexbaseSelect = ({ classNames, ...props }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  // const { classes } = customMantineStyles({ menuOpen });

  return (
    <Select
      {...props}
      classNames={{ ...classNames }}
      rightSection={
        props.rightSection !== false ? (
          menuOpen ? (
            <BsChevronUp />
          ) : (
            <BsChevronDown />
          )
        ) : (
          <></>
        )
      }
      onDropdownOpen={() => setMenuOpen(true)}
      onDropdownClose={() => setMenuOpen(false)}
    />
  );
};

export default FlexbaseSelect;
