import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#4B4B4B',
  },
  container: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: '8px',
    maxWidth: '1365px',
    margin: 'auto',
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: '#E6E7E9',
    borderBottomWidth: 0.5,
    paddingBottom: 16,
    paddingTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusColumn: {
    padding: 5,
    textAlign: 'center',
    borderRadius: '100px',
    '@media(max-width: 767px)': {
      width: '100%',
    },
    minWidth: '100px',
  },
  contactName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    wordWrap: 'normal',
  },
  contactIcon: {
    height: '32px',
    width: '32px',
    color: theme.fn.primaryColor(),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: '#FFEEEC !important',
    },
    marginRight: '10px',
  },
  errorMessage: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: '32px',
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: '14px',
      textAlign: 'center',
    },
  },
}));
