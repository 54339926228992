import { useState } from 'react';
import { createStyles, Menu } from '@mantine/core';
import { BiHelpCircle, BiLinkExternal } from 'react-icons/bi';

const useStyles = createStyles(() => ({
  item: {
    '&[data-hovered]': {
      background: 'rgba(255, 87, 69, 0.08);',
    },
  },
  menuTarget: {
    display: 'flex',
    fontSize: '14px',
    color: '#5F5F5F',
    cursor: 'pointer',
    alignItems: 'center',
    svg: {
      marginRight: 4,
    },
  },
}));

const HelpActionButtons = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      withArrow
      offset={4}
      shadow="sm"
      opened={opened}
      classNames={classes}
      position="bottom-end"
      arrowPosition="center"
      onChange={(open) => setOpened(open)}
    >
      <Menu.Target>
        <div className={classes.menuTarget}>
          <BiHelpCircle size={16} color="#5F5F5F" />
          Help
        </div>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Resources</Menu.Label>
        <Menu.Item
          icon={<BiLinkExternal size={14} />}
          component="a"
          href="https://flexbase.zendesk.com/hc/en-us"
          target="_blank"
        >
          Support articles
        </Menu.Item>
        <Menu.Divider />

        <Menu.Label>Get in touch</Menu.Label>
        <Menu.Item
          component="a"
          href="https://flexbase.zendesk.com/hc/en-us/requests/new"
          target="_blank"
        >
          Share feedback
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HelpActionButtons;
