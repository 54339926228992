import { createStyles, MantineTheme } from '@mantine/core';

/*
Base styles to use for the core @mantine components.
*/
export const useStyles = createStyles((theme) => ({
  grid: {},
  mainContainer: {
    minHeight: '100vh',
    backgroundColor: theme.fn.themeColor('neutral', 2),
    paddingLeft: 32,
    paddingRight: 32,
    '@media(max-width: 767px)': {
      padding: 0,
    },
  },
  container: {
    px: '0px',
    borderRadius: theme.defaultRadius,
    '@media(max-width: 767px)': {
      flexDirection: 'column',
    },
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.fn.themeColor('neutral', 0),
    maxWidth: 'none',
    height: '100%',
    margin: 0,
    width: '100%',
    padding: '1.5rem',
    fontFamily: 'Inter',
  },
  input: {
    height: '40px',
    fontWeight: 500,
    color: '#5F5F5F',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '8px',
    background: 'white',
    border: '1px solid #E6E7E9',
    '&:focus': { borderColor: theme.fn.primaryColor() },
  },
  button: {
    borderColor: theme.fn.primaryColor(),
    backgroundColor: 'white',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.fn.primaryColor(),
    width: '100%',
    '&:not([data-disabled])': theme.fn.hover({
      background: 'rgba(255, 87, 69, 0.1)',
    }),
    borderRadius: '8px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
  card: {
    marginTop: '5rem',
    borderColor: '#c8d6e5',
    width: '100%',
    margin: '1.5rem',
    opacity: '',
  },
  'close-btn': {},
  icon: {
    color: '#5F5F5F',
  },
  checkbox: {},
}));

// Note: we use react-data-table for tables with the formatting used in
// customStyles: https://react-data-table-component.netlify.app/?path=/docs/api-custom-styles--page
export const baseTableStyles = (theme?: MantineTheme) => {
  return {
    table: {
      style: {
        borderTop: `1px solid ${theme?.colors.neutral[1]}`,
        color: theme?.colors.neutral[7],
        fontSize: '14px',
        lineHeight: '21px',
        width: '100%',
        button: {
          border: 0,
          display: 'flex',
          cursor: 'pointer',
          backgroundColor: 'unset',
        },
      },
    },
    cells: {
      style: {
        color: theme?.colors.neutral[7],
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        // paddingLeft: '8px',
        // paddingRight: '8px',
        alignItems: 'center',
        svg: {
          margin: '0px 15px',
        },
      },
    },
    headCells: {
      style: {
        color: theme?.fn.primaryColor(),
        fontWeight: 500,
        display: 'flex',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: 400,
        color: theme?.colors.neutral[7],
        lineHeight: '21px',
        minHeight: '68px',
        '&:hover': {
          background: theme?.colors.neutral[2],
          cursor: 'pointer',
        },
      },
      stripedStyle: {
        backgroundColor: 'unset',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: theme?.colors.neutral[2],
      span: {
        color: theme?.colors.neutral[2],
      },
      svg: {
        fill: theme?.colors.neutral[2],
      },
    },
    pagination: {
      style: {
        '@media(max-width: 767px)': {
          fontSize: '12px',
          paddingTop: '12px',
          '> span:first-of-type': {
            width: '288px',
            margin: '0px 10px',
          },
          '> span': {
            width: '393px',
            margin: '0px 1px',
          },
        },
      },
      pageButtonsStyle: {
        '@media(max-width: 767px)': {
          height: '40px',
          width: '25px',
        },
      },
    },
  };
};

export const liteTableStyles = (primaryColor?: string) => {
  return {
    table: {
      style: {
        borderTop: '1px solid #DEE2E6',
        color: '#5F5F5F',
        fontSize: '14px',
        lineHeight: '21px',
        width: '100%',
      },
    },
    cells: {
      style: {
        color: '#5F5F5F',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        alignItems: 'center',
      },
    },
    headCells: {
      style: {
        color: 'black',
        fontWeight: 400,
        display: 'flex',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#5F5F5F',
        lineHeight: '21px',
        minHeight: '68px',
        '&:hover': {
          background: 'rgba(255, 87, 69, 0.08);',
          cursor: 'pointer',
        },
      },
      stripedStyle: {
        backgroundColor: 'rgba(230, 231, 233, 0.12)',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#FFEEEC',
      span: {
        color: primaryColor,
      },
    },
  };
};

export const tableWithActionButtonsStyle = (theme: MantineTheme) => ({
  table: {
    style: {
      borderTop: '1px solid rgba(230, 231, 233)',
      color: '#5F5F5F',
      fontSize: '14px',
      lineHeight: '21px',
      width: '100%',
    },
  },
  responsiveWrapper: {
    style: {
      display: 'contents',
    },
  },
  cells: {
    style: {
      color: '#5F5F5F',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
    },
  },
  headCells: {
    style: {
      color: 'black',
      fontWeight: 400,
    },
  },
  rows: {
    style: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#5F5F5F',
      lineHeight: '21px',
      minHeight: '68px',
      '&:hover': {
        background: theme.colors.primarySecondarySuccess[0],
        cursor: 'pointer',
      },
    },
    // stripedStyle: {
    //   backgroundColor: 'rgba(230, 231, 233, 0.12)',
    // },
  },
});
