import { useOnboardingStyles } from '../../onboarding.styles';
import {
  BUSINESS_ANNUAL_REVENUE,
  BUSINESS_MERCHANT_CODES,
  BUSINESS_VERTICALS,
  NAICS_CODES,
} from 'states/business/constants';
import { ConditionalFieldValidator } from 'utilities/validators/validate-required';
import { useForm } from '@mantine/form';
import { validateUrl } from 'utilities/validators/validate-url';
import { useProductOnboarding } from '../../onboarding-hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import OnboardingStep from '../../components/onboarding-step';
import FlexbaseSelect from 'components/select/flexbase-select';
import { useEffect, useState } from 'react';
import {
  ApplicationState,
  ProductState,
} from 'states/application/product-onboarding';
import { Autocomplete, Group, Radio, Text, TextInput } from '@mantine/core';
import { Analytics } from 'services/analytics/analytics';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { filterAutocompleteData } from 'utilities/filter/filter-data';
import { RequiredFieldValidator } from '@flexbase-eng/web-components';
import TagManager from 'react-gtm-module';
import { formatCurrencyInput } from '../../../../utilities/formatters/format-currency';

const BusinessActivity = () => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { navigateToNextProductStep, goBack, createOrUpdateCompany } =
    useProductOnboarding();
  const [application, setApplication] = useRecoilState(ApplicationState);
  const product = useRecoilValue(ProductState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mcc, setMcc] = useState<{ value: string; label: string }[]>([]);
  const { user, company } = useRecoilValue(ApplicationState);

  const getCodes = async () => {
    setLoading(true);
    try {
      const code = await flexbaseOnboardingClient.getMccCodes();
      setMcc(code.map((c) => ({ value: c.code, label: c.description })));
    } catch (e) {
      setError('Unable to load full business category data');
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCodes();
  }, []);

  const businessActivityForm = useForm({
    initialValues: {
      companyName: application.company.companyName
        ? application.company.companyName === 'My Company'
          ? ''
          : application.company.companyName
        : '',
      hasWebsite: application.company.website
        ? 'Yes'
        : application.company.website === null
        ? 'No'
        : '',
      website: application.company.website || '',
      category: application.company.category || '',
      annualRevenue: application.company.annualRevenue || '',
      businessPurpose: application.company.businessPurpose || '',
      naicsCode: application.company.naicsCode || '',
      businessVertical: application.company.businessVertical || '',
      doingBusinessAs: application.company.doingBusinessAs || '',
      requestedCreditLimit: application.company.requestedCreditLimit || '',
    },
    validate: {
      hasWebsite: ConditionalFieldValidator(
        product === 'BANKING' || product === 'ALL',
      ),
      website: (val, formValues) => {
        // TODO fix validation for credit product
        if (val || formValues.hasWebsite === 'Yes') {
          return validateUrl(val) ? null : 'Must be a valid URL';
        }

        return null;
      },
      companyName: (val) =>
        val === 'My Company'
          ? 'Default value detected. Make sure to update.'
          : RequiredFieldValidator('You must enter the name of your company')(
              val,
            ),
      category: RequiredFieldValidator('Category is required'),
      annualRevenue: RequiredFieldValidator('Annual revenue is required'),
      businessPurpose: RequiredFieldValidator('Business purpose is required'),
      naicsCode: ConditionalFieldValidator(product === 'TREASURY'),
      businessVertical: ConditionalFieldValidator(
        product === 'BANKING' || product === 'ALL',
      ),
    },
  });

  const onRequestedCreditChange = (value: string) => {
    const formatted = formatCurrencyInput(value);
    if (formatted !== undefined) {
      businessActivityForm.setFieldValue('requestedCreditLimit', formatted);
    }
  };

  const onSubmit = async () => {
    const validationResult = businessActivityForm.validate();
    if (!validationResult.hasErrors) {
      setError('');
      setLoading(true);
      const formValues = businessActivityForm.values;

      // Due to some oddness with the Mantine autocomplete and how we want to display data, need to do this
      if (formValues.category.includes('-')) {
        formValues.category = formValues.category.split('-')?.[0]?.trim();
      }

      if (user.id) {
        Analytics.people.set(user.id, {
          ...formValues,
        });
      }
      const updateValues = {
        companyName: formValues.companyName,
        businessVertical: formValues.businessVertical,
        category: formValues.category,
        annualRevenue: formValues.annualRevenue,
        businessPurpose: formValues.businessPurpose,
        naicsCode: formValues.naicsCode,
        website: formValues.website || (null as any),
        ...(formValues.requestedCreditLimit && {
          requestedCreditLimit: formValues.requestedCreditLimit.replace(
            ',',
            '',
          ),
        }),
      };
      setApplication((prev) => ({
        ...prev,
        ...updateValues,
      }));
      let canNavigateToNextStep = true;
      if (application.company.id) {
        const result = await createOrUpdateCompany(updateValues);
        canNavigateToNextStep = result.success;
      }
      if (canNavigateToNextStep) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'businessActivitySubmitted',
          },
        });
        navigateToNextProductStep();
      } else {
        setError('Unable to update business data');
      }
    }
  };

  const onBack = () => {
    goBack();
  };

  const [searchString, setSearchString] = useState('');
  const naicsAutocompleteData = filterAutocompleteData(
    searchString,
    NAICS_CODES,
  );
  const mccAutocompleteData = filterAutocompleteData(
    searchString,
    mcc.length > 0 ? mcc : BUSINESS_MERCHANT_CODES,
  );

  const categoryLabel = (value: string) => {
    const category = mcc.find((code) => code.value === value);
    return category ? `${category?.value} - ${category?.label}` : value;
  };

  const disableWebsiteInput =
    (product === 'BANKING' || product === 'ALL') &&
    businessActivityForm.values.hasWebsite !== 'Yes';

  return (
    <OnboardingStep
      title="Business activity"
      subtitle="Tell us about your business"
      onBackClick={onBack}
      onNextClick={onSubmit}
      stepId="business-activity"
      showContinueSpinner={loading}
      error={error}
    >
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        label="Legal entity name"
        placeholder="Enter your exact legal entity name"
        {...businessActivityForm.getInputProps('companyName')}
        id="input-business-legal-name"
      />
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        label="DBA (Optional)"
        placeholder="Enter your DBA (Doing Business As)"
        {...businessActivityForm.getInputProps('doingBusinessAs')}
        id="input-business-dba"
      />
      {(product === 'BANKING' || product === 'ALL') && (
        <Radio.Group
          label="Do you have a website?"
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          mt={16}
          {...businessActivityForm.getInputProps('hasWebsite')}
          onChange={(val) => {
            businessActivityForm.setFieldValue('hasWebsite', val);
            if (val === 'No') {
              businessActivityForm.setFieldValue('website', '');
            }
          }}
        >
          <Group mt={4}>
            <Radio
              value="Yes"
              label="Yes"
              data-testid="radio-button-website-yes"
            />
            <Radio
              value="No"
              label="No"
              data-testid="radio-button-website-no"
            />
          </Group>
        </Radio.Group>
      )}
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        label="Website or social media website (optional)"
        placeholder="Enter URL for website or social media website"
        {...businessActivityForm.getInputProps('website')}
        disabled={disableWebsiteInput}
        id="input-business-website"
      />
      <TextInput
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        label="Business purpose"
        placeholder="Describe the business purpose"
        {...businessActivityForm.getInputProps('businessPurpose')}
        id="input-business-business-purpose"
      />
      <Autocomplete
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        mt={16}
        value={categoryLabel(businessActivityForm.values.category)}
        onChange={(e) => {
          setSearchString(e);
          businessActivityForm.setFieldValue('category', e);
        }}
        onItemSubmit={(i) => {
          businessActivityForm.setFieldValue('category', i.value);
        }}
        data={mccAutocompleteData}
        label="Category"
        placeholder="Search for your MCC by code or description"
        id="input-business-category"
        nothingFound="No results"
        error={businessActivityForm.errors.category}
      />
      <FlexbaseSelect
        label="Annual revenue"
        placeholder="Select range"
        mt={16}
        {...businessActivityForm.getInputProps('annualRevenue')}
        data={BUSINESS_ANNUAL_REVENUE}
        classNames={{
          label: onboardingClasses.inputLabel,
        }}
        id="input-business-annual-revenue"
      />
      {product === 'TREASURY' && (
        <Autocomplete
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          mt={16}
          {...businessActivityForm.getInputProps('naicsCode')}
          onChange={(e) => {
            setSearchString(e);
            businessActivityForm.setFieldValue('naicsCode', e);
          }}
          onItemSubmit={(i) => {
            businessActivityForm.setFieldValue('naicsCode', i.value);
          }}
          data={naicsAutocompleteData}
          label="NAICS Code"
          placeholder="Start typing to search for your NAICS"
          id="input-business-naics-code"
          nothingFound="No results"
        />
      )}
      {(product === 'BANKING' || product === 'ALL') && (
        <FlexbaseSelect
          label="Business vertical"
          placeholder="Select closest match"
          mt={16}
          {...businessActivityForm.getInputProps('businessVertical')}
          data={BUSINESS_VERTICALS}
          classNames={{
            label: onboardingClasses.inputLabel,
          }}
          id="input-business-vertical"
          searchable
        />
      )}
      {company.optedProducts.includes('CREDIT') &&
        !company.requestedCreditLimit && (
          <TextInput
            mt={16}
            classNames={{
              label: onboardingClasses.inputLabel,
            }}
            label="What is your desired credit limit? (Optional)"
            placeholder="ie: 20,000"
            {...businessActivityForm.getInputProps('requestedCreditLimit')}
            icon={<Text fz={14}>$</Text>}
            onChange={(e) => onRequestedCreditChange(e.target.value)}
            id="input-requested-credit-limit"
          />
        )}
    </OnboardingStep>
  );
};

export default BusinessActivity;
