import { atom, selector } from 'recoil';

export interface BnplStuff {
  merchantLogoUrl: string;
  amount: string;
  merchantName: string;
  isBnpl: boolean;
  hasError: boolean;
  callbackBaseUrl: string;
  callbackParam: string;
  sessionParam: string;
  invoiceId: string;
  status: TransactionStatus;
  isPreApproval?: boolean;
}

export enum TransactionStatus {
  pending = 'pending',
  settled = 'settled',
  declined = 'declined',
  canceled = 'canceled',
}

export const BnplState = atom<BnplStuff>({
  key: 'bnpl',
  default: {
    merchantLogoUrl: '',
    amount: '',
    merchantName: '',
    isBnpl: false,
    hasError: false,
    callbackBaseUrl: '',
    callbackParam: '',
    sessionParam: '',
    status: TransactionStatus.pending,
    invoiceId: '',
    isPreApproval: false,
  },
});

export const BnplCallbackUrl = selector<string>({
  key: 'bnpl_callback',
  get: ({ get }) => {
    const { callbackBaseUrl, callbackParam, sessionParam, invoiceId, status } =
      get(BnplState);

    if (callbackBaseUrl) {
      const baseUrlTrimmed = callbackBaseUrl.replace(/\/$/, '');
      const callbackTrimmed = callbackParam.replace(/^\//, '');

      const query = new URLSearchParams({ status });

      if (sessionParam) {
        query.append('session', sessionParam);
      }

      if (invoiceId) {
        query.append('id', invoiceId);
      }

      return `${baseUrlTrimmed}/${callbackTrimmed}?${query.toString()}`;
    }

    return '';
  },
});
