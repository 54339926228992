import { useState } from 'react';
import {
  Button,
  Radio,
  Group,
  useMantineTheme,
  Select,
  TextInput,
  Alert,
} from '@mantine/core';

import { useStyles, CustomMantineStyles } from '../../styles';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';
import AccountSelection from 'areas/payments/components/account-selection';
import {
  AccountProps,
  DepositAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { RedAlertIcon } from 'assets/svg';

interface SelectItemProps {
  label: string;
  value: string;
}

interface Props {
  form: any;
  appType: string;
  employees: SelectItemProps[];
  setStep: (x: number) => void;
  setDebitCards: (x: any) => void;
  bankingAccounts: DepositAccount[];
}

const CreateDebitCardForm = ({
  form,
  setStep,
  appType,
  employees,
  setDebitCards,
  bankingAccounts,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<DepositAccount>(
    bankingAccounts[0],
  );

  const onInputChange = (
    input: string,
    fieldValue: 'purchaseLimit' | 'withdrawalLimit',
  ) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    form.setFieldValue(fieldValue, Number(currentValue));
  };

  const selectAccount = (selectAccount: DepositAccount) => {
    setSelectedAccount(selectAccount);
    form.setFieldValue('accountId', selectAccount.id);
  };

  let cardLimits: {
    dailyPurchase?: number;
    dailyWithdrawal?: number;
    monthlyPurchase?: number;
    monthlyWithdrawal?: number;
  };
  switch (form.values.limitType) {
    case 'daily':
      cardLimits = {
        dailyPurchase: form.values.purchaseLimit,
      };
      break;

    case 'monthly':
      cardLimits = {
        monthlyPurchase: form.values.purchaseLimit,
      };
      break;

    default:
      cardLimits = {
        monthlyPurchase: form.values.purchaseLimit,
      };
      break;
  }

  const issueDebitCard = async () => {
    try {
      setIsLoading(true);
      const userId = form.values.userId;
      const cardForm =
        form.values.limitType === 'unlimited'
          ? {
              issuer: 'unit-co',
              cardType: form.values.cardType,
              depositAccountId: selectedAccount.id,
              cardName:
                form.values.cardName !== '' ? form.values.cardName : null,
            }
          : {
              issuer: 'unit-co',
              cardType: form.values.cardType,
              depositAccountId: selectedAccount.id,
              limits: form.values.limitType !== 'unlimited' ? cardLimits : null,
              cardName:
                form.values.cardName !== '' ? form.values.cardName : null,
            };
      const { success, card } = await flexbaseBankingClient.issueDebitCard(
        userId,
        cardForm,
      );

      if (success && card) {
        setDebitCards((prevState: any) => {
          return [card, ...prevState];
        });
        setStep(2);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Unable to create the card', error);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  setTimeout(() => {
    if (error) {
      setError(false);
    }
  }, 5000);

  return (
    <div className={classes.card}>
      <div className="move_funds">
        <span>Create a new card</span>
        <Select
          mt={25}
          data={employees}
          dropdownPosition="bottom"
          placeholder="Select one"
          label="Who’s this card for?"
          {...form.getInputProps('userId')}
        />

        <div style={{ marginTop: '25px', position: 'relative' }}>
          <AccountSelection
            label="Flexbase account"
            accounts={bankingAccounts}
            currentAccount={selectedAccount}
            onAccountChange={(c: AccountProps) =>
              selectAccount(c as DepositAccount)
            }
          />
        </div>

        <TextInput
          mt={25}
          label="Customize card:"
          {...form.getInputProps('cardName')}
          placeholder="e.g. nickname lunch card"
        />

        <Radio.Group
          mt={25}
          spacing={100}
          {...form.getInputProps('cardType')}
          classNames={{ label: CustomMantineStyles().debitCard.radioLabel }}
        >
          <Group>
            <Radio
              value={
                appType === 'businessApplication'
                  ? 'businessDebitCard'
                  : 'individualDebitCard'
              }
              label="Physical"
              styles={{
                radio: CustomMantineStyles(theme.fn.primaryColor()).debitCard
                  .radio,
              }}
            />
            <Radio
              value={
                appType === 'businessApplication'
                  ? 'businessVirtualDebitCard'
                  : 'individualVirtualDebitCard'
              }
              label="Virtual"
              styles={{
                radio: CustomMantineStyles(theme.fn.primaryColor()).debitCard
                  .radio,
              }}
            />
          </Group>
        </Radio.Group>

        <Select
          label="Limit type"
          {...form.getInputProps('limitType')}
          data={[
            { value: 'daily', label: 'Daily' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'unlimited', label: 'Unlimited' },
          ]}
          styles={{ root: { marginTop: '25px !important' } }}
        />

        {form.values.limitType !== 'unlimited' && (
          <TextInput
            mt={25}
            value={formatCurrency(form.values.purchaseLimit || '0')}
            onChange={(v) =>
              onInputChange(v.currentTarget.value, 'purchaseLimit')
            }
            label={`${
              form.values.limitType === 'daily' ? 'Daily' : 'Monthly'
            } spending limit`}
          />
        )}

        {error && (
          <Alert my={15} color="red" icon={<RedAlertIcon />}>
            Unable to issue the card. Please, try it again.
          </Alert>
        )}

        <div
          className="row-content"
          style={{
            justifyContent:
              form.values.cardType === 'virtual' ? 'space-between' : 'flex-end',
          }}
        >
          {form.values.cardType === 'virtual' && (
            <div style={{ maxWidth: '60%' }}>
              <p>The card will be activated within a few moments.</p>
            </div>
          )}
          <div>
            <Button
              mb={15}
              variant="light"
              loading={isLoading}
              onClick={issueDebitCard}
              disabled={
                !form.values.userId ||
                !selectedAccount.id ||
                !form.values.cardType ||
                (form.values.limitType !== 'unlimited' &&
                  form.values.purchaseLimit === 0)
              }
            >
              Create Card
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDebitCardForm;
