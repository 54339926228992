import { useEffect } from 'react';

import MoveFunds from './move-funds';
import useModal from 'components/modal/modal-hook';

const MoveFundsPage = () => {
  const { openTransparentModal } = useModal();

  useEffect(() => {
    openTransparentModal(<MoveFunds />);
  }, []);

  return <></>;
};

export default MoveFundsPage;
