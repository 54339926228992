import { Button, createStyles, Text } from '@mantine/core';
import { MdErrorOutline } from 'react-icons/md';

const useStyles = createStyles((theme) => ({
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 520,
  },
  infoText: {
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
  },
  buttons: {
    borderRadius: '8px',
    width: '100%',
  },
  exitButton: {
    borderRadius: '8px',
    width: '100%',
    backgroundColor: theme.fn.primaryColor(),
    ':hover': {
      backgroundColor: theme.fn.primaryColor(),
    },
  },
  tryAgainButton: {
    marginTop: 16,
    width: '100%',
    border: `1px solid ${theme.fn.primaryColor()}`,
    borderRadius: '8px',
    color: theme.fn.primaryColor(),
    ':hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.1)',
    },
  },
  errorCircle: {
    background: 'rgba(39, 194, 129, 0.1)',
    width: 72,
    height: 72,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorIcon: {
    color: theme.fn.primaryColor(),
    fontSize: 40,
  },
}));

type Props = { onClose: () => void; onTryAgain: () => void };

const PaymentError = ({ onClose, onTryAgain }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <MdErrorOutline className={classes.errorIcon} />
      <Text className={classes.infoText}>Something went wrong.</Text>
      <div className={classes.buttons}>
        <Button
          className={classes.exitButton}
          size="lg"
          onClick={() => onClose()}
        >
          Back to credit
        </Button>
        <Button
          className={classes.tryAgainButton}
          variant="outline"
          size="lg"
          onClick={() => onTryAgain()}
        >
          Try again
        </Button>
      </div>
    </div>
  );
};

export default PaymentError;
