import { Outlet } from 'react-router-dom';
import { NavTab } from 'components/tabs/nav-tabs';
import { NavBackIcon } from 'components/icons/circle-icons';
import { NavTabContainer } from '../../components/tabs/nav-tab-container';

const tabs: NavTab[] = [
  {
    label: 'Back',
    leftIcon: <NavBackIcon />,
    route: '/accounts/dashboard',
    noHover: true,
  },
  { label: 'Flex Treasury', route: '/accounts/treasury' },
  { label: 'Transfer funds', route: '/accounts/activities' },
  { label: 'Transactions', route: '/accounts/transactions', disabled: true },
  { label: 'Statements & docs', route: '/accounts/documents', disabled: true },
];

const TreasuryManagementTabContainer = () => {
  return (
    <NavTabContainer tabs={tabs}>
      <Outlet />
    </NavTabContainer>
  );
};

export default TreasuryManagementTabContainer;
