import { ObjectFlags } from 'typescript';

export const getObjectKeys = <T extends ObjectFlags>(object: T) => {
  return Object.keys(object) as (keyof T)[];
};

export const getInitialsOfNames = (fullName: string) => {
  let res;
  if (typeof fullName === 'string') {
    fullName = fullName.replace(/ /g, ' ').trim();
    const [firsName, lastName] = fullName.split(' ');
    if (typeof lastName === 'string') {
      res = `${firsName.substring(0, 1)}${lastName.substring(0, 1)}`;
    } else {
      res = `${firsName.substring(0, 2).toUpperCase()}`;
    }
  }
  return res;
};
