import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    color: '#fff',
    padding: '45px',
    backgroundColor: theme.fn.primaryColor(),
    '@media(max-width: 767px)': {
      padding: '20px',
    },
  },
  rowContent: {
    gap: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding: {
    padding: '0px 50px',
    '@media(max-width: 767px)': {
      padding: '0px 20px',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  alingEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paymentDetailsModal: {
    paddingBottom: 50,
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: 20,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 63px 0px 63px',
    '@media (max-width: 768px)': {
      padding: '10px',
    },
  },
  smallText: {
    fontSize: 14,
    color: theme.fn.primaryColor(),
    fontWeight: 400,
    '@media (max-width: 768px)': {
      fontSize: 12,
    },
  },
  bigText: {
    fontSize: 20,
    color: theme.fn.primaryColor(),
    fontWeight: 400,
    '@media (max-width: 768px)': {
      fontSize: 14,
    },
  },
  statusText: {
    padding: '5px 10px',
  },
  statusContainer: {
    border: 'solid',
    borderRadius: '8px',
  },
  counterpartyAmountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 63px 15px 63px',
    '@media (max-width: 768px)': {
      padding: '10px',
    },
  },
  dateContainer: {
    padding: '15px 63px 15px 63px',
    '@media (max-width: 768px)': {
      padding: '10px',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  detailsContainer: {
    padding: '0px 50px',
    '@media (max-width: 768px)': {
      padding: '0px 20px',
    },
  },
  detailsData: {
    alignItems: 'flex-end',
    fontSize: 14,
    color: '#5F5F5F',
  },

  docsContainer: {
    gap: 10,
    display: 'grid',
    padding: '0px 50px',
    '@media (max-width: 768px)': {
      padding: '0px 20px',
    },
  },

  docsBox: {
    gap: 10,
    fontSize: 14,
    display: 'flex',
    padding: '10px 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.fn.themeColor('neutral', 7),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      backgroundColor: theme.fn.themeColor('neutral', 0),
    },
  },
  addAttachmentContent: {
    gap: 10,
    fontSize: 14,
    display: 'flex',
    color: theme.fn.themeColor('neutral', 7),
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.fn.themeColor('primarySecondarySuccess', 2),
      svg: {
        color: theme.fn.themeColor('primarySecondarySuccess', 2),
      },
    },
  },

  dropZone: {
    width: '100%',
    height: '160px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'unset',
    borderRadius: theme.defaultRadius,
    border: `1px dashed ${theme.fn.primaryColor()}`,
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 0),
    },
  },

  loadingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  receiptSection: {
    gap: 5,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
