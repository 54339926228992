import { useEffect, useState } from 'react';
import { Button, Center, Image, Pagination, Table, Title } from '@mantine/core';
import flexbaseClient from 'services/flexbase-client';
import TableRow from './table-row/table-row';
import ModalForm from './modal-form/modal-form';
import useModal from 'components/modal/modal-hook';
import { patronStyles } from './patrons.styles';

interface IPatronInfo {
  id?: string;
  name?: string;
  address?: string;
  tenantId?: string;
  imageUrl?: string;
  city?: string;
  companyId?: string;
  country?: string;
  state?: string;
  postalCode?: string;
}

const Patrons = () => {
  const [allPatrons, setAllPatrons] = useState<IPatronInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [messageType, setMessageType] = useState<string | undefined>('');
  const indexOfLastPost = currentPage * 8;
  const indexOfFirstPost = indexOfLastPost - 8;
  const totalPageCount = Math.ceil(allPatrons.length / 8);
  const patrons = allPatrons.slice(indexOfFirstPost, indexOfLastPost);

  const { classes } = patronStyles();

  const modal = useModal();
  const closeModal = modal.closeAllModals;

  const openModalForm = () => {
    screen.width <= 767
      ? modal.openCenterModal(<ModalForm {...{ closeModal, setAllPatrons }} />)
      : modal.openRightModal(<ModalForm {...{ closeModal, setAllPatrons }} />);
  };

  const btnAddClientSize = screen.width <= 767 ? 'sm' : 'md';

  const displayMessage =
    messageType === 'noPatrons'
      ? {
          title: '',
          text: 'You don’t have any clients yet. You can create the first one by clicking on the button below.',
          showBtnAddClient: true,
        }
      : {
          title: 'Something went wrong',
          text: "We keep track of these errors, but feel free to contact us if refreshing doesn't fix things",
          showBtnAddClient: false,
        };

  const getPatrons = async () => {
    try {
      const response = await flexbaseClient.getPatrons();
      if (response.length > 0) {
        setAllPatrons(
          response.map((patron: any) => {
            return {
              ...patron,
            };
          }),
        );
      } else {
        setMessageType('noPatrons');
      }
    } catch (error) {
      console.error(`Error when trying to obtain patrons' information`, error);
      setMessageType('error');
    }
  };

  useEffect(() => {
    getPatrons();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <Title className="title"> Clients 👷🏽‍♂️</Title>
        <div>
          <Button
            onClick={() => openModalForm()}
            fullWidth
            radius={8}
            color="flexbase-teal"
            size={btnAddClientSize}
          >
            + Add Client
          </Button>
        </div>
      </div>

      {messageType !== '' ? (
        <div className={classes.containerMessage}>
          <div>
            <Image
              src="/images/noPatrons.svg"
              alt="No patrons found"
              height={200}
              width="100%"
            />
          </div>
          <div>
            {displayMessage.title !== '' ? (
              <Center mt="5%">
                <Title data-testid={'display-message-title'}>
                  {displayMessage.title}
                </Title>
              </Center>
            ) : null}

            <Center mt="5%">
              <p
                data-testid={'display-message-text'}
                className={classes.messageText}
              >
                {displayMessage.text}
              </p>
            </Center>
            {displayMessage.showBtnAddClient === true ? (
              <Center>
                <Button
                  onClick={() =>
                    modal.openRightModal(
                      <ModalForm {...{ closeModal, setAllPatrons }} />,
                    )
                  }
                  fullWidth
                  radius={8}
                  color="flexbase-teal"
                  size="md"
                >
                  + Add Client
                </Button>
              </Center>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <Table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Postal Code</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {patrons.map((patron) => (
                <TableRow key={patron.id} {...{ setAllPatrons, patron }} />
              ))}
            </tbody>
          </Table>
          <Pagination
            style={{ marginTop: '1%' }}
            total={totalPageCount}
            value={currentPage}
            onChange={(page) => {
              setCurrentPage(page);
              scroll(0, 0);
            }}
            color="flexbase-teal"
            position="right"
          />
        </>
      )}
    </div>
  );
};

export default Patrons;
