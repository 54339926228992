import { createStyles, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import HelpActionButtons from '../help-action-button/help-action-buttons';
import UserActionButtons from '../user-action-buttons/user-action-buttons';
import BankingActionButton from '../banking-action-button/banking-action-button';
import { useRecoilValue } from 'recoil';
import {
  CompanySelector,
  IsAdmin,
} from '../../../states/application/product-onboarding';

interface Props {
  title?: string;
  center?: boolean;
}

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0',
    backgroundColor: theme.fn.themeColor('neutral', 2),
  },
  headerTitle: {
    fontFamily: 'Redaction',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '28px',
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  centeredHeaderTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '28px',
    color: '#5F5F5F',
    marginLeft: 'auto',
  },
  actionButtons: {
    gap: '24px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const FlexbaseDefaultHeader = ({ title, center }: Props) => {
  const { classes } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const { optedProducts } = useRecoilValue(CompanySelector);

  return (
    <div className={classes.header}>
      <Text
        className={center ? classes.centeredHeaderTitle : classes.headerTitle}
        data-testid="text-default-header"
      >
        {title}
      </Text>
      {!useMobileView && (
        <div className={classes.actionButtons}>
          {optedProducts.includes('BANKING') && isAdmin && (
            <BankingActionButton />
          )}
          <HelpActionButtons />
          <UserActionButtons />
        </div>
      )}
    </div>
  );
};

export default FlexbaseDefaultHeader;
