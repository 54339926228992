import { useState, useEffect } from 'react';
import {
  Grid,
  UnstyledButton,
  LoadingOverlay,
  useMantineTheme,
  Progress,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import SetPin from './set-pin';
import TwoFA from '../two-factor/unitco-token';
import { CloseIcon, FlexIcon } from 'assets/svg';
import { useStyles } from './activate-debit-card-styles';
import InputDebitCardData from './input-debit-card-data';
import Stepper from 'components/stepper/stepper';
import useModal from 'components/modal/modal-hook';
import ActivateDebitCardError from './activate-debit-card-error';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { CardByUser } from 'services/flexbase/banking.model';
import ModalSuccess from 'areas/banking/components/modal-success/modal-success';

interface Props {
  card: CardByUser;
  closeModal: () => void;
  updateDebitCardsList: (x: any) => void;
}

enum ActiveCardSteps {
  GET_TOKEN,
  INPUT_SENSITIVE_DATA,
  SET_PIN,
  ERROR,
  SUCCESS,
}

const ActivateDebitCard = ({
  card,
  closeModal,
  updateDebitCardsList,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { closeAllModals } = useModal();
  const unitcoCardId = card.ucCardId.substring(5);
  const [isLoading, setIsLoading] = useState(false);
  const [bearerToken, setBearerToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [loadingAction, setLoadingAction] = useState(false);
  const [step, setStep] = useState(ActiveCardSteps.GET_TOKEN);
  const [verificationToken, setVerificationToken] = useState('');

  const init = async () => {
    try {
      setIsLoading(true);
      const unitToken = await flexbaseBankingClient.getUnitcoToken();
      if (unitToken.success && unitToken.attributes) {
        setVerificationToken(unitToken.attributes.verificationToken);
      } else {
        setStep(ActiveCardSteps.ERROR);
      }
    } catch (error) {
      console.error('Eror updating costumer token', error);
      setStep(ActiveCardSteps.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    updateDebitCardsList({ ...card, status: 'active' });
    setStep(ActiveCardSteps.SET_PIN);
  };

  const activateCard = async (form: any) => {
    setErrorMessage('');
    setLoadingAction(true);
    await form.submit(
      '/cards/' + unitcoCardId + '/activate',
      {
        // This converts the dot-separated field name strings into a JSON object
        mapDotToObject: 'true',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: 'Bearer ' + bearerToken,
        },
      },
      (status: any, data: any) => {
        setLoadingAction(false);
        if (status === 200) {
          return nextStep();
        } else if (status === 400) {
          return setErrorMessage(data.errors[0].title);
        }
      },
    );
  };

  const setPin = async (form: any) => {
    setErrorMessage('');
    setLoadingAction(true);
    await form.submit(
      '/cards/' + unitcoCardId + '/secure-data/pin',
      {
        // This converts the dot-separated field name strings into a JSON object
        mapDotToObject: 'true',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Authorization: 'Bearer ' + bearerToken,
        },
      },
      (status: any, data: any) => {
        setLoadingAction(false);
        if (status === 200) {
          setStep(ActiveCardSteps.SUCCESS);
        } else {
          setErrorMessage(data.errors[0].title);
        }
      },
    );
  };

  useEffect(() => {
    init();
  }, []);

  let currentStep;
  switch (step) {
    case ActiveCardSteps.GET_TOKEN:
      currentStep = (
        <div style={{ width: 420 }}>
          <TwoFA
            token={verificationToken}
            setBearerToken={setBearerToken}
            stepName={ActiveCardSteps.INPUT_SENSITIVE_DATA}
            setStep={setStep}
          />
        </div>
      );
      break;

    case ActiveCardSteps.INPUT_SENSITIVE_DATA:
      currentStep = (
        <InputDebitCardData
          loading={loadingAction}
          {...{ card, errorMessage }}
          onNextClick={(f) => activateCard(f)}
        />
      );
      break;

    case ActiveCardSteps.SET_PIN:
      currentStep = (
        <SetPin
          loading={loadingAction}
          cardStatus="issued"
          {...{ card, errorMessage }}
          onNextClick={(f) => setPin(f)}
        />
      );
      break;

    case ActiveCardSteps.SUCCESS:
      currentStep = (
        <ModalSuccess
          backTo="debit cards"
          onClick={closeModal}
          {...{ closeModal }}
          title="Your card has been activated successfully"
        />
      );
      break;

    case ActiveCardSteps.ERROR:
      currentStep = <ActivateDebitCardError onClick={closeModal} />;
      break;
  }

  if (isLoading) {
    return (
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: theme.fn.primaryColor() }}
      />
    );
  }

  if (useMobileView) {
    return (
      <div className={classes.activateDebitCard}>
        <>
          <UnstyledButton className="close-button" onClick={closeModal}>
            <CloseIcon color="#979797" />
          </UnstyledButton>
          {step < ActiveCardSteps.ERROR && (
            <Progress radius="sm" value={((step + 1) * 100) / 3} />
          )}
        </>
        <div className="content">{currentStep}</div>
      </div>
    );
  }

  return (
    <Grid className={classes.activateDebitCard}>
      <Grid.Col span={3}>
        <FlexIcon width={90} />
        {step < ActiveCardSteps.ERROR && (
          <Stepper
            activeStep={step}
            stepsData={[
              'Verification required',
              'Input CVV and expiration date',
              'Set pin',
            ]}
          />
        )}
      </Grid.Col>

      <Grid.Col className="content" span={6}>
        <div className={classes.card}>{currentStep}</div>
      </Grid.Col>

      <Grid.Col span={3} className="close">
        <UnstyledButton onClick={closeAllModals}>
          <CloseIcon width={34} color="#979797" />
        </UnstyledButton>
      </Grid.Col>
    </Grid>
  );
};

export default ActivateDebitCard;
