import OnboardingEndScreen from '../../../components/onboarding-end-screen';
import DeclineGraphic from 'assets/images/decline-state.png';
import { useRecoilValue } from 'recoil';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { merchantCallback } from 'utilities/url/get-bnpl-merchant-callback';
import { useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../../../../states/auth/auth-token';

const DeclinedSoleProp = () => {
  const { logout } = useAuthToken();
  const { isBnpl, callbackBaseUrl, callbackParam, sessionParam } =
    useRecoilValue(BnplState);
  const navigate = useNavigate();

  const closeApp = () => {
    if (isBnpl) {
      window.location.replace(
        merchantCallback(
          callbackBaseUrl,
          TransactionStatus.declined,
          callbackParam,
          sessionParam,
        ),
      );
    } else {
      logout();
    }
  };

  return (
    <OnboardingEndScreen
      title="Sorry, we currently don’t offer this credit card sole-proprietorships"
      description={
        'We will be in touch when that changes, and in the meantime we have added you to the early access list for Flex Banking!'
      }
      graphic={DeclineGraphic}
      primaryButtonText="Close application"
      onPrimaryButtonClick={() => closeApp()}
      secondaryButtonText="Edit application"
      onSecondaryButtonClick={() => {
        navigate(-1);
      }}
    />
  );
};
export default DeclinedSoleProp;
