import { createStyles } from '@mantine/core';
import React, { ReactNode } from 'react';
import OnboardingButtonGroup from 'areas/onboarding-v2/components/onboarding-button-group';

type Props = {
  children: ReactNode;
  titleText?: string;
  title?: ReactNode;
  balloonText?: string;
  showBackButton?: boolean;
  showNextButton?: boolean;
  onBackClick?: () => void;
  onNextClick?: () => void;
  nextButtonLabel?: string;
  backButtonLabel?: string;
};

const PaymentStep = ({
  children,
  balloonText,
  showBackButton = true,
  showNextButton = true,
  onNextClick,
  onBackClick,
  titleText,
  nextButtonLabel,
  backButtonLabel,
  title,
}: Props) => {
  const { classes } = useStyles();

  const onNext = () => {
    if (onNextClick) {
      onNextClick();
    }
  };

  const onBack = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  return (
    <div className={classes.container}>
      {balloonText && (
        <div className={classes.recipientBalloon}>{balloonText}</div>
      )}
      {titleText && <div className={classes.title}>{titleText}</div>}
      {title && <>{title}</>}
      <div className={classes.inputContainer}>{children}</div>
      <div className={classes.buttonContainer}>
        <OnboardingButtonGroup
          showNextButton={showNextButton}
          showBackButton={showBackButton}
          onNextClick={() => onNext()}
          onBackClick={() => onBack()}
          nextButtonLabel={nextButtonLabel}
          backButtonLabel={backButtonLabel}
        />
      </div>
    </div>
  );
};

export const getPrettyMethod = (method: string) => {
  if (method === 'ach') {
    return 'ACH';
  }
  if (method === 'wire') {
    return 'Wire';
  }
  if (method === 'flexPay') {
    return 'Flexbase Pay';
  }
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '520px',
    display: 'flex',
    flexDirection: 'column',
  },
  recipientBalloon: {
    backgroundColor: theme.fn.themeColor('neutral', 2),
    borderRadius: theme.defaultRadius,
    marginBottom: '20px',
    height: '48px',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.fn.primaryColor(),
    fontSize: '14px',
    fontWeight: 500,
  },
  title: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: 32,
    lineHeight: '34px',
    '@media(max-width: 767px)': {
      fontSize: 24,
    },
  },
  inputContainer: {
    marginTop: '22px',
  },
  buttonContainer: {
    marginTop: 'auto',
  },
}));

export default PaymentStep;
