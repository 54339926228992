import { useMediaQuery } from '@mantine/hooks';
import { Alert, Button, Image, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { loadVGSCollect } from '@vgs/collect-js';
import { CardByUser } from 'services/flexbase/banking.model';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { RedAlertIcon } from 'assets/svg';

interface Props {
  card: CardByUser;
  loading: boolean;
  errorMessage: string;
  onNextClick: (f: any) => void;
}

const InputDebitCardData = ({
  card,
  loading,
  errorMessage,
  onNextClick,
}: Props) => {
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [errors, setErrors] = useState({
    cvv: '',
    expirationDate: '',
    validationCvvMessage: '',
    validationDateMessage: '',
  });
  const [form, setForm] = useState({
    submit: (path: string, req: any, result: any) => void 0,
  });

  const onClick = () => {
    setErrors({
      ...errors,
      validationCvvMessage: '',
      validationDateMessage: '',
    });
    if (errors.expirationDate.includes('is required')) {
      setErrors({
        ...errors,
        validationDateMessage: '*This field is required',
      });
    } else if (
      errors.expirationDate.includes('is not a valid expiration date')
    ) {
      setErrors({
        ...errors,
        validationDateMessage: '*Enter a valid date format (MM/YYYY)',
      });
    } else if (errors.cvv.includes('is required')) {
      setErrors({
        ...errors,
        validationCvvMessage: '*This field is required',
      });
    } else if (errors.cvv.includes('is not a valid security code')) {
      setErrors({
        ...errors,
        validationCvvMessage: '*The security code should be with three-digit',
      });
    } else {
      onNextClick(form);
    }
  };

  useEffect(() => {
    const loadForm = async () => {
      const vgs_collect = await loadVGSCollect({
        vaultId: 'tntazhyknp1',
        environment: 'sandbox',
        version: '2.18.0',
      }).catch((e) => {
        console.log(e);
      });
      initForm(vgs_collect);
    };
    loadForm();
  }, []);

  const initForm = (vgs_collect: any) => {
    const form = vgs_collect.init((state: any) => {
      setErrors({
        ...errors,
        cvv: state['data.attributes.cvv2'].errorMessages,
        expirationDate: state['data.attributes.expirationDate'].errorMessages,
      });
    });
    form.field('#cc-expiration-date', {
      type: 'card-expiration-date',
      name: 'data.attributes.expirationDate',
      successColor: theme.colors.positive[1],
      errorColor: theme.colors.critical[2],
      placeholder: 'MM / YYYY',
      yearLength: 4,
      validations: ['required', 'validCardExpirationDate'],
      // Change to required experiation date format (YYYY-MM)
      serializers: [
        form.SERIALIZERS.replace('(\\d{2})\\s\\/\\s(\\d{4})', '$2-$1'),
      ],
      css: {
        '&::placeholder': {
          color: '#DFDFDF',
        },
      },
    });
    form.field('#cc-cvv2', {
      type: 'card-security-code',
      name: 'data.attributes.cvv2',
      successColor: theme.colors.positive[1],
      errorColor: theme.colors.critical[2],
      placeholder: '123',
      maxLength: 3,
      validations: ['required', 'validCardSecurityCode'],
      css: {
        '&::placeholder': {
          color: '#DFDFDF',
        },
      },
    });
    setForm(form);
  };

  return (
    <div className="input-data">
      <span className="title">
        Activate debit card {GetPaddedAccountMask(card.cardNumber, 3)}
      </span>
      <p>You’ll find this data on the back of the card.</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="group">
          <label>
            <span>Expiration date</span>
            <div id="cc-expiration-date" className="field" />
          </label>
          <p>{errors.validationDateMessage}</p>
          <label>
            <span>CVV</span>
            <div id="cc-cvv2" className="field" />
          </label>
          <p>{errors.validationCvvMessage}</p>
        </div>
      </div>

      <div className="image-container">
        <Image
          width={useMobileView ? 300 : 350}
          src="/images/back-debit-card.png"
        />
      </div>

      {errorMessage && errorMessage !== '' && (
        <Alert mt={20} icon={<RedAlertIcon />} color="red">
          {errorMessage}
        </Alert>
      )}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          type="submit"
          {...{ loading }}
          variant="light"
          onClick={onClick}
        >
          Activate card
        </Button>
      </div>
    </div>
  );
};

export default InputDebitCardData;
