export const getEnvironment = (
  host: string = window.location.host,
): 'local' | 'development' | 'production' | string => {
  if (host.includes('localhost')) {
    return 'local';
  } else if (
    host.includes('dev.flexbase.app') ||
    host.includes('dev.flex.one')
  ) {
    return 'development';
  } else if (
    host.includes('home.flexbase.app') ||
    host.includes('home.flex.one')
  ) {
    //|| host.includes('demo.flex.one') if we want demo env to hold the same navtab rules
    return 'production';
  } else if (host.includes('netlify.app')) {
    return host.split('.')[0];
  }
  return '';
};
