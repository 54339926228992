import { useFormStyles } from './form.styles';
import FormBase from './form-base';
import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';

type Props = {
  onClose: () => void;
  onSubmit: (firstName: string, lastName: string) => void;
  currentFirstName?: string;
  currentLastName?: string;
};

const NameForm = ({
  onClose,
  onSubmit,
  currentFirstName,
  currentLastName,
}: Props) => {
  const { classes } = useFormStyles();

  const form = useForm({
    initialValues: {
      firstName: currentFirstName || '',
      lastName: currentLastName || '',
    },
    validate: {
      firstName: (val) =>
        validateRequired(val) ? null : 'First name is required',
      lastName: (val) =>
        validateRequired(val) ? null : 'Last name is required',
    },
  });

  const onUpdateClick = () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      const { firstName, lastName } = form.values;
      onSubmit(firstName, lastName);
    }
  };

  return (
    <FormBase onCancel={onClose} onSubmit={onUpdateClick}>
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        label={'First name'}
        data-testid="first-name-input"
        size="lg"
        {...form.getInputProps('firstName')}
      />
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        style={{ marginTop: '10px' }}
        label={'Last name'}
        data-testid="last-name-input"
        size="lg"
        {...form.getInputProps('lastName')}
      />
    </FormBase>
  );
};

export default NameForm;
