import {
  Grid,
  Image,
  Badge,
  Alert,
  Button,
  Avatar,
  Loader,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { IoMdCheckmark } from 'react-icons/io';
import { AiFillCloseCircle } from 'react-icons/ai';
import { showNotification } from '@mantine/notifications';

import { cardStyles } from '../cards.styles';
import { Card } from 'states/cards/card-info';
import { ChipIcon, MastercardLogo } from 'assets/svg';
import useModal from 'components/modal/modal-hook';
import flexbaseClient from 'services/flexbase-client';

interface IModalForm {
  card: Card;
  updateCompanyCards: (card: Card) => void;
  amountSpentThisMonth: number;
}

const ActivateCard = ({
  card,
  updateCompanyCards,
  amountSpentThisMonth,
}: IModalForm) => {
  const theme = useMantineTheme();
  const { classes } = cardStyles();
  const { closeAllModals } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [last4Numbers, setLast4Numbers] = useState('');
  const [errorMessage, setMessageError] = useState('');

  const activatePhysicalCard = async () => {
    try {
      setIsLoading(true);
      const response = await flexbaseClient.updateCardStatus(
        card.id,
        'active',
        last4Numbers,
      );
      if (!response.success) {
        throw Error(
          JSON.stringify({ errorMessage: 'Unable to activate the card' }),
        );
      }
      const utilization = response?.card?.expensesTypes?.amount
        ? Math.round(
            (amountSpentThisMonth / response?.card.expensesTypes.amount) * 100,
          )
        : 0;
      updateCompanyCards({
        ...response.card!,
        monthToDateSpends: { mtdSpend: amountSpentThisMonth },
        utilization,
      });
      closeAllModals();
      showNotification({
        title: 'Success!',
        message: 'Your card is now activated',
        color: 'flexbase-teal',
        icon: <IoMdCheckmark />,
      });
    } catch (error) {
      console.error('ACTIVATE CARD ERROR', error);
      setMessageError(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {screen.width <= 767 ? (
        <div className={classes.activateCardContent}>
          <div className="card">
            <span>Activate your card</span>
            <p className="sub-text">Enter the last four digits of your card</p>
            {isLoading ? (
              <div className="loader-content">
                <div>
                  <Loader size="sm" variant="dots" color="gray" />
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextInput
                  maxLength={4}
                  placeholder="0000"
                  variant="unstyled"
                  value={last4Numbers}
                  onChange={(e) => setLast4Numbers(e.currentTarget.value)}
                  styles={{
                    root: { width: '130px', marginTop: '30px' },
                    input: {
                      fontSize: '48px',
                      textAlign: 'center',
                      height: 'fit-content',
                      borderBottom: `3px solid ${theme.fn.primaryColor()}`,
                      '&:focus': {
                        borderBottom: `3px solid ${theme.fn.primaryColor()}`,
                      },
                    },
                  }}
                />
              </div>
            )}
            <div className="credit-card">
              <div className="row-content" style={{ marginBottom: '20px' }}>
                <Image
                  width={70}
                  height={15}
                  style={{ opacity: 0.5 }}
                  src="/svg/flexbaseLogoNew.svg"
                />
                <Badge className="badge">Unactivated</Badge>
              </div>
              <ChipIcon width={42} style={{ opacity: 0.5 }} />
              <div
                className="row-content"
                style={{
                  opacity: 0.5,
                  marginTop: '12px',
                  alignItems: 'flex-end',
                }}
              >
                <div>
                  <p style={{ fontSize: '12px' }}>Flexbase Technologies</p>
                  <p>{card.holder}</p>
                  <p>•••• •••• •••• ••••</p>
                  <p>EXP ••/•• CVC •••</p>
                </div>
                <div>
                  <p style={{ fontSize: '10px', textAlign: 'end' }}>Credit</p>
                  <MastercardLogo />
                </div>
              </div>
            </div>

            {errorMessage && errorMessage !== '' && (
              <Alert
                mb={30}
                color="red"
                className="error"
                icon={<AiFillCloseCircle size={20} />}
              >
                {errorMessage}
              </Alert>
            )}

            <Button
              fullWidth
              radius="md"
              color={theme.fn.primaryColor()}
              onClick={activatePhysicalCard}
              disabled={last4Numbers.length < 4}
              styles={{
                root: {
                  height: '40px',
                  '&:disabled': {
                    opacity: 0.5,
                    color: '#fff !important',
                    backgroundColor: `${theme.fn.primaryColor()} !important`,
                  },
                },
              }}
            >
              Activate card
            </Button>

            <UnstyledButton onClick={closeAllModals} className="close-sm">
              Cancel
            </UnstyledButton>
          </div>
        </div>
      ) : (
        <Grid className={classes.activateCardContent}>
          <Grid.Col span={3}>
            <Avatar
              size={42}
              radius="md"
              color={theme.fn.primaryColor()}
              styles={{ placeholder: { color: '#fff', fontSize: '20px' } }}
            >
              fb
            </Avatar>
          </Grid.Col>

          <Grid.Col className="content" span={6}>
            <div>
              <div className="card">
                <span>Activate your card</span>
                <p className="sub-text">
                  Enter the last four digits of your card
                </p>
                {isLoading ? (
                  <div className="loader-content">
                    <div>
                      <Loader size="sm" variant="dots" color="gray" />
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextInput
                      maxLength={4}
                      placeholder="0000"
                      variant="unstyled"
                      value={last4Numbers}
                      onChange={(e) => setLast4Numbers(e.currentTarget.value)}
                      styles={{
                        root: { width: '130px', marginTop: '30px' },
                        input: {
                          fontSize: '48px',
                          textAlign: 'center',
                          height: 'fit-content',
                          borderBottom: `3px solid ${theme.fn.primaryColor()}`,
                          '&:focus': {
                            borderBottom: `3px solid ${theme.fn.primaryColor()}`,
                          },
                        },
                      }}
                    />
                  </div>
                )}
                <div className="credit-card">
                  <div className="row-content" style={{ marginBottom: '20px' }}>
                    <Image
                      width={70}
                      height={15}
                      style={{ opacity: 0.5 }}
                      src="/svg/flexbaseLogoNew.svg"
                    />
                    <Badge className="badge">Unactivated</Badge>
                  </div>
                  <ChipIcon width={42} style={{ opacity: 0.5 }} />
                  <div
                    className="row-content"
                    style={{
                      opacity: 0.5,
                      marginTop: '12px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div>
                      <p style={{ fontSize: '12px' }}>Flexbase Technologies</p>
                      <p>{card.holder}</p>
                      <p>•••• •••• •••• ••••</p>
                      <p>EXP ••/•• CVC •••</p>
                    </div>
                    <div>
                      <p style={{ fontSize: '10px', textAlign: 'end' }}>
                        Credit
                      </p>
                      <MastercardLogo />
                    </div>
                  </div>
                </div>

                {errorMessage && errorMessage !== '' && (
                  <Alert
                    mb={30}
                    color="red"
                    className="error"
                    icon={<AiFillCloseCircle size={20} />}
                  >
                    {errorMessage}
                  </Alert>
                )}

                <Button
                  fullWidth
                  radius="md"
                  color={theme.fn.primaryColor()}
                  onClick={activatePhysicalCard}
                  disabled={last4Numbers.length < 4}
                  styles={{
                    root: {
                      height: '40px',
                      '&:disabled': {
                        opacity: 0.5,
                        color: '#fff !important',
                        backgroundColor: `${theme.fn.primaryColor()} !important`,
                      },
                    },
                  }}
                >
                  Activate card
                </Button>
              </div>
            </div>
          </Grid.Col>

          <Grid.Col span={3} className="close">
            <UnstyledButton onClick={closeAllModals}>
              <IoClose size={34} color="#979797" />
              <p>esc</p>
            </UnstyledButton>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default ActivateCard;
