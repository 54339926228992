/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, forwardRef, SetStateAction, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Input,
  Loader,
  Title,
  Text,
  AutocompleteItem,
  Autocomplete,
  SelectItemProps,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaCheck, FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { modalFormStyles } from './modal-form.styles';
import flexbaseClient from 'services/flexbase-client';
import { useDebouncedCallback } from 'use-debounce';

interface ItemProps extends SelectItemProps {
  label: string;
  description: string;
}

interface IPatronInfo {
  id?: string;
  name?: string;
  address?: string;
  tenantId?: string;
  imageUrl?: string;
  city?: string;
  companyId?: string;
  country?: string;
  state?: string;
  postalCode?: string;
}

interface IModalForm {
  closeModal(): any;
  patron?: IPatronInfo;
  setAllPatrons: Dispatch<SetStateAction<IPatronInfo[]>>;
}

const ModalForm = ({ closeModal, patron, setAllPatrons }: IModalForm) => {
  const { classes } = modalFormStyles();
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<any>([]);
  const debounced = useDebouncedCallback((value) => getAddresses(value), 200, {
    maxWait: 1000,
    leading: true,
  });

  const inputSize = screen.width <= 767 ? 'md' : 'xl';
  const btnAddUpdatePatronSize = screen.width <= 767 ? 'md' : 'lg';

  const theForm = useForm<IPatronInfo>({
    initialValues: {
      id: patron?.id || '',
      name: patron?.name || '',
      address: patron?.address || '',
      state: patron?.state || '',
      city: patron?.city || '',
      postalCode: patron?.postalCode || '',
    },
  });

  const addPatron = async (values: IPatronInfo) => {
    try {
      setIsLoading(true);
      const response = await flexbaseClient.addOrUpdatePatron(values);
      if (!response) {
        throw Error(
          JSON.stringify({ errorMessage: 'Unable to add a new client' }),
        );
      }
      setAllPatrons((prevPatrons: any[]) => [...prevPatrons, response]);
      setTypeMessage('success');
      setMessage('You have a new client!');
    } catch (error) {
      console.error('ADD PATRON ERROR', error);
      setTypeMessage('error');
      setMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePatron = async (values: IPatronInfo) => {
    try {
      setIsLoading(true);
      const response = await flexbaseClient.addOrUpdatePatron(values);
      if (!response) {
        throw Error(
          JSON.stringify({ errorMessage: 'Unable to update the client data' }),
        );
      }
      setAllPatrons((prevPatrons: any[]) =>
        prevPatrons.map((patron) => {
          if (response?.id === patron.id) {
            return { ...patron, ...response };
          }
          return patron;
        }),
      );
      setTypeMessage('success');
      setMessage('The information has been updated!');
    } catch (error) {
      console.error('UPDATE PATRON ERROR', error);
      setTypeMessage('error');
      setMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const savePatron = (values: IPatronInfo) => {
    if (values.id) {
      updatePatron(values);
    } else {
      addPatron(values);
    }
  };

  const getAddresses = (street: string) => {
    flexbaseClient.getAddressPreview(street).then((x) => {
      setAddresses(
        x.map((y: any) => {
          return {
            value: y.street1 + y.city + y.postalCode,
            label: y.street1,
            description: `${y.city} ${y.state} ${y.postalCode}`,
            addr: y,
          };
        }),
      );
    });
  };

  const setStreet = (street: string) => {
    theForm.setFieldValue('address', street);
    debounced(street);
  };

  const selectAddress = (item: AutocompleteItem) => {
    const addr = item['addr'];
    theForm.setFieldValue('address', addr.street1);
    theForm.setFieldValue('city', addr.city);
    theForm.setFieldValue('state', addr.state);
    theForm.setFieldValue('postalCode', addr.postalCode);
  };

  const AddressAutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ description, label, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Text size="md">{label}</Text>
        <Text size="md" color="dimmed">
          {description}
        </Text>
      </div>
    ),
  );

  AddressAutoCompleteItem.displayName = 'AddressAutoCompleteItem';

  return (
    <Container p="3rem" style={{ position: 'relative' }}>
      <button
        type="button"
        className={classes.btnCloseModal}
        onClick={() => {
          closeModal();
        }}
      >
        <FaTimes size={20} />
      </button>
      <div>
        <Title aria-label="title" className={classes.title}>
          {theForm.values?.id ? 'Edit Client' : 'Create Client'}
        </Title>
      </div>
      <form id="addOrUpdatePatron" onSubmit={theForm.onSubmit(savePatron)}>
        <Container mt="1.5rem" p="0">
          <label htmlFor="name">Name</label>
          <Input
            mt="0.3rem"
            size={inputSize}
            radius="md"
            id="name"
            name="name"
            aria-label="name"
            type="text"
            {...theForm.getInputProps('name')}
          />
        </Container>
        <Container mt="1.5rem" p="0" style={{ position: 'relative' }}>
          <label htmlFor="address">Address</label>
          <Autocomplete
            id="address"
            name="address"
            size={inputSize}
            radius="md"
            mt="0.3rem"
            {...theForm.getInputProps('address')}
            data={addresses}
            onChange={(value) => setStreet(value)}
            onItemSubmit={selectAddress}
            itemComponent={AddressAutoCompleteItem}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <label htmlFor="state">State</label>
          <Input
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="state"
            name="state"
            aria-label="state"
            type="state"
            {...theForm.getInputProps('state')}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <label htmlFor="city">City</label>
          <Input
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="city"
            name="city"
            aria-label="city"
            type="text"
            {...theForm.getInputProps('city')}
          />
        </Container>
        <Container mt="1.5rem" p="0">
          <label htmlFor="postalCode">Postal Code</label>
          <Input
            size={inputSize}
            radius="md"
            mt="0.3rem"
            id="postalCode"
            name="postalCode"
            aria-label="postalCode"
            type="text"
            {...theForm.getInputProps('postalCode')}
          />
        </Container>
      </form>
      {message !== '' ? (
        <div style={{ marginTop: '1.5rem' }}>
          <Alert
            icon={
              typeMessage === 'success' ? (
                <FaCheck color="black" />
              ) : (
                <FaExclamationCircle color="black" />
              )
            }
            color={typeMessage === 'success' ? 'green' : 'red'}
          >
            <Text color="black">{message}</Text>
          </Alert>
        </div>
      ) : null}

      <div className={classes.buttonHolder}>
        <Button
          type="submit"
          form="addOrUpdatePatron"
          aria-label="btnAddOrUpdatePatron"
          color="flexbase-teal"
          size={btnAddUpdatePatronSize}
          fullWidth
        >
          {theForm.values?.id ? 'Update Client' : 'Add Client'}{' '}
          {isLoading ? (
            <Loader size={18} color="#fff" style={{ marginLeft: '10px' }} />
          ) : null}
        </Button>
      </div>
    </Container>
  );
};

export default ModalForm;
