import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Checkbox, createStyles, Stack } from '@mantine/core';

import { Group } from 'states/cards/card-info';
import Input from 'components/input/flexbase-input';

const useStyles = createStyles((theme) => ({
  dropdown: {
    zIndex: 1,
    width: '100%',
    padding: '15px',
    overflow: 'auto',
    marginTop: '5px',
    maxHeight: '250px',
    borderRadius: '5px',
    position: 'absolute',
    backgroundColor: '#fff',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    boxShadow: '0 1px 3px rgb(0 0 0 / 5%)',
  },
  input: {
    borderColor: '#ebf0f7 !important',
    backgroundColor: '#f9fbfe !important',
  },
  label: {
    color: '#5F5F5F',
    fontSize: '14px',
    fontWeight: 500,
  },
  icon: {
    color: '#1ec98b',
  },
}));

interface ItemProps {
  form: any;
  setHasChanges: (x: boolean) => void;
}

const Multiselect = ({ form, setHasChanges }: ItemProps) => {
  const { classes } = useStyles();
  const [show, setShow] = useState(false);
  const actionViewRef = useRef<HTMLDivElement>(null);
  const [isUnrestricted, setIsUnrestricted] = useState(
    form.values.groups.length === 0 ? true : false,
  );

  const itemsSelected = `${
    form.values?.groups.includes('UNRESTRICTED')
      ? '0'
      : form.values.groups.length
  } categories selected`;

  const handleClickOutside = (e: any) => {
    if (!actionViewRef?.current?.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (isUnrestricted) {
      form.setFieldValue('groups', []);
    }
  }, [isUnrestricted]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Input
        readOnly={true}
        value={itemsSelected}
        label="Spend restrictions"
        onClick={() => setShow(true)}
        style={{ marginTop: '15px' }}
        rightSection={
          show ? (
            <IoIosArrowUp color="#979797" />
          ) : (
            <IoIosArrowDown color="#979797" />
          )
        }
      />

      {show && (
        <div ref={actionViewRef} className={classes.dropdown}>
          <Checkbox
            label="No Restrictions"
            checked={isUnrestricted}
            classNames={classes}
            onChange={(event) => {
              setHasChanges(true);
              setIsUnrestricted(event.currentTarget.checked);
            }}
            styles={{ root: { marginBottom: '7px' } }}
          />
          <Checkbox.Group
            size="sm"
            onChange={(value) => {
              setHasChanges(true);
              setIsUnrestricted(false);
              form.setFieldValue('groups', value as Group[]);
            }}
            value={form.values?.groups}
            classNames={classes}
          >
            <Stack spacing="sm">
              <Checkbox label="Material suppliers" value="MATERIALSUPPLIERS" />
              <Checkbox label="Convenience stores" value="CONVENIENCESTORES" />
              <Checkbox label="Food and beverage" value="FOODANDBEVERAGE" />
              <Checkbox label="Equipment" value="EQUIPMENT" />
              <Checkbox
                label="Office supplies and cleaning"
                value="OFFICESUPPLIESANDCLEANING"
              />
              <Checkbox label="Fuel and Gas" value="FUELANDGAS" />
            </Stack>
          </Checkbox.Group>
        </div>
      )}
    </div>
  );
};

export default Multiselect;
