import { Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';
import { DateTime } from 'luxon';

const RiskPDFFooter = () => {
  return (
    <>
      <View style={stylesStatementPDF.footerContainer} fixed>
        <View>
          <Text>Flexbase Technologies Inc</Text>
          <Text> 2261 Market Street #4552 San Francisco, CA, 94114</Text>
        </View>
        <View>
          <Text>Generated on {DateTime.now().toFormat('LLL dd, yyyy')}</Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
        </View>
      </View>
    </>
  );
};

export default RiskPDFFooter;
