import { useRecoilState, useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import { DebitCard } from 'flexbase-client';
import { useEffect, useState, useCallback } from 'react';
import { Avatar, Badge, Button, Image, Text } from '@mantine/core';

import { useStyles } from '../styles';
import UnitcoToken from './two-factor/unitco-token';
import useModal from 'components/modal/modal-hook';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import IssueDebitCard from './issue-debit-card/issue-debit-card';
import FlexbaseTable from 'components/table/flexbase-table';

import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import DebitCardDetails from './debit-card-details/debit-card-details';
import { getInitialsOfNames } from 'utilities/extensions/object';
import { CardByUser, DepositAccount } from 'services/flexbase/banking.model';
import SkeletonLoading from 'components/loading/skeleton-loading';
import { formatCurrency } from 'utilities/formatters/format-currency';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import Header from './debit-cards-header/debit-cards-header';
import { Filter } from '../components/filter/filter-models';
import { FilterListState } from '../components/filter/filter-states';
import WatchBAnking from 'assets/images/watch-banking.png';

interface Props {
  appType: string;
  bankingAccounts: DepositAccount[];
}

const DebitCards = ({ appType, bankingAccounts }: Props) => {
  const modal = useModal();
  const { classes, theme } = useStyles();
  const closeModal = modal.closeAllModals;
  const [error, setError] = useState(false);
  const userId = useRecoilValue(UserIdState);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [debitCards, setDebitCards] = useState<CardByUser[]>([]);
  const [filterList, setFilterList] = useRecoilState(FilterListState);

  const cardType: any = {
    VIRTUAL: 'Virtual',
    PHYSICAL: 'Physical',
  };

  const cardStatus: any = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Unactivated',
    terminated: 'Terminated',
  };

  const columns = [
    {
      name: 'Cardholder',
      cell: (row: DebitCard) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar>{getInitialsOfNames(row.holder)}</Avatar>
          <div style={{ margin: '0px 12px' }}>{row.holder}</div>
          <Badge
            styles={{
              root: {
                backgroundColor:
                  row.status === 'suspended'
                    ? theme.colors.promote[1]
                    : theme.colors.neutral[0],
                display:
                  row.status === 'active' && userId !== row.userId
                    ? 'none'
                    : 'inline-flex',
              },
            }}
          >
            {userId === row.userId && row.status === 'active'
              ? 'You'
              : cardStatus[row.status]}
          </Badge>
        </div>
      ),
      selector: (row: { holder: string }) => row.holder,
      grow: 2,
    },
    {
      name: 'Card number',
      cell: (row: { cardNumber: string }) =>
        getPaddedAccountMask(row.cardNumber, 4),
      compact: true,
    },
    {
      name: 'Monthly spend',
      compact: true,
      sortable: true,
      selector: (row: DebitCard) =>
        formatCurrency(row.monthToDateSpends?.mtdSpend || 0),
    },
    {
      name: 'Monthly limit',
      cell: (row: DebitCard) => {
        const cardLimit =
          row.expensesTypes?.dailyPurchase ??
          row.expensesTypes?.monthlyPurchase ??
          'Unlimited';
        return cardLimit !== 'Unlimited'
          ? formatCurrency(cardLimit)
          : cardLimit;
      },
      compact: true,
    },
    {
      name: 'Type',
      selector: (row: { cardType: string }) => cardType[row.cardType],
      sortable: true,
      compact: true,
    },
    {
      name: 'Account',
      cell: (row: CardByUser) => {
        const cardAccount = bankingAccounts.find(
          (account) => account?.id === row?.depositAccountId,
        );

        const accountName =
          cardAccount && cardAccount.nickName
            ? cardAccount.nickName
            : 'Flexbase Banking';

        return accountName;
      },
    },
  ];

  const columnsSm = [
    {
      grow: 5,
      sortable: true,
      name: 'Cardholder',
      selector: (row: { holder: string }) => row.holder,
      cell: (row: DebitCard) => (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{row.holder}</div>
            <Badge
              className="badge"
              styles={{
                root: {
                  backgroundColor:
                    row.status === 'suspended'
                      ? 'rgba(48, 44, 255, 0.1)'
                      : '#EEEEF3',
                  display:
                    row.status === 'active' && userId !== row.userId
                      ? 'none'
                      : 'inline-flex',
                },
                inner: { color: '#000 !important' },
              }}
            >
              {userId === row.userId && row.status === 'active'
                ? 'You'
                : cardStatus[row.status]}
            </Badge>
          </div>
          <div>{getPaddedAccountMask(row.cardNumber, 4)}</div>
        </div>
      ),
    },
    {
      name: 'Type',
      compact: true,
      sortable: true,
      selector: (row: { cardType: string }) => row.cardType,
      cell: (row: { cardType: string }) => cardType[row.cardType],
    },
  ];

  const updateCostumerToken = async () => {
    const unitToken = await flexbaseClient.getUnitcoToken();
    if (unitToken.success && unitToken.attributes) {
      modal.openSizedCenterModal(
        <UnitcoToken
          {...{
            closeModal,
            getDebitCards,
          }}
          token={unitToken.attributes.verificationToken}
        />,
        {
          width: useMobileView ? '100%' : '40%',
        },
      );
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const onRowClicked = (card: CardByUser) => {
    useMobileView
      ? modal.openSizedCenterModal(
          <DebitCardDetails card={card} {...{ closeModal, setDebitCards }} />,
          {
            margin: '0px',
            closeOnClickOutside: true,
            closeModal: () => {
              closeModal();
            },
          },
        )
      : modal.openRightModalNoOpacity(
          <DebitCardDetails card={card} {...{ closeModal, setDebitCards }} />,
        );
  };

  const getDebitCards = async () => {
    try {
      setLoading(true);
      const result = await flexbaseBankingClient.getDebitCards(true);
      if (result.success && result.cards) {
        setDebitCards(result.cards);
        setLoading(false);
        setError(false);
      } else {
        await updateCostumerToken();
      }
    } catch (error) {
      console.error('Unable to get company debit cards', error);
      setError(true);
      updateCostumerToken();
    }
  };

  const issueDebitCard = () => {
    modal.openFullModal(
      <IssueDebitCard
        {...{ closeModal, appType, debitCards, setDebitCards }}
      />,
    );
  };

  const sortDebitCards = () => {
    const terminatedCards = debitCards.filter(
      (card) => card.status === 'terminated',
    );
    const nonTerminatedCards = debitCards.filter(
      (card) => card.status !== 'terminated',
    );
    return [...nonTerminatedCards, ...terminatedCards];
  };

  const getFilteredTableData = useCallback(() => {
    const normalizedFilterText = filterText?.trim().toLowerCase();

    return sortDebitCards().filter((card: CardByUser) => {
      const bankingAccount = bankingAccounts.find(
        (account) => account?.id === card?.depositAccountId,
      );
      const cardAccount = `${bankingAccount?.nickName} ${getPaddedAccountMask(
        bankingAccount?.accountNumber || '',
        3,
      )}`;

      return (
        card.holder?.toLowerCase().includes(normalizedFilterText) ||
        cardAccount?.toLowerCase().includes(normalizedFilterText) ||
        card.cardType?.toLowerCase().includes(normalizedFilterText) ||
        card.cardName?.toLowerCase().includes(normalizedFilterText) ||
        card.cardNumber?.toLowerCase().includes(normalizedFilterText) ||
        cardStatus[card.status]?.toLowerCase().includes(normalizedFilterText)
      );
    });
  }, [filterText, debitCards]);

  const setFilterColumn = (genericFilterList: Filter[], column: string) => {
    return genericFilterList.some((filter) =>
      (column && column?.toLowerCase())?.startsWith(
        filter.filterString.toLowerCase(),
      ),
    );
  };

  const filteredItems = () => {
    if (filterList.length === 0) {
      return getFilteredTableData();
    }

    const filterByHolder = (item: CardByUser) => {
      const holdersList = filterList.filter(
        (filter) => filter.filterColumn === 'WHO',
      );
      return (
        holdersList.length === 0 || setFilterColumn(holdersList, item.holder)
      );
    };

    const filterByStatus = (item: CardByUser) => {
      const statusList = filterList.filter(
        (filter) => filter.filterColumn === 'STATUS',
      );
      return (
        statusList.length === 0 ||
        setFilterColumn(statusList, cardStatus[item.status])
      );
    };

    const filterByType = (item: CardByUser) => {
      const typeList = filterList.filter(
        (filter) => filter.filterColumn === 'TYPE',
      );
      return typeList.length === 0 || setFilterColumn(typeList, item.cardType);
    };

    const filterByAccount = (item: CardByUser) => {
      const typeList = filterList.filter(
        (filter) => filter.filterColumn === 'ACCOUNT',
      );
      const bankingAccount = bankingAccounts.find(
        (account) => account?.id === item?.depositAccountId,
      );
      return (
        typeList.length === 0 ||
        setFilterColumn(
          typeList,
          `${bankingAccount?.nickName} ${getPaddedAccountMask(
            bankingAccount?.accountNumber || '',
            3,
          )}`,
        )
      );
    };

    return debitCards.filter((item) => {
      return (
        filterByHolder(item) &&
        filterByStatus(item) &&
        filterByType(item) &&
        filterByAccount(item)
      );
    });
  };

  useEffect(() => {
    getDebitCards();
    setFilterList([]);
    // as we need to update the customer token eventually, we need to check if the user is on mobile view
    // to render the appropriate modal size
  }, [useMobileView]);

  if (loading) {
    return <SkeletonLoading />;
  }

  if (error) {
    return (
      <div className={classes.emptyState}>
        <Image
          width={200}
          src={WatchBAnking}
          alt="Display info error"
          className="image-container"
        />
        <Text ff="redaction" size={36} align="center" mt={30}>
          Sorry about that...
        </Text>
        <p>An unhandled error occurred getting the debit cards.</p>
        <Button variant="light" onClick={getDebitCards} mt={20}>
          Please try again
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.debitCardsContent}>
      <div className={classes.card}>
        <Header
          {...{
            debitCards,
            filterText,
            filterList,
            setFilterText,
            setFilterList,
          }}
          onClickButton={issueDebitCard}
        />
        <FlexbaseTable
          onRowClicked={onRowClicked}
          isFetchingData={loading}
          data={filteredItems()}
          columns={useMobileView ? columnsSm : columns}
          pagination={filteredItems() && filteredItems()?.length > 8}
          noDataComponent={
            <Text size={24} fw={500}>
              No debit cards yet
            </Text>
          }
        />
      </div>
    </div>
  );
};

export default DebitCards;
