import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import FlexbaseInput from 'components/input/flexbase-input';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { AlertParam, AlertParamState } from 'states/notifications/alert-params';
import {
  NotificationChannels,
  UserPreferenceState,
} from 'states/user/preferences';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { useStyles } from './notifications.styles';
import { Checkbox, Switch, useMantineTheme } from '@mantine/core';
import AlertWithLink from '../../../components/alerts/alert-with-link';
import { IsAdmin } from '../../../states/application/product-onboarding';

interface Notification {
  groupName: string;
  eventName: string;
  description: string;
  threshold?: number;
  channels?: ('sms' | 'email' | 'push')[];
  subNotifications?: Omit<Notification, 'subNotifications'>[];
}

interface NotificationCategory {
  name: string;
  scope?: string;
  defaultOn?: boolean;
  notifications: Notification[];
}

//TODO: Remove temporary Personal type notification eventNames
const tempPersonalEvents = [
  'uploadReceipt',
  'declined',
  'delinquent',
  'frozen',
  'cardSwipe',
  'cardStatus',
  'suspectedFraud',
];

const Notifications = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [notificationData, setNotificationData] = useState<
    NotificationCategory[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const isAdmin = useRecoilValue(IsAdmin);
  const userId = useRecoilValue(UserIdState);
  const userPreferences = useRecoilValue(UserPreferenceState);
  const alertParams = useRecoilValue(AlertParamState);
  const setUserPreferences = useSetRecoilState(UserPreferenceState);

  const getNotificationData = async () => {
    try {
      setNotificationData(transformNotificationData(alertParams));
    } catch (err) {
      console.error('Error loading notification preferences', err);
    }
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  const transformNotificationData = (alertParams: AlertParam[]): any => {
    const userNotificationPrefs = userPreferences.notifications;
    const groupedData = alertParams.reduce((r: any, a: AlertParam) => {
      const userPref =
        userNotificationPrefs[a.groupName]?.[a.eventName] ||
        userNotificationPrefs[a.groupName]?.default;

      let pushGroup = a.groupName;
      // TODO: Remove logic to group temporary Personal group
      if (tempPersonalEvents.includes(a.eventName)) {
        pushGroup = 'Personal';
      }
      r[pushGroup] = r[pushGroup] || [];
      r[pushGroup].push({
        eventName: a.eventName,
        groupName: a.groupName,
        description: a.description,
        channels: userPref || [],
      });
      return r;
    }, Object.create(null));

    const transformedData = [];

    for (const [key, value] of Object.entries(groupedData)) {
      transformedData.push({
        name: key,
        notifications: value,
      });
    }

    return transformedData;
  };

  const updateNotificationsPreferences = async (
    checked: boolean,
    eventName: string,
    groupName: string,
    channel: NotificationChannels,
  ) => {
    const channelPref: NotificationChannels[] =
      userPreferences.notifications[groupName] &&
      userPreferences.notifications[groupName][eventName]
        ? [...userPreferences.notifications[groupName][eventName]]
        : [];
    if (checked && !channelPref.includes(channel)) {
      channelPref.push(channel);
    } else if (!checked && channelPref.includes(channel)) {
      channelPref.splice(
        channelPref.findIndex((element) => element === channel),
        1,
      );
    }
    const preferences = {
      preferences: JSON.parse(JSON.stringify(userPreferences)),
    };
    if (!preferences.preferences.notifications[groupName]) {
      preferences.preferences.notifications[groupName] = {};
    }
    preferences.preferences.notifications[groupName][eventName] = channelPref;
    try {
      setLoading(true);
      await flexbaseOnboardingClient.updateUserPreference(userId, preferences);
    } catch (err) {
      console.error('Failed updating notification preferences');
    } finally {
      setLoading(false);
      setUserPreferences(preferences.preferences);
    }
    return;
  };

  const generateNotificationControl = (notification: Notification) => {
    return (
      <div className={classes.notification} key={notification.eventName}>
        <div className={classes.notificationName}>
          {capitalizeOnlyFirstLetter(
            notification.description.replace(
              /(This will alert you when )|(This will alert you to )/,
              '',
            ),
          )}
          {notification.threshold !== undefined && (
            <FlexbaseInput type="number" size="sm" disabled={loading} />
          )}
        </div>
        <div className={classes.notificationCheckboxes}>
          <div>
            <Checkbox
              defaultChecked={notification.channels?.includes('sms')}
              onChange={(event) =>
                updateNotificationsPreferences(
                  event.currentTarget.checked,
                  notification.eventName,
                  notification.groupName,
                  'sms',
                )
              }
              disabled={loading}
            />
          </div>
          {/*<div>*/}
          {/*  <Checkbox*/}
          {/*    defaultChecked={notification.channels?.includes('email')}*/}
          {/*    onChange={(event) =>*/}
          {/*      updateNotificationsPreferences(*/}
          {/*        event.currentTarget.checked,*/}
          {/*        notification.eventName,*/}
          {/*        notification.groupName,*/}
          {/*        'email',*/}
          {/*      )*/}
          {/*    }*/}
          {/*    disabled={true} // disable email notification for now*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <Checkbox*/}
          {/*    defaultChecked={notification.channels?.includes('push')}*/}
          {/*    onChange={(event) =>*/}
          {/*      updateNotificationsPreferences(*/}
          {/*        event.currentTarget.checked,*/}
          {/*        notification.eventName,*/}
          {/*        notification.groupName,*/}
          {/*        'push',*/}
          {/*      )*/}
          {/*    }*/}
          {/*    disabled={true} // disable push notification for now*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };

  return (
    <div>
      <AlertWithLink
        withCloseButton={false}
        message="Email and push notifications coming soon"
        style={{ marginTop: '16px', marginBottom: 0 }}
      />
      {notificationData
        .filter((category) => category.name === 'Personal' || isAdmin)
        .map((category) => (
          <div key={category.name} className={classes.category}>
            <div className={classes.categoryHeader}>
              <div className={classes.categoryName}>
                {capitalizeOnlyFirstLetter(category.name)}
              </div>
              {category.defaultOn !== undefined && (
                <Switch
                  color={theme.fn.primaryColor()}
                  defaultChecked={category.defaultOn}
                />
              )}
              <div className={classes.categoryScope}>{category.scope}</div>
            </div>
            <div>
              <div className={classes.contentHeader}>
                <div>Notification Type</div>
                <div>
                  <div>SMS</div>
                  {/*<div>Email</div>*/}
                  {/*<div>App</div>*/}
                </div>
              </div>
              <div>
                {category.notifications.map((notification) => (
                  <div
                    className={classes.notificationsSection}
                    key={notification.eventName}
                  >
                    {generateNotificationControl(notification)}
                    {notification.subNotifications &&
                      notification.subNotifications.length > 0 && (
                        <div className={classes.subNotificationsSection}>
                          {notification.subNotifications?.map(
                            (subNotification) =>
                              generateNotificationControl(subNotification),
                          )}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Notifications;
