import { CreateTokenRequest, FlexbaseClient } from 'flexbase-client';
import { Wretcher } from 'wretch';
import { Analytics } from 'services/analytics/analytics';

import {
  BankingParameters,
  CardStatus,
  CounterpartyRequest,
  CounterpartyResponse,
  CounterpartyResponseList,
  CreateApplicationStatusResponse,
  CreateDepositAccountRequest,
  CreateDepositAccountResponse,
  CreateMoneyMovementRequest,
  CreateUnitcoTokenResponse,
  CustTokenVGS,
  DebitCardResponse,
  DebitCardsResponse,
  DepositListResponse,
  GetApplicationStatusResponse,
  GetUnitcoTokenResponse,
  IssueDebitCard,
  MoneyMovement,
  MoneyMovementListReponse,
  MoneyMovementResponse,
  PaymentDocsResponse,
  PlaidBalances,
  PlaidRelinkRequest,
  PlaidRelinkResponse,
  Statement,
  StatementsResponse,
  TransactionResponse,
  TreasuryAccount,
  TreasuryAccountResponse,
  TreasuryActivity,
  TreasuryActivityResponse,
  TreasuryAllocationsResponse,
  UpdateCardRequest,
  UpdatePaymentDocResponse,
  WireInstructionsModel,
  WireInstructionsResponse,
} from './banking.model';
import { DateTime } from 'luxon';

export class FlexbaseBankingClient {
  private readonly client: Wretcher;

  // This is a dirty, dirty hack until the entire onboarding client and all models are in flexbase-client
  constructor(flexbaseClient: FlexbaseClient) {
    this.client = flexbaseClient['_client'] as Wretcher;
  }

  private bankingParams(options?: BankingParameters) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};

    let property: keyof BankingParameters;
    for (property in options) {
      if (options && Object.hasOwn(options, property)) {
        if (typeof options[property] === 'object') {
          const newDate = options[property] as DateTime;
          params[property] = newDate.toISO();
        } else if (typeof options[property] === 'boolean') {
          params[property] = true;
        } else {
          params[property] = options[property];
        }
      }
    }

    return params;
  }

  async marketingSegment(): Promise<void> {
    return this.client.url(`/marketing/segment`).post();
  }

  async createApplication(): Promise<CreateApplicationStatusResponse> {
    return this.client
      .url(`/banking/application`)
      .post()
      .json<CreateApplicationStatusResponse>();
  }

  async getApplicationStatus(): Promise<GetApplicationStatusResponse> {
    return this.client
      .url(`/banking/application`)
      .get()
      .json<GetApplicationStatusResponse>();
  }

  async getDepositList(): Promise<DepositListResponse> {
    return this.client
      .url(`/banking/deposits`)
      .get()
      .json<DepositListResponse>();
  }

  async createDepositAccount(
    request: CreateDepositAccountRequest,
  ): Promise<CreateDepositAccountResponse> {
    return this.client
      .url(`/banking/deposits`)
      .post(request)
      .json<CreateDepositAccountResponse>();
  }

  async editDepositNickName(
    accountId: string,
    nickName: string,
  ): Promise<DepositListResponse> {
    return this.client
      .url(`/banking/deposits/${accountId}/nickname`)
      .put({ nickName })
      .json<DepositListResponse>();
  }

  async getPlaidBalances(): Promise<PlaidBalances> {
    return this.client.url('/plaid/balances').get().json();
  }

  async relinkPlaidAccount(
    request: PlaidRelinkRequest,
  ): Promise<PlaidRelinkResponse> {
    return this.client
      .url('/plaid/linkToken/update')
      .post(request)
      .json<PlaidRelinkResponse>();
  }

  async getCounterPartyList(): Promise<CounterpartyResponseList> {
    return this.client
      .url(`/banking/counterparties`)
      .get()
      .json<CounterpartyResponseList>();
  }

  async createCounterparty(
    request: CounterpartyRequest,
  ): Promise<CounterpartyResponse> {
    return this.client
      .url(`/banking/counterparties`)
      .post(request)
      .json<CounterpartyResponse>();
  }

  async removeCounterparty(id: string): Promise<CounterpartyResponse> {
    return this.client
      .url(`/banking/counterparties/${id}/status`)
      .put({ status: 'inactive' })
      .json<CounterpartyResponse>();
  }

  async getMoneyMovements(): Promise<MoneyMovementListReponse> {
    return this.client
      .url(`/banking/moneymovements`)
      .get()
      .json<MoneyMovementListReponse>();
  }

  async makePayment(
    request: CreateMoneyMovementRequest,
  ): Promise<MoneyMovementResponse> {
    const response = await this.client
      .url(`/banking/moneymovements`)
      .post(request)
      .json<MoneyMovementResponse>();

    if (response.success) {
      Analytics.track('Banking Payment Submitted', {
        amount: response.payment.payAmountCents,
        status: response.payment.status,
        direction: response.payment.payDirection,
        type: response.payment.type,
        id: response.payment.id,
      });
    } else {
      Analytics.track('Banking Payment Error', {
        amount: request.amount,
        direction: request.direction,
        type: request.type,
      });
    }

    return response;
  }

  async confirmPayment(
    id: string,
    verificationCode?: string, // if the verificationCode is missing, then a new SMS will be sent
  ): Promise<MoneyMovement> {
    const { payment } = await this.client
      .url(`/banking/moneymovements/${id}/confirmation`)
      .put({ verificationCode })
      .json();

    return payment;
  }

  async getPaymentDocs(paymentId: string): Promise<PaymentDocsResponse> {
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs`)
      .get()
      .json();
  }

  async uploadPaymentDoc(
    paymentId: string,
    request: { file: string | Blob; description?: string },
  ): Promise<UpdatePaymentDocResponse> {
    const body = JSON.stringify({
      description: request.description,
    });
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs`)
      .formData({
        file: request.file,
        body,
      })
      .post()
      .json();
  }

  async updatePaymentDocs(
    paymentId: string,
    docId: string,
    request: {
      description?: string;
      docDate: Date | string;
      expiresAt: Date | string;
    },
  ): Promise<UpdatePaymentDocResponse> {
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs/${docId}`)
      .put(request)
      .json();
  }

  async deletePaymentDocs(
    paymentId: string,
    docId: string,
  ): Promise<UpdatePaymentDocResponse> {
    return await this.client
      .url(`/banking/moneymovements/${paymentId}/docs/${docId}`)
      .delete()
      .json();
  }

  async createCustTokenVGS(request: CustTokenVGS) {
    return await this.client.url('/unitco/custToken/vgs').post(request).json();
  }

  async getTransactions() {
    return await this.client
      .url(`/banking/transactions`)
      .get()
      .json<TransactionResponse>();
  }

  async checkRoutingNumber(
    routingNumber: string,
  ): Promise<{ banks: string[]; success: boolean }> {
    return await this.client
      .url(`/plaid/institution/${routingNumber}`)
      .get()
      .json();
  }

  async getTreasuryAccount(): Promise<TreasuryAccount> {
    const response = await this.client
      .url('/treasury/account')
      .get()
      .json<TreasuryAccountResponse>();
    return response.account;
  }

  async getTreasuryActivities(): Promise<TreasuryActivity[]> {
    const response = await this.client
      .url('/treasury/activity')
      .get()
      .json<TreasuryActivityResponse>();
    return response.activity;
  }

  async postTreasuryActivity(
    activity: 'deposit' | 'withdrawal',
    amount: number,
  ): Promise<TreasuryActivityResponse> {
    const response = await this.client
      .url('/treasury/activity')
      .post({
        activity,
        amount,
      })
      .json<TreasuryActivityResponse>();
    return response;
  }

  async getTreasuryAllocations(): Promise<TreasuryAllocationsResponse> {
    return await this.client.url('/treasury/allocations').get().json();
  }

  async issueDebitCard(
    userId: string,
    request: IssueDebitCard,
  ): Promise<DebitCardResponse> {
    return await this.client.url(`/card/${userId}/issue`).post(request).json();
  }

  async getDebitCards(getFullData = false): Promise<DebitCardsResponse> {
    return await this.client
      .url(`/banking/cards`)
      .query({ full: getFullData })
      .get()
      .json();
  }

  async cardStatus(
    cardId: string,
    status: CardStatus,
  ): Promise<DebitCardResponse> {
    return await this.client
      .url(`/banking/cards/${cardId}/status`)
      .put({ status })
      .json();
  }

  async updateBankingDebitCard(
    request: UpdateCardRequest,
  ): Promise<DebitCardResponse> {
    return await this.client
      .url(`/banking/cards/${request.id}`)
      .patch(request)
      .json();
  }

  // unit customer token endpoints
  async getUnitcoToken(): Promise<GetUnitcoTokenResponse> {
    return await this.client
      .url('/unitco/verifToken')
      .get()
      .json<GetUnitcoTokenResponse>();
  }

  async createUnitCoToken(
    createToken: CreateTokenRequest,
  ): Promise<CreateUnitcoTokenResponse> {
    return await this.client
      .url('/unitco/custToken')
      .post(createToken)
      .json<CreateUnitcoTokenResponse>();
  }

  // list all of the statements, filtered by parameters
  async getStatements(options?: BankingParameters): Promise<Statement[]> {
    const params = this.bankingParams(options);

    const { statements } = await this.client
      .url('/banking/statements')
      .query(params)
      .get()
      .json<StatementsResponse>();

    return statements;
  }

  // get a single Statement by its ID
  // as either HTML (type "string") or PDF (type "ArrayBuffer")
  async getStatement(
    statementId: string,
    options?: BankingParameters,
  ): Promise<string | ArrayBuffer> {
    const params = this.bankingParams(options);
    const request = this.client
      .url(`/banking/statements/${statementId}`)
      .query(params)
      .get();

    if (options?.isPdf) {
      return request.arrayBuffer();
    } else {
      return request.json();
    }
  }

  async getWireInstructions(accountId: string): Promise<WireInstructionsModel> {
    const response = await this.client
      .url(`/banking/deposits/${accountId}/wireInstructions`)
      .get()
      .json<WireInstructionsResponse>();

    // The next two lines strip "success" out of the return value.
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars*/
    const { success, ...otherProperties } = response;

    return { ...otherProperties };
  }
}
