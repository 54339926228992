import { useRecoilState, useRecoilValue } from 'recoil';
import { Text } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useCallback, useEffect, useState } from 'react';
import { useStyles } from './banking-transactions.styles';
import { flexbaseBankingClient } from 'services/flexbase-client';
import BankingTransactionsHeader from './banking-transactions-header/banking-transactions-header';
import FlexbaseTable from 'components/table/flexbase-table';
import { formatInitials } from 'utilities/formatters/format-strings';
import { getLocaleDate } from 'utilities/formatters/format-date-string';
import { downloadCSV } from 'utilities/file-io/csv/csv-export';
import {
  extractToFrom,
  formatCustomerAndAccountNumber,
} from 'utilities/formatters/banking-transactions-utilities';
import { showNotification } from '@mantine/notifications';
import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';
import {
  DepositAccount,
  Transaction,
  transactionMapping,
} from 'services/flexbase/banking.model';
import { Filter } from '../components/filter/filter-models';
import { FilterListState } from '../components/filter/filter-states';
import { DepositAccountsState } from '../states/deposit-accounts';
import { onChangeFilters, OverflowTooltip } from '@flexbase-eng/web-components';
import { useMediaQuery } from '@mantine/hooks';
import useModal from 'components/modal/modal-hook';
import TransactionDetails from './transaction-details/transaction-details';

interface ExportedData {
  date: string;
  type: string;
  name: string;
  toFrom: string;
  amount: string;
  account: string;
  status: string;
}

const BankingTransactions = () => {
  const { classes } = useStyles();
  const modal = useModal();
  const [searchParams] = useSearchParams();
  const [filterText, setFilterText] = useState('');
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [filterList, setFilterList] = useRecoilState(FilterListState);
  const [selectedRows, setSelectedRows] = useState<ExportedData[]>([]);
  const [loading, setLoading] = useState(true);
  const bankingAccounts = useRecoilValue(DepositAccountsState);
  const filterStringList = filterList.map((filter) => {
    return filter.filterString;
  });

  /**
   * map deposit account id from money movement to name of deposit account
   */
  const mapAccountId = (id: string) => {
    return bankingAccounts.find((a) => a.id === id);
  };

  const mapTransactionPresentation = (
    direction: string,
    type: string,
    colorOrDirection: 'color' | 'direction',
  ) => {
    switch (type) {
      case 'Internal Transfer':
      case 'Payment Canceled':
        return colorOrDirection === 'color' ? '#000000' : '';
      case 'ACH Received':
      case 'ACH Payment':
      case 'Reward':
      case 'Card Reversal':
        return colorOrDirection === 'color' ? '#27C281' : '+';
      case 'Originated ACH':
      case 'Card Purchase':
      case 'Card Transaction':
      case 'ATM Withdrawal':
      case 'Wire':
        return colorOrDirection === 'color' ? '#FF0000' : '-';
    }
  };

  /**
   * Find and track first instances of each transaction by the charge date
   *
   * @param transactions
   */
  const trackFirstInstancesByDate = (transactions: Transaction[]) => {
    const firstInstancesByDate = new Map();

    const descTransactions = [...transactions].reverse();

    descTransactions.forEach((transaction) =>
      firstInstancesByDate.set(
        getLocaleDate(transaction.createdAt),
        transaction.id,
      ),
    );
  };

  /**
   * Get banking Transactions/Invoice data
   * Get money movement request data
   * merge into a singular transaction view
   * deduplicate rows that live in both sources
   */
  const getBankingTransactions = async () => {
    try {
      const bankingTransactions = await flexbaseBankingClient.getTransactions();
      if (bankingTransactions && bankingTransactions.transactions) {
        const transactions = bankingTransactions.transactions;

        const paymentIds = transactions
          .filter((t) => t.paymentId !== null)
          .map((t) => t.paymentId);

        if (!bankingTransactions.success) {
          throw new Error('Unable to retrieve transactions at this time.');
        }
        let transformMMtoTransactions: Transaction[] = [];
        const moneyMovementResponse =
          await flexbaseBankingClient.getMoneyMovements();
        if (moneyMovementResponse && moneyMovementResponse.success) {
          transformMMtoTransactions = moneyMovementResponse.payments
            .filter((mm) => mm.status !== 'Sent')
            .filter((mm) => !paymentIds.includes(mm.id))
            .map((p) => {
              return {
                accountName: mapAccountId(p.depositId)?.nickName,
                accountNumber: mapAccountId(p.depositId)?.accountNumber,
                amount: p.payAmountCents,
                balance: '',
                companyName: '',
                createdAt: p.createdAt,
                depositAccount: p.depositId,
                depositAccountId: p.depositId,
                direction: p.payDirection,
                id: p.id, // not great, id can be of either table technically, so no longer deterministic lookup
                summary: p.payDescription,
                whoDisplayName: '',
                whoFirstName: '',
                whoLastName: '',
                whoUsername: '',
                type: p.type,
                moneyMovement: true,
              } as Transaction;
            });
          const finalTransactionsList = transactions
            .concat(transformMMtoTransactions)
            .sort((a, b) => {
              return (
                DateTime.fromSQL(b.createdAt).toMillis() -
                DateTime.fromSQL(a.createdAt).toMillis()
              );
            });
          setTransactions(finalTransactionsList);
          trackFirstInstancesByDate(finalTransactionsList);
        } else {
          throw new Error('');
        }
      }
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: `${e.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBankingTransactions();
  }, []);

  const sortDate = (a: Transaction, b: Transaction) => {
    return (
      DateTime.fromSQL(a.createdAt).toMillis() -
      DateTime.fromSQL(b.createdAt).toMillis()
    );
  };

  /**
   * Define columns to be passed into the FlexbaseTable component
   */
  const columns = [
    {
      name: 'Date',
      cell: (row: { id: string; date: string }) => {
        return <Text className={classes.date}>{row.date}</Text>;
      },
      selector: (row: { createdAt: string }) => row.createdAt,
      sortable: true,
      sortFunction: sortDate,
      id: 'date',
    },
    {
      name: 'Team Member',
      selector: (row: { name: any }) => row.name,
      sortable: true,
      compact: true,
    },
    {
      name: 'Summary',
      cell: (row: { initials: string; toFrom: string }) => (
        <div className={classes.storeName}>
          {row.toFrom || 'Payment received'}
        </div>
      ),
      selector: (row: { toFrom: string }) => row.toFrom,
      sortable: true,
      compact: true,
      grow: 1.7,
    },
    {
      name: 'Amount',
      cell: (row: {
        direction: string;
        amount: any;
        type: string;
        paymentId: string;
      }) => (
        <Text
          style={{
            color: mapTransactionPresentation(row.direction, row.type, 'color'),
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
          }}
        >
          {`${mapTransactionPresentation(
            row.direction,
            row.type,
            'direction',
          )}${formatCurrency(row.amount)}`}
        </Text>
      ),
      selector: (row: { amount: string; direction: string }) => {
        if (row.direction === 'Debit') {
          return parseFloat(`-${row.amount}`);
        }
        return parseFloat(row.amount);
      },
      sortable: true,
      compact: true,
    },
    {
      name: 'Account',
      cell: (row: { account: any }) => <OverflowTooltip text={row.account} />,
      selector: (row: { account: any }) => row.account,
      sortable: true,
      grow: 1.5,
    },
    {
      name: 'Type',
      selector: (row: { type: any }) => row.type,
      sortable: true,
      compact: true,
    },
  ];

  const columnsSm = [
    {
      sortable: true,
      name: 'Summary',
      cell: (row: { name: string; type: string; date: string }) => (
        <div>
          <div style={{ fontWeight: 500, fontSize: 14 }}>{row.name}</div>
          <div style={{ fontWeight: 500, fontSize: 12 }}>{row.type}</div>
          <div style={{ fontSize: 12 }}>{row.date}</div>
        </div>
      ),
      selector: (row: { date: string }) => row.date,
    },
    {
      name: 'Amount',
      cell: (row: {
        direction: string;
        amount: any;
        type: string;
        paymentId: string;
      }) => (
        <Text
          style={{
            color: mapTransactionPresentation(row.direction, row.type, 'color'),
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '21px',
          }}
        >
          {`${mapTransactionPresentation(
            row.direction,
            row.type,
            'direction',
          )}${formatCurrency(row.amount)}`}
        </Text>
      ),
      selector: (row: { amount: string; direction: string }) => row.amount,
      format: (row: { amount: string; direction: string }) => {
        if (row.direction === 'Debit') {
          return parseFloat(`-${row.amount}`);
        }
        return parseFloat(row.amount);
      },
      sortable: true,
      compact: true,
      right: true,
      style: {
        padding: '0px 16px',
      },
    },
  ];

  /**
   * Prep/format data to be passed into the FlexbaseTable component
   */
  const tableData = transactions.map((transaction) => {
    const bankingAccount = bankingAccounts.find(
      (account: DepositAccount) => account.id === transaction.depositAccountId,
    );

    return {
      initials: formatInitials(extractToFrom(transaction.summary)),
      cardId: transaction.cardId,
      id: transaction.id,
      date: DateTime.fromSQL(transaction.createdAt).toFormat('LLL dd, yyyy'),
      createdAt: transaction.createdAt,
      name: [transaction.whoFirstName, transaction.whoLastName]
        .filter(Boolean)
        .join(' '),
      toFrom: transaction.summary,
      amount: parseFloat(transaction.amount) / 100,
      account: bankingAccount
        ? formatCustomerAndAccountNumber(
            bankingAccount.nickName || bankingAccount.name || '',
            bankingAccount.type,
            bankingAccount.accountNumber,
          )
        : '',
      type: transactionMapping[transaction.type],
      direction: transaction.direction,
      transactionDetail: transaction,
    };
  });

  /**
   * Filter table data based on filter text
   */
  const getFilteredTableData = useCallback(() => {
    const normalizedFilterText = filterText?.trim().toLowerCase();

    return tableData.filter((transaction) => {
      return (
        transaction.amount?.toString().includes(normalizedFilterText) ||
        transaction.type?.toLowerCase().includes(normalizedFilterText) ||
        transaction.date?.toLowerCase().includes(normalizedFilterText) ||
        transaction.name?.toLowerCase().includes(normalizedFilterText) ||
        transaction.toFrom?.toLowerCase().includes(normalizedFilterText) ||
        transaction.account?.toLowerCase().includes(normalizedFilterText) ||
        transaction.initials?.toLowerCase().includes(normalizedFilterText)
      );
    });
  }, [filterText, tableData]);

  /**
   * Prep/format data to be exported
   */

  const selectedData = selectedRows.length > 0 ? selectedRows : tableData;

  const csvData = selectedData.map((data) => {
    return {
      Date: data.date,
      Name: data.name,
      'To/From': data.toFrom,
      Amount: formatCurrency(data.amount),
      Account: data.account,
      Type: data.type,
    };
  });

  /**
   * Define column to filter against
   *
   * @param genericFilterList
   * @param column
   */
  const setFilterColumn = (genericFilterList: Filter[], column: string) => {
    return genericFilterList.some((filter) =>
      (column && column.toLowerCase()).startsWith(
        filter.filterString.toLowerCase(),
      ),
    );
  };

  /**
   * Define criterion/columns for search/filtering
   */
  const filteredItems = () => {
    if (filterList.length === 0) {
      return getFilteredTableData();
    }

    return tableData.filter((item) => {
      const toFromList = filterList.filter(
        (filter) => filter.filterColumn === 'TO_FROM',
      );
      const holdersList = filterList.filter(
        (filter) => filter.filterColumn === 'WHO',
      );
      const bankingAccountList = filterList.filter(
        (filter) => filter.filterColumn === 'ACCOUNT',
      );

      let passFilters = true;

      if (toFromList.length > 0) {
        // Format dates range
        const filterStringToFromList = filterList.map((filter) => {
          return filter.filterString;
        });

        if (toFromList.length === 1) {
          const [startDateString, endDateString] =
            filterStringToFromList[0].split(' - ');
          const startDate = new Date(startDateString);
          const endDate = new Date(endDateString);
          const datesArray = [];

          for (
            let date = startDate;
            date <= endDate;
            date.setDate(date.getDate() + 1)
          ) {
            const formattedDate =
              date.toISOString().replace('T', ' ').slice(0, -5) + '+00';
            const formattedDateString =
              DateTime.fromSQL(formattedDate).toFormat('LLL dd, yyyy');
            datesArray.push({
              filterString: formattedDateString,
              filterColumn: 'TO_FROM',
            });
          }

          passFilters = passFilters && setFilterColumn(datesArray, item.date);
        } else {
          passFilters = passFilters && setFilterColumn(toFromList, item.date);
        }
      }
      if (holdersList.length > 0) {
        passFilters = passFilters && setFilterColumn(holdersList, item.name);
      }
      if (bankingAccountList.length > 0) {
        passFilters =
          passFilters && setFilterColumn(bankingAccountList, item.account);
      }

      return passFilters;
    });
  };

  const filterAccount = () => {
    if (bankingAccounts.length > 0) {
      const result = bankingAccounts.find((account) => {
        return account.accountNumber === searchParams.get('account');
      });

      if (result) {
        const bankAccountFormatted =
          formatCustomerAndAccountNumber(
            result.nickName || result.name,
            result.type,
            result.accountNumber,
          ) || '';
        onChangeFilters({
          filterList: filterList,
          setFilterList: setFilterList,
          values: [...filterStringList, bankAccountFormatted],
          filterStringList: filterStringList,
          filterType: 'ACCOUNT',
        });
      }
    }
  };

  const onRowClicked = (row: any) => {
    modal.openRightModal(<TransactionDetails transaction={row} />);
  };

  useEffect(() => {
    if (searchParams.get('account')) {
      filterAccount();
    } else {
      setFilterList([]);
    }
  }, []);

  return (
    <div className={classes.baseContainer}>
      <div className={classes.widgetContainer}>
        <BankingTransactionsHeader
          setFilterText={setFilterText}
          filterText={filterText}
          filterList={filterList}
          downloadCSV={() => downloadCSV(csvData, 'Banking Transactions')}
          transactions={transactions}
          setFilterList={setFilterList}
        />
        <FlexbaseTable
          columns={useMobileView ? columnsSm : columns}
          data={filteredItems()}
          onSelectedRowsChange={({ selectedRows }) =>
            setSelectedRows(selectedRows)
          }
          isFetchingData={loading}
          defaultSortFieldId={'createdAt'}
          onRowClicked={onRowClicked}
          noDataComponent={
            <Text size={24} fw={500}>
              No transactions yet
            </Text>
          }
        />
      </div>
    </div>
  );
};
export default BankingTransactions;
