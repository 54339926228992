import {
  OnboardingCompany,
  OnboardingUser,
} from '../onboarding/onboarding-info';
import IAddress from '../user/address';
import { ApplicationStatus } from './product-onboarding.models';

const defaultAddress: IAddress = {
  line1: '',
  city: '',
  state: '',
  postalCode: '',
  country: 'US',
};

const defaultCompany: OnboardingCompany = {
  id: '',
  companyName: '',
  address: { ...defaultAddress },
  doingBusinessAs: '',
  taxId: '',
  formationDate: '',
  legalStructure: '',
  website: '',
  monthlyExpenditure: '',
  phone: '',
  owners: [],
  financialInstitutions: [],
  category: '',
  annualRevenue: '',
  averageMonthlyPayroll: '',
  insuranceTypes: [],
  hasInsurance: false,
  optedProducts: [],
  autopay: false,
  formationState: '',
  businessPurpose: '',
  cashOnHand: '',
  naicsCode: '',
  reinvestTreasuryInterest: false,
  primaryPlaidAccount: '',
  businessVertical: '',
  requestedCreditLimit: '',
  stateOfOperation: '',
  onboardedBy: '',
  createdBy: '',
};

const defaultOnboardingUser: OnboardingUser = {
  id: '',
  firstName: '',
  lastName: '',
  address: { ...defaultAddress },
  taxId: '',
  birthDate: '',
  jobTitle: '',
  email: '',
  phone: '',
  cellPhone: '',
  authorizedSignatory: false,
  roles: [],
  status: '',
  plaidLinked: false,
  termsOfServiceSigned: false,
  bankingTermsOfServiceSigned: false,
  treasuryTermsOfServiceSigned: false,
  creditTermsOfServiceSigned: false,
  hasBusinessCard: false,
  businessCardTypes: [],
  employeeCount: '',
  personalGuarantySigned: false,
};

const defaultProductStatus: ApplicationStatus = {
  credit: {
    status: 'unused',
  },
  banking: {
    status: 'unused',
  },
  treasury: {
    status: 'unused',
  },
};

type _Defaults = {
  company: OnboardingCompany;
  user: OnboardingUser;
  address: IAddress;
  productStatus: ApplicationStatus;
};

export const DEFAULT_APP_STATE_VALUES: _Defaults = {
  company: defaultCompany,
  user: defaultOnboardingUser,
  address: defaultAddress,
  productStatus: defaultProductStatus,
};
