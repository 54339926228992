import {
  CustomMantineStyles,
  useStyles,
} from './credit-transactions-header.styles';
import {
  Button,
  Menu,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FilterIcon, ReviewIcon, SyncIcon, SearchIcon } from 'assets/svg';
import { useState } from 'react';

interface Props {
  onFilter: (e: any) => void;
  filterText: string;
  onDownloadCSVClick: () => void;
}

// Note: Tooltips will not show on mobile and v4 Mantine doesn't make it easy
// to use controlled tooltips.
// TODO: In v5 Mantine update, add touch events to Tooltips.
const CreditTransactionHeaderSm = ({
  onFilter,
  filterText,
  onDownloadCSVClick,
}: Props) => {
  const theme = useMantineTheme();
  const [searchFocused, setSearchFocused] = useState(false);
  const { classes } = useStyles({ searchFocused });
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        <Menu
          styles={{
            item: CustomMantineStyles().actions.item,
            dropdown: { width: '120px', borderRadius: '8px' },
          }}
          opened={menuOpen}
          onChange={(open) => setMenuOpen(open)}
        >
          <Menu.Target>
            <div className={classes.actionsSm}>
              <div>Actions</div>
              {menuOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item disabled>
              <div>Sync with Quickbooks</div>
            </Menu.Item>
            <Menu.Item onClick={onDownloadCSVClick}>Download CSV</Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Tooltip label="Quickbooks integration coming soon">
          <Button
            leftIcon={<SyncIcon fill={'#5F5F5F'} width={19} height={19} />}
            className={classes.sync}
            disabled={true}
          >
            Sync
          </Button>
        </Tooltip>
        <Tooltip label="Quickbooks integration coming soon">
          <Button
            leftIcon={<ReviewIcon fill={'#5F5F5F'} width={20} height={20} />}
            className={classes.review}
            disabled={true}
          >
            Review
          </Button>
        </Tooltip>
      </div>
      <TextInput
        icon={
          <SearchIcon
            className={classes.searchIcon}
            style={{ marginLeft: '6px', width: 20, height: 20 }}
          />
        }
        placeholder={'Search transactions...'}
        value={filterText}
        onChange={onFilter}
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        rightSection={
          <FilterIcon
            style={{ marginRight: '6px' }}
            fill={'#979797'}
            width={20}
            height={20}
          />
        }
        styles={{
          input: CustomMantineStyles(theme.fn.primaryColor())
            .searchTransactionsSm.input,
        }}
      ></TextInput>
    </div>
  );
};

export default CreditTransactionHeaderSm;
