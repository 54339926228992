import flexbaseClient from 'services/flexbase-client';
import { Employee } from 'states/employees/employee';
import { useEffect, useState } from 'react';
import { Avatar, Menu, useMantineTheme } from '@mantine/core';
import { customMantineStyles, useStyles } from './team-members.styles';
import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import useModal from 'components/modal/modal-hook';
import TeamMembersHeader from './team-members-header/team-members-header';
import { RowAction } from '../../assets/svg';
import DeleteTeamMember from './delete-team-member/delete-team-member';
import { showNotification } from '@mantine/notifications';
import { ROLE_SELECT_VALUES } from 'states/onboarding/onboarding-info';
import { PersonUpdate } from 'flexbase-client';
import { useMediaQuery } from '@mantine/hooks';
import Select from '../../components/select/flexbase-select';
import { getInitialsOfNames } from '../../utilities/extensions/object';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { FlexbaseTable } from 'components/table';
import { useRecoilValue } from 'recoil';
import { IsAdmin } from '../../states/application/product-onboarding';
import { capitalizeOnlyFirstLetter } from '@flexbase-eng/web-components';

const MAIN_ROLES = ['ADMIN', 'EMPLOYEE', 'ACCOUNTANT'];

const TeamMembers = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { classes } = useStyles();
  const isAdmin = useRecoilValue(IsAdmin);
  const userId = useRecoilValue(UserIdState);
  const { openTransparentModal, closeAllModals } = useModal();
  const [allUsers, setAllUsers] = useState<Employee[]>([]);
  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(true);
  const [pendingInvitationsClicked, setPendingInvitationsClicked] =
    useState(false);
  const [checkPending, setCheckingPending] = useState(false);

  const updateCheckingPending = () => {
    setCheckingPending(!checkPending);
  };

  const updatePendingInvitationClicked = () => {
    setPendingInvitationsClicked(!pendingInvitationsClicked);
  };

  const getAllUsers = async () => {
    try {
      let companyUsers = await flexbaseClient.getEmployees();
      if (companyUsers && companyUsers.length > 0) {
        companyUsers = companyUsers.filter(
          (currentUser) => currentUser?.id !== userId,
        );
        setAllUsers(companyUsers);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  const updateUserRole = async (
    user: string,
    userId: string,
    request: PersonUpdate,
  ) => {
    try {
      const response = await flexbaseClient.updatePerson(userId, request);

      if (response && response.roles![0] === request.roles![0]) {
        showNotification({
          color: 'flexbase-teal',
          title: 'Success!',
          message: `${user}'s role has been successfully updated`,
        });
        getAllUsers();
      } else {
        showNotification({
          color: 'red',
          title: 'Failure',
          message: `${user}'s role could not be updated`,
        });
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const columns = [
    {
      name: 'Name',
      cell: (row: { initials: string; who: string }) => (
        <div className={classes.who}>
          <Avatar radius="md" size="32px" className={classes.whoIcon}>
            {row.initials.toUpperCase()}
          </Avatar>
          {row.who}
        </div>
      ),
      minWidth: isMobile ? '150px' : '',
      selector: (row: { who: string }) => row.who,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Status',
      cell: (row: { status: any }) => {
        return (
          <div className={classes.statusColumn}>
            {capitalizeOnlyFirstLetter(row.status)}
          </div>
        );
      },
      minWidth: isMobile ? '125px' : '',
      selector: (row: { status: string }) => row.status,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: { email: string }) => row.email,
      sortable: true,
      minWidth: isMobile ? '270px' : '',
      grow: 2,
    },
    {
      name: 'Role',
      cell: (row: {
        type: any[];
        id: string;
        who: string;
        completedOnboarding: boolean;
      }) => (
        <Select
          dropdownPosition="flip"
          value={row.type.filter((r) => MAIN_ROLES.includes(r))?.[0] || ''}
          data={ROLE_SELECT_VALUES}
          style={{ width: isMobile ? 130 : 235 }}
          disabled={!isAdmin}
          placeholder={'Unassigned'}
          onChange={(value: any) => {
            if (value) {
              // Filter out main roles while keeping others so they don't get overwritten.
              const currentRoles = row.type.filter(
                (r) => !MAIN_ROLES.includes(r),
              );
              updateUserRole(row.who, row.id, {
                roles: [...currentRoles, value.toUpperCase()],
              });
            }
          }}
        />
      ),
      selector: (row: { type: any[] }) => row.type[0],
      sortable: true,
      grow: 2,
    },
    {
      cell: (row: { id: string; who: string; type: string }) => (
        <Menu
          position="bottom-end"
          classNames={{ dropdown: classes.dropdown }}
          styles={customMantineStyles(theme.fn.primaryColor())}
        >
          <Menu.Target>
            <div style={{ width: '30px' }}>
              <RowAction style={{ fill: '#979797', cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              style={{ fontSize: '14px', width: 150, color: 'red' }}
              onClick={() => {
                if (row.type[0] === 'NO-LOGINS') {
                  showNotification({
                    color: 'red',
                    title: 'Failure',
                    message: `${row.who} is not fully onboarded and cannot be deleted.`,
                  });
                } else {
                  openTransparentModal(
                    <DeleteTeamMember
                      userId={row.id}
                      userName={row.who}
                      closeModal={closeAllModals}
                      refreshTeamMembers={async () => {
                        await getAllUsers();
                      }}
                    />,
                  );
                }
              }}
            >
              Remove
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
      compact: true,
      width: '25px',
    },
  ];

  /**
   * Prep/format data to be passed into the FlexbaseTable component
   */
  const tableData = allUsers.map((user) => {
    return {
      id: user.id,
      who: `${user.firstName} ${user.lastName}`,
      initials: getInitialsOfNames(`${user.firstName} ${user.lastName}`),
      status: user.status,
      email: user.email || 'N/A',
      type: user.roles,
      completedOnboarding: user.completedOnboarding,
    };
  });

  /**
   * Define column to filter against
   *
   * @param column
   */
  const setFilterColumn = (column: string) => {
    return (column && column.toLowerCase()).startsWith(
      filterText.toLowerCase(),
    );
  };

  /**
   * Define criterion/columns for search/filtering
   */
  const filteredItems = tableData.filter((item) => {
    if (checkPending) {
      return !item.completedOnboarding;
    } else {
      return (
        setFilterColumn(item.who) ||
        setFilterColumn(item.email) ||
        setFilterColumn(item.type![0])
      );
    }
  });
  return (
    <div className={classes.baseContainer}>
      <FlexbaseDefaultHeader title={'Team'} />
      <div className={classes.container}>
        <div className={classes.widgetContainer}>
          <TeamMembersHeader
            onFilter={(e) => setFilterText(e.target.value)}
            filterText={filterText}
            pendingInvitationsClicked={pendingInvitationsClicked}
            updatePendingInvitationClicked={updatePendingInvitationClicked}
            updateCheckingPending={updateCheckingPending}
            getAllUsers={getAllUsers}
          />
          <FlexbaseTable
            columns={columns}
            data={filteredItems}
            isFetchingData={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default TeamMembers;
