import { useState } from 'react';
import { useForm } from '@mantine/form';
import { Alert, Button, Group, Select, TextInput } from '@mantine/core';

import { RedAlertIcon } from 'assets/svg';
import { useStyles } from './debit-card-details.styles';
import { CardByUser } from 'services/flexbase/banking.model';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';

interface Props {
  cardData: any;
  setCardData: (x: any) => void;
  setEditLimits: (x: boolean) => void;
  updateDebitCardsList: (x: CardByUser) => void;
}

const UpdateDebitCard = ({
  cardData,
  setCardData,
  setEditLimits,
  updateDebitCardsList,
}: Props) => {
  const { classes } = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const form = useForm({
    initialValues: {
      amount: cardData.limit,
      limitType: cardData.limitType,
    },
  });

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    form.setFieldValue('amount', Number(currentValue));
    setHasChanges(true);
  };

  const updateCard = async () => {
    try {
      setLoading(true);
      const cardLimits =
        form.values.limitType === 'daily'
          ? { dailyPurchase: form.values.amount }
          : { monthlyPurchase: form.values.amount };

      const updateCard =
        form.values.limitType !== 'unlimited'
          ? {
              id: cardData.id,
              limits: cardLimits,
            }
          : { id: cardData.id, limits: {} };

      const { success, card } =
        await flexbaseBankingClient.updateBankingDebitCard(updateCard);

      if (success && card) {
        setCardData({
          ...card,
          limit: form.values.amount,
          limitType: form.values.limitType,
        });

        updateDebitCardsList(card);
        setHasChanges(false);
        setEditLimits(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('Unable to update the card info', error);
    } finally {
      setLoading(false);
    }
  };

  setTimeout(() => {
    if (error) {
      setError(false);
    }
  }, 5000);

  return (
    <div className={classes.padding}>
      <Select
        label="Limit type"
        value={form.values.limitType}
        data={[
          { label: 'Daily', value: 'daily' },
          { label: 'Monthly', value: 'monthly' },
          { value: 'unlimited', label: 'Unlimited' },
        ]}
        onChange={(value) => {
          form.setFieldValue('limitType', value);
          setHasChanges(true);
        }}
      />

      {form.values.limitType !== 'unlimited' && (
        <TextInput
          mt={12}
          label="Spending limit"
          value={formatCurrency(form.values.amount || '0')}
          onChange={(v) => onInputChange(v.currentTarget.value)}
        />
      )}

      {error && (
        <Alert color="red" mt={20} icon={<RedAlertIcon />}>
          Unable to update the card. Please, try it again.
        </Alert>
      )}

      <Group mt={20} position="right">
        <Button variant="outline" onClick={() => setEditLimits(false)}>
          Cancel
        </Button>
        <Button
          {...{ loading }}
          onClick={() => {
            updateCard();
            setError(false);
          }}
          disabled={!hasChanges}
        >
          Save
        </Button>
      </Group>
    </div>
  );
};

export default UpdateDebitCard;
