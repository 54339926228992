import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';
import { Outlet } from 'react-router-dom';
import NavTabs, { NavTab } from 'components/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import { PlusSignIcon } from 'assets/svg';
import ContactForm from './contact-form/contact-form';
import useModal from 'components/modal/modal-hook';

const tabs: NavTab[] = [
  { label: 'Team', route: '/' },
  { label: 'Customers', route: '/' },
  { label: 'Vendors', route: '/people-projects/vendors' },
  { label: 'Projects', route: '/' },
];

const PeopleProjects = () => {
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const openContactForm = () => {
    modal.openFullModal(<ContactForm {...{ closeModal }} />);
  };

  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'People & Projects'} />
      <NavTabs
        rightButton={true}
        rightButtonLabel="Add Contact"
        rightButtonIcon={<PlusSignIcon />}
        rightButtonFn={openContactForm}
        tabs={tabs}
      />
      <Outlet />
    </FlexbaseMainLayout>
  );
};

export default PeopleProjects;
