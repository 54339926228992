import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tabs: {
    display: 'flex',
    marginBottom: '40px',
  },

  card: {
    padding: '24px',
    borderRadius: theme.defaultRadius,
    backgroundColor: '#fff',
    table: {
      thead: {
        borderTop: '1px solid #E6E7E9',
      },
      tbody: {
        'tr:nth-of-type(even)': {
          backgroundColor: 'rgba(230, 231, 233, 0.12)',
        },
      },
    },
  },
}));
