import { ChevronDownIcon } from 'assets/svg';
import { useStyles } from './styles';
import { ReactNode } from 'react';

type Props = {
  onClick?: () => void;
  showArrow?: boolean;
  rotateArrow?: boolean;
  isListItem?: boolean;
  icon: ReactNode;
  headerText: string;
  subheaderText?: string | ReactNode;
  showBorder?: boolean;
  classNames?: Partial<{ containerClassName: string }>;
};

export const AccountBox = ({
  headerText,
  subheaderText,
  showArrow,
  classNames,
  isListItem,
  icon,
  showBorder,
  onClick,
  rotateArrow,
}: Props) => {
  const { classes, cx } = useStyles({ rotateArrow });

  return (
    <div
      className={cx(
        classes.sendToBox,
        isListItem && classes.listItem,
        classNames?.containerClassName,
      )}
      style={!showBorder ? { border: 'none' } : {}}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div id="icon" className={classes.initials}>
        {icon}
      </div>
      <div>
        <div className={classes.optionText}>{headerText}</div>
        <div className={classes.optionInfoContainer}>{subheaderText}</div>
      </div>
      {showArrow && (
        <ChevronDownIcon className={classes.arrowIcon} fill="#94928F" />
      )}
    </div>
  );
};
export default AccountBox;
