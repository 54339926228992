import { Image } from '@mantine/core';
import { useStartScreenStyles } from './styles';
import { ReactNode } from 'react';
import { OnboardingProduct } from 'states/application/product-onboarding.models';
import RegisterImage from 'assets/images/register.png';
import BankingRegisterImage from 'assets/images/banking-registration.png';

const FlexbaseTreasuryImageUrl =
  'https://assets-global.website-files.com/6334b9839008121d98519897/641259b7c3de7251ed9a8e86_Treasury%20Management%20-%20Default%20(1).svg';

type Props = { product: OnboardingProduct; isBnpl: boolean };
const RegistrationImage = ({ product, isBnpl }: Props) => {
  const { classes, cx } = useStartScreenStyles();

  let image: ReactNode;

  if (product === 'CREDIT' && !isBnpl) {
    image = <Image src={RegisterImage} maw={1200} />;
  } else if (product === 'TREASURY') {
    image = <Image src={FlexbaseTreasuryImageUrl} width={1200} />;
  } else if (product === 'BANKING') {
    image = <Image src={BankingRegisterImage} />;
  } else {
    image = <Image src="/img.png" height="100%" />;
  }

  return (
    <div
      className={cx(classes.imageContainer, {
        [classes.imageContainerBorder]: product === 'TREASURY',
      })}
    >
      {image}
    </div>
  );
};

export default RegistrationImage;
