import { formatUSPhoneNumber } from 'utilities/formatters/format-phone-number';
import { validateUSPhoneNumber } from 'utilities/validators/validate-phone-number';
import { useRecoilValue } from 'recoil';
import { useProductOnboarding } from '../../onboarding-hooks';
import { useState } from 'react';
import { useVerifyPhoneStyles } from './styles';
import FlexbaseInput from 'components/input/flexbase-input';
import OnboardingStep from '../../components/onboarding-step';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import VerifyCode from '../verify-code/verify-code';
import {
  InProgressUser,
  OnboardingUserPhone,
  ProductNavStack,
} from 'states/application/product-onboarding';
import { useOnboardingStyles } from '../../onboarding.styles';
import TagManager from 'react-gtm-module';
import { formatPhoneForApi } from '@flexbase-eng/web-components';
import { notifications } from '@mantine/notifications';

const VerifyPhone = () => {
  const { navigateToNextProductStep } = useProductOnboarding();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { classes } = useVerifyPhoneStyles();
  const [error, setError] = useState('');
  const [hasInputError, setHasInputError] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentPhone = useRecoilValue(OnboardingUserPhone);
  const inProgressUser = useRecoilValue(InProgressUser);
  const navStack = useRecoilValue(ProductNavStack);
  const [phoneNumber, _setPhoneNumber] = useState(
    currentPhone || inProgressUser.phone || '',
  );
  const [generatingCode, setGeneratingCode] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);

  const setPhoneNumber = (value: string) => {
    if (hasInputError && validateUSPhoneNumber(value)) {
      setHasInputError(false);
      setError('');
    }

    _setPhoneNumber(formatUSPhoneNumber(value));
  };

  const generateCode = async (resend = false) => {
    try {
      if (error) {
        setError('');
      }
      setGeneratingCode(true);
      const result = await flexbaseClient.generateCode(phoneNumber);
      if (!result) {
        setError('You have sent too many verification codes, try again later!');
      }
      if (resend) {
        notifications.show({
          color: 'something.2',
          title: 'Code Resent',
          message: 'A new code was successfully sent',
        });
      }
      setGeneratingCode(false);
      return result;
    } catch (error) {
      console.error('Unable to resend code', error);
      setError('Unable to resend code');
    }
  };

  const onPhoneContinue = async () => {
    setLoading(true);
    const validationResult = validateUSPhoneNumber(phoneNumber);

    if (!validationResult) {
      setHasInputError(true);
      setError('Please enter a valid phone number');
      setLoading(false);
      return;
    }

    // If there are entries in the navStack indicating a back button click and no changes to phone, navigate.
    if (currentPhone && phoneNumber === currentPhone && navStack.length > 0) {
      navigateToNextProductStep();
      return;
    }

    try {
      const formattedPhone = formatPhoneForApi(phoneNumber);
      await flexbaseOnboardingClient.updateUser({
        cellPhone: formattedPhone,
        phone: formattedPhone,
      });
      const result = await generateCode();
      if (!result) {
        return;
      }
      setShowVerify(true);
    } catch (e) {
      setError(
        'Failed to update phone number. Please contact support if the issue continues.',
      );
    } finally {
      setLoading(false);
    }
  };

  const onNextClick = () => {
    if (!codeVerified) {
      setError('Verify the code sent to your phone to continue.');
    } else {
      navigateToNextProductStep();
    }
  };

  const onCodeVerified = () => {
    const dataLayerArgs = {
      dataLayer: {
        event: 'phoneVerified',
      },
    };
    TagManager.dataLayer(dataLayerArgs);
    setCodeVerified(true);
    navigateToNextProductStep();
  };

  const onBackClick = () => {
    setError('');
    setShowVerify(false);
  };

  return (
    <>
      <OnboardingStep
        title={showVerify ? 'Enter verification code' : 'Verify your number'}
        stepId="verify-phone"
        subtitle={
          showVerify
            ? `Enter the 6 digit code sent to ${phoneNumber}`
            : 'We’ll you a one time security code'
        }
        showBack={showVerify}
        nextLabel={showVerify ? 'Verify' : 'Send Verification'}
        error={error}
        onNextClick={showVerify ? onNextClick : onPhoneContinue}
        onBackClick={onBackClick}
        showNext={true}
        showContinueSpinner={loading}
        bottomDisclosureText={
          !showVerify
            ? 'By clicking "Send Verification," you agree to receive SMS alerts from Flex regarding account activity. Standard messaging and data rates may apply. '
            : null
        }
        width={360}
      >
        {showVerify ? (
          <VerifyCode
            generateCode={generateCode}
            generatingCode={generatingCode}
            onCodeVerify={onCodeVerified}
            onCodeError={(e) => setError(e)}
            cellPhone={phoneNumber}
          />
        ) : (
          <>
            {/*<Text p="0" className={classes.txtPhoneNumber}>*/}
            {/*  Phone number*/}
            {/*</Text>*/}
            <div className={classes.inputContainer}>
              <FlexbaseInput
                label="Cellphone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter number"
                error={hasInputError}
                classNames={{
                  root: onboardingClasses.inputRoot,
                  label: onboardingClasses.inputLabel,
                }}
                id="input-phone-number"
              />
            </div>
          </>
        )}
      </OnboardingStep>
    </>
  );
};

export default VerifyPhone;
