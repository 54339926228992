import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import {
  Badge,
  createStyles,
  Skeleton,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';

import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import { ChipIcon, FlexIcon, VisaIcon } from 'assets/svg';
import TwoFactorAuth from 'components/auth/two-factor-auth';
import { CardByUser } from 'services/flexbase/banking.model';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { useMediaQuery } from '@mantine/hooks';

interface Props {
  card: CardByUser;
  cardStatus: string;
  displayData?: boolean;
  setDisplayData?: (x: boolean) => void;
}

declare const VGSShow: any;

const DebitCard = ({
  card,
  cardStatus,
  displayData,
  setDisplayData,
}: Props) => {
  const theme = useMantineTheme();
  const [token, setToken] = useState('');
  const [retry, setRetry] = useState(false);
  const userId = useRecoilValue(UserIdState);
  const [errorMsg, setErrorMsg] = useState('');
  const { classes } = useStyles({ cardStatus });
  const [loading, setLoading] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [needsTwoFactor, setNeedsTwoFactor] = useState(false);
  const [bearerToken, setBearerToken] = useState<string | null>(null);

  const formatCardStatus: any = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Unactivated',
    terminated: 'Terminated',
  };

  // Create iframe for CVV2 and Card Number
  const show = VGSShow.create(import.meta.env.VITE_APP_UNIT_VAULT_ID);
  const customerToken = bearerToken;
  const cardId = card.ucCardId.substring(5);

  const cvv2iframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: '/cards/' + cardId + '/secure-data/cvv2',
    headers: {
      Authorization: 'Bearer ' + customerToken,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.cvv2',
  });

  const cardNumberIframe = show.request({
    name: 'data-text',
    method: 'GET',
    path: '/cards/' + cardId + '/secure-data/pan',
    headers: {
      Authorization: 'Bearer ' + customerToken,
    },
    htmlWrapper: 'text',
    jsonPathSelector: 'data.attributes.pan',
    serializers: [
      show.SERIALIZERS.replace(
        '(\\d{4})(\\d{4})(\\d{4})(\\d{4})',
        '$1 $2 $3 $4',
      ),
    ],
  });

  const displayCardData = () => {
    if (setDisplayData) {
      setDisplayData(true);
    }
  };

  const getUnitToken = async () => {
    setRetry(true);
    setErrorMsg('');
    setBearerToken(null);
    try {
      setLoading(true);
      displayCardData();
      setNeedsTwoFactor(true);
      const unitToken = await flexbaseClient.getUnitcoToken();
      if (unitToken.success && unitToken.attributes) {
        setToken(unitToken.attributes?.verificationToken);
      }
    } catch (error) {
      console.error('Eror updating costumer token', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = async (code: string) => {
    setErrorMsg('');
    try {
      if (code.length === 6) {
        setLoading(true);
        const tokenResponse = await flexbaseBankingClient.createCustTokenVGS({
          verificationCode: code,
          verificationToken: token,
        });

        if (tokenResponse.success) {
          setBearerToken(tokenResponse.accessToken);
          setNeedsTwoFactor(false);
        } else {
          setErrorMsg('Invalid code. Please try again or re-send the code');
          setRetry(true);
        }
      }
    } catch (error) {
      console.error('Error updating costumer token', error);
      setRetry(true);
      setErrorMsg('Invalid code. Please try again or re-send the code');
    } finally {
      setLoading(false);
    }
  };

  let badgeColor = '';
  switch (cardStatus) {
    case 'active':
      badgeColor = theme.colors.primarySecondarySuccess[0];
      break;

    case 'suspended':
      badgeColor = theme.colors.promote[1];
      break;
    case 'issued':
      badgeColor = theme.colors.neutral[2];
      break;
    case 'terminated':
      badgeColor = theme.colors.neutral[2];
      break;
  }

  useEffect(() => {
    if (bearerToken) {
      cvv2iframe.render('#cvv2', {
        'font-family': '"Inter", sans-serif',
        color: '#071C14',
        'font-size': '12px',
        'font-weight': '200',
      });
      cardNumberIframe.render('#cardNumber', {
        'font-family': '"Inter", sans-serif',
        color: '#071C14',
        'font-size': '20px',
        'font-weight': '200',
      });
    }
  }, [bearerToken]);

  return (
    <div>
      <div className={classes.debitCardContainer}>
        <div className={classes.headerContainer}>
          <FlexIcon color={theme.fn.primaryColor()} width={60} />
          <Badge
            sx={() => ({
              backgroundColor: badgeColor,
            })}
            classNames={classes}
          >
            {formatCardStatus[cardStatus]}
          </Badge>
        </div>
        <ChipIcon
          width={useMobileView ? 30 : 50}
          style={{ opacity: cardStatus === 'active' ? 1 : 0.5 }}
        />
        <div className={classes.cardInfo}>
          <div>
            <Text size={12}>Flex Technologies</Text>
            <Text size={useMobileView ? 14 : 18}>{card.holder}</Text>
            {displayData ? (
              <div>
                <Skeleton
                  radius={3}
                  h={bearerToken ? 'auto' : 10}
                  my={bearerToken ? 'auto' : 6}
                  visible={bearerToken ? false : true}
                >
                  <div id="cardNumber" />
                </Skeleton>
                <div
                  className={classes.dateAndCodeContainer}
                  style={{ margin: 5 }}
                >
                  <Text size={12}>EXP</Text>
                  <Skeleton
                    radius="xl"
                    h={bearerToken ? 'auto' : 10}
                    width={bearerToken ? 'auto' : 42}
                    visible={bearerToken ? false : true}
                  >
                    <Text size={12}>{card.expirationDate}</Text>
                  </Skeleton>
                  <Text size={12}>CVV </Text>
                  <Skeleton
                    radius="xl"
                    h={bearerToken ? 'auto' : 10}
                    width={bearerToken ? 'auto' : 25}
                    visible={bearerToken ? false : true}
                  >
                    <div id="cvv2" />
                  </Skeleton>
                </div>
              </div>
            ) : (
              <Tooltip
                label="Click to reveal"
                disabled={userId !== card.userId || cardStatus !== 'active'}
                withArrow
              >
                <div
                  onClick={() => {
                    if (cardStatus === 'active' && userId === card.userId) {
                      getUnitToken();
                    }
                  }}
                  style={{
                    cursor:
                      cardStatus === 'active' && userId === card.userId
                        ? 'pointer'
                        : 'auto',
                  }}
                >
                  <Text size={useMobileView ? 14 : 20}>
                    •••• {card.cardNumber.slice(-4)}
                  </Text>
                  <div className={classes.dateAndCodeContainer}>
                    <Text size={12}>EXP ••/••</Text>
                    <Text size={12}>CVV •••</Text>
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
          <div className={classes.cardBrand}>
            <Text align="right">Debit</Text>
            <VisaIcon />
          </div>
        </div>
      </div>
      {token && needsTwoFactor && (
        <div className={classes.twoFactorContainer}>
          <div>Verification required</div>
          <div className={classes.twoFactorText}>Enter the SMS code</div>
          <TwoFactorAuth
            token=""
            errorMsg={errorMsg}
            retry={retry}
            handleOnChange={handleOnChange}
            resendCode={getUnitToken}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default DebitCard;

const useStyles = createStyles((theme, { cardStatus }: Partial<Props>) => ({
  debitCardContainer: {
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[1]}`,
    iframe: {
      width: '100%',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    '@media(max-width: 767px)': {
      padding: '15px',
      margin: '25px 20px',
    },
  },

  inner: {
    color:
      cardStatus === 'active'
        ? theme.colors.primarySecondarySuccess[2]
        : theme.fn.primaryColor(),
  },

  headerContainer: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width: 767px)': {
      marginBottom: 10,
    },
  },

  cardInfo: {
    display: 'flex',
    marginTop: '12px',
    color: theme.fn.primaryColor(),
    justifyContent: 'space-between',
    opacity: cardStatus === 'active' ? 1 : 0.5,
    '@media(max-width: 767px)': {
      marginTop: 0,
    },
  },

  dateAndCodeContainer: {
    gap: 5,
    display: 'flex',
    alignItems: 'center',
  },

  cardBrand: {
    fontSize: 10,
    display: 'flex',
    fontWeight: 600,
    alignSelf: 'end',
    paddingBottom: '5px',
    flexDirection: 'column',
    color: theme.colors.neutral[5],
  },

  twoFactorContainer: {
    margin: '20px 50px',
  },

  twoFactorText: {
    fontSize: '12px',
    marginBottom: '10px',
    '.input': {
      width: '30px',
      height: '30px',
    },
  },
}));
