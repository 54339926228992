import { createStyles } from '@mantine/core';

export const cardStyles = createStyles((theme) => ({
  title: {
    fontSize: '2rem',
    fontWeight: 400,
    '@media(max-width: 767px)': {
      fontSize: '1.3rem',
    },
    fontFamily: 'PP Neue Montreal',
  },

  buttonHolder: {
    display: 'flex',
    position: 'relative',
    marginTop: '5%',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },

  containerImg: {
    display: 'flex',
    marginTop: '1.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20rem',
    backgroundColor: 'rgb(235, 240, 246, 1)',
    borderRadius: '15px',
  },

  imgFreezeCard: {
    height: 200,
    width: '40%',
    '@media(max-width: 767px)': {
      width: '75%',
    },
  },

  imgUnlockCard: {
    height: 200,
    width: '30%',
    '@media(max-width: 767px)': {
      width: '50%',
    },
  },

  input: {
    width: '45px',
    height: '45px',
    padding: 0,
    fontSize: '24px',
    textAlign: 'center',
    marginTop: '25px',
    textTransform: 'uppercase',
    border: '1px solid #d6d6d6',
    borderRadius: '4px',
    background: '#fff',
    backgroundClip: 'padding-box',
  },

  mobileInput: {
    width: '10vw',
    height: '5vh',
    padding: 0,
    fontSize: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    border: '1px solid #d6d6d6',
    borderRadius: '4px',
    background: '#fff',
    backgroundClip: 'padding-box',
  },

  inputContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  message: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.25rem',
  },

  userData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.25rem',
  },

  secondaryAction: {
    border: 0,
    width: '100%',
    color: '#adb0ca',
    fontSize: '12px',
    cursor: 'pointer',
    marginTop: '12px',
    backgroundColor: 'unset',
    justifyContent: 'center',
  },

  cardHolder: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  storeIcon: {
    height: '32px',
    width: '32px',
    color: theme.fn.primaryColor(),
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: '#FFEEEC !important',
    },
  },

  activateCardContent: {
    margin: '0px',
    padding: '40px',
    minHeight: '100vh',
    borderRadius: '8px',
    backgroundColor: '#FAFAFA',
    '.content': {
      display: 'flex',
      marginTop: '20px',
      justifyContent: 'center',
      '> div': {
        width: '60%',
      },
    },
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',
        color: '#979797',
        textAlign: 'center',
      },
    },
    '.loader-content': {
      display: 'flex',
      marginTop: '1.96rem',
      justifyContent: 'center',
      div: {
        width: '130px',
        display: 'flex',
        padding: '10px 0px',
        justifyContent: 'center',
        borderBottom: '3px solid #27C281',
      },
    },
    '.card': {
      padding: '34px',
      color: '#5F5F5F',
      borderRadius: '8px',
      position: 'relative',
      backgroundColor: '#fff',
      span: {
        color: '#000',
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '33px',
      },
      button: {
        span: {
          color: '#fff',
          fontWeight: 500,
          fontSize: '14px',
        },
      },
    },
    '.sub-text': {
      margin: '0px',
      color: '#000',
      fontWeight: 400,
      fontSize: '14px',
      marginTop: '10px',
    },
    '.credit-card': {
      padding: '20px',
      fontSize: '10px',
      margin: '30px 0px',
      borderRadius: '15px',
      border: '1px solid #DFDFDF',
      p: {
        margin: '0px',
      },
    },
    '.badge': {
      span: {
        color: '#fff',
        fontWeight: 400,
        fontSize: '10px !important',
        textTransform: 'capitalize',
      },
      padding: '5px 12px',
      backgroundColor: '#5F5F5F',
    },
    '.row-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '.close-sm': {
      width: '100%',
      color: '#5F5F5F',
      fontSize: '14px',
      marginTop: '10px',
      textAlign: 'center',
    },

    '@media(max-width: 767px)': {
      padding: '20px',
      minHeight: '88vh',
    },
  },
}));
