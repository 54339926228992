import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  containerSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1.5rem',
    paddingBottom: '24px',
  },

  searchInput: {
    color: 'black',
    display: 'flex',
    height: '40px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      width: '65%',
    },
  },

  btnOptions: {
    fill: '#979797',
    cursor: 'pointer',
    marginRight: 25,
  },

  containerTable: {
    background: 'white',
    borderRadius: '8px',
    margin: '1rem 0rem',
    padding: '0.1rem 1.5rem',
  },

  tableTitle: {
    color: '#5F5F5F',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
  },

  actionsSm: {
    height: '40px',
    opacity: 0.8,
    border: '1px solid #E6E7E9',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    width: '152px',
    '&:hover': { borderColor: theme.fn.primaryColor() },
    '&[aria-expanded="true"]': { borderColor: theme.fn.primaryColor() },
  },
}));

export const CustomMantineStyles = (primaryColor?: string) => {
  return {
    searchContacts: {
      input: {
        color: '#5F5F5F',
        width: '368px',
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        background: 'white',
        '&:focus': { borderColor: primaryColor },
      },
    },
    actions: {
      root: {
        width: '252px',
        height: '40px',
        '&:hover': { cursor: 'pointer' },
      },
      item: {
        opacity: 0.8,
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 500,
        color: '#5F5F5F',
        background: 'white',
        cursor: 'pointer',
        '&:hover': {
          border: 'none',
          background: 'rgba(255, 87, 69, 0.08);',
        },
      },
    },
  };
};
