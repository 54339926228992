import { createStyles, Loader, Text, useMantineTheme } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  mainLoading: {
    minHeight: '92.5vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingMsg: {
    color: 'black',
    fontSize: '17px',
    lineHeight: '22px',
    marginTop: '2.5vh',
    flexGrow: 0,
  },
}));

const BnplLoading = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  return (
    <div className={classes.mainLoading}>
      <Loader color={theme.fn.primaryColor()} size={110} />
      <Text
        className={classes.loadingMsg}
      >{`Sit tight, we're working on it`}</Text>
    </div>
  );
};

export default BnplLoading;
