import { useState } from 'react';
import { useClipboard } from '@mantine/hooks';
import { Image, Badge, Tooltip } from '@mantine/core';
import { CardHiddenInfo } from 'flexbase-client/dist/models/Card/Card';

import { Card } from 'states/cards/card-info';
import { MastercardLogo, ChipIcon } from 'assets/svg';
import { formatCardNumber } from 'utilities/formatters/format-card-number';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

interface ICardDetails {
  card: Card;
  cardStatus: string;
  cardData: CardHiddenInfo;
}

const CreditCard = ({ card, cardStatus, cardData }: ICardDetails) => {
  const clipboard = useClipboard();
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);

  const cardStatusFormat: any = {
    terminated: 'Canceled',
    active: 'Active',
    suspended: 'Frozen',
    requested: 'Requested',
    issued: 'Unactivated',
  };

  return (
    <div
      className={cardStatus === 'active' ? 'active-credit-card' : 'credit-card'}
    >
      <div className="row-content" style={{ marginBottom: '20px' }}>
        <Image
          width={70}
          height={15}
          src="/svg/flexbaseLogoNew.svg"
          style={{ opacity: cardStatus === 'active' ? 1 : 0.5 }}
        />
        <Badge
          className="badge"
          style={{
            backgroundColor: cardStatus === 'active' ? '#0FA669' : '#5F5F5F',
          }}
        >
          {cardStatusFormat[cardStatus]}
        </Badge>
      </div>
      <ChipIcon
        width={42}
        style={{ opacity: cardStatus === 'active' ? 1 : 0.5 }}
      />
      <div
        className="row-content"
        style={{
          marginTop: '12px',
          alignItems: 'flex-end',
          opacity: cardStatus === 'active' ? 1 : 0.5,
        }}
      >
        <div>
          <p style={{ fontSize: '12px' }}>Flexbase Technologies</p>
          <p>{card.holder}</p>
          {card.cardType.toLowerCase() === 'physical' ? (
            <>
              {cardStatus === 'issued' || cardStatus === 'terminated' ? (
                ''
              ) : (
                <p>{getPaddedAccountMask(card?.cardNumber, 4)}</p>
              )}{' '}
            </>
          ) : (
            <Tooltip
              withArrow
              label={
                displayAccountNumber
                  ? clipboard.copied
                    ? 'Copied'
                    : 'Click to copy'
                  : 'Click to reveal'
              }
              disabled={cardStatus !== 'active'}
            >
              {displayAccountNumber ? (
                <div onClick={() => setDisplayAccountNumber(true)}>
                  <p onClick={() => clipboard.copy(cardData.cardNumber)}>
                    {formatCardNumber(cardData.cardNumber!)}
                  </p>
                  <p>
                    EXP {cardData.expirationDate} CVC {cardData.cvc}
                  </p>
                </div>
              ) : (
                <div onClick={() => setDisplayAccountNumber(true)}>
                  <p>{getPaddedAccountMask(card?.cardNumber, 4)}</p>
                  <p>EXP ••/•• CVC •••</p>
                </div>
              )}
            </Tooltip>
          )}
        </div>
        <div>
          <p style={{ fontSize: '10px', textAlign: 'end' }}>Credit</p>
          <MastercardLogo />
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
