import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export interface NavigationUrlState {
  /**
   * Represents the URL a user was trying to access before they were routed to a mandatory action.
   * A mandatory action would be login, register, onboarding, or anything that the user is required
   * to do before proceeding.
   *
   * Example: A user tries to access /cards but is not logged in. They are routed to /login, and after
   * they have been authorized they are routed back to the pendingUrl, /cards.
   */
  pendingUrl: string;

  /**
   * Represents the URL a user will be routed to after logging out. The `onboarded-routes` route guard will
   * generally send unauthorized users to the /register page. If different behavior is desired (for example if the user
   * clicks "Log Out"), then this should be set to the desired page.
   */
  noAuthUrl: string;
}

export const PendingUrlState = atom<string>({
  key: 'PendingUrlState', //pending url
  default: '',
  effects: [
    recoilPersist({
      key: 'flexbase_pending_url',
      storage: localStorage,
    }).persistAtom,
  ],
});

export const NoAuthUrlState = atom<string>({
  key: 'no_auth_url_state',
  default: '/register',
  effects: [
    recoilPersist({
      key: 'flexbase_no_auth_url',
      storage: localStorage,
    }).persistAtom,
  ],
});
