import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  baseContainer: {
    paddingBottom: 24,
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    paddingBottom: 13,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerContainerSm: {
    flexDirection: 'column',
    paddingBottom: 13,
  },
  actionsContainer: {
    gap: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterListContainer: { display: 'flex' },
  title: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#5F5F5F',
    fontFamily: 'PP Neue Montreal',
  },
  actions: {
    opacity: 0.8,
    padding: '10px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 400,
    color: theme.fn.primaryColor(),
    background: 'white',
    cursor: 'pointer',
    '&:hover': {
      border: 'none',
      background: theme.fn.themeColor('neutral', 2),
    },
  },
  rigthSection: {
    padding: '6px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
}));
