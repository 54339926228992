import { ContextModalProps } from '@mantine/modals';

const ModalRight = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{ children: JSX.Element }>) => (
  <div key={id}>{innerProps.children}</div>
);

export default ModalRight;
