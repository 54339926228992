import { createStyles, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { FlexbaseTheme } from './theme';
import ModalCenter from 'components/modal/modal-center';
import ModalRight from 'components/modal/modal-right';
import RecoilNexus from 'utilities/recoil/nexus';
import { getEnvironment } from 'utilities/url/window-helpers';
import FlexbaseRoutes from './routes';
import { Fonts } from './global';
import ZenDesk from 'components/zendesk/zendesk';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { FacebookPixel } from 'services/analytics/pixel';
import Loadable from './components/loading/loadable';
import { Notifications } from '@mantine/notifications';
import TagManager from 'react-gtm-module';
import { AuthProvider } from './states/auth/auth-token';
import { RouteSectionProvider } from './components/routes/route-context';

const useStyles = createStyles(() => ({
  fullheight: {
    height: '100vh',
  },
}));

// https://github.com/segmentio/analytics-next/tree/master/packages/browser
export const analytics = AnalyticsBrowser.load(
  {
    writeKey: import.meta.env.VITE_APP_SEGMENT_KEY || '',
  },
  {
    integrations: {
      'Segment.io': {
        apiHost:
          import.meta.env.VITE_APP_ANALYTICS_PROXY ||
          'https://proxy-dev.flexbase.app/v1',
        protocol: 'https',
      },
    },
    user: {
      persist: false,
    },
  },
);

function App() {
  const hostEnv = getEnvironment(window.location.host);
  FacebookPixel.init();
  const AuthenticatedUserState = localStorage?.getItem('flexbase_user');

  let parseData;
  let userKey;
  try {
    parseData = AuthenticatedUserState
      ? JSON.parse(AuthenticatedUserState)
      : null;
    userKey = parseData ? parseData.AuthenticatedUserState.user.userId : 'anon';
  } catch {
    parseData = null;
    userKey = 'anon';
  }

  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: import.meta.env.VITE_APP_SPLIT_FEATURE_FLAG || '',
      key: userKey,
    },
  };

  if (hostEnv === 'production' || hostEnv === 'development') {
    Sentry.init({
      dsn: import.meta.env.VITE_APP_SENTRY_KEY,
      release: import.meta.env.VITE_APP_GITHUB_SHA,
      replaysSessionSampleRate: hostEnv === 'production' ? 0.3 : 0.0,
      replaysOnErrorSampleRate: hostEnv === 'production' ? 1.0 : 0.0,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: hostEnv === 'production' ? 0.3 : 1.0,
      environment: hostEnv,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        "Can't find variable: _AutofillCallbackHandler",
      ],
    });
  }

  if (hostEnv === 'development' || hostEnv === 'local') {
    TagManager.initialize({
      gtmId: 'GTM-WFHSQ3V',
    });
  }

  if (hostEnv === 'production') {
    TagManager.initialize({
      gtmId: 'GTM-MTJK9FZ',
    });
  }

  const { classes } = useStyles();

  return (
    <>
      {hostEnv === 'production' && <ZenDesk />}
      <SplitFactory config={sdkConfig}>
        <div className={classes.fullheight} id="app-body">
          <Helmet>
            <meta name="robots" content="noindex" />
            <meta
              name="facebook-domain-verification"
              content="r9grrg8jfhkdxbdbo6zaifyynbfluf"
            />
            <script
              type="text/javascript"
              src="https://js.verygoodvault.com/vgs-show/2.1.0/show.js"
            ></script>
          </Helmet>
          <RecoilRoot>
            <RecoilNexus />
            <MantineProvider
              theme={FlexbaseTheme}
              withGlobalStyles
              withNormalizeCSS
            >
              <Fonts />
              <Notifications position="bottom-left" />
              <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
                <ModalsProvider
                  modals={{ center: ModalCenter, right: ModalRight }}
                >
                  <AuthProvider>
                    <Loadable>
                      <RouteSectionProvider>
                        <FlexbaseRoutes />
                      </RouteSectionProvider>
                    </Loadable>
                  </AuthProvider>
                </ModalsProvider>
              </BrowserRouter>
            </MantineProvider>
          </RecoilRoot>
        </div>
      </SplitFactory>
    </>
  );
}

export default App;
