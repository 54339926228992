import FlexbaseInput from 'components/input/flexbase-input';
import { useOnboardingStyles } from '../../onboarding.styles';
import { useState } from 'react';
import {
  CompanyOwner,
  OnboardingUser,
} from 'states/onboarding/onboarding-info';
import { useForm } from '@mantine/form';
import { RequiredFieldValidator } from 'utilities/validators/validate-required';
import { Box, Button, Text } from '@mantine/core';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  owner?: Partial<CompanyOwner>;
  onCancel: () => void;
  onSuccess: (owner: OnboardingUser) => void;
  totalOwnershipPercent: number;
};

const BeneficialOwnerForm = ({
  owner,
  onCancel,
  onSuccess,
  totalOwnershipPercent,
}: Props) => {
  const { classes, cx } = useOnboardingStyles();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      firstName: owner?.firstName || '',
      lastName: owner?.lastName || '',
      email: owner?.email || '',
      ownershipPct: owner?.ownershipPct ? owner.ownershipPct + '' : '',
    },
    validate: {
      firstName: RequiredFieldValidator('First name is required'),
      lastName: RequiredFieldValidator('Last name is required'),
      email: RequiredFieldValidator('Email is required'),
      ownershipPct: (value) => {
        const asNum = Number(value);
        return !asNum || asNum < 25 ? 'Ownership % must 25 or more' : null;
      },
    },
  });
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const setOwnershipPercent = (value: string) => {
    if (!value || Number(value)) {
      form.setFieldValue('ownershipPct', value);
    }
  };

  const onSubmit = async () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      const ownershipPercent = Number(form.values.ownershipPct);

      const newTotal = owner?.ownershipPct
        ? totalOwnershipPercent - owner.ownershipPct + ownershipPercent
        : totalOwnershipPercent + ownershipPercent;
      if (newTotal > 100) {
        form.setFieldError(
          'ownershipPct',
          `Total ownership % for all owners cannot exceed 100%. (Currently ${newTotal}%)`,
        );
      } else {
        // Attempting owner add
        try {
          setLoading(true);
          const formValues = form.values;
          const request: Partial<OnboardingUser> & { ownershipPct: number } = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            ownershipPct: ownershipPercent,
            email: formValues.email,
            roles: ['OWNER'],
          };
          const result = await flexbaseOnboardingClient.addOwner(request, true);
          onSuccess(result);
        } catch (e) {
          if (
            e?.text &&
            typeof e.text === 'string' &&
            e.text.toLowerCase().includes('already in use')
          ) {
            setError('A user with this email already exists.');
          } else {
            setError(
              `An error occurred, could not ${owner ? 'update' : 'add'} owner.`,
            );
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const onBack = () => {
    onCancel();
  };

  return (
    <div className={classes.addOwnerForm}>
      <div className={classes.containerTitleForm}>
        <Text size={20} c="something.8" ff="Redaction">
          Add Beneficial Owner
        </Text>
        <div className={classes.yellowContainer}>
          <Text>Must own at least 25%</Text>
        </div>
      </div>

      <Box mt="xl" className={classes.containerOwnerForm}>
        <FlexbaseInput
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="Enter first name"
          label="Legal first name"
          {...form.getInputProps('firstName')}
        />
        <FlexbaseInput
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="Enter last name"
          label="Legal Last Name"
          {...form.getInputProps('lastName')}
        />
      </Box>

      <Box mt="md" className={classes.containerOwnerForm}>
        <FlexbaseInput
          w={useMobileView ? 'auto' : '60%'}
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="Email address"
          label="Email address"
          {...form.getInputProps('email')}
        />

        <FlexbaseInput
          classNames={{
            label: classes.inputLabel,
            root: classes.btnSpacingAddressPage,
          }}
          placeholder="%"
          label={'% Ownership'}
          {...form.getInputProps('ownershipPct')}
          onChange={(e) => setOwnershipPercent(e.target.value)}
        />
      </Box>

      {error && (
        <Box mt="xs" className={cx(classes.stepText, classes.stepErrorText)}>
          {error}
        </Box>
      )}

      <Box mt="xl" className={classes.buttonGroup}>
        <Button variant="outline" onClick={() => onBack()} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="outline"
          onClick={() => onSubmit()}
          classNames={{ root: classes.addNewOwnerBtn }}
          loading={loading}
        >
          Save
        </Button>
      </Box>
    </div>
  );
};

export default BeneficialOwnerForm;
