import { createStyles } from '@mantine/core';

export const useUserStyles = createStyles(() => ({
  container: {
    '@media(max-width: 767px)': {
      '.table': {
        td: {
          border: 'none',
          paddingBottom: '0px',
        },
        th: {
          border: 'none',
        },
        tr: {
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #d6d6d6',
          padding: '1em',
          marginBottom: '1em',
        },
        thead: {
          display: 'none',
        },
        'td[data-title]': {
          display: 'flex',
        },
        'td[data-title]::before': {
          content: `attr(data-title)`,
          display: 'grid',
          gridTemplateColumns: '4.5rem',
          fontWeight: 'bold',
        },
        '.btnEdit': {
          width: '100%',
          height: '30px',
          borderRadius: '4px',
          marginTop: '4%',
        },
      },
    },
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',
  },

  // pagination styles
  item: {
    fontSize: '12px',
    color: '#6F7181',
    marginTop: '20px',
    backgroundColor: '#fff',
    border: '1px solid #EBF0F7',
    '&[data-active]': {
      color: '#1DC98B',
    },
  },
  dots: {
    fontSize: '12px',
    color: '#6F7181',
    marginTop: '20px',
    backgroundColor: '#fff',
    border: '1px solid #EBF0F7',
  },
  active: {
    fontSize: '12px',
    color: '#1DC98B',
    marginTop: '20px',
    backgroundColor: '#fff',
    border: '1px solid #EBF0F7',
  },
}));
