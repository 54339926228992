import OnboardingEndScreen from '../../../components/onboarding-end-screen';
import DeclineGraphic from 'assets/images/decline-state.png';
import { useRecoilValue } from 'recoil';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { merchantCallback } from 'utilities/url/get-bnpl-merchant-callback';
import { Text } from '@mantine/core';
import { useEndScreenStyles } from '../../../components/use-end-screen-styles';
import { useAuthToken } from '../../../../../states/auth/auth-token';

const DeclinedBadFico = () => {
  const { logout } = useAuthToken();

  const { isBnpl, callbackBaseUrl, callbackParam, sessionParam } =
    useRecoilValue(BnplState);
  const { classes } = useEndScreenStyles();

  const closeApp = () => {
    if (isBnpl) {
      window.location.replace(
        merchantCallback(
          callbackBaseUrl,
          TransactionStatus.declined,
          callbackParam,
          sessionParam,
        ),
      );
    } else {
      logout();
    }
  };

  return (
    <OnboardingEndScreen
      title="Sorry, you are ineligible for the Flexbase credit card"
      description={
        <>
          <Text className={classes.description} mt={0}>
            Thank you for your interest in the flex card!
          </Text>
          <Text className={classes.description} mt={0}>
            This business credit card is available for those with higher credit
            scores.
          </Text>
          <Text className={classes.description} mt={0}>
            Flex Banking will be available soon and you will be granted early
            access! Keep a lookout for your banking email invitation over the
            coming weeks!
          </Text>
        </>
      }
      graphic={DeclineGraphic}
      primaryButtonText="Close application"
      onPrimaryButtonClick={() => closeApp()}
    />
  );
};
export default DeclinedBadFico;
