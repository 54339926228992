import { Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';

const NoAccountsView = () => {
  return (
    <>
      <View
        style={{
          ...stylesStatementPDF.cardTable,
          marginTop: 20,
          height: 'auto',
        }}
      >
        <View
          style={{
            display: 'flex',
            alignSelf: 'center',
          }}
        >
          <Text style={stylesStatementPDF.text}>
            There are no external accounts to report on
          </Text>
        </View>
      </View>
    </>
  );
};

export default NoAccountsView;
