/**
 * Copied from Mantine docs. It actually works!
 */
import { useState } from 'react';
import { Box, Popover, Progress, Text, useMantineTheme } from '@mantine/core';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';

type Props = { children: JSX.Element; inputValue: string };

const PasswordRequirement = ({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) => {
  const theme = useMantineTheme();
  return (
    <Text
      color={meets ? theme.colors.positive[1] : theme.colors.critical[2]}
      sx={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="sm"
    >
      {meets ? <IoMdCheckmark /> : <IoMdClose />} <Box ml={10}>{label}</Box>
    </Text>
  );
};

const requirements = [
  { re: /[0-9]/, label: 'Includes number' },
  { re: /[a-z]/, label: 'Includes lowercase letter' },
  { re: /[A-Z]/, label: 'Includes uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password: string) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const PasswordStrengthPopover = ({ children, inputValue }: Props) => {
  const theme = useMantineTheme();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(inputValue)}
    />
  ));

  const strength = getStrength(inputValue);
  const color =
    strength === 100
      ? theme.fn.themeColor('primarySecondarySuccess', 8)
      : strength > 50
      ? theme.fn.themeColor('tertiary', 2)
      : theme.fn.themeColor('critical', 2);

  return (
    <Popover
      opened={popoverOpened}
      position="bottom-start"
      withArrow
      width="target"
      trapFocus={false}
      transitionProps={{ transition: 'pop-top-left' }}
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        <PasswordRequirement
          label="Includes at least 8 characters"
          meets={inputValue.length > 7}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordStrengthPopover;
