import { Button, Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import {
  CommercialBank,
  PartnerTemplate,
} from 'services/flexbase/flexbase-onboarding-client';
import BankDiversificationOptionsTable from '../components/treasury-tables/bank-diversification-options-table';
import { useStyles } from '../styles';
import { TableContainerWithSearchFilter } from 'components/table';

const OpenBankAccount = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState<CommercialBank[]>([]);
  const [templates, setTemplates] = useState<PartnerTemplate[]>([]);
  const navigate = useNavigate();

  const getBanks = async () => {
    setLoading(true);
    try {
      const res =
        await flexbaseOnboardingClient.getTreasuryTopCommercialBanks();
      setBanks(res.banks);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getPartnerTemplates = async () => {
    setLoading(true);
    try {
      const res = await flexbaseOnboardingClient.getTreasuryPartnerTemplates();
      setTemplates(res.templates);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const banksWithTemplates = banks.map((bank) => {
    const temp = templates.find(
      (template) => template.partnerName === bank.name,
    );
    return { ...bank, emailId: temp?.id };
  });

  useEffect(() => {
    getBanks();
    getPartnerTemplates();
  }, []);

  return (
    <div style={{ marginBottom: '30px' }}>
      <TableContainerWithSearchFilter title="Bank Diversification Options">
        <BankDiversificationOptionsTable
          banks={banksWithTemplates}
          loading={loading}
        />
      </TableContainerWithSearchFilter>
      <Group>
        <Button
          variant="outline"
          size="lg"
          classNames={{
            root: classes.buttonRoot,
            label: classes.buttonLabel,
            inner: classes.buttonInner,
          }}
          onClick={() => {
            navigate('/accounts');
          }}
        >
          Back to Accounts
        </Button>
      </Group>
    </div>
  );
};

export default OpenBankAccount;
