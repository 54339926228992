import { Button, Text } from '@mantine/core';

interface Props {
  onClick: () => void;
}

const CancelDebitCardError = ({ onClick }: Props) => {
  return (
    <div className="error-step">
      <Text fw={500} size={20} color="neutral.8">
        Something went wrong
      </Text>
      <p>An unexpected error occurred. Please, try it again later</p>
      <Button variant="light" onClick={onClick}>
        Back to debit cards{' '}
      </Button>
    </div>
  );
};

export default CancelDebitCardError;
