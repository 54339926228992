import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './confirmation-receipt.styles';
import { useEffect, useState } from 'react';
import { Loader, useMantineTheme } from '@mantine/core';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { GetPaymentByIdResponse } from '../../../services/flexbase/payment.model';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from '../../../states/application/product-onboarding';
import HeaderPDF from 'components/pdf-viewer/header';

const ConfirmationReceipt = () => {
  const { paymentId } = useParams();
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentResponse, setPaymentResponse] =
    useState<GetPaymentByIdResponse>();

  const styles = useStyles({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[7],
  });

  const { company } = useRecoilValue(ApplicationState);

  const getPayment = async (_paymentId: string) => {
    setIsLoading(true);
    try {
      const result = await flexbaseOnboardingClient.getPaymentById(_paymentId);
      setPaymentResponse(result);
    } catch (e) {
      console.error('confirmation-receipt-pdf.tsx', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentId) {
      getPayment(paymentId);
    }
  }, [paymentId]);

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: '92.5vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader color={theme.fn.primaryColor()} />
      </div>
    );
  }

  const paymentAccount = company.financialInstitutions.find(
    (f) =>
      f.accountId === paymentResponse?.payment?.pldPayment?.transfer?.accountId,
  );

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page wrap size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>
          <View style={{ padding: '0px 25px' }}>
            <View style={styles.containerHeader}>
              <View>
                <Text
                  style={{
                    ...styles.textHeader,
                    fontStyle: 'bold',
                  }}
                >
                  Flex Credit Card
                </Text>
                <Text style={styles.textHeader}>{company?.companyName}</Text>
                <Text style={styles.textHeader}>
                  {company?.address.line1}, {company?.address.line2}
                </Text>
                <Text style={styles.textHeader}>
                  {company?.address.city}, {company?.address.state} -{' '}
                  {company?.address.postalCode}
                </Text>
              </View>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text
                style={{
                  ...styles.text,
                  fontSize: '12px',
                  color: theme.colors.neutral[6],
                }}
              >
                {`Time initiated: ${
                  paymentResponse?.payment.createdAt
                    ? DateTime.fromSQL(
                        paymentResponse.payment.createdAt,
                      ).toFormat('ff')
                    : ''
                }`}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  fontSize: '18px',
                }}
              >
                Your payment receipt
              </Text>
            </View>
            <View style={styles.cardReceiptContainer}>
              <Text style={styles.text}>Payment</Text>
              <Text
                style={{
                  ...styles.text,
                  fontSize: '36px',
                  marginTop: 10,
                }}
              >
                {formatCurrency(
                  paymentResponse?.payment.amount
                    ? Math.abs(+paymentResponse.payment.amount)
                    : 0,
                )}
              </Text>
              <Text style={styles.text}>towards your credit balance</Text>
            </View>

            <View style={styles.cardSentFromContainer}>
              <Text
                style={{
                  ...styles.text,
                  fontStyle: 'normal',
                  color: theme.colors.primarySecondarySuccess[2],
                }}
              >
                Sent from
              </Text>
              <Text
                style={{
                  ...styles.text,
                  marginTop: 10,
                  color: theme.colors.neutral[6],
                }}
              >
                Account
              </Text>
              <Text style={styles.text}>
                {`${paymentAccount?.bankName || 'Bank Name'} / ${
                  paymentAccount?.accountType || 'Account Type'
                } ••${paymentAccount?.last4 || ''}`}
              </Text>
              <Text
                style={{
                  ...styles.text,
                  fontSize: '9px',
                  color: theme.colors.neutral[6],
                  marginTop: 10,
                }}
              >
                Processing of your payment may take up to 4 business days, after
                which your credit balance will be updated.
              </Text>
            </View>
          </View>

          <View style={styles.footerContainer} fixed>
            <View>
              <Text>Flexbase Technologies Inc</Text>
              <Text>390 NE 191st St #8019 Miami, FL 33179</Text>
            </View>
            <View>
              <Text style={{ alignSelf: 'flex-end' }}>Needs help?</Text>
              <Text>support@flex.one or call 415-840-8721</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default ConfirmationReceipt;
