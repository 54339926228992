import FlexbaseDefaultHeader from 'components/header/current/flexbase-header';

// import { useAuthorizedUser } from 'states/auth/authenticated-user';
import { NavTab } from 'components/tabs/nav-tabs';
import { Outlet } from 'react-router-dom';
import FlexbaseMainLayout from 'components/layouts/main';
import { ApplicationState } from 'states/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import NoAccount from 'areas/banking/no-account/no-account';
import { NavTabContainer } from '../../components/tabs/nav-tab-container';

// TODO: future feature for sending invoices to customers
//   { label: 'Incoming', route: '/payments/incoming' },

/**
 *
 * This component orchestrates the various views/components for payments section.
 * The main goal is to provide the overall styling and grid design, calling various views to swap
 * based on which tab the user uses.
 */

const PaymentDashboard = () => {
  const onboardingState = useRecoilValue(ApplicationState);

  const tabs: NavTab[] = [
    { label: 'Outgoing', route: '/payments/outgoing' },
    {
      label: 'Recipients',
      route: '/payments/recipients',
    },
  ];

  return onboardingState.company.optedProducts?.includes('BANKING') ===
    false ? (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Payments'} />
      <NoAccount />
    </FlexbaseMainLayout>
  ) : (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Payments'} />
      <NavTabContainer tabs={tabs}>
        <Outlet />
      </NavTabContainer>
    </FlexbaseMainLayout>
  );
};

export default PaymentDashboard;
