/**
 * Define column to filter against
 *
 * @param column
 */
export const setFilterColumn = (column: string, filterText: string) => {
  if (column) {
    return column.toLowerCase().includes(filterText.toLowerCase());
  }
};
