import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ga4Helpers } from './helpers';

const useGA4 = () => {
  const location = useLocation();
  useEffect(() => {
    trackGA4PageView();
  }, [location]);
  function trackGA4PageView() {
    const route = window.location.pathname;
    ga4Helpers.pageView(route);
  }
};

export default useGA4;
