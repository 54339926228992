export const customMantineStyles = (primaryColor?: string) => {
  return {
    input: {
      height: '40px',
      fontWeight: 500,
      color: '#5F5F5F',
      fontSize: '14px',
      lineHeight: '16px',
      borderRadius: '8px',
      background: 'white',
      border: '1px solid #E6E7E9',
      '&:focus': { borderColor: primaryColor },
    },
    label: {
      color: '#4B4B4B',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19px',
    },
    item: {
      fontSize: 14,
      fontWeight: 500,
      color: '#5F5F5F',
      '&:hover': {
        border: 'none',
        background: 'rgba(255, 87, 69, 0.08);',
      },
    },
    dropdown: {
      borderRadius: 8,
    },
  };
};
