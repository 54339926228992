import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import { useForm } from '@mantine/form';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import { validateUSBirthDate } from 'utilities/validators/validate-dates';
import {
  formatDateForApi,
  formatDateInput,
} from 'utilities/formatters/format-date-input';
import { formatOnboardingApiDate } from 'utilities/formatters/format-datetime';
import { TextInput } from '@mantine/core';

type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryBirthdayForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ user }, setStatus] = useRecoilState(ApplicationState);
  const form = useForm({
    initialValues: {
      birthDate: formatOnboardingApiDate(user.birthDate),
    },
    validate: {
      birthDate: (val) =>
        validateUSBirthDate(val)
          ? null
          : 'Must be at least 18 in format MM/DD/YYYY.',
    },
  });

  const setDob = (value: string) => {
    form.setFieldValue('birthDate', formatDateInput(value));
  };

  const onSave = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const { birthDate } = form.values;
      const formattedDate = formatDateForApi(birthDate);
      await flexbaseOnboardingClient.updateUser({
        id: user.id,
        birthDate: formattedDate,
      });
      setStatus((prev) => ({
        ...prev,
        user: { ...user, birthDate: formattedDate },
      }));
    } catch (e) {
      throw 'Could not update birthday';
    }
  };

  return (
    <SummaryEditBox
      editInputs={
        <div className={classes.inputContainer}>
          <TextInput
            classNames={{ label: onboardingClasses.inputLabel }}
            {...form.getInputProps('birthDate')}
            onChange={(e) => setDob(e.target.value)}
            label="Birthday"
            placeholder="Type in birthday (MM/DD/YYYY)"
            id="input-birthdate"
          />
        </div>
      }
      savePromise={onSave}
      label={'Birthday'}
      value={formatOnboardingApiDate(user.birthDate)}
      onCancelClick={() => form.reset()}
      classNames={classNames}
    />
  );
};

export default SummaryBirthdayForm;
