import { GiPauseButton } from 'react-icons/gi';
import { IoIosArrowDown } from 'react-icons/io';
import { BsFillPlayFill } from 'react-icons/bs';
import { Grid, Loader, Menu, Tooltip, UnstyledButton } from '@mantine/core';

import { Card } from 'states/cards/card-info';
import ActivateCard from '../activate-card/activate-card';
import useModal from 'components/modal/modal-hook';
import flexbaseClient from 'services/flexbase-client';
import { useRecoilValue } from 'recoil';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import {
  IsAdmin,
  IsEmployee,
} from '../../../../states/application/product-onboarding';

interface ICardDetails {
  card: Card;
  cardStatus: string;
  setCardStatus: (x: string) => void;
  setErrorMessage: (x: string) => void;
  setShowOptions: (x: boolean) => void;
  setShowCancelForm: (x: boolean) => void;
  updateCompanyCards: (card: Card) => void;
  amountSpentThisMonth: number;
  isLoadingStatus: boolean;
  setIsLoadingStatus: (x: boolean) => void;
}

const CreditCardActions = ({
  card,
  cardStatus,
  setCardStatus,
  setShowOptions,
  setShowCancelForm,
  setErrorMessage,
  updateCompanyCards,
  amountSpentThisMonth,
  isLoadingStatus,
  setIsLoadingStatus,
}: ICardDetails) => {
  const userId = useRecoilValue(UserIdState);
  const { openFullModal, openCenterModal } = useModal();
  const isAdmin = useRecoilValue(IsAdmin);
  const isEmployee = useRecoilValue(IsEmployee);

  const freezeCard = async () => {
    try {
      setIsLoadingStatus(true);
      const response = await flexbaseClient.updateCardStatus(
        card.id,
        'suspended',
      );
      if (!response || !response.card) {
        throw Error(
          JSON.stringify({
            errorMessage: 'Unable to pause the card, please contact support',
          }),
        );
      }
      const utilization = response?.card?.expensesTypes?.amount
        ? Math.round(
            (amountSpentThisMonth / response?.card.expensesTypes.amount) * 100,
          )
        : 0;
      setCardStatus(response?.card?.status);
      updateCompanyCards({
        ...response.card,
        monthToDateSpends: { mtdSpend: amountSpentThisMonth },
        utilization,
      });
    } catch (error) {
      console.error('ERROR PAUSE THE CARD', error);
      setErrorMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const unlockCard = async () => {
    try {
      setIsLoadingStatus(true);
      const response = await flexbaseClient.updateCardStatus(card.id, 'active');

      if (!response || !response.card) {
        throw Error(
          JSON.stringify({
            errorMessage: 'Unable to unlock the card, please contact support',
          }),
        );
      }
      const utilization = response?.card?.expensesTypes?.amount
        ? Math.round(
            (amountSpentThisMonth / response?.card.expensesTypes.amount) * 100,
          )
        : 0;
      setCardStatus(response.card.status);
      updateCompanyCards({
        ...response.card,
        monthToDateSpends: { mtdSpend: amountSpentThisMonth },
        utilization,
      });
    } catch (error) {
      console.error('ERROR UNLOCK THE CARD', error);
      setErrorMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const activateCard = () => {
    if (screen.width <= 767) {
      openCenterModal(
        <ActivateCard
          {...{ card, updateCompanyCards, amountSpentThisMonth }}
        />,
      );
    } else {
      openFullModal(
        <ActivateCard
          {...{ card, updateCompanyCards, amountSpentThisMonth }}
        />,
      );
    }
  };

  let action: {
    label: string;
    onClick: () => void;
  };
  switch (cardStatus) {
    case 'issued':
      action = {
        label: 'Activate',
        onClick: activateCard,
      };
      break;

    case 'requested':
      action = {
        label: 'Activate',
        onClick: activateCard,
      };
      break;

    case 'suspended':
      action = {
        label: 'Unfreeze',
        onClick: unlockCard,
      };
      break;

    default:
      action = {
        label: 'Freeze',
        onClick: freezeCard,
      };
      break;
  }

  return (
    <>
      {cardStatus !== 'terminated' && (
        <Grid>
          {isAdmin || (isEmployee && userId === card?.userId) ? (
            <>
              <Grid.Col span={6} className="row-content">
                {isLoadingStatus ? (
                  <div className="actions">
                    <Loader size="sm" variant="dots" color="white" />
                  </div>
                ) : (
                  <>
                    {cardStatus === 'active' ? (
                      <UnstyledButton
                        className="freeze-action"
                        onClick={() => action.onClick()}
                      >
                        <GiPauseButton color="#5F5F5F" />

                        <p>{action.label}</p>
                      </UnstyledButton>
                    ) : (
                      <Tooltip
                        withArrow
                        disabled={
                          cardStatus !== 'issued' || card.userId === userId
                        }
                        label="The owner of this card is the only one who can activate it."
                      >
                        <UnstyledButton
                          className={
                            card.userId !== userId && cardStatus === 'issued'
                              ? 'actions-disable'
                              : 'actions'
                          }
                          disabled={
                            cardStatus === 'issued' && card.userId !== userId
                          }
                          onClick={() => action.onClick()}
                        >
                          <BsFillPlayFill
                            color={
                              card.userId !== userId && cardStatus === 'issued'
                                ? 'rgba(95, 95, 95, 0.5)'
                                : '#fff'
                            }
                          />
                          <p>{action.label}</p>
                        </UnstyledButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </Grid.Col>
              {isAdmin && (
                <Grid.Col span={6} className="end-content">
                  <Menu
                    // className="menu" // TODO investigate
                    styles={{
                      item: {
                        fontSize: '14px',
                        color: '#5F5F5F',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 87, 69, 0.08)',
                        },
                      },
                    }}
                  >
                    <Menu.Target>
                      <div className="row-content">
                        <p style={{ marginRight: '5px' }}>More</p>
                        <IoIosArrowDown color="#5F5F5F" size={15} />
                      </div>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item onClick={() => setShowOptions(true)}>
                        Edit card
                      </Menu.Item>
                      <Menu.Item onClick={() => setShowCancelForm(true)}>
                        Cancel card
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Grid.Col>
              )}
            </>
          ) : (
            <>
              {cardStatus === 'issued' && card.userId === userId ? (
                <Grid.Col span={6} className="row-content">
                  {isLoadingStatus ? (
                    <div className="actions">
                      <Loader size="sm" variant="dots" color="white" />
                    </div>
                  ) : (
                    <UnstyledButton className="actions" onClick={activateCard}>
                      <BsFillPlayFill color="#fff" />
                      <p>Activate</p>
                    </UnstyledButton>
                  )}
                </Grid.Col>
              ) : null}
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default CreditCardActions;
