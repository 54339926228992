import ValidatorFn from './validator-fn';
import { ReactNode } from 'react';

export const validateEmail = (value: string): boolean =>
  /^\S+@\S+$/.test(value);

export function EmailValidator(
  errorMessage?: string | ReactNode,
): ValidatorFn<string> {
  return (val) =>
    validateEmail(val) ? null : errorMessage || 'Enter a valid email';
}
