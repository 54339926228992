import { useMediaQuery } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { createStyles, Text } from '@mantine/core';
import { ReactNode } from 'react';
import OnboardingButtonGroup from 'areas/onboarding-v2/components/onboarding-button-group';

const useModalStyles = createStyles((theme) => ({
  title: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
  },
  header: {
    marginBottom: '12px',
  },
  closeButton: {
    color: '#9CA3AF',
  },
  confirmButton: {
    borderColor: theme.fn.themeColor('primarySecondarySuccess', 2),
    background: theme.fn.themeColor('primarySecondarySuccess', 2),
    borderRadius: theme.defaultRadius,
    '&:hover': {
      backgroundColor:
        theme.fn.themeColor('primarySecondarySuccess', 2) + ' !important',
    },
  },
  confirmButtonText: {
    color: 'rgb(252,239,237)',
    fontSize: '14px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  cancelButton: {
    height: '40px',
    backgroundColor: 'inherit',
    border: `1px solid ${theme.fn.themeColor('neutral', 3)}`,
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
    color: theme.fn.themeColor('neutral', 6),
    '&:hover': {
      color: theme.fn.themeColor('primarySecondarySuccess', 8),
      backgroundColor: 'transparent !important',
      borderColor: `${theme.fn.themeColor('primarySecondarySuccess', 8)}`,
    },
  },
  cancelButtonText: {
    fontSize: '13.6px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  confirmTitle: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '34px',
  },
  text: {
    fontFamily: 'PP Neue Montreal',
    fontSize: '16px',
    lineHeight: '23px',
    color: '#4B4B4B',
    marginBottom: 32,
  },
  modalBody: {
    marginBottom: 32,
  },
  hideElement: {
    display: 'none',
  },
}));

type ConfirmationModalOptions = {
  id?: string;
  hideCancelButton?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
  confirmText?: string;
  cancelText?: string;
  showCloseButton?: boolean;
  content: string | ReactNode;
};

const useModal = () => {
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const modals = useModals();
  const { classes, cx } = useModalStyles();

  const openCenterModalUnstyled = (children: JSX.Element) => {
    modals.openContextModal('center', {
      innerProps: { children },
      size: '60%',
      styles: {
        body: { padding: 0 },
        header: { display: 'none' },
      },
      closeOnClickOutside: false,
    });
  };

  const openCenterModal = (children: JSX.Element) => {
    modals.openContextModal('center', {
      innerProps: { children },
      size: '95%',
      styles: {
        body: { minHeight: '100%', padding: '0px !important' },
        header: { display: 'none' },
      },
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  };

  const openSizedCenterModal = (
    children: JSX.Element,
    options: {
      width?: string;
      height?: string;
      margin?: string;
      closeOnClickOutside?: boolean;
      closeOnEscape?: boolean;
      closeModal?: () => void;
    },
  ) => {
    console.log('sized center');
    modals.openContextModal('center', {
      innerProps: { children },
      size: options?.width || '95%',
      styles: {
        body: {
          minHeight: options?.height || '100%',
          padding: '0px !important',
          margin: options?.margin || '16px',
        },
        header: { display: 'none' },
      },
      closeOnClickOutside: options?.closeOnClickOutside || false,
      closeOnEscape: options?.closeOnEscape || false,
      onClose: () => {
        if (options?.closeModal) {
          options?.closeModal();
        }
      },
    });
  };

  const openRightModal = (children: JSX.Element) => {
    if (useMobileView) {
      openSizedCenterModal(children, {
        margin: '0px',
        closeOnClickOutside: true,
      });
      return;
    }
    modals.openContextModal('right', {
      innerProps: { children },
      styles: {
        root: { display: 'flex', justifyContent: 'end' },
        header: { display: 'none' },
        inner: { width: '642px' },
        body: { padding: 0 },
      },
      transitionProps: { transition: 'fade' },
      closeOnClickOutside: true,
      fullScreen: true,
    });
  };

  const openFullModal = (children: JSX.Element) => {
    modals.openContextModal('right', {
      innerProps: { children },
      styles: {
        header: { display: 'none' },
        title: { display: 'none' },
        body: { padding: 0 },
        inner: { padding: 0 },
      },
      transitionProps: { transition: 'fade' },
      closeOnClickOutside: true,
      fullScreen: true,
    });
  };

  const openTransparentModal = (children: JSX.Element) => {
    modals.openModal({
      children,
      styles: {
        header: { display: 'none' },
        title: { display: 'none' },
        body: { padding: 0 },
        inner: { padding: 0 },
        root: { height: '100vh' },
      },
      fullScreen: true,
    });
  };

  const openRightModalNoOpacity = (
    children: JSX.Element,
    options?: {
      closeModal?: () => void;
    },
  ) => {
    modals.openContextModal('right', {
      innerProps: { children },
      styles: {
        root: { display: 'flex', justifyContent: 'end' },
        header: { display: 'none' },
        inner: { width: '500px' },
        body: { padding: '0px' },
        overlay: { background: 'rgba(0, 0, 0, 0.0)' },
      },
      onClose: () => {
        if (options?.closeModal) {
          options?.closeModal();
        }
      },
      transitionProps: { transition: 'fade' },
      closeOnClickOutside: true,
      fullScreen: true,
    });
  };

  const openModal = (
    title: string,
    children: JSX.Element,
    opts?: Partial<{ closeOnClickOutside: boolean; showClose: boolean }>,
  ) => {
    modals.openModal({
      title,
      children,
      classNames: {
        title: classes.title,
        close: classes.closeButton,
        header: classes.header,
      },
      closeOnClickOutside: opts?.closeOnClickOutside ?? true,
      withCloseButton: opts?.showClose ?? true,
    });
  };

  const openConfirmationModal = ({
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    title,
    showCloseButton,
    content,
    id,
    hideCancelButton,
  }: ConfirmationModalOptions) => {
    modals.openConfirmModal({
      modalId: id,
      title: title,
      children:
        typeof content === 'string' ? (
          <Text className={classes.text}>{content}</Text>
        ) : (
          content
        ),
      labels: { confirm: confirmText, cancel: cancelText },
      confirmProps: {
        id: id ? `button-confirm-${id}` : 'button-confirm',
        classNames: {
          root: classes.confirmButton,
        },
      },
      cancelProps: {
        classNames: {
          root: cx(
            classes.cancelButton,
            hideCancelButton && classes.hideElement,
          ),
          inner: classes.cancelButtonText,
        },
        id: id ? `button-cancel-${id}` : 'button-cancel',
      },
      classNames: {
        title: classes.confirmTitle,
      },
      onConfirm: () => {
        if (onConfirm) {
          onConfirm();
        }
      },
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }
      },
      withCloseButton: showCloseButton ?? true,
      closeOnClickOutside: false,
    });
  };

  const closeAllModals = () => {
    modals.closeAll();
  };

  const closeModalById = (id: string) => {
    modals.closeModal(id);
  };

  const openModalForResult = (title: string, children: JSX.Element) => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        title,
        children,
        classNames: {
          title: classes.title,
          close: classes.closeButton,
          header: classes.header,
        },
        onConfirm: () => resolve('onConfirm'),
        onClose: () => resolve('onClose'),
        onCancel: () => resolve('onCancel'),
        // confirmProps: { style: { display: 'none' } },
        cancelProps: { style: { display: 'none' } },
        labels: {
          confirm: <OnboardingButtonGroup showBackButton={false} />,
          cancel: <></>,
        },
      });
    });
  };

  return {
    openCenterModal,
    openSizedCenterModal,
    openRightModal,
    openCenterModalUnstyled,
    openFullModal,
    closeAllModals,
    openTransparentModal,
    openRightModalNoOpacity,
    openModal,
    openConfirmationModal,
    closeModalById,
    openModalForResult,
  };
};

export default useModal;
