import { Button, Flex } from '@mantine/core';
import PaymentsTable from './components/payments-table/payments-table';
import { ArrowWhite } from 'assets/svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';

const OutgoingPayments = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useRecoilValue(UserInfoState);

  return (
    <>
      <Flex mb={20} justify="flex-end" align="flex-start" direction="row">
        <Button
          variant="light"
          leftIcon={<ArrowWhite />}
          disabled={!user.roles.includes('ADMIN')}
          onClick={() => {
            navigate('recipient');
          }}
          data-testid={'send-payment'}
        >
          Send a payment
        </Button>
      </Flex>
      <PaymentsTable
        recipientName={searchParams.get('recipient') || undefined}
      />
    </>
  );
};

export default OutgoingPayments;
