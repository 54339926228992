import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';
import { validateState } from 'utilities/validators/validate-state';
import { useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { formatZip } from 'utilities/formatters/format-address';
import { US_STATES } from 'states/business/constants';
import { useProductOnboarding } from '../../onboarding-hooks';
import { useOnboardingStyles } from '../../onboarding.styles';
import OnboardingStep from '../../components/onboarding-step';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
import FlexbaseInput from 'components/input/flexbase-input';
import FlexbaseSelect from 'components/select/flexbase-select';
import IAddress from 'states/user/address';
import validatePostalCode from 'utilities/validators/validate-postal-code';
import { AddressFormValues } from './address-form-types';
import TagManager from 'react-gtm-module';
import GooglePlacesSuggest from 'components/input/google-places-suggest-input';

const AddressVerification = () => {
  const { classes: onboardingClasses } = useOnboardingStyles();
  const { navigateToNextProductStep, goBack } = useProductOnboarding();
  const { user } = useRecoilValue(ApplicationState);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addressForm = useForm<AddressFormValues>({
    initialValues: {
      line1: user.address?.line1 || '',
      line2: user.address?.line2 || '',
      state: getInitialState(user.address),
      postalCode: user.address?.postalCode || '',
      city: user.address?.city || '',
      country: user.address?.country || '',
    },
    validate: {
      line1: (value) =>
        validateRequired(value) ? null : 'Street address is required',
      state: (value) => (validateState(value) ? null : 'State is required'),
      postalCode: (value) =>
        validatePostalCode(value)
          ? null
          : 'A valid postal code is required (ex 30044 or 30044-4576)',
      city: (value) => (validateRequired(value) ? null : 'City is required'),
    },
  });

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    addressForm.setFieldValue('line1', item.address.trim());
    addressForm.setFieldValue('city', item.city);
    addressForm.setFieldValue('state', item.state);
    addressForm.setFieldValue('postalCode', item.postalCode);
  };

  const setZip = (zip: string) => {
    addressForm.setFieldValue('postalCode', formatZip(zip));
  };

  const saveData = async (formValues: IAddress) => {
    setLoading(true);
    try {
      await flexbaseOnboardingClient.updateUser({
        address: {
          ...formValues,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'verifiedAddress',
        },
      });
      await navigateToNextProductStep();
    } catch (e) {
      setErrorMessage('Could not update address');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    setErrorMessage('');
    const validationResult = addressForm.validate();
    if (!validationResult.hasErrors) {
      const formValues = addressForm.values;
      setLoading(true);
      try {
        await saveData(formValues);
      } catch (e) {
        setErrorMessage(typeof e === 'string' ? e : 'Could not update address');
      } finally {
        setLoading(false);
      }
    }
  };

  const onBack = () => {
    goBack();
  };

  return (
    <OnboardingStep
      title="Mailing address"
      subtitle="This is the address your card will be mailed to. Please enter your personal mailing address. (Cannot be a P.O. box)."
      onBackClick={onBack}
      onNextClick={onSubmit}
      stepId="Business-type"
      showContinueSpinner={loading}
      error={errorMessage}
    >
      <div className={onboardingClasses.containerSearchAddress}>
        <GooglePlacesSuggest
          label="Address line 1"
          {...addressForm.getInputProps('line1')}
          onChange={(value) => {
            addressForm.setFieldValue('line1', value);
          }}
          onItemSubmit={selectAddress}
          placeholder="Address line 1"
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
            container: onboardingClasses.btnSpacingAddressPage,
          }}
          id="input-address-street"
        />
        <FlexbaseInput
          label="Address line 2"
          placeholder="Address line 2"
          {...addressForm.getInputProps('line2')}
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          // className={onboardingClasses.btnSpacingAddressPage}
          id="input-address-line-2"
        />
      </div>
      <div className={onboardingClasses.containerSearchAddress}>
        <FlexbaseInput
          label="City"
          placeholder="City"
          {...addressForm.getInputProps('city')}
          classNames={{
            label: onboardingClasses.inputLabel,
            root: onboardingClasses.btnSpacingAddressPage,
          }}
          id="input-address-city"
        />

        <div className={onboardingClasses.stateZipContainer}>
          <FlexbaseSelect
            {...addressForm.getInputProps('state')}
            label="State"
            placeholder="State"
            data={(US_STATES || []).map(
              (s: { label: string; value: string }) => ({
                label: s.value,
                value: s.value,
                name: s.label,
              }),
            )}
            searchable
            dropdownPosition="bottom"
            maxDropdownHeight={400}
            nothingFound="No data"
            filter={(value, item) => {
              const lowerCaseValue = value.toLowerCase();
              return (
                item.label?.toLowerCase().includes(lowerCaseValue) ||
                item.value.toLowerCase().includes(lowerCaseValue) ||
                item.name?.toLowerCase().includes(lowerCaseValue)
              );
            }}
            classNames={{
              label: onboardingClasses.inputLabel,
              root: onboardingClasses.btnSpacingAddressPage,
            }}
            id="input-address-select-state"
          />
          <FlexbaseInput
            label="ZIP Code"
            placeholder="ZIP"
            pattern={'[0-9]*'}
            {...addressForm.getInputProps('postalCode')}
            classNames={{
              label: onboardingClasses.inputLabel,
              root: onboardingClasses.btnSpacingAddressPage,
            }}
            onChange={(e) => setZip(e.target.value)}
            id="input-address-zip-code"
          />
        </div>
      </div>
    </OnboardingStep>
  );
};

// If they only have a state and nothing else, then this was a value set by the API for the "state of operation"
// and should not be used as an initial value. Or at least that's what I tell myself as I make this change
// to get a test to pass.
const getInitialState = (userAddress?: IAddress): string => {
  if (!userAddress) {
    return '';
  } else if (
    userAddress.line1 &&
    userAddress.city &&
    userAddress.postalCode &&
    userAddress.state
  ) {
    return userAddress.state;
  }

  return '';
};

export default AddressVerification;
