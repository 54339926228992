import { Button, createStyles, rem, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';

interface Props {
  closeModal: () => void;
  error?: string;
  merchantCallbackUrl?: string;
}

const useStyles = createStyles((theme) => ({
  mainError: {
    '@media(max-width: 767px)': {
      width: '100%',
    },
    width: '50vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: rem(32),
  },
  error: {
    display: 'grid',
    justifyItems: 'center',
    backgroundColor: '#ffffff',
  },
  errorImg: {
    alignSelf: 'center',
    width: '12rem',
    height: '13rem',
  },
  errorHeader: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 700,
    fontStyle: `normal`,
    lineHeight: `23px`,
    marginBottom: '16px',
    color: theme.fn.primaryColor(),
  },
  errorMsg: {
    marginTop: '2.5%',
    textAlign: 'center',
    alignSelf: 'center',
    fontStyle: `normal`,
    color: '#6D6D6D',
    fontSize: '17px',
    lineHeight: '23px',
    fontWeight: 400,
    width: '55%',
  },
  actionButton: {
    '@media(max-width: 767px)': {
      width: '80%',
      height: '110%',
    },
    marginTop: '2.5%',
    width: '359px',
    height: '50px',
    background: `linear-gradient(124.59deg, ${theme.fn.primaryColor()} 29.59%, #FF8B7E 100.08%)`,
    borderRadius: '8px',
    color: '#FFFFFF',
  },
}));

const BnplError = ({
  error = `We've encountered an error attempting to process this transaction. We will be redirecting you back to the merchant shortly`,
  closeModal,
  merchantCallbackUrl,
}: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <div>
      <FlexbaseHeader />
      <Stack align="center">
        <div className={classes.mainError}>
          <div className={classes.error}>
            <Text className={classes.errorHeader}>{`We're sorry!`}</Text>
            <img
              src="/images/roadblockSign.png"
              alt="red-flag"
              className={classes.errorImg}
            />
            <p className={classes.errorMsg}>{error}</p>
            {merchantCallbackUrl ? (
              <Button
                variant="light"
                className={classes.actionButton}
                onClick={() => window.location.replace(merchantCallbackUrl!)}
              >
                Back to the store
              </Button>
            ) : (
              <Button
                variant="light"
                className={classes.actionButton}
                onClick={() => {
                  closeModal();
                  navigate('/overview');
                }}
              >
                Back
              </Button>
            )}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default BnplError;
