import { useEffect, useState } from 'react';
import { Alert, Button, Container, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaExclamationCircle } from 'react-icons/fa';
import { useChangePasswordStyles } from './change-password.styles';
import flexbaseClient from 'services/flexbase-client';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';

interface IChangePassword {
  closeModal: () => void;
}

interface IChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
);

const ChangePassword = ({ closeModal }: IChangePassword) => {
  const { classes } = useChangePasswordStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const user = useRecoilValue(UserInfoState);

  const theForm = useForm<IChangePasswordForm>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      newPassword: (val) =>
        passwordRegex.test(val)
          ? null
          : 'Password must be at least 8 characters, contain 1 lowercase letter, 1 uppercase letter, a number and a symbol',
      confirmPassword: (val, values) =>
        val === values?.newPassword ? null : 'Passwords do not match',
    },
  });

  const changePassword = async () => {
    try {
      setIsLoading(true);
      const validationResult = theForm.validate();
      if (!validationResult.hasErrors) {
        if (user?.email) {
          const validateCurrentPass = await flexbaseClient.validatePassword(
            user.email,
            theForm.values.oldPassword,
          );

          if (!validateCurrentPass.token) {
            throw Error(
              JSON.stringify({
                errorMessage: 'The current password is not correct',
              }),
            );
          }
          await flexbaseClient.changePassword(theForm.values.newPassword);
          closeModal();
        }
      }
    } catch (error) {
      console.error('Unable to change password', error);
      setErrMessage(JSON.parse(error.message).errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrMessage('');
    }, 5000);
  }, [errMessage]);

  return (
    <form id="changePassword" onSubmit={theForm.onSubmit(changePassword)}>
      <Container p="3rem" style={{ position: 'relative' }}>
        <div>
          <div style={{ fontSize: '2rem', fontWeight: 400 }}>
            Change Password
          </div>
        </div>
        <PasswordInput
          label="Old password"
          required
          {...theForm.getInputProps('oldPassword')}
        />

        <PasswordInput
          mt="1rem"
          label="New password"
          required
          {...theForm.getInputProps('newPassword')}
        />

        <PasswordInput
          mt="1rem"
          label="Confirm password"
          required
          {...theForm.getInputProps('confirmPassword')}
        />

        {errMessage !== '' ? (
          <Alert
            mt="1rem"
            title="Error"
            icon={<FaExclamationCircle />}
            color="red"
          >
            {errMessage}
          </Alert>
        ) : null}

        <div className={classes.buttonHolder}>
          <Button
            form="changePassword"
            aria-label="btnChangePassword"
            type="submit"
            color="flexbase-teal"
            size="lg"
            fullWidth
            loading={isLoading}
            disabled={
              !theForm.values.oldPassword || !theForm.values.newPassword
            }
          >
            Change password
          </Button>
          <Button
            className={classes.secondaryAction}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        </div>
      </Container>
    </form>
  );
};

export default ChangePassword;
