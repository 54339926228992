import { StyleSheet } from '@react-pdf/renderer';

export interface Colors {
  gray?: string;
  primaryDark?: string;
  secondaryDark?: string;
  tertiary?: string;
  neutral?: string;
  textColor?: string;
  borderRadius?: number;
}

export const defaultStylesPDF = ({
  primaryDark,
  neutral,
  borderRadius,
  textColor,
}: Colors) => {
  return StyleSheet.create({
    containerHeader: {
      padding: '0px 25px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },

    textHeader: {
      fontSize: 10,
      alignSelf: 'flex-end',
      fontFamily: 'PP Neue Montreal',
    },

    title: {
      fontSize: 36,
      color: primaryDark,
      fontFamily: 'Redaction',
      margin: '10px 0px 20px 0px',
    },

    footerContainer: {
      bottom: 0,
      fontSize: 10,
      width: '100%',
      height: '7vh',
      lineHeight: '1.5',
      color: textColor,
      padding: '15px 20px',
      position: 'absolute',
      backgroundColor: neutral,
      fontFamily: 'PP Neue Montreal',
    },

    card: {
      padding: 20,
      borderRadius,
      border: `1px solid ${neutral}`,
    },
  });
};
