import { createStyles } from '@mantine/core';

interface Props {
  hasDelinquentAmount?: boolean;
  hasOnClick: boolean;
}

export const useStyles = createStyles(
  (theme, { hasDelinquentAmount, hasOnClick }: Props) => ({
    baseContainer: {
      display: 'flex',
      flexDirection: 'row',
      '@media(max-width: 767px)': {
        flexDirection: 'column',
      },
      width: '100%',
    },
    frozen: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      borderRadius: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(48, 44, 255, 0.03)',
      '@media(max-width: 767px)': {
        width: '100%',
      },
    },
    unfreezeButton: {
      textAlign: 'center',
      alignItems: 'center',
      color: 'white',
      width: '242px',
      lineHeight: '28px',
      borderRadius: '100px',
      backgroundColor: theme.colors['flexbase-cosmic'],
      fontSize: 14,
    },
    payNow: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: 'white',
      background: 'transparent',
      border: 0,
    },
    creditContainer: {
      position: 'relative',
      borderRadius: theme.defaultRadius,
      border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.fn.themeColor('neutral', 0),
      height: '200px',
      minWidth: '420px',
      '@media(max-width: 767px)': {
        width: '100%',
        minWidth: 'unset',
      },
      ...(hasDelinquentAmount && {
        border: '1px solid rgba(48, 44, 255, 0.03)',
      }),
      ...(hasOnClick && { cursor: 'pointer' }),
    },
    availableCreditLabel: {
      fontSize: '16px',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '19px',
      color: '#5F5F5F',
    },
    availableCreditAmt: {
      marginTop: '8px',
      fontSize: '26px',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '31px',
      color: 'black',
      fontFamily: 'PP Neue Montreal',
    },
    totalBalanceContainer: {
      marginTop: '5rem',
      borderColor: '#c8d6e5',
      width: '100%',
      margin: '1.5rem',
      opacity: hasDelinquentAmount ? '0.3' : '',
    },
    statusBar: {
      borderRadius: '0.5rem',
      backgroundColor: theme.fn.themeColor('neutral', 0),
    },
    statusBarTextContainer: {
      marginTop: '17px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    statusBarText: {
      fontSize: '14px',
      lineHeight: '17px',
      marginBottom: '0.5rem',
      color: '#5F5F5F',
    },
  }),
);
