import { useEffect, useState } from 'react';
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Image,
  Progress,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useStyles } from '../styles';
import { Card } from 'states/cards/card-info';
import flexbaseClient from 'services/flexbase-client';
import useModal from 'components/modal/modal-hook';
import IssueCard from '../issue-card';
import { FilterIcon, PlusSignIcon, SearchIcon } from 'assets/svg';
import FlexbaseTable from 'components/table/flexbase-table';
import { getInitialsOfNames } from 'utilities/extensions/object';
import { formatCurrency } from 'utilities/formatters/format-currency';
import CardDetails from './card-details/card-details';
import { IoMdInfinite } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa';
import SkeletonLoading from 'components/loading/skeleton-loading';
import FilterStatus from './filter-cards/filter-cards';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormCardState } from 'states/cards/card-state';
import { UserIdState } from 'areas/onboarding/onboarding-form.state';
import { UserInfoState } from 'states/user/user-info';

const CardsTable = () => {
  const theme = useMantineTheme();
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const user = useRecoilValue(UserInfoState);

  const userId = useRecoilValue(UserIdState);
  const [loading, setLoading] = useState(false);
  const [searchCard, setSearchCard] = useState('');
  const [companyCards, setCompanyCards] = useState<Card[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { classes } = useStyles();
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('active/pending');
  const [openFilter, setOpenFilter] = useState(false);
  const setCardForm = useSetRecoilState(FormCardState);

  const cardType: any = {
    PHYSICAL: 'Physical',
    VIRTUAL: 'Virtual',
  };

  const onCloseModal = () => {
    closeModal();
    setCardForm({
      userId: '',
      creditLimit: null,
      cardType: 'physical',
      notifyUse: false,
      cardName: '',
      expensesTypes: {
        amount: 0,
        groups: [],
        interval: 'monthly',
      },
    });
  };

  const addCompanyCard = (card: Card) => {
    setCompanyCards((prevCompanyCards) => {
      const amountSpentThisMonth = card.monthToDateSpends?.mtdSpend ?? 0;
      const utilization = card.monthToDateSpends?.amount
        ? Math.round(
            (amountSpentThisMonth / card.monthToDateSpends?.amount) * 100,
          )
        : 0;

      return [
        ...prevCompanyCards,
        { ...card, utilization, amountSpentThisMonth },
      ];
    });
    onRowClicked(card);
  };

  const updateCompanyCards = (card: Card) => {
    setCompanyCards((prevCompanyCards) =>
      prevCompanyCards.map((currentCard) => {
        if (card?.id === currentCard.id) {
          const amountSpentThisMonth = card.monthToDateSpends?.mtdSpend ?? 0;

          return {
            amountSpentThisMonth,
            ...card,
          };
        }
        return currentCard;
      }),
    );
  };

  const getCompanyCards = async () => {
    try {
      if (!searchCard && status === '') {
        setLoading(true);
      }
      const { success, cards, error } = await flexbaseClient.getCardsByCompany({
        full: true,
        searchTerm: searchCard,
        status: status === 'active/pending' ? '' : status,
      });

      if (success) {
        const cardsData = cards
          .filter((cc: any) => {
            if (status === 'active/pending') {
              return (
                !cc.ucCardId &&
                cc.status !== 'terminated' &&
                cc.status !== 'suspended'
              );
            } else {
              return !cc.ucCardId;
            }
          })
          .map((cc: any) => {
            const amountSpentThisMonth = cc.monthToDateSpends?.mtdSpend ?? 0;
            const utilization = cc?.expensesTypes?.amount
              ? Math.round(
                  (amountSpentThisMonth / cc.expensesTypes.amount) * 100,
                )
              : 0;

            return { ...cc, amountSpentThisMonth, utilization };
          });
        setCompanyCards(cardsData);
      } else {
        if (error) {
          setErrorMessage(error as string);
        } else {
          setErrorMessage(
            'We are unable to retrieve the company cards at the moment.',
          );
        }
      }
    } catch (error) {
      setErrorMessage(
        'We are unable to retrieve the company cards at the moment.',
      );
    } finally {
      setLoading(false);
    }
  };

  const onRowClicked = (card: Card) => {
    screen.width <= 767
      ? modal.openSizedCenterModal(
          <CardDetails {...{ card, updateCompanyCards, modal }} />,
          {
            margin: '0px',
            closeOnClickOutside: true,
            closeModal: () => {
              onCloseModal();
            },
          },
        )
      : modal.openRightModalNoOpacity(
          <CardDetails {...{ card, updateCompanyCards, modal }} />,
          {
            closeModal: () => {
              onCloseModal();
            },
          },
        );
  };

  const cardStatus: any = {
    active: 'Active',
    suspended: 'Frozen',
    issued: 'Pending',
    requested: 'Pending',
    terminated: 'Canceled',
  };

  const badgeColor: any = {
    issued: '#fff',
    requested: '#fff',
    active: '#EEEEF3',
    terminated: '#EEEEF3',
    suspended: 'rgba(48, 44, 255, 0.1)',
  };

  const columns = [
    {
      name: 'hidden_date',
      selector: (row: any) => row.asOf,
      omit: true,
    },
    {
      name: 'Cardholder',
      cell: (row: { holder: any; userId: any; status: any }) => {
        return (
          <>
            {screen.width > 767 && (
              <Avatar radius="34px" className={classes.cardAvatar}>
                {getInitialsOfNames(row.holder)}
              </Avatar>
            )}
            <div className={classes.holderField}>{row.holder}</div>

            <Badge
              className={classes.badge}
              styles={{
                root: {
                  border:
                    row.status === 'issued' || row.status === 'requested'
                      ? '1px solid #EEEEF3'
                      : 'unset',
                  backgroundColor: badgeColor[row.status],
                },
                inner: { color: '#000 !important' },
              }}
            >
              {userId === row.userId && row.status === 'active'
                ? 'You'
                : cardStatus[row.status]}
            </Badge>
          </>
        );
      },
      selector: (row: { holder: any }) => row.holder,
      sortable: true,
      width: '350px',
    },
    {
      name: 'Card Name',
      selector: (row: { cardName: any }) => row.cardName,
      sortable: true,
    },
    {
      name: 'Credit Card',
      cell: (row: { cardNumber: any; status: any; cardType: any }) => {
        return (
          <>
            <div style={{ color: '#5F5F5F' }}>
              <strong>•••••</strong>
              {row?.cardNumber &&
              row.cardType === 'PHYSICAL' &&
              (row.status === 'issued' || row.status === 'requested')
                ? ''
                : row?.cardNumber.substring(row?.cardNumber.length - 4)}
            </div>
          </>
        );
      },
      selector: (row: { cardNumber: any }) => row.cardNumber,
      sortable: true,
    },
    {
      name: 'Spent This Month',
      cell: (row: { amountSpentThisMonth: any; id: string }) => (
        <div style={{ display: 'flex' }}>
          {row.amountSpentThisMonth
            ? formatCurrency(row.amountSpentThisMonth)
            : '$0.00'}
        </div>
      ),
      selector: (row: { amountSpentThisMonth: any }) =>
        row.amountSpentThisMonth,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row: { cardType: any }) => cardType[row.cardType],
      sortable: true,
    },
    {
      name: 'Card Utilization',
      cell: (card: any) => {
        let utilization;
        if (card?.expensesTypes?.amount !== 0) {
          utilization = (
            <div
              style={{ display: 'flex', width: '100%', alignItems: 'center' }}
            >
              <Progress
                style={{ width: '75%' }}
                value={card?.utilization || 0}
                color={theme.fn.primaryColor()}
              />{' '}
              <div className={classes.utilizationField}>
                {card?.utilization || 0}%
              </div>
            </div>
          );
        } else {
          utilization = (
            <div>
              <IoMdInfinite size={30} />
            </div>
          );
        }
        return utilization;
      },
      sortable: true,
      selector: (row: { utilization: any }) => row.utilization,
    },
  ];

  useEffect(() => {
    getCompanyCards();
  }, [searchCard, status]);

  useEffect(() => {
    if (message !== '') {
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
  }, [message]);

  // const theme = useMantineTheme();

  if (loading) {
    return (
      <div style={{ marginTop: '50px' }}>
        <SkeletonLoading />
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: '1307.5px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <div className={classes.cardsContainer} data-testid="cards-table">
        {message !== '' ? (
          <div className={classes.containerMessage}>
            <Alert icon={<FaCheck />} color={'green'}>
              <Text color="black">{message}</Text>
            </Alert>
          </div>
        ) : null}

        {errorMessage && errorMessage !== '' ? (
          <div className={classes.emptyState}>
            <div>
              <Image
                src="/images/noTransactions.svg"
                className="image-container"
                alt="No cards found"
                height={360}
                width={360}
              />
              <span>Something went wrong</span>
              <p data-testid="error-message">{errorMessage}</p>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.containerTable}>
              <div className={classes.containerSearch}>
                <Text className={classes.tableTitle}>Credit Cards</Text>
                <div className={classes.searchInput}>
                  <Button
                    mr={24}
                    variant="outline"
                    disabled={!user.roles.includes('ADMIN')}
                    onClick={() =>
                      modal.openFullModal(
                        <IssueCard
                          {...{
                            closeModal,
                            updateCompanyCards,
                            addCompanyCard,
                          }}
                        />,
                      )
                    }
                  >
                    <PlusSignIcon
                      width={12}
                      height={12}
                      style={{
                        fill: theme.fn.primaryColor(),
                      }}
                    />{' '}
                    <div style={{ marginLeft: '0.5vh', fontWeight: 500 }}>
                      New credit card
                    </div>
                  </Button>
                  {openFilter ? (
                    <FilterStatus
                      search={status}
                      isFilter={openFilter}
                      searchCallback={setStatus}
                      filterCallback={setOpenFilter}
                    />
                  ) : (
                    <TextInput
                      // styles={{
                      //   input: CustomMantineStyles(theme.fn.primaryColor())
                      //     .searchTransactions.input,
                      // }}
                      icon={<SearchIcon width={20} height={20} />}
                      value={searchCard}
                      onChange={(e) => setSearchCard(e.target.value)}
                      placeholder="Search credit cards"
                      type="search"
                      rightSection={
                        screen.width > 767 && (
                          <div
                            onClick={() => setOpenFilter(true)}
                            style={{ cursor: 'pointer' }}
                          >
                            <FilterIcon
                              style={{ paddingRight: '8px', marginTop: 8 }}
                              fill={'#979797'}
                              width={'25px'}
                              height={'25px'}
                              color="#5f5f5f"
                            />
                          </div>
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <FlexbaseTable
                data={companyCards}
                columns={columns}
                onRowClicked={onRowClicked}
                defaultSortAsc={false}
                defaultSortFieldId="1"
                isFetchingData={loading}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CardsTable;
