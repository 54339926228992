import CreditPaymentCard from '../../components/credit-payment/credit-payment';
import AutoPay from '../../components/auto-pay-settings/auto-pay';
import PaymentsTable from '../../components/payments-table/payments-table';
import { useStyles } from './credit-payment.styles';
import CreditPaymentScheduleChart from './credit-payment-schedule.chart';

const CreditPaymentTab = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.scheduleAndWidgetContainer}>
        <div className={classes.widgetContainer}>
          <CreditPaymentCard credit={true} />
          <AutoPay />
        </div>
        <div
          className={classes.scheduleContainer}
          style={{ overflow: 'hidden' }}
        >
          <CreditPaymentScheduleChart />
        </div>
      </div>
      <div>
        <PaymentsTable />
      </div>
    </div>
  );
};

export default CreditPaymentTab;
