import { Document, Page, View, Text, PDFViewer } from '@react-pdf/renderer';
import Header from './header';
import { stylesStatementPDF } from './styles';
import { useMantineTheme } from '@mantine/core';

const PaymentRequestPDF = () => {
  const theme = useMantineTheme();
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page wrap size="A4">
          <View fixed style={stylesStatementPDF.topColors}>
            <View style={stylesStatementPDF.topRed}></View>
            <View style={stylesStatementPDF.topRose}></View>
            <View style={stylesStatementPDF.topBlue}></View>
          </View>
          <View style={stylesStatementPDF.containerPage}>
            <Header />
            <View
              style={{
                ...stylesStatementPDF.cardContainer,
                marginTop: 0,
              }}
            >
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: 'black',
                  }}
                >
                  Payment Request
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    color: 'black',
                    marginTop: 30,
                  }}
                >
                  Flexbase
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    marginTop: 5,
                  }}
                >
                  c/o Jane Black
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    marginTop: 5,
                    textDecoration: 'underline',
                  }}
                >
                  login@flexbase.com
                </Text>
              </View>

              <View>
                <View>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      fontSize: 12,
                      color: theme.fn.primaryColor(),
                      marginTop: 53,
                    }}
                  >
                    Request Details
                  </Text>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      marginTop: 20,
                    }}
                  >
                    Amount
                  </Text>
                </View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    marginTop: 20,
                  }}
                >
                  Requestee
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    marginTop: 20,
                  }}
                >
                  Memo
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    marginTop: 30,
                    color: 'black',
                  }}
                >
                  Due Date
                </Text>
              </View>

              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    color: 'black',
                    marginTop: 95,
                  }}
                >
                  $99,999.00
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    color: 'black',
                    marginTop: 15,
                  }}
                >
                  Davey Harris
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    color: 'black',
                  }}
                >
                  bananachamp@collins.net
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    color: 'black',
                    width: 180,
                    marginTop: 13,
                  }}
                >
                  These are payment request notes. Each 9 in the amount = one
                  banana.
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    color: 'black',
                    marginTop: 12,
                  }}
                >
                  December 2, 22
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...stylesStatementPDF.containerPage, marginTop: 0 }}>
            <Header />
            <View
              style={{ ...stylesStatementPDF.cardContainer, marginTop: 60 }}
            >
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: 'black',
                  }}
                >
                  Flexbase Technologies Inc.
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: 'black',
                    marginTop: 3,
                  }}
                >
                  Domestic Transfer Details
                </Text>
              </View>
              <View style={{ marginRight: 50 }}>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                  }}
                >
                  Use these details to send both domestic wires and ACH
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 4,
                  }}
                >
                  transfers to Flexbase Technologies Inc.&nbsp;s Flexbase
                  account.
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: theme.fn.primaryColor(),
                    marginTop: 30,
                  }}
                >
                  Beneficiary
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      marginTop: 4,
                    }}
                  >
                    Beneficiary Name
                  </Text>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                      marginTop: 4,
                      marginLeft: 80,
                    }}
                  >
                    Flexbase Technologies Inc
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      marginTop: 4,
                    }}
                  >
                    Account Number
                  </Text>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                      marginTop: 4,
                      marginLeft: 85,
                    }}
                  >
                    999504395940
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      marginTop: 4,
                    }}
                  >
                    Type of Account
                  </Text>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                      marginTop: 4,
                      marginLeft: 90,
                    }}
                  >
                    Checking
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      marginTop: 4,
                    }}
                  >
                    Beneficiary Address
                  </Text>
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      color: 'black',
                      marginTop: 4,
                      marginLeft: 70,
                      width: 180,
                    }}
                  >
                    81 Langton, Unit 4 San Francisco, CA 94103
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      ...stylesStatementPDF.text,
                      fontSize: 12,
                      color: theme.fn.primaryColor(),
                      marginTop: 4,
                    }}
                  >
                    Receiving Bank Details
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        marginTop: 4,
                      }}
                    >
                      ABA Routing Number
                    </Text>
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        color: 'black',
                        marginTop: 4,
                        marginLeft: 60,
                      }}
                    >
                      091311229
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        marginTop: 4,
                      }}
                    >
                      Bank Name
                    </Text>
                    <View>
                      <Text
                        style={{
                          ...stylesStatementPDF.text,
                          color: 'black',
                          marginTop: 4,
                          marginLeft: 102,
                        }}
                      >
                        Choice Financial Group
                      </Text>
                      <Text
                        style={{
                          ...stylesStatementPDF.text,
                          marginTop: 4,
                          marginLeft: 102,
                          width: 180,
                          fontSize: 9,
                        }}
                      >
                        Mercury uses Choice Financial Group as a banking
                        partner.
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        marginTop: 4,
                      }}
                    >
                      Bank Address
                    </Text>
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        color: 'black',
                        marginTop: 4,
                        marginLeft: 90,
                        width: 100,
                      }}
                    >
                      4501 23rd Avenue S Fargo, ND 58104
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              ...stylesStatementPDF.containerPage,
              marginTop: 0,
              height: '90vh',
            }}
          >
            <Header />
            <View
              style={{ ...stylesStatementPDF.cardContainer, marginTop: 60 }}
            >
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: 'black',
                  }}
                >
                  Flexbase Technologies Inc.
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: 'black',
                    marginTop: 3,
                  }}
                >
                  International Wire Details
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: '#58574e',
                    marginRight: 70,
                  }}
                >
                  If you are filling out a wire form, please reference the
                  section
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: '#58574e',
                    marginTop: 4,
                    marginRight: 60,
                  }}
                >
                  labels with MT103 field numbers in grey.
                </Text>
              </View>
            </View>
            <View style={{ border: '1px solid #E6E7E9', marginTop: 20 }} />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: theme.fn.primaryColor(),
                    marginTop: 30,
                  }}
                >
                  Receiving Bank
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={stylesStatementPDF.text}>
                    57D Account with institution
                  </Text>
                </View>
              </View>
              <View style={{ marginRight: 30 }}>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 30,
                  }}
                >
                  SWIFT / BIC Code
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 10,
                  }}
                >
                  ABA Routing number
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 10,
                  }}
                >
                  Bank Name
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 10,
                  }}
                >
                  Bank Address
                </Text>
              </View>
              <View style={{ marginRight: 70 }}>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 30,
                  }}
                >
                  CHFGUS44021
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 10,
                  }}
                >
                  09184575849
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 10,
                  }}
                >
                  Choice Financial Group
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 10,
                    width: 110,
                  }}
                >
                  4501 23rd Avenue S Fargo, ND 58104 USA
                </Text>
              </View>
            </View>

            <View style={{ border: '1px solid #E6E7E9', marginTop: 20 }} />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 12,
                    color: theme.fn.primaryColor(),
                    marginTop: 30,
                  }}
                >
                  Beneficiary
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                    justifyContent: 'space-between',
                  }}
                >
                  <View>
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        marginTop: 4,
                      }}
                    >
                      59 Beneficiary customer
                    </Text>
                    <Text
                      style={{
                        ...stylesStatementPDF.text,
                        marginTop: 4,
                      }}
                    >
                      name & address
                    </Text>
                  </View>
                </View>
              </View>
              <View>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 30,
                  }}
                >
                  IBAN / Account Number
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 10,
                  }}
                >
                  Beneficiary Name
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    marginTop: 10,
                  }}
                >
                  Beneficiary Address
                </Text>
              </View>
              <View style={{ marginRight: 60 }}>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 30,
                  }}
                >
                  94304392430
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 10,
                  }}
                >
                  Flexbase Technologies Inc
                </Text>
                <Text
                  style={{
                    ...stylesStatementPDF.text,
                    fontSize: 11,
                    color: 'black',
                    marginTop: 10,
                    width: 110,
                  }}
                >
                  81 Langton, Unit 4 San Francisco, CA 94103 USA
                </Text>
              </View>
            </View>
          </View>

          <View style={stylesStatementPDF.footerContainer} fixed>
            <View>
              <Text>Flexbase Technologies Inc</Text>
              <Text> 2261 Market Street #4552 San Francisco, CA, 94114</Text>
            </View>
            <View>
              <Text
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
              />
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PaymentRequestPDF;
