import NavbarWeb from './navbar';
import NavbarMobile from './navbar-mobile';
import { Outlet } from 'react-router-dom';
import {
  AppShell,
  Burger,
  Center,
  Header,
  MediaQuery,
  Navbar,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import useStyles from './navbar.styles';
import UserActionButtons from 'components/header/user-action-buttons/user-action-buttons';
import { FlexIcon } from '../../assets/svg';

const NavbarAppShell = () => {
  const theme = useMantineTheme();

  const { classes } = useStyles({});
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [opened, setOpened] = useState(false);
  return (
    <>
      {useMobileView ? (
        <AppShell
          styles={{
            main: {
              background:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[8]
                  : theme.fn.themeColor('neutral', 2),
              paddingTop: '60px',
            },
          }}
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          navbar={
            <Navbar
              p="md"
              hiddenBreakpoint="sm"
              width={{ sm: 200, lg: 90 }}
              style={{
                padding: '0px',
                background: 'transparent',
                zIndex: 199,
                width: 'auto',
                marginTop: '-10px',
              }}
            >
              <NavbarMobile {...{ setOpened, opened }} />
            </Navbar>
          }
          header={
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Header
                height={70}
                style={{
                  position: 'fixed',
                  width: '100%',
                  height: '60px',
                  backgroundColor: theme.fn.themeColor('neutral', 0),
                  borderWidth: '0px',
                  zIndex: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '8px 16px',
                  }}
                >
                  <Burger
                    opened={opened}
                    onClick={() => setOpened((o) => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                  {opened ? (
                    <Text size="sm" className={classes.nameText}>
                      Flexbase Technologies
                    </Text>
                  ) : (
                    <Center className={classes.avatar}>
                      <FlexIcon />
                    </Center>
                  )}

                  <div
                    style={{
                      visibility: opened ? 'hidden' : 'visible',
                      marginTop: '3px',
                    }}
                  >
                    <UserActionButtons />
                  </div>
                </div>
              </Header>
            </MediaQuery>
          }
        >
          <Outlet />
        </AppShell>
      ) : (
        <NavbarWeb>
          <Outlet />
        </NavbarWeb>
      )}
    </>
  );
};

export default NavbarAppShell;
