import { Stack } from '@mantine/core';
import OnboardingStep from '../../components/onboarding-step';
import RadioCard from 'components/input/radio-card';
import { ReactNode, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ApplicationState,
  ProductState,
} from 'states/application/product-onboarding';
import { useProductOnboarding } from '../../onboarding-hooks';
import DebitCardImage from 'assets/images/debit-card.png';
import CreditCardImage from 'assets/images/credit-card.png';
import { OnboardingProduct } from 'states/application/product-onboarding.models';
import { Analytics } from 'services/analytics/analytics';

type ProductList = Array<{
  title: string;
  subtitle: string;
  description: string;
  product: OnboardingProduct;
  cardImage: ReactNode;
}>;

const MultiProduct = () => {
  const [product, setProduct] = useRecoilState(ProductState);
  const [error, setError] = useState('');
  const { navigateToNextProductStep } = useProductOnboarding();
  const [loading] = useState(true);
  const { user } = useRecoilValue(ApplicationState);

  useEffect(() => {
    // If they have a product already, navigate them to wherever they need to go.
    if (product) {
      navigateToNextProductStep();
    } else {
      setProduct('CREDIT');
      navigateToNextProductStep();
    }
  }, []);

  const products: ProductList = [
    {
      title: 'Credit',
      subtitle: '0% APR for 60 days. Does not impact credit score.',
      description: 'Apply for a credit card account',
      product: 'CREDIT',
      cardImage: (
        <img
          src={CreditCardImage}
          width={90}
          height={60}
          style={{ marginLeft: '5px', marginTop: '5px' }}
        />
      ),
    },
    {
      title: 'Debit',
      subtitle: 'Set me up with a bank account and debit card',
      description:
        'Detailed description of the product that expands slowly when you select this option and closes if I select a different one. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur volutpat ligula sit amet dolor egestas, faucibus ultrices eros efficitur.',
      product: 'BANKING',
      cardImage: (
        <img
          src={DebitCardImage}
          width={90}
          height={60}
          style={{ marginLeft: '5px', marginTop: '5px' }}
        />
      ),
    },
    {
      title: 'Credit & Debit',
      subtitle:
        'Set me up with bank account, a debit card, and apply for a credit card',
      description:
        'Detailed description of the product that expands slowly when you select this option and closes if I select a different one. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur volutpat ligula sit amet dolor egestas, faucibus ultrices eros efficitur.',
      product: 'ALL',
      cardImage: (
        <div style={{ position: 'relative' }}>
          <img
            src={CreditCardImage}
            width={90}
            height={60}
            style={{
              marginLeft: '-8px',
              marginTop: '-2px',
              position: 'absolute',
            }}
          />{' '}
          <img
            src={DebitCardImage}
            width={90}
            height={60}
            style={{ marginLeft: '7px', marginTop: '15px' }}
          />
        </div>
      ),
    },
  ];

  const onNextClick = async () => {
    if (!product) {
      setError('Select a product to continue');
      return;
    }

    await navigateToNextProductStep();
  };

  return (
    <>
      {!loading && (
        <OnboardingStep
          title="Select an option"
          subtitle="Choose what products you need"
          onNextClick={onNextClick}
          stepId="product-select"
          showBack={false}
          error={error}
        >
          <Stack>
            {products.map((p) => (
              <RadioCard
                onSelect={() => {
                  if (error) {
                    setError('');
                  }
                  setProduct(p.product);
                  if (user) {
                    Analytics.submitProductStart.set(user.id, p.product);
                  }
                }}
                isSelected={product === p.product}
                title={p.title}
                subtitle={p.subtitle}
                rightContent={p.cardImage}
                key={p.title}
                id={`radio-button-${p.product.toLowerCase()}`}
              />
            ))}
          </Stack>
        </OnboardingStep>
      )}
      {/*<div className={classes.footerContainer}>*/}
      {/*  <Text className={classes.footerText}>*/}
      {/*    To help the U.S. government fight the funding of terrorism and money*/}
      {/*    laundering activities, Federal law requires that xxxxxxx on behalf of*/}
      {/*    xxxxxxx , to obtain, verify, and record information that identifies*/}
      {/*    each individual or institution that opens an account or establishes a*/}
      {/*    customer relationship.*/}
      {/*  </Text>*/}
      {/*</div>*/}
    </>
  );
};

export default MultiProduct;
