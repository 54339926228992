import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '20px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: theme.fn.primaryColor(),
    fontFamily: 'PP Neue Montreal',
  },
  container: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: theme.defaultRadius,
    maxWidth: '1365px',
    margin: 'auto',
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: '#E6E7E9',
    borderBottomWidth: 0.5,
    paddingBottom: 16,
    paddingTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      gap: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  statusColumn: {
    textAlign: 'center',
    borderRadius: '100px',
    fontWeight: 500,
    color: 'black',
    padding: '1px',
    '@media(max-width: 767px)': {
      fontSize: '12px',
      margin: '10px 0px',
    },
    minWidth: '100px',
  },
  errorMessage: {
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    span: {
      display: 'flex',
      fontWeight: 600,
      marginTop: '32px',
      justifyContent: 'center',
    },

    p: {
      color: '#6F7181',
      fontSize: '14px',
      textAlign: 'center',
    },
  },

  emptyState: {
    height: '60vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '.image-container': {
      display: 'flex',
      marginTop: '50px',
      justifyContent: 'center',
    },

    p: {
      margin: 0,
      fontSize: '14px',
      textAlign: 'center',
      color: theme.fn.themeColor('neutral', 6),
    },
  },
}));
