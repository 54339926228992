import { useEffect } from 'react';

import AddFunds from './add-funds';
import useModal from 'components/modal/modal-hook';

const AddFundsPage = () => {
  const { openTransparentModal } = useModal();

  useEffect(() => {
    openTransparentModal(<AddFunds />);
  }, []);

  return <></>;
};

export default AddFundsPage;
