import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  baseContainer: {
    display: 'flex',
  },
  button: {
    margin: '5px',
    border: '1px solid #F2F3F7',
    width: '32px',
    height: '32px',
    borderRadius: '8px',
    background: '#FCFCFC',
    color: '#979797',
    padding: 0,
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
    '&:disabled': {
      background: '#F9FAFA !important',
      border: '1px solid #F0F0F0 !important',
      color: '#BCBCBC !important',
    },
  },
}));
