import React, { useEffect } from 'react';
import { BnplStep, useBnpl } from './bnpl-hook';
import BnplLoading from './bnpl-modals/bnpl-loading';
import BnplSuccess from './bnpl-modals/bnpl-success';
import BnplError from './bnpl-modals/bnpl-error';
import useModal from 'components/modal/modal-hook';
import BnplCongrats from './bnpl-modals/bnpl-congrats';
import BnplLineOfCredit from './bnpl-modals/bnpl-line-of-credit';
import BnplAutopay from './bnpl-modals/bnpl-autopay';
import BnplConfirm from './bnpl-modals/bnpl-confirm';
import BnplFailedLineOfCredit from './bnpl-modals/bnpl-failed-line-of-credit';
import { useSetRecoilState } from 'recoil';
import { PendingUrlState } from 'states/pending-url/pending-url-state';
import { TransactionStatus } from 'states/bnpl/bnpl.state';
import { merchantCallback } from 'utilities/url/get-bnpl-merchant-callback';

const Bnpl = React.memo(function Bnpl() {
  const [
    {
      merchant,
      totalLoc,
      lastFourBankAccountDigits,
      callback,
      amount,
      session,
      isLoading,
      error,
      noCredit,
      success,
      bnplStep,
    },
    { buyNow },
    { setError },
    { setBnplStep },
  ] = useBnpl();
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const setPendingUrl = useSetRecoilState(PendingUrlState);

  useEffect(() => {
    // We need to clear the pending URL since it is now stored in local storage
    setPendingUrl('');
    if (isLoading) {
      modal.openCenterModal(<BnplLoading />);
    } else if (error) {
      modal.openCenterModal(
        <BnplError
          {...{
            closeModal,
            // Hide this error for now and use the default error message for the initial launch
            error,
            merchantCallbackUrl: merchantCallback(
              merchant?.baseUrl || '',
              TransactionStatus.declined,
              callback!,
              session!,
            ),
          }}
        />,
      );
    } else if (noCredit) {
      modal.openCenterModal(
        <BnplFailedLineOfCredit
          closeModal={closeModal}
          merchantCallbackCancelUrl={merchantCallback(
            merchant?.baseUrl || '',
            TransactionStatus.declined,
            callback!,
            session!,
          )}
        />,
      );
    } else if (success) {
      modal.openCenterModal(<BnplSuccess />);
    } else {
      switch (bnplStep) {
        case BnplStep.Congrats:
          modal.openCenterModal(
            <BnplCongrats
              {...{
                closeModal,
                merchant: merchant!,
                amount,
                merchantCallbackCancelUrl: merchantCallback(
                  merchant?.baseUrl || '',
                  TransactionStatus.canceled,
                  callback!,
                  session!,
                ),
                onContinue: () => {
                  setBnplStep!(BnplStep.Loc);
                },
              }}
            />,
          );
          break;
        case BnplStep.Loc:
          modal.openCenterModal(
            <BnplLineOfCredit
              {...{
                closeModal,
                merchant: merchant!,
                totalLoc: totalLoc ?? 0,
                amount,
                merchantCallbackCancelUrl: merchantCallback(
                  merchant?.baseUrl || '',
                  TransactionStatus.canceled,
                  callback!,
                  session!,
                ),
                onContinue: () => {
                  setBnplStep!(BnplStep.Autopay);
                },
                onBack: () => {
                  setBnplStep!(BnplStep.Congrats);
                },
              }}
            />,
          );
          break;
        case BnplStep.Autopay:
          modal.openCenterModal(
            <BnplAutopay
              {...{
                closeModal,
                merchantCallbackUrl: merchantCallback(
                  merchant?.baseUrl || '',
                  TransactionStatus.declined,
                  callback!,
                  session!,
                ),
                onContinue: () => {
                  setBnplStep!(BnplStep.Confirm);
                },
                onBack: () => {
                  setBnplStep!(BnplStep.Loc);
                },
                setError: setError!,
                lastFourBankAccountDigits: lastFourBankAccountDigits!,
              }}
            />,
          );
          break;
        case BnplStep.Confirm:
          modal.openCenterModal(
            <BnplConfirm
              {...{
                closeModal,
                merchant: merchant!,
                amount,
                merchantCallbackCancelUrl: merchantCallback(
                  merchant?.baseUrl || '',
                  TransactionStatus.canceled,
                  callback!,
                  session!,
                ),
                onContinue: () => {
                  buyNow!((invoiceId) => {
                    setTimeout(() => {
                      window.location.replace(
                        merchantCallback(
                          merchant?.baseUrl || '',
                          TransactionStatus.settled,
                          callback!,
                          session!,
                          invoiceId,
                        )!,
                      );
                    }, 7000);
                  });
                },
                onBack: () => {
                  setBnplStep!(BnplStep.Autopay);
                },
              }}
            />,
          );
          break;
        default:
          modal.openCenterModal(<BnplLoading />);
      }
    }
  }, [merchant, lastFourBankAccountDigits, isLoading, error, bnplStep]);

  return <></>;
});

export default Bnpl;
