import CardForm from './card-form';
import { Card } from 'states/cards/card-info';
import FullScreenModalContainer from 'components/modal/full-screen-modal-container';
import { useStyles } from '../styles';

interface Props {
  card?: any;
  closeModal: () => void;
  updateCompanyCards: (card: Card) => void;
  addCompanyCard: (card: Card) => void;
}

const IssueCardHeader = ({
  card,
  closeModal,
  updateCompanyCards,
  addCompanyCard,
}: Props) => {
  const { classes } = useStyles();

  return (
    <FullScreenModalContainer closeModal={closeModal}>
      <div className={classes.issueCardContainer} data-testid="issue-card">
        <CardForm
          {...{ closeModal, card, updateCompanyCards, addCompanyCard }}
        />
      </div>
    </FullScreenModalContainer>
  );
};

export default IssueCardHeader;
