import { atom } from 'recoil';
import IAddress from './address';

export type UserRole =
  | 'ADMIN'
  | 'EMPLOYEE'
  | 'ACCOUNTANT'
  | 'NO-LOGINS'
  | 'PLAID-STALE'
  | 'SERVICER'
  | 'OWNER';

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  initials: string;
  phone: string;
  picUrl: string | null;
  jobTitle: string;
  address: IAddress;
  email: string;
  cellPhone: string;
  roles: UserRole[];
}

export const UserInfoState = atom<UserInfo>({
  key: 'user-info',
  default: {
    id: '',
    firstName: '',
    lastName: '',
    initials: '',
    phone: '',
    picUrl: null,
    address: {
      state: '',
      city: '',
      country: '',
      line2: '',
      postalCode: '',
      line1: '',
    },
    jobTitle: '',
    email: '',
    cellPhone: '',
    roles: [],
  },
});
