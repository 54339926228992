/**
 * Convert passed-in array of objects into parsable and writable string
 *
 * A bulk of this code was brought over from the example in
 * https://react-data-table-component.netlify.app/?path=/story/examples-export-csv--export-csv
 *
 * @param dataArray
 */
export const convertArrayOfObjectsToCSV = (dataArray: object[]) => {
  let result: string;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = dataArray[0] ? Object.keys(dataArray[0]) : [];

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  dataArray.forEach((item: any) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }

      let value = item[key];
      if (typeof value === 'string' && value.search(',') !== -1) {
        value = `"${value.replace('bnpl', 'Flexbase Pay')}"`;
      }

      result += value;

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

/**
 * Download/export table data to CSV
 *
 * A bulk of this code was brought over from the example in
 * https://react-data-table-component.netlify.app/?path=/story/examples-export-csv--export-csv
 *
 * @param data
 * @param fileName
 */
export const downloadCSV = (data: any, fileName: string) => {
  const link = document.createElement('a');
  const date = new Date().toDateString();
  const csvFileName = `${fileName}_${date}.csv`;
  const csv = convertArrayOfObjectsToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', csvFileName);
  link.click();
};
