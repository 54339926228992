import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import {
  Alert,
  Anchor,
  Button,
  Center,
  Checkbox,
  Container,
  Input,
  Loader,
} from '@mantine/core';
import { BuildingIcon } from 'assets/svg';
import { useProfileStyles } from './profile-tab.styles';
import { foregroundLight, highlighted } from 'utilities/theme/theme';
import flexbaseClient from 'services/flexbase-client';
import { formatUSPhoneNumber } from 'utilities/formatters/format-phone-number';
import { FaExclamationCircle } from 'react-icons/fa';
import useModal from 'components/modal/modal-hook';
import ChangePassword from './change-password/change-password';
import { useRecoilValue } from 'recoil';
import { UserIdState } from '../../onboarding/onboarding-form.state';

type HandleInputChange = ChangeEvent<HTMLInputElement>;
interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  cellPhone: string;
  authorizedSignatory: boolean;
}

interface Props {
  person: IUserInfo | any;
}

const ProfileTab = ({ person }: Props) => {
  const userId = useRecoilValue(UserIdState);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const uploadDocRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [profilePic, setProfilePic] = useState<string>('');
  const { classes } = useProfileStyles();

  const modal = useModal();
  const closeModal = modal.closeAllModals;

  const inputSize = screen.width <= 767 ? 'md' : 'xl';

  const openChangePassModal = () => {
    screen.width <= 767
      ? modal.openCenterModal(<ChangePassword {...{ closeModal }} />)
      : modal.openCenterModalUnstyled(<ChangePassword {...{ closeModal }} />);
  };

  const theForm = useForm({
    initialValues: {
      firstName: person.firstName || '',
      lastName: person.lastName || '',
      jobTitle: person.jobTitle || '',
      email: person.email || '',
      cellPhone: person.cellPhone || '',
      authorizedSignatory: person.authorizedSignatory || false,
    },
  });

  const updateUser = async (values: IUserInfo) => {
    try {
      setErrorMsg('');
      setIsLoading(true);

      if (userId) {
        const response = await flexbaseClient.updatePerson(userId, {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
          email: values.email,
          authorizedSignatory: values.authorizedSignatory,
          phone: { number: values.cellPhone },
        });

        if (!response) {
          return setErrorMsg('Unable to update profile');
        }
      }
    } catch {
      setErrorMsg('Unable to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const uploadPicture = async ({ target: { files } }: HandleInputChange) => {
    try {
      if (files) {
        setErrorMsg('');
        setIsLoadingImg(true);

        if (userId) {
          const response = await flexbaseClient.updatePersonPicture(
            userId,
            files[0],
          );

          if (!response) {
            setErrorMsg('Unable to upload profile picture');
          }
        }
      }
    } catch (error) {
      setErrorMsg('Unable to upload profile picture');
    } finally {
      setIsLoadingImg(false);
    }
  };

  const getProfile = async () => {
    try {
      setIsLoadingImg(true);

      if (userId) {
        const response = await flexbaseClient.getPersonPicture(userId);

        if (response) {
          const blobURL = URL.createObjectURL(new Blob([response]));
          setProfilePic(blobURL);
        }
      }
    } finally {
      setIsLoadingImg(false);
    }
  };

  const setFilePicker = () => {
    const evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: false,
    });
    uploadDocRef?.current?.dispatchEvent(evt);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className={classes.containerForm}>
      <div>
        <div className={classes.imgContainer}>
          {isLoadingImg ? (
            <Loader color={highlighted} />
          ) : (
            <>
              {profilePic ? (
                <img alt="Profile" src={profilePic} />
              ) : (
                <BuildingIcon width={120} height={120} fill={foregroundLight} />
              )}
            </>
          )}
        </div>

        <Center style={{ marginTop: '2rem' }}>
          <Button
            className={classes.btnUploadPic}
            variant="outline"
            type="button"
            onClick={setFilePicker}
          >
            Upload New Image
          </Button>
        </Center>
      </div>
      <div style={{ width: '100%' }}>
        <form id="updateUser" onSubmit={theForm.onSubmit(updateUser)}>
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="file"
            accept=".png, .jpg, .jpge"
            ref={uploadDocRef}
            onChange={uploadPicture}
          />
          <Container mb="1rem">
            {errorMsg ? (
              <Alert icon={<FaExclamationCircle />} color="red">
                {errorMsg}
              </Alert>
            ) : null}
          </Container>
          <Container>
            <label htmlFor="firstName">First Name</label>
            <Input
              mt="0.3rem"
              size={inputSize}
              radius="md"
              id="firstName"
              name="firstName"
              aria-label="firstName"
              type="text"
              {...theForm.getInputProps('firstName')}
            />
          </Container>
          <Container mt="1.5rem">
            <label htmlFor="lastName">Last Name</label>
            <Input
              mt="0.3rem"
              size={inputSize}
              radius="md"
              id="lastName"
              name="lastName"
              aria-label="lastName"
              type="text"
              {...theForm.getInputProps('lastName')}
            />
          </Container>
          <Container mt="1.5rem">
            <label htmlFor="email">Email</label>
            <Input
              size={inputSize}
              radius="md"
              mt="0.3rem"
              id="email"
              name="email"
              aria-label="email"
              type="email"
              {...theForm.getInputProps('email')}
            />
          </Container>
          <Container mt="1.5rem">
            <label htmlFor="jobTitle">Title</label>
            <Input
              size={inputSize}
              radius="md"
              mt="0.3rem"
              id="jobTitle"
              name="jobTitle"
              aria-label="jobTitle"
              type="text"
              {...theForm.getInputProps('jobTitle')}
            />
          </Container>
          <Container mt="1.5rem">
            <label htmlFor="phone">Phone</label>
            <Input
              size={inputSize}
              radius="md"
              mt="0.3rem"
              id="cellPhone"
              aria-label="cellPhone"
              type="text"
              placeholder="(333) 333-3333"
              name="cellPhone"
              onChange={({ target }: HandleInputChange) =>
                theForm.setFieldValue(
                  'cellPhone',
                  formatUSPhoneNumber(target.value),
                )
              }
              value={theForm.values.cellPhone}
            />
          </Container>
          {!theForm.values.authorizedSignatory && (
            <Checkbox
              mt="1rem"
              id="authorizedSignatory"
              name="authorizedSignatory"
              aria-label="authorizedSignatory"
              {...theForm.getInputProps('authorizedSignatory', {
                type: 'checkbox',
              })}
              label="I&rsquo;m an authorized signatory for my company"
            />
          )}
        </form>
        <div style={{ marginTop: '1.25rem' }}>
          <Center>
            <Anchor onClick={() => openChangePassModal()}>
              Change Password
            </Anchor>
          </Center>
          <Button
            className={classes.btnSaveProfile}
            type="submit"
            form="updateUser"
            aria-label="btnUpdateUser"
            disabled={
              !theForm?.values?.firstName ||
              !theForm?.values?.lastName ||
              !theForm?.values?.email ||
              !theForm?.values?.jobTitle ||
              !theForm?.values?.cellPhone
            }
          >
            {isLoading ? (
              <Loader size={18} color="#fff" style={{ marginRight: '10px' }} />
            ) : null}
            Save Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;
