import { Global } from '@mantine/core';
import regular from 'assets/fonts/PPNeueMontreal-Regular.woff2';
import medium from 'assets/fonts/PPNeueMontreal-Medium.woff2';

import redactionRegular from 'assets/fonts/Redaction-Regular.otf';

export function Fonts() {
  return (
    <Global
      styles={(theme) => [
        {
          '@font-face': {
            fontFamily: 'PP Neue Montreal',
            src: `url('${regular}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'PP Neue Montreal',
            src: `url('${medium}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Redaction',
            src: `url(${redactionRegular})`,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  );
}
