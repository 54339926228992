import { Button, Center, Image, Text } from '@mantine/core';
import { ReactNode } from 'react';
import { useEndScreenStyles } from './use-end-screen-styles';

interface Props {
  title: string;
  description: ReactNode;
  graphic: string;
  primaryButtonText: ReactNode;
  secondaryButtonText?: string;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  id?: string;
  showPrimaryLoader?: boolean;
  showSecondaryLoader?: boolean;
}

const OnboardingEndScreen = ({
  title,
  description,
  graphic,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  id,
  showPrimaryLoader,
  showSecondaryLoader,
}: Props) => {
  const { classes } = useEndScreenStyles();
  return (
    <Center style={{ padding: '16px 0' }}>
      <div className={classes.container} id={id}>
        <Image
          className={classes.graphic}
          src={graphic}
          height={'100%'}
          width={'550px'}
        />
        <div className={classes.titleDescriptionContainer}>
          <Text className={classes.title}>{title}</Text>
          <Text className={classes.description}>{description}</Text>
          {primaryButtonText && (
            <Button
              id={`button-primary-${id || 'end-screen'}`}
              data-testid={`button-primary-${id || 'end-screen'}`}
              onClick={onPrimaryButtonClick}
              className={classes.primaryButton}
              loading={showPrimaryLoader}
              styles={{
                root: {
                  ':hover': {
                    backgroundColor: '#FF978C',
                  },
                },
              }}
            >
              {primaryButtonText}
            </Button>
          )}
          {secondaryButtonText && (
            <Button
              onClick={onSecondaryButtonClick}
              id={`button-secondary-${id || 'end-screen'}`}
              data-testid={`button-secondary-${id || 'end-screen'}`}
              variant="outline"
              styles={{
                root: {
                  '&:not([data-disabled]):hover': {
                    backgroundColor: '#FFEFED',
                  },
                },
              }}
              className={classes.secondaryButton}
              loading={showSecondaryLoader}
            >
              {secondaryButtonText}
            </Button>
          )}
        </div>
      </div>
    </Center>
  );
};

export default OnboardingEndScreen;
