import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import useStyles from './navbar.styles';
import {
  BOTTOM_NAVBAR_OPTIONS,
  MenuOption,
  NAVBAR_OPTIONS,
} from './menu-options';
import { getEnvironment } from 'utilities/url/window-helpers';
import { useRecoilValue } from 'recoil';
import { UserCompanyState } from 'states/company/company';
import { IsAdmin } from '../../states/application/product-onboarding';

interface Props {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const NavbarMobile = ({ setOpened, opened }: Props): ReactElement => {
  const { classes } = useStyles({ opened });
  const [highlightedRoute, setHighlightedRoute] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const hostEnv = getEnvironment(window.location.host);
  const isAdmin = useRecoilValue(IsAdmin);
  const { optedProducts } = useRecoilValue(UserCompanyState);

  const menuItemClass = (opt: MenuOption): string => {
    const path = location.pathname.split('/').filter((p) => p);
    if (location.pathname === opt.location || opt.location.includes(path[0])) {
      if (opt.unclickable) {
        return classes.notClickableHighlightedMenuOption;
      }
      return classes.highlightedMenuOption;
    } else if (opt.disabled || (opt.adminOnly && !isAdmin)) {
      return classes.disabledMenuOption;
    }
    if (opt.unclickable) {
      return classes.notClickableMenuOption;
    }
    return classes.menuOption;
  };

  const openSubOptions = (opt: MenuOption) => {
    if (opt.subRoutes && opt?.subRoutes.length > 0) {
      setHighlightedRoute(opt.location);
    } else {
      navigate(opt.location);
      setOpened(false);
      setHighlightedRoute('');
    }
  };

  useEffect(() => {
    if (!opened) {
      setHighlightedRoute('');
    }
  }, [opened]);

  const generateNavbarOptions = (options: MenuOption[]) =>
    options
      .filter(
        (opt) => opt.product === 'ALL' || optedProducts.includes(opt.product),
      )
      .map((opt: MenuOption) => {
        if (opt.disabled === true) {
          return;
        }
        if (opt.devOnly === true && hostEnv === 'production') {
          return;
        } else {
          return (
            <div key={opt.label}>
              <div
                className={`${classes.menuOptionCommon} ${menuItemClass(opt)}`}
                onClick={() => {
                  openSubOptions(opt);
                }}
              >
                <IconContext.Provider value={{ size: '25px' }}>
                  <div className={classes.menuOptionIcon}>{opt.icon}</div>
                </IconContext.Provider>
                <Text
                  size="sm"
                  style={{
                    fontFamily: 'PP Neue Montreal',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                    marginLeft: '30px',
                    alignItems: 'center',
                  }}
                >
                  {opt.label}
                  {opt.label === 'Insurance' && (
                    <span className={classes.txtComingSoon}>Coming soon</span>
                  )}
                </Text>
              </div>
              {opt.location === highlightedRoute && (
                <div>
                  <Text
                    key={opt.label}
                    size="sm"
                    className={classes.subroute}
                    onClick={() => {
                      navigate(`${opt.location}`);
                      setOpened(false);
                      setHighlightedRoute('');
                    }}
                  >
                    {opt.label}
                  </Text>
                  {opt.subRoutes?.map((route) => {
                    return (
                      <Text
                        key={route.label}
                        size="sm"
                        className={classes.subroute}
                        onClick={() => {
                          navigate(`${opt.location}${route.location}`);
                          setHighlightedRoute('');
                        }}
                      >
                        {route.label}
                      </Text>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }
      });

  return (
    <div className={classes.fullScreen}>
      <div className={classes.navbar}>
        <div className={classes.navbarOptions}>
          {generateNavbarOptions(NAVBAR_OPTIONS)}
        </div>
        <div className={classes.navbarOptions}>
          {generateNavbarOptions(BOTTOM_NAVBAR_OPTIONS)}
        </div>
      </div>
    </div>
  );
};

export default NavbarMobile;
