import { Box, createStyles, Group, Loader, rem, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import {
  TermsOfServiceDoc,
  TermsOfServiceType,
} from 'services/flexbase/flexbase-onboarding-client';
import { CompanySelector } from 'states/application/product-onboarding';

const bankingDisclaimer =
  '*See your Flex Cashback Terms and Conditions for details. \nFlexbase Technologies, Inc. is a financial technology company and is not a bank. Banking services provided by Thread Bank; Member FDIC. The Flexbase Technologies, Inc. Visa® Debit Card (“Flex Debit Card” / “Flex Banking”) is issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted. \n©2023 Flexbase Technologies, Inc., d/b/a Flex, “Flex" and the Flex logo are registered trademarks. Flex products may not be available to all customers. See the Flex Terms of Service for details. Terms are subject to change.';
const interestDisclaimer =
  'The interest rate on your account is 0.00% with an annual percentage yield of 0.00%. Your interest rate and annual percentage yield are variable and subject to change based on the target range of the Federal Funds Rate.';
const creditDisclaimer =
  'The Flexbase Technologies Credit Corporate Credit Card (“Flexbase Card”) is issued by Patriot Bank, N.A., Member FDIC, pursuant to licenses from Mastercard® International Incorporated. The Flexbase Card can be used everywhere Mastercard is accepted';

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.neutral[0]
    }`,
    padding: `0 ${theme.spacing.xs}`,
    lineHeight: 1,
    '@media(max-width: 767px)': {
      marginTop: 0,
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    [theme.fn.smallerThan(1280)]: {
      padding: 0,
    },
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      padding: 0,
    },
  },

  disclaimer: {
    fontSize: '10px',
    width: 600,
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },

  disclaimer2: {
    fontSize: '8px',
    width: 600,
    '@media(max-width: 767px)': {
      width: 400,
    },
  },

  contact: {
    fontSize: '10px',
    height: '100%',
    marginRight: theme.spacing.md,
    '@media(max-width: 767px)': {
      marginTop: 8,
    },
  },

  disclosure: {
    fontSize: '10px',
    fontWeight: 500,
  },
  disclosureContainer: {
    maxWidth: 500,
    '@media(max-width: 767px)': {
      maxWidth: 200,
    },
  },
}));

type ClassNames = {
  footer: string;
  footerInner: string;
  footerDisclaimer: string;
  footerDisclaimer2: string;
  footerContact: string;
  footerDiscloure: string;
  footerDisclosureContainer: string;
};

type Props = {
  disclaimer2?: boolean;
  footerMarginTop?: string;
  classNames?: Partial<ClassNames>;
};

export const FlexbaseFooter = ({
  disclaimer2 = false,
  footerMarginTop,
  classNames,
}: Props) => {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const [flexbaseDocs, setFlexbaseDocs] = useState<TermsOfServiceDoc[]>([]);
  const [productDocs, setProductDocs] = useState<TermsOfServiceDoc[]>([]);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { optedProducts } = useRecoilValue(CompanySelector);

  const getTermsOfServiceDocs = async () => {
    setLoading(true);
    try {
      const flexbaseResponse =
        await flexbaseOnboardingClient.getTermsOfServiceDocs('flexbase', false);
      let productResponse: TermsOfServiceDoc[] = [];
      // user has opted products set
      if (optedProducts.length > 0) {
        optedProducts.map(async (p) => {
          const productAsString: string = p;

          const tos = await flexbaseOnboardingClient.getTermsOfServiceDocs(
            productAsString.toLocaleLowerCase() as TermsOfServiceType,
          );
          productResponse.concat(tos);
        });
      } else {
        // user has no opted products set, default to all tos
        const allTos: TermsOfServiceType[] = ['credit', 'banking'];
        const promises = allTos.map(async (t) => {
          const tos = await flexbaseOnboardingClient.getTermsOfServiceDocs(
            t,
            false,
          );
          return tos;
        });
        const resolvedVals = (await Promise.all(
          promises,
        )) as TermsOfServiceDoc[][];
        productResponse = productResponse.concat(...resolvedVals);
        console.log(productResponse);
      }
      setFlexbaseDocs(flexbaseResponse);
      setProductDocs(
        productResponse.filter(
          (doc) => doc.label !== 'Application and Solicitation Disclosure',
        ),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTermsOfServiceDocs();
  }, []);

  const supportContact = (
    <Stack
      className={cx(classes.contact, classNames?.footerContact)}
      align="flex-start"
      spacing="sm"
    >
      <div>support@flex.one</div>
      <div>(415) 840-8721 ext. 571</div>
    </Stack>
  );

  const disclosures = loading ? (
    <Loader
      size="sm"
      variant="dots"
      sx={(theme) => ({ color: theme.colors.neutral[0] })}
    />
  ) : (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        columnGap: theme.spacing.xs,
      })}
    >
      {[...flexbaseDocs, ...productDocs].map((doc, i) => {
        return (
          <div key={doc.tosId + i}>
            <a
              href={doc.url}
              target="_blank"
              rel="noreferrer"
              className={cx(classes.disclosure, classNames?.footerDiscloure)}
            >
              {doc.label}
            </a>
          </div>
        );
      })}
    </Box>
  );
  return (
    <Box
      mt={footerMarginTop}
      className={cx(classes.footer, classNames?.footer)}
    >
      <Group>
        <Box className={cx(classes.inner, classNames?.footerInner)}>
          <Stack spacing="xs">
            {optedProducts.map((p) => {
              if (p === 'CREDIT') {
                return (
                  <div key={p} className={classes.disclaimer}>
                    {creditDisclaimer}
                  </div>
                );
              }
              if (p === 'BANKING') {
                return (
                  <>
                    <div key={p} className={classes.disclaimer}>
                      {bankingDisclaimer}
                    </div>
                    {disclaimer2 && (
                      <div className={classes.disclaimer2}>
                        {interestDisclaimer}
                      </div>
                    )}
                  </>
                );
              }
            })}
          </Stack>
          {optedProducts.length === 0 && (
            <Stack spacing="xs">
              <div className={classes.disclaimer}>{creditDisclaimer}</div>
              <div className={classes.disclaimer}>{bankingDisclaimer}</div>
              {disclaimer2 && (
                <div className={classes.disclaimer2}>{interestDisclaimer}</div>
              )}
            </Stack>
          )}
        </Box>
        {!isMobile ? (
          <>
            <Box className={cx(classes.inner, classNames?.footerInner)}>
              {supportContact}
            </Box>
            <Box className={cx(classes.inner, classNames?.footerInner)}>
              {disclosures}
            </Box>
          </>
        ) : (
          <Box
            className={cx(classes.inner, classNames?.footerInner)}
            style={{ paddingTop: 0 }}
          >
            <Group align="flex-start">
              {supportContact}
              {disclosures}
            </Group>
          </Box>
        )}
      </Group>
    </Box>
  );
};
