import { PlaidAccount } from 'areas/banking/move-funds/move-funds.model';
import { DateTime } from 'luxon';

export type PlaidRelinkRequest =
  | { accessToken: string }
  | { plaidTokenId: string }
  | { bankName: string };

export interface PlaidRelinkResponse {
  success: boolean;
  response: {
    expiration: string;
    link_token: string;
    request_id: string;
  };
}

export interface PlaidBalances {
  success: boolean;
  accounts: PlaidBalanceAccount[];
}

export interface PlaidBalanceAccount {
  accountId: string;
  balances: Balances;
  mask: string;
  name: string;
  officialName: string;
  subtype: string;
  type: string;
  itemId: string;
  institutionId: string;
}

export interface Balances {
  available: number;
  current: number;
  isoCurrencyCode: string;
  limit: any;
  unofficialCurrencyCode: any;
}

export interface PlaidAccounts {
  success: boolean;
  accounts?: PlaidAccount[];
}

export interface Account {
  accessToken: string;
  account: string;
  accountId: string;
  accountName: string;
  accountType: string;
  bankName: string;
  companyId: string;
  id: string;
  itemId: string;
  officialName: string;
  routing: string;
  stripeBankAccountToken: string;
  unitProcessorToken: string;
  userId: string;
  unlinked: boolean;
  tenantId: string;
  logoUrl: string;
}

export interface CounterpartyResponse {
  success: boolean;
  counterparty: Counterparty;
}

export interface CounterpartyResponseList {
  success: boolean;
  counterparties: Counterparty[];
}

export interface Address {
  address: string;
  addressLine2?: string | null;
  // how we get the address object from UnitCo
  street?: string;
  street2?: string | null;
  city: string;
  country: string;
  postalCode: string;
  state: string;
}
export interface Counterparty {
  accountName: string;
  accountNumber: string;
  accountType?: 'Checking' | 'Savings';
  routingNumber: string;
  asOf: string;
  companyId: string;
  createdAt: string;
  id: string;
  response: UnitCounterpartyResponse;
  status: 'active' | 'inactive';
  type: 'ach' | 'wire';
  nickName: string;
  name: string;
  userId: string;
  version: number;
  tenantId: string;
  lastUsedAt: string;
  address?: Address;
  bank?: string;
}

export interface UnitCounterpartyResponse {
  data: CounterpartyData;
}

export interface ActualCounterpartyResponse {
  counterparty: Counterparty;
}

export interface CounterpartyData {
  attributes: CounterpartyAttributes;
  id: string;
  relationships: Relationships;
  type: string;
}

export interface CounterpartyAttributes {
  accountNumber: string;
  accountType: string;
  bank?: string;
  createdAt: string;
  name: string;
  permissions: string;
  routingNumber: string;
  tags?: Tags;
  type: string;
}

export interface Tags {
  companyId?: string;
  userId?: string;
  tenantId?: string;
}

export interface Relationships {
  customer: Customer;
}

export interface Customer {
  data: UnitCoMetaData;
}

export interface CounterpartyAch {
  name: string;
  nickName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  type: string;
}

export interface CounterpartyWire {
  name: string;
  nickName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
  type: string;
  address: {
    city: string;
    state: string;
    country: string;
    postalCode: string;
    address: string;
    addressLine2?: string;
  };
}

export type PaymentType = 'ach' | 'wire' | 'flex';

export interface CounterpartyRequest {
  type: PaymentType;
  counterparty: CounterpartyAch | CounterpartyWire;
}

export type MoneyMovementStatus =
  | 'AwaitingConfirmation'
  | 'Clearing'
  | 'Pending'
  | 'PendingReview'
  | 'Rejected'
  | 'Sent';

interface MoneyMovementBase {
  id: string;
  type: 'ach' | 'wire';
  asOf: string;
  createdAt: string;
  companyId: string;
  counterpartyId?: string;
  depositId: string;
  docs?: string[];
  payAmount: string;
  payAmountCents: string;
  payCtrParty: string;
  payDescription: string;
  payDirection: 'Credit' | 'Debit';
  status: MoneyMovementStatus;
  reason?: string;
  userId: string;
  version: number;
  tenantId: string;
  notes: string;
}

export type MoneyMovement = (
  | {
      type: 'ach';
      payDirection: 'Credit';
    }
  | {
      type: 'ach';
      payDirection: 'Debit';
    }
  | {
      type: 'wire';
      payDirection: 'Credit';
    }
) &
  MoneyMovementBase;

export interface MoneyMovementResponse {
  success: boolean;
  payment: MoneyMovement;
}

export type MoneyMovementListReponse = {
  success: boolean;
  payments: MoneyMovement[];
};

export type GetDepositAccountListResponse = { accounts: DepositAccount[] };

// deposit response
export interface DepositListResponse {
  success: boolean;
  accounts: DepositAccount[];
}

export interface CreateDepositAccountRequest {
  isDefault?: boolean;
  nickName?: string;
}
export interface CreateDepositAccountResponse {
  success: boolean;
  id: string;
  status: string;
  message: string;
  depositAccount: DepositAccount;
}

export interface DepositAccount {
  asOf: string;
  companyId: string;
  createdAt: string;
  id: string;
  isDefault: boolean;
  name: string;
  nickName: string;
  status: string;
  type: 'depositAccount';
  accountType: 'checking';
  userId: string;
  version: number;
  tier: 1 | 2 | 3;
  routingNumber: string;
  accountNumber: string;
  balance: number;
  available: number;
}

export interface DepositRelationship {
  customer: DepositCustomer;
  org?: Org;
}

export interface DepositCustomer {
  data: UnitCoMetaData;
}

export interface UnitCoMetaData {
  id: string;
  type: string;
}

export interface Org {
  data: UnitCoMetaData;
}

interface CreateMoneyMovementBase {
  accountId: string;
  amount: string;
  description: string;
  notes?: string;
  authorizations?: [
    {
      contents: string;
    },
  ];
  attachments?: string[];
}

export type CreateWireMoneyMovement = {
  type: 'wire';
  direction: 'Credit';
  counterpartyId: string;
} & CreateMoneyMovementBase;

export type CreateBookeMoneyMovement = {
  type: 'book';
  direction: string;
  accountIdTo: string;
  description: string;
} & CreateMoneyMovementBase;

export interface DebitAuthorization {
  contents: string;
}

export type CreateACHMoneyMovement = (
  | {
      type: 'ach';
      direction: 'Credit';
      counterpartyId: string;
    }
  | {
      type: 'ach';
      direction: 'Credit';
      plaidTokenId: string;
    }
  | {
      type: 'ach';
      direction: 'Debit';
      plaidTokenId: string;
      authorizations: DebitAuthorization[];
    }
) &
  CreateMoneyMovementBase;

export type CreateMoneyMovementRequest =
  | CreateWireMoneyMovement
  | CreateACHMoneyMovement
  | CreateBookeMoneyMovement;

export interface CustTokenVGS {
  verificationToken: string;
  verificationCode: string;
}
interface TransactionTypeLookup {
  [key: string]: string;
}
export const transactionMapping: TransactionTypeLookup = {
  paymentCanceledTransaction: 'Payment Canceled',
  wireTransaction: 'Wire',
  bookTransaction: 'Internal Transfer',
  receivedAchTransaction: 'ACH Received',
  atmTransaction: 'ATM Withdrawal',
  cardReversalTransaction: 'Card Reversal',
  originatedAchTransaction: 'Originated ACH',
  purchaseTransaction: 'Card Purchase',
  cardTransaction: 'Card Transaction',
  rewardTransaction: 'Reward',
  wire: 'Wire', // payment mapping
  book: 'Internal Transfer', // payment mapping
  ach: 'ACH Payment', // payment mapping
};

export interface TransactionResponse {
  success: boolean;
  transactions: Transaction[];
}

export interface Transaction {
  accountName: string;
  accountNumber: string;
  amount: string;
  balance: string;
  cardId?: string; // deprecate soon
  companyName: string;
  createdAt: string;
  depositAccount: string; // deprecate soon
  depositAccountId: string;
  direction: string;
  id: string;
  summary: string;
  whoDisplayName?: string;
  whoFirstName?: string;
  whoLastName?: string;
  whoUsername?: string;
  type: string;
  moneyMovement?: boolean;
  status?: string; // only on money movement requests.
  paymentId?: string;
}

export interface TreasuryAllocationsResponse extends FlexbaseResponse {
  allocations: TreasuryAllocation[];
  closingBalance: string;
}

export interface TreasuryAllocation {
  admAcctId: string;
  amount: string;
  asOf: string;
  companyId: string;
  datePosted: string;
  id: string;
  instCity: string;
  instId: string;
  instLogoUrl: string;
  instName: string;
  instState: string;
  instUrl: string;
  tenantId: string;
}

export interface TreasuryActivityResponse extends FlexbaseResponse {
  activity: TreasuryActivity[];
}

export interface TreasuryActivity {
  asOf: string;
  byUser: string;
  createdAt: string;
  datePosted: string;
  id: string;
  status: string;
  failureReason: any;
  admAcctId: string;
  userId: string;
  companyId: string;
  version: number;
  activity: string;
  amount: number;
}

export interface TreasuryAccountResponse extends FlexbaseResponse {
  account: TreasuryAccount;
}

export interface TreasuryAccount {
  asOf: string;
  byUser: string;
  companyId: string;
  createdAt: string;
  id: string;
  admName: string;
  plaidTokenId: string;
  userId: string;
  version: number;
}

export interface Limits {
  dailyWithdrawal?: number;
  dailyPurchase?: number;
  monthlyWithdrawal?: number;
  monthlyPurchase?: number;
}

export interface IssueDebitCard {
  issuer: string;
  cardType: string;
  limits?: Limits | null;
  cardName?: string | null;
  depositAccountId: string;
  shippingAddress?: Address;
}

export interface MonthToDateSpends {
  mtdSpend: number;
  startOfMonth: string;
}

export interface CardByUser {
  asOf: string;
  cardName: string;
  cardNumber: string;
  cardSubtype: string;
  cardType: string;
  creditLimit: string;
  expirationDate: string;
  expensesTypes: Limits;
  holder: string;
  id: string;
  monthToDateSpends: MonthToDateSpends;
  shipTo: Address;
  status: string;
  ucCardId: string;
  userId: string;
  depositAccountId: string;
}

export interface DebitCardResponse {
  success: boolean;
  card?: CardByUser;
  error?: string;
}

export interface DebitCardsResponse {
  success: boolean;
  cards?: CardByUser[];
  error?: string;
}

export type CardStatus =
  | 'lost'
  | 'stolen'
  | 'freeze'
  | 'unfreeze'
  | 'cancelled';

export interface UpdateCardRequest {
  id: string;
  cardName?: string;
  limits?: Limits;
  shippingAddress?: Address;
}

export type ApplicationType = 'individualApplication' | 'businessApplication';

export interface ApplicationDocument {
  applicationDocId: string;
  documentType: string;
  status: string;
  description?: string;
  name?: string;
}

// get application response
export type BankingApplicationStatus =
  | 'PendingReview'
  | 'Pending'
  | 'Approved'
  | 'Denied'
  | 'Canceled'
  | 'AwaitingDocuments'
  | 'Unqualified'
  | 'Incomplete'
  | 'Unused';
type GetApplicationStatusErrorResponse = { success: false; error: string };
type GetApplicationStatusAwaitingDocumentsResponse = {
  success: true;
  type: ApplicationType;
  status: 'AwaitingDocuments';
  url?: string;
  documents: ApplicationDocument[];
};
type GetApplicationStatusApplicationResponse = {
  success: true;
  type: ApplicationType;
  status: BankingApplicationStatus;
};
export type GetApplicationStatusResponse =
  | GetApplicationStatusErrorResponse
  | GetApplicationStatusAwaitingDocumentsResponse
  | GetApplicationStatusApplicationResponse;
export function BankApplicationStatusIsError(
  response: GetApplicationStatusResponse,
): response is GetApplicationStatusErrorResponse {
  return !response.success && !!response.error;
}
export function BankApplicationStatusIsAwaitingDocuments(
  response: GetApplicationStatusResponse,
): response is GetApplicationStatusAwaitingDocumentsResponse {
  return response.success && response.status === 'AwaitingDocuments';
}
export function BankApplicationStatusIsApplicationResponse(
  response: GetApplicationStatusResponse,
): response is GetApplicationStatusApplicationResponse {
  return response.success && response.status !== 'AwaitingDocuments';
}

// post application response
export interface CreateApplicationStatusResponse {
  success: boolean;
  id: string;
  status: BankingApplicationStatus;
  message: string;
  application: Application;
}

export interface Application {
  data: Data;
  included: any[];
}

export interface Data {
  attributes: Attributes;
  id: string;
  relationships: Relationships;
  type: string;
}

export interface Attributes {
  address: Address;
  archived: boolean;
  beneficialOwners: any[];
  contact: Contact;
  createdAt: string;
  entityType: string;
  message: string;
  name: string;
  officer: Officer;
  phone: Phone3;
  stateOfIncorporation: string;
  status: string;
  tags: Tags;
  updatedAt: string;
}

export interface Contact {
  fullName: FullName;
  phone: Phone;
}

export interface FullName {
  first: string;
  last: string;
}

export interface Phone {
  countryCode: string;
  number: string;
}

export interface Officer {
  address: Address2;
  fullName: FullName2;
  phone: Phone2;
  status: string;
  title: string;
}

export interface Address2 {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  street: string;
  street2: string;
}

export interface FullName2 {
  first: string;
  last: string;
}

export interface Phone2 {
  countryCode: string;
  number: string;
}

export interface Phone3 {
  countryCode: string;
  number: string;
}

export interface Data2 {
  id: string;
  type: string;
}

export interface Data3 {
  id: string;
  type: string;
}

export interface FlexbaseError {
  success: false;
  error: string;
}

interface FlexbaseResponse {
  success: boolean;
  error?: unknown;
}
export interface CreateTokenRequest {
  scope?: string;
  expiresIn?: number;
  verificationCode: string;
  verificationToken: string;
}

export interface GetUnitcoTokenResponse extends FlexbaseResponse {
  type?: string;
  attributes?: {
    verificationToken: string;
  };
}

export interface CreateUnitcoTokenResponse extends FlexbaseResponse {
  asOf?: string;
  expiresIn?: number;
  accessToken?: string;
}

export interface Statement {
  id: string;
  type: 'accountStatementDTO';
  depositAccountId: string;
  attributes: {
    period: string;
  };
}

export interface StatementsResponse extends FlexbaseResponse {
  statements: Statement[];
}

export interface BankingParameters {
  isPdf?: boolean;
  pageLimit?: number;
  pageOffset?: number;
  fromDate?: DateTime;
  toDate?: DateTime;
  period?: DateTime;
  sort?: string;
  limit?: number;
  offset?: number;
  accountId?: string;
  cardId?: string;
  full?: boolean;
  depositAccountId?: string;
  paymentId?: string;
}

export interface WireInstructionsModel {
  beneficiaryName: string;
  beneficiaryAccountNumber: string;
  beneficiaryRoutingNumber: string;
  receiverABA: string;
  beneficiaryAddress: string;
  beneficiaryBankName: string;
  beneficiaryBankRoutingNumber: string;
  beneficiaryBankAddress: string;
}

export type WireInstructionsResponse = WireInstructionsModel & FlexbaseResponse;

export interface DocMetadata {
  createdAt: string;
  docType: string;
  docId?: string;
  extension: string;
  id: string;
  path: string;
  sourceName: string;
}

export interface PaymentDocs {
  description: string;
  docDate: string;
  docId: string;
  droppedAt: string;
  id: string;
  paymentId: string;
  uploadedAt: string;
  userId: string;
  metadata: DocMetadata;
}

export interface PaymentDocsResponse {
  success: boolean;
  docs: PaymentDocs[];
}

export interface UpdatePaymentDocResponse {
  success: boolean;
  doc: PaymentDocs;
}
