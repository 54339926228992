import { IAuthenticatedUser } from 'states/auth/authenticated-user';
import { AuthenticationToken } from '@flexbase/http-client-middleware';
import {
  convertFlexbaseToken,
  FlexbaseClient,
  FlexbasePasswordCredentials,
  FlexbaseTokenResponse,
} from 'flexbase-client';
import jwt_decode from 'jwt-decode';
import { FlexbaseJwt } from 'flexbase-client/dist/models/auth/FlexbaseJwt';
import { Analytics } from './analytics/analytics';
import { getRecoil } from 'utilities/recoil/nexus';
import { BnplState } from 'states/bnpl/bnpl.state';
import { DateTime } from 'luxon';
import { KEY_REMEMBER_USER } from '../states/auth/auth-token';

type LoginOptions = { isBnpl: boolean; rememberUsername: boolean };
export type RegisterUser = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  cellPhone?: string;
  state?: string;
  roles?: string[];
  options?: Partial<LoginOptions>;
  promoCode?: string;
};
export interface ResponseMessageFormat {
  success: boolean;
  error: string;
}

export class FlexbaseClientLogin extends FlexbaseClient {
  private convertToAuthUser(
    authToken: AuthenticationToken,
    options: Partial<LoginOptions> = {},
  ): IAuthenticatedUser {
    // const { email, companyId, id } = decodeFlexbaseToken(authToken.token);
    const { id } = jwt_decode<FlexbaseJwt>(authToken.token);

    // we have valid authentication, identify this user in analytics
    return {
      user: {
        email: '',
        userId: id,
        completedOnboarding: false,
      },
      scopes: authToken.scope.split(' '),
      token: authToken,
      username: '',
      rememberUsername: !!options.rememberUsername,
      logoutOn: DateTime.now().plus({ minutes: 15 }).toISO()!,
    };
  }

  async login(
    email: string,
    password: string,
    code: string,
    options: Partial<LoginOptions> = {},
  ): Promise<IAuthenticatedUser | AuthenticationToken | null> {
    // This same endpoint is used for 2FA, we only want to track the initial attempt
    if (!code) {
      Analytics.track('Login Attempt', {
        email,
        bnplUser: !!options.isBnpl,
      });
    }

    try {
      this.setAuthenticationToken(null);

      const creds = new FlexbasePasswordCredentials({
        tokenUrl: import.meta.env.VITE_APP_FLEXBASE_API_AUTH_TOKEN_URL || '',
        username: email,
        password,
      });
      creds.code = code;

      const authToken = await this.tokenAccessor.requestToken(creds, undefined);
      if (!authToken) {
        this.logger.error('Unable to login');
        return null;
      }

      // If it's a type challenge, then just return it to Login Page
      if (authToken.tokenType === 'Challenge') {
        return authToken;
      }

      this.setAuthenticationToken(authToken);

      Analytics.track('Login Successful');
      return this.convertToAuthUser(authToken, options);
    } catch (error) {
      this.logger.error('Login error');
      Analytics.track('Login Unsuccessful', {
        email,
      });
      return null;
    }
  }

  async register(
    {
      email,
      password,
      state,
      cellPhone,
      firstName,
      lastName,
      roles = ['ADMIN'],
      options = {},
      promoCode,
    }: RegisterUser,
    variant?: string,
  ): Promise<IAuthenticatedUser> {
    Analytics.track('Registration Attempt', {
      email,
      promoCode,
      bnplUser: !!options.isBnpl,
      variant,
    });

    const request: Omit<RegisterUser, 'options'> = { email, password, roles };

    if (state) {
      request.state = state;
    }

    if (cellPhone) {
      request.cellPhone = cellPhone;
    }

    if (firstName) {
      request.firstName = firstName;
    }

    if (lastName) {
      request.lastName = lastName;
    }

    if (promoCode) {
      request.promoCode = promoCode;
    }

    const response = await this.client
      .url('/auth/newUser')
      .options({ authContext: { isAnonymousRoute: true } }, true)
      .post(request)
      .json<FlexbaseTokenResponse>();

    const authToken = convertFlexbaseToken(response);

    if (!authToken) {
      this.logger.error('Unable to register new user');
      Analytics.track('Application Error Registering New User', {
        email,
      });
      throw new Error('Unable to find auth token.');
    }

    this.setAuthenticationToken(authToken);
    const authenticatedUserObject = this.convertToAuthUser(authToken, options);
    if (authenticatedUserObject.user?.userId) {
      Analytics.people.set(authenticatedUserObject.user?.userId, {
        variant: variant,
      });
    }
    return authenticatedUserObject;
  }

  async forgotPassword(email: string): Promise<ResponseMessageFormat> {
    let response: ResponseMessageFormat = { success: false, error: '' };
    try {
      response = await this.client
        .url('/auth/magicLogin?action=resetPass')
        .options({ isAnonymousRoute: true }, true)
        .post({ destination: email, redirect: 'changePassword' })
        .json<ResponseMessageFormat>();
      Analytics.track('Login Password Reset Requested');
      return response;
    } catch (error) {
      this.logger.error('Unable to submit forgotten password request');
    }
    return response;
  }

  async resetPassword(password: string, token: string): Promise<boolean> {
    const { email } = jwt_decode<FlexbaseJwt>(token);

    const authToken = convertFlexbaseToken({ token, success: true });
    this.setAuthenticationToken(authToken);

    try {
      const response = await this.client
        .url('/auth/setPass')
        .headers({ Authorization: `Bearer ${authToken?.token || ''}` })
        .post({ email, password })
        .json();

      if (!response || !response.success) {
        this.logger.error('Unable to reset password for user');
        return false;
      }
    } catch (error) {
      this.logger.error('unable to reset password at this time.');
      return false;
    }
    return true;
  }

  async generateCode(phoneNumber: string): Promise<boolean> {
    try {
      await this.client
        .url('/twilio/sms/code/generate')
        .options({ isAnonymousRoute: true }, true)
        .post({
          phoneNumber,
        })
        .json<{
          success: boolean;
          error?: string;
          twilioResp?: { valid?: boolean };
        }>();
      return true;
    } catch (error) {
      console.error('Unable to generate 2FA code', error);
      return false;
    }
  }

  async verifyCode(phoneNumber: string, code: string): Promise<boolean> {
    try {
      const result = await this.client
        .url('/twilio/sms/code/verify')
        .options({ isAnonymousRoute: true }, true)
        .post({ phoneNumber, code })
        .json<{
          success: boolean;
          twilioResp?: { valid?: boolean };
          error?: string;
        }>(); // This is a subset of the Twilio resp object, but we don't need the model
      Analytics.track('Login Valid F2A Submitted');
      return result?.twilioResp?.valid ?? false;
    } catch (error) {
      console.error('2FA code invalid.');
      Analytics.track('Login Invalid F2A Submitted');
      return false;
    }
  }

  async refreshToken(): Promise<IAuthenticatedUser | null> {
    try {
      const result = await this.client
        .url('/auth/refreshToken')
        .get()
        .json<FlexbaseTokenResponse>();

      // For whatever reason, the API deviates from the expected format, so this function will fail
      const authToken = convertFlexbaseToken({
        ...result,
        success: !!result.token,
      });

      if (!authToken) {
        return null;
      }

      this.setAuthenticationToken(authToken);

      const rememberUsername =
        localStorage?.getItem(KEY_REMEMBER_USER) === 'true';
      const { isBnpl } = getRecoil(BnplState);

      return this.convertToAuthUser(authToken, {
        isBnpl,
        rememberUsername,
      });
    } catch (error) {
      console.error('Unable to refresh token', error);
      return null;
    }
  }
}
