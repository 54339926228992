import { SelectItemProps, Text } from '@mantine/core';
import { forwardRef } from 'react';

interface ItemProps extends SelectItemProps {
  label: string;
  description: string;
}

export const AddressAutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ description, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
      <Text size="xs" color="dimmed">
        {description}
      </Text>
    </div>
  ),
);

AddressAutoCompleteItem.displayName = 'AddressAutoCompleteItem';
