import { DateTime } from 'luxon';

/**
 * Format date string to display the localized {Month + day} OR {Month + day + year}
 *
 * @param date
 * @param withYear (optional)
 */
export const getLocaleDate = (date: string, withYear?: boolean) => {
  const newDate = DateTime.fromSQL(date);

  if (!newDate.isValid) {
    return '';
  }

  return withYear ? newDate.toFormat('MMM d, yyyy') : newDate.toFormat('MMM d');
};

export const getLocaleMMDDYYYY = (date: string) => {
  const newDate = DateTime.fromSQL(date);

  if (!newDate.isValid) {
    return '';
  }

  return newDate.toFormat('MM/dd/yyyy');
};
