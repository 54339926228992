import { StyleSheet, Font } from '@react-pdf/renderer';
import { defaultStylesPDF } from 'components/pdf-viewer/styles';
import RedactionRegular from 'assets/fonts/Redaction-Regular.otf';
import PPNeueMontrealBold from 'assets/fonts/PPNeueMontreal-Bold.otf';
import PPNeueMontrealMedium from 'assets/fonts/PPNeueMontreal-Medium.otf';
import PPNeueMontrealRegular from 'assets/fonts/PPNeueMontreal-Regular.otf';

interface Colors {
  neutral?: string;
  tertiary?: string;
  textColor?: string;
  primaryDark?: string;
  secondaryDark?: string;
}

export const stylesPDF = ({
  neutral,
  textColor,
  primaryDark,
  secondaryDark,
}: Colors) => {
  const defaultStyles = defaultStylesPDF({
    primaryDark,
    neutral,
    textColor,
    secondaryDark,
  });

  Font.register({
    family: 'Redaction',
    fonts: [
      { src: RedactionRegular }, // font-style: normal, font-weight: normal
    ],
  });

  Font.register({
    family: 'PP Neue Montreal',
    fonts: [
      { src: PPNeueMontrealMedium }, // font-style: normal, font-weight: normal
      { src: PPNeueMontrealBold, fontStyle: 'bold' },
      { src: PPNeueMontrealRegular, fontStyle: 'thin' },
    ],
  });

  return StyleSheet.create({
    ...defaultStyles,

    paymentAmount: {
      fontSize: 36,
      color: primaryDark,
      fontFamily: 'Redaction',
    },

    text: {
      fontSize: 14,
      margin: '8px 0px',
      color: primaryDark,
      fontFamily: 'PP Neue Montreal',
    },

    containerPage: {
      width: '100%',
      padding: '0px 25px',
    },

    containerData: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    greenText: {
      fontSize: 14,
      marginBottom: 10,
      color: secondaryDark,
      fontFamily: 'PP Neue Montreal',
    },

    grayText: {
      fontSize: 12,
      color: textColor,
      fontStyle: 'thin',
      fontFamily: 'PP Neue Montreal',
    },

    bigGrayText: {
      color: '#4B4B4B',
      fontSize: '18px',
      fontFamily: 'PP Neue Montreal',
    },

    statusContainer: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      margin: '20px 25px 0px 25px',
      justifyContent: 'space-between',
    },

    transferDetails: {
      width: '100%',
      textAlign: 'center',
    },
  });
};
