import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import {
  Grid,
  UnstyledButton,
  Center,
  Loader,
  useMantineTheme,
  Progress,
} from '@mantine/core';

import { useStyles } from '../../styles';
import DebitCardForm from './debit-card-form';
import { CloseIcon, FlexIcon } from 'assets/svg';
import UnitcoToken from '../two-factor/unitco-token';
import { CardByUser } from 'services/flexbase/banking.model';
import flexbaseClient, {
  flexbaseBankingClient,
} from 'services/flexbase-client';
import FlexbaseConfetti from 'components/confetti/flexbase-confetti';
import ModalSuccess from '../../components/modal-success/modal-success';
import { DepositAccount } from 'areas/banking/move-funds/move-funds.model';

interface SelectItemProps {
  label: string;
  value: string;
}

enum IssueDebitCardSteps {
  GET_TOKEN,
  ISSUE_CARD,
  SUCCESS,
}

interface Props {
  appType: string;
  closeModal: () => void;
  debitCards: CardByUser[];
  setDebitCards: (x: any) => void;
}

const IssueDebitCard = ({
  appType,
  debitCards,
  closeModal,
  setDebitCards,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [error, setError] = useState(false);
  const [unitToken, setUnitToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [step, setStep] = useState(IssueDebitCardSteps.ISSUE_CARD);
  const [employees, setEmployees] = useState<SelectItemProps[]>([]);
  const [bankingAccounts, setBankingAccounts] = useState<DepositAccount[]>([]);

  const form = useForm({
    initialValues: {
      userId: '',
      cardName: '',
      purchaseLimit: null,
      limitType: 'unlimited',
      cardType:
        appType === 'businessApplication'
          ? 'businessDebitCard'
          : 'individualDebitCard',
    },
  });

  const getCompanyUsers = async () => {
    try {
      setIsLoading(true);
      const result = await flexbaseClient.getEmployees();
      const allowedEmployees = result.filter(
        (user) => user.roles?.includes('ADMIN') && user.completedOnboarding,
      );
      const employeesData = allowedEmployees.map((employee) => {
        return {
          value: employee.id!,
          label: `${employee.firstName} ${employee.lastName}`,
        };
      });
      setEmployees(employeesData);
    } catch (error) {
      console.error('Unable to get the employees list', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBankingAccounts = async () => {
    try {
      setIsLoading(true);
      const result = await flexbaseBankingClient.getDepositList();
      let accountsData: DepositAccount[] = [];
      if (result.success && result.accounts) {
        result.accounts.forEach((account) => {
          accountsData.push({ ...account, plaidOrDeposit: 'deposit' });
        });
        accountsData = accountsData.filter((a) => a.status === 'Open');
        setBankingAccounts(accountsData);
      }
    } catch (error) {
      console.error('Unable to get the company accounts', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCostumerToken = async () => {
    const unitToken = await flexbaseClient.getUnitcoToken();
    if (unitToken.success && unitToken.attributes) {
      setUnitToken(unitToken.attributes?.verificationToken);
    }
  };

  const cardUser = employees.find(
    (employee) => employee.value === form.values.userId,
  );

  let currentStep: any;
  switch (step) {
    case IssueDebitCardSteps.GET_TOKEN:
      currentStep = (
        <div className={classes.card}>
          <div style={{ width: '420px' }}>
            <UnitcoToken
              stepName={IssueDebitCardSteps.ISSUE_CARD}
              token={unitToken}
              {...{ setStep }}
            />
          </div>
        </div>
      );
      break;

    case IssueDebitCardSteps.ISSUE_CARD:
      currentStep = (
        <DebitCardForm
          {...{
            form,
            setStep,
            appType,
            employees,
            setDebitCards,
            bankingAccounts,
          }}
        />
      );
      break;

    case IssueDebitCardSteps.SUCCESS:
      currentStep = (
        <div>
          <FlexbaseConfetti />
          <ModalSuccess
            backTo="debit cards"
            onClick={() => {
              setStep(1);
              form.setValues({
                userId: '',
                cardType: '',
                cardName: '',
                purchaseLimit: null,
                limitType: 'unlimited',
              });
            }}
            {...{ closeModal }}
            textToStart="Issue another debit card"
            title={`You’ve created a new debit card for ${cardUser?.label}!`}
          />
        </div>
      );
      break;
  }

  setTimeout(() => {
    if (error) {
      setError(false);
    }
  }, 5000);

  useEffect(() => {
    if (debitCards.length === 0) {
      setStep(IssueDebitCardSteps.GET_TOKEN);
      getCostumerToken();
    }
    getCompanyUsers();
    getBankingAccounts();
  }, []);

  if (isLoading || !employees.length || !bankingAccounts.length) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color={theme.fn.primaryColor()} size={50} />
      </Center>
    );
  }

  if (useMobileView) {
    return (
      <div className={classes.modalFormContent}>
        <>
          <UnstyledButton className="close-button" onClick={closeModal}>
            <CloseIcon color="#979797" />
          </UnstyledButton>
          {step < IssueDebitCardSteps.SUCCESS && (
            <Progress radius="sm" value={((step + 1) * 100) / 3} />
          )}
        </>
        <div className="content">{currentStep}</div>
      </div>
    );
  }

  return (
    <Grid className={classes.modalFormContent}>
      <Grid.Col span={3}>
        <FlexIcon width={90} />
      </Grid.Col>

      <Grid.Col className="content" span={6}>
        {currentStep}
      </Grid.Col>

      <Grid.Col span={3} className="close">
        <UnstyledButton onClick={closeModal}>
          <CloseIcon width={34} color="#979797" />
        </UnstyledButton>
      </Grid.Col>
    </Grid>
  );
};

export default IssueDebitCard;
