import type { ComponentProps } from 'react';
import { isValidElement, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {
  Checkbox,
  createStyles,
  Loader,
  Text,
  useMantineTheme,
} from '@mantine/core';
import type { TermsOfServiceType } from 'services/flexbase/flexbase-onboarding-client';
import { flexbaseOnboardingClient } from 'services/flexbase-client';

export type TextPromptProps = {
  type: TermsOfServiceType;
  showPreview?: boolean;
};

// render the prompt from the API for a single terms of service
export type Props = Omit<ComponentProps<typeof Checkbox>, 'label'> &
  TextPromptProps;

export const LoadingTermsFallback = ({ type }: TextPromptProps) => {
  const theme = useMantineTheme();
  return (
    <Text size="sm" style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <Loader color={theme.fn.primaryColor()} size="sm" variant="oval" />
      Fetching {type} terms of service...
    </Text>
  );
};

export const useTermsOfService = (type?: TermsOfServiceType) => {
  const [termsContent, setTermsContent] = useState<JSX.Element[]>([]);

  const [loading, setLoading] = useState(true);

  const getTerms = async () => {
    try {
      const terms = await flexbaseOnboardingClient.getTermsOfService(
        type,
        false,
      );
      const content = parse(terms.contents);
      if (Array.isArray(content)) {
        setTermsContent(
          content.filter((element) => element.toString() !== '\n'),
        );
      } else if (isValidElement(content)) {
        setTermsContent([content]);
      }
      setLoading(false);
    } catch (e) {
      console.error('An error occurred when retrieving terms of service', e);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return { loading, termsContent };
};

export const useTermsStyles = createStyles((theme) => ({
  document: {
    iframe: {
      width: '100%',
      height: '34rem',
    },
  },
}));

export const TermsOfServiceCheckboxPrompt = ({
  type,
  disabled,
  showPreview,
  ...props
}: Props) => {
  const { classes } = useTermsStyles();
  const { loading, termsContent } = useTermsOfService(type);
  const [preview, label] =
    type === 'credit' ? termsContent : [null, termsContent[0]];

  return (
    <>
      {loading ? (
        <LoadingTermsFallback type={type} />
      ) : (
        <>
          {showPreview && <div className={classes.document}>{preview}</div>}

          <Checkbox
            disabled={disabled}
            label={<div style={{ fontSize: 12 }}>{label}</div>}
            {...props}
            id={`checkbox-agree-${type.toLowerCase()}-terms-of-service`}
          />
        </>
      )}
    </>
  );
};

export const TermsOfServiceTextPrompt = ({
  type,
  showPreview,
}: TextPromptProps) => {
  const { loading, termsContent } = useTermsOfService(type);
  const [preview, label] =
    type === 'credit' ? termsContent : [null, termsContent[0]];

  return (
    <>
      {loading ? (
        <LoadingTermsFallback type={type} />
      ) : (
        <>
          {showPreview && preview}
          <div id={`text-terms-of-service-${type}`} style={{ fontSize: 12 }}>
            {label}
          </div>
        </>
      )}
    </>
  );
};
