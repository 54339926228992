import { createStyles } from '@mantine/core';

export const detailsCardStyles = createStyles((theme) => ({
  cardDetails: {
    color: '#5F5F5F',
    padding: '60px 0px',
    p: {
      margin: '0px',
      fontSize: '14px',
    },
    '> div': {
      padding: '0px 60px',
      '@media(max-width: 767px)': {
        padding: '0px 30px',
      },
    },
    '.badge': {
      color: '#fff',
      fontWeight: 500,
      padding: '5px 12px',
      textTransform: 'capitalize',
      span: {
        fontSize: '10px !important',
      },
    },
    '.actions': {
      height: '30px',
      display: 'flex',
      minWidth: '55%',
      padding: '0px 10px',
      alignItems: 'center',
      borderRadius: '100px',
      justifyContent: 'center',
      backgroundColor: theme.colors['flexbase-cosmic'],
      p: {
        color: '#fff',
      },
    },
    '.actions-disable': {
      height: '30px',
      display: 'flex',
      minWidth: '55%',
      padding: '0px 10px',
      alignItems: 'center',
      borderRadius: '100px',
      justifyContent: 'center',
      border: '1px solid #DFDFDF',
      backgroundColor: 'rgba(151, 151, 151, 0.1)',
      p: {
        color: 'rgba(95, 95, 95, 0.5)',
      },
    },
    '.row-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      button: {
        display: 'flex',
        fontSize: '14px',
        color: '#5F5F5F',
        alignItems: 'center',
        svg: {
          marginRight: '5px',
        },
      },
      span: {
        fontSize: '14px',
        fontWeight: 600,
      },
      '&:not(:first-child)': {
        span: {
          margin: '8px 0px',
        },
      },
    },
    '.end-content': {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '.credit-card': {
      padding: '20px',
      margin: '20px 0px',
      borderRadius: '15px',
      border: '1px solid #DFDFDF',
    },
    '.active-credit-card': {
      padding: '20px',
      margin: '20px 0px',
      borderRadius: '15px',
      border: `1px solid ${theme.fn.primaryColor()}`,
      backgroundColor: 'rgba(255, 87, 69, 0.05)',
    },
    '.buttons-container': {
      display: 'flex',
      marginTop: '20px',
      justifyContent: 'space-between',
    },
    '.menu': {
      cursor: 'pointer',
      'div[aria-expanded=true]': {
        color: theme.fn.primaryColor(),
        svg: {
          fill: theme.fn.primaryColor(),
        },
      },
      '&:hover': {
        color: theme.fn.primaryColor(),
        svg: {
          fill: theme.fn.primaryColor(),
        },
      },
    },
    '.transactions-action': {
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '&:hover': {
        color: theme.fn.primaryColor(),
        svg: {
          fill: theme.fn.primaryColor(),
        },
      },
    },
    '.freeze-action': {
      height: '30px',
      display: 'flex',
      color: '#5F5F5F',
      alignItems: 'center',
      '&:hover': {
        color: theme.colors['flexbase-cosmic'],
        svg: {
          fill: theme.colors['flexbase-cosmic'],
        },
      },
    },
    '@media(max-width: 767px)': {
      padding: '30px 0px',
    },
  },
}));
