import { atom } from 'recoil';

export interface AlertParam {
  companyId: string;
  description: string;
  eventName: string;
  fallback: boolean;
  groupName: string;
  id: string;
  sendTo: string;
  template: string;
  tenantId: string;
  userId: string;
}

export const AlertParamState = atom<AlertParam[]>({
  key: 'alert-param',
  default: [],
});
