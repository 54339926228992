import { Avatar, createStyles, Menu } from '@mantine/core';
import { GrLogout } from 'react-icons/gr';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';
import { useAuthToken } from '../../../states/auth/auth-token';

const useStyles = createStyles((theme) => ({
  avatar: {
    height: '40px',
    width: '40px',
    '> div:first-of-type': {
      color: `${theme.fn.primaryColor()} !important`,
      backgroundColor: 'rgb(252, 239, 237) !important',
      fontFamily: 'PP Neue Montreal',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
    },
  },
}));

const UserActionButtons = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const userInfo = useRecoilValue(UserInfoState);
  const { logout } = useAuthToken();

  return (
    <Menu
      opened={opened}
      onChange={(open) => setOpened(open)}
      styles={{
        item: {
          '&[data-hovered]': {
            border: 'none',
            background: 'rgba(255, 87, 69, 0.08);',
          },
          maxWidth: '143px',
          fontSize: '14px',
        },
      }}
      closeOnClickOutside={true}
    >
      <Menu.Target>
        <div
          style={{
            maxWidth: '143px',
            marginLeft: 'auto',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          <Avatar
            radius="xl"
            src={userInfo.picUrl || null}
            className={classes.avatar}
            data-testid="user-avatar"
          >
            {userInfo.initials}
          </Avatar>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={<GrLogout />}
          onClick={() => logout()}
          data-testid="logout-button"
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserActionButtons;
