import * as React from 'react';
import { createStyles } from '@mantine/core';

type Props = { children: React.ReactNode };

const RightJustifiedRow = ({ children }: Props) => {
  const { classes } = useStyles();

  return <div className={classes.row}>{children}</div>;
};

export const useStyles = createStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'right',
    '> *': {
      flex: 0,
    },
    marginBottom: '15px',
  },
}));

export default RightJustifiedRow;
