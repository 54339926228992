import {
  Menu,
  Group,
  Button,
  Tooltip,
  TextInput,
  useMantineTheme,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  EyeOpenIcon,
  EyeClosedIcon,
  ChevronDownIcon,
  PlusSignIcon,
  DownloadIcon,
} from 'assets/svg';
import { useStyles } from './styles';
import {
  DepositAccount,
  WireInstructionsModel,
} from 'services/flexbase/banking.model';
import useModal from 'components/modal/modal-hook';
import { downloadWireInstructions } from './utilities';
import { flexbaseBankingClient } from 'services/flexbase-client';

interface Props {
  bankAccount: DepositAccount;
  onSubmit: (accountId: string, nickName: string) => void;
}

const AccountDetails = ({ bankAccount, onSubmit }: Props) => {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const clipboard = useClipboard();
  const { closeAllModals } = useModal();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wireInstructions, setWireInstructions] =
    useState<WireInstructionsModel>();
  const [accountForm, setAccountForm] = useState(
    bankAccount.nickName ?? bankAccount.name,
  );
  const [error, setError] = useState<string | null>(null);
  const [editAccountNickName, setEditAccountNickName] = useState(false);
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);

  const onEditSubmit = async () => {
    try {
      setLoading(true);
      onSubmit(bankAccount.id, accountForm);
      setEditAccountNickName(false);
      showNotification({
        title: 'Success',
        message: 'Account nickname updated successfully!',
        color: 'flexbase-teal',
      });
    } catch (e) {
      showNotification({
        title: 'Error',
        message: 'An error occurred while updating the account nickname.',
        color: 'failure',
      });
    } finally {
      setLoading(false);
    }
  };

  const getWireInstructions = async (_accountId: string) => {
    try {
      const instructions = await flexbaseBankingClient.getWireInstructions(
        bankAccount.id,
      );
      setWireInstructions(instructions);
    } catch (e) {
      console.error('Unable to load wire instructions for this account.', e);
      setError('Unable to load bank info for this account.');
    }
  };

  const companyAddress = (address: string) => {
    if (!address || address === '') {
      return '';
    }
    const parts = address?.split(', ');
    if (parts.length < 2) {
      return address;
    }
    const [street, rest] = parts;
    const [city, state, zipCode] = rest.split(' ');

    return (
      <div>
        {street}
        <br />
        {city}, {state} {zipCode}
      </div>
    );
  };

  useEffect(() => {
    getWireInstructions(bankAccount.id);
  }, []);

  return (
    <div className={classes.accountDetailsContainer}>
      <div className={classes.header}>
        <div className={classes.accountDetailsName}>
          {editAccountNickName ? 'Edit account nickname' : accountForm}
        </div>
        <span className={classes.accountDetailsType}>
          {bankAccount.accountType} ••{bankAccount.accountNumber.slice(-4)}
        </span>
      </div>

      {editAccountNickName && (
        <div className={classes.padding}>
          <TextInput
            my={10}
            value={accountForm}
            onChange={(e) => setAccountForm(e.target.value)}
          />
          <div className={classes.buttonsContent}>
            <Button
              onClick={() => setEditAccountNickName(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              variant="light"
              onClick={() => onEditSubmit()}
              {...{ loading }}
            >
              Save
            </Button>
          </div>
        </div>
      )}

      <div
        className={classes.padding}
        style={{ opacity: editAccountNickName ? 0.5 : 1 }}
      >
        <div>
          <div className={classes.accountDetailsLabel}>Account number</div>
          <div className={classes.accountDetailsInput}>
            {displayAccountNumber ? (
              <div className={classes.rowContent}>
                <Tooltip
                  withArrow
                  data-testid="account-tooltip-icon"
                  label={clipboard.copied ? 'Copied' : 'Click to copy'}
                >
                  <div
                    onClick={() => clipboard.copy(bankAccount.accountNumber)}
                  >
                    {bankAccount.accountNumber}
                  </div>
                </Tooltip>
                <div
                  onClick={() => setDisplayAccountNumber(false)}
                  className={cx(classes.rowContent, classes.cursor)}
                >
                  <EyeClosedIcon
                    width={20}
                    height={20}
                    color={theme.fn.themeColor('neutral', 4)}
                  />
                </div>
              </div>
            ) : (
              <div className={classes.rowContent}>
                <div>
                  {' '}
                  •••• •••• •••• {bankAccount.accountNumber?.slice(-4)}
                </div>
                <div
                  onClick={() => setDisplayAccountNumber(true)}
                  className={cx(classes.rowContent, classes.cursor)}
                >
                  <EyeOpenIcon
                    width={20}
                    height={20}
                    color={theme.fn.themeColor('neutral', 4)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div className={classes.accountDetailsLabel}>Bank</div>
          <div>
            {error ? (
              <div>{error}</div>
            ) : (
              <>
                <Text fw={400} size={20}>
                  {wireInstructions?.beneficiaryBankName}
                </Text>
                <Text fw={400} size={20}>
                  {companyAddress(
                    wireInstructions?.beneficiaryBankAddress || '',
                  )}
                </Text>
              </>
            )}
          </div>
        </div>

        <Group mt={40} spacing="xl" position="apart">
          {bankAccount.status === 'Open' && (
            <>
              <div
                className={cx(classes.rowContent, classes.actionConent)}
                onClick={() =>
                  navigate('/banking/move-funds', {
                    state: {
                      account: { ...bankAccount, plaidOrDeposit: 'deposit' },
                    },
                  })
                }
              >
                <PlusSignIcon width={20} />
                <div>Add funds</div>
              </div>

              <div
                className={cx(classes.rowContent, classes.actionConent)}
                onClick={() => downloadWireInstructions(bankAccount.id)}
              >
                <DownloadIcon width={20} />
                <div>Wire Details</div>
              </div>
            </>
          )}
          <Menu position="bottom-end" opened={open} onChange={setOpen}>
            <Menu.Target>
              <div className={cx(classes.rowContent, classes.actionConent)}>
                <div>More</div>
                <ChevronDownIcon
                  width={20}
                  style={{
                    transition: '0.2s ease-out',
                    transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
                  }}
                />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item onClick={() => setEditAccountNickName(true)}>
                Edit account nickname
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  closeAllModals();
                  navigate({
                    pathname: `/banking/statements`,
                    search: createSearchParams({
                      accountId: `${bankAccount.id}`,
                    }).toString(),
                  });
                }}
              >
                Statements and Documents
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  closeAllModals();
                  navigate({
                    pathname: `/banking/transactions`,
                    search: createSearchParams({
                      account: `${bankAccount.accountNumber}`,
                    }).toString(),
                  });
                }}
              >
                Transactions
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </div>
    </div>
  );
};

export default AccountDetails;
