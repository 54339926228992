import {
  Alert,
  createStyles,
  LoadingOverlay,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { RedAlertIcon } from 'assets/svg';
import { useEffect, useRef } from 'react';

export const useStyles = createStyles((theme) => ({
  mainFont: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.colors.neutral[7],
  },
  modal2FAContent: {
    width: '100%',
    maxWidth: '400px',
  },
}));

interface Props {
  token: string;
  retry: boolean;
  errorMsg: string;
  handleOnChange: (code: string) => Promise<void>;
  resendCode: () => Promise<void>;
  loading?: boolean;
}

const TwoFactorAuth = ({
  token,
  errorMsg,
  retry,
  handleOnChange,
  resendCode,
  loading = false,
}: Props) => {
  const AuthInputRef = useRef<HTMLInputElement>(null);
  const { classes } = useStyles();

  useEffect(() => {
    if (retry && AuthInputRef.current) {
      AuthInputRef.current.value = '';
    }
  }, [retry]);

  return (
    <div className={classes.modal2FAContent}>
      <LoadingOverlay visible={loading} />
      <TextInput
        autoFocus
        type="numeric"
        ref={AuthInputRef}
        onChange={(e) => handleOnChange(e.currentTarget.value)}
        data-testid="input-two-factor-code"
      />
      {errorMsg !== '' && (
        <Alert my={10} color="red" icon={<RedAlertIcon />}>
          {errorMsg}
        </Alert>
      )}

      <UnstyledButton className={classes.mainFont} onClick={resendCode}>
        Resend code
      </UnstyledButton>
    </div>
  );
};

export default TwoFactorAuth;
