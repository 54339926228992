import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Grid, UnstyledButton, Progress } from '@mantine/core';

import { CloseIcon, FlexIcon } from 'assets/svg';
import IAddress from 'states/user/address';
import ChooseReason from './steps/choose-reason';
import useModal from 'components/modal/modal-hook';
import { useStyles } from './cancel-debit-card-styles';
import FlexbaseStepper from 'components/stepper/stepper';
import ConfirmCancellation from './steps/confirm-cancellation';
import { flexbaseBankingClient } from 'services/flexbase-client';
import CancelDebitCardError from './steps/cancel-debit-card-error';
import ConfirmShippingAddress from './steps/confirm-shipping-address';
import { CardByUser, CardStatus } from 'services/flexbase/banking.model';
import ModalSuccess from 'areas/team-members/modal-success/modal-success';

interface Props {
  card: CardByUser;
  setCardStatus: (x: string) => void;
  updateDebitCardsList: (x: CardByUser, y?: boolean) => void;
}

enum Step {
  CHOOSE_REASON,
  CONFIRM,
  CONFIRM_ADDRESS,
  SUCCESS,
  ERROR,
}

const CancelDebitCard = ({
  card,
  setCardStatus,
  updateDebitCardsList,
}: Props) => {
  const { classes } = useStyles();
  const { closeAllModals } = useModal();
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const [step, setStep] = useState(Step.CHOOSE_REASON);
  const [issueNewCard, setIssueNewCard] = useState('yes');
  const useMobileView = useMediaQuery('(max-width: 767px)');

  const status: any = {
    Lost: 'lost',
    Stolen: 'stolen',
    Damaged: 'cancelled',
    Other: 'cancelled',
  };

  const updateCardStatus = async (status: CardStatus) => {
    try {
      setLoading(true);
      const result = await flexbaseBankingClient.cardStatus(card.id, status);
      if (result.success && result.card) {
        setCardStatus(result.card.status);
        updateDebitCardsList(result.card);
        setStep(Step.SUCCESS);
      } else {
        setStep(Step.ERROR);
      }
      return result;
    } catch (error) {
      console.error('Error updating card status', error);
      setStep(Step.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const cancelCard = async () => {
    if (issueNewCard === 'no') {
      await updateCardStatus(status[isSelected]);
    } else {
      setStep(Step.CONFIRM_ADDRESS);
    }
  };

  const confirmAddress = async (address: IAddress) => {
    try {
      setLoading(true);
      const response = await updateCardStatus(status[isSelected]);
      if (response?.success) {
        const cardForm = {
          issuer: 'unit-co',
          cardType: card.cardSubtype,
          depositAccountId: card.depositAccountId,
          limits: card.expensesTypes ?? null,
          cardName: card.cardName ?? null,
          shippingAddress: {
            address: address.line1,
            addressLine2: address.line2,
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            country: address.country ?? 'US',
          },
        };
        const result = await flexbaseBankingClient.issueDebitCard(
          card.userId,
          cardForm,
        );
        if (result.success && result.card) {
          updateDebitCardsList(result.card, true);
          setStep(Step.SUCCESS);
        } else {
          setStep(Step.ERROR);
        }
      }
    } catch (error) {
      console.error('Error issuing a new card', error);
      setStep(Step.ERROR);
    } finally {
      setLoading(false);
    }
  };

  let comp;
  switch (step) {
    case Step.CHOOSE_REASON:
      comp = (
        <ChooseReason
          {...{ card, isSelected, setIsSelected }}
          nextStep={() => setStep(Step.CONFIRM)}
          onBack={closeAllModals}
        />
      );
      break;
    case Step.CONFIRM:
      comp = (
        <ConfirmCancellation
          {...{ card, loading, issueNewCard, setIssueNewCard }}
          nextStep={cancelCard}
          onBack={() => setStep(Step.CHOOSE_REASON)}
        />
      );
      break;
    case Step.CONFIRM_ADDRESS:
      comp = (
        <ConfirmShippingAddress
          {...{ card, loading }}
          nextStep={(address: IAddress) => confirmAddress(address)}
          onBack={() => setStep(Step.CONFIRM)}
        />
      );
      break;
    case Step.SUCCESS:
      comp = (
        <ModalSuccess
          backTo="debit cards"
          closeModal={closeAllModals}
          title={
            issueNewCard === 'yes'
              ? 'The new card is on the way'
              : 'The card has been cancelled'
          }
          description={
            issueNewCard === 'yes'
              ? 'We’ve deactivated the old debit card, and the new card will arrive within the next 4-6 days.'
              : undefined
          }
        />
      );
      break;
    case Step.ERROR:
      comp = <CancelDebitCardError onClick={closeAllModals} />;
      break;
  }

  if (useMobileView) {
    return (
      <div className={classes.cancelDebitCard}>
        <>
          <UnstyledButton className="close-button" onClick={closeAllModals}>
            <CloseIcon color="#979797" />
          </UnstyledButton>
          {step < Step.SUCCESS && (
            <Progress radius="sm" value={((step + 1) * 100) / 3} />
          )}
        </>
        <div className="content">{comp}</div>
      </div>
    );
  }

  return (
    <Grid className={classes.cancelDebitCard}>
      <Grid.Col span={3}>
        <FlexIcon width={90} />
        {step < Step.SUCCESS && (
          <FlexbaseStepper
            activeStep={step}
            stepsData={
              issueNewCard === 'yes'
                ? ['Choose Reason', 'Confirm', 'Confirm Address']
                : ['Choose Reason', 'Confirm']
            }
          />
        )}
      </Grid.Col>
      <Grid.Col className="content" span={6}>
        <div className={classes.card}>{comp}</div>
      </Grid.Col>
      <Grid.Col className="close" span={3}>
        <UnstyledButton onClick={closeAllModals}>
          <CloseIcon width={34} color="#979797" />
        </UnstyledButton>
      </Grid.Col>
    </Grid>
  );
};

export default CancelDebitCard;
