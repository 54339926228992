import {
  Button,
  Select,
  SelectItemProps,
  Text,
  TextInput,
} from '@mantine/core';
import { forwardRef, useState } from 'react';
import { useStyles } from './team-member-header.styles';
import { useMediaQuery } from '@mantine/hooks';
import { InvitePeople, SearchIcon } from 'assets/svg';
import InviteUserModal from 'areas/invite-user/modal/invite-user-modal';
import useModal from 'components/modal/modal-hook';
import { IsAdmin } from '../../../states/application/product-onboarding';
import { useRecoilValue } from 'recoil';

interface ItemProps extends SelectItemProps {
  label: string;
  disabled: boolean;
  description: string;
  onClick: () => void;
}

export interface Props {
  onFilter: (e: any) => void;
  filterText: string;
  pendingInvitationsClicked?: boolean;
  updatePendingInvitationClicked?: () => void;
  updateCheckingPending?: () => void;
  getAllUsers?: () => void;
}

const TeamMembersHeader = ({
  onFilter,
  filterText,
  pendingInvitationsClicked,
  updatePendingInvitationClicked,
  updateCheckingPending,
  getAllUsers,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [searchFocused, setSearchFocused] = useState(false);
  const { classes } = useStyles({ searchFocused });
  const { openTransparentModal, closeAllModals } = useModal();
  const isAdmin = useRecoilValue(IsAdmin);
  const [options, setOptions] = useState(true);

  const ActionsSelect = forwardRef<HTMLButtonElement, ItemProps>(
    ({ label, onClick, disabled }, ref) => (
      <Button ref={ref} className={classes.actions} onClick={onClick}>
        <Text size={'sm'}>{label}</Text>
      </Button>
    ),
  );

  ActionsSelect.displayName = 'ActionsSelect';

  return (
    <div className={isMobile ? classes.baseContainerSm : classes.baseContainer}>
      <Text className={classes.title}>Users</Text>

      <div
        style={{
          display: isMobile ? '' : 'flex',
          gap: '1rem',
        }}
      >
        <TextInput
          icon={
            <SearchIcon
              className={classes.searchIcon}
              style={{ marginLeft: '6px', width: 20, height: 20 }}
            />
          }
          w={isMobile ? '100%' : '18rem'}
          placeholder={'Search team members'}
          value={filterText}
          onChange={onFilter}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
        ></TextInput>
        {isAdmin &&
          updatePendingInvitationClicked &&
          updateCheckingPending &&
          getAllUsers && (
            <>
              {isMobile ? (
                <Select
                  placeholder="Actions"
                  mt={'1rem'}
                  w="100%"
                  itemComponent={ActionsSelect}
                  data={
                    options
                      ? [
                          {
                            value: pendingInvitationsClicked
                              ? 'View All Members'
                              : 'Pending invites',
                            label: pendingInvitationsClicked
                              ? 'View All Members'
                              : 'Pending invites',
                            onClick: () => {
                              updatePendingInvitationClicked();
                              updateCheckingPending();
                            },
                          },
                          {
                            value: 'Invite Team Members',
                            label: 'Invite Team Members',
                            onClick: () => {
                              setOptions(false);
                              openTransparentModal(
                                <InviteUserModal
                                  closeModal={() => {
                                    closeAllModals();
                                    setOptions(true);
                                  }}
                                  refreshTeamMembers={async () => {
                                    await getAllUsers();
                                  }}
                                />,
                              );
                            },
                          },
                        ]
                      : []
                  }
                />
              ) : (
                <>
                  {' '}
                  {/* <Button
                    variant="light"
                    leftIcon={
                      pendingInvitationsClicked ? (
                        <TeamsIconActive fill="white" style={{ padding: 3 }} />
                      ) : (
                        <ClockIcon />
                      )
                    }
                    onClick={() => {
                      updatePendingInvitationClicked();
                      updateCheckingPending();
                    }}
                  >
                    {pendingInvitationsClicked
                      ? 'View All Members'
                      : 'Pending invites'}
                  </Button> */}
                  <Button
                    variant="light"
                    leftIcon={
                      <InvitePeople fill="white" width={13} height={13} />
                    }
                    onClick={() => {
                      openTransparentModal(
                        <InviteUserModal
                          closeModal={closeAllModals}
                          refreshTeamMembers={async () => {
                            await getAllUsers();
                          }}
                        />,
                      );
                    }}
                  >
                    Invite Team Members
                  </Button>
                </>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default TeamMembersHeader;
