import { useMantineTheme } from '@mantine/core';
import { useWindowSize } from '@react-hook/window-size';
import ReactConfetti from 'react-confetti';

const FlexbaseConfetti = ({ className }: { className?: string }) => {
  const theme = useMantineTheme();
  const [width, height] = useWindowSize();

  return (
    <ReactConfetti
      width={width}
      height={height}
      initialVelocityY={4}
      numberOfPieces={200}
      recycle={false}
      tweenDuration={10000}
      colors={[theme.fn.primaryColor(), '#FFF2F0']}
      className={className}
    />
  );
};

export default FlexbaseConfetti;
