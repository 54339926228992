import { Group, Text } from '@mantine/core';
import { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
}

const AccountSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{description}</Text>
          <Text size="xs" opacity={0.65}>
            {label}
          </Text>
        </div>
      </Group>
    </div>
  ),
);

AccountSelectItem.displayName = 'AccountSelectItem';

export default AccountSelectItem;
