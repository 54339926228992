import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  noCreditAccount: {
    borderRadius: '8px',
    padding: '20px 46px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    backgroundImage: 'url(/images/credit-card-apply.png)',

    h2: {
      fontWeight: 400,
      fontSize: '56px',
      lineHeight: '66px',
      margin: '24px 0px',
    },

    '.black-banner': {
      color: '#fff',
      padding: '10px',
      fontSize: '10px',
      lineHeight: '12px',
      borderRadius: '60px',
      width: 'fit-content',
      backgroundColor: '#000',
      textTransform: 'uppercase',
    },

    '.info': {
      fontSize: '16px',
      lineHeight: '24px',
    },

    '.card': {
      padding: '20px',
      marginBottom: '200px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      textAlign: 'center',
      minHeight: 235,
      opacity: 0.9,
      '.icon-container': {
        width: '56px',
        height: '56px',
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        borderRadius: '30px',
        justifyContent: 'center',
        backgroundColor: '#F6F7F9',
      },
      p: {
        fontWeight: 500,
        fontsize: '25px',
        lineHeight: '30px',
      },
      '> div': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
}));
