import { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { notifications } from '@mantine/notifications';

type Props = { children: ReactNode };

interface State {
  hasError: boolean;
  errorMessage: string;
}

class AppErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(errorInfo);
    console.error('Caught error in error boundary:', error, errorInfo);
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (!prevState.hasError && this.state.hasError) {
      notifications.show({
        color: 'critical.2',
        title: 'Unexpected Error',
        message: 'An unexpected error occurred.',
      });
    }
  }

  public render() {
    return this.props.children;
  }
}

export default AppErrorBoundary;
