import { useStyles } from '../styles';
import { Text } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { TreasuryManagementState } from '../treasury-management.state';
import { formatCurrency } from 'utilities/formatters/format-currency';

// A widget can be a card, but a card is not a widget.
// Widget: Responsible for its own state, designed to be used anywhere with no props
// Card: A layout component responsible for formatted display of content only
const CashBalanceWidget = () => {
  const { classes, cx } = useStyles();
  const { totalAvailableBalance, totalAccounts } = useRecoilValue(
    TreasuryManagementState,
  );

  return (
    <div className={classes.card}>
      <Text size={16} color="#5F5F5F" weight={500}>
        Cash balance (all accounts)
      </Text>
      <Text size={26} color="#000000" weight={500} className={classes.pp}>
        {totalAccounts > 0 ? formatCurrency(totalAvailableBalance) : 'Pending'}
      </Text>
      <Text
        className={cx(classes.pp, classes.endContent)}
        color="#5F5F5F"
        weight={500}
      >
        You have{' '}
        <Text
          className={classes.pp}
          color="#5F5F5F"
          component={'span'}
          weight={700}
        >
          {totalAccounts}
        </Text>{' '}
        account{totalAccounts === 1 ? '' : 's'}
      </Text>
    </div>
  );
};

export default CashBalanceWidget;
