import {
  createStyles,
  Group,
  Popover,
  Radio,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { FilterIcon } from 'assets/svg';

interface Props {
  search: string;
  isFilter: boolean;
  searchCallback: any;
  filterCallback: any;
}

const useStyles = createStyles((theme) => ({
  parentContainer: {
    width: '589px',
  },
  navbarLabel: {
    padding: 10,
    width: 170,
    height: 40,
    borderRadius: 8,
    fontWeight: 500,
    fontSize: 16,
    color: '#4B4B4B',
    background: theme.colors.tertiary[1],
    cursor: 'pointer',
  },
}));

const CustomMantineStyles = (primaryColor?: string) => {
  return {
    filter: {
      root: {
        borderRadius: '8px',
      },
      wrapper: {
        marginRight: -4,
        borderRadius: '8px',
      },
      body: {
        boxShadow: 'none',
        border: `1px solid ${primaryColor}`,
        borderRadius: '8px',
      },
      inner: {
        color: '#5F5F5F',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
      },
    },

    radio: {
      radio: {
        borderColor: '#ebf0f7 !important',
        backgroundColor: '#f9fbfe !important',
      },
      icon: { color: '#1ec98b' },
      label: { color: '#5F5F5F', fontSize: '14px', fontWeight: 500 },
    },
  };
};

const Filter = ({
  search,
  isFilter,
  searchCallback,
  filterCallback,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Popover
      opened={isFilter}
      onClose={() => filterCallback(false)}
      zIndex={1}
      position={'bottom-end'}
      radius={8}
      width={589}
      transitionProps={{ transition: 'pop-top-right', duration: 500 }}
      styles={{
        dropdown: {
          ...CustomMantineStyles().filter.root,
          ...CustomMantineStyles().filter.wrapper,
          ...CustomMantineStyles(theme.fn.primaryColor()).filter.body,
          ...CustomMantineStyles().filter.inner,
        },
      }}
    >
      <Popover.Target>
        <div
          onClick={() => {
            searchCallback(false);
            filterCallback(false);
          }}
          style={{ cursor: 'pointer', marginTop: 4 }}
        >
          <FilterIcon
            style={{
              paddingRight: '6px',
              position: 'relative',
              zIndex: 2,
            }}
            fill={isFilter ? theme.fn.primaryColor() : '#979797'}
            width={25}
            height={25}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Group align="start" noWrap className={classes.parentContainer}>
          <Stack>
            {/* TODO: Replace with NavLink when upgrading to Mantine 5.x */}
            <div className={classes.navbarLabel}>Card status</div>
          </Stack>
          <div>
            <Radio.Group
              size="sm"
              onChange={(value) => searchCallback(value)}
              value={search}
              styles={{ ...CustomMantineStyles().radio }}
            >
              <Stack spacing="sm">
                <Radio label="All" value="" />
                <Radio label="Active/Pending" value="active/pending" />
                <Radio label="Active" value="active" />
                <Radio label="Pending Activation" value="issued" />
                <Radio label="Canceled" value="terminated" />
                <Radio label="Frozen" value="suspended" />
              </Stack>
            </Radio.Group>
          </div>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Filter;
