import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  counterpartyTypeTitle: {
    fontWeight: 400,
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px',
    fontFamily: 'PP Neue Montreal',
  },
  counterpartyDetail: {
    fontWeight: 500,
    fontSize: '14px',
  },
  input: {
    border: '1px solid #D1D5D8',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    height: '50px',
    backgroundColor: '#FFFFFF',
  },
  inputLabel: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#374151',
    marginBottom: '10px',
  },
  control: {
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
  },
}));
