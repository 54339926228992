import { createStyles } from '@mantine/core';

export const contactFormStyles = createStyles(() => ({
  innerContent: {
    backgroundColor: '#FFFFFF',
    '@media(max-width: 767px)': {
      marginTop: '8rem',
      padding: '10px',
    },
  },
  title: {
    textAlign: 'start',
    fontSize: '28px',
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
  },
}));
