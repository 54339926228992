import { Container, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { CardByUser } from 'services/flexbase/banking.model';

interface Props {
  card: CardByUser;
}

const BillingAddress = ({ card }: Props) => {
  const useMobileView = useMediaQuery('(max-width: 767px)');

  return (
    <Container p={0} mx={useMobileView ? 20 : 50}>
      <Text mb={8} size={14}>
        Billing address
      </Text>
      <Text size={20}>{card.shipTo?.street}</Text>
      <Text size={20}>{card.shipTo?.street2}</Text>
      <Text size={20}>
        {card.shipTo?.city}, {card.shipTo?.state} {card.shipTo?.postalCode}
      </Text>
    </Container>
  );
};

export default BillingAddress;
