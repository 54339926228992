import IAddress from 'states/user/address';

export function formatZip(value: string): string {
  if (!value) {
    return '';
  }

  const zip = value.replace(/\D/g, '');

  if (value.endsWith('-')) {
    return `${zip}-`;
  }

  if (zip.length > 5) {
    return `${zip.substring(0, 5)}-${zip.slice(5, 9)}`;
  }

  return zip;
}

export function formatFullAddressToString(value: IAddress): string {
  if (!value) {
    return '';
  }

  return `${value.line1}${value.line2 ? ' ' + value.line2 : ''}, ${
    value.city
  } ${value.state} ${value.postalCode}`;
}
