import { createStyles } from '@mantine/core';

const usePlotlyGlobalStyles = createStyles((theme) => ({
  '.gtitle': {
    fontWeight: 'bold',
  },
  something: {
    '.svg-container': {
      background: theme.fn.themeColor('neutral', 0),
    },
    svg: {
      background: 'unset !important',

      '.main-svg': {
        background: 'unset',
      },
      // background: theme.fn.themeColor('neutral', 0),
    },
  },
}));

export default usePlotlyGlobalStyles;
