import { UseFormReturnType } from '@mantine/form';
import { Link, useNavigate } from 'react-router-dom';
import {
  Anchor,
  Checkbox,
  createStyles,
  Group,
  PasswordInput,
  TextInput,
} from '@mantine/core';

import AuthContent from 'components/login/auth-content';

type FormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

interface LoginProps {
  errorMsg: string;
  onSubmit: () => void;
  loginForm: UseFormReturnType<FormValues>;
}

const Login = ({ loginForm, errorMsg, onSubmit }: LoginProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <AuthContent
      screen="log-in"
      onSubmit={onSubmit}
      errorMsg={errorMsg}
      submitButtonLabel="Log in"
      title="Log in to your Flex account"
      onGoback={() => navigate('/register')}
    >
      <TextInput
        label="Email"
        id="username-email"
        classNames={classes}
        placeholder="Enter your email"
        data-testid="input-username-email"
        {...loginForm.getInputProps('email')}
      />
      <PasswordInput
        id="password"
        label="Password"
        classNames={classes}
        data-testid="input-password"
        placeholder="Enter your password"
        {...loginForm.getInputProps('password')}
      />

      <Group grow mt="xl">
        <Checkbox
          color="something.2"
          id="remember-user"
          label="Remember email"
          classNames={{ label: classes.label }}
          data-testid="checkbox-remember-username"
          {...loginForm.getInputProps('rememberMe', { type: 'checkbox' })}
        />
        <Anchor
          underline
          size="sm"
          align="right"
          component={Link}
          to="/forgot-password"
          c="primarySecondarySuccess.2"
          data-testid="button-forgot-password"
        >
          Forgot Password
        </Anchor>
      </Group>
    </AuthContent>
  );
};

export default Login;

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.colors.critical[2],
  },
}));
