import { Button, Loader, Text, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { FaExclamationCircle } from 'react-icons/fa';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useStyles } from './styles';

type Props = {
  connected: boolean;
  loading: boolean;
};

const QbooksActionButton = ({ connected, loading }: Props) => {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();

  return (
    <Button
      className={cx(classes.btnConnect, { [classes.btnConnected]: connected })}
      onClick={async () => {
        try {
          const res = await flexbaseOnboardingClient.qbooksLogin();
          let parameters = 'location=1,width=800,height=650';
          parameters +=
            ',left=' +
            (screen.width - 800) / 2 +
            ',top=' +
            (screen.height - 650) / 2;
          window.open(res.url, 'connectPopup', parameters);
        } catch (e) {
          showNotification({
            title: 'Failure',
            message: 'Unable to retrieve authentication link',
            color: 'red',
            icon: <FaExclamationCircle />,
          });
        }
      }}
    >
      {loading ? (
        <Loader color={theme.fn.primaryColor()} variant="dots" />
      ) : (
        <Text
          className={cx(classes.connectText, {
            [classes.connectedText]: connected,
          })}
        >
          {connected ? 'Connected' : 'Connect'}
        </Text>
      )}
    </Button>
  );
};

export default QbooksActionButton;
