import { Divider } from '@mantine/core';
import { useRecoilValue } from 'recoil';

import DebitCard from './debit-card';
import { useStyles } from '../../styles';
import BillingAddress from './billing-address';
import { CardByUser } from 'services/flexbase/banking.model';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { DepositAccountsState } from 'areas/banking/states/deposit-accounts';

interface Props {
  limit: number;
  card: CardByUser;
  limitType: string;
  cardStatus: string;
}

const TerminatedDebitCard = ({ card, limitType, cardStatus, limit }: Props) => {
  const { classes } = useStyles();
  const depositAccounts = useRecoilValue(DepositAccountsState);
  const cardDepositAccount = depositAccounts.find(
    (account) => account.id === card.depositAccountId,
  );

  return (
    <div className={classes.cardDetails}>
      <div>
        <div className="row-content">
          <div>
            <span>{card.cardName || 'Office Card'}</span>
            <p style={{ fontSize: '12px' }}>{card.holder}</p>
          </div>
        </div>

        <DebitCard {...{ card, cardStatus }} />
      </div>

      {card.cardType === 'PHYSICAL' && (
        <>
          <Divider style={{ margin: '20px 0px' }} />
          <BillingAddress {...{ card }} />
        </>
      )}

      <Divider style={{ margin: '20px 0px' }} />

      <div>
        <div className="row-content">
          <span>Account</span>
          <p>{cardDepositAccount?.nickName ?? cardDepositAccount?.name}</p>
        </div>
        <div className="row-content" style={{ marginTop: '5px' }}>
          <span>Card type</span>
          <p>{card.cardSubtype.includes('Virtual') ? 'Virtual' : 'Physical'}</p>
        </div>
        <div className="row-content">
          <span>POS Limit</span>
          <p>
            {limitType === 'unlimited' ? 'Unlimited' : formatCurrency(limit)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TerminatedDebitCard;
