export const backgroundLight = '#ffffff';
export const background = '#fffff';
export const backgroundDark = '#f6f5f7';
export const backgroundLightGray = '#f3f4f6';

export const foregroundLight = 'rgba(107, 114, 128, 0.6)';
export const foreground = 'rgb(107, 114, 128)';
export const foregroundDark = '#0B1130';
export const foregroundGray = '#6B7280';

export const highlighted = 'rgb(255, 87, 69)';
export const highlightedLight = 'rgb(255, 87, 69, 0.7)';
export const action = 'rgb(34,200, 132)';
export const actionLight = 'rgba(34,200, 132, 0.2)';
export const actionHover = 'rgba(34,200, 132, 0.8)';
export const blueAction = '#39A0FF';
