import { Text, createStyles } from '@mantine/core';
import { UploadFileIcon } from 'assets/svg';

const DropzoneChildren = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.dropZonePlaceholderContainer}>
      <UploadFileIcon width={36} />
      <Text className={classes.dropZonePlaceholder}>
        Drag and drop file, or&nbsp;<span>browse</span>&nbsp;to add document
        (PDF/JPG/PNG)
      </Text>
    </div>
  );
};

export default DropzoneChildren;

const useStyles = createStyles((theme) => ({
  dropZonePlaceholderContainer: {
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dropZonePlaceholder: {
    fontWeight: 400,
    fontSize: '12px',
    color: theme.fn.primaryColor(),
    '> span': {
      display: 'inline',
      color: theme.fn.themeColor('primarySecondarySuccess', 2),
    },
  },
}));
