import { Button, Text } from '@mantine/core';
import { PayFromBox } from '@flexbase-eng/web-components';
import { useConfirmAndReviewStyles } from './review-and-pay.styles';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { PlaidAccount } from 'areas/banking/move-funds/move-funds.model';
import { BankIconNeue } from '../../../assets/svg';

type Props = {
  paymentAmount: string;
  plaidAccount: PlaidAccount;
  onConfirmClick: () => void;
  onGoBackClick: () => void;
};

const CardPaymentReview = ({
  paymentAmount,
  plaidAccount,
  onConfirmClick,
  onGoBackClick,
}: Props) => {
  const { classes } = useConfirmAndReviewStyles();
  return (
    <>
      <Text className={classes.subtitle}>
        Once confirmed, your payment will be applied to your Flexbase card.
      </Text>

      <div>
        <Text className={classes.inputTitle}>Payment Amount</Text>
        <Text
          className={classes.paymentAmtBold}
          data-testid="text-payment-amount"
        >
          {paymentAmount}
        </Text>
      </div>

      <div>
        <Text className={classes.inputTitle}>Pay From:</Text>
        <PayFromBox
          accountMask={
            plaidAccount.last4 || getPaddedAccountMask(plaidAccount.account, 4)
          }
          accountName={plaidAccount.bankName}
          accountType={plaidAccount.accountType}
          review
          icon={<BankIconNeue />}
          classNames={{ iconAvatar: classes.iconAvatar }}
        />
      </div>
      <Text className={classes.utc}>
        Payments to Flexbase initiated after 11:59 p.m. UTC will be processed
        the next business day.
      </Text>
      <Button
        // className={classes.confirm}
        onClick={() => onConfirmClick()}
        size="lg"
        data-testid="confirm-payment-button"
      >
        Confirm Payment
      </Button>
      <Button
        variant="outline"
        // className={classes.goBack}
        onClick={() => onGoBackClick()}
        size="lg"
        data-testid="go-back-button"
      >
        Go back
      </Button>
      <Text className={classes.authorize}>
        By clicking Confirm Payment, I authorize Flexbase to initiate the
        transaction detailed above.
      </Text>
    </>
  );
};

export default CardPaymentReview;
