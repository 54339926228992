import { atom } from 'recoil';
import { Filter } from './filter-models';

export const FilterListState = atom<Filter[]>({
  key: 'filterListState',
  default: [],
});

export const TIME_FRAME = [
  { label: 'All time', value: 'allTime' },
  { label: 'Custom', value: 'custom' },
  { label: 'Last week', value: 'lastWeek' },
  { label: 'This month', value: 'thisMonth' },
  { label: 'Last month', value: 'lastMonth' },
];

export const TimeFrameState = atom({
  key: 'timeFrameState',
  default: 'allTime',
});
