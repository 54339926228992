import { useEffect, useState } from 'react';
import { Employee } from 'states/employees/employee';
import flexbaseClient from 'services/flexbase-client';
import { useRecoilValue } from 'recoil';
import { TreasuryManagementState } from '../treasury-management.state';
import { setFilterColumn } from 'utilities/filter/filter-column';
import { TreasuryActivity } from 'services/flexbase/banking.model';
import { FilterOption } from 'components/table/table-filter';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import { Button, LoadingOverlay } from '@mantine/core';
import { PlusSignIcon } from 'assets/svg';
import TableContainerWithSearchFilter from 'components/table/table-container';
import TreasuryActivitiesTable from '../components/treasury-tables/treasury-activities-table';
import { useNavigate } from 'react-router-dom';
import RightJustifiedRow from 'components/layouts/right-justified-row';

const MoveFundsActivities = () => {
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(false);
  const [filteredColumn, setFilteredColumn] = useState('type');
  const [filteredValue, setFilteredValue] = useState('');
  const [searchActivity, setSearchActivity] = useState('');

  const { activities } = useRecoilValue(TreasuryManagementState);

  const activitiesWithUserName = activities.map((activity) => {
    const user = allUsers.find((user) => user.id === activity.userId);
    if (user) {
      return {
        ...activity,
        byUser: user.firstName + ' ' + user.lastName,
      };
    }
    return activity;
  });

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const companyUsers = await flexbaseClient.getEmployees();
      if (companyUsers && companyUsers.length > 0) {
        setAllUsers(companyUsers);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const filteredItems = activitiesWithUserName.filter(
    (activity) =>
      (setFilterColumn(activity.activity, searchActivity) ||
        setFilterColumn(activity.byUser, searchActivity) ||
        setFilterColumn(activity.status, searchActivity)) &&
      (!filteredValue ||
        setFilterColumn(
          activity[filteredColumn as keyof TreasuryActivity],
          filteredValue,
        )),
  );

  const setFilter = (column: string, value: string) => {
    setFilteredColumn(column);
    setFilteredValue(value);
  };

  const allStatus = activitiesWithUserName.map((activity) => activity.status);

  const allActivity = activitiesWithUserName.map(
    (activity) => activity.activity,
  );

  const filterOptions: FilterOption[] = [
    {
      title: 'Activity',
      column: 'activity',
      options: allActivity.map((activity) => ({
        value: activity,
        label: activity,
      })),
    },
    {
      title: 'Status',
      column: 'status',
      options: allStatus.map((status) => ({
        value: status,
        label: capitalizeOnlyFirstLetter(status),
      })),
    },
  ];

  return (
    <>
      <RightJustifiedRow>
        <Button
          variant="outline"
          onClick={() => {
            navigate(`/accounts/move-funds`);
          }}
          leftIcon={<PlusSignIcon width={12} height={12} />}
        >
          New transfer
        </Button>
      </RightJustifiedRow>
      <LoadingOverlay visible={loading} />
      <TableContainerWithSearchFilter
        title="Transfer History"
        searchPlaceholder="Search transfer history"
        filterProps={{
          filters: filterOptions,
          initial: { column: filteredColumn, value: filteredValue },
          searchCallback: setFilter,
        }}
        searchProps={{ value: searchActivity, onChange: setSearchActivity }}
      >
        <TreasuryActivitiesTable activities={filteredItems} loading={loading} />
      </TableContainerWithSearchFilter>
    </>
  );
};

export default MoveFundsActivities;
