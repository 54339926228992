import { StepBaseIcon, StepTodoIcon } from 'assets/svg';
import React, { ReactNode, useState } from 'react';
import { createStyles } from '@mantine/core';

type Props = {
  onSelect: () => void;
  isSelected: boolean;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  rightContent?: ReactNode;
  children?: ReactNode;
  className?: string;
  id?: string;
};

const RadioCard = ({
  onSelect,
  isSelected,
  title,
  subtitle,
  rightContent,
  children,
  className = '',
  id,
}: Props) => {
  const [disabled] = useState(false);

  const { classes, cx } = useStyles();

  const onBoxClick = () => {
    onSelect();
  };

  return (
    <div
      className={cx(
        classes.optionPaymentBox,
        disabled ? classes.disabled : classes.enabled,
        className,
      )}
      style={isSelected ? { backgroundColor: 'rgba(255, 87, 69, 0.08)' } : {}}
      onClick={onBoxClick}
      id={id}
    >
      {isSelected ? <StepTodoIcon /> : <StepBaseIcon />}
      {children ? (
        <>{children}</>
      ) : (
        <>
          <div>
            <div className={classes.optionText}>{title}</div>
            <div className={classes.optionInfoContainer}>{subtitle}</div>
          </div>
          {rightContent && (
            <div className={classes.rightContent}>{rightContent}</div>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  optionPaymentBox: {
    border: '1px solid #E6E7E9',
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: '48px 168px 1fr',
    alignItems: 'center',
    minHeight: '100px',
    padding: '12px 20px',
    '&.specific': {},
  },
  enabled: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.08)',
      opacity: 0.8,
    },
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  optionText: {
    color: '#5F5F5F',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
  },
  optionInfoContainer: {
    color: '#5F5F5F',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
  },
  optionSpeed: {
    color: theme.fn.primaryColor(),
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  rightContent: {
    width: 108,
    height: 76,
  },
}));

export default RadioCard;
