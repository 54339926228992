import { useRecoilState, useSetRecoilState } from 'recoil';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { LoadingOverlay, useMantineTheme } from '@mantine/core';
import { UserCompanyState } from 'states/company/company';
import { UserInfoState, UserRole } from 'states/user/user-info';
import { formatInitials } from 'utilities/formatters/format-strings';
import { getMainOwner } from 'areas/onboarding/onboarding-form.state';
import { UserPreferenceState } from 'states/user/preferences';
import { AlertParamState } from 'states/notifications/alert-params';
import { useIdleTimer } from 'react-idle-timer';
import { AuthenticatedUserState } from 'states/auth/authenticated-user-state';
import useModal from 'components/modal/modal-hook';
import { DateTime } from 'luxon';
import { useShowTermsModal } from 'components/terms/terms-modal';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'states/application/product-onboarding';
import { useLogout } from '../../services/logout/logout-hook';
// import PlaidAutoOpen from '../banking/plaid-auto-open';

const IDLE_MODAL_ID = 'idle-detection-modal';

const OnboardedRoutes = () => {
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(true);
  const [status, setOverviewState] = useRecoilState(ApplicationState);
  const { openConfirmationModal, closeAllModals } = useModal();
  const [needsTermsAgreement, setNeedsTermsAgreement] = useState(false);
  const [authUser, setAuthUser] = useRecoilState(AuthenticatedUserState);
  const showTermsModal = useShowTermsModal();
  const logout = useLogout();

  const onUserIdle = async () => {
    await logout();
    closeAllModals();
  };

  useEffect(() => {
    const logoutOnDate = DateTime.fromISO(authUser.logoutOn);
    if (
      logoutOnDate.isValid &&
      logoutOnDate.diffNow('milliseconds').milliseconds < 0
    ) {
      onUserIdle();
    }
  }, []);

  const idleTimer = useIdleTimer({
    onPrompt: () => {
      openConfirmationModal({
        id: IDLE_MODAL_ID,
        title: 'User idle detection',
        confirmText: 'Logout',
        cancelText: 'Keep me logged in',
        onCancel: () => {
          idleTimer.reset();
        },
        onConfirm: () => {
          onUserIdle();
        },
        showCloseButton: false,
        content: 'You are about to be logged out due to inactivity.',
      });
    },
    onIdle: onUserIdle,
    timeout: 13 * 60 * 1000,
    promptTimeout: 2 * 60 * 1000,
    onAction: () => {
      setAuthUser((prev) => ({
        ...prev,
        logoutOn: DateTime.now().plus({ minutes: 15 }).toISO()!,
      }));
    },
  });

  // const isPlaidStale = useRecoilValue(IsPlaidStale);

  const setUserInfo = useSetRecoilState(UserInfoState);
  const setUserPreferences = useSetRecoilState(UserPreferenceState);
  const setAlertParams = useSetRecoilState(AlertParamState);
  const setUserCompany = useSetRecoilState(UserCompanyState);

  const setOnboardingStatus = async () => {
    const _status = status.user.id
      ? status
      : await getProductOnboardingStatus();
    if (!status.user.id) {
      setOverviewState(_status);
    }
    const { company, user, requiredProperties } = _status;
    const response = await flexbaseClient.getPersonPicture(user.id);
    try {
      const preferences = await flexbaseOnboardingClient.getUserPreference();
      const alertParams = await flexbaseOnboardingClient.getAlertParams();
      setUserPreferences(preferences.preferences);
      setAlertParams(alertParams);
    } catch (e) {
      console.error('error for alert params and prefs');
    }

    let blobURL = null;
    if (response) {
      blobURL = URL.createObjectURL(new Blob([response]));
    }
    if (
      requiredProperties.length &&
      requiredProperties.every(
        (r) =>
          r === 'user.bankingTermsOfServiceSigned' ||
          r === 'user.termsOfServiceSigned' ||
          r === 'user.creditTermsOfServiceSigned' ||
          r === 'user.personalGuarantySigned',
      )
    ) {
      setNeedsTermsAgreement(true);
    }

    setUserInfo({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      initials: formatInitials(`${user.firstName} ${user.lastName}`),
      phone: user.phone,
      picUrl: blobURL,
      cellPhone: user.cellPhone,
      address: {
        line1: user.address.line1,
        line2: user.address.line2,
        city: user.address.city,
        state: user.address.state,
        postalCode: user.address.postalCode,
        country: user.address.country,
      },
      jobTitle: user.jobTitle,
      email: user.email,
      roles: [...(user.roles as UserRole[])],
    });

    setUserCompany({
      id: company.id || '',
      creditLimit: company.creditLimit ? company.creditLimit : '0',
      name: company.companyName || '',
      initials: formatInitials(company.companyName),
      autopay: company.autopay,
      address: company.address?.line1 || '',
      addressLine2: company.address?.line2 || '',
      city: company.address?.city || '',
      postalCode: company.address?.postalCode || '',
      state: company.address?.state || '',
      country: company.address?.country || '',
      owners: company.owners?.map((o) => ({
        id: o.id || '',
        title: o.jobTitle || '',
        percent: o.ownershipPct || 0,
        firstName: o.firstName || '',
        lastName: o.lastName || '',
        email: o.email || '',
      })),
      mainOwner: getMainOwner(company, user),
      ein: company.taxId || '',
      websiteUrl: company.website || '',
      optedProducts: company.optedProducts ? [...company.optedProducts] : [],
      phone: company.phone,
    });
  };

  const checkStatus = async () => {
    try {
      await setOnboardingStatus();
      setLoading(false);
    } catch (e) {
      console.error('onboarded-routes.tsx', e);
    }
  };

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    if (needsTermsAgreement) {
      showTermsModal();
    }
  }, [needsTermsAgreement]);

  return (
    <>
      <LoadingOverlay
        visible={loading}
        loaderProps={{ color: theme.fn.primaryColor() }}
      />
      {/*{isPlaidStale && <PlaidAutoOpen />}*/}
      {!loading && <Outlet />}
    </>
  );
};

export default OnboardedRoutes;
