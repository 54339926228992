/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMediaQuery } from '@mantine/hooks';
import { Group, SelectItemProps, Text, TextInput } from '@mantine/core';
import { FilterComp, FilterList } from '@flexbase-eng/web-components';

import { useStyles } from './banking-transactions-header.styles';
import { forwardRef } from 'react';
import {
  AccountsIcon,
  CloseIcon,
  DateIcon,
  SearchIcon,
  WhoIcon,
} from 'assets/svg';
import Select from 'components/select/flexbase-select';
import { Transaction } from 'services/flexbase/banking.model';
import { NavbarOptions, Filter } from '../../components/filter/filter-models';
import FilterAdvancedWho from '../filter/filter-views/filter-advanced-who/filter-advanced-who';
import FilterAdvancedDate from '../filter/filter-views/filter-advanced-date/filter-advanced-date';
import FilterAdvancedAccounts from '../filter/filter-views/filter-advanced-accounts/filter-advanced-accounts';

export interface Props {
  filterText: string;
  filterList: Filter[];
  transactions: Transaction[];
  setFilterText: (e: any) => void;
  downloadCSV: (array: any) => void;
  setFilterList: (x: Filter[]) => void;
}

interface ItemProps extends SelectItemProps {
  label: string;
  disabled: boolean;
  description: string;
  onClick: () => void;
}

const BankingTransactionsHeader = (props: Props) => {
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');

  const customFilterList = props.filterList.filter(
    (filter) => filter.filterColumn !== 'TO_FROM',
  );
  const toFromFilter = props.filterList.filter(
    (filter) => filter.filterColumn === 'TO_FROM',
  );
  toFromFilter.sort((a, b) => {
    if (a.filterString < b.filterString) return -1;
    if (a.filterString > b.filterString) return 1;
    return 0;
  });
  const startDate = toFromFilter[0];
  const last = toFromFilter[toFromFilter.length - 1];
  const filterDates = {
    filterString: `${startDate?.filterString} - ${last?.filterString}`,
    filterColumn: 'TO_FROM',
  };
  const filterListToDisplay =
    toFromFilter.length > 0
      ? [...customFilterList, filterDates]
      : props.filterList;

  /**
   * Custom Dropdown item with onClick functionality
   */
  const ActionsSelect = forwardRef<HTMLDivElement, ItemProps>(
    ({ label, onClick, disabled }, ref) => (
      <div ref={ref} className={classes.actions} onClick={onClick}>
        <Text size={'sm'}>{label}</Text>
      </div>
    ),
  );

  ActionsSelect.displayName = 'ActionsSelect';

  const navbarOptions: NavbarOptions[] = [
    {
      label: 'Date',
      icon: (
        <DateIcon
          style={{
            marginTop: '3px',
          }}
          width={20}
          height={20}
        />
      ),
      viewIndex: 0,
    },
    {
      label: 'Who',
      icon: (
        <WhoIcon
          style={{
            marginTop: '3px',
          }}
          width={20}
          height={20}
        />
      ),
      viewIndex: 1,
    },
    {
      label: 'Accounts',
      icon: (
        <AccountsIcon
          style={{
            marginTop: '3px',
          }}
          width={20}
          height={20}
        />
      ),
      viewIndex: 2,
    },
  ];

  const renderView = (viewIndex: number) => {
    let view = <></>;
    switch (viewIndex) {
      case 0:
        view = <FilterAdvancedDate />;
        break;
      case 1:
        view = <FilterAdvancedWho {...props} />;
        break;
      case 2:
        view = <FilterAdvancedAccounts />;
        break;
    }

    return view;
  };

  return (
    <div className={classes.baseContainer}>
      <Group position="apart">
        <TextInput
          w={500}
          icon={<SearchIcon />}
          value={props.filterText}
          placeholder="Search transaction"
          onChange={(e) => props.setFilterText(e.target.value)}
          rightSection={
            props.filterText.length > 0 && (
              <CloseIcon
                width={10}
                onClick={() => props.setFilterText('')}
                style={{
                  cursor: 'pointer',
                }}
              />
            )
          }
        />
        <Select
          w={useMobileView ? '100%' : 152}
          data={[
            {
              value: 'downloadCSV',
              label: 'Download CSV',
              disabled: false,
              onClick: props.downloadCSV,
            },
          ]}
          placeholder="Actions"
          itemComponent={ActionsSelect}
        />
        {/* <div className={classes.filterListContainer}>
                <FilterList
                  filterList={filterListToDisplay}
                  setFilterList={props.setFilterList}
                />
              </div> */}
      </Group>
    </div>
  );
};

export default BankingTransactionsHeader;
