import { createStyles } from '@mantine/core';

export const patronStyles = createStyles(() => ({
  container: {
    '.title': {
      '@media(max-width: 767px)': {
        fontSize: '23px',
      },
      fontFamily: 'PP Neue Montreal',
    },
    '@media(max-width: 767px)': {
      marginTop: '5%',
      '.table': {
        td: {
          border: 'none',
          paddingBottom: '0px',
        },
        th: {
          border: 'none',
        },
        tr: {
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid grey',
          padding: '1em',
          marginBottom: '1em',
        },
        thead: {
          display: 'none',
        },
        'td[data-title]': {
          display: 'flex',
        },
        'td[data-title]::before': {
          content: `attr(data-title)`,
          display: 'grid',
          gridTemplateColumns: '6rem',
          fontWeight: 'bold',
        },
        '.btnEditClient': {
          width: '100%',
          height: '30px',
          borderRadius: '4px',
          marginTop: '4%',
        },
      },
    },
  },

  containerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 12,
    alignItems: 'center',
  },

  actionsContainer: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#e5e7eb',
    },
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    padding: '6px 8px',
    color: 'gray',
  },

  containerMessage: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80vh',
  },

  messageText: {
    color: '#6F7181',
    fontSize: '14px',
    textAlign: 'center',
    width: '80%',
  },
}));
