// import flexbaseTerms from 'assets/json/documents/flexbase-terms.json';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStartScreenStyles } from './styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FacebookPixel } from 'services/analytics/pixel';
import { useQuery } from 'utilities/url/query-param';
import { ProductState } from 'states/application/product-onboarding';
import { BnplState } from 'states/bnpl/bnpl.state';
import RegistrationImage from './registration-image';
import MiscAppStartForm from './treasury/misc-app-start-form';
import { Analytics } from 'services/analytics/analytics';
import { ContainerWithFooter } from 'components/footer/footer-container';
import { Image, Text, useMantineTheme } from '@mantine/core';
import FlexbaseImg from '../../../../assets/images/logo-flexbase.png';
import { CreditAndBankingStart } from './credit-and-banking.start';

const StartScreen = () => {
  const { classes } = useStartScreenStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const query = useQuery();
  const [selectedProduct, setProduct] = useRecoilState(ProductState);
  const { isBnpl } = useRecoilValue(BnplState);

  const segmentAnon = searchParams.get('segment_anon');
  const variant = searchParams.get('variant') ?? 'control';
  const theme = useMantineTheme();

  const onLoginClick = () => {
    navigate('/login');
  };

  useEffect(() => {
    if (variant) {
      localStorage?.setItem('variant', variant);
      Analytics.track('Marketing Registration Variant', {
        variant,
      });
    }
  }, [variant]);

  if (segmentAnon) {
    localStorage.setItem('segment_anon', segmentAnon);
  }

  useEffect(() => {
    FacebookPixel.pageView();
  }, [location]);

  useEffect(() => {
    const product = query.get('product');
    const productLegacy = query.get('p');
    // If there is a query param, always set the product, even if one was previously set.
    if (product) {
      switch (product) {
        case 'banking':
          setProduct('BANKING');
          break;
        case 'credit':
          setProduct('CREDIT');
          break;
        case 'all':
          setProduct('ALL');
          break;
        case 'treasury':
          setProduct('TREASURY');
          break;
      }
    } else if (productLegacy) {
      switch (productLegacy) {
        case '1':
          setProduct('BANKING');
          break;
        case '2':
          setProduct('CREDIT');
          break;
        case '3':
          setProduct('ALL');
          break;
        case '4':
          setProduct('TREASURY');
          break;
      }
    } else if (isBnpl) {
      // If this is BNPL, always set to CREDIT just to be sure.
      setProduct('CREDIT');
    } else if (!selectedProduct) {
      // If there is no query param, no BNPL, and a product was not prev set, default to credit
      setProduct('CREDIT');
    }
  }, [query, isBnpl, selectedProduct]);

  if (
    selectedProduct === 'ALL' ||
    selectedProduct === 'BANKING' ||
    selectedProduct === 'CREDIT'
  ) {
    return <CreditAndBankingStart />;
  }

  return (
    <ContainerWithFooter
      footerMarginTop="1rem"
      product={selectedProduct}
      withFooter={true}
      // className={classes.screenContainer}
    >
      <div className={classes.containerHeader}>
        <Image src={FlexbaseImg} width={126} height={22} />
        <div>
          <Text className={classes.txtAlreadyAccount}>
            Already have an account?{' '}
            <Text
              ml="0.5rem"
              data-testid="button-login"
              style={{ cursor: 'pointer' }}
              onClick={onLoginClick}
              color={theme.fn.primaryColor()}
            >
              Login
            </Text>
          </Text>
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <MiscAppStartForm />
        </div>
        <RegistrationImage product={selectedProduct} isBnpl={isBnpl} />
      </div>
    </ContainerWithFooter>
  );
};

export default StartScreen;
