import { useEffect, useState } from 'react';
import CounterpartyAccountDetails from '../counterparty/details';
import { Recipient } from './recipient';
import { Counterparty } from 'services/flexbase/banking.model';
import { useMantineTheme } from '@mantine/core';

interface Props {
  recipient: Recipient;
  refreshRecipients: () => void;
}

const ViewRecipientModal = (props: Props) => {
  const [, setExisting] = useState(false);
  const { recipient } = props;
  const { counterparties } = recipient;
  const theme = useMantineTheme();

  const [achCounterparties, setAchCounterparties] = useState<Counterparty[]>(
    [],
  );
  const [wireCounterparties, setWireCounterparties] = useState<Counterparty[]>(
    [],
  );
  useEffect(() => {
    if (recipient) {
      setExisting(true);

      const achCounterparties = counterparties.filter((c) => c.type === 'ach');

      const wireCounterparties = counterparties.filter(
        (c) => c.type === 'wire',
      );

      // group counterparties by type:
      setAchCounterparties(achCounterparties);
      setWireCounterparties(wireCounterparties);
    }
  }, []);

  return (
    <>
      <div
        style={{
          fontSize: '14px',
          color: theme.fn.themeColor('neutral', 6),
          marginBottom: '10px',
          marginTop: '-15px',
        }}
      >
        {`Recipient's Profile`}
      </div>
      <CounterpartyAccountDetails
        type={'ACH'}
        counterparties={achCounterparties}
      ></CounterpartyAccountDetails>
      <CounterpartyAccountDetails
        type={'Wire'}
        counterparties={wireCounterparties}
      ></CounterpartyAccountDetails>
    </>
  );
};

export default ViewRecipientModal;
