import { Button, Image } from '@mantine/core';

import { useStyles } from '../../styles';
import errorPendingTransfer from 'assets/images/watch-banking.png';

interface Props {
  errorMessage: string;
  onGoBack: () => void;
}

const ModalError = ({ errorMessage, onGoBack }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.modalSuccess}>
      <Image src={errorPendingTransfer} width={260} />

      <span style={{ fontFamily: 'redaction' }}>Something went wrong</span>
      <p style={{ fontFamily: 'PP Neue Montreal' }}>{errorMessage}</p>

      <Button w={300} variant="light" onClick={onGoBack}>
        Go back
      </Button>
    </div>
  );
};

export default ModalError;
