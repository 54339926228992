import { Button, Chip, Group, Text, useMantineTheme } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useRef, useState } from 'react';
import { useConfirmAndReviewStyles } from './review-and-pay.styles';
import FlexbaseInput from 'components/input/flexbase-input';
// import { Account, PlaidAccounts } from 'services/flexbase/banking.model';
import AccountSelection from 'areas/payments/components/account-selection';
import { PlaidAccount } from 'areas/banking/move-funds/move-funds.model';

type Props = {
  delinquentAmount: number;
  interestDue: number;
  paymentAmount: number;
  currentBalance: number;
  minimumDue: number;
  maxPaymentAmount: number;
  minPaymentAmount: number;
  plaidAccount?: PlaidAccount;
  closeModal: () => void;
  onReviewClick: () => void;
  onPaymentAmountChange: (amount: number) => void;
  plaidAccounts: PlaidAccount[];
  currentAccount: PlaidAccount;
  onPlaidAccountChange: (account: PlaidAccount) => void;
  comeCurrentAmount: number;
  frozen: boolean;
};

const getInitialChipValue = (
  paymentAmount: number,
  currentBalance: number,
  minimumDue: number,
) => {
  if (paymentAmount === currentBalance) {
    return 'max';
  }

  if (paymentAmount === minimumDue) {
    return 'min';
  }

  return 'custom';
};

// TODO: Make sure the payment selection chips function properly with the new amount parameters
const CardPaymentAmount = ({
  delinquentAmount,
  interestDue,
  paymentAmount,
  currentBalance,
  currentAccount,
  closeModal,
  onReviewClick,
  onPaymentAmountChange,
  minimumDue,
  maxPaymentAmount,
  minPaymentAmount,
  comeCurrentAmount,
  plaidAccounts,
  onPlaidAccountChange,
  frozen,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useConfirmAndReviewStyles();
  const [inputDirty, setInputDirty] = useState(false);
  const [chipValue, setChipValue] = useState<'min' | 'max' | 'custom'>(
    getInitialChipValue(paymentAmount, currentBalance, minimumDue),
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const currentSelectionAccount: PlaidAccount = {
    ...currentAccount,
    plaidOrDeposit: 'plaid',
  };

  const getDistinctAccounts = (): PlaidAccount[] => {
    const distinctAccts: PlaidAccount[] = [];
    plaidAccounts.forEach((account) => {
      if (
        !distinctAccts.some(
          (acct) => acct.last4 === account.last4 && !account.unlinked,
        )
      ) {
        distinctAccts.push({ ...account, plaidOrDeposit: 'plaid' });
      }
    });
    return distinctAccts;
  };

  const setAmountFromChip = (value: string) => {
    if (value === 'max') {
      setChipValue('max');
      onPaymentAmountChange(currentBalance);
    } else if (value === 'min') {
      setChipValue('min');
      onPaymentAmountChange(minimumDue);
    } else {
      setChipValue('custom');
      inputRef.current?.focus();
    }
  };

  const onInputChange = (input: string) => {
    setInputDirty(true);
    setChipValue('custom');

    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length === 0) {
      onPaymentAmountChange(0);
      return;
    }

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    onPaymentAmountChange(Number.parseFloat(currentValue));
  };

  const setMaxPayment = () => {
    onPaymentAmountChange(maxPaymentAmount);
    setChipValue('max');
  };

  const getAmountErrorText = (reviewClick = false) => {
    if (reviewClick) {
      setInputDirty(true);
    }

    if (paymentAmount > maxPaymentAmount) {
      return `Your payment amount cannot exceed ${formatCurrency(
        maxPaymentAmount,
      )}`;
    }

    const runValidation = inputDirty || reviewClick;
    const actualMinPayment = minPaymentAmount || 0.01;

    if (runValidation && paymentAmount < actualMinPayment) {
      return `Your payment must be greater than ${formatCurrency(
        minPaymentAmount,
      )}`;
    }

    return '';
  };

  return (
    <>
      <Text className={classes.subtitle}>
        {frozen
          ? `Once confirmed, your payment will be applied to your Flexbase card and
        your account will be unfrozen.`
          : `Once confirmed, your payment will be applied to your Flexbase card.`}
      </Text>
      {frozen && (
        <div className={classes.paymentInfo}>
          <div className={classes.infoRow}>
            <span>Current balance:</span>
            <span>{formatCurrency(currentBalance)}</span>
          </div>
          <div className={classes.infoRow}>
            <span>Amount past due:</span>
            <span>{formatCurrency(delinquentAmount)}</span>
          </div>
          <div className={classes.infoRow}>
            <span>Interest:</span>
            <span>{formatCurrency(interestDue)}</span>
          </div>
          <div className={classes.infoRow}>
            <span style={{ fontWeight: 700 }}>
              {frozen
                ? 'Minimum to unfreeze account: '
                : 'Minimum payment due now: '}
            </span>
            <span style={{ fontWeight: 700 }}>
              {frozen
                ? formatCurrency(comeCurrentAmount)
                : formatCurrency(minimumDue)}
            </span>
          </div>
        </div>
      )}
      <div>
        <Text className={classes.inputTitle}>Payment Amount</Text>
        {!delinquentAmount && (
          <Chip.Group
            multiple={false}
            value={chipValue}
            onChange={setAmountFromChip}
          >
            <Group className={classes.amountChips}>
              <Chip
                value="max"
                classNames={{
                  label: classes.chipLabel,
                  iconWrapper: classes.chipIcon,
                }}
              >
                Max
              </Chip>
              <Chip
                value="min"
                classNames={{
                  label: classes.chipLabel,
                  iconWrapper: classes.chipIcon,
                }}
              >
                Due
              </Chip>
              <Chip
                value="custom"
                classNames={{
                  label: classes.chipLabel,
                  iconWrapper: classes.chipIcon,
                }}
              >
                Custom
              </Chip>
            </Group>
          </Chip.Group>
        )}
        <FlexbaseInput
          value={formatCurrency(paymentAmount)}
          onChange={(v) => onInputChange(v.currentTarget.value)}
          error={!!getAmountErrorText()}
          size="lg"
          rightSection={
            paymentAmount !== currentBalance && (
              <Button
                color={theme.fn.primaryColor()}
                className={classes.maxPaymentButton}
                onClick={() => setMaxPayment()}
              >
                Max Payment
              </Button>
            )
          }
          data-testid="payment-amount-text-field"
          ref={inputRef}
        />
        <Text
          className={
            getAmountErrorText()
              ? classes.currentBalanceError
              : classes.currentBalance
          }
        >
          {getAmountErrorText() ||
            (frozen
              ? `The minimum amount you can pay is ${formatCurrency(
                  minPaymentAmount,
                )}. This will not unfreeze your account.`
              : `Your current balance is ${formatCurrency(currentBalance)}`)}
        </Text>
      </div>
      <AccountSelection
        label="Pay From"
        currentAccount={currentSelectionAccount}
        accounts={getDistinctAccounts()}
        onAccountChange={(c) => {
          c = c as PlaidAccount;
          const id = c.id;
          return onPlaidAccountChange(plaidAccounts!.find((p) => p.id === id)!);
        }}
      />
      <Button
        // className={classes.confirm}
        onClick={() => {
          if (!getAmountErrorText(true)) {
            onReviewClick();
          }
        }}
        size="lg"
        data-testid="review-payment-button"
      >
        Review
      </Button>
      <Button
        variant="outline"
        // className={classes.goBack}
        // classNames={{ root: classes.goBack }}
        onClick={() => {
          closeModal();
        }}
        size="lg"
        data-testid="go-back-button"
      >
        Go back
      </Button>
    </>
  );
};

export default CardPaymentAmount;
