import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  statusText: {
    padding: '5px 10px',
  },
  risky: {
    color: '#D80027',
  },
  good: {
    color: 'green',
  },
  tableCellMargin: {
    marginLeft: '5px',
  },
  root: {
    marginTop: '35px',
  },

  // SELECT MANTINE COMP
  label: {
    color: '#4B4B4B',
    fontSize: '16px',
    fontWeight: 500,
  },
  title: {
    color: '#000',
    fontSize: '28px',
    lineHeight: '34px',
    fontFamily: 'PP Neue Montreal',
  },
  subtitle: {
    fontSize: '14px',
    color: '#5F5F5F',
    width: '349px',
    lineHeight: '18px',
    marginTop: '10px',
  },
  labelWrapper: {
    label: {
      fontWeight: 500,
      color: '#4B4B4B',
    },
    marginTop: '30px',
  },
  transferAmount: {
    fontSize: '48px',
    color: '#11152A',
    marginTop: '45px',
    textAlign: 'center',
    height: 'fit-content',
    borderBottom: `3px solid ${theme.fn.primaryColor()}`,
  },
  authorizationSection: {
    color: '#11152A',
    marginTop: '30px',
  },
  hovered: {
    background: 'rgba(255, 87, 69, 0.08) !important',
  },
  item: {
    color: '#0B1130 !important',
    background: 'unset !important',
  },
  buttonRoot: {
    borderRadius: '8px',
    borderColor: theme.fn.primaryColor(),
    height: 40,
    marginTop: 16,
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.1)',
    },
  },
  buttonInner: { display: 'flex', alignItems: 'center' },
  buttonLabel: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.fn.primaryColor(),
  },
  andButtonGreenRoot: {
    borderColor: theme.fn.themeColor('green', 5),
    '&:hover': {
      backgroundColor: theme.fn.lighten(theme.fn.themeColor('green', 5), 0.8),
    },
  },
  andButtonGreenLabel: {
    color: theme.fn.themeColor('green', 5),
  },
  card: {
    width: '420px',
    height: '200px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 767px)': {
      maxWidth: '100%',
    },
  },
  modalCard: {
    padding: '34px',
    height: 'unset',
  },
  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  endContent: {
    marginTop: 'auto',
  },
  spacingTop: {
    marginTop: '16px',
  },
  flexGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  moveFundsStack: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '193px',
  },
  moveFundsButton: {
    // width: '156px',
  },
  moveFundsGraphic: {
    alignSelf: 'center',
  },
  fullHeight: {
    height: '100%',
  },
  linkedAccountsTitle: {
    justifyContent: 'flex-start',
    gap: '8px',
  },
  flexCard: {
    flex: 1,
    height: '111px',
  },
  emptyTableComp: {
    backgroundColor: '#F5F6F8',
    borderRadius: '100px',
    padding: '8px 16px',
    marginTop: '16px',
  },
  emptyTableLink: {
    cursor: 'pointer',
  },
  expandedCard: {
    backgroundColor: '#F9F9F9',
    borderRadius: '8px',
    padding: '16px',
    marginLeft: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  linkIcon: {
    marginRight: '7px',
    marginTop: '3px',
  },
  partnerPill: {
    svg: {
      margin: '0 6px 0 -2px !important',
    },
    backgroundColor: '#F4FFB4',
    width: '130px',
    height: '21px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '21px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: '32px',
    minWidth: '32px',
    backgroundColor: theme.fn.primaryColor(),
    borderRadius: 100,
    marginLeft: 10,
    marginRight: 10,
  },
  flexAccountName: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#000000',
  },
  flexAccountSubName: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '21px',
    color: '#000000',
  },

  accountSelection: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginTop: '30px',
    '> *': {
      marginBottom: '28px',
    },
  },
  stepperContainer: {
    position: 'absolute',
    left: '58px',
    top: '95px',
  },
  transferEstimate: {
    height: '47px',
    backgroundColor: '#fffcec',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
    '> div': {
      fontSize: '14px',
      color: '#000000',
    },
  },
}));
