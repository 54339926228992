import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Badge, Text, Image, Button } from '@mantine/core';
import { useStyles } from './table.styles';
import { sortList } from 'utilities/extensions/sort-lists';
import { SearchIcon } from 'assets/svg';
import FlexbaseInput from 'components/input/flexbase-input';
import PaymentDetailsModal from '../payment-details-modal/payment-details';
import useModal from 'components/modal/modal-hook';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { FlexbaseTable } from 'components/table';
import {
  MoneyMovement,
  transactionMapping,
} from 'services/flexbase/banking.model';
import { selector, useRecoilValue } from 'recoil';
import { useMediaQuery } from '@mantine/hooks';
import WatchBAnking from 'assets/images/watch-banking.png';

// Fetch and cache the user's deposit accuonts
// FIXME: this should really be used for all deposit account state
// in the banking section of the app
const depositAccountsQuery = selector({
  key: 'banking/depositAccounts',
  get: async () => {
    try {
      const { accounts } = await flexbaseBankingClient.getDepositList();
      return accounts ?? [];
    } catch (e) {
      return [];
    }
  },
});

interface Payment {
  amount: string;
  status: string;
  datePosted: string;
  createdAt: string;
  origin: string;
  id: string;
  description: string;
  direction: string;
  number: string;
  recipient: string;
  notes: string;
}

type TablePayment = {
  amount: string;
  type: string;
  status: string;
  direction: string;
  description: string;
  number: string;
  recipient: string;
  datePosted: string;
  dateSubmitted: string;
  notes: string;
};

type Props = {
  recipientName?: string;
};

const PaymentsTable = ({ recipientName }: Props) => {
  const { classes } = useStyles();
  const [error, setError] = useState('');
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [payments, setPayments] = useState<MoneyMovement[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<TablePayment[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const depositAccounts = useRecoilValue(depositAccountsQuery);
  const modal = useModal();

  const getPayments = async (name: string) => {
    setLoading(true);
    try {
      const { payments } = await flexbaseBankingClient.getMoneyMovements();
      if (payments) {
        setPayments(payments);
        transformAndFilterPaymentsList(payments, name);
      } else {
        setError(
          'We are unable to retrieve the company payments at the moment.',
        );
      }
    } catch (e) {
      setError('We are unable to retrieve the company payments at the moment.');
    } finally {
      setLoading(false);
    }
  };

  const inputHandler = (e: any) => {
    const lowerCase = e.target.value.toLowerCase();
    transformAndFilterPaymentsList(payments, lowerCase);
  };

  const formatStatus = (status: string, postedDate: string): string => {
    const hasFuturePostDate =
      DateTime.fromSQL(postedDate).diffNow('milliseconds').milliseconds > 0;

    switch (status) {
      case 'succeeded':
        return hasFuturePostDate ? 'Processing' : 'Confirmed';
      case 'failed':
        return 'Failed';
      case 'pending':
        return 'Pending';
      case 'initiated':
        return 'Initiated';
      case 'canceled':
        return 'Canceled';
      case 'sent':
        return 'Sent';
      case 'clearing':
        return 'Clearing';
      case 'awaitingconfirmation':
        return '2FA Required';
      case 'rejected':
        return 'Rejected';
      default:
        return 'Initiated';
    }
  };

  /* 
  take raw api response, convert to table worthy records
  filter records with the input text presented.
  set the filtered table data hook, which updates the FlexbaseTable component.
  */
  const transformAndFilterPaymentsList = (
    bankingPayments: MoneyMovement[],
    inputTxt: string,
  ) => {
    inputTxt = inputTxt.toLocaleLowerCase();
    const bankingPaymentsArray = (bankingPayments || []).map((payment) => {
      const recipient =
        payment.payDirection === 'Debit'
          ? depositAccounts.find((account) => account.id === payment.depositId)
              ?.name ?? 'Deposit Account'
          : payment.payCtrParty;

      return {
        amount: payment.payAmount || '',
        status: payment.status || '',
        datePosted: payment.asOf || '',
        createdAt: payment.createdAt || '',
        origin: payment.type || '',
        id: payment.id || '',
        description: payment.payDescription || '',
        direction: payment.payDirection || '',
        notes: payment.notes || '',
        recipient,
      } as Payment;
    });

    sortList(bankingPaymentsArray!, {
      sortType: 'desc',
      sortBy: 'datePosted',
    });

    let transformData: TablePayment[] = bankingPaymentsArray.map((payment) => {
      return {
        datePosted: DateTime.fromSQL(payment.datePosted).toFormat(
          'LLL d, yyyy',
        ),
        dateSubmitted: DateTime.fromSQL(payment.createdAt).toFormat(
          'LLL d, yyyy',
        ),
        amount: payment.amount,
        type: transactionMapping[payment.origin],
        id: payment.id,
        status: formatStatus(payment.status.toLowerCase(), payment.datePosted),
        direction: payment.direction || '',
        description: payment.description || '',
        number: payment.number || '',
        recipient: payment.recipient || '',
        notes: payment.notes || '',
        createdAt: payment.createdAt,
      } as TablePayment;
    });

    transformData = transformData?.filter((payment) => {
      if (inputTxt === '') {
        return payment;
      } else {
        return (
          payment.datePosted.toLowerCase().includes(inputTxt) ||
          payment.dateSubmitted.toLowerCase().includes(inputTxt) ||
          payment.amount.toLowerCase().includes(inputTxt) ||
          payment.status.toLowerCase().includes(inputTxt) ||
          payment.recipient.toLowerCase().includes(inputTxt) ||
          payment.type.toLowerCase().startsWith(inputTxt.toLowerCase())
        );
      }
    });
    setFilteredTableData(transformData);
  };

  useEffect(() => {
    getPayments(recipientName || '');
  }, []);

  const toUpperCase = (string: string) => {
    // In some cases the API returns nothing for certain fields..
    if (!string) {
      return 'N/A';
    }

    let capitalized = '';
    if (string === 'ach') {
      return 'ACH';
    }
    if (string === 'inDispute') {
      capitalized = 'In dispute';
      return capitalized;
    }
    capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized;
  };

  const columns = [
    {
      name: 'Date created',
      selector: (row: { dateSubmitted: any }) => row.dateSubmitted,
      sortable: true,
    },
    {
      name: 'Date Posted',
      selector: (row: { datePosted: any }) => row.datePosted,
      sortable: true,
    },
    {
      name: 'Recipient',
      selector: (row: { recipient: any }) => toUpperCase(row.recipient) || '',
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row: { amount: any }) => row.amount,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row: { type: any }) => toUpperCase(row.type),
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row: { status: any }) => {
        return <Badge>{toUpperCase(row.status)}</Badge>;
      },
    },
  ];

  const columnsSm = [
    {
      grow: 3,
      sortable: true,
      name: 'Payment Details',
      selector: (row: { recipient: any }) => toUpperCase(row.recipient) || '',
      cell: (row: { dateSubmitted: any; recipient: any }) => (
        <div>
          <div>{toUpperCase(row.recipient)}</div>
          <div style={{ fontSize: '12px' }}>{row.dateSubmitted}</div>
        </div>
      ),
    },
    {
      name: 'Amount',
      selector: (row: { amount: any }) => row.amount,
      sortable: true,
      compact: true,
    },
    {
      name: 'Type',
      selector: (row: { type: any }) => toUpperCase(row.type),
      sortable: true,
      compact: true,
    },
    {
      name: 'Status',
      cell: (row: { status: any }) => {
        let statusColor = '';

        switch (row.status) {
          case 'Confirmed':
          case 'Sent':
          case 'Clearing':
            statusColor = '#E9F9F2';
            break;
          case 'Processing':
          case 'Pending':
          case 'Initiated':
            statusColor = '#EEEEF3';
            break;
          case 'Failed':
          case 'Canceled':
            statusColor = '#F9D9DF';
            break;
          case 'Rejected':
            statusColor = '#FF8080';
            break;
          case '2FA Required':
            statusColor = '#FFD870';
            break;
          default:
            statusColor = '#E4D2A2';
        }
        return (
          <div
            className={classes.statusColumn}
            style={{
              backgroundColor: statusColor,
            }}
          >
            {toUpperCase(row.status)}
          </div>
        );
      },
    },
  ];

  if (error && error !== '') {
    return (
      <div className={classes.emptyState}>
        <Image
          width={200}
          src={WatchBAnking}
          alt="Display info error"
          className="image-container"
        />
        <Text ff="redaction" size={36} align="center" mt={30}>
          Sorry about that...
        </Text>
        <p>{error}</p>
        <Button
          variant="light"
          onClick={() => getPayments(recipientName || '')}
          mt={20}
        >
          Please try again
        </Button>
      </div>
    );
  }

  // setFilteredTableData(filterTablePayments(payments, inputText));

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}> Payment history</Text>
        <FlexbaseInput
          w={isMobile ? '100%' : '300px'}
          placeholder="Search payment history"
          onChange={inputHandler}
          defaultValue={recipientName || undefined}
          icon={<SearchIcon width={20} height={20} />}
        ></FlexbaseInput>
      </div>
      <FlexbaseTable
        columns={isMobile ? columnsSm : columns}
        data={filteredTableData}
        pagination={filteredTableData && filteredTableData?.length > 8}
        onRowClicked={(row) => {
          if (isMobile) {
            modal.openSizedCenterModal(
              <PaymentDetailsModal {...{ row, getPayments }} />,
              {
                closeOnClickOutside: true,
                margin: '0px',
              },
            );
          } else {
            modal.openRightModal(
              <PaymentDetailsModal {...{ row, getPayments }} />,
            );
          }
        }}
        isFetchingData={loading}
        noDataComponent={
          <Text size={24} fw={500}>
            No payments yet
          </Text>
        }
      />
    </div>
  );
};

export default PaymentsTable;
