import { Center, createStyles, Image } from '@mantine/core';
import { useState } from 'react';
import useModal from 'components/modal/modal-hook';
import { CloseIconSquareEnds } from 'assets/svg';
import { IconContext } from 'react-icons';
import { useMediaQuery } from '@mantine/hooks';
import PaymentRequest from './payment-request/payment-request';
import RequestReview from './payment-request/request-review';
import CopyLink from './payment-request/copy-link';
import Stepper from 'components/stepper/stepper';

enum PaymentSteps {
  PAYMENT_REQUEST_DETAILS,
  REQUEST_REVIEW,
  COPY_LINK,
  ERROR,
}

const IncomingPayment = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { closeAllModals } = useModal();
  const { classes } = useStyles();

  const [active, setActive] = useState(PaymentSteps.PAYMENT_REQUEST_DETAILS);

  const onPaymentRequestDetailsNext = () => {
    setActive(PaymentSteps.REQUEST_REVIEW);
  };

  const onPaymentRequestReviewNext = () => {
    setActive(PaymentSteps.COPY_LINK);
  };

  const createAnotherPaymentLink = () => {
    setActive(PaymentSteps.PAYMENT_REQUEST_DETAILS);
  };

  const backToPaymentRequestDetails = () => {
    setActive(PaymentSteps.PAYMENT_REQUEST_DETAILS);
  };

  const getContent = () => {
    switch (active) {
      case PaymentSteps.PAYMENT_REQUEST_DETAILS:
        return <PaymentRequest {...{ onPaymentRequestDetailsNext }} />;
      case PaymentSteps.REQUEST_REVIEW:
        return (
          <RequestReview
            {...{ onPaymentRequestReviewNext, backToPaymentRequestDetails }}
          />
        );
      case PaymentSteps.COPY_LINK:
        return <CopyLink {...{ createAnotherPaymentLink }} />;
      case PaymentSteps.ERROR:
        return <>Error</>;
    }
  };

  const closeModal = () => {
    closeAllModals();
  };

  return (
    <>
      {isMobile ? (
        <div className={classes.smallModal}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Center className={classes.avatar}>
              <Image src="/svg/fb.svg" alt="fb" fit="scale-down" />
            </Center>
            <IconContext.Provider value={{ size: '34px' }}>
              <div
                className={classes.escapeIconSm}
                onClick={() => closeModal()}
              >
                <CloseIconSquareEnds />
              </div>
            </IconContext.Provider>
          </div>
          <div
            style={{
              fontFamily: 'PP Neue Montreal',
              fontSize: '36px',
              fontWeight: 500,
              marginTop: '24px',
            }}
          >
            Please use a desktop device to create a payment request. Mobile
            functionality coming soon!
          </div>
        </div>
      ) : (
        <div className={classes.fakeModal}>
          <div className={classes.leftContainer}>
            <Center className={classes.avatar}>
              <Image src="/svg/fb.svg" alt="fb" fit="scale-down" />
            </Center>
            <Stepper
              activeStep={active}
              stepsData={[
                'Payment Request Details',
                'Request Review',
                'Copy Link',
              ]}
            />
          </div>

          <div className={classes.innerContent}>{getContent()}</div>

          <div className={classes.rightContainer}>
            <div className={classes.escapeIcon} onClick={() => closeModal()}>
              <CloseIconSquareEnds />
              <div className={classes.escape}>esc</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = createStyles((theme) => ({
  avatar: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.fn.primaryColor(),
    borderRadius: 8,
    marginRight: 5,
  },
  leftContainer: {
    width: '174px',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'right',
    width: '174px',
  },
  escapeIcon: {
    color: '#979797',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    svg: {
      height: '18px',
      width: '18px',
    },
  },
  escapeIconSm: {
    height: 32,
    width: 32,
    color: '#979797',
    cursor: 'pointer',
  },
  escape: {
    color: '#979797',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  fakeModal: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    overflowX: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 50,
    backgroundColor: '#FAFAFA',
    alignItems: 'flex-start', // Otherwise the height for the content goes funky
  },
  innerContent: {
    backgroundColor: '#FFFFFF',
    minHeight: 521,
    width: 520,
    marginTop: '2.5vh',
    padding: '36px 32px',
    display: 'flex',
  },
  smallModal: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100vw',
    height: '100vh',
    backgroundColor: '#FAFAFA',
    position: 'fixed',
    overflowX: 'auto',
  },
}));

export default IncomingPayment;
