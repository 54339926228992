import { atom } from 'recoil';
import { CardForm } from './card-info';

export const FormCardState = atom<CardForm>({
  key: 'issueCard',
  default: {
    userId: '',
    creditLimit: null,
    cardType: 'physical',
    notifyUse: false,
    cardName: '',
    expensesTypes: {
      amount: 0,
      groups: [],
      interval: 'monthly',
    },
  },
});
