import { CompanySimplifiedOwner } from 'states/company/company';
import { useFormStyles } from './form.styles';
import FormBase from './form-base';
import { useForm } from '@mantine/form';
import { validateRequired } from 'utilities/validators/validate-required';
import { NumberInput, TextInput } from '@mantine/core';
import { EmailValidator } from 'utilities/validators/validate-email';
import { useRecoilValue } from 'recoil';
import { CompanySelector } from 'states/application/product-onboarding';

type Props = {
  onClose: () => void;
  onSubmit: (owner: CompanySimplifiedOwner) => void;
  currentOwner?: CompanySimplifiedOwner;
};

const OwnerForm = ({ onClose, onSubmit, currentOwner }: Props) => {
  const company = useRecoilValue(CompanySelector);
  const { classes } = useFormStyles();
  const totalOwnershipPercent =
    company.owners?.reduce<number>(
      (previousValue, currentValue) =>
        previousValue + (currentValue.ownershipPct ?? 0),
      0,
    ) ?? 0;
  const form = useForm<CompanySimplifiedOwner>({
    initialValues: {
      firstName: currentOwner?.firstName || '',
      lastName: currentOwner?.lastName || '',
      percent: currentOwner?.percent || 20,
      title: currentOwner?.title || '',
      id: currentOwner?.id || '',
      email: currentOwner?.email || '',
    },
    validate: {
      firstName: (val) =>
        validateRequired(val) ? null : 'First name is required',
      lastName: (val) =>
        validateRequired(val) ? null : 'Last name is required',
      percent: (value) => {
        if (value < 20) {
          return 'You may only add owners with 20% or more ownership';
        } else if (value > 100 - totalOwnershipPercent) {
          return `Total ownership % including additional owners cannot exceed 100%. (Currently ${totalOwnershipPercent})%`;
        } else {
          return null;
        }
      },
      title: (val) => (validateRequired(val) ? null : 'Title is required.'),
      email: EmailValidator(),
    },
  });

  const onUpdateClick = () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      onSubmit(form.values);
    }
  };

  return (
    <FormBase onCancel={onClose} onSubmit={onUpdateClick}>
      <TextInput
        type="email"
        classNames={{ label: classes.inputLabel, input: classes.input }}
        label={'Email'}
        data-testid="email-input"
        size="lg"
        {...form.getInputProps('email')}
      />
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        style={{ marginTop: '10px' }}
        label={'First name'}
        data-testid="first-name-input"
        size="lg"
        {...form.getInputProps('firstName')}
      />
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        style={{ marginTop: '10px' }}
        label={'Last name'}
        data-testid="last-name-input"
        size="lg"
        {...form.getInputProps('lastName')}
      />
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        style={{ marginTop: '10px' }}
        label={'Job title'}
        data-testid="title-input"
        size="lg"
        {...form.getInputProps('title')}
      />

      <NumberInput
        label="Percent (%) ownership"
        style={{ marginTop: '10px' }}
        data-testid="ownership-percent-input"
        size="lg"
        min={0}
        max={100}
        {...form.getInputProps('percent')}
        classNames={{ label: classes.inputLabel, input: classes.input }}
      />
    </FormBase>
  );
};

export default OwnerForm;
