import {
  Button,
  createStyles,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Merchant } from 'flexbase-client';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';

interface Props {
  closeModal: () => void;
  merchant: Merchant;
  amount?: string;
  merchantCallbackCancelUrl?: string;
  onContinue: () => void;
}

const useStyles = createStyles((theme) => ({
  bnplCongrats: {
    '@media(max-width: 767px)': {
      width: '85%',
      minHeight: '87.5vh',
      justifyContent: 'space-between',
    },
    width: '600px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    margin: 'auto',
  },
  mainText: {
    flexBasis: '75%',
  },
  hereWeGo: {
    '@media(max-width: 767px)': {
      width: '97.5%',
      marginTop: '10%',
    },
    marginLeft: '5px',
    marginTop: '10%',
    fontSize: '21px',
    fontWeight: 700,
  },
  title: {
    '@media(max-width: 767px)': {
      fontSize: '24px',
      margin: '7.5% 0 7.5% 5px',
    },
    margin: '1.75% 0 1.75% 5px',
    fontWeight: 300,
    fontSize: '38px',
    fontFamily: 'PP Neue Montreal',
  },
  subtitle: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
    },
    marginLeft: '5px',
    fontSize: '24px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '32px',
    fontFamily: 'PP Neue Montreal',
  },
  total: {
    fontSize: '26px',
    fontWeight: 700,
    margin: '1.75% 0 1.75% 5px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
  approveButton: {
    height: '55px',
    backgroundImage: `linear-gradient(to bottom right, ${theme.fn.primaryColor()}, #FF8779)`,
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: '22px',
    marginTop: '7%',
  },
  cancelButton: {
    height: '55px',
    color: '#8A8A8F',
    fontSize: '22px',
    fontWeight: 700,
    margin: '2% auto 2% auto',
    cursor: 'pointer',
  },
  agreementLink: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
    },
    marginLeft: 0,
    fontSize: '24px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '32px',
  },
}));

const BnplCongrats = ({
  closeModal,
  merchant,
  amount,
  merchantCallbackCancelUrl,
  onContinue,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  const cancel = (): void => {
    window.location.replace(merchantCallbackCancelUrl!);
    closeModal();
  };

  return (
    <div>
      <FlexbaseHeader onCloseButtonClick={cancel} />
      <div className={classes.bnplCongrats}>
        <div className={classes.mainText}>
          <Text
            size="lg"
            weight="bold"
            align="left"
            className={classes.hereWeGo}
            color={theme.fn.primaryColor()}
          >
            Here we go!
          </Text>
          <Title order={1} align="left" className={classes.title}>
            Congrats!
          </Title>
          <Text align="left" className={classes.subtitle}>
            Your transaction with {merchant.name} for :
          </Text>
          <Text align="left" className={classes.total}>
            {formatCurrency(amount!)}
          </Text>
          <Text align="left" className={classes.subtitle}>
            is approved for 60 days at 0%.
          </Text>
        </div>
        <div className={classes.buttons}>
          <Button
            fullWidth={true}
            variant="gradient"
            className={classes.approveButton}
            onClick={onContinue}
            size="lg"
          >
            Continue
          </Button>
          <Text className={classes.cancelButton} onClick={cancel}>
            Previous step
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BnplCongrats;
