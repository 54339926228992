import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOverlay, useMantineTheme } from '@mantine/core';

import { stylesPDF } from './styles';
import {
  Counterparty,
  DepositAccount,
  MoneyMovement,
} from '../../../../services/flexbase/banking.model';
import HeaderPDF from 'components/pdf-viewer/header';
import { flexbaseBankingClient } from 'services/flexbase-client';

const WireReceiptPDF = () => {
  const { paymentId } = useParams();
  const theme = useMantineTheme();
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [paymentInfo, setpaymentInfo] = useState<MoneyMovement>();
  const [counterparty, setCounterparty] = useState<Counterparty>();
  const [depositAccount, setDepositAccount] = useState<DepositAccount>();
  const paymentDate = DateTime.fromSQL(
    paymentInfo?.createdAt || '' || '',
  ).toFormat('LLL dd, yyyy');

  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    secondaryDark: theme.colors.primarySecondarySuccess[2],
    tertiary: theme.colors.tertiary[2],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });

  const getPaymentInfo = async (_paymentId: string) => {
    const response = await flexbaseBankingClient.getMoneyMovements();
    let paymentInfo: MoneyMovement | undefined;
    if (response.success) {
      paymentInfo = response.payments.find(
        (payment) => payment.id === _paymentId,
      );
      setpaymentInfo(paymentInfo);
    }
    return paymentInfo;
  };

  const getCounterpartyInfo = async (payment: MoneyMovement) => {
    const response = await flexbaseBankingClient.getCounterPartyList();
    if (response.success) {
      setCounterparty(
        response.counterparties.find(
          (counterparty) => counterparty.id === payment?.counterpartyId,
        ),
      );
    }
  };

  const getDepositAccount = async (payment: MoneyMovement) => {
    const response = await flexbaseBankingClient.getDepositList();
    if (response.success) {
      setDepositAccount(
        response.accounts.find((account) => account.id === payment?.depositId),
      );
    }
  };

  const init = async (paymentId: string) => {
    try {
      setLoading(true);
      const paymentInfo = await getPaymentInfo(paymentId);
      if (paymentInfo) {
        await getCounterpartyInfo(paymentInfo);
        await getDepositAccount(paymentInfo);
      }
    } catch (error) {
      console.error('Unable to load payment info.', error);
      setError('Unable to load payment info.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentId) {
      init(paymentId);
    }
  }, [paymentId]);

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} />;
  }

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document
        title={`Flex-wire-receipt-${paymentDate}-${paymentInfo?.id?.slice(
          -4,
        )}.pdf`}
      >
        <Page size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>

          <View style={styles.containerHeader}>
            <View>
              <Text style={styles.textHeader}>Flexbase Technologies, Inc.</Text>
              <Text style={styles.textHeader}>
                Checking ••{depositAccount?.accountNumber?.slice(-4)}
              </Text>
            </View>
          </View>

          {error ? (
            <View style={{ ...styles.card, marginTop: 20 }}>
              <Text style={styles.paymentAmount}>{error}</Text>
            </View>
          ) : (
            <>
              <View style={styles.statusContainer}>
                <View>
                  <Text style={styles.grayText}>{paymentDate}</Text>
                  <Text style={styles.bigGrayText}>
                    Your receipt from Flexbase
                  </Text>
                </View>
                <Text style={styles.grayText}>
                  Status: {paymentInfo?.status}
                </Text>
              </View>

              <View style={styles.containerPage}>
                <View style={{ ...styles.card, marginTop: 20 }}>
                  <View style={styles.transferDetails}>
                    <Text style={{ ...styles.text, fontStyle: 'thin' }}>
                      Domestic Wire
                    </Text>
                    <Text style={styles.paymentAmount}>
                      {paymentInfo?.payAmount}
                    </Text>
                    <Text style={{ ...styles.text, fontStyle: 'thin' }}>
                      to {paymentInfo?.payCtrParty}
                    </Text>
                  </View>
                </View>

                <View style={{ ...styles.card, marginTop: 20 }}>
                  <Text style={styles.greenText}>Recipient’s bank details</Text>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Routing Number</Text>
                    <Text style={styles.text}>
                      {counterparty?.routingNumber}
                    </Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Account Number</Text>
                    <Text style={styles.text}>
                      {counterparty?.accountNumber}
                    </Text>
                  </View>
                </View>

                <View style={{ ...styles.card, marginTop: 20 }}>
                  <Text style={styles.greenText}>Memo</Text>
                  <Text style={styles.text}>
                    &quot;{paymentInfo?.payDescription}&quot;
                  </Text>
                </View>

                <View style={{ ...styles.card, marginTop: 20 }}>
                  <Text style={styles.greenText}>Cost breakdown</Text>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Recipient Name</Text>
                    <Text style={styles.text}>{counterparty?.name}</Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Amount</Text>
                    <Text style={styles.text}>{paymentInfo?.payAmount}</Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Wire Cost</Text>
                    <Text style={styles.text}>$0.00</Text>
                  </View>
                  <View style={styles.containerData}>
                    <Text style={styles.text}>Total Paid</Text>
                    <Text style={styles.text}>{paymentInfo?.payAmount}</Text>
                  </View>
                </View>
              </View>
            </>
          )}

          <View style={styles.footerContainer} fixed>
            <View style={styles.containerData}>
              <View>
                <Text>Flexbase Technologies Inc</Text>
                <Text>2261 Market Street #4552 San Francisco, CA, 94114</Text>
              </View>
              <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
                <Text>Transaction ID: {paymentInfo?.id}</Text>
                <Text>Email us at support@flex.one</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default WireReceiptPDF;
