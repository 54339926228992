import {
  Box,
  Badge,
  Image,
  Button,
  Tooltip,
  Divider,
  Accordion,
} from '@mantine/core';
import { useState } from 'react';
import { useClipboard } from '@mantine/hooks';

import { useStyles } from './add-funds';
import { CopyIcon, DownloadIcon } from 'assets/svg';
import PlaidLogo from 'assets/images/plaid-logo.png';
import { PaymentForm } from 'states/banking/payments';
import ConnectBankAccount from './connect-bank-account';
import TransferFundsSection from './transfer-funds-section';
import { downloadWireInstructions } from '../accounts/utilities';
import AccountSelection from 'areas/payments/components/account-selection';
import { DepositAccount, PlaidAccount } from '../move-funds/move-funds.model';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';

interface Props {
  transferLoading: boolean;
  plaidAccounts: PlaidAccount[];
  depositAccounts: DepositAccount[];
  onLinkAccount: (x: boolean) => void;
  onMakeTransfer: (values: PaymentForm) => void;
}

const AddFundsOptions = ({
  onLinkAccount,
  plaidAccounts,
  onMakeTransfer,
  depositAccounts,
  transferLoading,
}: Props) => {
  const clipboard = useClipboard();
  const { classes, theme } = useStyles({ amount: '0' });
  const [displayAccountNumber, setDisplayAccountNumber] = useState(false);
  const [accountSelected, setAccountSelected] = useState(depositAccounts[0]);

  return (
    <Box w={420}>
      <div className={classes.contentContainer}>
        <div className={classes.title}>Add funds</div>

        <Divider my={15} />

        <Accordion
          radius={0}
          defaultValue="wire-transfer"
          classNames={{
            control: classes.accordionRoot,
            chevron: classes.accordionChevron,
          }}
        >
          <Accordion.Item value="wire-transfer">
            <Accordion.Control>
              <div style={{ padding: '0px 10px' }}>
                <div className={classes.rowContent}>
                  <span className={classes.infoContent}>Wire Transfer</span>
                  <Badge
                    classNames={{
                      inner: classes.badgeInner,
                      root: classes.badgeRoot,
                    }}
                  >
                    Recommended
                  </Badge>
                </div>

                <div className={classes.infoContent}>
                  <p>Arrives in 1-2 business days</p>
                </div>
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              <div style={{ padding: '0px 10px' }}>
                <AccountSelection
                  label="Transferring from"
                  accounts={depositAccounts}
                  currentAccount={accountSelected}
                  onAccountChange={(acc) =>
                    setAccountSelected(acc as DepositAccount)
                  }
                />

                <Box className={classes.rowContent} mt={12}>
                  <div style={{ width: '100%' }}>
                    <span className={classes.label}>Routing number</span>
                    <Tooltip
                      withArrow
                      label={clipboard.copied ? 'Copied' : 'Click to copy'}
                      onClick={() =>
                        clipboard.copy(accountSelected?.routingNumber)
                      }
                    >
                      <div className={classes.input}>
                        <p>{accountSelected?.routingNumber}</p>
                        <CopyIcon color={theme.fn.themeColor('neutral', 1)} />
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ width: '100%' }}>
                    <span className={classes.label}>Account number</span>
                    <Tooltip
                      withArrow
                      label={clipboard.copied ? 'Copied' : 'Click to copy'}
                      onClick={() =>
                        clipboard.copy(accountSelected?.accountNumber)
                      }
                    >
                      <div
                        className={classes.input}
                        onMouseOver={() => setDisplayAccountNumber(true)}
                        onMouseLeave={() => setDisplayAccountNumber(false)}
                      >
                        <p>
                          {displayAccountNumber
                            ? accountSelected?.accountNumber
                            : GetPaddedAccountMask(
                                accountSelected?.accountNumber,
                                4,
                              )}
                        </p>
                        <CopyIcon color={theme.fn.themeColor('neutral', 1)} />
                      </div>
                    </Tooltip>
                  </div>
                </Box>
                <div className={classes.buttonContainer}>
                  <Button
                    my={15}
                    variant="light"
                    leftIcon={<DownloadIcon width={20} />}
                    onClick={() =>
                      downloadWireInstructions(accountSelected!.id)
                    }
                  >
                    Wire Instructions
                  </Button>
                </div>
              </div>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="bank-transfer">
            <Accordion.Control>
              <div style={{ padding: '0px 10px' }}>
                <div className={classes.flexContent}>
                  <span className={classes.infoContent}>Bank transfer</span>
                  <Image src={PlaidLogo} width={46.5} height={18} />
                </div>
                <div className={classes.infoContent}>
                  <p>Arrives in 3-5 business days</p>
                </div>
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              <div style={{ padding: '0px 10px' }}>
                {!plaidAccounts.length ? (
                  <ConnectBankAccount {...{ onLinkAccount }} />
                ) : (
                  <TransferFundsSection
                    onMakeTransfer={onMakeTransfer}
                    {...{ depositAccounts, transferLoading, plaidAccounts }}
                  />
                )}
                <div className={classes.textInfo}>
                  Flex is powered by Flexbase Technologies, Inc.
                </div>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </Box>
  );
};

export default AddFundsOptions;
