import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    color: '#fff',
    padding: '45px',
    backgroundColor: theme.fn.primaryColor(),
    '@media(max-width: 767px)': {
      padding: '20px',
    },
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding: {
    padding: '0px 50px',
    '@media(max-width: 767px)': {
      padding: '0px 20px',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  alingEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cardDetails: {
    paddingBottom: 50,
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: 20,
    },
  },

  transactionsAction: {
    gap: 5,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: theme.colors.primarySecondarySuccess[2],
      svg: {
        fill: theme.colors.primarySecondarySuccess[2],
      },
    },
    '@media(max-width: 767px)': {
      justifyContent: 'flex-end',
    },
  },

  debitCardInfoContent: {
    display: 'flex',
    alignItems: 'center',
    span: {
      color: '#000',
    },
    p: {
      margin: '0px',
      color: '#5F5F5F',
    },
  },
}));
