import { createStyles } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import PaymentStep, { getPrettyMethod } from '../payment-step';
import { SafeBoxIcon } from 'assets/svg';
import AccountBox from 'components/account-box/account-box';

import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  counterpartiesListFilter,
  counterpartyInitial,
  filteredCounterpartiesListState,
  newRecipientFlag,
  paymentMethod,
  paymentRecipientName,
  Recipient,
  recipientState,
  selectedCounterparty,
} from './payment.states';
import PaymentAccountForm, {
  PaymentRecipientFormRef,
} from './step3b-payment-account-form';

type Props = {
  onNextClick: (recipientData?: Recipient) => void;
  onBackClick: () => void;
};

const PaymentRecipientDetails = ({ onBackClick, onNextClick }: Props) => {
  const { classes } = useStyles();

  // local state
  const [showSelect, setShowSelect] = useState(false);

  // payment flow state
  const method = useRecoilValue(paymentMethod);
  const recipientName = useRecoilValue(paymentRecipientName);
  const setFilter = useSetRecoilState(counterpartiesListFilter);

  const [accountDetails, setAccountDetails] =
    useRecoilState(selectedCounterparty);

  const [, setRecipient] = useRecoilState(recipientState);
  const filteredCounterparties = useRecoilValue(
    filteredCounterpartiesListState,
  );
  const [newRecipient, setNewRecipient] = useRecoilState(newRecipientFlag);

  const formRef = useRef<PaymentRecipientFormRef>(null);

  useEffect(() => {
    if (newRecipient) {
      setFilter('new_recipient');
      setAccountDetails(counterpartyInitial);
    } else {
      if (filteredCounterparties.length > 0) {
        setAccountDetails(filteredCounterparties[0]);
      }
    }
  }, [method, newRecipient]);

  const onNext = async () => {
    // if adding a new counterparty, add form will be shown.
    // submit the form results
    if (filteredCounterparties.length === 0) {
      const res = await formRef?.current?.submitForm(); // response is null if validation fails
      if (res) {
        setRecipient(res);
        onNextClick(res);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // This doesn't seem to work.
      }
    } else {
      // move to the next step
      onNextClick();
    }
  };

  const onBack = () => {
    setNewRecipient(false);
    onBackClick();
  };

  return (
    <PaymentStep
      titleText={`${getPrettyMethod(method)} details`}
      balloonText={`${getPrettyMethod(method)} payment to ${recipientName}`}
      onNextClick={onNext}
      onBackClick={onBack}
      nextButtonLabel="Use this account"
    >
      {filteredCounterparties.length === 0 && (
        <PaymentAccountForm
          onFormError={() => console.log('error')}
          onFormValid={(f) => console.log('got', f)}
          ref={formRef}
        />
      )}
      {filteredCounterparties.length > 0 && (
        <>
          <div style={{ position: 'relative' }}>
            <div
              className={classes.optionInfoContainer}
              style={{ marginBottom: '8px' }}
            >
              Send to account
            </div>
            <AccountBox
              headerText={
                accountDetails
                  ? accountDetails.bank ||
                    accountDetails.nickName ||
                    accountDetails.name ||
                    accountDetails.accountName
                  : ''
              }
              subheaderText={
                accountDetails
                  ? `Account ${getPaddedAccountMask(
                      accountDetails.accountNumber,
                      4,
                    )}`
                  : ''
              }
              onClick={() =>
                setShowSelect((prev) => {
                  if (filteredCounterparties.length > 1) {
                    return !prev;
                  }
                  return prev;
                })
              }
              showArrow={filteredCounterparties.length > 1}
              rotateArrow={showSelect}
              showBorder
              isListItem={false}
              icon={
                <SafeBoxIcon
                  className={classes.icon}
                  data-testid={'accounts-list'}
                />
              }
            />
            {showSelect && (
              <div className={classes.selectList}>
                {filteredCounterparties.slice(1).map((c, index) => (
                  <AccountBox
                    headerText={c.bank || c.nickName || c.name}
                    subheaderText={`${'Account'} ${getPaddedAccountMask(
                      c.accountNumber,
                      4,
                    )}`}
                    onClick={() => {
                      setAccountDetails(c);
                      setShowSelect(false);
                    }}
                    showArrow={false}
                    rotateArrow={false}
                    isListItem={true}
                    icon={<SafeBoxIcon className={classes.icon} />}
                    key={index}
                    data-testid={`accounts-list-${c.accountName}`}
                  />
                ))}
              </div>
            )}
          </div>
          <div
            className={classes.newAccount}
            onClick={() => {
              setNewRecipient(true);
            }}
          >
            + Add a new payment account for {recipientName}
          </div>
        </>
      )}
    </PaymentStep>
  );
};

const useStyles = createStyles((theme) => ({
  icon: { opacity: 0.8 },
  optionInfoContainer: {
    color: theme.fn.themeColor('neutral', 7),
    fontSize: '14px',
    fontWeight: 500,
  },
  sectionHeader: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '20px',
    color: '#000000',
    marginTop: '48px',
  },
  inputContainer: {
    marginTop: '24px',
  },
  selectList: {
    position: 'absolute',
    maxHeight: '250px',
    width: '356px',
    overflow: 'scroll',
    marginTop: 4,
    backgroundColor: 'white',
    boxShadow: theme.shadows.sm,
    borderRadius: theme.defaultRadius,
  },
  newAccount: {
    marginTop: '14px',
    fontWeight: 400,
    cursor: 'pointer',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
}));

export default PaymentRecipientDetails;
