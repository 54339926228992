import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import { useCallback, useEffect, useState } from 'react';
import { Invoice } from 'flexbase-client/dist/models/Invoice/Invoice';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useStyles } from './credit-transactions.styles';
import CreditTransactionsHeader from './credit-transactions-header/credit-transactions-header';
import { Avatar, Text } from '@mantine/core';
import ExpenseManagement, {
  ActionStatus,
} from './expense-management/expense-management';
import useModal from 'components/modal/modal-hook';
import TransactionDetails, {
  FocusElement,
} from './details/transaction-details';
import { downloadCSV } from 'utilities/file-io/csv/csv-export';
import { useMediaQuery } from '@mantine/hooks';
import {
  getLocaleDate,
  getLocaleMMDDYYYY,
} from 'utilities/formatters/format-date-string';
import { SortFunction, TableColumn } from 'react-data-table-component';
import CreditTransactionsSm from './credit-transactions-sm/credit-transactions-sm';
import { Analytics } from 'services/analytics/analytics';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CompanyIdState,
  UserIdState,
} from 'areas/onboarding/onboarding-form.state';
import { showNotification } from '@mantine/notifications';
import { IoMdCheckmark } from 'react-icons/io';
import { QbooksCompanyState } from 'states/qbooks/qbooks-company';
import FlexbaseTable from 'components/table/flexbase-table';
import {
  IsAccountant,
  IsAdmin,
} from '../../../../states/application/product-onboarding';
import { FilterListState } from 'areas/banking/components/filter/filter-states';

interface ExportedData {
  id: string;
  date: string;
  type: string;
  name: string;
  toFrom: string;
  amount: string;
  status: string;
  transactionDetail: Invoice;
}

interface FlexbaseTableData {
  id: string;
  date: string;
  type: string;
  name: string;
  toFrom: string;
  amount: string;
  status: string;
  transactionDetail: Invoice;
  logoUrl: string;
  qbSynced?: boolean;
}

const CreditTransactions = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const [qbCompany, setQbCompany] = useRecoilState(QbooksCompanyState);
  const hasCompany = !!qbCompany.CompanyInfo?.CompanyName;
  const modal = useModal();
  const userId = useRecoilValue(UserIdState);
  const companyId = useRecoilValue(CompanyIdState);
  const [transactions, setTransactions] = useState<Invoice[]>([]);
  const [qbooksUnsentInvoices, setQbooksUnsentInvoices] = useState<Invoice[]>(
    [],
  );

  const [filterList, setFilterList] = useRecoilState(FilterListState);
  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<ExportedData[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [syncData, setSyncData] = useState<{ id: string }[]>([]);

  const updateTransaction = (transaction: Invoice) => {
    const transactionsCopy = transactions;
    const index = transactions.findIndex((item) => item.id === transaction.id);
    if (index !== -1) {
      transactionsCopy[index] = {
        ...transactionsCopy[index],
        description: transaction.description,
        docId: transaction.docId,
      };
    }
    setTransactions(transactionsCopy);
  };

  const openTransactionDetails = (
    row: FlexbaseTableData,
    focusElement?: FocusElement,
  ) => {
    modal.openRightModal(
      <TransactionDetails
        id={row.transactionDetail.id}
        createdAt={row.transactionDetail.createdAt}
        docId={row.transactionDetail.docId}
        description={row.transactionDetail.description || ''}
        storeName={row.transactionDetail.storeName}
        total={row.transactionDetail.total}
        employee={row.transactionDetail.cardholderName}
        cardId={row.transactionDetail.cardId}
        storeId={row.transactionDetail.storeId}
        storeLogoUrl={row.logoUrl}
        focusElement={focusElement}
        closeModal={modal.closeAllModals}
        updateTransaction={updateTransaction}
      />,
    );
  };

  /**
   * Get company Transactions/Invoice data
   */
  const getCompanyTransactions = async () => {
    setLoading(true);
    let companyTransactions;
    try {
      if (isAdmin || isAccountant) {
        companyTransactions = await flexbaseClient.getInvoicesByCompany(
          `${companyId}`,
          {
            includeCardholder: true,
            includeMerchantName: true,
            includeReversed: true,
          },
        );
      } else {
        companyTransactions = await flexbaseClient.getInvoicesByUser(
          `${userId}`,
          {
            includeCardholder: true,
            includeMerchantName: true,
            includeReversed: true,
          },
        );
      }
      if (companyTransactions) {
        setTransactions(companyTransactions);
      }
    } finally {
      setLoading(false);
    }
  };

  const getQbooksCompany = async () => {
    try {
      const qbCompanyResponse =
        await flexbaseOnboardingClient.getQbooksCompany();
      setQbCompany(qbCompanyResponse);
    } catch (e) {
      console.error(e);
    }
  };

  const getQbooksUnsentInvoices = async () => {
    setLoading(true);
    try {
      const qbUnsentInvoices =
        await flexbaseOnboardingClient.getQbooksUnsentInvoices();
      if (qbUnsentInvoices.success) {
        setQbooksUnsentInvoices(qbUnsentInvoices.invoices);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const syncQuickbooks = async (syncData: { id: string }[]) => {
    setLoading(true);
    try {
      const qbUnsentInvoices =
        await flexbaseOnboardingClient.resendQbooksUnsentInvoices(syncData);
      if (qbUnsentInvoices.success) {
        await getQbooksUnsentInvoices();
        showNotification({
          title: 'Success',
          message: `${qbUnsentInvoices.posted.length} new matches posted.`,
          color: 'flexbase-teal',
          icon: <IoMdCheckmark />,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const prepareCsvData = () => {
    if (selectedRows.length > 0) {
      setCsvData(
        selectedRows.map((data: any) => {
          return {
            Date: getLocaleMMDDYYYY(data.date),
            Name: data.name,
            Transaction: data.toFrom,
            Amount:
              parseInt(data.amount) < 0
                ? `+ ${formatCurrency(Math.abs(data.amount))}`
                : formatCurrency(data.amount),
            Type: data.type,
            Memo: `${data.transactionDetail.description || ''} ${
              data.transactionDetail.memo || ''
            }`,
          };
        }),
      );
    } else {
      setCsvData(
        filteredItems()
          .filter((tx) => {
            // return !(['reversed', 'declined'].includes(tx.status));
            return tx.status !== 'declined';
          })
          .map((data: any) => {
            data = data.transactionDetail;
            return {
              Date: getLocaleMMDDYYYY(data.createdAt),
              Name: data.cardholderName,
              Transaction: data.storeName,
              Amount: `${data.status === 'reversed' ? '-' : ''}${formatCurrency(
                Math.abs(data.total),
              )}`,
              Type: data.origin === 'bnpl' ? 'Flexbase Pay' : 'Credit Card',
              Memo: `${data.description || ''} ${data.memo || ''}`,
            };
          }),
      );
    }
  };

  const prepareSyncData = () => {
    if (selectedRows.length > 0) {
      setSyncData(
        selectedRows.map((row) => {
          return {
            id: row.id,
          };
        }),
      );
    } else {
      setSyncData(
        filteredItems().map((row) => {
          return {
            id: row.id,
          };
        }),
      );
    }
  };

  useEffect(() => {
    getQbooksCompany();
    getQbooksUnsentInvoices();
    getCompanyTransactions();
  }, []);

  // Prep and format the csv data if rows are selected
  useEffect(() => {
    prepareCsvData();
    prepareSyncData();
  }, [selectedRows, transactions, filterList]);

  /**
   * Define columns to be passed into the FlexbaseTable component
   */
  const columns: TableColumn<FlexbaseTableData>[] = [
    {
      name: 'Date',
      format: (row: { date: string }) => getLocaleDate(row.date, true),
      selector: (row: { date: string }) => row.date,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row: { type: string }) => row.type,
      sortable: true,
      compact: true,
    },
    {
      name: 'Name',
      selector: (row: { name: string }) => row.name,
      sortable: true,
      compact: true,
    },
    {
      name: 'To/From',
      cell: (row) => (
        <div
          className={classes.storeName}
          onClick={() => openTransactionDetails(row)}
        >
          {row.logoUrl ? (
            <img
              src={row.logoUrl}
              className={classes.storeLogo}
              alt={row.toFrom}
            />
          ) : (
            <Avatar radius="34px" className={classes.storeIcon} size="32px">
              {row.toFrom?.charAt(0) || '$'}
            </Avatar>
          )}
          {row.toFrom || 'Payment received'}
        </div>
      ),
      selector: (row: { toFrom: string }) => row.toFrom,
      sortable: true,
      compact: true,
      wrap: false,
      grow: 3,
    },
    {
      name: 'Transaction Management',
      cell: (row) =>
        row.toFrom ? (
          <ExpenseManagement
            receiptAction={() => openTransactionDetails(row, 'receipt')}
            memoAction={() => openTransactionDetails(row, 'memo')}
            flagAction={() => openTransactionDetails(row, 'flag')}
            receiptActionStatus={
              row.transactionDetail.docId ? ActionStatus.complete : null
            }
            memoActionStatus={
              row.transactionDetail.description ? ActionStatus.complete : null
            }
            approveActionStatus={row.qbSynced ? ActionStatus.complete : null}
          />
        ) : (
          <></>
        ),
      center: false,
      compact: true,
      grow: 2,
      minWidth: '200px',
    },
    {
      name: 'Amount',
      cell: (row: any) => {
        const amountInt = parseInt(row.amount);
        return (
          <Text
            style={{
              color:
                amountInt < 0 || row.status === 'reversed'
                  ? '#27C281'
                  : row.status === 'declined'
                  ? '#ff0000'
                  : '#5F5F5F',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            {amountInt < 0 && '+ '}
            {formatCurrency(Math.abs(row.amount)) || 'N/A'}
          </Text>
        );
      },
      selector: (row: { amount: string }) => parseInt(row.amount),
      sortable: true,
      compact: true,
    },
    {
      cell: (row) => {
        switch (row.status) {
          case 'pending':
            return <Text className={classes.status}>Pending</Text>;
          case 'declined':
            return <Text className={classes.status}>Declined</Text>;
          case 'reversed':
            return <Text className={classes.status}>Refunded</Text>;
          default:
            return <> </>;
        }
      },
      compact: true,
    },
  ];

  const qbooksUnsentInvoiceIds = qbooksUnsentInvoices.map(
    (invoice) => invoice.id,
  );

  /**
   * Prep/format data to be passed into the FlexbaseTable component
   */
  const tableData: FlexbaseTableData[] = transactions.map((transaction) => {
    return {
      id: transaction.id,
      date: transaction.createdAt,
      type: transaction.origin === 'bnpl' ? 'Flexbase Pay' : 'Credit Card',
      name: transaction.cardholderName,
      toFrom: transaction.storeName,
      amount: transaction.total,
      status: transaction.status,
      transactionDetail: transaction,
      logoUrl: (transaction as any).storeLogoUrl,
      qbSynced: !qbooksUnsentInvoiceIds.includes(transaction.id),
    };
  });

  /**
   * Filter table data based on filter text
   */
  const getFilteredTableData = useCallback(() => {
    const normalizedFilterText = filterText?.trim().toLowerCase();

    return tableData.filter((transaction) => {
      return (
        transaction.date?.toString().includes(normalizedFilterText) ||
        transaction.type?.toLowerCase().includes(normalizedFilterText) ||
        transaction.name?.toLowerCase().includes(normalizedFilterText) ||
        transaction.name?.toLowerCase().includes(normalizedFilterText) ||
        transaction.toFrom?.toLowerCase().includes(normalizedFilterText) ||
        transaction.status?.toLowerCase().includes(normalizedFilterText) ||
        transaction.amount?.toLowerCase().includes(normalizedFilterText)
      );
    });
  }, [filterText, tableData]);

  const filteredItems = () => {
    if (filterList.length === 0) {
      return getFilteredTableData();
    }
    const toFromList = filterList.filter(
      (filter) => filter.filterColumn === 'TO_FROM',
    );
    if (toFromList.length === 0) {
      return getFilteredTableData();
    } else {
      const filterStringToFromList = filterList
        .map((filter) => {
          return filter.filterString;
        })
        .sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });
      const filteredData = tableData.filter((item) => {
        const formattedTransactionDate = new Date(item.date).toLocaleString(
          'en-US',
          { month: 'short', day: '2-digit', year: 'numeric' },
        );
        return filterStringToFromList.includes(formattedTransactionDate);
      });
      return filteredData;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function parseSelector<T extends Record<string, any>>(
    row: T,
    selector: string,
  ): T {
    return selector.split('.').reduce((acc, part) => {
      // O(n2) when querying for an array (e.g. items[0].name)
      // Likely, the object depth will be reasonable enough that performance is not a concern
      const arr = part.match(/[^\]\\[.]+/g);
      if (arr && arr.length > 1) {
        for (let i = 0; i < arr.length; i++) {
          return acc[arr[i]][arr[i + 1]];
        }
      }

      return acc[part];
    }, row);
  }

  let sortedTableData: FlexbaseTableData[] = filteredItems();
  // This is literally to make tslint stop complaining because telling it to ignore doesn't work
  sortedTableData.some((e) => e.date);
  const sorter: SortFunction<FlexbaseTableData> = (
    rows,
    selector,
    direction,
  ) => {
    return (sortedTableData = rows.slice(0).sort((a, b) => {
      let aValue;
      let bValue;
      if (typeof selector === 'string') {
        aValue = parseSelector(a, selector);
        bValue = parseSelector(b, selector);
      } else {
        aValue = selector(a);
        bValue = selector(b);
      }
      if (direction === 'asc') {
        if (aValue < bValue) {
          return -1;
        }
        if (aValue > bValue) {
          return 1;
        }
      }
      if (direction === 'desc') {
        if (aValue > bValue) {
          return -1;
        }
        if (aValue < bValue) {
          return 1;
        }
      }
      return 0;
    }));
  };

  return (
    <div className={classes.baseContainer}>
      <div className={classes.widgetContainer}>
        {isMobile ? (
          <CreditTransactionsSm />
        ) : (
          <>
            <CreditTransactionsHeader
              filterList={filterList}
              setFilterList={setFilterList}
              onFilter={setFilterText}
              filterText={filterText}
              onDownloadCSVClick={() => {
                Analytics.track('Credit Downloaded Transactions to CSV');
                downloadCSV(csvData, 'Transactions');
              }}
              qbConnected={hasCompany}
              onSyncSelection={() => {
                syncQuickbooks(syncData);
              }}
              onSyncUnsynced={() => {
                syncQuickbooks(
                  qbooksUnsentInvoices.map((invoice) => {
                    return { id: invoice.id };
                  }),
                );
              }}
              onSyncAll={() => {
                syncQuickbooks([]);
              }}
              hasSelection={selectedRows.length > 0}
            />
            <FlexbaseTable
              columns={columns}
              data={filteredItems()}
              sortFunction={sorter}
              selectableRows={true}
              pagination={filteredItems.length > 8}
              onRowClicked={(row) => openTransactionDetails(row)}
              onSelectedRowsChange={({ selectedRows }) =>
                setSelectedRows(selectedRows)
              }
              isFetchingData={loading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CreditTransactions;
