import { Avatar, createStyles, Text } from '@mantine/core';
type Props = {
  logoUrl?: string;
  placeholderName: string;
  withLabel?: boolean;
  classNames?: Partial<{
    logo: string;
    avatar: string;
    avatarText: string;
    label: string;
  }>;
};

export const AvatarLogoCell = ({
  logoUrl,
  placeholderName,
  withLabel = true,
  classNames,
}: Props) => {
  const { classes, cx } = useStyles();

  const formatInitials = (name: string): string => {
    const initials =
      name
        ?.split(' ')
        ?.reduce(
          (prev: string, curr: string) => prev + curr[0]?.toUpperCase(),
          '',
        ) || '';

    if (initials.length > 2) {
      return `${initials.charAt(0)}${initials.charAt(initials.length - 1)}`;
    }

    return initials;
  };

  return (
    <>
      {logoUrl ? (
        <img
          src={logoUrl}
          className={cx(classes.logo, classNames?.logo)}
          alt={placeholderName}
        />
      ) : (
        <Avatar
          radius="md"
          classNames={{
            root: cx(classes.avatar, classNames?.avatar),
            placeholder: cx(classes.avatarPlaceholder, classNames?.avatarText),
          }}
        >
          {formatInitials(placeholderName).substring(0, 2) || '$'}
        </Avatar>
      )}
      {withLabel && (
        <Text className={classNames?.label}>{placeholderName}</Text>
      )}
    </>
  );
};

const useStyles = createStyles((theme) => ({
  avatar: {
    fontSize: '20px',
    borderRadius: '50%',
    height: '32px',
    width: '32px',
    color: theme.fn.primaryColor(),
    marginRight: '10px',
    minWidth: 'unset',
    backgroundColor: '#FFEEEC',
  },
  avatarPlaceholder: {
    color: theme.fn.primaryColor(),
    backgroundColor: '#FFEEEC',
    fontWeight: 500,
    fontFamily: 'PP Neue Montreal',
  },
  logo: {
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: '10px',
  },
}));

export default AvatarLogoCell;
