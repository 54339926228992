import { Loader, MantineNumberSize } from '@mantine/core';
import React from 'react';

type Props = { visible: boolean; size?: MantineNumberSize };

const ParentCenteredLoader = ({ visible, size }: Props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: visible ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader size={size || 'md'} />
    </div>
  );
};

export default ParentCenteredLoader;
