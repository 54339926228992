import { Container, Divider, Grid, Text } from '@mantine/core';
import { detailsStyles } from './details.styles';
import useModal from 'components/modal/modal-hook';
import { CloseIconSquareEnds, UnitedStatesCircle } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';

interface Props {
  type: 'vendor' | 'customer';
}

const Details = ({ type }: Props) => {
  const { classes } = detailsStyles();
  const modal = useModal();
  const closeModal = modal.closeAllModals;

  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div style={{ marginBottom: '3rem' }}>
      <Container
        pl="3rem"
        pt="3rem"
        pr="3rem"
        style={{
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text className={classes.title}>Home Depot</Text>
          <div
            className={classes.btnCloseContainer}
            onClick={() => closeModal()}
          >
            <CloseIconSquareEnds
              width={isMobile ? 20 : 34}
              height={isMobile ? 20 : 34}
            />
            <Text>esc</Text>
          </div>
        </div>

        <div style={{ marginTop: '1rem' }}>
          <Text size="sm" color="#5F5F5F">
            Customer since August 1, 2022
          </Text>
          <Text size="sm" color="#5F5F5F">
            Next payment due: Nov 15, 2022
          </Text>
        </div>
      </Container>

      <Divider style={{ marginTop: '2rem', color: '#E6E7E9' }} />

      <Grid pl="3rem" mt="2rem" pr="3rem">
        <Grid.Col span={6}>
          <Text className={classes.subtitle}>Contact name:</Text>
          <Text className={classes.data}>Max Luigi</Text>
        </Grid.Col>
      </Grid>

      <Grid pl="3rem" mt="2rem" pr="3rem">
        <Grid.Col span={isMobile ? 12 : 6}>
          <Text className={classes.subtitle}>Contact email:</Text>
          <div className={classes.data}>max@bstreet.com</div>
        </Grid.Col>
        <Grid.Col span={isMobile ? 12 : 6}>
          <Text className={classes.subtitle}>Contact number:</Text>
          <div className={classes.data}>(638) 873-5544</div>
        </Grid.Col>
      </Grid>

      <div className={classes.container}>
        <Text className={classes.subtitle}>Vendor address:</Text>
        <div
          className={classes.data}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <UnitedStatesCircle width={20} height={20} />{' '}
          <Text style={{ marginLeft: '1vh' }}>
            2804 B Street, San Diego, CA, 92102
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Details;
