/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';
// Progress
import { Text, createStyles } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';

type Props = {
  title: ReactNode;
  content: ReactNode;
  totalAvailableBalance: number;
  currentAmount: number;
  currentAmountPercent?: boolean;
  classNames?: Partial<{ card: string; endContent: string }>;
};

const ProgressPercent = ({
  title,
  content,
  totalAvailableBalance,
  currentAmountPercent,
  classNames,
  currentAmount,
}: Props) => {
  const { classes, cx } = useStyles();
  const amountPercent =
    totalAvailableBalance > 0
      ? (currentAmount / totalAvailableBalance) * 100
      : 0;

  return (
    <div className={cx(classes.card, classNames?.card)}>
      <Text size={16} color="#5F5F5F" weight={500}>
        {title}
      </Text>
      <Text size={26} color="#000000" weight={500} className={classes.pp}>
        {formatCurrency(currentAmount)}
        {currentAmountPercent && (
          <>
            {' / '}
            {amountPercent.toFixed(2)}%
          </>
        )}
      </Text>
      {/* commented out for now */}
      {/* <div className={cx(classes.endContent, classNames?.endContent)}> */}
      {/* <Progress value={amountPercent} size="sm" /> */}
      {/* <div className={cx(classes.flexGroup, classes.spacingTop)}>
          <Text size={12} color="#5F5F5F" weight={400}>
            of {formatCurrency(totalAvailableBalance)}
          </Text>
          <Text
            size={12}
            color="#5F5F5F"
            weight={400}
            style={{ width: 'fit-content' }}
          >
            {amountPercent.toFixed(2)}
            {content}
          </Text>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default ProgressPercent;

const useStyles = createStyles(() => ({
  card: {
    width: '420px',
    height: '200px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  endContent: {
    marginTop: 'auto',
  },
  flexGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacingTop: {
    marginTop: '16px',
  },
}));
