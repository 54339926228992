import { TransactionStatus } from 'states/bnpl/bnpl.state';

// I just moved this, don't Git Blame me if it goes wrong!
export const merchantCallback = (
  merchantBaseUrl: string,
  transactionStatus: TransactionStatus,
  callbackUrl: string,
  sessionId?: string,
  invoiceId?: string,
): string => {
  if (merchantBaseUrl) {
    const baseUrlTrimmed = merchantBaseUrl.replace(/\/$/, '');
    const callbackTrimmed = callbackUrl.replace(/^\//, '');

    const query = new URLSearchParams({ status: transactionStatus });

    if (sessionId) {
      query.append('session', sessionId);
    }

    if (invoiceId) {
      query.append('id', invoiceId);
    }

    return `${baseUrlTrimmed}/${callbackTrimmed}?${query.toString()}`;
  }
  return '';
};
