import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import FormBase from 'components/forms/form-base';
import useModal from 'components/modal/modal-hook';
import { Analytics } from 'services/analytics/analytics';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { Counterparty } from 'services/flexbase/banking.model';
import { Avatar, Divider, Text, createStyles } from '@mantine/core';
import { formatInitials } from 'utilities/formatters/format-strings';

interface Props {
  recipientId: string;
  name: string;
  counterparties: Counterparty[];
  refreshRecipients: () => void;
}

const RemoveRecipient = ({
  name,
  counterparties,
  refreshRecipients,
}: Props) => {
  const { closeAllModals } = useModal();
  const [, setLoading] = useState(false);
  const { classes } = useStyles();

  const onDelete = async () => {
    setLoading(true);
    try {
      // find all counterparties with recipient name
      const counterpartiesToDelete = counterparties.filter(
        (c) => c.accountName === name,
      );

      // iterate over the array to remove counterparty
      for (let i = 0; i < counterpartiesToDelete.length; i++) {
        await flexbaseBankingClient.removeCounterparty(
          counterpartiesToDelete[i].id,
        );
      }
      await refreshRecipients();
      showNotification({
        color: 'flexbase-teal',
        title: 'Success',
        message: `${name} was removed.`,
      });
      Analytics.track('Payments Removed a Recipient', { name });
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Failure',
        message: `${name} could not be deleted. Please try again later.`,
      });
    } finally {
      setLoading(false);
      closeAllModals();
    }
  };
  return (
    <FormBase
      onCancel={closeAllModals}
      onSubmit={onDelete}
      submitText="Remove Recipient"
    >
      <div>
        <Divider />
        <div className={classes.content}>
          <Avatar
            src={null}
            radius="md"
            color="flexbase-rose"
            size={42}
            classNames={{ placeholder: classes.placeHolder }}
          >
            {formatInitials(name)}
          </Avatar>
          <Text>{name}</Text>
        </div>
        <Divider />
      </div>
    </FormBase>
  );
};

export default RemoveRecipient;

const useStyles = createStyles((theme) => ({
  content: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    margin: '15px 0px',
  },

  placeHolder: {
    color: theme.fn.primaryColor(),
    fontSize: '20px',
    fontWeight: 400,
  },
}));
