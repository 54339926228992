import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'states/application/product-onboarding';
// CompanySelector,
import { useEffect, useState } from 'react';
import { Box, createStyles, rem, Text } from '@mantine/core';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
// import { BETA_TESTERS } from 'states/beta-testers';
import Frame from 'assets/images/flex-background-text.png';
import Yolo from 'assets/images/img.png';
import OnboardingSubmittingScreen from '../components/onboarding-submitting-screen';
import {
  BankApplicationStatusIsApplicationResponse,
  BankApplicationStatusIsAwaitingDocuments,
  BankingApplicationStatus,
} from '../../../services/flexbase/banking.model';
import AuthLoader from '../../../components/login/auth-loader';
import { useProductOnboarding } from '../onboarding-hooks';

const EndPage = () => {
  const { classes } = useStyles();
  const status = useRecoilValue(ApplicationState);
  const [loading, setLoading] = useState(true);
  const [bankingStatus, setBankingStatus] =
    useState<BankingApplicationStatus>('Unused');
  const [hasOutstandingOwners, setHasOutstandingOwners] = useState(false);

  const { refreshProductOnboardingStatus } = useProductOnboarding();

  const endOnboardingV2 = async () => {
    setLoading(true);
    try {
      // First, refresh the status so we are 100% sure what we have to do
      // (since having just one endpoint to submit a new application is NO GOOD apparently)
      const onboardingStatus = await refreshProductOnboardingStatus();

      if (onboardingStatus.company.createdBy !== onboardingStatus.user.id) {
        return;
      }
      const promises: Promise<any>[] = [];

      if (onboardingStatus.productStatus.banking.status === 'pending') {
        const bankingApplication =
          await flexbaseBankingClient.getApplicationStatus();
        if (
          BankApplicationStatusIsApplicationResponse(bankingApplication) &&
          bankingApplication.status === 'Incomplete'
        ) {
          promises.push(flexbaseBankingClient.createApplication());
        }
      }

      // Do we have anything we need to do for credit?
      // Pending is actually a poor way to determine if it needs to be submitted but.. wygd
      if (onboardingStatus.productStatus.credit.status === 'pending') {
        promises.push(flexbaseOnboardingClient.underwritingSubmit());
      }

      await Promise.allSettled(promises);
      const refreshResult = await Promise.allSettled([
        refreshProductOnboardingStatus(),
        flexbaseOnboardingClient.getOnboardingOverview(),
      ]);
      if (refreshResult[1].status === 'fulfilled') {
        const overviewResponse = refreshResult[1].value;
        const allOwnersComplete = overviewResponse.users
          .filter((o) => o.roles.includes('OWNER'))
          .every((o) => o.isComplete);
        setHasOutstandingOwners(!allOwnersComplete);
      }
      if (onboardingStatus.optedProducts.includes('BANKING')) {
        const bankingApplicationStatus =
          await flexbaseBankingClient.getApplicationStatus();
        if (
          BankApplicationStatusIsApplicationResponse(
            bankingApplicationStatus,
          ) ||
          BankApplicationStatusIsAwaitingDocuments(bankingApplicationStatus)
        ) {
          setBankingStatus(bankingApplicationStatus.status);
        }
      }
    } catch (e) {
      console.error('end.page.tsx', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    endOnboardingV2();
  }, []);

  return (
    <Box className={classes.container}>
      {loading ? (
        <AuthLoader
          classNames={{ loadingContainer: classes.loader }}
          label={
            <Text className={classes.loaderLabel}>
              {status.userIsApplicant
                ? 'Submitting Application'
                : 'Saving Information'}
            </Text>
          }
        />
      ) : (
        <OnboardingSubmittingScreen
          endScreenFor={
            status.userIsApplicant
              ? 'applicant'
              : status.user.roles.includes('OWNER')
              ? 'owner'
              : 'user'
          }
          id="idk"
          graphic={Yolo}
          productStatus={status.productStatus}
          bankingStatus={bankingStatus}
          hasOutstandingOwners={hasOutstandingOwners}
        />
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Frame})`,
    width: '100%',
    height: '100vh',
    backgroundColor: theme.fn.themeColor('neutral', 2),
    '@media(max-width: 767px)': {
      backgroundImage: 'unset',
    },
  },
  loader: {
    backgroundColor: 'transparent',
    color: theme.fn.themeColor('primarySecondarySuccess', 2),
  },
  loaderLabel: {
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    fontSize: rem(32),
  },
}));

export default EndPage;
