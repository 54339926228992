import React, { useEffect } from 'react';

const QbooksLoginResponse = () => {
  useEffect(() => {
    localStorage?.setItem('qbloginredirect', 'true');
    window.opener = null;
    window.open('', '_self');
    window.close();
  }, []);

  return <></>;
};

export default QbooksLoginResponse;
