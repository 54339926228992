import { useEffect, useState } from 'react';
import { Button, Center, Image, Pagination, Table, Title } from '@mantine/core';
import flexbaseClient from 'services/flexbase-client';
import TableRow from './table-row/table-row';
import { projectStyles } from './projects.styles';
import useModal from 'components/modal/modal-hook';
import ModalForm from './modal-form/modal-form';
import { IProjectInfo } from 'states/projects/project-info.interface';
import { ProjectsResponse } from 'flexbase-client';

const Projects = () => {
  const [allProjects, setAllProjects] = useState<IProjectInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [messageType, setMessageType] = useState<string | undefined>('');
  const indexOfLastPost = currentPage * 8;
  const indexOfFirstPost = indexOfLastPost - 8;
  const totalPageCount = Math.ceil(allProjects.length / 8);
  const projects = allProjects.slice(indexOfFirstPost, indexOfLastPost);

  const { classes } = projectStyles();

  const modal = useModal();
  const closeModal = modal.closeAllModals;

  const btnCreateProjectSize = screen.width <= 767 ? 'sm' : 'md';

  const displayMessage =
    messageType === 'noProjects'
      ? {
          title: '',
          text: 'You don’t have any projects yet. You can create the first one by clicking on the button below.',
          showBtnAddProject: true,
        }
      : {
          title: 'Something went wrong',
          text: 'No worries, we’re working on it. You can go back or choose any other section in the product.',
          showBtnAddProject: false,
        };

  const getProjects = async () => {
    try {
      const response = await flexbaseClient.getCompanyProjects();
      if (response && response.length > 0) {
        setAllProjects(
          response.map((project: ProjectsResponse): IProjectInfo => {
            const { street1, city, postalCode, country, state, street2 } =
              project.location;
            return {
              id: project.id,
              name: project.name,
              description: project.description,
              street1,
              street2,
              city,
              state,
              postalCode,
              country,
            };
          }),
        );
      } else {
        setMessageType('noProjects');
      }
    } catch (error) {
      setMessageType('error');
    }
  };

  const openActionModal = () => {
    screen.width <= 767
      ? modal.openCenterModal(<ModalForm {...{ closeModal, setAllProjects }} />)
      : modal.openRightModal(<ModalForm {...{ closeModal, setAllProjects }} />);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <Title className={classes.title}> Projects 🏗️</Title>
        <div>
          <Button
            onClick={() => openActionModal()}
            fullWidth
            radius={8}
            color="flexbase-teal"
            size={btnCreateProjectSize}
          >
            + Create Project
          </Button>
        </div>
      </div>

      <div className={classes.description}>
        Here is the list of your projects.
      </div>

      {messageType !== '' ? (
        <div className={classes.containerMessage}>
          <div>
            <Image
              src="/svg/noProjects.svg"
              alt="No projects found"
              height={200}
              width="100%"
            />
          </div>
          <div>
            {displayMessage.title !== '' ? (
              <Center mt="5%">
                <Title data-testid={'display-message-title'}>
                  {displayMessage.title}
                </Title>
              </Center>
            ) : null}

            <Center mt="5%">
              <p
                data-testid={'display-message-text'}
                className={classes.messageText}
              >
                {displayMessage.text}
              </p>
            </Center>
            {displayMessage.showBtnAddProject === true ? (
              <Center>
                <Button fullWidth radius={8} color="flexbase-teal" size="md">
                  + Add Project
                </Button>
              </Center>
            ) : null}
          </div>
        </div>
      ) : (
        <>
          <Table className="table">
            <thead>
              <tr>
                <th
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Project
                </th>
                <th
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Address
                </th>
                <th
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project: any) => (
                <TableRow key={project.id} {...{ setAllProjects, project }} />
              ))}
            </tbody>
          </Table>
          <Pagination
            style={{ marginTop: '1%' }}
            total={totalPageCount}
            value={currentPage}
            onChange={(page) => {
              setCurrentPage(page);
              scroll(0, 0);
            }}
            color="flexbase-teal"
            position="right"
          />
        </>
      )}
    </div>
  );
};

export default Projects;
