import { ReactNode } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import {
  createStyles,
  Group,
  Image,
  Stack,
  useMantineTheme,
} from '@mantine/core';

import { FlexIcon } from 'assets/svg';
import Frame from 'assets/images/loginFrame.png';
import LoginImage from 'assets/images/login-image.png';

interface AuthPageContainerProps {
  children: ReactNode;
}

const AuthPageContainer = ({ children }: AuthPageContainerProps) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 768px)');

  return (
    <div className={classes.container}>
      <FlexIcon color={theme.colors.neutral[2]} width={110} />
      <Group position="apart" mt={useMobileView ? 20 : 50}>
        <Stack pos="relative" w={360}>
          {children}
        </Stack>
        {!useMobileView && (
          <div className={classes.imageContainer}>
            <Image src={LoginImage} height="100vh" />
          </div>
        )}
      </Group>
    </div>
  );
};

export default AuthPageContainer;

const useStyles = createStyles((theme) => ({
  container: {
    padding: '80px',
    minHeight: '100vh',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Frame})`,
    backgroundColor: theme.colors.primarySecondarySuccess[8],
    '@media (max-width: 768px)': {
      padding: '20px',
    },
  },
  imageContainer: {
    top: '0',
    right: '0',
    width: '50%',
    position: 'absolute',
  },
}));
