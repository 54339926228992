import { Button, Tooltip } from '@mantine/core';
import { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { TbFlag3, TbReceipt } from 'react-icons/tb';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useStyles } from './expense-management.styles';
import { MemoIcon } from 'assets/svg';

export enum ActionStatus {
  complete,
  incomplete,
  flagged,
  default,
}

interface Action {
  name: string;
  icon?: ReactElement<IconType>;
  action?: () => void;
  status?: ActionStatus | null;
  disabled?: boolean;
  pointerDisabled?: boolean;
  tooltip?: string;
  toolTipDisabled?: boolean;
}

interface Props {
  receiptAction?: () => void;
  receiptActionStatus?: ActionStatus | null;
  memoAction?: () => void;
  memoActionStatus?: ActionStatus | null;
  approveAction?: () => void;
  approveActionStatus?: ActionStatus | null;
  flagAction?: () => void;
}

const ExpenseManagement = ({
  receiptAction,
  receiptActionStatus = null,
  memoAction,
  memoActionStatus = null,
  approveAction,
  approveActionStatus = null,
  flagAction,
}: Props) => {
  const { classes } = useStyles();

  const actions: Action[] = [
    {
      name: 'Upload receipt',
      icon: <TbReceipt />,
      action: receiptAction,
      status: receiptActionStatus,
      tooltip:
        receiptActionStatus === ActionStatus.complete
          ? 'Receipt Uploaded'
          : 'Upload Receipt',
    },
    {
      name: 'Add Memo',
      icon: (
        <MemoIcon
          style={{
            marginLeft: '3px',
            marginTop: '3px',
          }}
          width={18}
          height={18}
        />
      ),
      action: memoAction,
      status: memoActionStatus,
      tooltip:
        memoActionStatus === ActionStatus.complete ? 'Memo Added' : 'Add Memo',
    },
    {
      name: 'Approve',
      icon: <AiOutlineCheckCircle />,
      action: approveAction,
      status: approveActionStatus,
      tooltip:
        approveActionStatus === ActionStatus.complete
          ? 'Synced with Quickbooks'
          : 'Not synced with Quickbooks',
    },
    {
      name: 'Flag transaction',
      icon: <TbFlag3 />,
      action: flagAction,
      tooltip: 'Dispute Transaction',
    },
  ];

  const getStatusColor = (action: Action) => {
    let statusColor;

    switch (action.status) {
      case ActionStatus.complete:
        statusColor = '#27C281';
        break;
      case ActionStatus.incomplete:
        statusColor = '#D80027';
        break;
      case ActionStatus.flagged:
        statusColor = '#D80027';
        break;
      default:
        statusColor = '#979797';
        break;
    }

    return statusColor;
  };

  return (
    <div className={classes.baseContainer}>
      {actions.map((action) => {
        return (
          //TODO: remove tooltip after V2
          <Tooltip
            key={actions.indexOf(action)}
            label={action.tooltip}
            disabled={action.toolTipDisabled}
          >
            <Button
              style={{
                color: getStatusColor(action),
                fill: getStatusColor(action),
                pointerEvents: action.pointerDisabled ? 'none' : 'auto',
              }}
              className={classes.button}
              key={actions.indexOf(action)}
              onClick={action.action}
              disabled={action.disabled}
            >
              {action.icon}
            </Button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ExpenseManagement;
