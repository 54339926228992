import { useEffect, useState } from 'react';
import { Alert, Group, Image, Select, Text } from '@mantine/core';

import { useStyles } from '../styles';
import { flexbaseBankingClient } from 'services/flexbase-client';
import FlexbaseTable from 'components/table/flexbase-table';

import SkeletonLoading from 'components/loading/skeleton-loading';
import { Analytics } from 'services/analytics/analytics';
import type { Statement } from 'services/flexbase/banking.model';
import AccountSelection from '../../payments/components/account-selection';
import { DepositAccount } from '../move-funds/move-funds.model';
import { useSearchParams } from 'react-router-dom';
import { ChevronDownIcon, DownloadIcon, RedAlertIcon } from 'assets/svg';
import WatchBAnking from 'assets/images/watch-banking.png';

const Statements = () => {
  const { classes, theme } = useStyles();
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [statements, setStatements] = useState<Statement[]>([]);
  // Due to limitations with the Account boxes, it needs a specific model and exposes an entire object as the selected one
  const [accounts, setAccounts] = useState<DepositAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<DepositAccount>();
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('accountId');
  const [years, setYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>();
  const [statementsByYear, setStatementsByYear] = useState<Statement[]>([]);

  const columns = [
    {
      name: 'Date',
      cell: (statement: Statement) => statement.attributes.period,
      selector: (statement: Statement) => statement.attributes.period,
      sortable: true,
      grow: 6,
    },
    {
      name: 'PDF',
      cell: (statement: Statement) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() =>
            getStatement(statement.id, statement.attributes.period)
          }
        >
          <DownloadIcon
            width={20}
            color={theme.fn.themeColor('primarySecondarySuccess', 2)}
          />
        </div>
      ),
      compact: true,
      center: true,
    },
  ];

  const getStatement = async (statementId: string, statementName: string) => {
    try {
      const statement = await flexbaseBankingClient.getStatement(statementId, {
        isPdf: true,
      });
      Analytics.track('Banking Statement Requested', {
        success: true,
      });
      const arr = new Uint8Array(statement as ArrayBuffer);
      const blobURL = URL.createObjectURL(
        new Blob([arr], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');
      link.href = blobURL;
      link.download = statementName;
      link.click();
    } catch (error) {
      Analytics.track('Banking Statement Requested', {
        success: false,
      });
      console.error(error);
      setAlertMessage('We are unable to download the document');
    }
  };

  const getAccounts = async () => {
    setLoading(true);
    try {
      const depositAccountResponse =
        await flexbaseBankingClient.getDepositList();
      const accts = depositAccountResponse.accounts.map((a) => ({
        ...a,
        plaidOrDeposit: 'deposit' as any,
      }));
      setAccounts(accts);

      if (accts.length > 0) {
        if (accountId) {
          const foundAccount = accts.find((a) => a.id === accountId);
          if (foundAccount) {
            setSelectedAccount(foundAccount);
          }
        } else {
          setSelectedAccount(accts[0]);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('We are unable to retrieve any statements at the moment');
    } finally {
      setLoading(false);
    }
  };

  const filterStatementsByYear = (year: string, _statements: Statement[]) => {
    const filteredStatements = _statements.filter((statement) => {
      const [statementYear] = statement.attributes.period.split('-');
      return statementYear === year;
    });
    return setStatementsByYear(filteredStatements);
  };

  const getStatementsByAccountId = async (accountId: string) => {
    setLoading(true);
    try {
      const _statements = await flexbaseBankingClient.getStatements({
        depositAccountId: accountId,
        sort: '-period',
      });

      // filter statements per year
      const extractYears = _statements.map((statement) => {
        const [year] = statement.attributes.period.split('-');
        return year;
      });

      const uniqueYears = [...new Set(extractYears)];

      setYears(uniqueYears);
      setStatements(_statements);
      setSelectedYear(uniqueYears[0]);
      filterStatementsByYear(uniqueYears[0], _statements);
    } catch (e) {
      setErrorMessage('We are unable to retrieve any statements at the moment');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      getStatementsByAccountId(selectedAccount.id);
    }
  }, [selectedAccount]);

  if (errorMessage && errorMessage !== '') {
    return (
      <div className={classes.emptyState}>
        <Image
          width={200}
          src={WatchBAnking}
          alt="Display info error"
          className="image-container"
        />
        <Text ff="redaction" size={36} align="center" mt={30}>
          Sorry about that...
        </Text>
        <p>{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className={classes.statementContent}>
      {alertMessage && alertMessage !== '' && (
        <div className={classes.alert}>
          <Alert
            color="red"
            title="Something went wrong"
            icon={<RedAlertIcon />}
            onClose={() => setAlertMessage('')}
          >
            {alertMessage}
          </Alert>
        </div>
      )}
      <div className={classes.card}>
        {loading ? (
          <SkeletonLoading />
        ) : (
          <>
            <Group position="apart">
              <div>
                {accounts.length > 0 && selectedAccount && (
                  <AccountSelection
                    label=""
                    currentAccount={selectedAccount}
                    accounts={accounts}
                    onAccountChange={(c) => {
                      // need to rework account select this annoying
                      const foundAccount = accounts.find((a) => a.id === c.id);
                      if (foundAccount) {
                        setSelectedAccount(foundAccount);
                      }
                    }}
                    classNames={{
                      target: classes.accountSelectTarget,
                      list: classes.accountSelectList,
                    }}
                  />
                )}
              </div>
              <Group>
                <Select
                  w={175}
                  data={years}
                  value={selectedYear}
                  onChange={(value) => {
                    if (value) {
                      setSelectedYear(value);
                      filterStatementsByYear(value, statements);
                    }
                  }}
                  rightSection={
                    <ChevronDownIcon
                      color={theme.fn.themeColor('primarySecondarySuccess', 8)}
                    />
                  }
                />
              </Group>
            </Group>
            <FlexbaseTable
              data={statementsByYear}
              columns={columns}
              noDataComponent={
                <Text size={24} fw={500}>
                  There are no statements to display
                </Text>
              }
              pagination={statements.length > 8}
              isFetchingData={loading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Statements;
