import { Button, Menu, Text, createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { PlusSignIcon, SearchIcon } from 'assets/svg';
import useModal from 'components/modal/modal-hook';
import RemoveRecipient from './components/recipients/remove-recipient';
import { Recipient } from './components/recipients/recipient';
import { groupBy } from 'underscore';
import { Counterparty } from 'services/flexbase/banking.model';
import ViewRecipientModal from './components/recipients/view-recipient';
import { DateTime } from 'luxon';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Analytics } from 'services/analytics/analytics';
import { FlexbaseTable } from 'components/table';
import MenuCell from 'components/table/cells/menu-cell';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';
import { useMediaQuery } from '@mantine/hooks';
import FlexbaseInput from 'components/input/flexbase-input';

const Recipients = () => {
  const { classes } = useStyles();
  const { openModal } = useModal();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [tableData, setTableData] = useState<Recipient[]>([]);
  const [filterText, setFilterText] = useState('');
  const user = useRecoilValue(UserInfoState);
  const navigate = useNavigate();

  // service call to get available counterparties
  const getRecipients = async () => {
    try {
      setLoading(true);
      const { counterparties } =
        await flexbaseBankingClient.getCounterPartyList();

      const groupedCounterparties = groupBy(counterparties, 'accountName');

      // convert to a recipient object for use client side
      const recipients: Recipient[] = [];
      for (const [key, value] of Object.entries(groupedCounterparties)) {
        recipients.push({
          name: key,
          id: key,
          counterparties: value,
        });
      }

      setTableData(recipients);
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to retrieve external accounts.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecipients();
  }, []);

  // table data
  const getFilteredTableData = useCallback(() => {
    const normalizedFilterText = filterText.trim().toLowerCase();

    return tableData.filter((d: Recipient) => {
      return d.name.toLowerCase().includes(normalizedFilterText);
    });
  }, [filterText, tableData]);

  const columns: TableColumn<Recipient>[] = [
    {
      name: 'Name',
      sortable: true,
      sortFunction: (a, b) => a?.name.localeCompare(b?.name),
      selector: (row) => row.name,
      grow: isMobile ? 2 : 5,
    },
    {
      name: 'Last paid',
      selector: (row) => {
        const isValidCheck = (dateT: string) => {
          if (DateTime.fromSQL(dateT).isValid) {
            return DateTime.fromSQL(dateT);
          }
          console.log(`${dateT} is not valid`);
          return null;
        };

        let lastUsed = row.counterparties.map((c) =>
          isValidCheck(c.lastUsedAt),
        ) as DateTime[];

        lastUsed = lastUsed.filter((n) => n); // filter nulls so we dont get invalid results for DateTime max
        if (lastUsed.length > 0) {
          return DateTime.max(...lastUsed)
            .toFormat('LLL d, yyyy')
            .toString();
        }
        return '-';
      },
      sortable: true,
      grow: 2,
      style: {
        fontSize: isMobile ? '12px' : '14px',
      },
    },
    {
      grow: 1,
      right: true,
      cell: (row: {
        id: string;
        name: string;
        counterparties: Counterparty[];
      }) => (
        <div style={{ marginRight: '1vh' }}>
          <MenuCell position="bottom-end">
            <Menu.Item
              styles={{ fontSize: '14px' }}
              data-testid={`view-payments-${row.name}`}
              onClick={() => {
                Analytics.track('Payments View Recipient Transactions');
                navigate({
                  pathname: '/payments/outgoing',
                  search: createSearchParams({
                    recipient: `${row.name}`,
                  }).toString(),
                });
              }}
            >
              View Payments
            </Menu.Item>
            <Menu.Item
              styles={{ fontSize: '14px' }}
              data-testid={`send-payment-${row.name}`}
              onClick={() =>
                navigate({
                  pathname: '/payments/outgoing/recipient',
                  search: createSearchParams({
                    recipient: `${row.name}`,
                  }).toString(),
                })
              }
            >
              Send a Payment
            </Menu.Item>
            <Menu.Item
              styles={{ fontSize: '14px' }}
              data-testid={`view-details-${row.name}`}
              onClick={() => onRowClicked(row)}
            >
              View Recipient Details
            </Menu.Item>
            <Menu.Item
              styles={{ fontSize: '14px' }}
              data-testid={`remove-recipient-${row.name}`}
              onClick={() => {
                openModal(
                  'Are you sure you want to remove this recipient?',
                  <RemoveRecipient
                    recipientId={row.id}
                    counterparties={row.counterparties}
                    name={row.name}
                    refreshRecipients={getRecipients}
                  />,
                  {
                    showClose: false,
                  },
                );
              }}
            >
              Remove Recipient
            </Menu.Item>
          </MenuCell>
        </div>
      ),
      compact: true,
    },
  ];

  const openAddRecipientModal = () => {
    navigate('/payments/outgoing/recipient');
  };

  const viewOrEditRecipientModal = (recipient: Recipient) => {
    openModal(
      `${recipient.name}`,
      <ViewRecipientModal
        recipient={recipient}
        refreshRecipients={async () => {
          await getRecipients();
        }}
      />,
    );
  };

  const onRowClicked = (row: Recipient) => {
    viewOrEditRecipientModal(row);
  };

  return (
    <>
      <div className={classes.addRecipientButton}>
        <Button
          onClick={openAddRecipientModal}
          data-testid={'add-recipient'}
          variant="light"
          disabled={!user.roles.includes('ADMIN')}
        >
          <PlusSignIcon width={12} height={12} style={{ marginRight: 5 }} />
          Add Recipient
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.header}>
          <Text className={classes.title}>Payment Recipients</Text>
          <FlexbaseInput
            w={isMobile ? '100%' : '300px'}
            placeholder="Search external accounts"
            onChange={(e) => setFilterText(e.target.value)}
            icon={<SearchIcon width={20} height={20} />}
          />
        </div>
        <FlexbaseTable
          columns={columns}
          data={getFilteredTableData()}
          pagination={
            getFilteredTableData() && getFilteredTableData()?.length > 8
          }
          onRowClicked={onRowClicked}
          isFetchingData={loading}
        />
      </div>
    </>
  );
};

export default Recipients;

export const useStyles = createStyles((theme) => ({
  addRecipientButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0px',
  },
  container: {
    padding: 12,
    backgroundColor: 'white',
    borderRadius: theme.defaultRadius,
    maxWidth: '1365px',
    margin: 'auto',
    marginBottom: '20px',
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: '#E6E7E9',
    borderBottomWidth: 0.5,
    paddingBottom: 16,
    paddingTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      gap: 15,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: theme.fn.primaryColor(),
    fontFamily: 'PP Neue Montreal',
  },
}));
