import { FlexbaseAuthenticationTokenAccessor } from 'flexbase-client';
import wretch from 'wretch';
import { FlexbaseClientLogin } from './flexbase-client.login';
import { FlexbaseOnboardingClient } from './flexbase/flexbase-onboarding-client';
import { FlexbaseBankingClient } from './flexbase/flexbase-banking-client';
import { KEY_TOKEN_STORAGE } from '../states/auth/auth-token';

const tokenAccessor = new FlexbaseAuthenticationTokenAccessor();

const flexbaseClient = new FlexbaseClientLogin(
  wretch(import.meta.env.VITE_APP_FLEXBASE_API_URL)
    .options({
      headers: { Accept: 'application/json' },
    })
    .accept('application/json')
    .middlewares([
      (next) => async (url, opts) => {
        const token = localStorage?.getItem(KEY_TOKEN_STORAGE);

        const requestOptions = {
          ...opts,
          headers: {
            ...(opts.headers || {}),
            ...(!!token && { Authorization: `Bearer ${token}` }),
          },
        };

        return next(url, requestOptions);
      },
    ]),
  tokenAccessor,
);

export const flexbaseOnboardingClient = new FlexbaseOnboardingClient(
  flexbaseClient,
);

export const flexbaseBankingClient = new FlexbaseBankingClient(flexbaseClient);

export default flexbaseClient;
