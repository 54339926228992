import type { AccountProps } from 'areas/banking/move-funds/move-funds.model';

export interface PaymentForm {
  id?: string;
  amount: string;
  status?: string;
  reason?: string;
  fromAccount?: AccountProps;
  toAccount?: AccountProps;
}

export const paymentFormInitial = {
  id: '',
  amount: '',
  fromAccount: undefined,
  toAccount: undefined,
} as PaymentForm;
