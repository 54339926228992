import {
  Group,
  createStyles,
  LoadingOverlay,
  UnstyledButton,
} from '@mantine/core';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseIcon, FlexIcon } from 'assets/svg';
import {
  AUTHORIZATIONS,
  DepositAccount,
  PlaidAccount,
} from '../move-funds/move-funds.model';
import {
  ApplicationState,
  getProductOnboardingStatus,
} from 'states/application/product-onboarding';
import AddFundsOptions from './add-funds-options';
import useModal from 'components/modal/modal-hook';
import { Analytics } from 'services/analytics/analytics';
import { showNotification } from '@mantine/notifications';
import ModalError from '../components/modal-error/modal-error';
import { flexbaseBankingClient } from 'services/flexbase-client';
import ModalSuccess from '../components/modal-success/modal-success';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { FinancialInstitution } from 'states/onboarding/onboarding-info';
import { PaymentForm, paymentFormInitial } from 'states/banking/payments';
import { usePlaidBankingComponent } from 'components/banking/plaid-banking-component';

export enum AddFundsSteps {
  ADD_FUNDS,
  SUCCESS,
  LOADING,
  ERROR,
}

const AddFunds = () => {
  const navigate = useNavigate();
  const { closeAllModals } = useModal();
  const [error, setError] = useState('');
  const [transferForm, setTransferForm] =
    useState<PaymentForm>(paymentFormInitial);
  const { classes } = useStyles({ amount: '0' });
  const [content, setContent] = useState(AddFundsSteps.LOADING);
  const [transferLoading, setTransferLoading] = useState(false);
  const [needsLinkAccount, setNeedsLinkAccount] = useState(false);
  const depositAccount = transferForm.toAccount as DepositAccount;
  const [plaidAccounts, setPlaidAccounts] = useState<PlaidAccount[]>([]);
  const [linkingAccountLoading, setLinkingAccountLoading] = useState(false);
  const [{ company }, setCompany] = useRecoilState(ApplicationState);
  const [depositAccounts, setDepositAccounts] = useState<DepositAccount[]>([]);

  const getAccounts = async (compPlaidAccounts: FinancialInstitution[]) => {
    try {
      // set linked Plaid accounts
      const plaidLinkedAccounts = compPlaidAccounts
        .filter((acc) => acc.active && !acc.unlinked)
        .map((acc) => {
          return { ...acc, plaidOrDeposit: 'plaid' } as PlaidAccount;
        });
      setPlaidAccounts(plaidLinkedAccounts);

      // set deposit accounts
      const result = await flexbaseBankingClient.getDepositList();
      if (result.success) {
        const activeAccounts = result.accounts.filter(
          (acc) => acc.status === 'Open',
        );
        const accountsData: DepositAccount[] = activeAccounts.map((account) => {
          return { ...account, plaidOrDeposit: 'deposit' } as DepositAccount;
        });
        setDepositAccounts(accountsData);
        setContent(AddFundsSteps.ADD_FUNDS);
      } else {
        setError('Unable to get your deposit accounts. Try again later.');
        setContent(AddFundsSteps.ERROR);
      }
    } catch (error) {
      console.error('error getting deposit accounts', error);
      setError('Unable to get your deposit accounts. Try again later.');
      setContent(AddFundsSteps.ERROR);
    }
  };

  const makeTransfer = async (values: PaymentForm) => {
    try {
      setTransferLoading(true);

      const transferFormToAccount = values.toAccount as DepositAccount;
      // fund banking account with plaid linked account
      const result = await flexbaseBankingClient.makePayment({
        type: 'ach',
        direction: 'Debit',
        description: 'Funding',
        accountId: transferFormToAccount.id,
        plaidTokenId: values.fromAccount!.id,
        amount: formatCurrency(values.amount),
        authorizations: [AUTHORIZATIONS.achDebit],
      });

      if (result.success && result.payment) {
        setTransferForm({
          ...values,
          status: result.payment.status,
          reason: result.payment.reason,
          id: result.payment.id,
        });
        setContent(AddFundsSteps.SUCCESS);
      }
      Analytics.track('Banking Payment Submitted', {
        ...result,
      });
    } catch (error) {
      console.error('unable to make payment', error);
      const { payment = {}, error: errorMsg = '' } = JSON.parse(error.message);

      if (payment.status === 'Rejected' && payment.reason) {
        setTransferForm({
          ...transferForm,
          status: payment.status,
          reason: payment.reason,
        });
        setContent(AddFundsSteps.SUCCESS);
      } else if (errorMsg.includes('expired')) {
        setError(
          'Your Plaid account connection has expired, please relink plaid by logging out and logging back in again.',
        );
      } else {
        setError('Unable to make the payment. Please, try it again');
      }
      setContent(AddFundsSteps.ERROR);
    } finally {
      setTransferLoading(false);
    }
  };

  // plaid logic when there are no linked accounts yet
  const { open, ready } = usePlaidBankingComponent({
    onSuccess: () => onLinkSuccess(),
    onError: () => {
      closeModal();
      showNotification({
        color: 'red',
        title: 'Error',
        message: 'Unable to link bank account',
      });
    },
    setLoading: (loading) => setLinkingAccountLoading(loading),
  });

  const onLinkSuccess = async () => {
    setLinkingAccountLoading(true);
    // update company recoil value with the new linked accounts
    const { company } = await getProductOnboardingStatus();
    setCompany((prev) => ({ ...prev, company }));
    setNeedsLinkAccount(false);
    await getAccounts(company.financialInstitutions);
    setLinkingAccountLoading(false);
  };

  useEffect(() => {
    if (needsLinkAccount && ready) {
      open();
    }
  }, [needsLinkAccount, ready]);

  // generate the contents of this flow based on content state
  const getContents = () => {
    switch (content) {
      case AddFundsSteps.ADD_FUNDS:
        return (
          <AddFundsOptions
            onLinkAccount={setNeedsLinkAccount}
            onMakeTransfer={(values: PaymentForm) => makeTransfer(values)}
            {...{ depositAccounts, transferLoading, plaidAccounts }}
          />
        );

      case AddFundsSteps.SUCCESS:
        return (
          <ModalSuccess
            backTo="dashboard"
            closeModal={() => closeModal()}
            onClick={() => setContent(AddFundsSteps.ADD_FUNDS)}
            title={`You’ve transferred ${formatCurrency(
              transferForm.amount,
            )} to ${depositAccount.nickName} ${depositAccount.accountNumber}`}
            textToStart="Make another transfer"
            status={transferForm.status}
            reason={transferForm.reason}
          />
        );

      case AddFundsSteps.LOADING:
        return <LoadingOverlay visible={true} />;

      case AddFundsSteps.ERROR:
        return (
          <ModalError errorMessage={error} onGoBack={() => closeModal()} />
        );
    }
  };

  const closeModal = () => {
    navigate(-1);
    closeAllModals();
  };

  useEffect(() => {
    getAccounts(company.financialInstitutions);
  }, []);

  return (
    <Group className={classes.container} position="apart">
      {linkingAccountLoading && <LoadingOverlay visible={true} />}
      <div>
        <FlexIcon width={90} />
      </div>
      <div>{getContents()}</div>
      <div>
        <UnstyledButton onClick={closeModal} className={classes.closeButton}>
          <CloseIcon />
        </UnstyledButton>
      </div>
    </Group>
  );
};

export default AddFunds;

interface StylesProps {
  amount: string;
}

export const useStyles = createStyles((theme, { amount }: StylesProps) => ({
  container: {
    padding: '50px',
    minHeight: '100vh',
    alignItems: 'start',
    backgroundColor: theme.fn.themeColor('neutral', 2),
  },
  avatarPlaceholder: {
    color: '#fff',
    fontSize: '20px',
  },
  contentContainer: {
    marginTop: '20px',
    maxWidth: '420px',
    backgroundColor: '#fff',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  closeButton: {
    display: 'flex',
    color: theme.fn.themeColor('neutral', 5),
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    color: '#000',
    fontWeight: 400,
    fontSize: '32px',
    padding: '30px 30px 0px 30px',
  },
  rowContent: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chevronIcon: {
    transform: 'rotate(-90deg)',
    fill: theme.colors.blackish[2],
  },
  icon: {
    padding: '8px',
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: theme.colors['flexbase-rose'],
  },
  infoContent: {
    color: '#000',
    fontWeight: 400,
    fontSize: '24px',
    p: {
      marginTop: 5,
      fontWeight: 400,
      fontSize: '14px',
      color: theme.fn.themeColor('neutral', 6),
    },
  },
  cursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badgeInner: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.fn.themeColor('neutral', 7),
  },
  badgeRoot: {
    height: 28,
    padding: '6px 14px',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.themeColor('tertiary', 1),
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '12px',
    color: theme.colors.blackish[3],
  },
  input: {
    height: '42px',
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.colors.blackish[3],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    p: {
      margin: '0px',
      fontSize: '14px',
    },
    '&:hover': {
      cursor: 'pointer',
      p: {
        textDecoration: 'underline',
      },
    },
  },
  target: {
    borderRadius: '10px',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  amountInput: {
    border: 'none',
    marginTop: 20,
    marginBottom: 34,
    fontSize: '48px',
    borderRadius: '0px',
    textAlign: 'center',
    height: 'fit-content',
    fontFamily: 'PP Neue Montreal',
    color: amount === '0' ? '#C5C2BE' : '#000',
    borderBottom: `3px solid ${theme.fn.themeColor(
      'primarySecondarySuccess',
      2,
    )}`,
    '&:focus': {
      borderBottom: `3px solid ${theme.fn.themeColor(
        'primarySecondarySuccess',
        2,
      )}`,
    },
  },
  accountsSection: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '20px 0px',
    color: theme.colors.blackish[3],
  },
  disclaimer: {
    width: 200,
    fontWeight: 400,
    fontSize: '12px',
    color: theme.colors.blackish[3],
  },
  flexContent: {
    gap: 20,
    display: 'flex',
    alignItems: 'center',
  },
  IconSection: {
    padding: '8px',
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    backgroundColor: theme.colors['flexbase-rose'][0],
  },
  accordionRoot: {
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  accordionChevron: {
    marginTop: 20,
  },
  textInfo: {
    fontSize: 12,
    marginTop: 24,
    fontWeight: 400,
    marginBottom: 10,
    textAlign: 'center',
    color: theme.fn.themeColor('neutral', 7),
  },
}));
