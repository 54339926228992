import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Button, Group, TextInput } from '@mantine/core';

import { useStyles } from '../styles';
import { AlertIcon } from 'assets/svg';
import { PaymentForm } from 'states/banking/payments';
import { formatCurrency } from 'utilities/formatters/format-currency';
import AccountSelection from 'areas/payments/components/account-selection';
import { AccountProps, DepositAccount, PlaidAccount } from './move-funds.model';

interface Props {
  form: PaymentForm;
  onBackClick: () => void;
  // when the user wants to transfer funds from External Accounts tab
  initPlaidAccount?: PlaidAccount;
  bankingAccounts: DepositAccount[];
  // when the user wants to add funds from Accounts tab
  initDepositAccount?: DepositAccount;
  plaidLinkedAccounts: PlaidAccount[];
  onContinue: (values: PaymentForm) => void;
}

const TransferDetails = ({
  form,
  onContinue,
  onBackClick,
  bankingAccounts,
  initPlaidAccount,
  initDepositAccount,
  plaidLinkedAccounts,
}: Props) => {
  const { classes } = useStyles();
  const [isPlaidAccountSelected, setIsPlaidAccountSelected] = useState(
    (initPlaidAccount && true) || false,
  );

  const transferForm = useForm<PaymentForm>({
    initialValues: {
      ...form,
      fromAccount: form.fromAccount ?? bankingAccounts[0],
      toAccount: form.toAccount ?? plaidLinkedAccounts[0],
      id: '',
    },
    validate: {
      amount: (value) =>
        ['', '00.00', '0.00'].includes(value) ? 'Amount is required' : null,
    },
  });

  const selectAccount = (value: AccountProps, accountType: string) => {
    transferForm.setValues({
      ...transferForm.values,
      [`${accountType}Account`]: value,
    });
  };

  const transferFrom = (value: AccountProps) => {
    selectAccount(value, 'from');
    setIsPlaidAccountSelected(value.plaidOrDeposit === 'plaid');
  };

  const transferTo = (value: AccountProps) => {
    selectAccount(value, 'to');
  };

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length === 0) {
      transferForm.setFieldValue('amount', '0');
      return;
    }

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else if (currentValue.length > 9) {
      return;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    transferForm.setFieldValue('amount', currentValue);
  };

  const onContinueClick = () => {
    if (
      transferForm.values.fromAccount?.id === transferForm.values.toAccount?.id
    ) {
      return;
    }
    const validationResult = transferForm.validate();
    if (!validationResult.hasErrors) {
      onContinue(transferForm.values);
    }
  };

  useEffect(() => {
    // check if user has preselected a plaid account, set account on initial render
    if (initPlaidAccount) {
      setIsPlaidAccountSelected(true);
      transferForm.setFieldValue('fromAccount', initPlaidAccount);
    }

    // check if user has preselected a deposit account, set account on initial render
    if (initDepositAccount) {
      const firstBankingAccount = bankingAccounts[0];
      transferForm.setFieldValue(
        'fromAccount',
        initDepositAccount.id === firstBankingAccount.id &&
          bankingAccounts.length > 1
          ? bankingAccounts[1]
          : plaidLinkedAccounts[0],
      );
      transferForm.setFieldValue('toAccount', initDepositAccount);
    }
  }, []);

  useEffect(() => {
    // update the form toAccount values if user switches between plaid and deposit accounts
    if (isPlaidAccountSelected) {
      transferForm.setFieldValue('toAccount', bankingAccounts[0]);
    }
  }, [isPlaidAccountSelected]);

  return (
    <div className={classes.card}>
      <div className="move_funds">
        <span>
          Transfer funds
          <br />
          between accounts{' '}
        </span>
        <TextInput
          mb={20}
          variant="unstyled"
          data-testid="amount"
          error={transferForm.errors.amount}
          classNames={{ input: classes.amountInput }}
          onChange={(v) => onInputChange(v.currentTarget.value)}
          value={formatCurrency(transferForm.values.amount || '0')}
        />
        <div className="accounts_select" data-testid="transfer-from">
          <AccountSelection
            label="Transferring from"
            currentAccount={transferForm.values.fromAccount!}
            accounts={[...bankingAccounts, ...plaidLinkedAccounts]}
            onAccountChange={(c) => {
              transferFrom(c);
            }}
          />
        </div>
        <div className="accounts_select" data-testid="transfer-to">
          <AccountSelection
            label="Transferring to"
            currentAccount={transferForm.values.toAccount!}
            accounts={
              isPlaidAccountSelected
                ? bankingAccounts
                : [...plaidLinkedAccounts, ...bankingAccounts]
            }
            onAccountChange={(c) => transferTo(c)}
          />
        </div>
        {transferForm.values.fromAccount?.id ===
          transferForm.values.toAccount?.id && (
          <div className="warning_alert" data-testid={'error-message'}>
            <AlertIcon />
            You can&apos;t send to/from the same account
          </div>
        )}
        <Group position="apart" mt={30}>
          <Button
            variant="outline"
            onClick={onBackClick}
            data-testid={'button-back'}
          >
            Back
          </Button>
          <Button
            variant="light"
            onClick={onContinueClick}
            data-testid={'button-next'}
          >
            Continue
          </Button>
        </Group>
      </div>
    </div>
  );
};

export default TransferDetails;
