import { createStyles } from '@mantine/core';

interface StyleProps {
  flagMenuHeight: '115px' | '235px';
}

export const useStyles = createStyles(
  (theme, { flagMenuHeight }: StyleProps) => ({
    baseContainer: {
      padding: 0,
      '> *': {
        width: '100%',
      },
      overflowY: 'scroll',
      '@media(max-width: 767px)': {
        marginTop: 0,
        marginBottom: 0,
        padding: 0,
      },
    },
    headerRow1: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 35,
      backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 8),
      '> div:last-of-type': {
        marginRight: '-11px',
      },
    },
    headerRow2: {
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '@media(max-width: 767px)': {
        marginTop: '20px',
      },
    },
    avatar: {
      height: '50px',
      width: '50px',
      color: theme.fn.primaryColor(),
      '> div:first-of-type': {
        color: `${theme.fn.primaryColor()} !important`,
        backgroundColor: '#FFEEEC !important',
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'PP Neue Montreal',
      },
      marginRight: '20px',
    },
    storeLogo: {
      borderRadius: '8px',
      width: '50px',
      height: '50px',
      objectFit: 'contain',
      objectPosition: 'center',
      marginRight: '20px',
    },
    amount: {
      width: '151px',
      height: '43px',
      color: theme.fn.themeColor('neutral', 0),
      fontFamily: 'PP Neue Montreal',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '36px',
      lineHeight: '43px',
    },
    closeButton: {
      display: 'flex',
      width: '34px',
      boxSizing: 'content-box',
      flexDirection: 'column',
      color: '#979797',
      cursor: 'pointer',
      borderRadius: '8px',
      alignItems: 'center',
      backgroundColor: 'unset',
      border: '1px solid transparent',
      '&:focus': {
        outline: 'none',
      },
    },
    dateTime: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: theme.fn.themeColor('neutral', 0),
    },
    flagButtonTop: {
      borderRadius: '8px',
      width: '150px',
      height: '28px',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '15px',
      borderColor: theme.fn.primaryColor(),
      color: theme.fn.primaryColor(),
      cursor: 'default',
      '&:hover': {
        background: '#ffffff',
      },
    },
    section: {
      borderTop: '1px solid #E6E7E9',
      padding: 20,
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '> *': {
        flex: 1,
      },
      marginBottom: '50px',
      '&:first-of-type': {
        marginTop: '32px;',
      },
      '&:last-of-type': {
        marginBottom: '32px;',
      },
      '@media(max-width: 767px)': {
        marginBottom: '30px',
      },
    },
    infoCluster: {
      display: 'flex',
      flexDirection: 'column',
    },
    infoTitleFlex: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
    },
    infoTitle: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#4B4B4B',
    },
    infoValue: {
      marginTop: '10px',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '22px',
      color: '#4B4B4B',
    },
    sectionTitle: {
      marginTop: '26px',
      marginBottom: ' 20px',
      display: 'flex',
      alignItems: 'center',
    },
    dropZone: {
      width: '100%',
      height: '183px',
      padding: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed black',
      borderRadius: '16px',
      backgroundColor: theme.fn.themeColor('neutral', 2),
      '&:hover': {
        backgroundColor: 'rgba(57, 160, 255, 0.04)',
      },
      marginBottom: '32px',
      position: 'relative',
      '> div:first-of-type': {
        position: 'absolute',
        zIndex: 1,
        display: 'flex',
        top: '60px',
        '> button': {
          height: '30px',
          width: '30px',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px',
          background: '#eeeeeecc',
          mixBlendMode: 'normal',
          zIndex: 99,
          margin: '3px',
          cursor: 'pointer !important',
          border: 'none',
        },
      },
    },
    dropZonePlaceholder: {
      marginTop: '14px',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '130%',
      display: 'inline-block',
      alignItems: 'center',
      textAlign: 'center',
      color: '#757575',
      '> span': {
        display: 'inline',
        color: theme.fn.primaryColor(),
      },
    },
    roundIcons: {
      height: '30px',
      width: '30px',
      borderRadius: '50%',
      background: '#6B6B6BAA',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: 5,
      svg: {
        color: 'white',
      },
    },
    containerMemoInput: {
      opacity: 0.8,
      border: '1px solid black',
      borderRadius: theme.defaultRadius,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#5F5F5F',
      marginBottom: '15px',
    },
    memoInput: {
      padding: 8,
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
    memoButtons: {
      overflow: 'hidden',
      transition: 'max-height 0.15s ease-out',
      display: 'flex',
      justifyContent: 'right',
      '> *': {
        marginLeft: '20px',
      },
      marginBottom: '20px',
    },
    switch: {
      marginRight: '15px',
      '> input': {
        width: '52px',
        height: '30px',
        ':before': {
          width: '24px',
          height: '24px',
        },
      },
    },
    billableInfo: {
      width: '20px',
      height: '20px',
      color: '#5F5F5F',
      marginLeft: '15px',
      marginTop: '3px',
    },
    billableTitle: {
      marginTop: '26px',
      marginBottom: '9px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#4B4B4B',
    },
    billableInput: {
      marginBottom: '26px',
      '&:last-of-type': {
        marginBottom: '45px',
      },
    },
    actionButtons: {
      paddingLeft: 20,
      paddingBottom: 20,
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
    flagButtonButtom: {
      width: '184px',
      height: '40px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '17px',
      backgroundColor: 'black',
      color: '#FFFF',
      '&:hover': {
        background: '#FF574533',
        color: 'black',
      },
      'svg:last-of-type': {
        fontSize: '18px',
        marginLeft: '5px',
      },
    },
    flagMenu: {
      borderRadius: '8px',
      height: '275px',
      width: '352px !important',
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      '@media(max-width: 767px)': {
        width: '200px !important',
      },
    },
    flagMenuItem: {
      height: flagMenuHeight,
      borderRadius: '8px',
      '&:hover': {
        background: 'rgba(255, 87, 69, 0.08)',
      },
      '&:last-of-type': {
        cursor: 'not-allowed',
        '&:hover': {
          background: '#ffffff',
        },
      },
    },
    flagMenuText: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#5F5F5F',
    },
    approve: {
      borderRadius: '8px',
      background: '#27C281',
      width: '184px',
      height: '40px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'center',
      color: '#FFFFFF',
      '&:hover': {
        background: '#27C281',
      },
    },
    disputeMenu: {
      padding: '0 10px',
    },
    disputeTitle: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#5F5F5F',
      marginBottom: '15px',
    },
    disputeText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#5F5F5F',
      marginBottom: '20px',
    },
    disputeButtons: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '> button': {
        padding: '12px 15px',
        gap: '10px',
        height: '40px',
        borderRadius: '8px',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#5F5F5F',
        '&:last-of-type': {
          color: 'white',
        },
      },
    },
  }),
);
