import { Button, Tabs, Title, useMantineTheme } from '@mantine/core';
import { Employee } from 'states/employees/employee';
import { AlertMessage } from 'states/alert/alert-message';
import useModal from 'components/modal/modal-hook';
import Users from './users/users';
import ActionModal from './users/action-modal/action-modal';
import Profile from './profile-tab/profile-tab';
import { useEffect, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { useSettingStyles } from './settings.styles';
import { useRecoilValue } from 'recoil';
import { UserIdState } from '../onboarding/onboarding-form.state';

const SettingsTab = () => {
  const theme = useMantineTheme();
  const modal = useModal();
  const closeModal = modal.closeAllModals;
  const [allUsers, setAllUsers] = useState<Employee[]>([]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
  const [person, setPerson] = useState({});
  const userId = useRecoilValue(UserIdState);
  const { classes } = useSettingStyles();
  const getUserData = async () => {
    try {
      if (userId) {
        const result = await flexbaseClient.getPerson(userId);
        if (!result) {
          throw Error(
            JSON.stringify({ errorMessage: 'Unable to get user data' }),
          );
        }
        setPerson(result);
      }
    } catch (error) {
      console.error(JSON.parse(error.message).errorMessage);
    }
  };

  const openActionModal = () => {
    screen.width <= 767
      ? modal.openCenterModal(
          <ActionModal {...{ closeModal, setAllUsers, setAlertMessage }} />,
        )
      : modal.openRightModal(
          <ActionModal {...{ closeModal, setAllUsers, setAlertMessage }} />,
        );
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <div className={classes.containerHeader}>
        <Title data-testid={'settings-title'} className="title">
          Settings ⚙️
        </Title>
        <Button
          color="flexbase-teal"
          size="sm"
          onClick={() => openActionModal()}
        >
          Add User
        </Button>
      </div>
      <Tabs color={theme.fn.primaryColor()}>
        <Tabs.List>
          <Tabs.Tab value="user-management">User Management</Tabs.Tab>
          <Tabs.Tab value="profile">Profile</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="user-management">
          <Users
            {...{
              allUsers,
              alertMessage,
              setAllUsers,
              setAlertMessage,
              closeModal,
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel value="profile">
          <Profile {...{ person }} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default SettingsTab;
