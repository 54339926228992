import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import { Data } from 'plotly.js';
import { DateTime, Duration } from 'luxon';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import {
  convertDatesToMonths,
  createWeightedAverageMarkers,
} from 'components/charts/utilities';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { CompanyIdState } from 'areas/onboarding/onboarding-form.state';
import { useMantineTheme } from '@mantine/core';

export interface CreditPaymentChartData {
  data: Data[];
}

export interface ComingDueFormattedData {
  billDate: DateTime;
  jsDate: Date;
  milliDate: number;
  invoices: number;
}

// Testing: users to test with
// no payments due: postmantestuser10565016805676@test.flexbase.app
// some payments due: postmantestuser95055656702121@test.flexbase.app
const CreditPaymentScheduleChart = () => {
  const theme = useMantineTheme();

  const [paymentHistory, setPaymentHistory] = useState<CreditPaymentChartData>({
    data: [],
  });
  const [dateList, setDateList] = useState<ComingDueFormattedData[]>([]);

  const companyId = useRecoilValue(CompanyIdState);
  /**
   * added this to add some additional length to several of the svg lines used in the visual.
   * length is calculated with milliseconds, since the x axis is a time basis.
   *  */
  const threeDayOffset = Duration.fromObject({
    days: 3,
    hour: 0,
    minute: 0,
    second: 0,
  }).toMillis();

  const getLayout = (dateList: ComingDueFormattedData[]) => {
    // get date list in millisecond format for calcs and axis
    const milliDateList = dateList.map((d) => d.milliDate);

    // use millisecond date list to determine ranges
    return {
      autosize: true,
      hovermode: false,
      font: {
        family: 'Inter, sans-serif',
      },
      title: {
        text: 'Estimated Invoices Due',
        y: 0.96,
        x: 0.05,
        font: {
          family: 'PP Neue Montreal',
          size: 16,
          weight: 500,
          color: '#5F5F5F',
        },
      },
      margin: {
        l: 5,
        r: 5,
      },
      showlegend: false,
      xaxis: {
        type: 'date' as any,
        fixedrange: true,
        visible: false,
        range: [
          Math.min(...milliDateList) - threeDayOffset,
          Math.max(...milliDateList) + threeDayOffset,
        ],
        autorange: true,
      },
      yaxis: {
        showgrid: false,
        fixedrange: true,
        visible: false,
      },
      annotations: [
        {
          font: {
            family: 'Inter, sans-serif',
            size: 12,
          },
          x: DateTime.now().toJSDate(),
          y: 19,
          xref: 'x',
          yref: 'y',
          text: 'Today',
          showarrow: false,
          arrowhead: 7,
        },
      ],
    } as any;
  };
  /**
   * main function to get the payment data, and generate the correct data array for
   * plotly.js.
   */
  const getPaymentScheduleData = async () => {
    const comingDueResponse = await flexbaseOnboardingClient.getComingDue(
      companyId,
      true,
    );
    const comingDueList = comingDueResponse.comingDue;

    const dateList = comingDueList.map((d) => {
      return {
        billDate: DateTime.fromFormat(d.billDate, 'yyyy-MM-dd'),
        jsDate: DateTime.fromFormat(d.billDate, 'yyyy-MM-dd').toJSDate(),
        milliDate: DateTime.fromFormat(d.billDate, 'yyyy-MM-dd').toMillis(),
        invoices: Number(d.invoices as string),
      };
    });

    const dateListSkipPastPayment = dateList.filter((d) => {
      return d.billDate > DateTime.now();
    });

    // aggregate all of the data arrays together
    const totalData: Partial<any>[] = [];

    // quick const to control the offset of the svgs in the canvas.
    const bottomOffset = 2;

    // create svg data elements
    // 1. create grey lines
    dateList.forEach((t) => {
      totalData.push({
        x: [t.billDate.toJSDate(), t.billDate.toJSDate()],
        y: [0 + bottomOffset, 18],
        mode: 'lines',
        line: {
          dash: 'dot',
        },
        marker: {
          color: '#E6E7E9',
          dash: 'dash',
        },
        type: 'scatter',
      });
    });

    // 2. create orange line representing today's date
    totalData.push({
      x: [DateTime.now().toJSDate(), DateTime.now().toJSDate()],
      y: [0 + bottomOffset, 18],
      mode: 'lines',
      line: {
        dash: 'dot',
      },
      marker: {
        color: theme.fn.primaryColor(),
        dash: 'dash',
      },
      type: 'scatter',
    });

    // 3. add the bottom axis
    const milliDateList = dateList.map((d) => d.milliDate);

    totalData.push({
      x: [
        Math.min(...milliDateList) - threeDayOffset,
        Math.max(...milliDateList) + threeDayOffset,
      ],
      y: [0 + bottomOffset, 0 + bottomOffset],
      mode: 'lines',
      marker: {
        color: '#E6E7E9',
      },
      type: 'scatter',
    });

    // 4. create text for dates
    const dateListDateTime = dateList.map((d) => {
      return d.billDate;
    });
    totalData.push({
      x: milliDateList,
      y: [0, 0, 0, 0, 0],
      mode: 'text',
      type: 'scatter',
      text: convertDatesToMonths(dateListDateTime),
      textposition: 'bottom center',
      textfont: {
        family: 'Inter, sans-serif',
      },
      marker: { size: 12 },
    });

    /**
     * get coming due amount list in both number and formatted currency
     *  */
    const comingDueAmountsList = dateListSkipPastPayment.map((payment) => {
      const currencyFormattedComingDue =
        payment.invoices > 0
          ? formatCurrency(payment.invoices.toString())
          : formatCurrency(0);

      return {
        invoiceAmount: payment.invoices,
        invoiceFormattedValue: currencyFormattedComingDue,
      };
    });

    const baseline = 11;
    const range = 3;
    // calc y axis based on amount and transform to weight value
    const yAxisOffset = createWeightedAverageMarkers(
      comingDueAmountsList.map((a) => a.invoiceAmount),
      {
        max: baseline + range,
        min: baseline - range,
        zero: baseline,
        avg: baseline,
      },
    );

    // 5. create text for payment amounts
    totalData.push({
      x: milliDateList.slice(1, milliDateList.length),
      y: yAxisOffset.map((yVal) => {
        return yVal - range * (yVal / baseline);
      }),
      mode: 'text',
      type: 'scatter',
      text: comingDueAmountsList.map((a) => a.invoiceFormattedValue),
      textposition: 'bottom center',
      textfont: {
        family: 'Inter, sans-serif',
      },
      marker: { size: 12 },
    });

    // 6. create fun circles with relative sizing boundaries
    const percentMarkerListBigCircle = createWeightedAverageMarkers(
      comingDueAmountsList.map((a) => a.invoiceAmount),
      {
        max: 80,
        min: 0,
        zero: 0,
        avg: 50,
      },
    );
    const percentMarkerListSmallCircle = createWeightedAverageMarkers(
      comingDueAmountsList.map((a) => a.invoiceAmount),
      {
        max: 50,
        min: 0,
        zero: 0,
        avg: 30,
      },
    );

    totalData.push({
      x: milliDateList.slice(1, milliDateList.length),
      y: yAxisOffset,
      mode: 'markers',
      type: 'scatter',
      marker: {
        size: percentMarkerListBigCircle,
        color: `${theme.colors.tertiary[1]}`,
      },
    });

    totalData.push({
      x: milliDateList.slice(1, milliDateList.length),
      y: yAxisOffset,
      mode: 'markers',
      type: 'scatter',
      marker: {
        size: percentMarkerListSmallCircle,
        color: theme.fn.primaryColor(),
      },
    });

    // set the context
    setPaymentHistory({
      data: totalData,
    });
    setDateList(dateList);
  };

  useEffect(() => {
    getPaymentScheduleData();
  }, []);

  // set the main component's data and layout, and you are done!
  return (
    <div>
      <Plot
        style={{ width: 'inherit', height: 'inherit' }}
        data={paymentHistory.data}
        config={{
          displayModeBar: false,
          displaylogo: false,
          staticPlot: true,
        }}
        layout={getLayout(dateList)}
      />
    </div>
  );
};

export default CreditPaymentScheduleChart;
