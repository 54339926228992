import { useRecoilValue } from 'recoil';
import { Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import ParentCenteredLoader from 'components/loading/parent-centered-loader';
import { useConfirmAndReviewStyles } from './review-and-pay.styles';
import CardPaymentAmount from './card-payment-amount';
import CardPaymentReview from './card-payment-review';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { Analytics } from 'services/analytics/analytics';
import {
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  usePlaidLink,
} from 'react-plaid-link';
import { PlaidAccount } from 'areas/banking/move-funds/move-funds.model';
import { ApplicationState } from 'states/application/product-onboarding';

// wrapper component for triggering a plaid link if we need one
interface LinkProps {
  token: string | null;
  onSuccess: PlaidLinkOnSuccess;
  onError: () => void;
}

const PlaidLink = ({ token, onSuccess, onError }: LinkProps) => {
  const { ready, open, error } = usePlaidLink({
    onSuccess: onSuccess,
    onExit: onError,
    token: token ?? null,
  });

  if (error) {
    onError();
  }

  useEffect(() => {
    if (open && ready) {
      open();
    }
  }, [open, ready]);

  return null;
};

type Props = {
  closeModal: () => void;
  onSuccess: (paymentId: string, paymentAmount: number) => void;
  onError: () => void;
};

const ReviewAndPay = ({ closeModal, onSuccess, onError }: Props) => {
  const { company } = useRecoilValue(ApplicationState);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [minimumDue, setMinimumDue] = useState(0);
  const [reviewing, setReviewing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [delinquentAmount, setDelinquentAmount] = useState(0);
  const [frozen, setFrozen] = useState(false);
  const [interestDue, setInterestDue] = useState(0);
  const [maxPayment, setMaxPayment] = useState(0);
  const [minPayment, setMinPayment] = useState(0);
  const [comeCurrentPayment, setComeCurrentPayment] = useState(0);
  const [plaidAccounts, setPlaidAccounts] = useState<PlaidAccount[]>([]);
  const [plaidAccount, setPlaidAccount] = useState<PlaidAccount>();
  const [token, setToken] = useState<string | null>(null);

  const getBalanceAndAccount = async () => {
    setLoading(true);
    const balance = await flexbaseOnboardingClient.getMinimumDue(company.id);

    if (balance.success) {
      setCurrentBalance(balance.currentBalance);

      const interest = balance.interestDue ?? 0;
      const isDelinquent = balance.frozen;
      if (isDelinquent) {
        setFrozen(true);
      }
      const comeCurrentAmount = balance.comeCurrentPayment ?? 0;
      setPaymentAmount(
        isDelinquent ? balance.maximumAllowedPayment : balance.currentBalance,
      );

      setMinimumDue(balance.minimumDue > 0 ? balance.minimumDue : 0);
      setDelinquentAmount(balance.delinquentAmount ?? 0);
      setInterestDue(interest);
      setMaxPayment(balance.maximumAllowedPayment ?? 0);
      setMinPayment(
        isDelinquent ? interest : balance.minimumAllowedPayment ?? 0,
      );
      setComeCurrentPayment(isDelinquent ? comeCurrentAmount : 0);

      // set finalcial institutions
      const accounts = company.financialInstitutions
        .filter((acc) => acc.active && !acc.unlinked)
        .map((acc) => {
          return { ...acc, plaidOrDeposit: 'plaid' } as PlaidAccount;
        });
      setPlaidAccounts(accounts);
      // find the primary plaid account
      const primaryPlaidAccount = accounts.find(
        (acc) => acc.id === company.primaryPlaidAccount,
      );
      setPlaidAccount(primaryPlaidAccount || accounts[0]);
    } else {
      onError();
    }

    setLoading(false);
  };

  const onLinkSuccess: PlaidLinkOnSuccess = async (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => {
    setLoading(true);
    const response = await flexbaseClient.exchangePlaidPublicToken(
      publicToken,
      metadata,
    );

    if (response.success) {
      await getBalanceAndAccount();
    }

    setLoading(false);
  };

  const onConfirmClick = async () => {
    setLoading(true);

    try {
      const paymentResult = await flexbaseOnboardingClient.makePaymentPlaid(
        paymentAmount,
        // SAFETY: plaidAccount *must* exist by this point
        // (see the assertion in the "stack" variable below)
        plaidAccount!.id,
      );
      if (
        paymentResult.success &&
        paymentResult.cardPayment.status !== 'failed'
      ) {
        Analytics.track('Credit Payment Submitted', { paymentAmount });
        onSuccess(paymentResult.cardPayment.id, paymentAmount);
      } else {
        onError();
      }
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBalanceAndAccount();
  }, []);

  useEffect(() => {
    if (!loading && !plaidAccount && !token) {
      flexbaseClient.getPlaidLinkToken().then((token) => setToken(token));
    }
  }, [loading, plaidAccount]);

  const { classes } = useConfirmAndReviewStyles();

  const stack = plaidAccount ? (
    <>
      <Text className={classes.title}>
        {frozen ? (
          <>
            Pay now to unfreeze <br /> your account
          </>
        ) : (
          'Make a credit payment'
        )}
      </Text>
      {reviewing ? (
        <CardPaymentReview
          plaidAccount={plaidAccount}
          paymentAmount={formatCurrency(paymentAmount)}
          onConfirmClick={onConfirmClick}
          onGoBackClick={() => setReviewing(false)}
        />
      ) : (
        <CardPaymentAmount
          delinquentAmount={delinquentAmount}
          frozen={frozen}
          interestDue={interestDue}
          paymentAmount={paymentAmount}
          currentBalance={currentBalance}
          minimumDue={minimumDue}
          maxPaymentAmount={maxPayment}
          minPaymentAmount={minPayment}
          comeCurrentAmount={comeCurrentPayment}
          closeModal={closeModal}
          onReviewClick={() => setReviewing(true)}
          onPaymentAmountChange={(amount) => setPaymentAmount(amount)}
          plaidAccounts={plaidAccounts}
          currentAccount={plaidAccount}
          onPlaidAccountChange={(account) => setPlaidAccount(account)}
        />
      )}
    </>
  ) : (
    <PlaidLink token={token} onSuccess={onLinkSuccess} onError={onError} />
  );

  return (
    <>
      <ParentCenteredLoader visible={loading} />
      {!loading && <Stack>{stack}</Stack>}
    </>
  );
};

export default ReviewAndPay;
