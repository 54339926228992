import {
  Alert,
  Button,
  Checkbox,
  Container,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import Input from 'components/input/flexbase-input';
import Select from 'components/select/flexbase-select';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { Card, Group as CardInfoGroup } from 'states/cards/card-info';
import { CustomMantineStyles } from '../styles';
import { validateRequired } from 'utilities/validators/validate-required';
import { useMediaQuery } from '@mantine/hooks';

interface Props {
  closeModal: () => void;
  card?: any;
  addCompanyCard: (card: Card) => void;
}

interface SelectItemProps {
  label: string;
  value: string;
}

type CardFormGodDammit = {
  userId: string;
  cardType: string;
  cardName: string;
  spendingLimit: number;
  spendingLimitInterval: string;
  groups: CardInfoGroup[];
};

const IssueCardForm = ({ closeModal, card, addCompanyCard }: Props) => {
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const theme = useMantineTheme();
  const [employees, setEmployees] = useState<SelectItemProps[]>([]);
  const [limitCategories, setLimitCategories] = useState('no');
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState<string>('');
  const form = useForm<CardFormGodDammit>({
    initialValues: {
      userId: '',
      cardType: 'physical',
      cardName: '',
      spendingLimit: 0,
      spendingLimitInterval: '',
      groups: [],
    },
    validate: {
      cardName: (value) =>
        validateRequired(value) ? null : 'Card name is required.',
      userId: (value) =>
        validateRequired(value)
          ? null
          : 'A user must be selected for this card',
      spendingLimitInterval: (value) =>
        validateRequired(value) ? null : 'Select a limit type',
      spendingLimit: (value) => {
        if (form.values.spendingLimitInterval !== 'unlimited' && value < 1) {
          return 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        return null;
      },
    },
  });

  const onEmployeeSelect = (value: string) => {
    form.setFieldValue(
      'cardName',
      employees.find((s) => s.value === value)!.label,
    );
    form.setFieldValue(
      'userId',
      employees.find((s) => s.value === value)!.value,
    );
  };

  const getEmployees = async () => {
    try {
      const result = await flexbaseClient.getEmployees();
      setEmployees(
        result
          .filter((employee) => {
            return (
              employee.completedOnboarding && !employee.completedOffboarding
            );
          })
          .map((employee) => {
            return {
              value: employee.id!,
              label: `${employee.firstName} ${employee.lastName}`,
            };
          }),
      );
    } catch (error) {
      setMessageError('Unable to get the company employees');
    }
  };

  const setCardLimitCategories = () => {
    if (limitCategories === 'no') {
      setLimitCategories('yes');
    } else {
      setLimitCategories('no');
      form.setFieldValue('groups', []);
    }
  };

  const issueCard = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      return;
    }

    try {
      setLoading(true);
      setMessageError('');
      const userId = form.values.userId;
      const cardType = form.values.cardType;

      const {
        success,
        card: cardIssued,
        error,
      } = await flexbaseClient.issueCard(userId, { cardType });

      if (success && cardIssued?.id) {
        const cardId = cardIssued.id;
        let optionalFields: any = {};
        if (form.values.spendingLimitInterval !== 'unlimited') {
          optionalFields = {
            expensesTypes: {
              amount:
                form.values.spendingLimitInterval === 'unlimited'
                  ? null
                  : form.values.spendingLimit,
              groups: form.values.groups,
              interval:
                form.values.spendingLimitInterval === 'unlimited'
                  ? null
                  : form.values.spendingLimitInterval,
            },
            creditLimit:
              form.values.spendingLimitInterval === 'unlimited'
                ? null
                : form.values.spendingLimit,
          };
        } else {
          optionalFields = {
            expensesTypes: {
              groups: form.values.groups,
            },
          };
        }

        const {
          card: cardUpdated,
          success,
          error,
        } = await flexbaseClient.updateCard(cardId, {
          ...optionalFields,
          cardName: form.values.cardName,
          notifyUse: false,
        });
        if (success && cardUpdated) {
          closeModal();
          addCompanyCard(cardUpdated);
        } else {
          setMessageError(error as string);
        }
      } else {
        if (error) {
          setMessageError(error as string);
        } else {
          setMessageError('Unable to issue the card. Try it again');
        }
      }
    } catch (error) {
      console.error('Unable to issue the card', error);
      if ((error as string).search('Terms of Service, but it has not.') > -1) {
        setMessageError(
          `Unable to issue card to ${form.values.cardName}, they have not accepted the latest terms and conditions. Please have them log in and agree to the latest terms.`,
        );
      } else {
        setMessageError('Unable to issue the card. Try it again');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const setSpendingLimit = (value: string) => {
    if (!value) {
      form.setFieldValue('spendingLimit', 0);
    } else {
      form.setFieldValue('spendingLimit', +value.replace(/\D/g, ''));
    }
  };

  return (
    <Container>
      <Title
        style={{
          textAlign: 'start',
          fontSize: '28px',
          fontFamily: 'PP Neue Montreal',
          fontWeight: 400,
        }}
      >
        {card?.id ? 'Update credit card' : 'Create a new credit card'}
      </Title>
      <Text mt="1rem" color="#4B4B4B">
        Who’s this card for?
      </Text>

      <Select
        style={{ marginTop: '-10px' }}
        searchable
        data={employees}
        radius={8}
        disabled={card?.id}
        maxDropdownHeight={280}
        nothingFound="No options"
        data-testid="select-employee"
        placeholder="Select Employee"
        {...form.getInputProps('userId')}
        onChange={onEmployeeSelect}
      />

      <Text mt="0.5rem" color="#4B4B4B">
        Card Name
      </Text>

      <Input
        mt="0.4rem"
        data-testid="card-name"
        placeholder="e.g. nickname lunch card"
        {...form.getInputProps('cardName')}
      />

      <Radio.Group
        size="sm"
        value={form.values.cardType}
        onChange={(value: 'physical' | 'virtual') =>
          form.setFieldValue('cardType', value)
        }
        color={theme.fn.primaryColor()}
        aria-label="card-type"
      >
        <Group mt="0.5rem" spacing={useMobileView ? 60 : 100}>
          <Radio
            styles={{ body: CustomMantineStyles().issueCard.radio.body }}
            disabled={card?.id}
            aria-label="physical-card"
            value="physical"
            label="Physical"
          />
          <Radio
            styles={{ body: CustomMantineStyles().issueCard.radio.body }}
            disabled={card?.id}
            aria-label="virtual-card"
            value="virtual"
            label="Virtual"
          />
        </Group>
      </Radio.Group>

      <Text mt="0.5rem" color="#4B4B4B">
        Limit Type
      </Text>

      <Select
        style={{ marginTop: '-10px' }}
        defaultValue="monthly"
        data={[
          { value: 'monthly', label: 'Monthly' },
          { value: 'unlimited', label: 'Unlimited' },
        ]}
        radius={8}
        maxDropdownHeight={280}
        nothingFound="No options"
        data-testid="select-limit-type"
        placeholder="Select Limit Type"
        {...form.getInputProps('spendingLimitInterval')}
        onChange={(e) => {
          form.setFieldValue('spendingLimit', 0);
          if (e) {
            form.setFieldValue('spendingLimitInterval', e);
          }
        }}
      />

      {form.values.spendingLimitInterval !== 'unlimited' && (
        <>
          <Text mt="0.7rem" color="#4B4B4B">
            Monthly Spending Limit
          </Text>

          <TextInput
            mt="0.4rem"
            variant="default"
            radius={8}
            labelProps={{
              color: '#4B4B4B',
            }}
            pattern="[0-9]*"
            data-testid="amount"
            styles={{
              label: { color: 'black' },
              input: { '&:focus': { borderColor: theme.fn.primaryColor() } },
            }}
            {...form.getInputProps('spendingLimit')}
            onChange={(e) => setSpendingLimit(e.target.value)}
          />
        </>
      )}

      <Text mt="1rem" color="#4B4B4B">
        Do you want this card to be limited to specific categories of spend?
      </Text>

      <Radio.Group
        size="sm"
        value={limitCategories}
        onChange={() => setCardLimitCategories()}
        color={theme.fn.primaryColor()}
      >
        <Group spacing={100}>
          <Radio
            styles={{ body: CustomMantineStyles().issueCard.radio.body }}
            aria-label="categoriesYes"
            value="yes"
            label="Yes"
          />
          <Radio
            styles={{ body: CustomMantineStyles().issueCard.radio.body }}
            aria-label="categoriesNo"
            value="no"
            label="No"
          />
        </Group>
      </Radio.Group>

      {limitCategories === 'yes' && (
        <Checkbox.Group
          size="sm"
          onChange={(value) => {
            form.setFieldValue('groups', value as CardInfoGroup[]);
          }}
          value={form.values.groups}
        >
          <Stack spacing="sm">
            <Checkbox
              data-testid="material-suppliers"
              label="Material suppliers"
              value="MATERIALSUPPLIERS"
            />
            <Checkbox
              data-testid="convenience-stores"
              label="Convenience stores"
              value="CONVENIENCESTORES"
            />
            <Checkbox
              data-testid="food-and-beverage"
              label="Food and beverage"
              value="FOODANDBEVERAGE"
            />
            <Checkbox
              data-testid="equipment"
              label="Equipment"
              value="EQUIPMENT"
            />
            <Checkbox
              data-testid="office-supplies"
              label="Office supplies and cleaning"
              value="OFFICESUPPLIESANDCLEANING"
            />
            <Checkbox
              data-testid="fuel-and-gas"
              label="Fuel and Gas"
              value="FUELANDGAS"
            />
          </Stack>
        </Checkbox.Group>
      )}

      {messageError && messageError !== '' && (
        <Alert
          color="red"
          withCloseButton
          variant="filled"
          className="alert"
          closeButtonLabel="close"
          data-testid="error-message"
        >
          {messageError}
        </Alert>
      )}

      <div className="buttons-container" style={{ marginTop: '1rem' }}>
        {form.values.cardType === 'virtual' && (
          <Text color="#4B4B4B" size="xs" style={{ marginRight: '16px' }}>
            The card will be activated within a few moments.
          </Text>
        )}

        <Button
          mt="1rem"
          size="lg"
          {...{ loading }}
          radius={8}
          onClick={() => issueCard()}
          color={theme.fn.primaryColor()}
          fullWidth={form.values.cardType === 'physical'}
          className="btn-create-card"
        >
          <Text style={{ fontSize: '14px', fontWeight: 500 }}>
            {card?.id ? 'Update Card' : 'Create Card'}
          </Text>
        </Button>
      </div>
    </Container>
  );
};

export default IssueCardForm;
