import { Progress, Text, useMantineTheme } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useEffect, useState } from 'react';
import { CompanyCredit } from 'flexbase-client';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useStyles } from './credit-limit.styles';
import CardRepaymentModal from '../../repayment/card-repayment-modal';
import useModal from 'components/modal/modal-hook';
import { useRecoilValue } from 'recoil';
import {
  CompanySelector,
  IsAccountant,
} from '../../../../states/application/product-onboarding';

type Props = {
  hasDelinquentAmount: boolean;
  hasPendingPayment: boolean;
  onClick?: () => void;
};

/**
 *
 * @param hasDelinquentAmount
 * @param hasPendingPayment
 * @param onClick Will only be called if the user does not have a delinquent amount.
 * @constructor
 */
const CreditLimit = ({
  hasDelinquentAmount,
  hasPendingPayment,
  onClick,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles({ hasDelinquentAmount, hasOnClick: !!onClick });
  const { openTransparentModal, closeAllModals } = useModal();
  const isAccountant = useRecoilValue(IsAccountant);
  const [companyCredit, setCompanyCredit] = useState<CompanyCredit>({
    available: 0,
    total: 0,
  });
  const { id } = useRecoilValue(CompanySelector);

  const utilization =
    companyCredit.available > 0
      ? Math.ceil((1 - companyCredit.available / companyCredit.total) * 100)
      : 100;

  const getCreditBalance = async () => {
    try {
      const companyBalance = await flexbaseOnboardingClient.getCreditBalance(
        id,
      );
      setCompanyCredit({
        available: companyBalance.availableLimit,
        total: companyBalance.creditLimit,
      });
    } catch (e) {
      console.error('credit-limit.tsx', e);
    }
  };

  const openPaymentModal = () => {
    openTransparentModal(<CardRepaymentModal closeModal={closeAllModals} />);
  };

  const onCardClick = () => {
    if (onClick && !hasDelinquentAmount) {
      onClick();
    }
  };

  useEffect(() => {
    getCreditBalance();
  }, []);
  return (
    <div className={classes.creditContainer} onClick={onCardClick}>
      {hasDelinquentAmount && (
        <div className={classes.frozen}>
          <div className={classes.unfreezeButton}>
            Frozen -{' '}
            <button
              type="button"
              className={classes.payNow}
              disabled={isAccountant}
              onClick={() => openPaymentModal()}
            >
              {hasPendingPayment ? 'Payment is pending' : 'Pay now to unfreeze'}
            </button>
          </div>
        </div>
      )}
      <div className={classes.totalBalanceContainer}>
        <div>
          <Text className={classes.availableCreditLabel}>Available Credit</Text>
          <div className={classes.availableCreditAmt}>
            {formatCurrency(companyCredit.available.toString())}
          </div>
        </div>
        <div style={{ marginTop: '3.4rem' }}>
          <div className={classes.statusBar}>
            <Progress
              size="md"
              value={100 - utilization}
              color={theme.fn.primaryColor()}
            />
            <div className={classes.statusBarTextContainer}>
              <Text className={classes.statusBarText}>
                of {formatCurrency(companyCredit.total.toString())}
              </Text>
              <Text className={classes.statusBarText}>
                {100 - utilization}% available
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditLimit;
