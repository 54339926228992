import { useState } from 'react';
import { Button, Grid, Group, Image } from '@mantine/core';

import { useStyles } from '../styles';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ApplicationState,
  mapProductsArrayToProduct,
  ProductState,
} from '../../../states/application/product-onboarding';
import { useRouteSectionContext } from '../../../components/routes/route-context';
import Card from '../../../assets/images/login-image.png';
/**
 * States that get to NoAccount:
 * Incomplete - user has not completed all required fields for banking
 * Unused - user has not started a banking app (no opted_product)
 */
const NoAccount = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { company, productStatus } = useRecoilValue(ApplicationState);
  const setProduct = useSetRecoilState(ProductState);
  const { setShowRoutesFor } = useRouteSectionContext();

  const createBankingApplication = async () => {
    try {
      setLoading(true);
      if (productStatus.banking.status.toLowerCase() === 'incomplete') {
        // TODO: If it is incomplete and all they have is terms, just show the terms modal with useShowTermsModal
        setShowRoutesFor('application');
      } else if (productStatus.banking.status.toLowerCase() === 'unused') {
        const { optedProducts } = await flexbaseOnboardingClient.updateCompany({
          id: company.id,
          optedProducts: [...company.optedProducts, 'BANKING'],
        });
        setProduct(mapProductsArrayToProduct(optedProducts));
        setShowRoutesFor('application');
      }
    } catch (error) {
      setErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  if (errorMessage) {
    return (
      <div className={classes.emptyState}>
        <div>
          <Image
            width={200}
            height={200}
            src="/svg/redFlag.svg"
            alt="Display info error"
            className="image-container"
          />
          <p>
            We are unable to create your account. Please contact support to
            determine the reason your application could not be created.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.noBankingAccount}>
      <Group position="apart" pos="relative">
        <div style={{ width: '55%' }}>
          <div className="banner">Earn up to 4% APY on your idle cash</div>

          <h2>Banking that feels like x-ray glasses for your finances</h2>
          <span className="info">
            Flexbase is a financial technology company, not a bank. Banking
            services provided by Thread Bank, FDIC Insured.
          </span>

          <div>
            <Button
              {...{ loading }}
              onClick={createBankingApplication}
              variant="light"
              my={30}
            >
              Get started now
            </Button>
          </div>
        </div>
        <div>
          <Image src={Card} width={600} pos="absolute" bottom={0} right={-50} />
        </div>
      </Group>

      <Grid>
        <Grid.Col span={4}>
          <div className="card">
            <p>Watch cash flow grow in an easy-to-understand dashboard</p>
          </div>
        </Grid.Col>

        <Grid.Col span={4}>
          <div className="card">
            <p>
              Add employee or 3rd party users, with clearly defined restrictions
              & access.
            </p>
          </div>
        </Grid.Col>

        <Grid.Col span={4}>
          <div className="card">
            <p>
              Granular view of account balances and spending across your entire
              company.
            </p>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default NoAccount;
