import OnboardingEndScreen from '../../components/onboarding-end-screen';
import PendingGraphic from 'assets/images/pending-state.png';
import { ZendeskAPI } from 'react-zendesk';

// TODO: Make a dedicated error screen for the app error boundary instead of repurposing this one from onboarding/application
type EndScreenHackProps = {
  config?: {
    emailSubject: string;
    emailBody: string;
    errorTitle: string;
    errorDescription: string;
  };
};

// This component is technically an end state screen, but is used for errors.
// Warning: DO NOT add any calls to get Recoil state here or make any unhandled network calls. If they error out, they will
// cause a never ending error loop.
const ErrorEndScreen = ({ config }: EndScreenHackProps) => {
  const openSupportChat = () => {
    ZendeskAPI('messenger', 'open');
  };

  const emailSupport = () => {
    const emailSubject = encodeURI(
      config?.emailSubject || 'Application Support Request',
    );
    const emailBody = encodeURI(
      config?.emailBody ||
        'I have encountered an error during the sign-up process.',
    );
    window.open(
      `mailto:support@flex.one?subject=${emailSubject}&body=${emailBody}`,
    );
  };

  return (
    <OnboardingEndScreen
      title={config?.errorTitle || "Oops, something's not right..."}
      description={
        config?.errorDescription ||
        'Something went amiss during your sign-up. Contact our customer support team to help you complete your sign-up with the live chat widget or by emailing support@flex.one'
      }
      graphic={PendingGraphic}
      primaryButtonText="Open live chat"
      secondaryButtonText="Email support"
      onPrimaryButtonClick={() => openSupportChat()}
      onSecondaryButtonClick={() => emailSupport()}
    />
  );
};

export default ErrorEndScreen;
