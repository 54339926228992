import { Box, Text } from '@mantine/core';

import { useStyles } from './add-funds';
import { SafeBoxIcon, ChevronDownIcon } from 'assets/svg';

interface Props {
  onLinkAccount: (x: boolean) => void;
}

const ConnectBankAccount = ({ onLinkAccount }: Props) => {
  const { classes, cx } = useStyles({ amount: '0' });

  return (
    <Box
      className={cx(classes.target, classes.rowContent, classes.cursor)}
      p={20}
      onClick={() => onLinkAccount(true)}
    >
      <div className={classes.flexContent}>
        <div className={classes.IconSection}>
          <SafeBoxIcon width={24} height={24} />
        </div>
        <Text size={14}>Connect a bank account</Text>
      </div>

      <ChevronDownIcon />
    </Box>
  );
};

export default ConnectBankAccount;
