import { Anchor, Loader, rem, TextInput } from '@mantine/core';
import flexbaseClient from 'services/flexbase-client';

type Props = {
  onCodeVerify: () => void;
  onCodeError: (errorMessage: string) => void;
  cellPhone: string;
  generateCode: (resend: boolean) => void;
  generatingCode: boolean;
};

const VerifyCode = ({
  onCodeError,
  onCodeVerify,
  cellPhone,
  generateCode,
  generatingCode,
}: Props) => {
  const onCodeChange = async (code: string) => {
    if (code.length === 6) {
      const result = await flexbaseClient.verifyCode(cellPhone, code);
      if (!result) {
        onCodeError('Unable to verify your code. Please check and re-enter.');
        return;
      } else {
        onCodeVerify();
      }
    }
  };

  return (
    <div>
      <TextInput
        placeholder="Enter 6 digit code"
        label="Code"
        maxLength={6}
        onChange={(e) => onCodeChange(e.target.value)}
        id={'input-code'}
        data-testid={'input-code'}
        styles={{
          rightSection: { width: 'auto' },
        }}
        rightSection={
          <Anchor
            sx={(theme) => ({
              color: theme.fn.themeColor('primarySecondarySuccess', 2),
              marginRight: theme.spacing.sm,
              fontSize: rem(14),
            })}
            onClick={() => generateCode(true)}
          >
            {generatingCode ? (
              <Loader
                size="sm"
                sx={(theme) => ({ color: theme.fn.primaryColor() })}
              />
            ) : (
              'Resend code'
            )}
          </Anchor>
        }
      />
    </div>
  );
};

export default VerifyCode;
