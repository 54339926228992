import { MantineTheme } from '@mantine/core';
import { Image, Text, View } from '@react-pdf/renderer';
import { stylesPDF } from '../styles-pdf';
import Divider from 'assets/images/divider.png';
import { EmailSvg, SupportSvg } from 'components/pdf-viewer/icons';

type Props = {
  theme: MantineTheme;
  dates: any;
};

const TransactionDates = ({ theme, dates }: Props) => {
  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });

  return (
    <View
      style={{
        ...styles.cardStatementContainer,
        marginTop: 30,
        padding: '8px 20px',
      }}
    >
      <View>
        <Text style={styles.text}>Transactions Start</Text>
        <Text
          style={{
            ...styles.text,
            fontSize: '12px',
            fontStyle: 'thin',
            color: theme.fn.primaryColor(),
          }}
        >
          {dates?.statementStart}
        </Text>
      </View>
      <View>
        <Text style={styles.text}>Transactions End</Text>
        <Text
          style={{
            ...styles.text,
            fontSize: '12px',
            fontStyle: 'thin',
            color: theme.fn.primaryColor(),
          }}
        >
          {dates?.statementEnd}
        </Text>
      </View>
      <View style={{ marginLeft: 0 }}>
        <Image src={Divider} style={{ width: 1, height: 30 }} />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <SupportSvg />
        <Text
          style={{
            ...styles.text,
            marginLeft: 5,
            fontSize: '9px',
          }}
        >
          Call (415) 840-8721
        </Text>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <EmailSvg />
        <Text
          style={{
            ...styles.text,
            marginLeft: 5,
            fontSize: '9px',
          }}
        >
          Email billing@flex.one
        </Text>
      </View>
    </View>
  );
};

export default TransactionDates;
