import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, createStyles } from '@mantine/core';

import {
  AddFundsIcon,
  AddFundsIconActive,
  ArrowsHorizontalIcon,
  ChevronDownIcon,
  PaySomeoneIcon,
  PaySomeoneIconActive,
} from 'assets/svg';

interface MenuItemWithIconProps {
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

const MenuItemWithIcon = ({
  onClick,
  label,
  icon,
  onMouseOver,
  onMouseLeave,
}: MenuItemWithIconProps) => (
  <Menu.Item
    onClick={onClick}
    icon={icon}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
  >
    {label}
  </Menu.Item>
);

const BankingActionButton = () => {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [activeIcon, setActiveIcon] = useState<number | null>(null);
  const { classes, theme } = useStyles(opened);

  const menuOptions = [
    {
      path: '/payments/outgoing/recipient',
      icon: <PaySomeoneIcon width={20} />,
      activeIcon: <PaySomeoneIconActive width={20} />,
      label: 'Send Money',
    },
    {
      path: '/banking/add-funds',
      icon: <AddFundsIcon width={20} />,
      activeIcon: <AddFundsIconActive width={20} />,
      label: 'Add or Receive Funds',
    },
    {
      path: '/banking/move-funds',
      icon: <ArrowsHorizontalIcon width={20} fill="#454648" />,
      activeIcon: (
        <ArrowsHorizontalIcon width={20} fill={theme.fn.primaryColor()} />
      ),
      label: 'Transfer Between Your Accounts',
    },
  ];

  return (
    <Menu opened={opened} onChange={setOpened} position="bottom-start">
      <Menu.Target>
        <Button
          size="sm"
          rightIcon={
            <ChevronDownIcon fill="#fff" width={18} className={classes.icon} />
          }
        >
          Move Money
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuOptions.map((option, index) => (
          <MenuItemWithIcon
            key={option.path}
            label={option.label}
            onMouseOver={() => setActiveIcon(index)}
            onMouseLeave={() => setActiveIcon(null)}
            onClick={() => navigate(option.path)}
            icon={activeIcon === index ? option.activeIcon : option.icon}
          />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default BankingActionButton;

const useStyles = createStyles((theme, opened: boolean) => ({
  menuItem: {
    fontWeight: 500,
    fontSize: '14px',
    cursor: 'pointer',
    color: theme.colors.blackish[0],
    '&:hover': {
      backgroundColor: 'white',
      color: theme.fn.primaryColor(),
    },
  },

  icon: {
    transition: '0.2s ease-out',
    transform: opened ? 'rotate(-180deg)' : 'rotate(0deg)',
  },
}));
