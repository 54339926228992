import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    padding: '45px',
    justifyContent: 'space-between',
    color: theme.fn.themeColor('neutral', 0),
    backgroundColor: theme.fn.primaryColor(),
    '@media(max-width: 767px)': {
      padding: '20px',
    },
  },

  transactionDetails: {
    paddingBottom: 50,
    minHeight: '100vh',
    backgroundColor: theme.colors.neutral[2],
    '@media(max-width: 767px)': {
      paddingBottom: 20,
    },
  },
}));
