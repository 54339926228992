import { createStyles, rem } from '@mantine/core';

export const useOnboardingStyles = createStyles((theme) => ({
  title: {
    fontSize: '48px',
    fontFamily: 'PP Neue Montreal',
    fontWeight: 375,
  },

  buttonGroup: {
    display: 'flex',
    gap: 16,
    justifyContent: 'space-between',
  },
  buttonGroupContinueOnly: {
    justifyContent: 'flex-end',
  },
  buttonBase: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    height: '50px',
    '&:hover': {
      opacity: 0.8,
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
    userSelect: 'none',
  },
  disabled: {
    opacity: 0.4,
    cursor: 'default',
    '&:hover': {
      opacity: 0.4,
    },
    '&:active': {
      transform: 'unset',
    },
  },
  backButton: {
    border: `1px solid ${theme.fn.themeColor('neutral', 6)}`,
    // backgroundColor: 'white',
    // borderRadius: '8px',
    // border: '1px solid #979797',
    // padding: '0px 22px 0px 22px',
    // color: '#5F5F5F',
    // '&:hover': {
    //   backgroundColor: '#FCFCFC',
    // },
  },
  continueButton: {
    minWidth: '115px',
  },
  fullWidth: {
    width: '100%',
  },
  autoWidth: {
    width: 'auto',
  },
  inputHint: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#4B4B4B',
    marginTop: 4,
    marginLeft: 4,
  },
  error: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    marginTop: 4,
    marginLeft: 4,
  },
  inputRoot: {
    width: '100%',
  },
  inputLabel: {
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
  },
  input: {
    label: {
      fontSize: 14,
      color: '#5F5F5F',
      fontWeight: 500,
    },
    error: {
      color: 'red',
    },
    height: '42px',
    fontWeight: 500,
    color: '#5F5F5F',
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '8px',
    background: 'white',
    border: '1px solid #D1D5DB',
    '&:focus': { borderColor: theme.fn.primaryColor() },
    '&:focus-within': { borderColor: theme.fn.primaryColor() },
  },
  label: {
    color: '#4B4B4B',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
  },
  icon: {
    color: '#5F5F5F',
  },
  visibilityToggle: {
    color: '#5F5F5F',
  },
  item: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5F5F5F',
    '&:hover': {
      border: 'none',
      background: 'rgba(255, 87, 69, 0.08);',
    },
  },
  selected: {
    fontSize: 14,
    fontWeight: 400,
    color: '#5F5F5F',
    background: 'rgba(255, 87, 69, 0.08);',
  },
  dropdown: {
    borderRadius: 8,
  },
  searchInput: {
    fontSize: '14px',
  },
  defaultValueLabel: {
    fontSize: '14px',
  },
  descriptionLabel: {
    fontFamily: 'PP Neue Montreal',
    color: '#5F5F5F',
    fontSize: 14,
    fontWeight: 400,
  },
  selectedNavTabButton: {
    width: '111px',
    height: '30px',
    backgroundColor: theme.colors['flexbase-rose'],
    borderRadius: '100px',
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.fn.lighten('#FFF2F0', 0.3),
    },
  },
  navTabButton: {
    width: '111px',
    height: '30px',
    backgroundColor: '#ECECEC',
    borderRadius: '100px',
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.fn.lighten('#FFF2F0', 0.3),
    },
  },
  dualButton: {
    width: '172.5px',
    height: '42px',
    borderRadius: '8px',
    border: '1px solid #DEDFE4',
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
  },
  selectedDualButton: {
    width: '172.5px',
    height: '42px',
    borderRadius: '8px',
    border: `1px solid ${theme.fn.primaryColor()}`,
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
  },
  datePickerRight: {
    right: 8,
  },
  datePickerRoot: {
    lineHeight: '16px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 21,
    marginBottom: 30,
  },
  ownerPillContainer: { display: 'flex', gap: '8px', margin: '16px 0' },
  summaryPillContainer: { marginTop: '32px', gap: '20px' },
  ownerPill: {
    width: 'unset',
    maxWidth: '111px',
    padding: '0 8px',
    flex: 1,
  },
  summaryPill: {
    width: '190px',
  },
  flavorText: {
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    marginTop: '1rem',
    color: '#5F5F5F',
  },
  stepContentContainer: { marginTop: 22, marginBottom: 32 },
  stepContentContainerError: { marginBottom: 16 },
  stepText: {
    marginBottom: 16,
    fontSize: '14px',
    fontFamily: 'PP Neue Montreal',
  },
  stepErrorText: { color: theme.fn.themeColor('critical', 2) },
  stepInfoText: {
    color: '#27C281',
  },
  rightAlign: {
    textAlign: 'right',
  },
  pointer: {
    cursor: 'pointer',
  },
  actionOpacity: {
    '&:hover': {
      opacity: 0.7,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  card: {
    height: '80px',
    border: '1px solid #E6E7E9',
    borderRadius: theme.defaultRadius,
    padding: '8px 16px',
    gap: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  cardTextContent: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  bankAccountCard: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: 'unset',
  },
  summaryCard: {
    borderRadius: theme.defaultRadius,
    padding: theme.spacing.md,
  },
  topSpacing: { margintTop: '30px' },
  bottomSpacing: { marginBottom: '16px' },
  addButton: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('primarySecondarySuccess', 2)}`,
    fontSize: rem(14),
    fontWeight: 500,
    padding: '0px 12px',
    color: 'black',
    height: rem(60),
    borderRadius: theme.defaultRadius,
    cursor: 'pointer',
    marginTop: '2rem',
    flexDirection: 'inherit',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.md,
    '&:not([data-disabled])': theme.fn.hover({
      backgroundColor: theme.fn.themeColor('primarySecondarySuccess', 0),
    }),
    '&[data-disabled]': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #D1D5DB',
      color: 'black',
      opacity: 0.3,
    },
    '.mantine-Button-inner': {
      justifyContent: 'flex-start',
    },
  },
  makePrimaryButtonLabel: {
    fontSize: rem(12),
  },
  selectedCard: {
    border: `1px solid ${theme.fn.primaryColor()}`,
  },
  centerFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unsetMargin: {
    margin: 'unset',
  },
  autoMarginLeft: {
    marginLeft: 'auto',
  },
  actionButton: {
    cursor: 'pointer',
    minWidth: '14px',
    '&:hover': {
      opacity: 0.5,
    },
    '&:active': {
      transform: 'scale(0.8)',
    },
  },
  actionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#E7E7E7',
  },
  largerFont: {
    fontSize: '20px',
  },
  mediumFont: {
    fontSize: '16px',
  },
  dropZonePlaceholderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropZonePlaceholder: {
    fontWeight: 400,
    fontSize: '13px',
    color: '#757575',
    '> span': {
      display: 'inline',
      color: theme.fn.primaryColor(),
    },
  },
  dropZone: {
    width: '100%',
    height: '80px',
    padding: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E2E7F0',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: theme.fn.primaryColor(),
    },
  },
  file: {
    width: '100%',
    height: '80px',
    borderRadius: '16px',
    border: '1px solid #E6E7E9',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#5F5F5F',
  },
  deleteFile: {
    all: 'unset',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  documentPreview: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  documentPreviewIcon: {
    width: '30px',
    height: '30px',
    radius: '8px',
    color: '#5F5F5F',
  },
  applicationStepBottomDisclosure: {
    color: '#5F5F5F',
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    marginTop: '32px',
  },
  addTextOwners: {
    lineHeight: '16px',
    cursor: 'pointer',
    display: 'flex',
    color: '#FF5745',
    fontWeight: 400,
    textAlign: 'center',
  },
  addOwnerForm: {
    backgroundColor: '#ECE9E2', // This is the color but is not in the theme
    width: '100%',
    height: 'auto',
    borderRadius: theme.defaultRadius,
    padding: theme.spacing.xl,
    marginTop: '2rem',
    '@media(max-width: 767px)': {
      padding: theme.spacing.md,
    },
  },
  containerListOwner: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerOwnerForm: {
    display: 'flex',
    gap: theme.spacing.md,
    '@media(max-width: 767px)': {
      minWidth: 'auto',
      flexDirection: 'column',
    },
  },
  inputsOwnersFormRoot: {
    flex: 1,
  },
  containerSearchAddress: {
    display: 'flex',
    marginTop: '16px',
    gap: '24px',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      gap: '16px',
    },
  },
  stateZipContainer: {
    display: 'flex',
    gap: '24px',
    flex: 1,
  },
  inputsOwnerForm: {
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  dividerLine: { border: '1px solid #E7E7E7', marginTop: '1.5rem', height: 1 },
  addOwnerBtn: {
    width: 'auto',
    backgroundColor: 'transparent',
    border: '1px solid  #888888',
    padding: '0px 12px',
    cursor: 'pointer',
    marginTop: '1rem',
    color: '#646464',
    '&:hover': {
      backgroundColor: '#E7E7E7',
    },
    height: '42px',
  },
  containerFormOwnerBtn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  yellowContainer: {
    backgroundColor: theme.fn.themeColor('tertiary', 1),
    width: rem(168),
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: rem(14),
    fontWeight: 500,
    color: theme.fn.themeColor('primarySecondarySuccess', 8),
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 767px)': {
      fontSize: '12px',
      padding: '5px',
    },
  },
  containerTitleForm: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  containerCardOwners: {
    backgroundColor: '#ECE9E2',
    borderRadius: theme.defaultRadius,
    marginTop: theme.spacing.xl,
    padding: theme.spacing.xl,
  },
  cardBeneficialOwners: {
    height: '80px',
    borderRadius: theme.defaultRadius,
    padding: '8px 16px',
    gap: '16px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.fn.themeColor('neutral', 0),
  },
  nameOwnerText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: rem(16),
    lineHeight: '20px',
    marginTop: '1rem',
    color: theme.fn.themeColor('primarySecondarySuccess', 9),
  },
  applicationStepBottomDisclosureScroll: {
    color: '#5F5F5F',
    fontFamily: 'PP Neue Montreal',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '25px',
    marginTop: '32px',
    border: '1px solid #E6E7E9',
    borderRadius: '8px',
    padding: 10,
  },
  scrollDisclsoureBackground: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
  },
  btnSpacingAddressPage: {
    flex: 1,
    '@media(max-width: 767px)': {
      width: 'auto',
    },
  },
  largeGuy: {
    flex: 2,
  },
  plaidTrustedByText: {
    fontSize: '14px',
    fontWeight: 500,
    marginTop: 35,
    marginBottom: 28,
  },
  plaidTrustedByIcons: {
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  },
  addNewOwnerBtn: {
    backgroundColor: theme.fn.themeColor('neutral', 0),
    '&:not([data-disabled])': theme.fn.hover({
      backgroundColor: theme.fn.themeColor('neutral', 0),
    }),
  },
}));
