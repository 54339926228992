import { useForm } from '@mantine/form';
import { Button, TextInput } from '@mantine/core';

import {
  AccountProps,
  PlaidAccount,
  DepositAccount,
} from '../move-funds/move-funds.model';
import { useStyles } from './add-funds';
import { ArrowsHorizontalIcon } from 'assets/svg';
import { PaymentForm } from 'states/banking/payments';
import { formatCurrency } from 'utilities/formatters/format-currency';
import AccountSelection from 'areas/payments/components/account-selection';

interface Props {
  transferLoading: boolean;
  plaidAccounts: PlaidAccount[];
  depositAccounts: DepositAccount[];
  onMakeTransfer: (values: PaymentForm) => void;
}

const TransferFundsSection = ({
  plaidAccounts,
  onMakeTransfer,
  depositAccounts,
  transferLoading,
}: Props) => {
  const transferForm = useForm({
    initialValues: {
      amount: '0',
      toAccount: depositAccounts[0],
      fromAccount: plaidAccounts[0],
    },
  });

  const { classes, theme } = useStyles({
    amount: transferForm.values.amount || '0',
  });

  const onInputChange = (input: string) => {
    let currentValue = input.replace(/[^\d]/g, '');

    if (currentValue.length === 0) {
      transferForm.setFieldValue('amount', '0');
      return;
    }

    if (currentValue.length < 2) {
      currentValue = `0.0${currentValue}`;
    } else if (currentValue.length === 2) {
      currentValue = `0.${currentValue}`;
    } else if (currentValue.length > 9) {
      return;
    } else {
      currentValue =
        currentValue.slice(0, currentValue.length - 2) +
        '.' +
        currentValue.slice(-2);
    }

    transferForm.setFieldValue('amount', currentValue);
  };

  const selectAccount = (value: AccountProps, accountType: string) => {
    transferForm.setValues({
      ...transferForm.values,
      [`${accountType}Account`]: value,
    });
  };

  const transferFrom = (value: AccountProps) => {
    selectAccount(value, 'from');
  };

  const transferTo = (value: AccountProps) => {
    selectAccount(value, 'to');
  };

  return (
    <div>
      <TextInput
        variant="unstyled"
        classNames={{ input: classes.amountInput }}
        onChange={(v) => onInputChange(v.currentTarget.value)}
        value={formatCurrency(transferForm.values.amount || '0')}
        c={transferForm.values.amount === '0' ? '#C4C6C8' : '#000'}
      />

      <div className={classes.accountsSection}>
        <span className={classes.label}>Transferring from</span>
        <AccountSelection
          currentAccount={transferForm.values.fromAccount!}
          accounts={plaidAccounts}
          onAccountChange={(c) => {
            transferFrom(c);
          }}
        />
      </div>

      <div className={classes.accountsSection}>
        <span className={classes.label}>Transferring to</span>
        <AccountSelection
          currentAccount={transferForm.values.toAccount!}
          accounts={depositAccounts}
          onAccountChange={(c) => {
            transferTo(c);
          }}
        />
      </div>

      <div className={classes.rowContent}>
        <div className={classes.disclaimer}>
          {`By clicking Transfer, I authorize Flex to initiate the transaction detailed above. Flex is powered by Flexbase.`}
        </div>
        <Button
          variant="light"
          loading={transferLoading}
          onClick={() => onMakeTransfer(transferForm.values)}
          leftIcon={
            <ArrowsHorizontalIcon
              width={20}
              fill={theme.fn.themeColor('neutral', 0)}
            />
          }
        >
          Transfer
        </Button>
      </div>
    </div>
  );
};

export default TransferFundsSection;
