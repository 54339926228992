import { Group } from '@mantine/core';
import TableContainerWithSearchFilter from 'components/table/table-container';
import AccountDiversificationTable from '../components/treasury-tables/account-diversification-table';
import { useRecoilValue } from 'recoil';
import { TreasuryManagementState } from '../treasury-management.state';
import { ContentCard } from '@flexbase-eng/web-components';
import { formatCurrency } from 'utilities/formatters/format-currency';

const FlexTreasury = () => {
  const { diversificationAccounts, flexTreasuryAccounts } = useRecoilValue(
    TreasuryManagementState,
  );
  const mappedAccounts = Object.values(diversificationAccounts).map(
    (snapshot) => ({
      name: snapshot.bankName,
      availableBalance: snapshot.availableBalance,
      logoUrl: snapshot.logoUrl,
    }),
  );
  return (
    <div>
      <Group grow style={{ marginBottom: '28px' }}>
        <ContentCard
          title="Flex Treasury Account"
          content={formatCurrency(flexTreasuryAccounts[0].availableBalance)}
        />
        <ContentCard title="Yield being generated" content="Pending" />
      </Group>
      <TableContainerWithSearchFilter
        title={`Account Diversification ${[].length ? [].length : ''}`}
      >
        <AccountDiversificationTable
          accounts={mappedAccounts}
          loading={false}
        />
      </TableContainerWithSearchFilter>
    </div>
  );
};

export default FlexTreasury;
