import ValidatorFn from './validator-fn';

export const validateUSPhoneNumber = (value: string): boolean =>
  /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value);

export function PhoneNumberValidator(
  errorMessage?: string,
): ValidatorFn<string> {
  return (val) =>
    validateUSPhoneNumber(val)
      ? null
      : errorMessage || 'Must be a valid US phone number';
}
