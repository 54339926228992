import { useStyles } from 'components/styles';

interface Props {
  children: React.ReactNode;
}
const FlexbaseMainLayout = (props: Props) => {
  const { classes } = useStyles();
  return <div className={classes.mainContainer}>{props.children}</div>;
};

export default FlexbaseMainLayout;
