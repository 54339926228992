import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PromoCodeState } from '../../states/application/product-onboarding';
import { useEffect } from 'react';
import { LoadingOverlay } from '@mantine/core';

/**
 * This component sits on a route to capture promo codes and save them into state.
 * When a user registers, this code will be saved to their user.
 *
 * Current existing promo codes
 * DEV: fishy22 (no uw auto-reject bypass), vip-dev (uw auto-reject bypass)
 * PROD: zaidvip (uw auto-reject bypass)
 *
 * @constructor
 */
export const ReferralRoute = () => {
  const setPromoCode = useSetRecoilState(PromoCodeState);
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // For now, individual promo code handling has been removed.
    // Add this back in if/when we need to set different products or have different
    // handling of certain promo codes.
    if (code) {
      setPromoCode(code);
      navigate('/register', { replace: true });
    }
  }, [code]);

  return <LoadingOverlay visible={true} />;
};
