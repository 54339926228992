import {
  OnboardingCompany,
  OnboardingOverviewResponse,
  OnboardingStatusResponse,
  OnboardingUser,
  ShipCardRequest,
  SubmitUnderwritingResponse,
  UnderwritingResponse,
} from 'states/onboarding/onboarding-info';
import { StripeDetails } from 'states/onboarding/stripe-details';
import { FlexbaseClient } from 'flexbase-client';
import { Wretcher } from 'wretch';
import { Analytics } from 'services/analytics/analytics';
import { AlertParam } from 'states/notifications/alert-params';
import { UserPreferences } from 'states/user/preferences';
import { CompanyCreditBalance } from 'states/company/company-credit-balance';
import { FlexbaseResponse } from 'flexbase-client/dist/models/FlexbaseResponse';
import { QbooksCompany } from 'states/qbooks/qbooks-company';
import { ProductOnboardingStatusResponse } from 'states/application/product-onboarding.models';
import { PlaidAccounts } from './banking.model';
import { MccCode } from './client.model';
import { FicoCreditModel } from './fico.model';
import { GetPaymentByIdResponse } from './payment.model';
import { PersonalGuarantyResponse } from './personal-guaranty.model';

type AccountBalance = {
  available: number;
  current: number;
  iso_currency_code: string;
  limit: number;
  unofficial_currency_code: string;
};
export type PlaidAccount = {
  account_id: string;
  balances: AccountBalance;
  mask: string;
  name: string;
  official_name: string;
  subtype: string;
  type: string;
};
type AccountResponse = {
  success: boolean;
  accounts: Array<PlaidAccount>;
};
type NewUser = {
  id: string;
  username: string;
  email: string;
  companyId: string;
  tempPassword: string;
  roles: string[];
};
type UserInviteResponse = {
  newUser: NewUser;
  status: string;
  emailStatus: string;
  success: boolean;
};

export interface MinimumDue {
  success: boolean;
  totalInvoices: number;
  totalPayments: number;
  fraudChargeOff: number;
  noPayChargeOff: number;
  currentBalance: number;
  creditLimit: number;
  availableLimit: number;
  billDate: string;
  graceDate: string;
  minimumDue: number;
  delinquentAmount: number;
  delinquentDays?: number;
  aprPct: number;
  interestDue: number;
  frozen: boolean;
  active: boolean;
  minimumAllowedPayment: number;
  maximumAllowedPayment: number;
  paymentMadeThisCycle: boolean;
  comeCurrentPayment: number;
}

export interface InvoicesByMccCode {
  allInvoices?: string;
  categories?: Category[];
  fromDate?: string;
  success: boolean;
  toDate?: string;
}

export interface Category {
  category?: string;
  description?: string;
  count?: number;
  total?: string;
}

export type HomeTransaction = {
  amount: string;
  date: string;
  transaction: string;
  type: string;
  who: string;
  logoUrl?: string;
};

type HomeTransactionsResponse = {
  companyId: string;
  fromDate: string;
  tenantId: string;
  toDate: string;
  error?: string;
  transactions: HomeTransaction[];
};

export interface ComingDueResponse {
  success: boolean;
  starting: string;
  comingDue: ComingDue[];
}

export interface Invoice {
  cardholder: string;
  city: string;
  date: string;
  last4: string;
  name: string;
  origin: string;
  postalCode?: string;
  project: any;
  state: string;
  total: string;
}

export interface ComingDue {
  billDate: string;
  invoices: string | Invoice;
}

export type TermsOfServiceType = 'flexbase' | 'banking' | 'treasury' | 'credit';

export interface TermsOfService {
  contents: string;
  createdAt: string;
  id: string;
  type: TermsOfServiceType;
  validFrom: string;
  validUntil?: string;
}

export interface TermsOfServiceDoc {
  type: TermsOfServiceType;
  url: string;
  label: string;
  tosId: string;
  tosCreatedAt: string;
}

export interface ShortCompanyDocument {
  companyId: string;
  description: string;
  docDate: string;
  docId: string;
  droppedAt: string;
  expiresAt: string;
  id: string;
  tenantId: string;
  type: string;
  uploadedAt: string;
  userId: string;
}

export interface CompanyDocument extends ShortCompanyDocument {
  metadata: DocumentMetadata;
}

export interface DocumentMetadata {
  createdAt: string;
  docType: string;
  extension: string;
  id: string;
  path: string;
  sourceName: string;
  validFrom: string;
  validUntil: string;
}

export interface CompanyDocumentsResponse extends FlexbaseResponse {
  companyDocs: CompanyDocument[];
}

export interface CompanyDocumentUploadResponse extends FlexbaseResponse {
  company: OnboardingCompany;
  companyDoc: ShortCompanyDocument;
  docMetadata: DocumentMetadata;
}
export interface CompanyDocumentResponse extends FlexbaseResponse {
  companyDoc: CompanyDocument;
}
interface UploadProps {
  file: string | Blob;
  description?: string;
  type?: string;
  docDate?: string;
  expiresAt?: string;
}

// companyAccounts which correlates to Plaid Accounts that are linked
export interface PlaidCompanyAccounts {
  success: boolean;
  accounts: Account[];
}

export interface Account {
  accessToken: string;
  account: string;
  accountId: string;
  accountName: string;
  accountType: string;
  active: boolean;
  bankName: string;
  companyId: string;
  id: string;
  institutionId?: string;
  itemId?: string;
  last4?: string;
  logoUrl?: string;
  officialName?: string;
  ownerInfo?: OwnerInfo;
  routing?: string;
  stripeBankAccountToken?: string;
  unitProcessorToken?: string;
  unlinked: boolean;
  userId: string;
  tenantId: string;
}

export interface OwnerInfo {
  address: Address;
  email: string;
  name: string;
  phone: string;
}

export interface Address {
  city: string;
  country: string;
  line1: string;
  postalCode: string;
  state: string;
}

type AnotherAddressModel = {
  address: string;
  errors: { [key: string]: any };
  city: string;
  state: string;
  postalCode: string;
  status: 'verified' | 'corrected' | 'failed' | string;
};

type VerifyAddressResponse = {
  address: AnotherAddressModel;
  verified: boolean;
};

export type QBooksAccount = {
  AccountSubType: string;
  AccountType: string;
  Active: boolean;
  Classification: string;
  CurrencyRef: {
    name: string;
    value: string;
  };
  CurrentBalance: number;
  CurrentBalanceWithSubAccounts: number;
  FullyQualifiedName: string;
  Id: string;
  MetaData: {
    CreateTime: string;
    LastUpdatedTime: string;
  };
  Name: string;
  SubAccount: boolean;
  SyncToken: string;
  domain: string;
  sparse: boolean;
};

export interface QBooksAccountsResponse extends FlexbaseResponse {
  accounts: QBooksAccount[];
  asOf: string;
  byUser: string;
  companyId: string;
  id: string;
  realmId: string;
  version: number;
}

export type QBooksMappingTarget = {
  account: QBooksAccount;
  expense: QBooksAccount;
  companyId?: string;
  storeId?: string;
  mccCode?: string;
  nameBit?: string;
  id?: string;
};

export type QBooksDeleteMappingTargetResponse = {
  success: boolean;
  target: QBooksMappingTarget;
};

export interface QBooksMappingTargetsResponse extends FlexbaseResponse {
  targets: (QBooksMappingTarget & {
    asOf: string;
    byUser: string;
    id: string;
    realmId: string;
    version: number;
  })[];
}

export type TreasuryApplicationResult = {
  userId: string;
  companyId: string;
  success: boolean;
  approved: boolean;
  tenantId: string;
};

export type CommercialBank = {
  flexbasePartner: boolean;
  name: string;
  website: string;
  logoUrl: string;
};

export interface TopCommercialBanksResponse extends FlexbaseResponse {
  banks: CommercialBank[];
}

export type PartnerTemplate = {
  id: string;
  description: string;
  partnerName: string;
  sendTo: string[];
  template: string;
};

export interface PartnerTemplatesResponse extends FlexbaseResponse {
  templates: PartnerTemplate[];
}

export interface TermsOfServiceAcceptance {
  type: TermsOfServiceType;
  acceptedAt: string;
  ipAddr?: string;
  userId: string;
  contents: string;
  tosCreatedAt: string;
  tosId: string;
}

interface Transactions {
  amount: string;
  date: string;
  id: string;
  status: string;
  transaction: string;
  type: string;
  who: string;
}

export interface CompanyTransactionResponse {
  fromDate: string;
  toDate: string;
  transactions: Transactions[];
}

interface ServicingParameters {
  target?: string;
  after?: string;
  before?: string;
  inclReversed?: string;
  inclExpired?: string;
  chargeOffs?: string;
}

export class FlexbaseOnboardingClient {
  private readonly client: Wretcher;

  private servicingParams(options?: ServicingParameters) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {};

    let property: keyof ServicingParameters;
    for (property in options) {
      if (options && Object.hasOwn(options, property)) {
        if (typeof options[property] === 'boolean') {
          params[property] = true;
        } else {
          params[property] = options[property];
        }
      }
    }

    return params;
  }

  // This is a dirty, dirty hack until the entire onboarding client and all models are in flexbase-client
  constructor(flexbaseClient: FlexbaseClient) {
    this.client = flexbaseClient['_client'] as Wretcher;
  }

  async getOnboardingStatus(
    getFullData = false,
  ): Promise<OnboardingStatusResponse> {
    const response = await this.client
      .url('/onboarding')
      .query({ full: getFullData })
      .get()
      .json<OnboardingStatusResponse>();
    if (response.success) {
      Analytics.people.set(response.user.id, { ...response.company });
      Analytics.checkSetReservedProperty.set({ ...response.user });
    }
    return response;
  }

  async getOnboardingOverview(): Promise<OnboardingOverviewResponse> {
    const response = await this.client
      .url('/onboarding/overview')
      .get()
      .json<OnboardingOverviewResponse>();
    return response;
  }

  async getProductOnboardingStatus(
    getFullData = false,
  ): Promise<ProductOnboardingStatusResponse> {
    const response = await this.client
      .url('/onboarding')
      .query({ full: getFullData })
      .get()
      .json<ProductOnboardingStatusResponse>();

    if (response.success) {
      Analytics.people.set(response.user.id, {
        ...response.user,
        ...response.company,
        // coerce output to string for ease of using in ActiveCampaign
        creditApplicant:
          response.company.optedProducts?.includes('CREDIT') == true
            ? 'TRUE'
            : 'FALSE',
        treasuryApplicant:
          response.company.optedProducts?.includes('TREASURY') == true
            ? 'TRUE'
            : 'FALSE',
        bankingApplicant:
          response.company.optedProducts?.includes('BANKING') == true
            ? 'TRUE'
            : 'FALSE',
        plaidLinked: response.user?.plaidLinked == true ? 'true' : 'false',
      });
      Analytics.checkSetReservedProperty.set({ ...response.user });
    }
    return response;
  }

  async updateCompany(
    company: Partial<OnboardingCompany>,
  ): Promise<OnboardingCompany> {
    const result = await this.client
      .url('/onboarding/company')
      .put(company)
      .json<{ success: boolean; company: OnboardingCompany }>();
    if (result.success) {
      Analytics.group.set(result.company.id, {
        ...result.company,
      });
      Analytics.track('Company Updated', { ...result.company });
    }
    return result.company;
  }

  async createCompany(
    company: Partial<OnboardingCompany>,
    userIsOwner = false,
  ): Promise<OnboardingCompany> {
    const result = await this.client
      .url('/onboarding/company')
      .query({ userIsOwner })
      .post(company)
      .json<{ success: boolean; company: OnboardingCompany }>();
    if (result.success) {
      Analytics.group.set(result.company.id, {
        ...result.company,
      });
      Analytics.track('Company Created', { ...result.company });
    }
    return result.company;
  }

  async addOwner(
    user: Partial<OnboardingUser & { ownershipPct: number }>,
    sendEmail = false,
  ): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/owner')
      .query({ inviteEmail: sendEmail })
      .put(user)
      .json<{ success: boolean; user: OnboardingUser }>();
    Analytics.track('Company Owner Added', { companyOwner: result.user });
    return result.user;
  }

  async updateUser(user: Partial<OnboardingUser>): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/user')
      .put(user)
      .json<{ success: boolean; user: OnboardingUser }>();
    if (result.user.id && result.success) {
      Analytics.people.set(result.user.id, {
        ...user,
      });
    }
    if (result.success) {
      Analytics.checkSetReservedProperty.set({ ...user });
      Analytics.track('Profile Updated for User');
    }
    return result.user;
  }

  async createUser(user: Partial<OnboardingUser>): Promise<OnboardingUser> {
    const result = await this.client
      .url('/onboarding/user')
      .post(user)
      .json<{ success: boolean; newUser: OnboardingUser }>();
    return result.newUser;
  }

  async getTermsOfService(
    type_: TermsOfServiceType = 'flexbase',
    useAuth = true,
  ): Promise<TermsOfService> {
    const result = await this.client
      .url(`/onboarding/tos/${type_}`)
      .options({ authContext: { isAnonymousRoute: !useAuth } }, true)
      .get()
      .json<{ success: boolean; termsOfService: TermsOfService }>();

    return result.termsOfService;
  }

  async getTermsOfServiceDocs(
    type_?: TermsOfServiceType,
    useAuth = true,
  ): Promise<TermsOfServiceDoc[]> {
    const queryParams: Partial<Record<'type', TermsOfServiceType>> = {};

    if (type_) {
      queryParams.type = type_;
    }

    const result = await this.client
      .url('/onboarding/tos/documents')
      .query(queryParams)
      .options({ authContext: { isAnonymousRoute: !useAuth } }, true)
      .get()
      .json<{ success: boolean; documents: TermsOfServiceDoc[] }>();

    return result.documents;
  }

  async updateStripe(stripeInformation: StripeDetails): Promise<boolean> {
    const result = await this.client
      .url('/onboarding/stripe')
      .put(stripeInformation)
      .json<{ success: true }>();

    return result.success;
  }

  async uploadStripeVerificationFile(formData: FormData): Promise<any> {
    return await this.client
      .url('/stripe/person/uploadFiles')
      .body(formData)
      .post()
      .json<StripeDetails>();
  }

  // This function does not belong here, but it is not in flexbase-client underwriting
  async runUnderwriting(
    userId: string,
    level: number,
    isBnpl = false,
  ): Promise<UnderwritingResponse> {
    const result = await this.client
      .url(`/underwriting/updateLevel/${userId}`)
      .query({ level })
      .headers({ 'X-FLEXBASE-SOURCE': isBnpl ? 'BNPL' : 'WEB' })
      .put()
      .json<UnderwritingResponse>();
    Analytics.track('Credit Underwriting Generated', {
      approved: result.approved,
      success: result.success,
      company: result.company,
      denial_reason: result.checks?.[result.checks?.length - 1]?.reason || null,
    });
    return result;
  }

  async checkUnderwriting(
    userId: string,
    level = 1,
    isBnpl = false,
  ): Promise<UnderwritingResponse> {
    const result = await this.client
      .url(`/underwriting/checkLevel/${userId}`)
      .query({ level })
      .headers({ 'X-FLEXBASE-SOURCE': isBnpl ? 'BNPL' : 'WEB' })
      .get()
      .json<UnderwritingResponse>();
    Analytics.track('Credit Underwriting Generated', {
      approved: result.approved,
      success: result.success,
      company: result.company,
      denial_reason: result.checks?.[result.checks?.length - 1]?.reason || null,
    });
    return result;
  }

  async runUnderwritingForTarget(
    userId: string,
    target: string | number,
    isBnpl = false,
  ): Promise<UnderwritingResponse> {
    const result = await this.client
      .url(`/underwriting/updateLevel/${userId}`)
      .headers({ 'X-FLEXBASE-SOURCE': isBnpl ? 'BNPL' : 'WEB' })
      .query({ target })
      .put()
      .json<UnderwritingResponse>();
    Analytics.track('BNPL Credit Underwriting Generated', {
      approved: result.approved,
      success: result.success,
      company: result.company,
      denial_reason: result.checks?.[result.checks?.length - 1]?.reason || null,
    });
    return result;
  }

  async underwritingSubmit(): Promise<SubmitUnderwritingResponse> {
    const result = await this.client
      .url('/underwriting/submit')
      .post()
      .json<SubmitUnderwritingResponse>();
    Analytics.track('Credit Underwriting Generated', {
      approved: result.approved,
      success: result.success,
      company: result.companyId,
      level: result.level,
    });
    return result;
  }

  // This function does not belong here, but it was removed from the repo and not added to flexbase-client. It will eventually be removed.
  async shipCard(
    userId: string,
    shippingDetails: ShipCardRequest,
  ): Promise<boolean> {
    const result = await this.client
      .url(`/card/${userId}/issue`)
      .post(shippingDetails)
      .json<{ success: boolean }>(); // This actually returns much more information, but we don't currently need it.
    Analytics.track('Credit Card Shipped', { ...shippingDetails });
    return result.success;
  }

  async getUserCompany(): Promise<any> {
    return await this.client.url(`/tenant`).get().json();
  }

  async getUserCards(): Promise<any> {
    return await this.client.url('/card/mine').get().json();
  }

  // TODO: Add this to Flexbase Client. Need to get it out ASAP and client modifications will take longer.
  async verifyBnplTransaction(
    session: string,
    apiKey: string,
    amount: string | number,
  ): Promise<boolean> {
    try {
      const result = await this.client
        .url('/credit/buyNow/verify')
        .post({ apiKey, session, amount })
        .json<{ success: boolean; errors?: string[] }>();
      return result.success;
    } catch (error) {
      return false;
    }
  }

  /**
   * NOTE: This endpoint will not currently work in prod.
   */
  async getPromissoryNote(): Promise<{
    documentUuid: string;
    documentUrl: string;
    signed: boolean;
  }> {
    const result = await this.client
      .url('/underwriting/promissoryNote')
      .get()
      .json<{
        promissoryNote: {
          documentUuid: string;
          documentUrl: string;
          signed: boolean;
        };
      }>();

    return result.promissoryNote;
  }

  /**
   * NOTE: This endpoint DOES NOT WORK! It was always return an error. Also, this endpoint will not currently work in prod.
   */
  async acceptCredit(): Promise<boolean> {
    try {
      const result = await this.client
        .url('/underwriting/activateLOC')
        .put()
        .json<{ success: boolean; status: string }>();

      return result.success && result.status === 'ACTIVE';
    } catch (error) {
      return false;
    }
  }

  async getPlaidCompanyAccounts(): Promise<PlaidAccounts> {
    return this.client
      .url(`/plaid/companyAccounts`)
      .get()
      .json<PlaidAccounts>();
  }

  async getLinkedAccountBalances(): Promise<AccountResponse> {
    return this.client.url('/plaid/balances').get().json<AccountResponse>();
  }

  // Deprecated - we're now using plaid for repayments instead of stripe
  async makePayment(amount: number): Promise<boolean> {
    try {
      const response = await this.client
        .url('/servicing/payments/stripe')
        .post({ amount: amount.toString() })
        .json<{
          success: boolean;
          cardPayment: { status: 'failed' | 'pending' | 'succeeded' };
        }>();

      return response.success && response.cardPayment.status !== 'failed';
    } catch (err) {
      return false;
    }
  }

  async makePaymentPlaid(
    amount: number,
    plaidTokenId: string,
    currency = 'usd',
  ): Promise<{
    success: boolean;
    cardPayment: { id: string; status: string };
  }> {
    return await this.client
      .url('/servicing/payments/plaid')
      .post({ amount: (amount * 100).toFixed(0), currency, plaidTokenId })
      .json();
  }

  async getPaymentById(id: string): Promise<GetPaymentByIdResponse> {
    return await this.client
      .url(`/card/payments/${id}`)
      .get()
      .json<GetPaymentByIdResponse>();
  }

  /**
   * Use this endpoint to show balance data for a billing view.
   * @param companyId
   * @param targetDate
   */
  async getMinimumDue(
    companyId: string,
    targetDate?: string,
  ): Promise<MinimumDue> {
    let minimumDueRequest = this.client.url(
      `/servicing/minimumDue/${companyId}`,
    );

    if (targetDate) {
      minimumDueRequest = minimumDueRequest.query({ target: targetDate });
    }

    return await minimumDueRequest.get().json<MinimumDue>();
  }

  async getComingDue(
    companyId: string,
    justTotals?: boolean,
  ): Promise<ComingDueResponse> {
    let getComingDueRequest = this.client.url(
      `/invoice/comingDue/${companyId}`,
    );
    if (justTotals) {
      getComingDueRequest = getComingDueRequest.query({ justTotals });
    }
    return await getComingDueRequest.get().json<ComingDueResponse>();
  }

  async getInvoicesByMccCategory(
    companyId: string,
    params: {
      limit?: string;
      after?: string;
      before?: string;
    },
  ): Promise<InvoicesByMccCode> {
    return await this.client
      .url(`/servicing/invoices/byCategory/${companyId}`)
      .query(params)
      .get()
      .json<InvoicesByMccCode>();
  }

  //TODO: temporary way to invite users
  // remove and reimplement at client level in the future
  async inviteUser(
    firstName: string,
    lastName: string,
    email: string,
    role: string,
    companyId?: string,
  ): Promise<UserInviteResponse> {
    return await this.client
      .url('/user')
      .post({ firstName, lastName, email, companyId, roles: [role] })
      .json<UserInviteResponse>();
  }

  async updateUserRole(
    id: string,
    roles: string[],
  ): Promise<UserInviteResponse> {
    return await this.client
      .url(`/user/${id}`)
      .put({ roles })
      .json<UserInviteResponse>();
  }

  //TODO: temporary way to retrieve home transactions
  // remove and reimplement at client level in the future
  async getHomeTransactions(
    companyId?: string,
  ): Promise<HomeTransactionsResponse> {
    return await this.client
      .url(`/servicing/transactions/${companyId}`)
      .get()
      .json();
  }

  async deleteUser(userId: string): Promise<any> {
    return await this.client.url(`/user/${userId}`).delete().json();
  }

  //TODO: temporary way to retrieve pdf statement (banking)
  // remove and reimplement at client level in the future
  async getPdfBankingStatement(
    companyId: string,
    statementId: string,
  ): Promise<any> {
    return await this.client
      .url(`/banking/${companyId}/statements/${statementId}`)
      .query('isPdf=true')
      .get();
  }

  async getUserPreference(): Promise<{ preferences: UserPreferences }> {
    return await this.client
      .url(`/user/self`)
      .get()
      .json<{ preferences: UserPreferences }>();
  }

  async updateUserPreference(userId: string, preference: any): Promise<any> {
    return await this.client.url(`/user/${userId}`).put(preference).json();
  }

  async getAlertParams(): Promise<AlertParam[]> {
    return await this.client.url(`/servicing/alertParams`).get().json();
  }

  /**
   * Use this endpoint to show company balance data for a credit view. DO NOT USE FOR BILLING!
   * @param companyId
   */
  async getCreditBalance(companyId: string): Promise<CompanyCreditBalance> {
    return await this.client
      .url(`/servicing/balance/${companyId}`)
      .get()
      .json<CompanyCreditBalance>();
  }

  /**
   * Endpoint to upload a document of any kind to keep in the
   * @param {Object} prop
   * @param {strong | Blob} prop.file
   * @param {string} prop.description
   * @param {string} prop.type
   * @param {string} prop.docDate
   * @param {string} prop.expiresAt
   *
   */
  async uploadDocument({
    file,
    description,
    type,
    docDate,
    expiresAt,
  }: UploadProps): Promise<CompanyDocumentUploadResponse> {
    const body = JSON.stringify({
      description,
      type,
      docDate,
      expiresAt,
    });
    return await this.client
      .url(`/tenant/doc`)
      .formData({
        file,
        body,
      })
      .post()
      .json<CompanyDocumentUploadResponse>();
  }

  /**
   * Endpoint to get list of documents for the tenant
   */
  async getDocuments(): Promise<CompanyDocumentsResponse> {
    return await this.client
      .url(`/tenant/doc`)
      .get()
      .json<CompanyDocumentsResponse>();
  }

  /**
   * Endpoint to soft delete document as an ADMIN or SERVICE
   * @param docId
   */
  async deleteDocument(docId: string): Promise<CompanyDocumentResponse> {
    return this.client
      .url(`/tenant/doc/${docId}`)
      .delete()
      .json<CompanyDocumentResponse>();
  }

  async downloadDocument(docId: string): Promise<Blob | null> {
    return await this.client
      .url(`/doc/${docId}`)
      .query({ tofile: true })
      .get()
      .blob();
  }

  async getFicoBucket(): Promise<{
    bucket: string;
    success: boolean;
    userId: string;
  }> {
    return await this.client.url('/servicing/ecredit').get().json();
  }

  async getFicoScore(userId: string): Promise<FicoCreditModel> {
    return await this.client
      .url(`/servicing/users/runCredit/${userId}`)
      .get()
      .json();
  }

  async verifyAddress(
    addressToVerify: Partial<AnotherAddressModel>,
  ): Promise<VerifyAddressResponse> {
    return await this.client
      .url('/address/verify')
      .post(addressToVerify)
      .json<VerifyAddressResponse>();
  }

  async checkTreasuryApplication(): Promise<TreasuryApplicationResult> {
    return await this.client.url('/treasury/checkApplication').get().json();
  }

  async completeTreasuryApplication(): Promise<TreasuryApplicationResult> {
    return await this.client.url('/treasury/checkApplication').post().json();
  }

  async qbooksLogin(): Promise<any> {
    return await this.client.url('/qbooks/login').get().json();
  }

  async qbooksUnlink(): Promise<any> {
    return await this.client.url('/qbooks/token').delete().json();
  }

  async getQbooksCompany(): Promise<QbooksCompany> {
    return await this.client
      .url('/qbooks/companyInfo')
      .get()
      .json<QbooksCompany>();
  }

  async getQbooksAccounts(): Promise<QBooksAccountsResponse> {
    return await this.client
      .url('/qbooks/accounts')
      .get()
      .json<QBooksAccountsResponse>();
  }

  async getQbooksMappingTargets(): Promise<QBooksMappingTargetsResponse> {
    return await this.client
      .url('/qbooks/targets')
      .get()
      .json<QBooksMappingTargetsResponse>();
  }

  async putQbooksMappingTarget(
    target: QBooksMappingTarget,
  ): Promise<QBooksMappingTarget> {
    return await this.client
      .url('/qbooks/targets')
      .put(target)
      .json<QBooksMappingTarget>();
  }

  async deleteQbooksMappingTarget(targetId: string): Promise<any> {
    return await this.client
      .url(`/qbooks/targets/${targetId}`)
      .delete()
      .json<QBooksDeleteMappingTargetResponse>();
  }

  async getQbooksUnsentInvoices(): Promise<any> {
    return await this.client.url('/qbooks/unsent').get().json<any>();
  }

  async resendQbooksUnsentInvoices(
    invoices: { id: string }[],
    companyId?: string,
  ): Promise<any> {
    return await this.client
      .url('/qbooks/unsent/resend')
      .post({
        invoices,
        companyId,
      })
      .json<any>();
  }

  async getTreasuryTopCommercialBanks(): Promise<TopCommercialBanksResponse> {
    return await this.client
      .url('/treasury/topCommercialBanks')
      .get()
      .json<TopCommercialBanksResponse>();
  }

  async getTreasuryPartnerTemplates(): Promise<PartnerTemplatesResponse> {
    return await this.client
      .url('/treasury/partnerTemplates')
      .get()
      .json<PartnerTemplatesResponse>();
  }

  async referToPartner(id: string): Promise<any> {
    return await this.client
      .url('/treasury/referToPartner')
      .post({ id })
      .json();
  }

  async deleteAuthToken(): Promise<any> {
    return await this.client.url('/auth/token').delete().json();
  }

  async getTosHistory(): Promise<TermsOfServiceAcceptance[]> {
    const { history } = await this.client
      .url(`/onboarding/tos/history`)
      .get()
      .json();

    return history;
  }

  async getCompanyTransactions(
    companyId: string,
    options?: ServicingParameters,
  ): Promise<CompanyTransactionResponse> {
    const params = this.servicingParams(options);
    return await this.client
      .url(`/servicing/transactions/${companyId}`)
      .query(params)
      .get()
      .json();
  }

  async getMccCodes(): Promise<MccCode[]> {
    return await this.client.url('/servicing/mccGroups').get().json();
  }

  async getPersonalGuaranty(): Promise<PersonalGuarantyResponse> {
    return await this.client.url('/onboarding/personalGuaranty').get().json();
  }
}
