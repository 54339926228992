import { ChangeEvent, useState } from 'react';

import { useClipboard, useMediaQuery } from '@mantine/hooks';
import {
  Text,
  Avatar,
  Button,
  Grid,
  Image,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { useStyles } from '../styles';
import SkeletonLoading from 'components/loading/skeleton-loading';
import { formatCurrency } from 'utilities/formatters/format-currency';
import {
  DownloadIcon,
  FlexIconShort,
  PlusSignIcon,
  SearchIcon,
} from 'assets/svg';
import {
  BankingApplicationStatus,
  DepositAccount,
} from 'services/flexbase/banking.model';
import FlexbaseTable from 'components/table/flexbase-table';
import { useRecoilState, useRecoilValue } from 'recoil';
import NewAccountModal from './new-account.modal';
import { flexbaseBankingClient } from 'services/flexbase-client';
import {
  DepositAccountsSelectedState,
  DepositAccountsState,
  depositAccountsSelectedState,
} from '../states/deposit-accounts';
import RecentTransactionsWidget from './recent-transactions-widget';
import useModal from '../../../components/modal/modal-hook';
import AccountDetails from './account-details';
import { UserInfoState } from 'states/user/user-info';
import { ConditionalStyles } from 'react-data-table-component';
import MakePaymentWidget from 'areas/cash-management/components/make-payment.widget';
import { downloadWireInstructions } from './utilities';
import { useRouteSectionContext } from 'components/routes/route-context';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from 'states/application/product-onboarding';
import ProgressPercent from '../components/progress-percent/progress-percent';
import WatchBAnking from 'assets/images/watch-banking.png';

interface Props {
  loading: boolean;
  errorMessage: boolean;
  documentsLink: string;
  bankingAccounts: DepositAccount[];
  applicationStatus: BankingApplicationStatus | null;
}

export interface DepositAccountRow extends DepositAccount {
  toggleSelected: boolean;
}

const BankingAccount = ({
  loading,
  errorMessage,
  documentsLink,
  applicationStatus,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const clipboard = useClipboard();
  const [addModal, setAddModal] = useState(false);
  const user = useRecoilValue(UserInfoState);
  const navigate = useNavigate();
  const [bankingAccounts, setBankingAccounts] =
    useRecoilState(DepositAccountsState);
  const productStatus = useRecoilValue(ApplicationState);
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [, setSelectedAccount] = useRecoilState(DepositAccountsSelectedState);
  const data = useRecoilValue(depositAccountsSelectedState);
  const modals = useModal();
  const closedAccounts = data.filter((account) => account.status === 'Closed');
  const openAccounts = data
    .filter((account) => account.status === 'Open')
    .sort((a, b) => b.balance - a.balance);
  const { setShowRoutesFor } = useRouteSectionContext();
  const sortDepositAccounts = [...openAccounts, ...closedAccounts];

  function inputHandler(event: ChangeEvent<HTMLInputElement>): void {
    // throw new Error('Function not implemented.');
  }

  const conditionalRowStyles: ConditionalStyles<DepositAccountRow>[] = [
    {
      when: (row: DepositAccountRow) => row.toggleSelected,
      style: {
        backgroundColor: theme.colors.neutral[2],
      },
    },
  ];

  const updateNickname = async (accountId: string, nickName: string) => {
    try {
      const result = await flexbaseBankingClient.editDepositNickName(
        accountId,
        nickName,
      );
      if (result.success) {
        setBankingAccounts(
          bankingAccounts.map((account) => {
            if (account.id === accountId) {
              return { ...account, nickName };
            }
            return account;
          }),
        );
      }
    } catch (error) {
      console.error('Unable to update the nickname', error);
    }
  };

  const columns = [
    {
      name: 'Account',
      cell: (row: DepositAccount) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar radius="xl" mr={15} w={45} h={45}>
            <FlexIconShort />
          </Avatar>
          {row.nickName}
        </div>
      ),
      sortable: true,
      selector: (row: { nickName: string }) => row.nickName,
      grow: 3,
    },
    {
      name: 'Routing number',
      cell: (row: { routingNumber: string }) => row.routingNumber,
      grow: 1,
    },
    {
      name: 'Account number',
      cell: (row: { accountNumber: string }) => {
        const [displayAccountNumber, setDisplayAccountNumber] = useState(false);
        return (
          <div>
            {displayAccountNumber ? (
              <Tooltip
                label={clipboard.copied ? 'Copied' : 'Click to copy'}
                withArrow
                data-testid="account-tooltip-icon"
              >
                <div style={{ display: 'flex' }}>
                  <button
                    style={{ color: '#5F5F5F' }}
                    onClick={() => clipboard.copy(row.accountNumber)}
                  >
                    {row.accountNumber}
                  </button>
                  <button onClick={() => setDisplayAccountNumber(false)}>
                    <IoEyeOffOutline color="#85868D" size={16} />
                  </button>
                </div>
              </Tooltip>
            ) : (
              <div style={{ display: 'flex' }}>
                <div>•••••{row.accountNumber?.slice(-4)}</div>

                <button onClick={() => setDisplayAccountNumber(true)}>
                  <IoEyeOutline color="#85868D" size={16} />
                </button>
              </div>
            )}
          </div>
        );
      },
      sortable: false,
      grow: 1,
    },
    {
      name: 'Balance',
      format: (row: { balance: number }) =>
        formatCurrency(row.balance / 100 || 0),
      selector: (row: { balance: number }) => row.balance,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Wire details',
      cell: (row: DepositAccount) => {
        return (
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => downloadWireInstructions(row.id)}
          >
            <DownloadIcon
              color={theme.fn.themeColor('primarySecondarySuccess', 2)}
              width={15}
              height={15}
            />
            Download
          </div>
        );
      },
    },
  ];

  const columnsSm = [
    {
      grow: 2,
      sortable: true,
      name: 'Account',
      cell: (row: DepositAccount) => row.nickName,
      selector: (row: { nickName: string }) => row.nickName,
    },
    {
      sortable: true,
      name: 'Balance',
      selector: (row: { balance: number }) => row.balance,
      format: (row: { balance: number }) => formatCurrency(row.balance / 100),
    },
  ];

  const AccountSummaryCard = () => {
    return (
      <ProgressPercent
        currentAmount={bankInfo.balance / 100}
        title="Banking Balance"
        totalAvailableBalance={(bankInfo.balance / 100) * 2}
        content={undefined}
        classNames={{ card: classes.cardOverride }}
      />
    );
  };

  const openModalDetails = (accountData: DepositAccountRow) => {
    setSelectedAccount(accountData.id);
    if (useMobileView) {
      modals.openSizedCenterModal(
        <AccountDetails
          bankAccount={accountData}
          onSubmit={(accountId: string, nickName: string) =>
            updateNickname(accountId, nickName)
          }
        />,
        {
          closeModal: () => {
            setSelectedAccount('');
          },
          closeOnClickOutside: true,
        },
      );
    } else {
      modals.openRightModalNoOpacity(
        <AccountDetails
          bankAccount={accountData}
          onSubmit={(accountId: string, nickName: string) =>
            updateNickname(accountId, nickName)
          }
        />,
        {
          closeModal: () => {
            setSelectedAccount('');
          },
        },
      );
    }
  };

  // temp cals for tiers
  const bankAccountsInfo = () => {
    let balance = 0;
    bankingAccounts.forEach((account) => {
      balance += account.balance;
    });
    return {
      balance,
    };
  };
  const bankInfo = bankAccountsInfo();

  let displayInfo = {
    status: '',
    title: '',
    redirectTo: '',
    description: '',
    buttonContent: '',
  };

  switch (applicationStatus) {
    case 'AwaitingDocuments':
      displayInfo = {
        status: 'AwaitingDocuments',
        title: 'Your application is almost done!',
        description:
          'Please upload the necessary documents to complete the process',
        buttonContent: 'Upload Documents',
        redirectTo: documentsLink,
      };
      break;

    case 'Denied':
      displayInfo = {
        status: 'Denied',
        title: "We're sorry – your application has been denied.",
        description:
          'Please contact us to determine the reason your application was denied.',
        buttonContent: 'Contact Flexbase Support',
        redirectTo: 'https://support.flexbase.app/',
      };
      break;
    case 'Incomplete': // TEMP - bank app not yet submitted to unit GET /application
      /**
     * {
      "success": true,
      "status": "Incomplete",
      "reason": "Company '0fa2dcc9-24c8-487c-a347-3b7f2743ec43' has not yet submitted a Banking Application",
      "type": "businessApplication"
      }
     */
      if (productStatus.productStatus.banking.status === 'pending') {
        // unit application is Incomplete since not submitted, but product status will flag as pending
        displayInfo = {
          status: 'Pending',
          title: 'Your application is being processed.',
          description:
            'Your application is pending review. Please check back in 24 hours for an update.',
          buttonContent: 'Go Back',
          redirectTo: '/',
        };
      } else {
        displayInfo = {
          status: 'Incomplete',
          title: 'Your application is incomplete.',
          description:
            'Your application is almost complete! Jump back in and complete now.',
          buttonContent: 'Continue Application',
          redirectTo: '/application',
        };
      }
      break;
    case 'Unqualified':
      displayInfo = {
        status: 'Unqualified',
        title: "We're sorry – You currently don't qualify for banking!",
        description: 'Please contact us for more information.',
        buttonContent: 'Contact Flexbase Support',
        redirectTo: 'https://support.flexbase.app/',
      };
      break;
    default:
      displayInfo = {
        status: 'Pending',
        title: 'Your application is being processed.',
        description:
          'Your application is pending review. Please check back in 24 hours for an update.',
        buttonContent: 'Go Back',
        redirectTo: '/',
      };
      break;
  }

  if (errorMessage) {
    return (
      <div className={classes.emptyState}>
        <Image
          width={200}
          src={WatchBAnking}
          alt="Display info error"
          className="image-container"
        />
        <Text ff="redaction" size={36} align="center" mt={30}>
          Sorry about that...
        </Text>
        <p>We are unable to retrieve the account information at the moment.</p>
      </div>
    );
  }

  if (loading) {
    return <SkeletonLoading />;
  }

  return (
    <div>
      {applicationStatus === 'Approved' ? (
        <div>
          <Grid>
            <Grid.Col sm={12} md={6} lg={4}>
              <div className={classes.containerWidgets}>
                <AccountSummaryCard></AccountSummaryCard>
              </div>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <div className={classes.containerWidgets}>
                <RecentTransactionsWidget />
              </div>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={4}>
              <div className={classes.containerWidgets}>
                <MakePaymentWidget />
              </div>
            </Grid.Col>
          </Grid>
          <div
            className={classes.card}
            style={{ marginTop: '20px' }}
            data-testid="account-info"
          >
            <Grid gutter={'xs'} align="center" mb={24}>
              <Grid.Col mt={useMobileView ? '1rem' : 0} span={2}>
                <TextInput
                  w={useMobileView ? '100%' : 368}
                  placeholder="Search"
                  onChange={inputHandler}
                  icon={<SearchIcon width={20} height={20} />}
                />
              </Grid.Col>
              <Grid.Col span={'auto'}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div>
                    <Button
                      leftIcon={<PlusSignIcon width={20} />}
                      variant="light"
                      onClick={() => {
                        setAddModal(true);
                      }}
                      disabled={!user.roles.includes('ADMIN')}
                    >
                      Open Flex account
                    </Button>
                    <NewAccountModal
                      addModal={addModal}
                      setAddModal={setAddModal}
                    ></NewAccountModal>
                  </div>
                </div>
              </Grid.Col>
            </Grid>

            <FlexbaseTable
              conditionalRowStyles={conditionalRowStyles}
              onRowClicked={(row: DepositAccountRow) => openModalDetails(row)}
              striped={false}
              columns={useMobileView ? columnsSm : columns}
              data={sortDepositAccounts}
              isFetchingData={loading}
            />
          </div>
        </div>
      ) : (
        <div className={classes.card} style={{ paddingTop: 24 }}>
          <Title data-testid="app-state-title" size="h3">
            {displayInfo.title}
          </Title>
          <p data-testid="app-state-description">{displayInfo.description}</p>
          <Button
            component="a"
            onClick={() => {
              if (displayInfo.redirectTo.includes('https')) {
                window.open(displayInfo.redirectTo);
              } else {
                if (displayInfo.status === 'Incomplete') {
                  setShowRoutesFor('application');
                } else {
                  navigate(displayInfo.redirectTo);
                }
              }
            }}
            loaderProps={{ color: theme.fn.primaryColor() }}
            target={applicationStatus === 'Denied' ? '_blank' : '_self'}
          >
            {displayInfo.buttonContent}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BankingAccount;
