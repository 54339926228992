import { Avatar, Button, Menu, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { tableWithActionButtonsStyle } from 'components/styles';
import FlexbaseTable from 'components/table/flexbase-table';
import flexbaseClient from 'services/flexbase-client';
import { PlusSignIcon } from 'assets/svg';
import { customMantineStyles, useStyles } from './customers.styles';
import { getInitialsOfNames } from 'utilities/extensions/object';
import TeamMembersHeader from 'areas/team-members/team-members-header/team-members-header';
import { ReactComponent as RowAction } from 'assets/svg/vertical-action-buttons.svg';
import { MdOutlinePersonRemoveAlt1 } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';

interface ICustomerInfo {
  id?: string;
  name?: string;
  address?: string;
  tenantId?: string;
  city?: string;
  companyId?: string;
  country?: string;
  state?: string;
  postalCode?: string;
}

const Customers = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [allCustomers, setAllCustomers] = useState<ICustomerInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');

  const getCostumers = async () => {
    try {
      const response = await flexbaseClient.getPatrons();
      if (response.length > 0) {
        setAllCustomers(
          response.map((patron: any) => {
            return {
              ...patron,
            };
          }),
        );
      }
    } catch (error) {
      console.error(`Error when trying to obtain patrons' information`, error);
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      name: 'Customer Name',
      cell: (row: { initials: string; name: string }) => (
        <div className={classes.who}>
          <Avatar radius="md" className={classes.whoIcon}>
            {row.initials}
          </Avatar>
          {row.name}
        </div>
      ),
      selector: (row: { name: string }) => row.name,
      sortable: true,
      grow: 2,
    },
    {
      grow: 1,
      right: true,
      cell: (row: { id: string; name: string }) => (
        <Menu
          position="bottom"
          styles={customMantineStyles(theme.fn.primaryColor())}
        >
          <Menu.Target>
            <RowAction
              style={{
                fill: '#979797',
                cursor: 'pointer',
                marginRight: 25,
              }}
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              styles={{ fontSize: '14px' }}
              icon={<MdOutlinePersonRemoveAlt1 />}
            >
              Remove Customer
            </Menu.Item>
            <Menu.Item styles={{ fontSize: '14px' }} icon={<AiOutlineEdit />}>
              Edit Customer
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
      compact: true,
    },
  ];

  const tableData = allCustomers.map((user) => {
    return {
      id: user.id,
      name: user.name,
      initials: getInitialsOfNames(user.name || ''),
    };
  });

  const setFilterColumn = (column: string) => {
    return (column && column.toLowerCase()).startsWith(
      filterText.toLowerCase(),
    );
  };

  const filteredItems = tableData.filter((item) => {
    return setFilterColumn(item.name || '');
  });

  useEffect(() => {
    getCostumers();
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 28,
        }}
      >
        <Button className={classes.button} leftIcon={<PlusSignIcon />}>
          Add contact
        </Button>
      </div>
      <div className={classes.widgetContainer}>
        <TeamMembersHeader
          onFilter={(e) => setFilterText(e.target.value)}
          filterText={filterText}
        />
        <FlexbaseTable
          customStyles={tableWithActionButtonsStyle(theme)}
          columns={columns}
          data={filteredItems}
          isFetchingData={loading}
        />
      </div>
    </div>
  );
};

export default Customers;
