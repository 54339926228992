import { Group, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import IAddress from 'states/user/address';
import { validateRequired } from 'utilities/validators/validate-required';
import { validateState } from 'utilities/validators/validate-state';
import { useState } from 'react';
import { US_STATES } from 'states/business/constants';
import FormBase from './form-base';
import { formatZip } from 'utilities/formatters/format-address';
import validatePostalCode from 'utilities/validators/validate-postal-code';
import GooglePlacesSuggest from 'components/input/google-places-suggest-input';

type Props = {
  onClose: () => void;
  onSubmit: (address: IAddress) => void;
  currentAddress?: IAddress;
  classNames?: Record<string, string>;
  cancelText?: string;
  submitText?: string;
  loading?: boolean;
};

const AddressForm = ({
  onClose,
  onSubmit,
  currentAddress,
  classNames,
  submitText,
  cancelText,
  loading,
}: Props) => {
  const [searchAddress, setSearchAddress] = useState(
    currentAddress?.line1 || '',
  );
  const form = useForm({
    initialValues: {
      line1: currentAddress?.line1 || '',
      line2: currentAddress?.line2 || '',
      city: currentAddress?.city || '',
      state: currentAddress?.state || '',
      postalCode: currentAddress?.postalCode || '',
      country: currentAddress?.country || 'US',
    },
    validate: {
      line1: (value) =>
        validateRequired(value) ? null : 'Address is required',
      state: (value) => (validateState(value) ? null : 'State is required'),
      postalCode: (value) =>
        validatePostalCode(value) ? null : 'Zip is required',
      city: (value) => (validateRequired(value) ? null : 'City is required'),
    },
  });

  const setStreet = (street: string) => {
    setSearchAddress(street);
  };

  const setZip = (zip: string) => {
    form.setFieldValue('postalCode', formatZip(zip));
  };

  const selectAddress = (item: {
    address: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
  }) => {
    form.setFieldValue('line1', item.address.trim());
    form.setFieldValue('city', item.city);
    form.setFieldValue('state', item.state);
    form.setFieldValue('postalCode', item.postalCode);
  };

  const onUpdateClick = () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      onSubmit(form.values);
    }
  };

  return (
    <FormBase
      onSubmit={onUpdateClick}
      onCancel={onClose}
      {...{ submitText, cancelText, loading }}
    >
      <GooglePlacesSuggest
        label="Address"
        value={searchAddress}
        onChange={(value) => setStreet(value)}
        onItemSubmit={selectAddress}
        placeholder="Address"
        id="input-search-address"
      />
      <TextInput
        style={{ marginTop: '10px' }}
        data-testid="addr-line-2-input"
        placeholder="Apt, suite, etc. (Optional)"
        {...form.getInputProps('line2')}
      />
      <TextInput
        style={{ marginTop: '10px' }}
        data-testid="addr-city-input"
        placeholder="City"
        required
        {...form.getInputProps('city')}
      />
      <Group grow style={{ marginTop: '10px' }}>
        <Select
          data-testid="addr-state-select"
          placeholder="State"
          data={US_STATES}
          searchable
          maxDropdownHeight={400}
          nothingFound="No data"
          filter={(value, item) => {
            const lowerCaseValue = value.toLowerCase();
            return (
              item.label?.toLowerCase().includes(lowerCaseValue) ||
              item.value.toLowerCase().includes(lowerCaseValue)
            );
          }}
          {...form.getInputProps('state')}
        />
        <TextInput
          data-testid="address-zip-input"
          placeholder="Zip Code"
          pattern={'[0-9]*'}
          required
          {...form.getInputProps('postalCode')}
          onChange={(e) => setZip(e.target.value)}
        />
      </Group>
    </FormBase>
  );
};

export default AddressForm;
