import { useEffect, useState } from 'react';
import { PDFDownloadLink, PDFViewer, Text } from '@react-pdf/renderer';
import { PDFStyles, stylesPDF } from '../styles-pdf';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useRecoilValue } from 'recoil';
import { customDate } from 'utilities/formatters/format-date-input';
import { useParams } from 'react-router-dom';
import { chunk } from 'underscore';
import { Loader, useMantineTheme } from '@mantine/core';
import { CompanySelector } from 'states/application/product-onboarding';
import { DateTime } from 'luxon';
import StatementDoc from './statement-doc';
import { WhiteArrowPDF } from 'assets/svg';

interface Invoice {
  date: string;
  merchant: string;
  who: string;
  amount: number;
}

interface TransactionSummary {
  totalCharges: number;
  totalRefunds: number;
  paymentsCreditBalance: number;
  totalFees: number;
  totalInterest: number;
}
export interface StatementState {
  fromDate: string;
  toDate: string;
  transactionsPerPage: Invoice[][];
  transactionsSum: TransactionSummary;
  total: number;
  totalCharges?: number;
  totalRefunds?: number;
  totalFees?: number;
  totalInterest?: number;
  paymentsCreditBalance?: number;
}

const PDFMonthlyTransactionReport = () => {
  const theme = useMantineTheme();
  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });
  const { classes } = PDFStyles();
  const { after = '', before = '' } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [isLoadingPDF, setIsloadingPDF] = useState(true);
  const [statement, setStatement] = useState<StatementState | any>();
  const [dates, setDates] = useState({
    amountDue: '',
    generatedAt: '',
    previousBalance: '',
    statementStart: '',
    statementEnd: '',
    amountDueMounthAndDay: '',
    firstFortnight: '',
    firstFortnightDue: '',
    secondFortnight: '',
    secondFortnightDue: '',
    calendarMonthAndYear: '',
  });
  const company = useRecoilValue(CompanySelector);

  const renderCalendarDays = () => {
    const currentDate = DateTime.fromISO(after).plus({ months: 2 });

    const daysInMonth = currentDate.daysInMonth;
    const firstDayOfWeek = currentDate.set({ day: 1 }).weekday;

    const daysArray = [];

    // Fill the array with empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfWeek; i++) {
      daysArray.push(<Text key={`empty-${i}`} style={styles.dayCell}></Text>);
    }

    // Fill the array with the days of the month
    for (let day = 1; day <= daysInMonth!; day++) {
      daysArray.push(
        <Text
          key={`day-${day}`}
          style={{
            ...styles.dayCell,
            backgroundColor: day < 16 ? '#5F5F5F' : '#D9D9D9',
            color: day < 16 ? '#ffffff' : '#000000',
          }}
        >
          {day}
        </Text>,
      );
    }

    return daysArray;
  };

  const getTransactionStatement = async (companyId: string) => {
    try {
      setIsloading(true);
      const result = await flexbaseOnboardingClient.getCompanyTransactions(
        companyId,
        {
          after,
          before,
          inclExpired: 'true',
          inclReversed: 'true',
        },
      );

      if (result?.fromDate && result.toDate) {
        setDates({
          ...dates,
          statementStart: customDate(
            result.fromDate.replaceAll('-', '/'),
            'numeric',
            'long',
            'numeric',
          ),
          statementEnd: customDate(
            result.toDate.replaceAll('-', '/'),
            'numeric',
            'long',
            'numeric',
          ),
          calendarMonthAndYear: DateTime.fromISO(after)
            .plus({ months: 2 })
            .toFormat('MMMM yyyy'),
          firstFortnight: `${DateTime.fromISO(after).toFormat('LLLL')} 1 to 15`,
          firstFortnightDue: `${DateTime.fromISO(after)
            .plus({ months: 2 })
            .toFormat('MMMM')} 15`,
          secondFortnight: `${DateTime.fromISO(after).toFormat(
            'LLLL',
          )} 16 to ${DateTime.fromISO(after).endOf('month').toFormat('dd')}`,
          secondFortnightDue: `${
            DateTime.fromISO(after).plus({ months: 2 }).toFormat('MMMM') +
            DateTime.fromISO(after)
              .plus({ months: 2 })
              .endOf('month')
              .toFormat('dd')
          }`,
        });
      }

      const transactions =
        result?.transactions.filter(
          (transaction) => transaction.status !== 'declined',
        ) ?? [];

      const transactionsSum = transactions.reduce(
        (acum: any, transaction: any) => {
          try {
            // total charges
            if (
              ['card', 'bnpl'].includes(transaction.type) &&
              transaction.status === 'settled' &&
              parseFloat(transaction.amount)
            ) {
              acum.totalCharges += parseFloat(transaction.amount);
            }

            // Credits/Refunds
            if (
              ['card', 'bnpl'].includes(transaction.type) &&
              transaction.status === 'settled' &&
              parseFloat(transaction.amount) < 0
            ) {
              acum.totalRefunds += parseFloat(transaction.amount);
            }

            // payments made by the company
            if (
              ['manual', 'autopay'].includes(transaction.type) &&
              transaction.status === 'succeeded' &&
              parseFloat(transaction.amount) < 0
            ) {
              acum.paymentsCreditBalance += parseFloat(transaction.amount);
            }

            // fee
            if (
              transaction.type !== 'autopay' &&
              transaction.type !== 'manual' &&
              transaction.type !== 'interest' &&
              transaction.status === 'succeeded' &&
              transaction.amount
            ) {
              acum.totalFees += parseFloat(transaction.amount);
            }

            // interest
            if (
              ['interest'].includes(transaction.type) &&
              transaction.status === 'succeeded' &&
              transaction.amount
            ) {
              acum.totalInterest += parseFloat(transaction.amount);
            }
          } catch (error) {
            console.error('Unable to get the float value', error);
          }
          return acum;
        },
        {
          totalCharges: 0,
          totalRefunds: 0,
          paymentsCreditBalance: 0,
          totalFees: 0,
          totalInterest: 0,
        },
      );

      const transactionsFormat = transactions.map((invoice: any) => {
        return {
          date: invoice.date,
          merchant: invoice.transaction,
          who: invoice.who,
          amount: invoice.amount,
          last4: invoice.sourceLast4 || '',
          type: invoice.type,
        };
      });

      const total =
        transactionsSum.totalCharges +
        transactionsSum.totalRefunds +
        transactionsSum.totalFees +
        transactionsSum.totalInterest;

      const transactionsPerPage = chunk(transactionsFormat, 12);
      setStatement({
        ...result,
        transactionsPerPage,
        ...transactionsSum,
        total,
      });
    } catch (error) {
      console.error('Unable to get transaction statement data', error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getTransactionStatement(company.id);
  }, [company.id]);

  setTimeout(() => {
    setIsloadingPDF(false);
  }, 2000);

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: '92.5vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader color={theme.fn.primaryColor()} />
      </div>
    );
  }

  return (
    <div>
      {!isLoadingPDF && (
        <PDFDownloadLink
          document={
            <StatementDoc
              {...{
                statement,
                dates,
                renderCalendarDays,
                after,
                company,
                theme,
              }}
            />
          }
          fileName={`Flex Credit Card Monthly Statement ${DateTime.fromISO(
            after,
          ).toFormat('MM-yy')}`}
        >
          {() => (
            <div className={classes.downloadContainer}>
              <WhiteArrowPDF width={15} />
            </div>
          )}
        </PDFDownloadLink>
      )}

      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <StatementDoc
          {...{ statement, dates, renderCalendarDays, after, company, theme }}
        />
      </PDFViewer>
    </div>
  );
};

export default PDFMonthlyTransactionReport;
