import { useState } from 'react';
import { Button, Grid, useMantineTheme } from '@mantine/core';
import { MdAttachMoney } from 'react-icons/md';

import { useStyles } from './styles';
import { AddUsers, Binoculars } from 'assets/svg';
import { FlexbaseOnboardingClient } from 'services/flexbase/flexbase-onboarding-client';
import { useRouteSectionContext } from '../../../components/routes/route-context';
import { OptedProduct } from 'states/application/product-onboarding';

interface NoAccountProps {
  client: FlexbaseOnboardingClient;
  optedProducts: OptedProduct[];
}

const NoAccountPage = (props: NoAccountProps) => {
  const { client, optedProducts } = props;

  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [loading] = useState(false);
  const { setShowRoutesFor } = useRouteSectionContext();

  return (
    <div className={classes.noCreditAccount}>
      <div className="black-banner">A business card with 0% for 60 days</div>

      <h2>
        More than another business card. <br /> A finance super app for growth.
      </h2>
      <span className="info">
        Finally! A card with 0% interest for 60 days for companies like yours*
      </span>

      <div>
        <Button
          radius="md"
          onClick={async () => {
            // add credit to opted products
            await client.updateCompany({
              optedProducts: optedProducts.concat(['CREDIT']),
            });
            setShowRoutesFor('application');
          }}
          {...{ loading }}
          color={theme.fn.primaryColor()}
          styles={{
            label: { fontSize: '14px', fontWeight: 400 },
            root: { margin: '20px 0px 280px 0px', height: '40px' },
          }}
        >
          Apply now
        </Button>
      </div>

      <Grid>
        <Grid.Col span={4}>
          <div className="card">
            <div>
              <div className="icon-container">
                <Binoculars width={25} />
              </div>
            </div>
            <p>0% interest for 60 days</p>
            Keep access to more working capital for longer to grow your business
          </div>
        </Grid.Col>

        <Grid.Col span={4}>
          <div className="card">
            <div>
              <div className="icon-container">
                <AddUsers width={25} />
              </div>
            </div>
            <p>Track Receipts for Transactions</p>
            Track reciepts with ease across employees and get reimbursed faster.
          </div>
        </Grid.Col>

        <Grid.Col span={4}>
          <div className="card">
            <div>
              <div className="icon-container">
                <MdAttachMoney size={25} />
              </div>
            </div>
            <p>Employee spend controls</p>
            Ensure that your employees are spending money on approved activities
            within a particular budget
          </div>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default NoAccountPage;
