import { atom, selector } from 'recoil';
import { DepositAccount } from 'services/flexbase/banking.model';
import { DepositAccountRow } from '../accounts/banking-account';

export const DepositAccountsState = atom<DepositAccount[]>({
  key: 'deposit_accounts_state',
  default: [],
});

export const DepositAccountsSelectedState = atom<string>({
  key: 'deposit_account_selected_state',
  default: '',
});

export const depositAccountsSelectedState = selector({
  key: 'deposit_accounts_list_selected_state',
  get: ({ get }) => {
    const id = get(DepositAccountsSelectedState);
    const listHighlighted = get(DepositAccountsState).map((account) => {
      return {
        ...account,
        toggleSelected: account.id === id ? true : false,
      } as DepositAccountRow;
    });
    return listHighlighted;
  },
});
