import {
  Button,
  createStyles,
  LoadingOverlay,
  Switch,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';
import React, { useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { useRecoilValue } from 'recoil';
import { BnplState } from 'states/bnpl/bnpl.state';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { DateTime } from 'luxon';

interface Props {
  closeModal: () => void;
  merchantCallbackCancelUrl?: string;
  onContinue: () => void;
  onBack: () => void;
  setError: (error: string) => void;
  lastFourBankAccountDigits: string;
}

const useStyles = createStyles((theme) => ({
  bnplAutopay: {
    '@media(max-width: 767px)': {
      width: '85%',
      minHeight: '87.5vh',
      justifyContent: 'space-between',
    },
    width: '600px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    margin: 'auto',
  },
  mainText: {
    flexBasis: '75%',
  },
  hereWeGo: {
    '@media(max-width: 767px)': {
      width: '97.5%',
      marginTop: '10%',
    },
    marginLeft: '5px',
    marginTop: '10%',
    fontSize: '21px',
    fontWeight: 700,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '130px',
    justifyContent: 'space-between',
  },
  title: {
    '@media(max-width: 767px)': {
      fontSize: '24px',
      margin: '7.5% 0 7.5% 5px',
    },
    margin: '1.75% 0 1.75% 5px',
    fontWeight: 300,
    fontSize: '38px',
    fontFamily: 'PP Neue Montreal',
  },
  subtitle: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
    },
    marginLeft: '5px',
    fontSize: '24px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '32px',
    fontFamily: 'PP Neue Montreal',
  },
  description: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
    },
    margin: '4% 0 1.75% 5px',
    fontSize: '24px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  autoPayment: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '20px !important',
      br: {
        display: 'none',
      },
    },
    fontSize: '24px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '32px',
  },
  last4digits: {
    fontSize: '26px',
    fontWeight: 700,
    margin: '1.75% 0 1.75% 5px',
  },
  boldBlack: {
    '@media(max-width: 767px)': {
      fontSize: '22px',
    },
    fontSize: '26px',
    fontWeight: 700,
  },
  orangeBox: {
    width: '100%',
    border: '1px solid #FF6150',
    borderRadius: '10px',
    height: '114px',
    marginTop: '35px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
  approveButton: {
    height: '55px',
    backgroundImage: `linear-gradient(to bottom right, ${theme.fn.primaryColor()}, #FF8779)`,
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: '22px',
    marginTop: '7%',
    '@media(max-width: 767px)': {
      fontSize: '18px',
    },
  },
  disabledApproveButton: {
    backgroundColor: '#FFBDB5',
    height: '55px',
    color: '#FFFFFF',
    borderRadius: '10px',
    fontSize: '22px',
    marginTop: '7%',
    '@media(max-width: 767px)': {
      fontSize: '18px',
    },
  },
  cancelButton: {
    height: '55px',
    color: '#8A8A8F',
    fontSize: '22px',
    fontWeight: 700,
    margin: '2% auto 2% auto',
    cursor: 'pointer',
  },
  mainLoading: {
    minHeight: '92.5vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingMsg: {
    color: 'black',
    fontSize: '17px',
    lineHeight: '22px',
    marginTop: '2.5vh',
    flexGrow: 0,
  },
  stupidMantine: {
    '& input[type=checkbox]': { cursor: 'pointer' },
  },
}));

const BnplAutopay = ({
  closeModal,
  merchantCallbackCancelUrl,
  onContinue,
  onBack,
  setError,
  lastFourBankAccountDigits,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [autopay, setAutopay] = useState(false);
  const [autopayWorking, setAutopayWorking] = useState(true);
  const [switchText, setSwitchText] = useState('OFF');
  const { amount } = useRecoilValue(BnplState); // TODO: It doesn't make sense to use the tx amount here. Likely this should be minimumDue
  const currentDate = DateTime.now();

  const cancel = (): void => {
    window.location.replace(merchantCallbackCancelUrl!);
    closeModal();
  };

  const getAutopayStatus = async () => {
    try {
      const company = await flexbaseOnboardingClient.getUserCompany();
      setAutopay(company.autopay);
      setSwitchText('ON');
    } catch (error) {
      setError(
        'Something went wrong. Please try again later or select another payment method at the merchant.',
      );
    } finally {
      setAutopayWorking(false);
    }
  };

  useEffect(() => {
    getAutopayStatus();
  }, []);

  const updateAutopay = async (enabled: boolean): Promise<void> => {
    setAutopayWorking(true);
    try {
      await flexbaseOnboardingClient.updateCompany({
        autopay: enabled,
      });
    } catch (err) {
      setError!(
        'Unable to set auto pay preferences. Please contact Flexbase support.',
      );
    } finally {
      setAutopayWorking(false);
    }
  };

  const toggleAutopay = async (e: React.FormEvent<HTMLInputElement>) => {
    const enabled = e.currentTarget.checked;
    await updateAutopay(enabled);
    setAutopay(enabled);
    if (enabled) {
      setSwitchText('ON');
    } else {
      setSwitchText('OFF');
    }
  };

  const getAutopayDate = (): string => {
    if (currentDate.day < 15) {
      return currentDate.set({ day: 15 }).toFormat('MM/dd/yyyy');
    } else {
      return currentDate.endOf('month').toFormat('MM/dd/yyyy');
    }
  };

  return (
    <div>
      <FlexbaseHeader onCloseButtonClick={cancel} />
      <LoadingOverlay
        visible={autopayWorking}
        loaderProps={{ color: theme.fn.primaryColor() }}
      />
      <div className={classes.bnplAutopay}>
        <div className={classes.mainText}>
          <Text
            size="lg"
            weight="bold"
            align="left"
            className={classes.hereWeGo}
            color={theme.fn.primaryColor()}
          >
            Here we go!
          </Text>
          <div className={classes.titleContainer}>
            <Title order={1} align="left" className={classes.title}>
              Autopay
            </Title>
            <div className={classes.switchContainer}>
              <Text align="left" className={classes.subtitle}>
                {switchText}
              </Text>
              <Switch
                className={classes.stupidMantine}
                checked={autopay}
                onChange={(e) => toggleAutopay(e)}
                size="lg"
                style={{ width: '67px' }}
              />
            </div>
          </div>
          <Text align="left" className={classes.subtitle}>
            Flexbase will automatically debit your bank account:
          </Text>
          <Text align="left" className={classes.last4digits}>
            ending in {lastFourBankAccountDigits}
          </Text>
          <Text align="left" className={classes.subtitle}>
            on the{' '}
            <span style={{ color: '#FF6150' }}>15th and the last day</span> of
            the month after the transaction.
          </Text>
          <Text align="left" className={classes.description}>
            This helps avoid any late fees and keeps your account current so
            that we can give you even more credit.
          </Text>
          <div className={classes.orangeBox}>
            <Text align="center" className={classes.autoPayment}>
              {autopay ? 'Autopayment starting ' : 'Manual payment due on '}
              <span style={{ fontWeight: 700 }}>{getAutopayDate()}</span>
            </Text>
            <Text align="center" className={classes.boldBlack}>
              {formatCurrency(amount)}
            </Text>
          </div>
        </div>
        <Text align="left" className={classes.description}>
          And then on <span style={{ fontWeight: 'bold' }}>the 15th</span> and{' '}
          <span style={{ fontWeight: 'bold' }}>the last day of the month</span>{' '}
          until paid in full.
        </Text>
        <div className={classes.buttons}>
          <Button
            fullWidth={true}
            className={classes.approveButton}
            onClick={onContinue}
          >
            Confirm {autopay ? 'Autopay' : 'Manual Payment'}
          </Button>
          <Text className={classes.cancelButton} onClick={onBack}>
            Previous step
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BnplAutopay;
