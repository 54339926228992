import { createStyles, Image, Text } from '@mantine/core';
import FlexbaseHeader from 'components/header/flex-pay/flexbase-header';

const useStyles = createStyles((theme) => ({
  mainSuccess: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  successHeader: {
    '@media(max-width: 767px)': {
      marginTop: '30px',
    },
    fontSize: '21px',
    fontWeight: 700,
    fontStyle: `normal`,
    lineHeight: `23px`,
    marginTop: '101px',
    color: theme.fn.primaryColor(),
    textAlign: 'center',
  },
  redirectMsg: {
    '@media(max-width: 767px)': {
      fontSize: '16px',
      width: '65%',
      margin: '2% 0 4% 0',
    },
    textAlign: 'center',
    alignSelf: 'center',
    fontStyle: `normal`,
    color: '#6D6D6D',
    fontSize: '17px',
    lineHeight: '23px',
    fontWeight: 400,
    margin: '1% 0 2% 0',
  },
  grayBox: {
    '@media(max-width: 767px)': {
      maxWidth: '90%',
      minWidth: 'inherit',
      height: '390px',
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '37.5%',
    minWidth: '600px',
    pading: '20% !important',
    height: '411px',
    background: '#FAFAFA',
    border: '1px solid rgba(111, 113, 129, 0.1)',
    borderRadius: '20px',
  },
  successImg: {
    '@media(max-width: 767px)': {
      width: '150px',
    },
    marginTop: '10px',
  },
  mobileApps: {
    margin: '0 5% 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  tipMsg: {
    '@media(max-width: 767px)': {
      fontSize: '15px',
      width: '65%',
      margin: 'auto',
    },
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '23px',
    width: '325px',
    color: 'black',
  },
  mobileLinks: {
    '@media(max-width: 767px)': {
      width: '100%',
      marginTop: '10px',
    },
    margin: ' 10% 0 0 20px',
    width: '400px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  appStoreImg: {
    '@media(max-width: 767px)': {
      width: '90%',
      height: '5%',
      margin: '25px 5px 0 5px',
    },
    width: '161.9px',
    height: '47.18px',
    background: 'black',
    borderRadius: '6px',
    margin: '0 10px 0 10px',
  },
}));

const BnplSuccess = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.mainSuccess}>
      <FlexbaseHeader />
      <Text className={classes.successHeader}>Transaction was approved</Text>
      <Text className={classes.redirectMsg}>
        You will be redirected to the store in a few seconds.
      </Text>
      <div className={classes.grayBox}>
        <Image
          src="/svg/payments.svg"
          alt="payments"
          className={classes.successImg}
        />
        <div className={classes.mobileApps}>
          <Text className={classes.tipMsg}>
            In the Flexbase app you can keep up to date with your credit limit,
            register your purchase invoices and report your problems.
          </Text>
          <div className={classes.mobileLinks}>
            <a
              href="https://apps.apple.com/us/app/flexbase/id1591985571"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/svg/appStore.svg"
                alt="app-store"
                className={classes.appStoreImg}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.flexbase.cards&hl=en_US&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/svg/playStore.svg"
                alt="play-store"
                className={classes.appStoreImg}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BnplSuccess;
