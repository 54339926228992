import {
  Alert,
  Box,
  Button,
  Checkbox,
  PasswordInput,
  rem,
  Text,
  TextInput,
} from '@mantine/core';
import { TermsOfServiceCheckboxPrompt } from '../../../../../components/terms/prompt';
import { useStartScreenStyles } from '../styles';
import { useForm } from '@mantine/form';
import PasswordStrengthPopover from '../../../../login/password-strength-popover';
import flexbaseClient, {
  flexbaseOnboardingClient,
} from '../../../../../services/flexbase-client';
import {
  EmailValidator,
  formatPhoneForApi,
  formatUSPhoneNumber,
  NewPasswordValidator,
  PhoneNumberValidator,
  RequiredFieldValidator,
} from '@flexbase-eng/web-components';
import { useRecoilValue } from 'recoil';
import {
  ProductState,
  PromoCodeState,
} from '../../../../../states/application/product-onboarding';
import { useAuthToken } from '../../../../../states/auth/auth-token';
import { useRouteSectionContext } from '../../../../../components/routes/route-context';
import { ReactNode, useState } from 'react';
import AuthLoad from '../../../../../components/login/auth-loader';
import { ErrorCircle, RedAlertIcon } from '../../../../../assets/svg';
import { useQuery } from '../../../../../utilities/url/query-param';

export const CreditBankingForm = () => {
  const { classes } = useStartScreenStyles();
  const product = useRecoilValue(ProductState);
  const { setUserData } = useAuthToken();
  const { setShowRoutesFor } = useRouteSectionContext();
  const promoCode = useRecoilValue(PromoCodeState);
  const queryParams = useQuery();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: queryParams.get('email') || '',
      cellphone: '',
      password: '',
      confirmPassword: '',
      is18: false,
      termsOfServiceSigned: false,
    },
    validate: {
      firstName: RequiredFieldValidator(),
      lastName: RequiredFieldValidator(),
      email: EmailValidator(),
      cellphone: PhoneNumberValidator(),
      password: NewPasswordValidator(),
      confirmPassword: (val, formValues) =>
        val && val === formValues.password ? null : 'Passwords must match',
      is18: RequiredFieldValidator(),
      termsOfServiceSigned: RequiredFieldValidator(),
    },
  });

  const onStartApplicationClick = async () => {
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      setLoading(true);
      const formValues = form.values;
      try {
        const registrationResult = await flexbaseClient.register({
          email: formValues.email,
          password: formValues.password,
          cellPhone: formatPhoneForApi(formValues.cellphone),
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          promoCode: promoCode,
        });
        setUserData({
          email: formValues.email,
          remember: true,
          token: registrationResult.token.token,
        });
        await flexbaseOnboardingClient.updateUser({
          termsOfServiceSigned: formValues.termsOfServiceSigned,
        });
        await flexbaseOnboardingClient.createCompany({
          companyName: 'My Company',
          optedProducts:
            product === 'BANKING' ? ['BANKING'] : ['CREDIT', 'BANKING'],
        });
        setShowRoutesFor('application');
      } catch (e) {
        if (
          e?.text &&
          typeof e.text === 'string' &&
          e.text.includes('same email address')
        ) {
          form.setFieldError(
            'email',
            'You already have an account with us. Please login.',
          );
        } else {
          setErrorMessage('An error occurred while trying to save your data.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: 524,
        marginBottom: theme.spacing.lg,
        [theme.fn.smallerThan(1281)]: {
          width: '100%',
          padding: `0 ${theme.spacing.lg}`,
          marginBottom: theme.spacing.lg,
        },
        [theme.fn.smallerThan('sm')]: {
          width: '100%',
          padding: theme.spacing.md,
        },
        position: 'relative',
      })}
    >
      {loading && <AuthLoad />}
      <Text className={classes.creditBankingTitle}>
        {product === 'BANKING' ? 'Flex Business Banking' : 'Flex Credit Card'}
      </Text>
      {product === 'BANKING' ? (
        <Text className={classes.creditBankingSubtitle} mt="xs">
          Zero Fees. Free ach & wires. 1% Cashback on your debit card.*
        </Text>
      ) : (
        <Text className={classes.creditBankingSubtitle} mt="xs">
          <span className={classes.creditBankingSubtitleEmphasis}>
            0% for 60 days
          </span>{' '}
          on every single purchase*. Plus a{' '}
          <span className={classes.creditBankingSubtitleEmphasis}>
            completely free
          </span>{' '}
          business checking account with 1% cashback on your debit card plus
          free ACH & wire transfers**
        </Text>
      )}

      <Box
        mt="sm"
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1rem',
          [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          },
        })}
      >
        <TextInput
          label="Legal first name"
          placeholder="First name"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
            // error: classes.darkBackgroundInputError,
          }}
          {...form.getInputProps('firstName')}
          id="input-first-name"
          error={getErrorNodeOrUndefined(form.errors.firstName)}
        />
        <TextInput
          label="Legal last name"
          placeholder="Last name"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          {...form.getInputProps('lastName')}
          id="input-last-name"
          error={getErrorNodeOrUndefined(form.errors.lastName)}
        />
        <TextInput
          label="Business email"
          placeholder="Enter business email"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          type="email"
          {...form.getInputProps('email')}
          id="input-email"
          error={getErrorNodeOrUndefined(form.errors.email)}
        />
        <TextInput
          label="Cell phone"
          placeholder="Enter phone number"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          type="tel"
          {...form.getInputProps('cellphone')}
          id="input-phone-number"
          data-testid="input-phone-number"
          onChange={(e) =>
            form.setFieldValue('cellphone', formatUSPhoneNumber(e.target.value))
          }
          error={getErrorNodeOrUndefined(form.errors.cellphone)}
        />
        <PasswordStrengthPopover inputValue={form.values.password}>
          <PasswordInput
            label="Create password"
            placeholder="Password"
            classNames={{
              root: classes.nameInputs,
              input: classes.input,
              label: classes.label,
            }}
            {...form.getInputProps('password')}
            id="input-password"
            data-testid="input-password"
            error={getErrorNodeOrUndefined(form.errors.password)}
          />
        </PasswordStrengthPopover>
        <PasswordInput
          label="Confirm password"
          placeholder="Password"
          classNames={{
            root: classes.nameInputs,
            input: classes.input,
            label: classes.label,
          }}
          {...form.getInputProps('confirmPassword')}
          id="input-confirm-password"
          data-testid="input-confirm-password"
          error={getErrorNodeOrUndefined(form.errors.confirmPassword)}
        />
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <Checkbox
            id="checkbox-legal-age"
            label={<Text size="xs">I am at least 18 years old</Text>}
            classNames={{ label: classes.label }}
            {...form.getInputProps('is18', { type: 'checkbox' })}
            color="something.2"
            error={getErrorNodeOrUndefined(form.errors.is18)}
          />
          <TermsOfServiceCheckboxPrompt
            type="flexbase"
            size="sm"
            mt={15}
            className={classes.checkboxBody}
            classNames={{
              body: classes.checkboxBody,
              label: classes.label,
            }}
            color="something.2"
            {...form.getInputProps('termsOfServiceSigned', {
              type: 'checkbox',
            })}
            error={getErrorNodeOrUndefined(form.errors.termsOfServiceSigned)}
          />
        </div>
        {errorMessage && (
          <Alert icon={<RedAlertIcon />} className={classes.alert} my="lg">
            {errorMessage}
          </Alert>
        )}
        <Button
          variant="light"
          style={{ gridColumnStart: '1', gridColumnEnd: '3' }}
          onClick={() => onStartApplicationClick()}
          id="button-register-submit"
        >
          Start Application
        </Button>
      </Box>
    </Box>
  );
};

const InputError = ({ error }: { error: ReactNode }) => {
  return (
    <Box
      sx={(theme) => ({
        color: theme.fn.themeColor('neutral', 2),
        svg: { color: theme.fn.themeColor('critical', 2), minWidth: rem(12) },
        display: 'flex',
        alignItems: 'center',
        gap: rem(4),
      })}
    >
      <ErrorCircle />
      <span>{error}</span>
    </Box>
  );
};

function getErrorNodeOrUndefined(
  error: ReactNode | null,
): ReactNode | undefined {
  return error ? <InputError error={error} /> : undefined;
}
