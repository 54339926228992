import { FlexAccountInfo } from 'areas/cash-management/treasury-management.state';

/**
 * PlaidAccount is the shape of the response from the companyAccounts endpoint
 */
export interface PlaidAccount {
  plaidOrDeposit: 'plaid';
  accessToken?: string;
  account: string;
  accountId: string;
  accountName: string;
  accountType?: string;
  active: boolean;
  bankName?: string;
  companyId?: string;
  id: string;
  institutionId?: string;
  itemId?: string;
  last4?: string;
  logoUrl?: string;
  officialName?: string;
  ownerInfo?: OwnerInfo;
  routing?: string;
  stripeBankAccountToken?: string;
  unitProcessorToken?: string;
  unlinked: boolean;
  userId?: string;
  tenantId?: string;
}

interface OwnerInfo {
  address?: Address;
  email?: string;
  name?: string;
  phone?: string;
}

interface Address {
  city: string;
  country: string;
  line1: string;
  postalCode: string;
  state: string;
}

/**
 * DepositAccount is the shape of the response from the banking/deposits endpoint
 **/
export interface DepositAccount {
  plaidOrDeposit: 'deposit';
  accountNumber: string;
  accountType: 'checking';
  asOf: string;
  companyId: string;
  createdAt: string;
  id: string;
  isDefault: boolean;
  name: string;
  nickName: string;
  routingNumber: string;
  status: string;
  type: 'depositAccount';
  userId: string;
  version: number;
  balance: number;
  available: number;
}
/**
 * generalized account properties for both Plaid and Deposit accounts
 */
export type AccountProps = DepositAccount | PlaidAccount | FlexAccountInfo;

export const AUTHORIZATIONS = {
  achDebit: {
    contents:
      'By clicking "Transfer", I authorize Flexbase to immediately debit the amount above from my bank account. Once I authorize this payment, I understand there cannot be any changes or corrections. Transactions made after 5:00 PM EST or on a weekend or holiday will be processed the next business day. It is recommended that you print a copy of this authorization and maintain it for your records.',
  },
  default: {
    contents:
      'By clicking "Transfer", I authorize Flexbase to initiate the transaction detailed above.',
  },
};
