import { Document, Page, PDFViewer, Text, View } from '@react-pdf/renderer';
import { stylesStatementPDF } from './styles';
import { useRecoilValue } from 'recoil';
import { formatCurrency } from 'utilities/formatters/format-currency';
import StatementHeader from './header';
import { TreasuryManagementState } from '../../treasury-management.state';
import RiskPDFFooter from './footer';
import NoAccountsView from './no-accounts';
import AddressHeader from './address';
import ViewStatementDetails from './statement-details';
import { CompanySelector } from 'states/application/product-onboarding';
import HeaderPDF from 'components/pdf-viewer/header';
import { useMantineTheme } from '@mantine/core';
// import { generatePlotlySvg } from 'components/pdf/svg-to-canvas';

const PDFRiskStatementViewer = () => {
  const company = useRecoilValue(CompanySelector);
  const theme = useMantineTheme();
  const cashManagementState = useRecoilValue(TreasuryManagementState);

  const companyDetails = {
    phone: company.phone,
    companyName: company.companyName,
    address: {
      line1: company.address.line1,
      line2: company.address.line2 || '',
      city: company.address.city,
      state: company.address.state,
      postalCode: company.address.postalCode,
      country: company.address.country || '',
    },
  };

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page wrap size="A4">
          <View fixed>
            <HeaderPDF {...{ theme }} />
          </View>
          <View style={stylesStatementPDF.containerPage}>
            <AddressHeader companyDetails={companyDetails}></AddressHeader>
            <View style={{ marginTop: 20 }}>
              <Text style={stylesStatementPDF.title}>
                Capital Diversification Report
              </Text>
            </View>
            <StatementHeader
              totalCapital={formatCurrency(
                cashManagementState.totalAvailableBalance,
              )}
              uninsuredCapitalPercent={
                cashManagementState.totalAvailableBalance > 0
                  ? (
                      (cashManagementState.uninsuredCapitalBalance /
                        cashManagementState.totalAvailableBalance) *
                      100
                    )
                      .toFixed(2)
                      .toString() + '%'
                  : 'N/A'
              }
              uninsuredCapitalValue={formatCurrency(
                cashManagementState.uninsuredCapitalBalance,
              )}
            ></StatementHeader>
            {cashManagementState.totalAvailableBalance === 0 ? (
              <NoAccountsView></NoAccountsView>
            ) : (
              <>
                <ViewStatementDetails
                  banks={cashManagementState.bankSnapshots}
                ></ViewStatementDetails>
              </>
            )}
          </View>
          <RiskPDFFooter></RiskPDFFooter>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PDFRiskStatementViewer;
