import { DateTime } from 'luxon';

export const formatDateInput = (value: string) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 3) {
    return currentValue;
  }
  if (cvLength < 5) {
    return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
  }
  return `${currentValue.slice(0, 2)}/${currentValue.slice(
    2,
    4,
  )}/${currentValue.slice(4, 8)}`;
};

export const formatDateFromApi = (date: string): string => {
  if (!date) {
    return date;
  }

  const dateTime = DateTime.fromFormat(date, 'yyyy-MM-dd');
  return dateTime.toFormat('MM/dd/yyyy');
};

export const formatDateForApi = (date: string): string => {
  if (!date) {
    return date;
  }

  const dateTime = DateTime.fromFormat(date, 'MM/dd/yyyy');

  return dateTime.toFormat('yyyy-MM-dd');
};

export const removeMaskedValue = (value: string): string => {
  if (!value || value.includes('*')) {
    return '';
  }

  return value;
};

export const customDate = (
  date: string,
  formatYear: '2-digit' | 'numeric' | '',
  formatMonth: '2-digit' | 'numeric' | 'short' | 'long' | 'narrow',
  formatDay: '2-digit' | 'numeric',
): string => {
  if (!date) {
    return date;
  }

  const newDate = new Date(date);

  let optionalFields = {};

  if (formatYear) {
    optionalFields = {
      year: formatYear,
    };
  }

  const result = newDate.toLocaleDateString('en-us', {
    ...optionalFields,
    month: formatMonth,
    day: formatDay,
  });

  return result;
};

export const getDayOfDate = (date: string) => {
  if (!date) {
    return date;
  }

  const newDate = new Date(date);

  return newDate.toISOString().slice(8, 10);
};

export const getLastDayMonth = (date: string) => {
  if (!date) {
    return date;
  }

  const newDate = new Date(date);

  return new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0)
    .toISOString()
    .slice(8, 10);
};
