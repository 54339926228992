import { Button, createStyles, Text, Image, Anchor } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { useEffect, useState } from 'react';
import { DownloadIcon } from 'assets/svg';
import {
  moneyMovementAmountDetails,
  paymentMethod,
  paymentRecipientName,
} from './payment.states';
import { useRecoilValue } from 'recoil';
import { setPaymentRejectedError } from 'utilities/formatters/payment-rejected-message';
import TwoFactorVerify from 'components/auth/two-factor-auth';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { MoneyMovement } from 'services/flexbase/banking.model';
import errorPendingTransfer from 'assets/images/watch-banking.png';
import SuccessTransfer from 'assets/images/transferred-banking.png';

type Props = {
  onCloseClick: () => void;
  onMakeAnotherPaymentClick: () => void;
  error?: boolean;
  payment: MoneyMovement | null;
};

const PaymentConfirmation = ({
  onCloseClick,
  onMakeAnotherPaymentClick,
  error = false,
  payment,
}: Props) => {
  const { classes } = useStyles();
  const moneyMovementDetails = useRecoilValue(moneyMovementAmountDetails);
  const recipientName = useRecoilValue(paymentRecipientName);
  const method = useRecoilValue(paymentMethod);
  const [confirmationStatus, setConfirmationStatus] = useState<string | null>(
    null,
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [retry, setRetry] = useState(false);
  const [rejectedReason, setRejectedReason] = useState<string>();
  const [loading, setLoading] = useState(false);

  const getPaymentError = (reason?: string) => {
    if (reason) {
      return setPaymentRejectedError(reason);
    } else {
      return 'An error occurred setting up your payment info';
    }
  };

  const handleOnChange = async (code: string) => {
    setRetry(false);
    if (payment?.id && code.length === 6) {
      setLoading(true);
      setErrorMsg('');
      try {
        const confirmed = await flexbaseBankingClient.confirmPayment(
          payment?.id,
          code,
        );
        setConfirmationStatus(confirmed?.status);
        setRejectedReason(confirmed?.reason);
      } catch (error) {
        setErrorMsg('Confirmation failed. Please try again...');
        setRetry(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const resendCode = async () => {
    setErrorMsg('');
    if (payment?.id) {
      try {
        await flexbaseBankingClient.confirmPayment(payment?.id);
      } catch (error) {
        setErrorMsg(error);
      }
    }
  };

  let paymentStatus: {
    title: string;
    description: string;
    downloadWire: boolean;
    sketch: string;
  };

  switch (confirmationStatus ?? payment?.status) {
    case 'AwaitingConfirmation':
      paymentStatus = {
        title: 'Two Factor Verification',
        description:
          'To ensure the safety of your transfer, we are requesting you reauthenticate with the six digit code sent via SMS.',
        sketch: errorPendingTransfer,
        downloadWire: false,
      };
      break;

    case 'Pending':
    case 'Clearing':
    case 'PendingReview':
      paymentStatus = {
        title: `You've sent ${formatCurrency(
          moneyMovementDetails.amount,
        )} to ${recipientName}!`,
        description:
          method === 'ach'
            ? 'The funds should arrive within 5 business days.'
            : 'Wire funds should arrive within 1-2 business days.',
        sketch: errorPendingTransfer,
        downloadWire: true,
      };
      break;

    case 'Rejected':
      paymentStatus = {
        title: 'Your transaction has failed',
        description: getPaymentError(rejectedReason),
        sketch: errorPendingTransfer,
        downloadWire: false,
      };
      break;

    default:
      paymentStatus = {
        title: `You've sent ${formatCurrency(
          moneyMovementDetails.amount,
        )} to ${recipientName}!`,
        description:
          method === 'ach'
            ? 'The funds should arrive within 5 business days.'
            : 'Wire funds should arrive within 1-2 business days.',
        sketch: SuccessTransfer,
        downloadWire: true,
      };
      break;
  }

  useEffect(() => {
    if (rejectedReason) {
      getPaymentError(rejectedReason);
    }
  }, []);

  const needsTwoFactor =
    (!confirmationStatus && payment?.status === 'AwaitingConfirmation') ||
    confirmationStatus === 'AwaitingConfirmation';

  return (
    <div className={classes.successContainer}>
      <Image
        src={error ? errorPendingTransfer : paymentStatus.sketch}
        width={260}
      />
      <Text className={classes.infoText} data-testid={'message'}>
        {error
          ? `An error occurred setting up your payment info`
          : paymentStatus.title}
      </Text>
      {paymentStatus.downloadWire && !error && (
        <Anchor
          fw={400}
          size={14}
          color="primarySecondarySuccess.2"
          className={classes.downloadReceipt}
          onClick={() => window.open(`/payment/wire-receipt/${payment?.id}`)}
        >
          Download wire receipt <DownloadIcon width={15} />
        </Anchor>
      )}
      <div className={classes.flavorText}>
        {error ? 'Something went wrong.' : paymentStatus.description}
      </div>
      {needsTwoFactor ? (
        <div className={classes.TwoFactorContainer}>
          <TwoFactorVerify
            token=""
            errorMsg={errorMsg}
            retry={retry}
            handleOnChange={handleOnChange}
            resendCode={resendCode}
            loading={loading}
          />
        </div>
      ) : (
        <div className={classes.buttons}>
          <Button
            variant="outline"
            onClick={() => onCloseClick()}
            data-testid="go-back-to-payments-button"
          >
            Go back to payments
          </Button>
          <Button
            variant="light"
            data-testid="make-another-payment-button"
            onClick={() => onMakeAnotherPaymentClick()}
          >
            {error ? 'Try again' : 'Send another payment'}
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  successContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    // height: 520,
  },
  checkCircle: {
    width: 72,
    height: 72,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    color: '#27C281',
    fontSize: 40,
  },
  infoText: {
    fontWeight: 400,
    fontSize: 36,
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
    marginTop: '44px',
    fontFamily: 'redaction',
    width: 500,
    '@media(max-width: 767px)': {
      fontSize: 24,
    },
  },
  flavorText: {
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.fn.primaryColor(),
    textAlign: 'center',
    fontSize: 14,
    marginTop: 20,
  },
  buttons: {
    display: 'flex',
    gap: 30,
    marginTop: '20px',
  },
  backButton: {
    borderRadius: '8px',
    width: '100%',
    backgroundColor: theme.fn.primaryColor(),
    ':hover': {
      backgroundColor: theme.fn.primaryColor(),
    },
  },
  anotherPaymentTextContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
    cursor: 'pointer',
  },
  anotherText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: 14,
    color: '#5F5F5F',
  },
  anotherArrow: {
    marginLeft: 16,
  },
  TwoFactorContainer: {
    marginTop: 30,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  downloadReceipt: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    marginTop: '20px',
  },
}));

export default PaymentConfirmation;
