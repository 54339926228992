import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Analytics } from './analytics';

const useAnalyticsPageView = (env: string) => {
  const location = useLocation();
  useEffect(() => {
    // triggered on route change
    if (['production', 'development', 'local'].includes(env)) {
      trackPageView(); // To track the first pageview upon load
    }
  }, [location]);
  function trackPageView() {
    const route = window.location.pathname;
    const capitalizedNamedPage = route
      .split('/')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
      .trim()
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
      .trim();

    Analytics.pageView(`${capitalizedNamedPage} Page Viewed`, {
      'Page Name': route,
    });
  }
};

export default useAnalyticsPageView;
