import {
  ArrowBackIconWhite,
  LinkIconWhite,
  PlaidIconLowRes,
  UnlinkIconWhite,
} from 'assets/svg';
import { createStyles } from '@mantine/core';

type Props = {
  classNames?: Partial<{ iconContainer: string; icon: string }>;
} & React.HTMLProps<HTMLDivElement>;

export const NavBackIcon = ({ classNames, ...props }: Props) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(classes.iconContainer, classNames?.iconContainer)}
      {...props}
    >
      <ArrowBackIconWhite className={cx(classes.backIcon, classNames?.icon)} />
    </div>
  );
};

export const PlaidIcon = ({ classNames, ...props }: Props) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(
        classes.iconContainer,
        classes.lightColor,
        classNames?.iconContainer,
      )}
      {...props}
    >
      <PlaidIconLowRes className={classNames?.icon} />
    </div>
  );
};

export const LinkIcon = ({ classNames, ...props }: Props) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(
        classes.iconContainer,
        classes.green,
        classNames?.iconContainer,
      )}
      {...props}
    >
      <LinkIconWhite className={classNames?.icon} />
    </div>
  );
};

export const UnlinkIcon = ({ classNames, ...props }: Props) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(classes.iconContainer, classNames?.iconContainer)}
      {...props}
    >
      <UnlinkIconWhite className={classNames?.icon} />
    </div>
  );
};

const useStyles = createStyles({
  iconContainer: {
    width: '16px',
    height: '16px',
    backgroundColor: '#5F5F5F',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightColor: {
    backgroundColor: '#F1F1F1',
  },
  green: {
    backgroundColor: '#27C281',
  },
  backIcon: {
    marginLeft: -2,
  },
});
