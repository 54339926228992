import React, { ReactElement, useState } from 'react';
import { Box, Text } from '@mantine/core';
import { IconContext } from 'react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import useStyles from './navbar.styles';
import {
  BOTTOM_NAVBAR_OPTIONS,
  MenuOption,
  NAVBAR_OPTIONS,
} from './menu-options';
import { getEnvironment } from 'utilities/url/window-helpers';
import { useRecoilValue } from 'recoil';
import { UserCompanyState } from 'states/company/company';
import { FlexIcon } from 'assets/svg';
import {
  RolesSelector,
  IsAdmin,
} from '../../states/application/product-onboarding';
//   CompanySelector,
// import { BETA_TESTERS } from '../../../src/states/beta-testers';
import { UserRole } from 'states/user/user-info';

interface Props {
  children?: React.ReactNode;
}
/**
 *
 * @param userRoles the currently logged in persons roles
 * @param allowedRoles the allowed roles for the navtab
 * @returns
 */
const allowed = (userRoles: UserRole[], allowedRoles?: UserRole[]): boolean => {
  return (
    !allowedRoles?.length || userRoles.some((r) => allowedRoles.includes(r))
  );
};

const Navbar = ({ children }: Props): ReactElement => {
  const { classes } = useStyles({});
  const [highlightedRoute, setHighlightedRoute] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const hostEnv = getEnvironment(window.location.host);
  const isAdmin = useRecoilValue(IsAdmin);
  const { optedProducts } = useRecoilValue(UserCompanyState);
  // const { id } = useRecoilValue(CompanySelector);
  const roles = useRecoilValue(RolesSelector);

  const menuItemClass = (opt: MenuOption): string => {
    const path = location.pathname.split('/').filter((p) => p);
    if (location.pathname === opt.location || opt.location.includes(path[0])) {
      if (opt.unclickable) {
        return classes.notClickableHighlightedMenuOption;
      }
      return classes.highlightedMenuOption;
    } else if (opt.disabled || (opt.adminOnly && !isAdmin)) {
      return classes.disabledMenuOption;
    }
    if (opt.unclickable) {
      return classes.notClickableMenuOption;
    }
    return classes.menuOption;
  };

  const generateNavbarOptions = (options: MenuOption[]) => {
    /*
    set cross sellable products in this array - 
    if the user hasn't opted into a product and the product is in this array, show it on nav bar as Apply Now.
    note: need to ensure that the cross sell UI is in place to support user applying for product
    */
    const crossSellProducts = ['CREDIT', 'BANKING'];

    return options
      .filter((opt) => {
        // filter out navtabs that the user role does not have access to
        return allowed(roles, opt.rolesAllowed);
      })
      .filter(
        // show any navtabs that a user should be cross sold
        (opt) =>
          opt.product === 'ALL' ||
          optedProducts
            .concat(optedProducts, crossSellProducts)
            .includes(opt.product),
      )
      .map((opt: MenuOption) => {
        if (
          opt.devOnly === true &&
          hostEnv === 'production' // since demo.flex.one is not labeled for production branch, we will be able to utilize for internal testing without a beta tester list
          // && !BETA_TESTERS.includes(id)
        ) {
          return;
        } else {
          return (
            <div key={opt.label}>
              <div
                className={`${classes.menuOptionCommon} ${menuItemClass(opt)}`}
                onClick={() => {
                  navigate(opt.location);
                }}
                data-testid={opt.label.toLowerCase()}
                onMouseOver={() => {
                  setHighlightedRoute(opt.location);
                }}
                onMouseLeave={() => {
                  setHighlightedRoute('');
                }}
                key={opt.label}
              >
                <IconContext.Provider value={{ size: '25px' }}>
                  <div
                    className={classes.menuOptionIcon}
                    data-testid={`icon-${opt.label.toLowerCase()}`}
                  >
                    {opt.icon}
                  </div>
                </IconContext.Provider>
                <Text
                  size="sm"
                  sx={(t) => ({
                    fontFamily: 'PP Neue Montreal',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                    marginLeft: '30px',
                    alignItems: 'center',
                    color: t.fn.themeColor('neutral', 0),
                  })}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {opt.label}{' '}
                  {['Insurance'].includes(opt.label) && (
                    <span className={classes.txtComingSoon}>Coming Soon</span>
                  )}
                  {!optedProducts.includes(opt.product) &&
                    crossSellProducts.includes(opt.product) && (
                      <span className={classes.txtComingSoon}>Apply Now</span>
                    )}
                </Text>
              </div>
              {opt.location === highlightedRoute && (
                <div>
                  {opt.subRoutes?.map((route) => {
                    return (
                      <Text
                        key={route.label}
                        size="sm"
                        className={classes.subroute}
                        onClick={() => {
                          navigate(`${opt.location}${route.location}`);
                        }}
                      >
                        {route.label}
                      </Text>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }
      });
  };

  return (
    <div className={classes.fullScreen}>
      <div className={classes.navbar}>
        <div className={classes.topSection}>
          <Box ml={8}>
            <FlexIcon className={classes.icon} />
          </Box>
          {/*<Center className={classes.avatar}>*/}
          {/*  <FlexIcon height={'40px'} />*/}
          {/*</Center>*/}
          <div className={classes.nameSection}>
            <Text size="sm" className={classes.nameText}>
              Flexbase Technologies
            </Text>
          </div>
        </div>
        <div className={classes.navbarOptions}>
          {generateNavbarOptions(NAVBAR_OPTIONS)}
        </div>

        <div className={classes.navbarOptions}>
          {generateNavbarOptions(BOTTOM_NAVBAR_OPTIONS)}
        </div>
      </div>

      <div id="main" className={classes.pageDiv}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Navbar;
