import { useRecoilValue } from 'recoil';
import { Analytics } from 'services/analytics/analytics';
import flexbaseClient from 'services/flexbase-client';
import { UserIdState } from '../../areas/onboarding/onboarding-form.state';

// TODO move messaging to UI so this is just logic returning true/false
export const useCreditCheck = () => {
  const userId = useRecoilValue(UserIdState);

  return async (needed: number): Promise<string> => {
    const creditCheckError = `Unfortunately we are unable to provide you with the adequate amount of credit required 
    for this purchase. To find more information on why your credit request was denied, please contact credit@flex.one.`;
    try {
      const credit = await flexbaseClient.getCompanyCredit();
      if (credit && userId) {
        Analytics.people.set(userId, { ...credit });
      }
      if (!credit || credit.available < needed) {
        return creditCheckError;
      }

      return '';
    } catch (error) {
      return creditCheckError;
    }
  };
};
