import { useStyles } from './delete-team-member.styles';
import { Button, Center, Text } from '@mantine/core';
import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { TransparentCenteredLoader } from '@flexbase-eng/web-components';

type Props = {
  userId: string;
  userName: string;
  closeModal: () => void;
  refreshTeamMembers: () => void;
};

const DeleteTeamMember = ({
  userId,
  userName,
  closeModal,
  refreshTeamMembers,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { classes } = useStyles({ isMobile });
  const [loading, setLoading] = useState(false);

  const onDeleteClick = async () => {
    setLoading(true);
    try {
      await flexbaseOnboardingClient.deleteUser(userId);
      await refreshTeamMembers();
      closeModal();
      showNotification({
        title: 'Success!',
        message: `${userName} has been deleted from the team.`,
        color: 'flexbase-teal',
      });
    } catch (e) {
      console.error(e);
      closeModal();
      showNotification({
        color: 'red',
        title: 'Failure',
        message: `${userName} could not be deleted.`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center>
      <div className={classes.contentContainer}>
        <TransparentCenteredLoader visible={loading} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Text className={classes.title}>Delete team member? </Text>

          <div className={classes.escapeIcon} onClick={() => closeModal()}>
            <IoMdClose style={{ width: 20, height: 20, paddingTop: 2 }} />
          </div>
        </div>
        <div className={classes.messageContainer}>
          <Text className={classes.message}>
            Deleting this team member will remove them from Flexbase. This
            action cannot be undone.
          </Text>
        </div>

        <div className={classes.actionButtonsContainer}>
          <Button className={classes.cancel} onClick={closeModal}>
            Cancel
          </Button>
          <Button className={classes.delete} onClick={onDeleteClick}>
            Yes, Delete
          </Button>
        </div>
      </div>
    </Center>
  );
};

export default DeleteTeamMember;
