import { Text } from '@mantine/core';
import { useState } from 'react';
import { formatCurrency } from '@flexbase-eng/web-components';
import getPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { SafeBoxIcon } from 'assets/svg';
import { useConfirmAndReviewStyles } from 'areas/credit/repayment/review-and-pay.styles';
import { useClickOutside } from '@mantine/hooks';
import {
  AccountProps,
  DepositAccount,
  PlaidAccount,
} from 'areas/banking/move-funds/move-funds.model';
import { FlexAccountInfo } from 'areas/cash-management/treasury-management.state';
import { capitalizeOnlyFirstLetter } from 'utilities/formatters/format-strings';
import AccountBox from 'components/account-box/account-box';

interface Props {
  currentAccount: AccountProps;
  accounts?: AccountProps[];
  onAccountChange?: (account: AccountProps) => void;
  label?: string;
  classNames?: Partial<{ list: string; target: string }>;
}

export interface SelectionAccount {
  account: string;
  name: string;
  logoUrl?: string;
  accountType?: string;
  unlinked?: boolean;
}

const AccountSelection = ({
  currentAccount,
  accounts,
  onAccountChange,
  label,
  classNames,
}: Props) => {
  const { classes, cx } = useConfirmAndReviewStyles();
  const [showSelect, setShowSelect] = useState(false);
  const ref = useClickOutside(() => setShowSelect(false));

  const accountSubheaderText = (c: AccountProps) => {
    switch (c.plaidOrDeposit) {
      case 'deposit': {
        const flexbaseAccount = c as DepositAccount;
        return `${formatCurrency(
          flexbaseAccount.balance / 100,
        )} / ${capitalizeOnlyFirstLetter(
          flexbaseAccount.accountType,
        )} ${getPaddedAccountMask(flexbaseAccount.accountNumber, 4)}`;
      }
      case 'plaid': {
        const plaidAccount = c as PlaidAccount;
        return `${capitalizeOnlyFirstLetter(plaidAccount.accountType || '')} ${
          plaidAccount.last4
            ? plaidAccount.last4
            : getPaddedAccountMask(plaidAccount.account, 4)
        }`;
      }
      case 'admDeposit': {
        const admAccount = c as FlexAccountInfo;
        return `${admAccount.subName} ${getPaddedAccountMask(
          admAccount.accountNumber,
          4,
        )}`;
      }
    }
  };

  return (
    <div ref={ref} className={classNames?.target}>
      {label && <Text className={classes.inputTitle}>{label}</Text>}
      <AccountBox
        headerText={
          currentAccount.plaidOrDeposit === 'deposit'
            ? currentAccount.nickName || currentAccount.name
            : currentAccount.plaidOrDeposit == 'admDeposit'
            ? currentAccount.name
            : currentAccount.accountName
        }
        subheaderText={accountSubheaderText(currentAccount) || ''}
        onClick={() => {
          if (accounts) {
            setShowSelect((prev) => {
              if (accounts.length > 1) {
                return !prev;
              }
              return prev;
            });
          }
          setShowSelect(!showSelect);
        }}
        showArrow={accounts && accounts.length > 1}
        rotateArrow={showSelect}
        isListItem={false}
        showBorder={true}
        icon={<SafeBoxIcon width={30} data-testid={'accounts-list'} />}
      />
      {showSelect && accounts && accounts.length > 1 && (
        <div className={cx(classes.selectList, classNames?.list)}>
          {accounts
            .filter((c) => c.id !== currentAccount.id)
            .map((c, index) => (
              <div key={index} data-testid={`accounts-list-${index}`}>
                <AccountBox
                  headerText={
                    c.plaidOrDeposit === 'deposit'
                      ? c.nickName || c.name
                      : c.plaidOrDeposit === 'admDeposit'
                      ? c.name
                      : c.accountName
                  }
                  subheaderText={accountSubheaderText(c) || ''}
                  onClick={() => {
                    if (onAccountChange) {
                      onAccountChange(c);
                    }
                    setShowSelect(false);
                  }}
                  showArrow={false}
                  rotateArrow={false}
                  isListItem={true}
                  key={index}
                  icon={<SafeBoxIcon />}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AccountSelection;
