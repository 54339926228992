import Plot from 'react-plotly.js';
import { useRecoilValue } from 'recoil';
import { Box, Overlay, rem, Text, useMantineTheme } from '@mantine/core';

import { useEffect, useState } from 'react';
import { Data } from 'plotly.js';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { DateTime } from 'luxon';
import { InvoicesByMccCode } from 'services/flexbase/flexbase-onboarding-client';
import {
  convertToCurrency,
  convertToCurrencyArray,
  shortenLabelName,
} from 'components/charts/utilities';
import { MoneySpentPlaceholder } from 'assets/svg';
import usePlotlyGlobalStyles from 'components/viz/styles';
import { CompanyIdState } from 'areas/onboarding/onboarding-form.state';
import { TransparentCenteredLoader } from '@flexbase-eng/web-components';

export interface AnnualSpendData {
  data: Data[];
}
/**
 *
 * @returns an amazing donut chart of the annual spend for a business
 */
const AnnualSpendChart = () => {
  const theme = useMantineTheme();
  const { classes } = usePlotlyGlobalStyles();
  const chartXAxisShift = -0.15;
  const companyId = useRecoilValue(CompanyIdState);
  let mccCodeData: InvoicesByMccCode;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAnnualSpendByMccData();
  }, []);

  const [annualSpend, setAnnualSpend] = useState<AnnualSpendData>({
    data: [],
  });
  const [totalSpend, setTotalSpend] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * The format object is 1 of 2 primary objects that is needed for a chart.
   * The format object defines the charts overall look and feel, with ability to add titles, annotations, etc
   * <Plot
      data={annualSpend.data}
      layout={getFormat()} />
   * 
   */
  const getFormat = () => {
    return {
      title: {
        text: 'Money Spent This Year',
        y: 0.92,
        x: 0.05,
        font: {
          family: 'Inter',
          size: 16,
          weight: 500,
          color: '#5F5F5F',
        },
      },
      hovermode: true,
      margin: { t: 10, b: 10, l: 0, r: 220 },
      annotations: [
        {
          font: {
            size: 16,
            family: 'PP Neue Montreal',
          },
          showarrow: false,
          text: `$${convertToCurrency(Number(totalSpend))}`,
          x: 0.57 + chartXAxisShift,
          y: 0.68,
        },
        {
          font: {
            family: 'PP Neue Montreal',
            size: 12,
          },
          showarrow: false,
          text: 'Total',
          x: 0.57 + chartXAxisShift,
          y: 0.58,
        },
      ],
      showlegend: true,
      legend: {
        y: 0.8,
        x: 0.8,
        itemclick: false,
        itemdoubleclick: false,
        itemsizing: 'constant',
        font: {
          family: 'PP Neue Montreal',
          size: 12,
        },
      },
    } as any;
  };

  /**
   * The data object is the second primary input for a plotlyjs plot.
   * The data object houses all of the computed SVGs, charts, and text elements
   * that are within a chart. You can have multiple charts within a chart object
   * as well as control positioning, sizing, etc.
   */
  const getAnnualSpendByMccData = async () => {
    try {
      setLoading(true);
      mccCodeData = await flexbaseOnboardingClient.getInvoicesByMccCategory(
        companyId,
        {
          limit: '4',
          after: DateTime.now().set({ day: 1, month: 1 }).toISODate()!,
          before: DateTime.now().toISODate()!,
        },
      );
      if (mccCodeData.success === true && mccCodeData.categories) {
        setTotalSpend(mccCodeData.allInvoices || '0');
        const catLabels: string[] = [];
        const catValues: number[] = [];

        // create an array of labels for the donut chart
        // create an array of values for the donut chart
        mccCodeData.categories?.forEach((c) => {
          catLabels.push(
            shortenLabelName(c.description || c.category || '', 25),
          );
          catValues.push(+Number(c.total).toFixed(2));
        });
        // calculate the total sum of the categories
        const sumCategories = mccCodeData.categories
          ?.map((c) => {
            return Number(c.total || '0');
          })
          .reduce((x, y) => {
            return x + y;
          });
        /**
         * calculate the "Other" category, which is all of the remaining amount
         * after subtracting the first 4 categories.
         *  */

        catValues.push(Number(mccCodeData.allInvoices) - (sumCategories || 0));
        /**
         *
         * create the final array holding the chart(s). in this case we just have 1 chart, the pie chart.
         * we do the following:
         * - set the values and labels for the chart
         * - set the hovermode for how the label get's shown when hoving over a piece of the donut
         * - set the label font info
         * - set the colors of the donut with the marker property
         * - set the x and y domain, where the chart can be persisted, out of the full relative size.
         * In this case, we state that the donut will have from 0 to 0.85,
         * or up to the 85% of the entire canvas on the x axis to persist.
         * - set various pie chart settings like hole size and auto margin
         *  */

        const responseData = [
          {
            values: catValues,
            labels: catLabels.concat(['Other']),
            hoverinfo: convertToCurrencyArray(catValues, '$'),
            hovermode: 'closest',
            // cannot find padding for hoverlabel, research
            hoverlabel: {
              bgcolor: '#FFF',
              bordercolor: '#F4F5FA',
              font: {
                color: 'black',
                family: 'PP Neue Montreal',
                size: 12,
              },
            },
            marker: {
              colors: [
                theme.fn.primaryColor(),
                '#3DCC79',
                '#79E7D4',
                '#FFF2F0',
                '#3B3B3B',
              ],
            },
            domain: { x: [0, 1 + chartXAxisShift], y: [0.43, 0.83] },
            textinfo: 'none',
            hole: 0.9,
            type: 'pie',
            automargin: true,
          },
        ];

        if (responseData) {
          setAnnualSpend({ data: responseData as any });
        }
      } else {
        setErrorMessage(
          'Unable to calculate your annual spend cagetories at this time.',
        );
      }
    } catch {
      setErrorMessage(
        'We are unable to retrieve the account information at the moment.',
      );
    } finally {
      setLoading(false);
    }
  };

  // we now set the data and layout in the main Plot component, and you are done.
  return (
    <Box
      sx={(theme) => ({
        minWidth: 420,
        height: '125%',
        backgroundColor: theme.fn.themeColor('neutral', 0),
      })}
    >
      <TransparentCenteredLoader visible={loading} />
      {!errorMessage ? (
        <Plot
          className={classes.something}
          style={{
            width: 'inherit',
            height: 'inherit',
          }}
          data={annualSpend.data}
          layout={getFormat()}
          config={{
            displayModeBar: false,
            displaylogo: false,
            staticPlot: true,
          }}
        />
      ) : (
        <div
          style={{
            position: 'relative',
            borderRadius: rem(2),
          }}
        >
          <MoneySpentPlaceholder width="100%" height="200px" />
          <Overlay
            color="#FFFFFF"
            opacity={0.9}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                backgroundColor: '#FF4337',
                color: '#FFFFFF',
                padding: '5px 15px',
                borderRadius: '360px',
                fontSize: '14px',
              }}
            >
              Start spending to analyze your finances.
            </Text>
          </Overlay>
        </div>
      )}
    </Box>
  );
};

export default AnnualSpendChart;
