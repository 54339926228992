import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Text, TextInput, useMantineTheme } from '@mantine/core';
import { formatCurrency } from 'utilities/formatters/format-currency';
import flexbaseClient from 'services/flexbase-client';
import { CustomMantineStyles, useStyles } from './payments-table.styles';
import { sortList } from 'utilities/extensions/sort-lists';
import { SearchIcon } from 'assets/svg';
import FlexbaseTable from 'components/table/flexbase-table';
import { useMediaQuery } from '@mantine/hooks';

interface Payment {
  amount: string;
  status: string;
  datePosted: string;
  createdAt: string;
  origin: string;
  id: string;
}

const PaymentsTable = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const useMobileView = useMediaQuery('(max-width: 767px)');
  const [inputText, setInputText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Payment[]>();

  const inputHandler = (e: any) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const formatStatus = (status: string, postedDate: string): string => {
    const hasFuturePostDate =
      DateTime.fromSQL(postedDate).diffNow('milliseconds').milliseconds > 0;

    switch (status) {
      case 'succeeded':
        return hasFuturePostDate ? 'Pending' : 'Posted';
      case 'failed':
        return 'Returned';
      case 'pending':
        return 'Pending';
      case 'initiated':
        return 'Created';
      case 'canceled':
        return 'Returned';
      default:
        return 'Created';
    }
  };

  const getCompanyPayments = async () => {
    try {
      setLoading(true);
      const { success, payments, error } =
        await flexbaseClient.getCompanyPayments();
      // Make sure the latest invoices come first
      sortList(payments!, {
        sortType: 'desc',
        sortBy: 'createdAt',
      });
      if (success) {
        setPayments(payments);
      } else {
        if (error) {
          setErrorMessage(error as string);
        } else {
          setErrorMessage(
            'We are unable to retrieve the company payments at the moment.',
          );
        }
      }
    } catch (error) {
      console.error('Unable to get the company payments', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyPayments();
  }, []);

  const toUpperCase = (string: string) => {
    // In some cases the API returns nothing for certain fields..
    if (!string) {
      return 'N/A';
    }

    let capitalized = '';

    if (string === 'inDispute') {
      capitalized = 'In dispute';
      return capitalized;
    }
    capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized;
  };

  const sortDate = (a: { createdAt: string }, b: { createdAt: string }) => {
    return (
      DateTime.fromSQL(a.createdAt).toMillis() -
      DateTime.fromSQL(b.createdAt).toMillis()
    );
  };

  const columns = [
    {
      name: 'Date Created',
      selector: (row: { dateSubmitted: string }) => row.dateSubmitted,
      sortable: true,
      sortFunction: sortDate,
    },
    {
      name: 'Date Posted',
      selector: (row: { datePosted: any }) => row.datePosted,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row: { amount: any }) =>
        formatCurrency((row.amount * -1).toString()),
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row: { type: any }) => {
        if (row.type.toLowerCase() === 'auto') {
          return 'Auto Pay';
        }
        if (row.type.toLowerCase() === 'manual') {
          return 'One Time';
        }
        return toUpperCase(row.type);
      },
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row: { status: any }) => {
        let statusColor = '';

        switch (row.status) {
          case 'Posted':
            statusColor = '#E9F9F2';
            break;
          case 'Pending':
          case 'Created':
            statusColor = '#EEEEF3';
            break;
          case 'Returned':
            statusColor = '#F9D9DF';
            break;
          default:
            statusColor = '#E4D2A2';
        }
        return (
          <div
            className={classes.statusColumn}
            style={{
              backgroundColor: statusColor,
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            {toUpperCase(row.status)}
          </div>
        );
      },
      sortable: true,
    },
  ];

  const tableData = payments?.map((payment) => {
    return {
      id: payment.id,
      datePosted: DateTime.fromSQL(payment.datePosted).toFormat('LLL d, yyyy'),
      dateSubmitted: DateTime.fromSQL(payment.createdAt).toFormat(
        'LLL dd, yyyy',
      ),
      createdAt: payment.createdAt,
      amount: payment.amount,
      type: toUpperCase(payment.origin),
      status: formatStatus(payment.status, payment.datePosted),
    };
  });

  const filteredData = tableData?.filter((payment) => {
    if (inputText === '') {
      return payment;
    } else {
      return (
        payment.datePosted.toLowerCase().includes(inputText) ||
        payment.dateSubmitted.toLowerCase().includes(inputText) ||
        payment.amount.toLowerCase().includes(inputText) ||
        payment.status.toLowerCase().includes(inputText) ||
        payment.type.toLowerCase().includes(inputText)
      );
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text className={classes.title}> </Text>
        <TextInput
          w={useMobileView ? '100%' : 368}
          styles={{
            input: CustomMantineStyles(theme).searchPaymentHistory.input,
          }}
          placeholder="Search payment history"
          onChange={inputHandler}
          icon={<SearchIcon width={20} height={20} />}
        />
      </div>
      <FlexbaseTable
        columns={columns}
        data={filteredData}
        pagination={filteredData && filteredData?.length > 8}
        isFetchingData={loading}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default PaymentsTable;
