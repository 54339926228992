import { Fragment, useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import CreditLimit from '../../components/credit-limit/credit-limit';
import CreditPaymentCard from '../../components/credit-payment/credit-payment';
import CreditTransactions from '../../components/credit-transactions/credit-transactions';
import AnnualSpendChart from './annual-spend.chart';
import SpentWeek from 'areas/credit/components/spent-this-week/spent-this-week';
import LastTransaction from 'areas/credit/components/last-transaction/last-transaction';
import { useStyles } from './overview.styles';
import { useRecoilValue } from 'recoil';
import { CompanyIdState } from 'areas/onboarding/onboarding-form.state';
import { ApplicationState } from '../../../../states/application/product-onboarding';

const Overview = () => {
  const { classes, cx } = useStyles();
  const [frozen, setFrozen] = useState(false);
  const companyId = useRecoilValue(CompanyIdState);
  const [hasMadePayment, setHasMadePayment] = useState(false);
  const { user } = useRecoilValue(ApplicationState);

  useEffect(() => {
    const getCompanyBalance = async () => {
      const result = await flexbaseOnboardingClient.getMinimumDue(
        `${companyId}`,
      );
      setFrozen(result.frozen);
      setHasMadePayment(result.paymentMadeThisCycle);
    };
    getCompanyBalance();
  }, []);

  return (
    <Fragment>
      <div className={classes.creditContainer}>
        {user.roles.some(
          (r) => r === 'ADMIN' || r === 'ACCOUNTANT' || r === 'OWNER',
        ) ? (
          <div className={classes.widgetContainer}>
            <div className={classes.widget}>
              <CreditLimit
                hasDelinquentAmount={frozen}
                hasPendingPayment={hasMadePayment}
              />
            </div>
            <div className={cx(classes.monaySpentContainer, classes.widget)}>
              <AnnualSpendChart></AnnualSpendChart>
            </div>
            <div className={classes.widget}>
              <CreditPaymentCard />
            </div>
          </div>
        ) : (
          <>
            <LastTransaction />
            <SpentWeek />
          </>
        )}
      </div>
      <CreditTransactions />
    </Fragment>
  );
};

export default Overview;
