import { useRecoilValue } from 'recoil';
import { ReactNode, useState } from 'react';
import {
  Alert,
  Button,
  createStyles,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';

import { ChevronDownIcon, RedAlertIcon } from 'assets/svg';
import { BnplState, TransactionStatus } from 'states/bnpl/bnpl.state';
import { merchantCallback } from 'utilities/url/get-bnpl-merchant-callback';

interface AuthContentProps {
  title: string;
  screen: string;
  errorMsg: string;
  children: ReactNode;
  onGoback: () => void;
  onSubmit: () => void;
  onResendCode?: () => void;
  submitButtonLabel: string;
}

const AuthContent = ({
  title,
  screen,
  onGoback,
  children,
  errorMsg,
  onSubmit,
  onResendCode,
  submitButtonLabel,
}: AuthContentProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [showBuild, setShowBuild] = useState({
    count: 0,
    show: false,
  });

  const showBuildOnClick = () => {
    if (showBuild.count === 4) {
      setShowBuild({
        count: 5,
        show: true,
      });
    } else if (showBuild.count > 4) {
      setShowBuild({
        count: 1,
        show: false,
      });
    } else {
      setShowBuild({ ...showBuild, count: showBuild.count + 1 });
    }
  };

  // bnpl cancel
  const { isBnpl, callbackParam, callbackBaseUrl, sessionParam, merchantName } =
    useRecoilValue(BnplState);

  const bnplCallback = merchantCallback(
    callbackBaseUrl,
    TransactionStatus.canceled,
    callbackParam,
    sessionParam,
  );

  const onCancelClick = () => {
    window.location.replace(bnplCallback);
  };

  return (
    <div>
      <div onClick={onGoback} className={classes.goBackSection}>
        <ChevronDownIcon />
        Back
      </div>

      <Title onClick={showBuildOnClick} className={classes.title}>
        {title}
      </Title>

      <Stack my={30} spacing="xl">
        {children}
      </Stack>

      {errorMsg && (
        <Alert icon={<RedAlertIcon />} className={classes.alert} my="lg">
          {errorMsg}
        </Alert>
      )}

      <Button
        fullWidth
        type="submit"
        variant="light"
        id="auth-submit"
        onClick={onSubmit}
        data-testid="button-auth-submit"
      >
        {submitButtonLabel}
      </Button>

      {screen === '2FA' && onResendCode && (
        <Text onClick={onResendCode} className={classes.reSendCode}>
          Re-send code
        </Text>
      )}

      {isBnpl && (
        <Button
          mt={15}
          fullWidth
          id="login-cancel"
          variant={'subtle'}
          onClick={onCancelClick}
          data-testid="button-bnpl-cancel"
        >
          {screen === 'change-password'
            ? `Back to ${merchantName || 'merchant'}`
            : 'Cancel and go back'}
        </Button>
      )}

      {showBuild.show && (
        <Text
          mt={15}
          color={theme.colors.primarySecondarySuccess[2]}
          inherit
          component="span"
        >
          Build ID: {import.meta.env.VITE_APP_GITHUB_SHA || 'LOCAL'}
        </Text>
      )}
    </div>
  );
};

export default AuthContent;

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: 'normal',
    fontFamily: 'Redaction',
    color: theme.fn.themeColor('neutral', 0),
    '@media (max-width: 768px)': {
      fontSize: '32px',
    },
  },
  goBackSection: {
    gap: 10,
    display: 'flex',
    cursor: 'pointer',
    marginBottom: '20px',
    alignItems: 'center',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      textDecoration: 'underline',
    },
    svg: {
      width: '20px',
      height: '20px',
      transform: 'rotate(90deg)',
      '@media (max-width: 768px)': {
        width: '15px',
        height: '15px',
      },
    },
  },
  reSendCode: {
    fontSize: '14px',
    cursor: 'pointer',
    marginTop: '10px',
    color: theme.fn.themeColor('neutral', 0),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  alert: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    padding: theme.spacing.sm,
    color: theme.colors.neutral[9],
    borderRadius: theme.defaultRadius,
    background: theme.fn.themeColor('critical', 0),
    border: `1px solid ${theme.fn.themeColor('critical', 2)}`,
  },
}));
