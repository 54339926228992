import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  category: {
    marginBottom: '53px',
  },
  categoryHeader: {
    display: 'flex',
    borderBottom: '1px solid #E8E8E8',
    alignItems: 'center',
    padding: '19px 0',
    marginBottom: '10px',
    '> div:last-of-type': {
      marginLeft: 'auto',
    },
  },
  categoryName: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 400,
    fontSize: '21px',
    lineHeight: '25px',
    marginRight: '15px',
  },
  categoryScope: {
    fontFamily: 'PP Neue Montreal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#8F8F8F',
  },
  contentHeader: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#858585',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    '> div:last-of-type': {
      display: 'flex',
      width: '212px',
      justifyContent: 'space-between',
    },
  },
  notificationsSection: {
    '&:not(:last-child)': {
      borderBottom: '1px solid #E8E8E8',
    },
  },
  subNotificationsSection: {
    marginLeft: '30px',
  },
  notification: {
    '&:not(:last-child)': {
      borderBottom: '1px solid #E8E8E8',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '60px',
    padding: '10px 0',
  },
  notificationName: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '30px',
    color: '#374151',
    '> div:last-of-type': {
      marginLeft: '22px',
      display: 'inline-block',
    },
  },
  notificationCheckboxes: {
    display: 'flex',
    width: '212px',
    minWidth: '212px',
    padding: '0 2px 0 5px',
    justifyContent: 'space-between',
  },
}));
