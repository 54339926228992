import {
  Badge,
  Button,
  CloseButton,
  Divider,
  Group,
  Text,
} from '@mantine/core';
import { useStyles } from './styles';
import TwoFactorVerify from 'components/auth/two-factor-auth';
import { flexbaseBankingClient } from 'services/flexbase-client';
import { useEffect, useState } from 'react';
import AttachtmentsSection from './attachments-section';
import { DownloadIcon } from 'assets/svg';
import { useMediaQuery } from '@mantine/hooks';
import { DateTime } from 'luxon';
import useModal from 'components/modal/modal-hook';

export interface Details {
  dateSubmitted: string;
  createdAt: string;
  amount: any;
  type: string;
  status: string;
  costumer: string;
  direction: string;
  description: string;
  number: string;
  recipient: string;
  id: string;
  notes: string;
}

interface Props {
  row: Details;
  getPayments: (name: string) => void;
}

const PaymentDetailsModal = ({ row, getPayments }: Props) => {
  const { closeAllModals } = useModal();
  const { classes, cx } = useStyles();
  const [loading, setLoading] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState(row.status);
  const useMobileView = useMediaQuery('(max-width: 755px)');
  const createdAt = DateTime.fromSQL(row.createdAt);
  const formattedDate = `${createdAt.toFormat('DDD')} at ${createdAt.toFormat(
    'ttt',
  )}`;

  const handleOnChange = async (code: string) => {
    if (row?.id && code.length === 6) {
      setLoading(true);
      setErrorMessage('');
      try {
        const confirmed = await flexbaseBankingClient.confirmPayment(
          row?.id,
          code,
        );
        setConfirmationStatus(confirmed?.status);
        setStatus(confirmed?.status);
      } catch (error) {
        setErrorMessage('Confirmation failed. Please try again...');
      } finally {
        setLoading(false);
      }
    }
  };

  const resendCode = async () => {
    setErrorMessage('');
    if (row?.id) {
      try {
        await flexbaseBankingClient.confirmPayment(row?.id);
      } catch (error) {
        setErrorMessage(error);
      }
    }
  };

  useEffect(() => {
    if (confirmationStatus) {
      getPayments('');
    }
  }, [confirmationStatus]);

  return (
    <div className={classes.paymentDetailsModal}>
      <div className={cx(classes.header, classes.rowContent)}>
        <div>
          <div style={{ display: 'flex', gap: 14, alignItems: 'center' }}>
            <Text size={useMobileView ? 20 : 36}>{row.amount}</Text>
            <Badge>{status}</Badge>
          </div>
          <Text size={useMobileView ? 10 : 14}>{formattedDate}</Text>
        </div>
        <div>
          <CloseButton
            color="neutral.0"
            variant="transparent"
            onClick={closeAllModals}
            size={useMobileView ? 20 : 30}
          />
        </div>
      </div>
      <Group
        spacing="xl"
        grow
        mx={useMobileView ? 20 : 50}
        align="initial"
        mt={24}
      >
        <div>
          <Text className={classes.smallText}>Recipient</Text>
          <Text mt={5} className={classes.bigText}>
            {row.recipient}
          </Text>
        </div>
        <div>
          <Text className={classes.smallText}>Type</Text>
          <Text mt={5} className={classes.bigText}>
            {row.type}
          </Text>
        </div>
      </Group>

      <Divider my={24} />
      <div className={classes.detailsContainer}>
        <Text className={classes.smallText}>External Notes </Text>
        <Text className={classes.smallText}>{row.description}</Text>
      </div>
      {row.notes !== '' && (
        <>
          <Divider my={24} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Internal Notes </Text>
            <Text className={classes.smallText}>{row.notes}</Text>
          </div>
        </>
      )}

      {row.status === '2FA Required' && row.type === 'Wire' && (
        <>
          <Divider my={24} />
          <div className={classes.detailsContainer}>
            {confirmationStatus ? (
              <Text>Congrats! Wire transfer is now being processed.</Text>
            ) : (
              <>
                <Text size={14} fw={400} mb={5}>
                  Provide the code that was sent to you via SMS
                </Text>
                <TwoFactorVerify
                  token=""
                  errorMsg={errorMessage}
                  retry={false}
                  handleOnChange={handleOnChange}
                  resendCode={resendCode}
                  loading={loading}
                />
                <Button mt={20} disabled>
                  Cancel
                </Button>
              </>
            )}
          </div>
        </>
      )}

      <AttachtmentsSection {...{ row }} />

      {row.type === 'Wire' && (
        <>
          <Divider my={24} />
          <div className={classes.detailsContainer}>
            <Text className={classes.smallText}>Payment receipt</Text>
            <div
              className={classes.receiptSection}
              onClick={() => window.open(`/payment/wire-receipt/${row?.id}`)}
            >
              <DownloadIcon width={15} />
              <Text>Download</Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentDetailsModal;
