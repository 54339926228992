/**
 * Mask an account with a •.
 * @param account
 * @param paddingLength The number of masking symbols to use. A length of 2 would produce ••1234
 * @param maskLength The length of the visible account numbers, defaults to 4
 * @constructor
 */
const GetPaddedAccountMask = (
  account: string,
  paddingLength: number,
  maskLength = 4,
) => {
  const accountMask =
    account.length >= maskLength
      ? account.slice(-1 * maskLength)
      : account.padStart(maskLength, '•');

  return accountMask.padStart(maskLength + paddingLength, '•');
};

export default GetPaddedAccountMask;
