import { OptedProduct } from 'states/application/product-onboarding';
import IAddress from 'states/user/address';
import { UserRole } from 'states/user/user-info';

export interface OnboardingInfoRequiredData {
  user: string[];
  company: string[];
  hasOnboardingRequirements: boolean;
}

export interface StripeError {
  code: string;
  reason: string;
  requirement: string;
}

export const ROLE_SELECT_VALUES = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'EMPLOYEE', label: 'Employee' },
  { value: 'ACCOUNTANT', label: 'Accountant' },
];

export interface OnboardingStripeInfo {
  status: 'not-started' | 'complete' | 'incomplete';
  currentlyDue: string[];
  pastDue: string[];
  pendingVerification: string[];
  errors: StripeError[];
}

export interface UnderwritingResponse {
  success: boolean;
  company: OnboardingCompany;
  approved: boolean;
  checks?: [
    {
      level: number;
      name: string;
      reason?: string;
      status: string;
      success: boolean;
    },
  ];
}

export interface PromoCode {
  id: string;
  userId: string;
  code: string;
  level: string;
  validFrom: string;
  validUntil: any;
  title: string;
  description: string;
  uwReject: boolean;
}

export interface OnboardingUser {
  id: string;
  firstName: string;
  lastName: string;
  taxId: string;
  birthDate: string;
  jobTitle: string;
  email: string;
  phone: string;
  cellPhone: string;
  address: IAddress;
  authorizedSignatory: boolean;
  roles: UserRole[];
  status: string;
  plaidLinked: boolean;
  termsOfServiceSigned: boolean;
  bankingTermsOfServiceSigned: boolean;
  treasuryTermsOfServiceSigned: boolean;
  creditTermsOfServiceSigned: boolean;
  hasBusinessCard: boolean;
  businessCardTypes: string[];
  employeeCount: string;
  promoCode?: PromoCode;
  tracking?: Tracking;
  personalGuarantySigned: boolean;
}

interface Tracking {
  session_id?: string;
  client_id?: string;
  timestamp_micros?: number;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  required: string[];
  requiredBanking: string[];
  requiredCredit: string[];
  requiredTreasury: string[];
  isComplete: boolean;
}

export function IsFullCompanyOwner(
  owner: Partial<CompanyOwner> | CompanyOwner,
): owner is CompanyOwner {
  return !!owner.email;
}

export interface CompanyOwner {
  id: string;
  address: string;
  addressLine2: string;
  asOf: string;
  authorizedSignatory: boolean;
  byUser: string;
  cellPhone: string;
  city: string;
  companyId: string;
  completedInviteNewUsers: boolean;
  completedOffboarding: boolean;
  completedOnboarding: boolean;
  country: string;
  displayName: string;
  email: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  mqUserToken: string;
  phone: string;
  postalCode: string;
  profileDocId: string;
  pushNotifTokens: string;
  roles: string[];
  state: string;
  status: string;
  stpCardholderId: string;
  stpPersonId: string;
  username: string;
  version: number;
  tenantId: string;
  ownershipPct: number;
}

export interface BankAccount {
  bankAccountNumber: string;
  bankRoutingNumber: string;
}

export interface FinancialInstitution {
  id?: string; // In practice these optional values are never null, but requiring them breaks old code
  accountId?: string;
  account: string;
  routing: string;
  accountName?: string;
  accountType?: string;
  bankName?: string;
  officialName?: string;
  last4?: string;
  active?: boolean;
  unlinked?: boolean;
}

export type OwnerType = Partial<CompanyOwner> | CompanyOwner;

export interface OnboardingCompany {
  id: string;
  companyName: string;
  doingBusinessAs: string;
  taxId: string;
  formationDate: string;
  legalStructure: string;
  website: string;
  monthlyExpenditure: string;
  phone: string;
  address: IAddress;
  owners: OwnerType[];
  financialInstitutions: FinancialInstitution[];
  category: string;
  annualRevenue: string;
  averageMonthlyPayroll: string;
  insuranceTypes: string[];
  hasInsurance: boolean;
  optedProducts: OptedProduct[];
  creditLimit?: string;
  autopay: boolean;
  formationState: string;
  businessPurpose: string;
  cashOnHand: string;
  naicsCode: string;
  reinvestTreasuryInterest: boolean;
  primaryPlaidAccount: string;
  businessVertical: string;
  requestedCreditLimit: string;
  stateOfOperation: string;
  onboardedBy: string;
  createdBy: string;
}

export interface OnboardingStatus {
  required: string[];
  user: Partial<OnboardingUser>;
  company: Partial<OnboardingCompany>;
  completedOnboarding: boolean;
}

export interface OnboardingStatusResponse {
  success: boolean;
  required: string[];
  requiredBanking: string[];
  requiredCredit: string[];
  requiredTreasury: string[];
  user: OnboardingUser;
  company: OnboardingCompany;
  completedOnboarding?: boolean;
}

export interface OnboardingOverviewResponse {
  success: boolean;
  users: User[];
  company: OnboardingCompany;
}

export interface ShipCardAddress {
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export interface ShipCardRequest {
  cardType: 'virtual' | 'physical';
  shipTo: ShipCardAddress;
  service: string;
}

export interface SubmitUnderwritingResponse {
  userId: string;
  companyId: string;
  level: number;
  success: boolean;
  incompleteOwners: unknown[];
  ownerKYCRuns: unknown[];
  incompleteOfficers: unknown[];
  officerKYCRuns: any[];
  approved: boolean;
}
