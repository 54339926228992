import { useNavigate } from 'react-router-dom';
import { AspectRatio, Button, createStyles, Image } from '@mantine/core';

import MoneyPile from 'assets/images/money-pile.png';

import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';

const MakePaymentWidget = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const user = useRecoilValue(UserInfoState);
  return (
    <div className={classes.card}>
      <div className={classes.contentCard}>
        <div>
          <span className={classes.titleCard}>Send a Payment</span>
          <p>A simple and easy-to-use way to send payments.</p>
        </div>
        <Button
          onClick={() => navigate('/payments/outgoing/recipient')}
          disabled={!user.roles.includes('ADMIN')}
        >
          Make a payment
        </Button>
      </div>
      <AspectRatio ratio={1} w={240} h={120} mt={20} mr={15}>
        <Image src={MoneyPile} />
      </AspectRatio>
    </div>
  );
};

export default MakePaymentWidget;

const useStyles = createStyles((theme) => ({
  card: {
    display: 'flex',
    padding: theme.spacing.xl,
    height: '200px',
    borderRadius: theme.defaultRadius,
    backgroundColor: theme.fn.themeColor('neutral', 0),
    alignContent: 'space-between',
    justifyContent: 'space-between',
  },

  contentCard: {
    display: 'flex',
    fontWeight: 500,
    flexWrap: 'wrap',
    alignContent: 'space-between',
    p: {
      color: '#757575',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },

  titleCard: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.colors.blackish[1],
  },
}));
