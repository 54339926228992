import { useCallback, useEffect, useState } from 'react';
import flexbaseClient from 'services/flexbase-client';
import { Invoice } from 'flexbase-client';
import { useRecoilValue } from 'recoil';
import { UserInfoState } from 'states/user/user-info';
import { useStyles } from '../credit-transactions.styles';
import { DateTime } from 'luxon';
import { uniq } from 'underscore';
import TransactionDetailRow from './transaction-detail-row';
import TransactionYearRow from './transaction-year-row';
import useModal from 'components/modal/modal-hook';
import TransactionsByYear from './transactions-by-year';
import TransactionDetails from '../details/transaction-details';
import { CompanySelector } from 'states/application/product-onboarding';

/**
 * A component to display credit transaction data in a non-tabular form for mobile views.
 * @constructor
 */
const CreditTransactionsSm = () => {
  const [transactions, setTransactions] = useState<Invoice[]>([]);
  const user = useRecoilValue(UserInfoState);
  const company = useRecoilValue(CompanySelector);
  const [visibleCount] = useState(10);
  const [years, setYears] = useState<number[]>([]);
  const modals = useModal();
  const { classes } = useStyles();

  const getCompanyTransactions = async () => {
    let companyTransactions: Invoice[] | null;

    if (user.roles.includes('ADMIN') || user.roles.includes('ACCOUNTANT')) {
      companyTransactions = await flexbaseClient.getInvoicesByCompany(
        `${company.id}`,
        {
          includeCardholder: true,
          includeMerchantName: true,
        },
      );
    } else {
      companyTransactions = await flexbaseClient.getInvoicesByUser(
        `${user.id}`,
        {
          includeCardholder: true,
          includeMerchantName: true,
        },
      );
    }

    if (companyTransactions) {
      setTransactions(companyTransactions);
      setYears(
        uniq(
          companyTransactions.map((t) => DateTime.fromSQL(t.createdAt).year),
        ),
      );
    }
  };

  useEffect(() => {
    getCompanyTransactions();
  }, []);

  const getTotalTransactionsInYear = useCallback(
    (year: number) => {
      return transactions
        .map((t) => DateTime.fromSQL(t.createdAt).year)
        .filter((y) => y === year).length;
    },
    [transactions],
  );

  const showTransactionsForYear = (year: number) => {
    modals.openFullModal(
      <TransactionsByYear
        year={year}
        transactions={transactions}
        onClose={modals.closeAllModals}
      />,
    );
  };

  const onTransactionClick = (transaction: Invoice) => {
    modals.openFullModal(
      <TransactionDetails
        id={transaction.id}
        createdAt={transaction.createdAt}
        docId={transaction.docId}
        description={transaction.description || ''}
        storeName={transaction.storeName}
        total={transaction.total}
        employee={transaction.cardholderName}
        cardId={transaction.cardId}
        storeId={transaction.storeId}
        storeLogoUrl={(transaction as any).storeLogoUrl}
        closeModal={modals.closeAllModals}
        updateTransaction={() => {
          console.log('f');
        }}
      />,
    );
  };

  return (
    <div>
      <div className={classes.latestTransactions}>Latest transactions</div>
      {transactions
        .filter((t, i) => i < visibleCount)
        .map((t, i) => (
          <TransactionDetailRow
            transaction={t as any}
            onClick={onTransactionClick}
            key={t.id}
            showBottomBorder={i < visibleCount - 1}
            customStyles={{
              borderTopLeftRadius: i === 0 ? '8px' : '',
              borderTopRightRadius: i === 0 ? '8px' : '',
              borderBottomLeftRadius: i === visibleCount - 1 ? '8px' : '',
              borderBottomRightRadius: i === visibleCount - 1 ? '8px' : '',
            }}
          />
        ))}
      {years.map((y) => (
        <TransactionYearRow
          year={y}
          totalTransactions={getTotalTransactionsInYear(y)}
          onClick={showTransactionsForYear}
          key={y}
        />
      ))}
    </div>
  );
};

export default CreditTransactionsSm;
