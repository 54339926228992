import { atom, AtomEffect, DefaultValue } from 'recoil';
import { IAuthenticatedUser } from './authenticated-user';
import { recoilPersist } from 'recoil-persist';
import authenticatedStorage from './authenticated-storage';
import { createEmptyAuthenticationToken } from '@flexbase/http-client-middleware';
import { hotjar } from 'react-hotjar';
import { getEnvironment } from 'utilities/url/window-helpers';

const hostEnv = getEnvironment(window.location.host);

const { persistAtom } = recoilPersist({
  key: 'flexbase_user',
  storage: authenticatedStorage,
});

const hotjarEffect: AtomEffect<IAuthenticatedUser> = ({ onSet }) => {
  onSet(({ user }, oldValue) => {
    const previousUserId =
      oldValue instanceof DefaultValue ? '' : oldValue.user?.userId || '';

    if (user && user.userId !== previousUserId) {
      hotjar.identify(user.email, { user });
    }
  });
};

/**
 * @deprecated Do not use. For token, email, and auth access, `useAuthToken()`. For user id, `useRecoilValue(UserIdState)`.
 */
export const AuthenticatedUserState = atom<IAuthenticatedUser>({
  key: 'AuthenticatedUserState',
  default: {
    user: null,
    scopes: [],
    token: createEmptyAuthenticationToken(),
    rememberUsername: false,
    username: '',
    logoutOn: '',
  },
  effects:
    hostEnv === 'production' ? [persistAtom, hotjarEffect] : [persistAtom],
});
