import { Button } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';
import ModalForm from '../modal-form/modal-form';
import useModal from 'components/modal/modal-hook';

interface IPatronInfo {
  id?: string;
  name?: string;
  address?: string;
  tenantId?: string;
  imageUrl?: string;
  city?: string;
  companyId?: string;
  country?: string;
  state?: string;
  postalCode?: string;
}

interface ItableRow {
  patron: IPatronInfo;
  setAllPatrons: Dispatch<SetStateAction<IPatronInfo[]>>;
}

const TableRow = ({ patron, setAllPatrons }: ItableRow) => {
  const modal = useModal();
  const closeModal = modal.closeAllModals;

  const openModalForm = () => {
    screen.width <= 767
      ? modal.openCenterModal(
          <ModalForm {...{ closeModal, patron, setAllPatrons }} />,
        )
      : modal.openRightModal(
          <ModalForm {...{ closeModal, patron, setAllPatrons }} />,
        );
  };

  return (
    <>
      <tr key={patron.id}>
        <td data-title="Name">{patron.name}</td>
        <td data-title="Address">{patron.address}</td>
        <td data-title="City">{patron.city}</td>
        <td data-title="Postal Code">{patron.postalCode}</td>
        <td>
          <Button
            onClick={() => {
              openModalForm();
            }}
            radius={8}
            id="edit-client"
            variant="outline"
            aria-label="edit-client"
            color="gray"
            size="md"
            className="btnEditClient"
          >
            Edit Client
          </Button>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
