import { PasswordInput, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import flexbaseClient from 'services/flexbase-client';
import { useQuery } from 'utilities/url/query-param';
import { showNotification } from '@mantine/notifications';
import PasswordStrengthPopover from './password-strength-popover';
import AuthContainer from 'components/login/auth-page';
import AuthContent from 'components/login/auth-content';
import { passwordRegex } from 'utilities/validators/validate-password';
import AuthLoader from 'components/login/auth-loader';

type PasswordResetForm = {
  password: string;
  confirmPassword: string;
};

const PasswordResetPage = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const query: URLSearchParams = useQuery();
  const navigator = useNavigate();

  const resetForm = useForm<PasswordResetForm>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (val) =>
        passwordRegex.test(val)
          ? null
          : 'Password must be at least 8 characters, contain 1 lowercase letter, 1 uppercase letter, a number and a symbol',
      confirmPassword: (val, values) =>
        val === values?.password ? null : 'Passwords do not match',
    },
  });

  const doReset = async () => {
    setErrorMsg('');
    const validateForm = resetForm.validate();
    if (!validateForm.hasErrors) {
      try {
        setLoading(true);
        const formValues = resetForm.values;
        const response = await flexbaseClient.resetPassword(
          formValues.password,
          query.get('token')!,
        );
        if (!response) {
          setErrorMsg(
            'We had an issue resetting your password. Please try again!',
          );
        } else {
          showNotification({
            color: 'flexbase-teal',
            message:
              "Congrats, you've reset your password! Please login to continue.",
            autoClose: 4000,
          });
          navigator('/login', { replace: true });
        }
      } catch (error) {
        setErrorMsg(
          'We had an issue resetting your password. Please try again!',
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthContainer>
      {loading && <AuthLoader />}
      <AuthContent
        errorMsg={errorMsg}
        onSubmit={doReset}
        title="Reset password"
        screen="reset-password"
        submitButtonLabel="Reset password"
        onGoback={() => navigator('/login')}
      >
        <PasswordStrengthPopover inputValue={resetForm.values.password}>
          <PasswordInput
            placeholder="Create a password"
            {...resetForm.getInputProps('password')}
            id="password"
            label="New Password"
            classNames={classes}
          />
        </PasswordStrengthPopover>
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm password"
          value={resetForm.values.confirmPassword}
          onChange={(event) =>
            resetForm.setFieldValue(
              'confirmPassword',
              event.currentTarget.value,
            )
          }
          error={
            resetForm.errors.confirmPassword &&
            'Password confirmation needs to match'
          }
          classNames={classes}
        />
      </AuthContent>
    </AuthContainer>
  );
};

export default PasswordResetPage;

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.colors.critical[2],
  },
}));
