import { useEffect } from 'react';
import useModal from 'components/modal/modal-hook';
import SendPayment from './send-payment';

const SendPaymentPage = () => {
  const { openTransparentModal } = useModal();

  useEffect(() => {
    openTransparentModal(<SendPayment />);
  }, []);

  return <></>;
};

export default SendPaymentPage;
