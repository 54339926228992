import { StyleSheet, Font } from '@react-pdf/renderer';
import RedactionRegular from 'assets/fonts/Redaction-Regular.otf';
import PPNeueMontrealBold from 'assets/fonts/PPNeueMontreal-Bold.otf';
import { defaultStylesPDF, Colors } from 'components/pdf-viewer/styles';
import PPNeueMontrealMedium from 'assets/fonts/PPNeueMontreal-Medium.otf';
import PPNeueMontrealRegular from 'assets/fonts/PPNeueMontreal-Regular.otf';
import { createStyles } from '@mantine/core';

export const stylesPDF = ({
  neutral,
  textColor,
  primaryDark,
  borderRadius,
}: Colors) => {
  const defaultStyles = defaultStylesPDF({
    primaryDark,
    neutral,
    borderRadius,
    textColor,
  });

  Font.register({
    family: 'Redaction',
    fonts: [
      { src: RedactionRegular }, // font-style: normal, font-weight: normal
    ],
  });

  Font.register({
    family: 'PP Neue Montreal',
    fonts: [
      { src: PPNeueMontrealMedium }, // font-style: normal, font-weight: normal
      { src: PPNeueMontrealBold, fontStyle: 'bold' },
      { src: PPNeueMontrealRegular, fontStyle: 'thin' },
    ],
  });

  return StyleSheet.create({
    ...defaultStyles,
    text: {
      fontSize: '10px',
      color: textColor,
      fontFamily: 'PP Neue Montreal',
    },
    cardStatementContainer: {
      ...defaultStyles.card,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 20px',
    },
    textTerms: {
      fontSize: '10px',
      color: primaryDark,
      fontFamily: 'PP Neue Montreal',
    },
    containerPage: {
      padding: '0px 25px',
      marginTop: 28,
      width: '100%',
      height: '90vh',
    },
    containerPageTerms: {
      width: '100%',
      height: '100vh',
      marginTop: 10,
      padding: '0px 30px',
    },
    container: {
      borderRadius,
      border: `1px solid ${neutral}`,
      padding: 20,
      marginTop: 20,
    },
    containerData: {
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
    },

    tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },

    textTermsBold: {
      fontSize: 10,
      color: primaryDark,
      fontStyle: 'bold',
      fontFamily: 'PP Neue Montreal',
    },

    page: {
      flexDirection: 'column',
      backgroundColor: '#E4E4E4',
    },
    content: {
      padding: 20,
    },
    header: {
      marginBottom: 10,
      textAlign: 'center',
    },
    monthName: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    dayCell: {
      width: 13,
      height: 13,
      fontSize: 6,
      padding: 2,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: '100%',
      fontFamily: 'PP Neue Montreal',
      margin: 1,
    },
    containerCalendar: {
      ...defaultStyles.card,
      padding: '10px',
      width: 130,
      height: 120,
    },
    blackDot: {
      width: 15,
      backgroundColor: '#5F5F5F',
      height: 15,
      marginRight: 8,
      borderRadius: '100%',
      fontFamily: 'PP Neue Montreal',
    },
    lightDot: {
      width: 15,
      backgroundColor: '#D9D9D9',
      height: 15,
      marginRight: 8,
      borderRadius: '100%',
      fontFamily: 'PP Neue Montreal',
    },
    descriptionDots: {
      fontSize: 10,
      fontStyle: 'thin',
      fontFamily: 'PP Neue Montreal',
    },
    calendarTitle: {
      fontSize: 6,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'PP Neue Montreal',
    },
    containerCalendarDays: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    descriptionHowPaymentsWork: {
      fontSize: 9,
      marginTop: 10,
      fontStyle: 'thin',
      fontFamily: 'PP Neue Montreal',
    },
  });
};

export const PDFStyles = createStyles(() => ({
  downloadContainer: {
    position: 'absolute',
    right: '6rem',
    backgroundColor: '#323639',
    top: '0.7rem',
    width: '2.4rem',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 100,
    height: '2.4rem',
    alignItems: 'center',
    ':hover': {
      background: '#46484b',
    },
  },
}));
