import { createStyles } from '@mantine/core';

interface Props {
  searchFocused?: boolean;
}

export const useStyles = createStyles((theme, { searchFocused }: Props) => ({
  baseContainer: {
    paddingBottom: 24,
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    paddingBottom: 13,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '> *': {
      marginLeft: '1rem',
    },
  },
  filterListContainer: { display: 'flex' },

  title: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#5F5F5F',
  },
  searchIcon: {
    color: searchFocused ? theme.fn.primaryColor() : '#979797',
  },
  actions: {
    padding: '10px',
    width: '252px',
    height: '40px',
    opacity: 0.8,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': { borderColor: theme.fn.primaryColor() },
    '&[aria-expanded="true"]': { borderColor: theme.fn.primaryColor() },
  },
  actionsSm: {
    height: '40px',
    opacity: 0.8,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    '&:hover': { borderColor: theme.fn.primaryColor() },
    '&[aria-expanded="true"]': { borderColor: theme.fn.primaryColor() },
  },
  sync: {
    gap: '8px',
    height: '40px',
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    borderRadius: theme.defaultRadius,
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'white',
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
    padding: '10px 15px 10px 12px',
    '&:disabled': {
      background: '#F9FAFA !important',
      border: '1px solid #F0F0F0 !important',
      color: '#BCBCBC !important',

      svg: {
        fill: '#BCBCBC !important',
      },
    },
  },
  review: {
    gap: '8px',
    height: '40px',
    border: '1px solid #F0F0F0',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'white',
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
    padding: '10px 15px 10px 12px',
    '&:disabled': {
      background: '#F9FAFA !important',
      border: '1px solid #F0F0F0 !important',
      color: '#BCBCBC !important',

      svg: {
        fill: '#BCBCBC !important',
      },
    },
  },
  textSearch: {
    '&:focus': {
      borderColor: theme.fn.themeColor('primarySecondarySuccess', 8),
    },
  },
  popoverDropdown: {
    border: `1px solid ${theme.fn.themeColor('primarySecondarySuccess', 8)}`,
  },
}));

export const CustomMantineStyles = (primaryColor?: string) => {
  return {
    searchTransactions: {
      input: {
        color: '#5F5F5F',
        width: '368px',
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '2px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        background: 'white',
        '&:focus': { borderColor: primaryColor },
      },
    },
    searchTransactionsSm: {
      input: {
        color: '#5F5F5F',
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '2px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        background: 'white',
        '&:focus': { border: `1px solid ${primaryColor}` },
      },
    },
    actions: {
      root: {
        width: '252px',
        height: '40px',
        '&:hover': { cursor: 'pointer' },
      },
      item: {
        opacity: 0.8,
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 500,
        color: '#5F5F5F',
        background: 'white',
        cursor: 'pointer',
        '&:hover': {
          border: 'none',
          background: 'rgba(255, 87, 69, 0.08);',
        },
      },
    },
    actionsSm: {
      input: {
        height: '40px',
        opacity: 0.8,
        border: '1px solid #E6E7E9',
        borderRadius: '2px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
        color: '#5F5F5F',
        background: 'white',
        '&:focus': { borderColor: primaryColor },
      },
    },
  };
};
