import { ReactNode, useState } from 'react';
import { createStyles, Menu } from '@mantine/core';
import { IoEllipsisVertical } from 'react-icons/io5';
import { FloatingPosition } from '@mantine/core/lib/Floating';

type Props = { children: ReactNode; position?: FloatingPosition };

export const MenuCell = ({ children, position = 'bottom' }: Props) => {
  const { classes, cx } = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Menu
      classNames={{
        item: cx(classes.inlineCell, classes.menuItem),
        dropdown: classes.dropdown,
      }}
      opened={menuOpen}
      onChange={(open) => setMenuOpen(open)}
      position={position}
    >
      <Menu.Target>
        <div className={classes.menuEllipsisContainer}>
          <IoEllipsisVertical />
        </div>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
};

const useStyles = createStyles({
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    flexShrink: 0,
  },
  menuItem: {
    width: '200px',
  },
  menuEllipsisContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
  },
  dropdown: {
    position: 'fixed',
    '@media(max-width: 767px)': {
      position: 'absolute',
    },
  },
});

export default MenuCell;
