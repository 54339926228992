import { Text, TextInput, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import flexbaseClient from 'services/flexbase-client';
import { EmailValidator } from 'utilities/validators/validate-email';
import AuthContainer from 'components/login/auth-page';
import AuthContent from 'components/login/auth-content';
import AuthLoader from 'components/login/auth-loader';

const MagicLinkPage = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  const forgotPasswordForm = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: EmailValidator(
        <div data-testid="error-message">Invalid email address</div>,
      ),
    },
  });

  const errorSupportMessage =
    'We are unable to locate your account, please check your email for typos and try again or contact support at support@flex.one';

  const sendMagicLink = async () => {
    setErrorMsg('');
    setSuccessMsg('');
    const validate = forgotPasswordForm.validate();
    if (!validate.hasErrors) {
      try {
        setLoading(true);
        const response = await flexbaseClient.forgotPassword(
          forgotPasswordForm.values.email,
        );
        setLoading(false);
        if (!response.success) {
          setErrorMsg(errorSupportMessage);
        } else {
          setSuccessMsg(
            'A magic link has been sent to your email! Please use this link to reset your password.',
          );
        }
      } catch (error) {
        setErrorMsg(errorSupportMessage);
      }
    }
  };

  const onSubmit = () => {
    if (successMsg) {
      navigate('/login');
    } else {
      sendMagicLink();
    }
  };

  return (
    <AuthContainer>
      {loading && <AuthLoader />}
      <AuthContent
        screen="magic-link"
        errorMsg={errorMsg}
        onSubmit={onSubmit}
        onGoback={() => navigate('/login')}
        title={successMsg ? 'Magic link sent!' : 'Magic Link'}
        submitButtonLabel={successMsg ? 'Back to log in' : 'Send magic link'}
      >
        {successMsg ? (
          <Text size="sm" color="neutral.0" data-testid={'success-message'}>
            {successMsg}
          </Text>
        ) : (
          <form onSubmit={forgotPasswordForm.onSubmit(() => sendMagicLink())}>
            <TextInput
              label="Email"
              classNames={classes}
              placeholder="youremail@gmail.com"
              {...forgotPasswordForm.getInputProps('email')}
              data-testid={'email'}
            />
          </form>
        )}
      </AuthContent>
    </AuthContainer>
  );
};

export default MagicLinkPage;

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.fn.themeColor('neutral', 0),
  },
  error: {
    color: theme.colors.critical[2],
  },
}));
