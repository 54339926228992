import { createStyles } from '@mantine/core';

export const actionModalStyles = createStyles(() => ({
  btnCloseModal: {
    '&:focus': {
      outline: 'none',
    },
    color: 'gray',
    position: 'absolute',
    top: '20px',
    left: '20px',
    borderWidth: 0,
    cursor: 'pointer',
  },

  buttonContainer: {
    display: 'flex',
    position: 'relative',
    marginTop: '5%',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
}));
