import {
  Anchor,
  Button,
  createStyles,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IoMdCheckmark } from 'react-icons/io';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { DownloadIcon } from 'assets/svg';

const useStyles = createStyles((theme) => ({
  successContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    height: 520,
  },
  checkCircle: {
    background: 'rgba(39, 194, 129, 0.1)',
    width: 72,
    height: 72,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    color: '#27C281',
    fontSize: 40,
  },
  infoText: {
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '40px', // In this case using a number does not set it to px..
    textAlign: 'center',
  },
  buttons: {
    borderRadius: theme.defaultRadius,
    width: '100%',
  },
  downloadButton: {
    background: '#FFF',
    borderRadius: '8px',
    width: '100%',
    border: `1px solid ${theme.fn.primaryColor()}`,
    padding: '10px 5px',
    color: theme.fn.primaryColor(),
    '&:hover': {
      backgroundColor: 'rgba(255, 87, 69, 0.1)',
    },
  },
  downloadReceiptText: {
    color: theme.fn.primaryColor(),
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineheight: '16px',
  },
  description: {
    fontSize: 14,
    color: '#5F5F5F',
    textAlign: 'center',
    marginTop: '2vh',
  },
}));

type Props = {
  paymentId: string;
  paymentAmount: number;
  onCloseClick: () => void;
};

const PaymentSuccess = ({ paymentId, onCloseClick, paymentAmount }: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <div className={classes.successContainer}>
      <div className={classes.checkCircle}>
        <IoMdCheckmark className={classes.checkmark} />
      </div>
      <Text className={classes.infoText} data-testid="successful-payment-text">
        You&apos;ve paid {formatCurrency(paymentAmount)} to your credit card.
        <Text className={classes.description}>
          Processing of your payment may take up to 4 business days, after which
          your credit balance will be updated upon successful settlement.
        </Text>
      </Text>

      <div className={classes.buttons}>
        <div style={{ width: '100%', display: 'flex' }}>
          <Anchor
            className={classes.downloadButton}
            href={`/payment/card-receipt/${paymentId}`}
            underline={false}
            target="_blank"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DownloadIcon
                color={theme.fn.primaryColor()}
                width={20}
                height={20}
              />
              <Text ml="0.5vh">Download confirmation receipt</Text>
            </div>
          </Anchor>
        </div>
        <Button
          mt="1rem"
          size="lg"
          fullWidth
          onClick={() => onCloseClick()}
          data-testid="go-back-to-credit-button"
        >
          Go back to credit
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
