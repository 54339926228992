import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  spentWeekContainer: {
    position: 'relative',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: '200px',
    width: '420px',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
    border: `1px solid ${theme.fn.themeColor('neutral', 0)}`,
    '&:hover': {
      border: `1px solid ${theme.fn.primaryColor()}`,
    },
    cursor: 'pointer',
  },
  spentWeekLabel: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#5F5F5F',
  },
  totalSpentWeek: {
    marginTop: '8px',
    fontSize: '26px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '31px',
    color: 'black',
    fontFamily: 'PP Neue Montreal',
  },
  totalSpentWeekContainer: {
    marginTop: '5rem',
    borderColor: '#c8d6e5',
    width: '420px',
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
    margin: '1.5rem',
  },
  purchasesText: {
    fontSize: '16px',
    lineHeight: '19px',
    color: '#A2A2A2',
    fontWeight: 500,
  },
}));
