import { useFormStyles } from './form.styles';
import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validateUSPhoneNumber } from 'utilities/validators/validate-phone-number';
import { formatUSPhoneNumber } from 'utilities/formatters/format-phone-number';
import FormBase from './form-base';

type Props = {
  onClose: () => void;
  onSubmit: (phoneNumber: string) => void;
  currentValue?: string;
};

const PhoneForm = ({ onClose, onSubmit, currentValue }: Props) => {
  const { classes } = useFormStyles();
  const form = useForm({
    initialValues: { phone: formatUSPhoneNumber(currentValue || '') },
    validate: {
      phone: (val) =>
        validateUSPhoneNumber(val) ? null : 'Please enter a valid phone number',
    },
  });

  const setPhoneNumber = (value: string) =>
    form.setFieldValue('phone', formatUSPhoneNumber(value));

  const onUpdateClick = () => {
    const validationResult = form.validate();

    if (!validationResult.hasErrors) {
      onSubmit(form.values.phone);
    }
  };

  return (
    <FormBase onCancel={onClose} onSubmit={onUpdateClick}>
      <TextInput
        classNames={{ label: classes.inputLabel, input: classes.input }}
        label={'Phone number'}
        data-testid="phone-input"
        type="tel"
        size="lg"
        {...form.getInputProps('phone')}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
    </FormBase>
  );
};

export default PhoneForm;
