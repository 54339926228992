import { useCallback, useEffect, useState } from 'react';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { showNotification } from '@mantine/notifications';
import SkeletonLoading from 'components/loading/skeleton-loading';
import FlexbaseTable from 'components/table/flexbase-table';

import { TableColumn } from 'react-data-table-component';
import GetPaddedAccountMask from 'utilities/formatters/get-padded-account-mask';
import { IoEllipsisVertical } from 'react-icons/io5';
import {
  Avatar,
  Button,
  Group,
  Menu,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import {
  ArrowsHorizontalIcon,
  EditIcon,
  EyeClosedIcon,
  EyeOpenIcon,
  PlusSignIcon,
  SearchIcon,
  UnlinkIcon,
} from 'assets/svg';
import { overrideStyles, useStyles } from './external-accounts.styles';
import { usePlaidBankingComponent } from 'components/banking/plaid-banking-component';
import { OnSuccessProps } from 'states/plaid/plaid-state';
import { FinancialInstitution } from 'states/onboarding/onboarding-info';
import { useRecoilValue } from 'recoil';
import { PlaidAccount } from '../move-funds/move-funds.model';
import { useMediaQuery } from '@mantine/hooks';
import { UserInfoState } from 'states/user/user-info';
import { useNavigate } from 'react-router-dom';
import { CompanySelector } from 'states/application/product-onboarding';
import { getInitialsOfNames } from 'utilities/extensions/object';

const AccountMaskCell = ({ accountNumber }: { accountNumber: string }) => {
  const { classes } = useStyles();
  const [hideAccount, setHideAccount] = useState(true);
  return (
    <div
      className={classes.inlineCell}
      onClick={() => setHideAccount((p) => !p)}
      style={{ cursor: 'pointer' }}
    >
      <div className={classes.contentText}>
        {hideAccount ? GetPaddedAccountMask(accountNumber, 5) : accountNumber}
      </div>
      {hideAccount ? (
        <EyeOpenIcon color="#94928F" width={15} height={15} />
      ) : (
        <EyeClosedIcon color="#94928F" width={15} height={15} />
      )}
    </div>
  );
};
const MenuCell = ({
  onEditClick,
  onUnlinkClink,
}: {
  onEditClick: () => void;
  onUnlinkClink: () => void;
}) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const unlinkFeatureAvailable = false; // set this temporarily until endpoint is available

  return (
    <div className={classes.inlineCell}>
      <Menu
        opened={menuOpen}
        onChange={(open) => setMenuOpen(open)}
        styles={{
          dropdown: {
            ...overrideStyles().menu.root,
            ...overrideStyles().menu.body,
          },
          item: overrideStyles().menu.itemInner,
        }}
      >
        <Menu.Target>
          <div className={classes.menuEllipsisContainer}>
            <IoEllipsisVertical
              size={20}
              style={{
                cursor: 'pointer',
                color: menuOpen ? theme.fn.primaryColor() : '',
              }}
            />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<EditIcon />} onClick={onEditClick}>
            Edit account name
          </Menu.Item>
          {unlinkFeatureAvailable && (
            <Menu.Item icon={<UnlinkIcon />} onClick={onUnlinkClink}>
              Unlink account
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

const checkAccounts = async (id: string): Promise<PlaidAccount[]> => {
  // pull onboarding to get plaid account details
  let accounts: PlaidAccount[] = [];
  try {
    const response = await flexbaseOnboardingClient.getPlaidCompanyAccounts();
    if (response.success && response.accounts) {
      response.accounts.forEach((account) => {
        accounts.push({
          ...account,
          plaidOrDeposit: 'plaid',
        });
      });
    } else {
      accounts = [];
    }
  } catch {
    accounts = [];
    showNotification({
      color: 'red',
      title: 'Error',
      message:
        'We were unable to retrieve your external accounts at this time. Please try again later.',
    });
  }
  return accounts;
};

const ExternalAccounts = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<PlaidAccount[]>([]);
  const [filterText, setFilterText] = useState('');
  const { id } = useRecoilValue(CompanySelector);
  const isMobileScreenSize = useMediaQuery('(max-width: 767px)');
  const user = useRecoilValue(UserInfoState);

  const getExternalAccts = async () => {
    setLoading(true);
    let accounts: PlaidAccount[];

    try {
      // pull onboarding to get plaid account details
      accounts = await checkAccounts(id);
    } catch {
      showNotification({
        color: 'red',
        title: 'Error',
        message:
          'We were unable to retrieve your external accounts at this time. Please try again later.',
      });
      accounts = [];
      return;
    } finally {
      setLoading(false);
    }

    setTableData(accounts);
    setLoading(false);
  };

  useEffect(() => {
    getExternalAccts();
  }, []);

  const getFilteredTableData = useCallback(() => {
    const normalizedFilterText = filterText.trim().toLowerCase();
    // return tableData;
    return tableData.filter((d) => {
      return (
        // d.nickName?.toLowerCase().includes(normalizedFilterText) ||
        // d.response.data.attributes.accountNumber.startsWith(filterText)
        d.accountName?.toLowerCase().includes(normalizedFilterText) ||
        d.bankName?.toLowerCase().includes(normalizedFilterText) ||
        d.routing!.includes(normalizedFilterText)
      );
    });
  }, [filterText, tableData]);

  const comingSoonClick = () => {
    showNotification({
      color: 'flexbase-teal',
      title: 'Coming soon!',
      message: 'This functionality will be available shortly.',
    });
  };

  const columns: TableColumn<FinancialInstitution>[] = [
    {
      name: 'Account',
      sortable: true,
      cell: (account) => (
        <Group>
          <Avatar>
            {getInitialsOfNames(account.bankName || 'Plaid Account')}
          </Avatar>
          <div className={classes.tableCell}>
            <div className={classes.contentText}>{account.bankName}</div>
            <div className={classes.smallContentText}>
              {account.accountType || 'Plaid Account'}
            </div>
          </div>
        </Group>
      ),
      grow: 2,
    },
    {
      name: 'Routing number',
      style: {
        paddingLeft: '20px',
      },
      cell: (account) => (
        <div className={classes.tableCell}>
          <div className={classes.contentText}>{account.routing}</div>
        </div>
      ),
    },
    {
      name: 'Account number',
      style: {
        paddingLeft: '20px',
      },
      cell: (account) => <AccountMaskCell accountNumber={account.account} />,
    },
    {
      name: '',
      right: true,
      cell: (account) => (
        <div
          className={classes.inlineCell}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (user.roles.includes('ADMIN')) {
              navigate('/banking/move-funds', { state: { account } });
            }
          }}
        >
          <div className={classes.contentText}>Transfer funds</div>
          <ArrowsHorizontalIcon color={theme.fn.themeColor('neutral', 5)} />
        </div>
      ),
    },
    {
      name: '',
      cell: (account) => (
        <MenuCell
          onEditClick={comingSoonClick}
          onUnlinkClink={comingSoonClick}
        />
      ),
      right: true,
    },
  ];

  const columnsSm: TableColumn<FinancialInstitution>[] = [
    {
      sortable: true,
      name: 'Account',
      cell: (account) => (
        <div className={classes.tableCell}>
          <div className={classes.contentText}>{account.bankName}</div>
          <div className={classes.smallContentText}>
            <AccountMaskCell accountNumber={account.account} />
            {account.accountType || 'Plaid Account'}
          </div>
        </div>
      ),
      selector: (account) => account.bankName!,
      grow: 2,
    },
    {
      name: 'Routing number',
      cell: (account) => (
        <div className={classes.tableCell}>
          <div className={classes.contentText}>{account.routing}</div>
        </div>
      ),
      compact: true,
      right: true,
    },
  ];

  // on success of plaid link, make the counterparty link call to unitco
  const onSuccess = async (data: OnSuccessProps) => {
    showNotification({
      color: 'flexbase-teal',
      title: 'Plaid Link successful!',
      message: 'Plaid is now linked to fund your new Flexbase deposit account.',
    });
    setTableData(await checkAccounts(id));
  };

  const onError = () => {
    showNotification({
      color: 'red',
      title: 'Error linking Plaid',
      message:
        'Plaid linking is unavailable at this time, please try again later!',
    });
  };

  const { open } = usePlaidBankingComponent({
    onSuccess,
    onError,
    setLoading,
  });

  return (
    <>
      {loading ? (
        <SkeletonLoading />
      ) : (
        <div className={classes.container}>
          <TextInput
            w={500}
            icon={<SearchIcon />}
            placeholder="Search external accounts"
            onChange={(e) => setFilterText(e.target.value)}
          />
          <div className={classes.tableContainer}>
            <FlexbaseTable
              columns={isMobileScreenSize ? columnsSm : columns}
              data={getFilteredTableData()}
              pagination={
                getFilteredTableData() && getFilteredTableData()?.length > 8
              }
              isFetchingData={loading}
            />
            {
              // conditionally render button - if no plaid account exists then allow user to link 1 account.
              tableData.length === 0 && (
                <Button
                  variant="outline"
                  size="lg"
                  leftIcon={<PlusSignIcon />}
                  styles={overrideStyles(theme.fn.primaryColor()).button}
                  onClick={() => {
                    open();
                  }}
                >
                  Link a Bank Account
                </Button>
              )
            }
          </div>
        </div>
      )}
    </>
  );
};

export default ExternalAccounts;
