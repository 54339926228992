import { TextInput, Text, Avatar, Menu, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import FlexbaseTable from 'components/table/flexbase-table';
import { FilterIcon, SearchIcon } from 'assets/svg';
import { useStyles, CustomMantineStyles } from './vendor.styles';
import { ReactComponent as RowAction } from 'assets/svg/vertical-action-buttons.svg';
import { getInitialsOfNames } from 'utilities/extensions/object';
import { MdOutlinePersonRemoveAlt1 } from 'react-icons/md';
import ProjectDetails from '../details/details';
import useModal from 'components/modal/modal-hook';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const Vendors = () => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [debitCards] = useState<any[]>([
    {
      name: 'Home Depot',
      contact: 'Max Luigi',
      email: 'maximillion@gmail.com',
      cellPhone: '(772) 844-4422',
    },
    {
      name: `Lowe's Depot`,
      contact: 'Ann Smith',
      email: 'annsmith@gmail.com',
      cellPhone: '(281) 603-4325',
    },
    {
      name: `Lowe's Depot`,
      contact: 'Manuela Gil',
      email: 'manuela@gmail.com',
      cellPhone: '(281) 502-5669',
    },
  ]);
  const [loading] = useState(false);
  const [searchCard, setSearchCard] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const modal = useModal();

  const columns = [
    {
      name: 'Vendor Name',
      cell: (row: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar color="red" radius="md">
            {getInitialsOfNames(row.name)}
          </Avatar>
          <div style={{ marginLeft: '12px' }}>
            <Text>{row.name}</Text>
          </div>
        </div>
      ),
      width: '30%',
      selector: (row: { name: string }) => row.name,
      sortable: true,
    },
    {
      name: 'Contact',
      cell: (row: { contact: string }) => row.contact,
    },
    {
      name: 'Email',
      cell: (row: { email: string }) => row.email,
    },
    {
      name: 'Number',
      cell: (row: { cellPhone: string }) => row.cellPhone,
    },
    {
      grow: 1,
      right: true,
      cell: (row: { id: string; name: string }) => (
        <Menu
          styles={{
            item: CustomMantineStyles().actions.item,
            dropdown: { width: '120px', borderRadius: '8px' },
          }}
          position={'bottom'}
        >
          <Menu.Target>
            <RowAction className={classes.btnOptions} />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              styles={{ fontSize: '14px' }}
              icon={<MdOutlinePersonRemoveAlt1 />}
              data-testid={`test-1-${row.name}`}
            >
              Test 1
            </Menu.Item>
            <Menu.Item
              styles={{ fontSize: '14px' }}
              icon={<MdOutlinePersonRemoveAlt1 />}
              data-testid={`test-2-${row.name}`}
            >
              Test 2
            </Menu.Item>
            <Menu.Item
              disabled
              styles={{ fontSize: '14px' }}
              icon={<MdOutlinePersonRemoveAlt1 />}
              data-testid={`test-3-${row.name}`}
            >
              Test 3
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
      compact: true,
    },
  ];

  const onRowClicked = () => {
    modal.openRightModal(<ProjectDetails type="vendor" />);
  };

  return (
    <div className={classes.containerTable}>
      <div className={classes.containerSearch}>
        <Text className={classes.tableTitle}>Vendors</Text>
        <div className={classes.searchInput}>
          <TextInput
            radius={8}
            color="red"
            styles={{
              input: CustomMantineStyles(theme.fn.primaryColor()).searchContacts
                .input,
            }}
            icon={<SearchIcon width={20} height={20} />}
            value={searchCard}
            onChange={(e) => setSearchCard(e.target.value)}
            placeholder="Search contacts"
            type="search"
            rightSection={
              screen.width > 767 && (
                <div style={{ cursor: 'pointer' }}>
                  <FilterIcon
                    style={{ paddingRight: '8px', marginTop: 8 }}
                    fill={'#979797'}
                    width={'25px'}
                    height={'25px'}
                    color="#5f5f5f"
                  />
                </div>
              )
            }
          />
          <div style={{ marginLeft: '1rem' }}>
            <Menu
              styles={{
                item: CustomMantineStyles().actions.item,
                dropdown: {
                  width: '120px',
                  borderRadius: '8px',
                },
              }}
              opened={menuOpen}
              onChange={(open) => setMenuOpen(open)}
            >
              <Menu.Target>
                <div className={classes.actionsSm}>
                  <div>Actions</div>
                  {menuOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item disabled>
                  <div>Sync with Quickbooks</div>
                </Menu.Item>
                <Menu.Item>Download CSV</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </div>
      <FlexbaseTable
        columns={columns}
        data={debitCards}
        isFetchingData={loading}
        selectableRows={true}
        onRowClicked={onRowClicked}
      />
    </div>
  );
};

export default Vendors;
