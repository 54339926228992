import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  cancelDebitCard: {
    margin: '0px',
    padding: '40px',
    minHeight: '100vh',
    borderRadius: '8px',
    backgroundColor: '#FAFAFA',
    '.close': {
      display: 'grid',
      justifyContent: 'end',
      alignContent: 'baseline',
      p: {
        margin: '0px',
        color: '#979797',
        textAlign: 'center',
      },
    },
    '.content': {
      display: 'flex',
      marginTop: '20px',
      height: 'fit-content',
      justifyContent: 'center',
    },
    '.error': {
      display: 'flex',
      padding: '16px',
      fontSize: '14px',
      color: '#991B1B',
      margin: '30px',
      borderRadius: '8px',
      alignItems: 'center',
      backgroundColor: '#FEF2F2',
      svg: {
        marginRight: '10px',
      },
    },
    '@media(max-width: 767px)': {
      padding: '20px',
      '.close-button': {
        width: '100%',
        display: 'flex',
        marginBottom: '15px',
        justifyContent: 'flex-end',
      },
    },
  },

  card: {
    width: '500px',
    padding: '34px',
    color: '#5F5F5F',
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },

  subtitle: {
    fontSize: '12px',
    color: '#5F5F5F',
  },

  title: {
    color: '#000',
    fontWeight: 400,
    fontSize: '28px',
    '@media(max-width: 767px)': {
      fontSize: '22px',
    },
  },

  optionBox: {
    gap: 10,
    margin: '5px',
    height: '70px',
    display: 'flex',
    padding: '20px',
    maxWidth: '47%',
    cursor: 'pointer',
    alignItems: 'center',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
    '&:hover': {
      backgroundColor: theme.fn.themeColor('neutral', 2),
    },
    '@media(max-width: 767px)': {
      maxWidth: '100%',
    },
  },

  optionText: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.fn.primaryColor(),
  },

  lightText: {
    color: theme.fn.themeColor('neutral', 0),
  },

  buttonsContainer: {
    display: 'flex',
    marginTop: '40px',
    justifyContent: 'flex-end',
  },

  debitCardInfoContent: {
    display: 'flex',
    alignItems: 'center',
    span: {
      color: '#000',
    },
    p: {
      margin: '0px',
    },
  },

  radioLabel: {
    fontSize: 16,
  },

  radioDescription: {
    fontSize: 14,
    color: '#5F5F5F',
  },

  circle: {
    width: '24px',
    height: '24px',
    display: 'flex',
    borderRadius: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    color: theme.fn.primaryColor(),
    backgroundColor: theme.fn.themeColor('neutral', 0),
    border: `1px solid ${theme.fn.themeColor('neutral', 1)}`,
  },
}));
