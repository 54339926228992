import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  actionButton: {
    margin: '5px',
    border: '1px solid #F2F3F7',
    width: 'auto',
    height: 'auto',
    borderRadius: '8px',
    background: '#FCFCFC',
    color: '#979797',
    padding: 0,
    '&:hover': {
      border: 'none',
      background: 'lightgray',
    },
    '&:disabled': {
      background: '#F9FAFA !important',
      border: '1px solid #F0F0F0 !important',
      color: '#BCBCBC !important',
    },
  },
  // Modal styles start here
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '521px',
    width: '100%',
    '> *:not(:last-child)': {
      marginBottom: '24px',
    },
  },
  filesContainer: {
    display: 'flex',
    minHeight: '50px',
    justifyContent: 'center',
  },
  file: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    border: '1px solid #E6E7E9',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#5F5F5F',
  },
  deleteFile: {
    all: 'unset',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropZonePlaceholderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropZonePlaceholder: {
    fontWeight: 400,
    fontSize: '13px',
    color: '#757575',
    '> span': {
      display: 'inline',
      color: theme.fn.primaryColor(),
    },
  },
  dropZone: {
    width: '100%',
    height: '80px',
    padding: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E2E7F0',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: 'rgba(57, 160, 255, 0.04)',
    },
  },
  dateInputs: {
    paddingBottom: '15px', // Fill space
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
  },
}));

export const useCustomInputStyles = createStyles((theme) => ({
  label: {
    fontSize: 16,
    marginBottom: 7,
    lineHeight: '19px',
    color: '#4B4B4B',
  },
  item: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5F5F5F',
    margin: '1px 0px',
    '&:hover': {
      border: 'none',
      background: 'rgba(255, 87, 69, 0.08);',
    },
  },
  rightSection: {
    pointerEvents: 'none',
  },
  selected: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'rgba(255, 87, 69, 0.08);',
  },
  dropdown: {
    borderRadius: 8,
  },
  datePicker: {
    lineHeight: '16px',
    '.mantine-DatePicker-input': {
      paddingLeft: 16,
      color: '#5F5F5F',
      fontWeight: 500,
      border: '1px solid #E6E7E9',
      ':focus': {
        border: `1px solid ${theme.fn.primaryColor()}`,
      },
      height: 50,
      borderRadius: 8,
    },
    '.mantine-DatePicker-label': {
      fontSize: 16,
      marginBottom: 9,
      color: '#4B4B4B',
    },
    '.mantine-DatePicker-rightSection': {
      right: 8,
      pointerEvents: 'none',
    },
  },
}));

export const useCustomInlineSelectStyles = createStyles((theme) => ({
  item: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5F5F5F',
    margin: '1px 0px',
    '&:hover': {
      border: 'none',
      background: 'rgba(255, 87, 69, 0.08);',
    },
  },
  rightSection: {
    pointerEvents: 'none',
  },
  selected: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5F5F5F',
    background: 'rgba(255, 87, 69, 0.08);',
  },
  dropdown: {
    borderRadius: 8,
  },
  root: {},
}));
