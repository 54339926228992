import React from 'react';
import { DateTime } from 'luxon';
import { MantineTheme } from '@mantine/core';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { stylesPDF } from '../styles-pdf';
import MonthlyTerms from './monthly-terms';
import TransactionDates from './transaction-dates';
import HeaderPDF from 'components/pdf-viewer/header';
import { StatementState } from './monthly-transaction-report';
import { LeftArrow, RightArrow } from 'components/pdf-viewer/icons';
import { customDate } from 'utilities/formatters/format-date-input';
import { formatCurrency } from 'utilities/formatters/format-currency';
import { OnboardingCompany } from 'states/onboarding/onboarding-info';

interface StatementeProps {
  dates: any;
  after: string;
  theme: MantineTheme;
  statement: StatementState;
  company: OnboardingCompany;
  renderCalendarDays: () => JSX.Element[];
}

const StatementDoc = ({
  after,
  theme,
  company,
  dates,
  statement,
  renderCalendarDays,
}: StatementeProps) => {
  const styles = stylesPDF({
    primaryDark: theme.colors.primarySecondarySuccess[8],
    neutral: theme.colors.neutral[2],
    textColor: theme.colors.neutral[6],
  });

  return (
    <Document
      title={`Flex Credit Card Monthly Statement ${DateTime.fromISO(
        after,
      ).toFormat('MM-yy')}`}
    >
      <Page wrap size="A4">
        <View fixed>
          <HeaderPDF {...{ theme }} />
        </View>
        <View style={styles.containerHeader}>
          <View>
            <Text
              style={{
                ...styles.textHeader,
                fontStyle: 'bold',
              }}
            >
              Flex Credit Card
            </Text>
            <Text style={styles.textHeader}>{company?.companyName}</Text>
            <Text style={styles.textHeader}>
              {company?.address.line1}, {company?.address.line2}
            </Text>
            <Text style={styles.textHeader}>
              {company?.address.city}, {company?.address.state} -{' '}
              {company?.address.postalCode}
            </Text>
          </View>
        </View>

        <View style={styles.containerPage}>
          <View>
            <Text style={{ ...styles.text, fontStyle: 'thin' }}>
              Let’s flex this.
            </Text>
            <Text style={styles.title}>Monthly Statements</Text>
          </View>

          <TransactionDates {...{ theme, dates }} />

          <View style={styles.container}>
            <View style={{ ...styles.containerData, marginTop: 0 }}>
              <Text style={styles.text}>Total Charges</Text>
              <Text style={{ ...styles.text, color: theme.fn.primaryColor() }}>
                {formatCurrency(statement?.totalCharges || 0)}
              </Text>
            </View>
            <View style={styles.containerData}>
              <Text style={styles.text}>Credits/Refunds</Text>
              <Text style={{ ...styles.text, color: theme.fn.primaryColor() }}>
                {formatCurrency(Math.abs(statement?.totalRefunds || 0))}
              </Text>
            </View>
            <View style={styles.containerData}>
              <Text style={styles.text}>Fees</Text>
              <Text style={{ ...styles.text, color: theme.fn.primaryColor() }}>
                {formatCurrency(statement?.totalFees || 0)}
              </Text>
            </View>

            <View style={styles.containerData}>
              <Text style={styles.text}>Interest</Text>
              <Text style={{ ...styles.text, color: theme.fn.primaryColor() }}>
                {formatCurrency(statement?.totalInterest || 0)}
              </Text>
            </View>

            <View style={{ ...styles.containerData, marginTop: 33 }}>
              <Text
                style={{
                  ...styles.text,
                  color: theme.fn.primaryColor(),
                  fontSize: '16px',
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  ...styles.text,
                  color: theme.fn.primaryColor(),
                  fontSize: '16px',
                }}
              >
                {formatCurrency(statement?.total || 0)}
              </Text>
            </View>
          </View>

          <View style={styles.container}>
            <View style={{ ...styles.containerData, marginTop: 0 }}>
              <Text
                style={{
                  ...styles.text,
                  color: theme.fn.primaryColor(),
                  fontSize: '10px',
                }}
              >
                Payments to credit balance
              </Text>
              <Text
                style={{
                  ...styles.text,
                  color: theme.fn.primaryColor(),
                  fontSize: '10px',
                }}
              >
                {formatCurrency(statement?.paymentsCreditBalance || 0)}
              </Text>
            </View>
          </View>

          <View style={styles.container}>
            <Text
              style={{ ...styles.descriptionHowPaymentsWork, fontSize: 16 }}
            >
              How does net-60 bill pay work?
            </Text>
            <View style={{ ...styles.containerData, marginTop: 10 }}>
              <View style={{ width: 190 }}>
                <Text style={styles.descriptionHowPaymentsWork}>
                  Use your Flex Credit Card between the 1st and 15th of the
                  month, enjoy 0% interest for 60+ days, then pay us back 2
                  months later for those transactions, on the 15th of the month.
                </Text>

                <Text style={styles.descriptionHowPaymentsWork}>
                  Use your Flex Credit Card between the 16th and the end of the
                  month, enjoy 0% interest for 60+ days, then pay us back 2
                  months later for those transactions on the last day of the
                  month.
                </Text>
              </View>
              <View style={styles.containerCalendar}>
                <View style={styles.tableContainer}>
                  <LeftArrow />
                  <Text style={styles.calendarTitle}>
                    {dates.calendarMonthAndYear}
                  </Text>
                  <RightArrow />
                </View>
                <View style={styles.containerCalendarDays}>
                  {renderCalendarDays()}
                </View>
              </View>

              <View>
                <View style={{ flexDirection: 'row' }}>
                  <View style={styles.blackDot} />
                  <View>
                    <Text style={styles.descriptionDots}>Purchases made</Text>
                    <Text
                      style={{ ...styles.descriptionDots, fontStyle: 'bold' }}
                    >
                      {dates.firstFortnight}
                    </Text>
                    <View style={styles.tableContainer}>
                      <Text style={styles.descriptionDots}>are due</Text>
                      <Text
                        style={{
                          ...styles.descriptionDots,
                          fontStyle: 'bold',
                        }}
                      >
                        {' '}
                        {dates.firstFortnightDue}
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: 'row', marginTop: 10 }}>
                  <View style={styles.lightDot} />
                  <View>
                    <Text style={styles.descriptionDots}>Purchases made</Text>
                    <Text
                      style={{ ...styles.descriptionDots, fontStyle: 'bold' }}
                    >
                      {dates.secondFortnight}
                    </Text>
                    <View style={styles.tableContainer}>
                      <Text style={styles.descriptionDots}>are due</Text>
                      <Text
                        style={{
                          ...styles.descriptionDots,
                          fontStyle: 'bold',
                        }}
                      >
                        {' '}
                        {dates.secondFortnightDue}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        {statement?.transactionsPerPage &&
        statement.transactionsPerPage.length === 0 ? (
          <>
            <View
              style={{
                padding: '0px 25px',
                width: '100%',
                height: '90vh',
                marginTop: 30,
              }}
            >
              <View>
                <TransactionDates {...{ theme, dates }} />
                <View
                  style={{
                    ...styles.card,
                    marginTop: 20,
                    height: 'auto',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                  >
                    <Text style={styles.text}>
                      There are no transactions for this period
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </>
        ) : (
          <>
            {(statement?.transactionsPerPage || []).map(
              (array: any, i: number) => {
                return (
                  <>
                    <View
                      style={{
                        padding: '0px 25px',
                        width: '100%',
                        height: '90vh',
                        marginTop: 25,
                      }}
                    >
                      <TransactionDates {...{ theme, dates }} />
                      <View
                        style={{
                          ...styles.card,
                          marginTop: 20,
                          height: 'auto',
                        }}
                      >
                        <View style={styles.tableContainer}>
                          <View style={{ width: '25%' }}>
                            <Text style={styles.text}>Date</Text>
                          </View>
                          <View style={{ width: '30%' }}>
                            <Text style={styles.text}>Merchant</Text>
                          </View>
                          <View style={{ width: '20%' }}>
                            <Text style={styles.text}>Cardholder</Text>
                          </View>
                          <View
                            style={{ width: '23%', alignItems: 'flex-end' }}
                          >
                            <Text style={styles.text}>Amount</Text>
                          </View>
                        </View>

                        <View
                          style={{
                            border: theme.colors.neutral[2],
                            width: '100%',
                            marginTop: 10,
                          }}
                        />

                        {(array || []).map((item: any, i: number) => (
                          <>
                            <View>
                              <View
                                style={{
                                  ...styles.tableContainer,
                                  marginTop: 10,
                                }}
                              >
                                <View style={{ width: '25%' }}>
                                  <Text style={styles.text}>
                                    {customDate(
                                      (item?.date || '')
                                        ?.slice(0, 10)
                                        .replaceAll('-', '/'),
                                      'numeric',
                                      'short',
                                      '2-digit',
                                    )}
                                  </Text>
                                </View>
                                <View style={{ width: '30%' }}>
                                  <Text
                                    style={{
                                      ...styles.text,
                                      color:
                                        parseFloat(item.total) < 0
                                          ? theme.colors.positive[1]
                                          : theme.fn.primaryColor(),
                                    }}
                                  >
                                    {parseFloat(item.total) < 0
                                      ? 'Refund'
                                      : item.merchant}
                                  </Text>
                                </View>
                                <View style={{ width: '25%' }}>
                                  <Text
                                    style={{
                                      ...styles.text,
                                      color: theme.fn.primaryColor(),
                                    }}
                                  >
                                    {item.who}
                                  </Text>
                                  <Text
                                    style={{
                                      ...styles.text,
                                      fontSize: '8px',
                                      marginTop: 3,
                                    }}
                                  >
                                    {item.type === 'bnpl'
                                      ? 'Flexbase Pay'
                                      : `••••${item.last4}`}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: '20%',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <Text
                                    style={{
                                      ...styles.text,
                                      color:
                                        parseFloat(item.amount) < 0
                                          ? theme.colors.positive[1]
                                          : theme.fn.primaryColor(),
                                    }}
                                  >
                                    {formatCurrency(
                                      Math.abs(parseFloat(item.amount)) || 0,
                                    )}
                                  </Text>
                                </View>
                              </View>
                              {i !== array.length - 1 && (
                                <View
                                  style={{
                                    border: '0.8px solid #EAEAEA',
                                    width: '100%',
                                    marginTop: 10,
                                  }}
                                />
                              )}
                            </View>
                          </>
                        ))}
                      </View>
                    </View>
                  </>
                );
              },
            )}
          </>
        )}
        <MonthlyTerms {...{ theme }} />
        <View style={styles.footerContainer} fixed>
          <View style={styles.tableContainer}>
            <View>
              <Text>Flexbase Technologies, Inc.</Text>
              <Text> 390 NE 191st St #8019, Miami, FL 33179</Text>
            </View>
            <View style={{ alignItems: 'flex-end' }}>
              <Text>Generated on {dates?.generatedAt}</Text>
              <Text
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StatementDoc;
